import React, { useState } from 'react';

import CompanyOverviewData from '../OverviewTab/company_overview.json';
import RiskAssessmentData from '../FinancialAndLegalTab/company_risk_assessment_report.json';
import MarketReportsData from './company_market_reports.json';
import CompanyDocumentData from '../GovernanceOperationsTab/company_documents.json';
import TechnologyReportData from '../GovernanceOperationsTab/company_technology_report.json';
import TechnologyRiskAssessmentData from './company_technology_risk_assessment.json';

import CompanyDocument from '../CompanyDocument';
import RiskAssessmentReport from '../FinancialAndLegalTab/RiskAssessmentReport';
import MarketReports from './MarketReports';
import CompanyDocuments from '../GovernanceOperationsTab/CompanyDocuments';
import ManagementReports from '../GovernanceOperationsTab/ManagementReports';
import TechnologyRiskAssessment from './TechnologyRiskAssessment';

const DiligenceSupportingTab = () => {
  const [selectedSection, setSelectedSection] = useState('documents');

  const toggleDocumentSection = value => {
    setSelectedSection(value);
  };
  return (
    <React.Fragment>
      <div className="company-category">
        <span
          className={selectedSection === 'documents' ? 'active' : ''}
          onClick={() => toggleDocumentSection('documents')}
        >
          Documents
        </span>
        <span
          className={selectedSection === 'marketReports' ? 'active' : ''}
          onClick={() => toggleDocumentSection('marketReports')}
        >
          Market Reports
        </span>
        <span
          className={selectedSection === 'technologyReports' ? 'active' : ''}
          onClick={() => toggleDocumentSection('technologyReports')}
        >
          Technology Reports
        </span>
        <span
          className={selectedSection === 'riskAssessmentReports' ? 'active' : ''}
          onClick={() => toggleDocumentSection('riskAssessmentReports')}
        >
          Risk Assessment Reports
        </span>
        <span
          className={selectedSection === 'technologyRisk' ? 'active' : ''}
          onClick={() => toggleDocumentSection('technologyRisk')}
        >
          Technology Risk
        </span>
      </div>
      <CompanyDocument header={CompanyOverviewData.header} details={CompanyOverviewData}>
        {selectedSection === 'documents' && <CompanyDocuments details={CompanyDocumentData} />}
        {selectedSection === 'marketReports' && <MarketReports details={MarketReportsData} />}
        {selectedSection === 'technologyReports' && <ManagementReports details={TechnologyReportData} />}
        {selectedSection === 'riskAssessmentReports' && <RiskAssessmentReport details={RiskAssessmentData} />}
        {selectedSection === 'technologyRisk' && <TechnologyRiskAssessment details={TechnologyRiskAssessmentData} />}
      </CompanyDocument>
    </React.Fragment>
  );
};

export default DiligenceSupportingTab;
