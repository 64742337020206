const BadActorReport = ({ details }) => {
  return (
    <>
      <h2>Bad Actor Report</h2>
      <table>
        <thead>
          <tr>
            <th>Role</th>
            <th>Name</th>
            <th>Status</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(details.badActorReport).map(([role, actor]: any) => (
            <tr key={role}>
              <td>{role}</td>
              <td>{actor.name}</td>
              <td>{actor.status}</td>
              <td>{actor.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default BadActorReport;
