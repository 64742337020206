import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-component';

import { gallery } from '../../../common/data';

const Marketplaces = () => {
  const [displayCategory, setCategory] = useState('ALL');

  document.title = 'Gallery | crwdcapital - universal wallet';
  const marketplaces = [
    { key: 'all', label: 'ALL' },
    { key: 'gics', label: 'GICS' },
    { key: 'crwdworld', label: 'crwdworld' },
    { key: '10xWorld', label: '10X WORLD' },
    { key: 'chWorld', label: 'CH WORLD' },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Marketplaces" pageTitle="Marketplaces" />
          <Row>
            <Col lg={12}>
              <div className="">
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <div className="text-center">
                        <ul className="list-inline categories-filter animation-nav" id="filter">
                          {marketplaces.map(marketplace => (
                            <li className="list-inline-item" key={marketplace.key}>
                              <Link
                                to="#"
                                onClick={() => setCategory(marketplace.label)}
                                className={displayCategory === marketplace.label ? 'categories active' : 'categories'}
                                data-filter="*"
                              >
                                {marketplace.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <Masonry className="row gallery-wrapper">
                        {gallery
                          .filter(({ category }) => displayCategory === category || displayCategory === 'ALL')
                          .map(({ img, title, auther, likes, comments }, key) => (
                            <Col xxl={3} xl={4} sm={6} className="element-item project designing development" key={key}>
                              <Card className="gallery-box">
                                <div className="gallery-container">
                                  <Link className="image-popup" to={img} title="">
                                    <img className="gallery-img img-fluid mx-auto" src={img} alt="" />
                                    <div className="gallery-overlay">
                                      <h5 className="overlay-caption">{title}</h5>
                                    </div>
                                  </Link>
                                </div>

                                <div className="box-content">
                                  <div className="d-flex align-items-center mt-1">
                                    {auther && (
                                      <div className="flex-grow-1 text-muted">
                                        by
                                        <Link to="#" className="text-body text-truncate">
                                          {auther}
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Card>
                            </Col>
                          ))}
                      </Masonry>
                    </Col>
                  </Row>
                </CardBody>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Marketplaces;
