import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CIM from '../../../assets/images/tile_contract.jpg';

const MyContracts = () => {
  document.title = 'Contacts | crwdcapital - universal wallet';

  const contracts = [
    {
      title: 'SAMPLE CONTRACT 1',
      img: CIM,
      text: 'This smart contract is used for securing terms and conditions within crwdworld.  It has both a presence on the Vogon Blockchain and a relationship to digital contract when necessary',
    },
    {
      title: 'SAMPLE CONTRACT 2',
      img: CIM,
      text: 'This smart contract is used for securing terms and conditions within crwdworld.  It has both a presence on the Vogon Blockchain and a relationship to digital contract when necessary',
    },
    {
      title: 'SAMPLE CONTRACT 3',
      img: CIM,
      text: 'This smart contract is used for securing terms and conditions within crwdworld.  It has both a presence on the Vogon Blockchain and a relationship to digital contract when necessary',
    },
    {
      title: 'SAMPLE CONTRACT 4',
      img: CIM,
      text: 'This smart contract is used for securing terms and conditions within crwdworld.  It has both a presence on the Vogon Blockchain and a relationship to digital contract when necessary',
    },
    {
      title: 'SAMPLE CONTRACT 5',
      img: CIM,
      text: 'This smart contract is used for securing terms and conditions within crwdworld.  It has both a presence on the Vogon Blockchain and a relationship to digital contract when necessary',
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="My Contracts" pageTitle="Ellipsis" />
          <Row>
            <Col xxl={12}>
              <Row>
                {contracts.map((contract, i) => (
                  <Col sm={6} xl={3} key={i}>
                    <Card>
                      <img className="card-img-top img-fluid" src={contract.img} alt="Card cap" />
                      <CardBody>
                        <h4 className="card-title mb-2">{contract.title}</h4>
                        <p className="card-text mb-0 line-clamp-2">{contract.text}</p>
                      </CardBody>
                      <div className="card-footer">
                        <Link to="#" className="card-link link-secondary">
                          Read More <i className="ri-arrow-right-s-line ms-1 align-middle lh-1"></i>
                        </Link>
                        <Link to="#" className="card-link link-success">
                          Bookmark <i className="ri-bookmark-line align-middle ms-1 lh-1"></i>
                        </Link>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyContracts;
