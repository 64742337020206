import CDF from '../../assets/images/marketplaces/gics/CDF.svg';
import CSF from '../../assets/images/marketplaces/gics/CSF.svg';
import CVF from '../../assets/images/marketplaces/gics/CVF.svg';
import ESF from '../../assets/images/marketplaces/gics/ESF.svg';
import FSF from '../../assets/images/marketplaces/gics/FSF.svg';
import HSF from '../../assets/images/marketplaces/gics/HSF.svg';
import ISF from '../../assets/images/marketplaces/gics/ISF.svg';
import ITF from '../../assets/images/marketplaces/gics/ITF.svg';
import MSF from '../../assets/images/marketplaces/gics/MSF.svg';
import REF from '../../assets/images/marketplaces/gics/REF.svg';
import USF from '../../assets/images/marketplaces/gics/USF.svg';

import EWP from '../../assets/images/marketplaces/gics/EWP.png';
import EEM from '../../assets/images/marketplaces/gics/EEM.png';
import MWP from '../../assets/images/marketplaces/gics/MWP.png';
import IWP from '../../assets/images/marketplaces/gics/IWP.png';
import CDWP from '../../assets/images/marketplaces/gics/CDWP.png';
import CDMM from '../../assets/images/marketplaces/gics/CDMM.png';
import CSWP from '../../assets/images/marketplaces/gics/CSWP.png';
import CSAM from '../../assets/images/marketplaces/gics/CSAM.png';
import HCWP from '../../assets/images/marketplaces/gics/HCWP.png';
import HCHN from '../../assets/images/marketplaces/gics/HCHN.png';
import ITWP from '../../assets/images/marketplaces/gics/ITWP.png';
import ITCM from '../../assets/images/marketplaces/gics/ITCM.png';
import CMSWP from '../../assets/images/marketplaces/gics/CMSWP.png';
import CMSDR from '../../assets/images/marketplaces/gics/CMSDR.png';
import UWP from '../../assets/images/marketplaces/gics/UWP.png';
import UCM from '../../assets/images/marketplaces/gics/UCM.png';
import FINCIAL from '../../assets/images/marketplaces/gics/financial_sector.png';
import CRWDUNIT from '../../assets/images/marketplaces/gics/crwdunit.png';

import GICSImage from '../../assets/images/marketplaces/gics_logo_white.png';

export const gics = [
  {
    id: 'energy',
    img: ESF,
    title: 'ENERGY',
    category: 'GICS',
    links: [
      {
        id: 'white-paper',
        img: EWP,
        link: 'https://f.hubspotusercontent40.net/hubfs/7175493/White%20Papers/Energy%20White%20Paper.pdf',
        title: '',
      },
      {
        id: 'esg-microgrid',
        img: EEM,
        link: 'https://7175493.fs1.hubspotusercontent-na1.net/hubfs/7175493/White%20Papers/esg_microgrid.pdf',
        title: '',
      },
    ],
  },
  {
    id: 'materials',
    img: MSF,
    title: 'MATERIALS',
    category: 'GICS',
    links: [
      {
        id: 'white-paper',
        img: MWP,
        link: 'https://7175493.fs1.hubspotusercontent-na1.net/hubfs/7175493/White%20Papers/Materials%20White%20Paper.pdf',
        title: '',
      },
    ],
  },
  {
    id: 'industrials',
    img: ISF,
    title: 'INDUSTRIALS',
    category: 'GICS',
    links: [
      {
        id: 'white-paper',
        img: IWP,
        link: 'https://7175493.fs1.hubspotusercontent-na1.net/hubfs/7175493/White%20Papers/Industrials%20White%20Paper.pdf',
        title: '',
      },
    ],
  },
  {
    id: 'consumer-discretionary',
    img: CDF,
    title: 'CONSUMER DISCRETIONARY',
    category: 'GICS',
    links: [
      {
        id: 'white-paper',
        img: CDWP,
        link: 'https://7175493.fs1.hubspotusercontent-na1.net/hubfs/7175493/White%20Papers/Consumer%20Discretionary%20White%20Paper.pdf',
        title: '',
      },
      { id: 'imedia-market', img: CDMM, link: 'https://imedia.market', title: '' },
    ],
  },
  {
    id: 'consumer-staples',
    img: CSF,
    title: 'CONSUMER STAPLES',
    category: 'GICS',
    links: [
      {
        id: 'white-paper',
        img: CSWP,
        link: 'https://7175493.fs1.hubspotusercontent-na1.net/hubfs/7175493/White%20Papers/Consumer%20Staples%20White%20Paper.pdf',
        title: '',
      },
      { id: 'advanced-medicine', img: CSAM, link: 'https://advancedmedicine.market', title: '' },
    ],
  },
  {
    id: 'health-care',
    img: HSF,
    title: 'HEALTH CARE',
    category: 'GICS',
    links: [
      {
        id: 'white-paper',
        img: HCWP,
        link: 'https://7175493.fs1.hubspotusercontent-na1.net/hubfs/7175493/White%20Papers/Health%20Care%20White%20Paper.pdf',
        title: '',
      },
      { id: 'health-now', img: HCHN, link: 'https://10xhealthnow.com', title: '' },
    ],
  },
  {
    id: 'financials',
    img: FSF,
    title: 'FINANCIALS',
    category: 'GICS',
    links: [
      {
        id: 'white-paper',
        img: FINCIAL,
        link: 'https://7175493.fs1.hubspotusercontent-na1.net/hubfs/7175493/White%20Papers/Financials%20White%20Paper.pdf',
        title: '',
      },
      { id: 'crwd-unit', img: CRWDUNIT, link: 'https://crwdunit.com', title: '' },
    ],
  },
  {
    id: 'inforamation-technology',
    img: ITF,
    title: 'INFORMATION TECHNOLOGY',
    category: 'GICS',
    links: [
      {
        id: 'white-paper',
        img: ITWP,
        link: 'https://7175493.fs1.hubspotusercontent-na1.net/hubfs/7175493/White%20Papers/Information%20Technology%20White%20Paper.pdf',
        title: '',
      },
      { id: 'cyber-market', img: ITCM, link: 'https://10xcyber.market', title: '' },
    ],
  },
  {
    id: 'communication-services',
    img: CVF,
    title: 'COMMUNICATION SERVICES',
    category: 'GICS',
    links: [
      {
        id: 'white-paper',
        img: CMSWP,
        link: 'https://7175493.fs1.hubspotusercontent-na1.net/hubfs/7175493/White%20Papers/Communication%20Services%20White%20Paper.pdf',
        title: '',
      },
      {
        id: 'dynamic_routing_D4',
        img: CMSDR,
        link: 'https://7175493.fs1.hubspotusercontent-na1.net/hubfs/7175493/White%20Papers/Dynamic_Routing_D4.pdf',
        title: '',
      },
    ],
  },
  {
    id: 'utilities',
    img: USF,
    title: 'UTILITIES',
    category: 'GICS',
    links: [
      {
        id: 'white-paper',
        img: UWP,
        link: 'https://7175493.fs1.hubspotusercontent-na1.net/hubfs/7175493/White%20Papers/Utilities%20White%20Paper.pdf',
        title: '',
      },
      {
        id: 'circle-h-marketplace',
        img: UCM,
        link: 'https://www.figma.com/proto/WA6iCdgzOD8kjZEoQJMxkM/Circle-H-Marketplace?node-id=404%3A826963&scaling=contain&page-id=924%3A78640&starting-point-node-id=149%3A508248',
        title: '',
      },
    ],
  },
  {
    id: 'real-estate',
    img: REF,
    title: 'REAL ESTATE',
    category: 'GICS',
    links: [],
  },
];

export const gics_logo = {
  id: '',
  img: GICSImage,
  title: 'Marketplaces',
};
