import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

// Import Images
import img1 from '../../../assets/images/landing/features/img-1.png';
import img2 from '../../../assets/images/landing/features/img-2.png';
import img3 from '../../../assets/images/landing/features/img-3.png';

const Features = () => {
  return (
    <React.Fragment>
      <section className="section bg-light py-5" id="features">
        <Container>
          <Row className="align-items-center gy-4">
            <Col lg={6} sm={7} className="mx-auto">
              <div>
                <img src={img1} alt="" className="img-fluid mx-auto" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-muted">
                <div className="avatar-sm icon-effect mb-4">
                  <div className="avatar-title bg-transparent rounded-circle text-success h1">
                    <i className="ri-collage-line fs-36"></i>
                  </div>
                </div>
                <h3 className="mb-3 fs-20">Huge collection of widgets</h3>
                <p className="mb-4 ff-secondary fs-16">
                  Collection widgets specialize in displaying many elements of the same type, such as a collection of
                  pictures from a collection of articles from a news app or a collection of messages from a
                  communication app.
                </p>

                <Row className="pt-3">
                  <Col className="col-3">
                    <div className="text-center">
                      <h4>5</h4>
                      <p>Dashboards</p>
                    </div>
                  </Col>
                  <Col className="col-3">
                    <div className="text-center">
                      <h4>150+</h4>
                      <p>Pages</p>
                    </div>
                  </Col>
                  <Col className="col-4">
                    <div className="text-center">
                      <h4>7+</h4>
                      <p>Functional Apps</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-5 bg-primary position-relative">
        <div className="bg-overlay bg-overlay-pattern opacity-50"></div>
        <Container>
          <Row className="align-items-center gy-4">
            <Col className="col-sm">
              <div>
                <h4 className="text-white mb-0 fw-semibold">Build your web App/SaaS with Velzon dashboard</h4>
              </div>
            </Col>
            <Col className="col-sm-auto">
              <div>
                <Link to="/1.envato.market/velzon-admin" target="_blank" className="btn bg-gradient btn-danger">
                  <i className="ri-shopping-cart-2-line align-middle me-1"></i> Buy Now
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section">
        <Container>
          <Row className="align-items-center gy-4">
            <Col lg={6} className="order-2 order-lg-1">
              <div className="text-muted">
                <h5 className="fs-12 text-uppercase text-success">Design</h5>
                <h4 className="mb-3">Well Designed Dashboards</h4>
                <p className="mb-4 ff-secondary">
                  Quality Dashboards (QD) is a condition-specific, actionable web-based application for quality
                  reporting and population management that is integrated into the Electronic Health Record (EHR).
                </p>

                <Row>
                  <Col sm={5}>
                    <div className="vstack gap-2">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <div className="avatar-xs icon-effect">
                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                              <i className="ri-check-fill"></i>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="fs-14 mb-0">Ecommerce</h5>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <div className="avatar-xs icon-effect">
                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                              <i className="ri-check-fill"></i>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="fs-14 mb-0">Analytics</h5>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <div className="avatar-xs icon-effect">
                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                              <i className="ri-check-fill"></i>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="fs-14 mb-0">CRM</h5>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={5}>
                    <div className="vstack gap-2">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <div className="avatar-xs icon-effect">
                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                              <i className="ri-check-fill"></i>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="fs-14 mb-0">Crypto</h5>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <div className="avatar-xs icon-effect">
                            <div className="avatar-title bg-transparent text-success rounded-circle h2">
                              <i className="ri-check-fill"></i>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="fs-14 mb-0">Projects</h5>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="mt-4">
                  <Link to="/index" className="btn btn-primary">
                    Learn More <i className="ri-arrow-right-line align-middle ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>

            <Col lg={6} sm={7} className="col-10 ms-auto order-1 order-lg-2">
              <div>
                <img src={img2} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>

          <Row className="align-items-center mt-5 pt-lg-5 gy-4">
            <Col lg={6} sm={7} className="col-10 mx-auto">
              <div>
                <img src={img3} alt="" className="img-fluid" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-muted ps-lg-5">
                <h5 className="fs-12 text-uppercase text-success">structure</h5>
                <h4 className="mb-3">Well Documented</h4>
                <p className="mb-4">
                  used to describe something that is known about or known to be true because there are many documents
                  that describe it, prove it, etc.
                </p>

                <div className="vstack gap-2">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-2">
                      <div className="avatar-xs icon-effect">
                        <div className="avatar-title bg-transparent text-success rounded-circle h2">
                          <i className="ri-check-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <p className="mb-0">Dynamic Conetnt</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-2">
                      <div className="avatar-xs icon-effect">
                        <div className="avatar-title bg-transparent text-success rounded-circle h2">
                          <i className="ri-check-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <p className="mb-0">Setup plugin's information.</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-2">
                      <div className="avatar-xs icon-effect">
                        <div className="avatar-title bg-transparent text-success rounded-circle h2">
                          <i className="ri-check-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <p className="mb-0">Themes customization information</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
