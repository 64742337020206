import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';

import Widgets from './Widgets';
import Marketplace from './Marketplace';
import Popularity from './Popularity';
import ESGAuction from 'pages/Pages/Capital/ESGAuction';
import SilverPriceChart from 'pages/Crypto/MyVault/SilverPriceChart';

const DashboardCrwdunit = ({ title, assetId }) => {
  document.title = title;
  const dashboardPageName = location.pathname === '/capital/esg-dashboard' ? 'ESG | ALL' : 'crwdunit';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={title} pageTitle="Dashboard" />
          <Row className="dash-nft">
            <Col xxl={9}>
              <Widgets name={name} />
              <SilverPriceChart />
              <Marketplace assetId={assetId} dashboardPageName={dashboardPageName} />
            </Col>
            <Popularity assetId={assetId} dashboardPageId={dashboardPageName} />
          </Row>
        </Container>
        <ESGAuction assetId={assetId} dashboardId={dashboardPageName} isComponentLayout={true} />
      </div>
    </React.Fragment>
  );
};

export default DashboardCrwdunit;
