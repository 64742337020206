import React from 'react';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Container } from 'reactstrap';

import List from './List';

const ProjectList = () => {
  document.title = 'Project List | crwdcapital - universal wallet';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Press Releases" pageTitle="Projects" />
          <List />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProjectList;
