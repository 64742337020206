import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader } from 'reactstrap';
import getChartColorsArray from 'Components/Common/ChartsDynamicColor';

const SilverPriceChart = () => {
  const yahooFinanceURL = `/yahoo-finance-silver`;
  const [series, setSeries] = useState([]);
  const [range, setRange] = useState('6mo');
  const [interval, setInterval] = useState('1d');

  const rangeToInterval = {
    '1d': '2m',
    '5d': '5m',
    '1mo': '1h',
    '3mo': '1d',
    '6mo': '1d',
    ytd: '1d',
    '1y': '1wk',
    '5y': '1mo',
    max: '1mo',
  };

  useEffect(() => {
    fetchSeriesData(range, interval);
  }, [range, interval]);

  const handleButtonClick = newRange => {
    setRange(newRange);
    setInterval(rangeToInterval[newRange]);
  };

  const fetchSeriesData = async (range, interval) => {
    const response = await fetch(
      `${yahooFinanceURL}?region=US&lang=en-US&includePrePost=false&interval=${interval}&range=${range}`,
    );
    const data = await response.json();
    const timestamp = data.chart.result[0]?.timestamp;
    const closePrices = data.chart.result[0].indicators.quote[0].close;

    const seriesData = timestamp.reduce((acc, time, index) => {
      if (closePrices[index] !== null) {
        acc.push([time * 1000, parseFloat(closePrices[index]).toFixed(2)]);
      }
      return acc;
    }, []);
    setSeries([{ data: seriesData }]);
  };

  const options = {
    chart: {
      id: 'area-datetime',
      type: 'area',
      height: 320,
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
    },
    colors: getChartColorsArray('["--vz-info"]'),

    dataLabels: {
      enabled: false,
    },

    xaxis: {
      type: 'datetime',
      tickAmount: 8,
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy',
      },
    },
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="card-title mb-0">Silver Price (SI:CMX)</h5>
            </div>
            <div className="toolbar d-flex align-items-start justify-content-center flex-wrap gap-2">
              {Object.keys(rangeToInterval).map(key => (
                <button
                  key={key}
                  type="button"
                  className={`btn btn-soft-primary timeline-btn btn-sm ${range === key ? 'active' : ''}`}
                  onClick={() => handleButtonClick(key)}
                >
                  {key.toUpperCase()}
                </button>
              ))}
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <ReactApexChart options={options} series={series} type="area" height="320" className="apex-charts" />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SilverPriceChart;
