import React, { useState } from 'react';
import { useUserContext } from 'shared/UserContext';

const Navdata = () => {
  const [isAdministrative, setIsAdministrative] = useState(false);
  const { userRole } = useUserContext();
  const isAdmin = userRole === 'admin';

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu')!;
      const iconItems: any = ul.querySelectorAll('.nav-icon.active');
      let activeIconItems = [...iconItems];
      activeIconItems.forEach(item => {
        item.classList.remove('active');
        var id = item.getAttribute('subitems');
        if (document.getElementById(id)) {
          document.getElementById(id)?.classList.remove('show');
        }
      });
    }
  }

  const adminMenu = {
    ...(isAdmin
      ? {
          id: 'cognitive',
          label: 'Cognitive Library',
          link: '/admin/cognitive-library',
          icon: 'ri-stack-line',
        }
      : {}),
  };

  const getAdministrativeMenu: any = [
    {
      id: 'authentication',
      label: 'Authentication',
      icon: 'ri-account-circle-line',
      link: '/#',
      click: function (e) {
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'signIn',
          label: 'Sign In',
          link: '/#',
          parentId: 'authentication',
          childItems: [
            { id: 1, label: 'Basic', link: '/auth-signin-basic' },
            { id: 2, label: 'Cover', link: '/auth-signin-cover' },
          ],
        },
        {
          id: 'signUp',
          label: 'Sign Up',
          link: '/#',
          parentId: 'authentication',
          childItems: [
            { id: 1, label: 'Basic', link: '/auth-signup-basic' },
            { id: 2, label: 'Cover', link: '/auth-signup-cover' },
          ],
        },
        {
          id: 'passwordReset',
          label: 'Password Reset',
          link: '/#',
          parentId: 'authentication',
          childItems: [
            { id: 1, label: 'Basic', link: '/auth-pass-reset-basic' },
            { id: 2, label: 'Cover', link: '/auth-pass-reset-cover' },
          ],
        },
        {
          id: 'passwordCreate',
          label: 'Password Create',
          link: '/#',
          parentId: 'authentication',
          childItems: [
            { id: 1, label: 'Basic', link: '/auth-pass-change-basic' },
            { id: 2, label: 'Cover', link: '/auth-pass-change-cover' },
          ],
        },
        {
          id: 'lockScreen',
          label: 'Lock Screen',
          link: '/#',
          parentId: 'authentication',
          childItems: [
            { id: 1, label: 'Basic', link: '/auth-lockscreen-basic' },
            { id: 2, label: 'Cover', link: '/auth-lockscreen-cover' },
          ],
        },
        {
          id: 'logout',
          label: 'Logout',
          link: '/#',
          parentId: 'authentication',
          childItems: [
            { id: 1, label: 'Basic', link: '/auth-logout-basic' },
            { id: 2, label: 'Cover', link: '/auth-logout-cover' },
          ],
        },
        {
          id: 'successMessage',
          label: 'Success Message',
          link: '/#',
          parentId: 'authentication',
          childItems: [
            { id: 1, label: 'Basic', link: '/auth-success-msg-basic' },
            { id: 2, label: 'Cover', link: '/auth-success-msg-cover' },
          ],
        },
        {
          id: 'twoStepVerification',
          label: 'Two Step Verification',
          link: '/#',
          parentId: 'authentication',
          childItems: [
            { id: 1, label: 'Basic', link: '/auth-twostep-basic' },
            { id: 2, label: 'Cover', link: '/auth-twostep-cover' },
          ],
        },
        {
          id: 'errors',
          label: 'Errors',
          link: '/#',
          parentId: 'authentication',
          childItems: [
            { id: 1, label: '404 Basic', link: '/auth-404-basic' },
            { id: 2, label: '404 Cover', link: '/auth-404-cover' },
            { id: 3, label: '404 Alt', link: '/auth-404-alt' },
            { id: 4, label: '500', link: '/auth-500' },
            { id: 5, label: 'Offline Page', link: '/auth-offline' },
          ],
        },
      ],
    },
    {
      id: 'pages',
      label: 'Pages',
      icon: 'ri-pages-line',
      link: '/#',
      click: function (e) {
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'starter',
          label: 'Starter',
          link: '/pages-starter',
          parentId: 'pages',
        },
        {
          id: 'profile',
          label: 'Profile',
          link: '/#',
          parentId: 'pages',
          childItems: [
            {
              id: 1,
              label: 'Simple Page',
              link: '/pages-profile',
              parentId: 'pages',
            },
            {
              id: 2,
              label: 'Settings',
              link: '/pages-profile-settings',
              parentId: 'pages',
            },
          ],
        },
        { id: 'team', label: 'Team', link: '/pages-team', parentId: 'pages' },
        {
          id: 'timeline',
          label: 'Timeline',
          link: '/pages-timeline',
          parentId: 'pages',
        },
        { id: 'faqs', label: 'FAQs', link: '/pages-faqs', parentId: 'pages' },
        {
          id: 'pricing',
          label: 'Pricing',
          link: '/pages-pricing',
          parentId: 'pages',
        },
        {
          id: 'maintenance',
          label: 'Maintenance',
          link: '/pages-maintenance',
          parentId: 'pages',
        },
        {
          id: 'comingSoon',
          label: 'Coming Soon',
          link: '/pages-coming-soon',
          parentId: 'pages',
        },

        {
          id: 'searchResults',
          label: 'Search Results',
          link: '/pages-search-results',
          parentId: 'pages',
        },
        { id: 'explore', label: 'Explore', link: '/apps-nft-explore', parentId: 'pages' },
        { id: 'live-auction', label: 'Live Auction', link: '/apps-nft-auction', parentId: 'pages' },
        { id: 'item-details', label: 'Item Details', link: '/apps-nft-item-details', parentId: 'pages' },
        { id: 22, label: 'ESG Auction', link: '/capital/esg-auction', parentId: 'capital' },
      ],
    },
    {
      id: 'landing',
      label: 'Landing',
      icon: 'ri-rocket-line',
      link: '/#',
      click: function (e) {
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'onePage',
          label: 'One Page',
          link: '/landing',
          parentId: 'landing',
        },
        {
          id: 'nftLanding',
          label: 'NFT Landing',
          link: '/nft-landing',
          parentId: 'landing',
        },
      ],
    },
    {
      id: 'baseUi',
      label: 'Base UI',
      icon: 'ri-pencil-ruler-2-line',
      link: '/#',
      click: function (e) {
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'alerts',
          label: 'Alerts',
          link: '/ui-alerts',
          parentId: 'baseUi',
        },
        {
          id: 'badges',
          label: 'Badges',
          link: '/ui-badges',
          parentId: 'baseUi',
        },
        {
          id: 'buttons',
          label: 'Buttons',
          link: '/ui-buttons',
          parentId: 'baseUi',
        },
        {
          id: 'colors',
          label: 'Colors',
          link: '/ui-colors',
          parentId: 'baseUi',
        },
        { id: 'cards', label: 'Cards', link: '/ui-cards', parentId: 'baseUi' },
        {
          id: 'carousel',
          label: 'Carousel',
          link: '/ui-carousel',
          parentId: 'baseUi',
        },
        {
          id: 'dropdowns',
          label: 'Dropdowns',
          link: '/ui-dropdowns',
          parentId: 'baseUi',
        },
        { id: 'grid', label: 'Grid', link: '/ui-grid', parentId: 'baseUi' },
        {
          id: 'images',
          label: 'Images',
          link: '/ui-images',
          parentId: 'baseUi',
        },
        { id: 'tabs', label: 'Tabs', link: '/ui-tabs', parentId: 'baseUi' },
        {
          id: 'accordions',
          label: 'Accordion & Collapse',
          link: '/ui-accordions',
          parentId: 'baseUi',
        },
        {
          id: 'modals',
          label: 'Modals',
          link: '/ui-modals',
          parentId: 'baseUi',
        },
        {
          id: 'offcanvas',
          label: 'Offcanvas',
          link: '/ui-offcanvas',
          parentId: 'baseUi',
        },
        {
          id: 'placeholders',
          label: 'Placeholders',
          link: '/ui-placeholders',
          parentId: 'baseUi',
        },
        {
          id: 'progress',
          label: 'Progress',
          link: '/ui-progress',
          parentId: 'baseUi',
        },
        {
          id: 'notifications',
          label: 'Notifications',
          link: '/ui-notifications',
          parentId: 'baseUi',
        },
        {
          id: 'media',
          label: 'Media object',
          link: '/ui-media',
          parentId: 'baseUi',
        },
        {
          id: 'embedvideo',
          label: 'Embed Video',
          link: '/ui-embed-video',
          parentId: 'baseUi',
        },
        {
          id: 'typography',
          label: 'Typography',
          link: '/ui-typography',
          parentId: 'baseUi',
        },
        { id: 'lists', label: 'Lists', link: '/ui-lists', parentId: 'baseUi' },
        {
          id: 'general',
          label: 'General',
          link: '/ui-general',
          parentId: 'baseUi',
        },
        {
          id: 'ribbons',
          label: 'Ribbons',
          link: '/ui-ribbons',
          parentId: 'baseUi',
        },
        {
          id: 'utilities',
          label: 'Utilities',
          link: '/ui-utilities',
          parentId: 'baseUi',
        },
      ],
    },
    {
      id: 'advanceUi',
      label: 'Advance UI',
      icon: 'ri-stack-line',
      link: '/#',
      click: function (e) {
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'nestablelist',
          label: 'Nestable List',
          link: '/advance-ui-nestable',
          parentId: 'advanceUi',
        },
        {
          id: 'scrollbar',
          label: 'Scrollbar',
          link: '/advance-ui-scrollbar',
          parentId: 'advanceUi',
        },
        {
          id: 'animation',
          label: 'Animation',
          link: '/advance-ui-animation',
          parentId: 'advanceUi',
        },
        {
          id: 'tour',
          label: 'Tour',
          link: '/advance-ui-tour',
          parentId: 'advanceUi',
        },
        {
          id: 'swiperslider',
          label: 'Swiper Slider',
          link: '/advance-ui-swiper',
          parentId: 'advanceUi',
        },
        {
          id: 'ratings',
          label: 'Ratings',
          link: '/advance-ui-ratings',
          parentId: 'advanceUi',
        },
        {
          id: 'highlight',
          label: 'Highlight',
          link: '/advance-ui-highlight',
          parentId: 'advanceUi',
        },
      ],
    },
    {
      id: 'forms',
      label: 'Forms',
      icon: 'ri-file-list-3-line',
      link: '/#',
      click: function (e) {
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'basicelements',
          label: 'Basic Elements',
          link: '/forms-elements',
          parentId: 'forms',
        },
        {
          id: 'formselect',
          label: 'Form Select',
          link: '/forms-select',
          parentId: 'forms',
        },
        {
          id: 'checkboxsradios',
          label: 'Checkboxs & Radios',
          link: '/forms-checkboxes-radios',
          parentId: 'forms',
        },
        {
          id: 'pickers',
          label: 'Pickers',
          link: '/forms-pickers',
          parentId: 'forms',
        },
        {
          id: 'inputmasks',
          label: 'Input Masks',
          link: '/forms-masks',
          parentId: 'forms',
        },
        {
          id: 'advanced',
          label: 'Advanced',
          link: '/forms-advanced',
          parentId: 'forms',
        },
        {
          id: 'rangeslider',
          label: 'Range Slider',
          link: '/forms-range-sliders',
          parentId: 'forms',
        },
        {
          id: 'validation',
          label: 'Validation',
          link: '/forms-validation',
          parentId: 'forms',
        },
        {
          id: 'wizard',
          label: 'Wizard',
          link: '/forms-wizard',
          parentId: 'forms',
        },
        {
          id: 'editors',
          label: 'Editors',
          link: '/forms-editors',
          parentId: 'forms',
        },
        {
          id: 'fileuploads',
          label: 'File Uploads',
          link: '/forms-file-uploads',
          parentId: 'forms',
        },
        {
          id: 'formlayouts',
          label: 'Form Layouts',
          link: '/forms-layouts',
          parentId: 'forms',
        },
        {
          id: 'select2',
          label: 'Select2',
          link: '/forms-select2',
          parentId: 'forms',
        },
      ],
    },
    {
      id: 'tables',
      label: 'Tables',
      icon: 'ri-layout-grid-line',
      link: '/#',
      click: function (e) {
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'basictables',
          label: 'Basic Tables',
          link: '/tables-basic',
          parentId: 'tables',
        },
        {
          id: 'gridjs',
          label: 'Grid Js',
          link: '/tables-gridjs',
          parentId: 'tables',
        },
        {
          id: 'listjs',
          label: 'List Js',
          link: '/tables-listjs',
          parentId: 'tables',
        },
        {
          id: 'datatables',
          label: 'Datatables',
          link: '/tables-datatables',
          parentId: 'tables',
        },
      ],
    },
    {
      id: 'charts',
      label: 'Charts',
      icon: 'ri-pie-chart-line',
      link: '/#',
      click: function (e) {
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'apexcharts',
          label: 'Apexcharts',
          link: '/#',
          childItems: [
            { id: 1, label: 'Line', link: '/charts-apex-line' },
            { id: 2, label: 'Area', link: '/charts-apex-area' },
            { id: 3, label: 'Column', link: '/charts-apex-column' },
            { id: 4, label: 'Bar', link: '/charts-apex-bar' },
            { id: 5, label: 'Mixed', link: '/charts-apex-mixed' },
            { id: 6, label: 'Timeline', link: '/charts-apex-timeline' },
            { id: 7, label: 'Candlstick', link: '/charts-apex-candlestick' },
            { id: 8, label: 'Boxplot', link: '/charts-apex-boxplot' },
            { id: 9, label: 'Bubble', link: '/charts-apex-bubble' },
            { id: 10, label: 'Scatter', link: '/charts-apex-scatter' },
            { id: 11, label: 'Heatmap', link: '/charts-apex-heatmap' },
            { id: 12, label: 'Treemap', link: '/charts-apex-treemap' },
            { id: 13, label: 'Pie', link: '/charts-apex-pie' },
            { id: 14, label: 'Radialbar', link: '/charts-apex-radialbar' },
            { id: 15, label: 'Radar', link: '/charts-apex-radar' },
            { id: 16, label: 'Polar Area', link: '/charts-apex-polar' },
          ],
        },
        {
          id: 'chartjs',
          label: 'Chartjs',
          link: '/charts-chartjs',
          parentId: 'charts',
        },
        {
          id: 'echarts',
          label: 'Echarts',
          link: '/charts-echarts',
          parentId: 'charts',
        },
      ],
    },
    {
      id: 'icons',
      label: 'Icons',
      icon: 'ri-compasses-2-line',
      link: '/#',
      click: function (e) {
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'remix',
          label: 'Remix',
          link: '/icons-remix',
          parentId: 'icons',
        },
        {
          id: 'boxicons',
          label: 'Boxicons',
          link: '/icons-boxicons',
          parentId: 'icons',
        },
        {
          id: 'materialdesign',
          label: 'Material Design',
          link: '/icons-materialdesign',
          parentId: 'icons',
        },
        {
          id: 'lineawesome',
          label: 'Line Awesome',
          link: '/icons-lineawesome',
          parentId: 'icons',
        },
        {
          id: 'feather',
          label: 'Feather',
          link: '/icons-feather',
          parentId: 'icons',
        },
      ],
    },
    {
      id: 'maps',
      label: 'Maps',
      icon: 'ri-map-pin-line',
      link: '/#',
      click: function (e) {
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'google',
          label: 'Google',
          link: '/maps-google',
          parentId: 'maps',
        },
        {
          id: 'vector',
          label: 'Vector',
          link: '/maps-vector',
          parentId: 'maps',
        },
        {
          id: 'leaflet',
          label: 'Leaflet',
          link: '/maps-leaflet',
          parentId: 'maps',
        },
      ],
    },
    {
      id: 'multilevel',
      label: 'Multi Level',
      icon: 'ri-share-line',
      link: '/#',
      click: function (e) {
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'level1.1',
          label: 'Level 1.1',
          link: '/#',
          parentId: 'multilevel',
        },
        {
          id: 'level1.2',
          label: 'Level 1.2',
          link: '/#',
          childItems: [
            { id: 1, label: 'Level 2.1', link: '/#' },
            {
              id: 'level2.2',
              label: 'Level 2.2',
              link: '/#',
              childItems: [
                { id: 1, label: 'Level 3.1', link: '/#' },
                { id: 2, label: 'Level 3.2', link: '/#' },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'widgets',
      label: 'Widgets',
      icon: 'ri-honour-line',
      link: '/widgets',
    },
  ];

  const menuItems = [
    {
      label: 'Explore',
      isHeader: true,
    },
    {
      id: 'dashboard',
      label: 'Vertical Performance',
      link: '/vertical-summary',
      icon: 'ri-dashboard-2-line',
      subItems: [
        {
          id: 'ellipsis',
          label: 'Ellipsis',
          link: '/apps-ellipsis-summary',
          parentId: 'dashboard',
          childItems: [
            {
              id: 'db_1',
              label: 'My Dashboard',
              link: '/my-dashboard',
              parentId: 'ellipsis',
              childItems: [
                {
                  id: 'db_1a',
                  label: 'My Profile',
                  link: '/pages-profile',
                  parentId: 'ellipsis',
                },
                {
                  id: 'db_1b',
                  label: 'My Profile Settings',
                  link: '/pages-profile-settings',
                  parentId: 'ellipsis',
                },
              ],
            },
            {
              id: 'db_2',
              label: 'My Contracts',
              link: '/my-contracts',
              parentId: 'ellipsis',
              childItems: [
                {
                  id: 'db_2a',
                  label: 'Contract Portfolio',
                  link: '/contract-portfolio',
                  parentId: 'db_2',
                },
                {
                  id: 'db_2b',
                  label: 'Asset Portfolio',
                  link: '/asset-portolio',
                  parentId: 'db_2',
                },
                {
                  id: 'db_2c',
                  label: 'My Products',
                  link: '/my-products',
                  parentId: 'db_2',
                },
              ],
            },
            {
              id: 'db_3',
              label: 'My Lineage',
              link: '/my-lineage',
              parentId: 'ellipsis',
              childItems: [
                {
                  id: 'db_3a',
                  label: 'My Contract Lineage',
                  link: '/pages-lineage',
                  parentId: 'db_3',
                },
              ],
            },
            {
              id: 'db_4',
              label: 'My Communication',
              link: '/my-communication',
              parentId: 'ellipsis',
              childItems: [
                {
                  id: 'db_4a',
                  label: 'My Chats',
                  link: '/apps-chat',
                  parentId: 'ellipsis',
                },
                {
                  id: 'db_4b',
                  label: 'Support Team',
                  link: '/support-team',
                  parentId: 'ellipsis',
                },
                {
                  id: 'db_5',
                  label: 'Search',
                  link: '/pages-search-results',
                  parentId: 'ellipsis',
                },
                {
                  id: 'db_5a',
                  label: 'Knowledge Base',
                  link: '/ellipsis',
                  parentId: 'db_5',
                },
              ],
            },
          ],
        },
        {
          id: 'vault',
          label: 'My Vault',
          link: '/my-vault',
          parentId: 'dashboard',
          childItems: [
            {
              id: 'ell_1',
              label: 'Vault Details',
              link: '/apps-crypto-ico',
              parentId: 'capital',
              childItems: [
                {
                  id: 'ell_1a',
                  label: 'My Watchlist',
                  link: '/my-watchlist',
                  parentId: 'ell_1',
                },
                {
                  id: 'ell_1b',
                  label: 'My Recent Orders',
                  link: '/apps-crypto-orders',
                  parentId: 'ell_1',
                },
                {
                  id: 'ell_1c',
                  label: 'My Orders',
                  link: '/my-orders',
                  parentId: 'ell_4',
                },
                {
                  id: 'ell_1d',
                  label: 'My Learning',
                  link: '/my-learning',
                  parentId: 'ell_4',
                },
              ],
            },
          ],
        },
        {
          id: 'identity',
          label: 'Identity',
          link: '/dashboard-identity',
          parentId: 'dashboard',
          childItems: [
            {
              id: 'id_1',
              label: 'Identity Summary',
              link: '/apps-projects-overview',
              parentId: 'identity',
              childItems: [
                {
                  id: 'id_1a',
                  label: 'My Identity',
                  link: '/forms-wizard',
                  parentId: 'id_1',
                },
                {
                  id: 'id_1b',
                  label: 'My ISP',
                  link: '/isp',
                  parentId: 'id_1',
                },
                {
                  id: 'id_1c',
                  label: 'Global Identities',
                  link: '/global-identities',
                  parentId: 'id_1',
                },
              ],
            },
            {
              id: 'id_2a',
              label: 'My Organization',
              link: '/my-organization',
              parentId: 'id_1',
              childItems: [
                {
                  id: 'id_2b',
                  label: 'Global Orgnizations ',
                  link: '/finance/global-org',
                  parentId: 'id_1',
                },
                {
                  id: 'id_2c',
                  label: 'Create / Edit Organization',
                  link: '/finance/org-page',
                  parentId: 'id_1',
                },
                {
                  id: 'id_2d',
                  label: 'Review / Edit Organization',
                  link: '/apps-crm-companies',
                  parentId: 'id_1',
                },
              ],
            },
            {
              id: 'id_3',
              label: 'Identity Compliance',
              link: '/identity-compliance',
              parentId: 'identity',
              childItems: [
                {
                  id: 'id_3a',
                  label: 'Identity KYC',
                  link: '/apps-crypto-kyc',
                  parentId: 'id_3',
                },
                {
                  id: 'id_3b',
                  label: 'Identity AML',
                  link: '/identity-aml',
                  parentId: 'id_3',
                },
                {
                  id: 'id_3c',
                  label: 'Identity Verification',
                  link: '/forms-validation',
                  parentId: 'id_3',
                },
                {
                  id: 'id_3d',
                  label: 'Identity Resolution',
                  link: '/identity-resolution',
                  parentId: 'id_3',
                },
              ],
            },
            {
              id: 'id_4',
              label: 'Social Identity',
              link: '/social-identity',
              parentId: 'identity',
              childItems: [
                {
                  id: 'id_4a',
                  label: 'Social Chat',
                  link: '/social-chat',
                  parentId: 'id_4',
                },
                {
                  id: 'id_4c',
                  label: 'Social Community',
                  link: 'https://app.crwd.world',
                  parentId: 'id_4',
                },
              ],
            },
          ],
        },
        {
          id: 'markets',
          label: 'Markets',
          link: '/dashboard-markets',
          parentId: 'dashboard',
          childItems: [
            {
              id: 'mkt_1',
              label: 'Market Summary',
              link: '/market-summary',
              parentId: 'markets',
              childItems: [
                {
                  id: 'mkt_1a',
                  label: 'My Cart',
                  link: '/my-cart',
                  parentId: 'mkt_1',
                },
                {
                  id: 'mkt_1b',
                  label: 'My Orders',
                  link: '/my-orders-list',
                  parentId: 'mkt_1',
                },
                {
                  id: 'mkt_1c',
                  label: 'My Offers',
                  link: '/apps-nft-explore',
                  parentId: 'mkt_1',
                },
              ],
            },
            {
              id: 'mkt_2',
              label: 'Commercial Markets',
              link: '/markets/marketplaces',
              parentId: 'markets',
              childItems: [
                {
                  id: 'mkt_2a',
                  label: 'Marketshop Builder',
                  link: 'https://vertical-dev.crwd.world/markets/shop',
                  parentId: 'mkt_2',
                },
                {
                  id: 'mkt_2b',
                  label: 'Marketshops',
                  link: '/apps-ecommerce-sellers',
                  parentId: 'mkt_2',
                },
                {
                  id: 'mkt_2c',
                  label: 'Marketshop Sellers',
                  link: '/marketshop-sellers',
                  parentId: 'mkt_2',
                },
                {
                  id: 'mkt_2d',
                  label: 'Marketshop Seller Details',
                  link: '/marketshop-details',
                  parentId: 'mkt_2',
                },
                {
                  id: 'mkt_2e',
                  label: 'Marketshop Showcase',
                  link: '/apps-ecommerce-customers',
                  parentId: 'mkt_2',
                },
                {
                  id: 'mkt_2f',
                  label: 'Marketshop Product Add',
                  link: '/apps-ecommerce-add-product',
                  parentId: 'mkt_2',
                },
                {
                  id: 'mkt_2g',
                  label: 'Marketshop Product Details',
                  link: '/apps-ecommerce-product-details',
                  parentId: 'mkt_2',
                },
              ],
            },
            {
              id: 'mkt_3',
              label: 'Shop Transactions',
              link: '/charts-apex-mixed',
              parentId: 'markets',
              childItems: [
                {
                  id: 'mkt_3a',
                  label: 'Orders',
                  link: '/apps-ecommerce-orders',
                  parentId: 'mkt_3',
                },
                {
                  id: 'mkt_3b',
                  label: 'Order Details',
                  link: '/apps-ecommerce-order-details',
                  parentId: 'mkt_3',
                },
                {
                  id: 'mkt_3c',
                  label: 'Order Customers',
                  link: '/apps-ecommerce-customers',
                  parentId: 'mkt_3',
                },
                {
                  id: 'mkt_3d',
                  label: 'Order Deals',
                  link: '/apps-crm-deals',
                  parentId: 'mkt_3',
                },
                {
                  id: 'mkt_3e',
                  label: 'Order Leads',
                  link: '/apps-crm-leads',
                  parentId: 'mkt_3',
                },
              ],
            },
          ],
        },
        {
          id: 'capital',
          label: 'Capital',
          link: '/dashboard-capital',
          parentId: 'dashboard',
          childItems: [
            {
              id: 'cpt_1',
              label: 'Capital Summary',
              link: '/apps-projects-overview',
              parentId: 'capital',
              childItems: [
                {
                  id: 'cpt_1a',
                  label: 'Capital Transactions',
                  link: '/markets/marketplaces',
                  parentId: 'cpt_1',
                },
                {
                  id: 'cpt_1b',
                  label: 'Reg D Equity Issuance',
                  link: '/charts-apex-mixed',
                  parentId: 'cpt_1',
                },
                {
                  id: 'cpt_1c',
                  label: 'Reg CF Equity Issuance',
                  link: '/apps-ecommerce-sellers',
                  parentId: 'cpt_1',
                },
                {
                  id: 'cpt_1d',
                  label: 'Reg A Equity Issuance',
                  link: '/apps-ecommerce-sellers',
                  parentId: 'cpt_1',
                },
                {
                  id: 'cpt_1e',
                  label: 'Mergers & Acquisition',
                  link: '/apps-ecommerce-seller-details',
                  parentId: 'cpt_1',
                },
                {
                  id: 'cpt_1f',
                  label: 'Tangible Capital',
                  link: '/apps-ecommerce-customers',
                  parentId: 'cpt_1',
                },
                {
                  id: 'cpt_1g',
                  label: 'Natural Resource Assets',
                  link: '/apps-ecommerce-add-product',
                  parentId: 'cpt_1',
                },
                {
                  id: 'cpt_1h',
                  label: 'ESG Assets',
                  link: '/charts-apex-mixed',
                  parentId: 'cpt_1',
                },
              ],
            },
            {
              id: 'cpt_2',
              label: 'Commodities',
              link: '/charts-apex-mixed',
              parentId: 'capital',
              childItems: [
                {
                  id: 'cpt_2a',
                  label: 'Agricultural Commodities',
                  link: '/charts-apex-mixed',
                  parentId: 'cpt_2',
                },
                {
                  id: 'cpt_2b',
                  label: 'Energy Commodities',
                  link: '//charts-apex-mixed',
                  parentId: 'cpt_2',
                },
                {
                  id: 'cpt_2c',
                  label: 'Metals',
                  link: '/charts-apex-mixed',
                  parentId: 'cpt_2',
                },
                {
                  id: 'cpt_2d',
                  label: 'Soft Commodities',
                  link: '/charts-apex-mixed',
                  parentId: 'cpt_2',
                },
                {
                  id: 'cpt_2e',
                  label: 'Livestock & Meat',
                  link: '/charts-apex-mixed',
                  parentId: 'cpt_2',
                },
                {
                  id: 'cpt_2f',
                  label: 'Precious Stones',
                  link: '/charts-apex-mixed',
                  parentId: 'cpt_2',
                },
                {
                  id: 'cpt_2g',
                  label: 'Industrial & Chemicals',
                  link: '/charts-apex-mixed',
                  parentId: 'cpt_2',
                },
                {
                  id: 'cpt_2h',
                  label: 'Water',
                  link: '/charts-apex-mixed',
                  parentId: 'cpt_2',
                },
              ],
            },
          ],
        },

        {
          id: 'finance',
          label: 'Finance',
          link: '/dashboard-finance',
          parentId: 'dashboard',
          childItems: [
            {
              id: 'fin_1',
              label: 'Financial Summary',
              link: '/apps-projects-overview',
              parentId: 'finance',
              childItems: [
                {
                  id: 'fin_1a',
                  label: 'My Securities Watchlist',
                  link: '/securities-watchlist',
                  parentId: 'fin_1',
                },
                {
                  id: 'fin_1b',
                  label: 'DPO Enrollment',
                  link: '/finance/DPO-enrollment',
                  parentId: 'fin_1',
                },
                {
                  id: 'fin_1c',
                  label: 'DPO Facts',
                  link: '/dpo-facts',
                  parentId: 'fin_1',
                },
                {
                  id: 'fin_1d',
                  label: 'Press Release',
                  link: '/apps-projects-list',
                  parentId: 'fin_1',
                },
              ],
            },
            {
              id: 'fin_2',
              label: 'GICS Public Securities',
              link: '/gics-pubic-securities',
              parentId: 'Finance',
              childItems: [
                {
                  id: 'fin_2a',
                  label: 'GICS Private Securities',
                  link: '/finance/property-tickers',
                  parentId: 'fin_2',
                },
                {
                  id: 'fin_2b',
                  label: 'DPO Activity',
                  link: '/finance/DPO',
                  parentId: 'fin_2',
                },
                {
                  id: 'fin_2c',
                  label: 'Digital Assets',
                  link: '/digital-assets',
                  parentId: 'mkt_3',
                },
              ],
            },
          ],
        },
        {
          id: 'Private Market Metrics',
          label: 'Private Market',
          link: '/dashboard-finance',
          parentId: 'dashboard',
          childItems: [
            {
              id: 'pmm_1',
              label: 'Deal Overview',
              link: '/apps-projects-overview',
              parentId: 'Private Market Metrics',
              childItems: [
                {
                  id: 'pmm_1a',
                  label: 'Deal Flow',
                  link: '/charts-apex-mixed',
                  parentId: 'pmm_1',
                },
                {
                  id: 'pmm_1b',
                  label: 'Valuation Trend',
                  link: '/charts-apex-mixeds',
                  parentId: 'pmm_1',
                },
                {
                  id: 'pmm_1c',
                  label: 'Fundraising Performance',
                  link: '/charts-apex-mixed',
                  parentId: 'pmm_1',
                },
                {
                  id: 'pmm_1d',
                  label: 'Exit Activity',
                  link: '/charts-apex-mixed',
                  parentId: 'pmm_1',
                },
              ],
            },
            {
              id: 'pmm_1e',
              label: 'Portolio Performance',
              link: '/charts-apex-mixed',
              parentId: 'pmm_1',
              childItems: [
                {
                  id: 'pmm_1f',
                  label: 'Sector Allocation',
                  link: '/finance/total-sector-activity',
                  parentId: 'pmm_1',
                },
                {
                  id: 'pmm_1g',
                  label: 'Geographic Distribution',
                  link: '/finance/DPO',
                  parentId: 'pmm_1',
                },
                {
                  id: 'pmm_1h',
                  label: 'Deal Size Distribution',
                  link: '/finance/DPO',
                  parentId: 'pmm_1',
                },
                {
                  id: 'pmm_1i',
                  label: 'Capital Deployment',
                  link: '/finance/DPO',
                  parentId: 'pmm_1',
                },
                {
                  id: 'pmm_1j',
                  label: 'Investment Performance',
                  link: '/finance/DPO',
                  parentId: 'pmm_1',
                },
                {
                  id: 'pmm_1k',
                  label: 'Forcasts',
                  link: '/finance/DPO',
                  parentId: 'pmm_1',
                },
              ],
            },
          ],
        },
      ],
    },

    {
      id: 'apps',
      label: 'Apps',
      icon: 'ri-apps-2-line',
      link: '/#',
      click: function (e) {
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'calendar',
          label: 'Calendar',
          link: '/apps-calendar',
          parentId: 'apps',
        },
        {
          id: 'chat',
          label: 'Chat',
          link: '/apps-chat',
          parentId: 'apps',
        },
        {
          id: 'mailbox',
          label: 'Direct Message',
          link: '/#',
          parentId: 'apps',
          isChildItem: true,

          childItems: [
            {
              id: 1,
              label: 'In Box',
              link: '/apps-mailbox',
              parentId: 'mailbox',
            },
            {
              id: 'messageTemplates',
              label: 'Message Templates',
              link: '/#',
              parentId: 'mailbox',
              isChildItem: true,
              childItems: [
                {
                  id: 1,
                  label: 'Basic Action',
                  link: '/apps-email-basic',
                  parentId: 'messageTemplates',
                },
                {
                  id: 2,
                  label: 'Ecommerce Action',
                  link: '/apps-email-ecommerce',
                  parentId: 'messageTemplates',
                },
              ],
            },
          ],
        },
        {
          id: 'tasks',
          label: 'Systems',
          link: '/#',
          isChildItem: true,
          parentId: 'apps',
          childItems: [
            {
              id: 1,
              label: 'Vogon',
              link: '#',
              parentId: 'tasks',
              isChildItem: true,
              childItems: [
                {
                  id: 1,
                  label: 'Vogon Viewer',
                  link: '/vogon-viewer',
                  parentId: 'tasks',
                },
                {
                  id: 2,
                  label: 'Vogon Browser',
                  link: '/vogon-browser',
                  parentId: 'tasks',
                },
                {
                  id: 'gaao',
                  label: 'GAAO',
                  link: '/#',
                  parentId: 'tasks',
                  isChildItem: true,
                  childItems: [
                    {
                      id: 1,
                      label: 'Energy',
                      link: '/gaao/energy',
                      parentId: 'gaao',
                    },

                    {
                      id: 2,
                      label: 'Materials',
                      link: '/gaao/materials',
                      parentId: 'gaao',
                    },

                    {
                      id: 3,
                      label: 'Industrials',
                      link: '/gaao/industrials',
                      parentId: 'gaao',
                    },

                    {
                      id: 4,
                      label: 'Consumer Discretionary',
                      link: '/gaao/consumer-discretionary',
                      parentId: 'gaao',
                    },

                    {
                      id: 5,
                      label: 'Consumer Staples',
                      link: '/gaao/consumer-staples',
                      parentId: 'gaao',
                    },

                    {
                      id: 6,
                      label: 'Health Care',
                      link: '/gaao/health-care',
                      parentId: 'gaao',
                    },

                    {
                      id: 7,
                      label: 'Financials',
                      link: '/gaao/financials',
                      parentId: 'gaao',
                    },

                    {
                      id: 8,
                      label: 'Information Technology',
                      link: '/gaao/information-technology',
                      parentId: 'gaao',
                    },

                    {
                      id: 9,
                      label: 'Communication Services',
                      link: '/gaao/communication-services',
                      parentId: 'gaao',
                    },

                    {
                      id: 10,
                      label: 'Utilities',
                      link: '/gaao/utilities',
                      parentId: 'gaao',
                    },

                    {
                      id: 11,
                      label: 'Real Estate',
                      link: '/gaao/real-estate',
                      parentId: 'gaao',
                    },
                  ],
                },
              ],
            },
            {
              id: 1,
              label: 'Kanban Board',
              link: '/apps-tasks-kanban',
              parentId: 'tasks',
            },
            {
              id: 2,
              label: 'List View',
              link: '/apps-tasks-list-view',
              parentId: 'tasks',
            },
            {
              id: 3,
              label: 'Task Details',
              link: '/apps-tasks-details',
              parentId: 'tasks',
            },
          ],
        },
        {
          id: 'invoices',
          label: 'Invoices',
          link: '/#',
          isChildItem: true,
          parentId: 'apps',
          childItems: [
            {
              id: 1,
              label: 'List View',
              link: '/apps-invoices-list',
              parentId: 'invoices',
            },
            {
              id: 2,
              label: 'Details',
              link: '/apps-invoices-details',
              parentId: 'invoices',
            },
            {
              id: 3,
              label: 'Create Invoice',
              link: '/apps-invoices-create',
              parentId: 'invoices',
            },
          ],
        },
      ],
    },
    { ...adminMenu },
    {
      label: 'Administrative',
      isHeader: true,
      icon: 'ri-dashboard-2-line',
      link: '/#',
      click: function (e) {
        setIsAdministrative(!isAdministrative);
        updateIconSidebar(e);
      },
    },
  ];

  if (isAdministrative) {
    menuItems.push(...getAdministrativeMenu);
  }
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
