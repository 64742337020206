import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { GraphQLClient } from 'graphql-request';
import { getSdk, Sdk } from 'graphql/generated/graphqlRequest';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAuth } from './auth';
import { loginApi, signupApi } from 'utils/api';

const graphqlUrl = process.env.REACT_APP_GRAPHQL_URL as string;
const jwtClaimsKey = process.env.REACT_APP_JWT_CLAIMS_KEY as string;

const middleware = (error: any, logout?: () => void): void => {
  if (error.message.includes('Could not verify JWT')) {
    logout?.();
  }
};

export function clientWithToken(token?: string): GraphQLClient {
  const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

  return new GraphQLClient(graphqlUrl, { headers });
}

export function sdkClientWithToken(token?: string): Sdk {
  return getSdk(clientWithToken(token));
}

export interface SignupInput {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  phone: string;
}

export interface UserContextType {
  token?: string;
  isFetchingToken: boolean;
  userEmail?: string;
  userId?: string;
  userRole?: string;
  client: GraphQLClient;
  sdkClient: Sdk;
  loginWithCredentials: (email: string, password: string) => Promise<string>;
  signup: (signupInput: SignupInput) => Promise<void>;
  logout: (returnPath?: string) => void;
}

export const UserContext = createContext<UserContextType | null>(null);

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === null) {
    throw new Error('useUserContext must be used within a UserProvider');
  }

  return context;
};
export const useClient = (): GraphQLClient => useUserContext().client;

function userIdFromToken(token?: string): string | undefined {
  if (!token) {
    return undefined;
  }
  return jwtDecode<Record<string, any>>(token)[jwtClaimsKey]?.['x-hasura-user-id'];
}

function userRoleFromToken(token?: string): string | undefined {
  if (!token) {
    return undefined;
  }
  return jwtDecode<Record<string, any>>(token)[jwtClaimsKey]?.['x-hasura-default-role'];
}

function userPrimaryEmailFromToken(token?: string): string | undefined {
  if (!token) {
    return undefined;
  }
  return jwtDecode<Record<string, any>>(token).email;
}

export const UserProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<string | undefined>(sessionStorage.getItem('idToken') || undefined);
  // const [displayLogin, setDisplayLogin] = useState(false);
  const [isFetchingToken, setIsFetchingToken] = useState(false);
  const [callbackUrl, setCallbackUrl] = useState('');
  const auth = useAuth();

  // const login = useCallback(() => {
  //   if (!token) {
  //     setDisplayLogin(true);
  //   }
  // }, [token]);

  const loginWithCredentials = async (email: string, password: string): Promise<string> => {
    const idToken = await loginApi(email, password);
    setToken(idToken);
    sessionStorage.setItem('idToken', idToken);
    return idToken;
  };

  const signup = async (signupInput: SignupInput): Promise<void> => signupApi(signupInput);

  const logout = useCallback((_returnPath?: string): void => {
    setToken(undefined);
    sessionStorage.removeItem('idToken');
  }, []);

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: error => middleware(error, logout),
    }),
  });

  useEffect(() => {
    if (auth.user?.id_token && auth.user?.expired) {
      logout();
    }
  }, [auth.user?.expired, auth.user?.id_token, logout]);

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      const { source, payload } = event.data;
      if (event.origin !== window.location.origin || source !== 'auth-redirect') {
        return;
      }
      if (payload.url) {
        setCallbackUrl(payload.url);
      }
    };

    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  useEffect(() => {
    if (callbackUrl && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      setIsFetchingToken(true);
      auth.handleCallbackUrl(callbackUrl);
      setCallbackUrl('');
    }
  }, [callbackUrl, auth]);

  const userContextValue: UserContextType = {
    token,
    isFetchingToken,
    userEmail: userPrimaryEmailFromToken(token),
    userId: userIdFromToken(token),
    userRole: userRoleFromToken(token),
    client: clientWithToken(token),
    sdkClient: sdkClientWithToken(token),
    loginWithCredentials,
    signup,
    logout,
  };

  return (
    <UserContext.Provider value={userContextValue}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </UserContext.Provider>
  );
};
