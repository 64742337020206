const MarketReports = ({ details }) => {
  return (
    <>
      <h2>Required Market Reports</h2>
      <div className="document-cards">
        {details.requiredMarketReports.map((report, index) => (
          <div className="document-card" key={index}>
            <a href={report.link}>{report.name}</a>
            <p>{report.description}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default MarketReports;
