import { Player } from '@lottiefiles/react-lottie-player';
import classnames from 'classnames';
import { useState } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { useForm } from 'react-hook-form';
import sha256 from 'crypto-js/sha256';
import { esRequest } from 'utils/api';

import _ from 'lodash';
import { AdminInputCountry, AdminInputEmail, AdminInputPhone, AdminInputState, AdminInputText } from 'Components/Admin';
import { Button } from 'primereact/button';
import './index.styled.scss';
import { CountryFieldsFragment } from 'graphql/generated/graphqlRequest';
import { toast } from 'react-toastify';
import {
  useUpsertSigmaPrimeMutation,
  useUpsertUserMutation,
  // useUpsertSigmaPrimeAddressMutation,
  SigmaPrimeInsertInput,
} from 'graphql/generated/resourceApi';
import { useUserContext } from 'shared/UserContext';
import { SigmaPrimeAddressInitialValues, SigmaPrimeInitialValues, UserInitialValues } from './affliate';
import { useAuth } from 'shared/auth';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const AffiliatePage = () => {
  const { client } = useUserContext();
  const auth = useAuth();
  const sigmaPrimeUpsertState = useUpsertSigmaPrimeMutation(client);
  const userUpsertState = useUpsertUserMutation(client);
  // const sigmaPrimeAddressState = useUpsertSigmaPrimeAddressMutation(client);
  const { control, trigger, getValues, setValue } = useForm({ mode: 'onChange' });
  const [tabState, setTabState] = useState<{
    prevIndex: number;
    currIndex: number;
    done: number[];
    submitted: boolean;
  }>({
    prevIndex: -1,
    currIndex: 0,
    done: [],
    submitted: false,
  });

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const [country, setCountry] = useState<CountryFieldsFragment>();
  const [geocode, setGeocode] = useState<any>();

  const tabs = ['Generate Token', 'Contact Information', 'Product Enrollment', 'Complete Registration'];

  const onTabNextOrClick = async (nextIndex: number, submitted = false) => {
    const nextTabState = {
      ...tabState,
      prevIndex: tabState.currIndex,
      currIndex: nextIndex,
      done: _.uniq([...tabState.done, tabState.currIndex]),
    };

    if (submitted) _.assign(nextTabState, { submitted });

    // console.log(nextTabState);
    setTabState(nextTabState);
  };

  const [generatedToken, setGeneratedToken] = useState({ isLoading: false, token: '' });
  const [sigmaPrimeObj, setSigmaPrimeObj] = useState<SigmaPrimeInsertInput>();
  const getGeneratedToken = async () => {
    const result = await trigger('personPrimaryEmail');
    const email = getValues('personPrimaryEmail');
    if (result) {
      try {
        setGeneratedToken({ ...generatedToken, isLoading: true });

        const spRes = await sigmaPrimeUpsertState.mutateAsync({
          objects: { ...SigmaPrimeInitialValues, personPrimaryEmail: email },
        });
        const spObj = spRes?.upsertSigmaPrime?.returning[0];
        setSigmaPrimeObj(spObj as SigmaPrimeInsertInput);
        const sha = sha256(`${email}`);
        const userRes = await userUpsertState.mutateAsync({
          objects: {
            ...UserInitialValues,
            sigmaPrimeId: spObj?.id,
            rememberToken: sha.toString(),
            uniqueHash: spObj?.id,
          },
        });
        const uObj = userRes?.upsertUser?.returning[0];
        setValue('rememberToken', uObj?.rememberToken);
        toast.success('Token generated sucessfully !');
        setGeneratedToken({ ...generatedToken, token: uObj?.rememberToken!, isLoading: false });
      } catch (error) {
        console.log(error.response?.errors[0]?.message);
        toast.error(error.response?.errors[0]?.message);
        setGeneratedToken({ ...generatedToken, isLoading: false });
      }
    }
  };

  const [emailSend, setEmailSend] = useState({ isLoading: false, isSend: false });
  const sendEmailToken = async () => {
    const result = await trigger('rememberToken');
    if (result) {
      const userEmail = getValues('personPrimaryEmail');

      const rememberToken = getValues('rememberToken');
      console.log(rememberToken);
      // TODO: improve this email send
      fetch('https://user-api.crowdpoint.tech/v1/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query:
            'mutation sendEmail($replyToEmail:String!, $fromEmail:String!, $toEmail:String!, $subject:String!, $htmlMessage:String!) {\n  sendEmail(\n    replyToEmail:$replyToEmail,\n    fromEmail:$fromEmail,\n    toEmail:$toEmail,\n    subject:$subject,\n    htmlMessage:$htmlMessage\n  ) {\n    status\n  }\n}',
          variables: {
            replyToEmail: 'support@crowdpoint.tech',
            fromEmail: 'support@crowdpoint.tech',
            toEmail: userEmail,
            subject: 'test',
            htmlMessage: `<div>Hi, Your remember token is <strong>${rememberToken}</strong></div>`,
          },
          operationName: 'sendEmail',
        }),
      });
      //Email send code will be here
      toast.success('Token email send sucessfully !');
      setEmailSend({ ...emailSend, isSend: true });
    }
  };

  const getGeoCode = () => {
    const { personCountryCode, personProvinceState, personCity, personPostal, personAddress1 } = getValues();
    try {
      esRequest(
        'geo',
        {
          country: personCountryCode,
          city: personCity,
          state: personProvinceState,
          zip: personPostal,
          address: `${personAddress1}`,
        },
        {
          headers: {
            authorization: `Bearer ${auth.user?.id_token}`,
          },
        },
      ).then(response => {
        if (response?.data) {
          setGeocode(response.data.geo);
        }
      });
    } catch (error) {
      setGeocode('Not Found');
    }
  };

  const [isContactInfoSaving, setContactInfoSaving] = useState(false);
  const saveContaceInformation = async () => {
    const result = await trigger([
      'personFirstName',
      'personMiddleName',
      'personLastName',
      'personFamilyName',
      'personHandleName',
      'personAddress1',
      'personAddress2',
      'personCountryCode',
      'personProvinceState',
      'personCity',
      'personRegionCounty',
      'personPostal',
      'personPostal4',
      'personPrimaryMobile',
    ]);

    if (result) {
      const {
        personFirstName,
        personMiddleName,
        personLastName,
        personFamilyName,
        personHandleName,
        personPrimaryMobile,
        personPrimaryEmail,
        rememberToken,
        ...restValues
      } = getValues();

      try {
        setContactInfoSaving(true);

        const spPayload = {
          ...sigmaPrimeObj,
          personFirstName,
          personMiddleName,
          personLastName,
          personFamilyName,
          personHandleName,
          personPrimaryMobile,
          personExchangeRequest: 'd',
        };
        console.log(spPayload);
        const spRes = await sigmaPrimeUpsertState.mutateAsync({ objects: spPayload });
        console.log('Response : ', spRes);

        const spaPayload = {
          ...SigmaPrimeAddressInitialValues,
          ...restValues, //Form fields value
          person_address1: restValues.personAddress1,
          person_address2: restValues.personAddress2,
          person_first_name: personFirstName,
          person_middle_name: personMiddleName,
          person_last_name: personLastName,
          person_postal: restValues.personPostal,
          person_postal4: restValues.personPostal4,
          person_province_state: restValues.personProvinceState,
          sigmaPrimeId: sigmaPrimeObj?.id, //SP id from sigmaPrimeObj
        };
        console.log(spaPayload);
        const spaRes = esRequest(
          `esmaster/crwd_wallet_sigmaprime_address_ui/_doc`,
          { ...spaPayload },
          {
            headers: {
              authorization: `Bearer ${auth.user?.id_token}`,
            },
          },
        ).then(() => {
          console.log('Response : ', spaRes);

          toast.success('Contact information saved sucessfully !');
          setContactInfoSaving(false);
          onTabNextOrClick(2);
        });
      } catch (error) {
        console.log(error.response?.errors[0]?.message);
        toast.error(error.response?.errors[0]?.message);
        setContactInfoSaving(false);
      }
    }
  };

  // const contactInfoFields = [
  //   { label: 'First Name', name: 'firstName' },
  //   { label: 'Middle Name', name: 'middleName' },
  //   { label: 'Last Name', name: 'lastName' },
  //   { label: 'Family Name', name: 'familyName' },
  //   { label: 'Handle Name', name: 'handleName' },
  //   { label: 'Address 1', name: 'address1' },
  //   { label: 'Address 2', name: 'address2' },
  // ];

  return (
    <Card className="m-0">
      {/* <CardHeader>
        <h4 className="card-title mb-0">Create Organization</h4>
      </CardHeader> */}
      <CardBody className={`form-steps ${query.get('theme')}`} style={{ background: query.get('bg') || 'inherite' }}>
        <form className="vertical-navs-step">
          <Row className="gy-5">
            <Col lg={3}>
              <Nav className="flex-column custom-nav nav-pills">
                {tabs.map((tabName, index) => {
                  return (
                    <NavItem key={index}>
                      <NavLink
                        disabled={
                          !tabState.done.includes(index) &&
                          // tabState.currIndex + 1 != index &&
                          tabState.currIndex != index
                        }
                        active={tabState.currIndex === index}
                        className={classnames({
                          done: tabState.done.includes(index),
                        })}
                        onClick={() => index != tabState.currIndex && onTabNextOrClick(index)}
                        href="#"
                      >
                        <span className="step-title me-2">
                          <i className={'ri-close-circle-fill step-icon me-2'}></i>
                          Step {index + 1}
                        </span>
                        {tabName}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
            </Col>
            <Col lg={9}>
              <TabContent activeTab={tabState.currIndex}>
                <TabPane tabId={0}>
                  <div className="grid">
                    <div className="col-6">
                      <AdminInputEmail label="Email" name="personPrimaryEmail" control={control} required />
                    </div>
                    <div className="col-2">
                      <Button
                        className="p-button-success custm-btn"
                        type="button"
                        label="Generate"
                        loading={generatedToken.isLoading}
                        onClick={getGeneratedToken}
                      />
                    </div>
                    {generatedToken.token && (
                      <>
                        <div className="col-6">
                          <AdminInputText label="Remember Token" name="rememberToken" control={control} disabled />
                        </div>
                        <div className="col-2">
                          <Button
                            className="p-button-success custm-btn"
                            type="button"
                            label="Email Token"
                            loading={emailSend.isLoading}
                            onClick={sendEmailToken}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="d-flex align-items-start justify-content-end gap-3 mt-4">
                    {emailSend.isSend && (
                      <Button
                        className="p-button-success custm-btn"
                        type="button"
                        label="Next"
                        icon="pi pi-arrow-right"
                        iconPos="right"
                        onClick={() => onTabNextOrClick(1)}
                      />
                    )}
                  </div>
                </TabPane>
                <TabPane tabId={1}>
                  <div className="grid">
                    <div className="col-3">
                      <AdminInputText label="First Name" name="personFirstName" control={control} required />
                    </div>
                    <div className="col-3">
                      <AdminInputText label="Middle Name" name="personMiddleName" control={control} />
                    </div>
                    <div className="col-3">
                      <AdminInputText label="Last Name" name="personLastName" control={control} />
                    </div>
                    <div className="col-3">
                      <AdminInputText label="Family Name" name="personFamilyName" control={control} />
                    </div>
                    <div className="col-3">
                      <AdminInputText label="Handle Name" name="personHandleName" control={control} required />
                    </div>
                    <div className="col-3">
                      <AdminInputText label="Address 1" name="personAddress1" control={control} required />
                    </div>
                    <div className="col-3">
                      <AdminInputText label="Address 2" name="personAddress2" control={control} />
                    </div>
                    <div className="col-3">
                      <AdminInputCountry
                        label="Contry Code"
                        name="personCountryCode"
                        control={control}
                        required
                        onCountrySelect={setCountry}
                      />
                    </div>
                    <div className="col-3">
                      <AdminInputState
                        label="Province State"
                        name="personProvinceState"
                        control={control}
                        required
                        countryCode={country?.code}
                      />
                    </div>
                    <div className="col-3">
                      <AdminInputText label="City" name="personCity" control={control} />
                    </div>
                    <div className="col-3">
                      <AdminInputText label="Region County" name="personRegionCounty" control={control} />
                    </div>
                    <div className="col-3">
                      <AdminInputText label="Postal" name="personPostal" control={control} />
                    </div>
                    <div className="col-3">
                      <AdminInputText label="Postal 4" name="personPostal4" control={control} />
                    </div>
                    <div className="col-3">
                      <AdminInputPhone label="Primary Mobile" name="personPrimaryMobile" control={control} required />
                    </div>
                    <div className="col-6">
                      {!geocode ? (
                        <Button
                          type="button"
                          label="Calculate Latitude and Longitude"
                          onClick={getGeoCode}
                          className="p-button-link p-button-success custm-btn2"
                        />
                      ) : (
                        <div className="geocode">{geocode}</div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-start justify-content-between gap-3 mt-4">
                    <Button
                      className="p-button-secondary custm-btn2"
                      type="button"
                      label="Back"
                      icon="pi pi-arrow-left"
                      iconPos="left"
                      onClick={() => setTabState({ ...tabState, currIndex: tabState.currIndex - 1 })}
                    />
                    <Button
                      className="p-button-success custm-btn2"
                      type="button"
                      label="Next"
                      icon="pi pi-arrow-right"
                      iconPos="right"
                      loading={isContactInfoSaving}
                      onClick={saveContaceInformation}
                    />
                  </div>
                </TabPane>
                <TabPane tabId={2}>
                  <div className="grid">
                    <div className="col-3 py-7">Not added yet</div>
                  </div>
                  <div className="d-flex align-items-start justify-content-between gap-3 mt-4">
                    <Button
                      className="p-button-secondary custm-btn2"
                      type="button"
                      label="Back"
                      icon="pi pi-arrow-left"
                      iconPos="left"
                      onClick={() => setTabState({ ...tabState, currIndex: tabState.currIndex - 1 })}
                    />
                    <Button
                      className="p-button-success custm-btn2"
                      type="button"
                      label="Next"
                      icon="pi pi-arrow-right"
                      iconPos="right"
                      onClick={() => onTabNextOrClick(3)}
                    />
                  </div>
                </TabPane>
                <TabPane tabId={3}>
                  <div className="text-center pt-4 pb-2">
                    <div className="mb-4">
                      <Player
                        autoplay
                        loop
                        src="https://cdn.lordicon.com/lupuorrc.json"
                        style={{ width: '120px', height: '120px' }}
                      />
                    </div>
                    <h5>Done !</h5>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </form>
      </CardBody>
    </Card>
  );
};

export default AffiliatePage;
