// Actions
export const API_RESPONSE_SUCCESS = 'API_RESPONSE_SUCCESS';
export const API_RESPONSE_ERROR = 'API_RESPONSE_ERROR';
export const GET_INVOICES = 'GET_INVOICES';

// Add Invoice

export const ADD_NEW_INVOICE = 'ADD_NEW_INVOICE';
export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS';
export const ADD_INVOICE_FAIL = 'ADD_INVOICE_FAIL';

// Edit Invoice

export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_FAIL = 'UPDATE_INVOICE_FAIL';

// Delete Invoice

export const DELETE_INVOICE = 'DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAIL = 'DELETE_INVOICE_FAIL';
