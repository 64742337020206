const OperationalReports = ({ details }) => {
  return (
    <>
      <h2>Required Operational Reports</h2>
      <div className="card-container">
        {details.requiredOperationalReports.map((report, index) => (
          <div key={index} className="card">
            <a href={report.link} className="card-title">
              {report.name}
            </a>
            <p className="card-description">{report.description}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default OperationalReports;
