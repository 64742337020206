import React from 'react';
import { CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { useHistory } from 'react-router-dom';
import Masonry from 'react-masonry-component';
import { gics, gics_logo } from '../../../../common/data';
import { NoDataTemplate } from 'Components/NoDataTemplate';
import LinkTemplate from './LinkTemplate';

export const GICS = () => {
  document.title = 'GICS | crwdcapital - universal wallet';

  const history = useHistory();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="GICS" pageTitle="Marketplaces" />
          <Row>
            <Col lg={12}>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    {gics.length ? (
                      <Masonry className="row gallery-wrapper">
                        {gics.map(({ id, img, title, links }, key) => (
                          <Col xxl={3} xl={3} sm={6} className="element-item project designing development" key={key}>
                            <LinkTemplate
                              img={img}
                              title={title}
                              onClick={() => links.length && history.push(`${history.location.pathname}/${id}`)}
                            />
                          </Col>
                        ))}
                        <Col
                          xxl={3}
                          xl={3}
                          sm={6}
                          className="element-item project designing development"
                          key={gics.length}
                        >
                          <LinkTemplate
                            img={gics_logo.img}
                            title={gics_logo.title}
                            onClick={() => history.push(`/markets/marketplaces`)}
                          />
                        </Col>
                      </Masonry>
                    ) : (
                      <NoDataTemplate />
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
