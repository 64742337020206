import React, { useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { useDataAdapter } from 'hooks/useDataAdapter';
import { useParams } from 'react-router-dom';
import { startCase, camelCase } from 'lodash';
import Listing from 'Components/List';
import { Toast } from 'primereact/toast';
import EntityRecordAdd from 'Components/EntityRecord/Add';
import './index.styled.scss';
import MutationDocs from 'Components/EntityRecord/Generic/mutationDoc';
import FragmentsDocs from 'Components/List/fragmentDoc';
import { getEntityFields, Schema } from 'utils/schema/index';
import { Dialog } from 'primereact/dialog';

const ListPage: React.FC = () => {
  const [showCreate, setShowCreate] = useState(false);
  const { table } = useParams() as any;
  const toast = useRef<any>(null);
  const entityName: string = startCase(table).replace(/\s/g, '');
  const entityNameWithSpace: string = startCase(table);
  const entityNameCamelCase = camelCase(entityName);
  const entityFragments: any = FragmentsDocs[entityName];
  const { tableAdapter } = useDataAdapter(entityNameCamelCase, entityFragments);
  const isMutationAvailable = MutationDocs[entityName];

  const dataTable = useRef<any>(null);

  if (!Schema[entityName]) {
    return (
      <Card>
        No json found for{' '}
        <b>
          <i>{entityName}</i>
        </b>{' '}
        table!
      </Card>
    );
  }

  const columns = getEntityFields(
    entityName,
    ['updatedAt', 'isDeleted'],
    true, //hiding uuid type columns
  );

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Toast ref={toast} />

        <Card
          className="list-card"
          header={
            <Toolbar
              left={<h1 className="flex justify-start text-xl uppercase m-0">{entityNameWithSpace}</h1>}
              right={
                <div className="flex gap-3">
                  <Button
                    icon="pi pi-cloud-download"
                    className="p-button-sm p-button-rounded p-button-secondary"
                    onClick={() => {
                      dataTable?.current?.exportCSV();
                    }}
                  />
                  <Button
                    icon="pi pi-plus"
                    className="p-button-sm p-button-rounded p-button-success"
                    onClick={() => setShowCreate(true)}
                  />
                </div>
              }
            />
          }
        >
          <Listing tableAdapter={tableAdapter} entityName={entityName} ref={dataTable} />
        </Card>

        {isMutationAvailable && (
          <Dialog
            visible={showCreate}
            onHide={() => setShowCreate(false)}
            breakpoints={{ '960px': '80vw', '640px': '90vw' }}
            style={{
              maxHeight: '95%',
              height: '95%',
              width: columns.filter(col => ['col-3', 'col-4', 'col-5'].includes(col.cssStyle)).length
                ? '80vw'
                : columns.filter(col => ['col-6', 'col-7', 'col-8'].includes(col.cssStyle)).length
                ? '70vw'
                : '50vw',
            }}
            // closable={!entityUpsertState.isLoading}
            dismissableMask={true}
            header={`Add ${startCase(entityName)}`}
          >
            <EntityRecordAdd
              entityName={entityName}
              onSubmitSuccess={() => {
                // console.log(result);
                toast.current?.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Record created successfully..',
                });
                setShowCreate(false);
                tableAdapter.reload();
              }}
              onSubmitError={error => {
                // console.log(error);
                toast.current?.show({
                  severity: 'error',
                  summary: 'Error',
                  detail: error.response?.errors[0]?.message,
                });
              }}
            />
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default ListPage;
