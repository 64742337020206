import React, { useRef, useState } from 'react';
import { DataTable, DataTableFilterMeta, DataTablePFSEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AdminTable } from 'Components/Admin/AdminTable/AdminTable';
import { AdminTableAdapter } from 'Components/Admin/AdminTable/AdminTableAdapter';
import { Toast } from 'primereact/toast';
import { Menu } from 'primereact/menu';
import { AvatarGroup } from 'primereact/avatargroup';
import { Avatar } from 'primereact/avatar';
import parse from 'html-react-parser';

import EntityRecordEdit from 'Components/EntityRecord/Edit';
import EntityRecordView from 'Components/EntityRecord/View';
import MutationDocs from 'Components/EntityRecord/Generic/mutationDoc';
import { format } from 'date-fns';
import EntityRecordDeletePopup from 'Components/EntityRecord/Delete';
import { EntityFieldProps, getEntityFields } from 'utils/schema';
import NoDataImg from '../../assets/images/nodata2.png';
import { startCase, get } from 'lodash';
import { Dialog } from 'primereact/dialog';
import { createFilters, getFieldName } from './listUtils';

interface TableProps {
  tableAdapter: AdminTableAdapter;
  entityName: string;
}

interface RecordPopup {
  rowData: any;
  visible: boolean;
}

const Listing = React.forwardRef<DataTable, TableProps>((props, dataTable) => {
  const [viewRecordState, setViewRecordState] = useState<RecordPopup>({
    rowData: null,
    visible: false,
  });

  const [editRecordState, setEditRecordState] = useState<RecordPopup>({
    rowData: null,
    visible: false,
  });

  const [deleteRecordState, setDeleteRecordState] = useState<RecordPopup>({
    rowData: null,
    visible: false,
  });

  const toast = useRef<any>(null);

  const isMutationAvailable = MutationDocs[props.entityName];

  const columns = getEntityFields(
    props.entityName,
    ['updatedAt', 'isDeleted'],
    true, //hiding uuid type columns
  );

  const [filters, setFilters] = useState<DataTableFilterMeta>(createFilters(columns));

  const onFilter = (e: DataTablePFSEvent) => {
    setFilters(f => {
      return {
        ...f,
        ...e.filters,
      };
    });
  };

  const ActionBodyTemplate = (rowData: any) => {
    const listActionMenu = useRef<any>(null);

    let items = [
      {
        label: 'View',
        command: () =>
          setViewRecordState(prevState => ({
            ...prevState,
            visible: true,
            rowData,
          })),
      },
      {
        label: 'Edit',
        command: () =>
          setEditRecordState(prevState => ({
            ...prevState,
            visible: true,
            rowData,
          })),
      },
      {
        label: 'Delete',
        command: () =>
          setDeleteRecordState(prevState => ({
            ...prevState,
            visible: true,
            rowData,
          })),
      },
    ];

    return (
      <div className="list-action cursor-pointer">
        <i className="pi pi-ellipsis-v" onClick={event => listActionMenu?.current.toggle(event)} />
        <Menu model={items} popup ref={listActionMenu} />
      </div>
    );
  };

  const noDataTemplate = () => (
    <img
      src={NoDataImg}
      style={{
        width: '300px',
        margin: '30px calc(((100vw - 240px) / 2) - 170px)',
      }}
    />
  );

  const fieldTemplate = (rowData: any, column: EntityFieldProps) => {
    const columnName = getFieldName(column);

    if (rowData[columnName] !== undefined) {
      switch (column.type) {
        case 'Object':
          return get(rowData[columnName], column.entityFieldLabel, column.blankValuePlaceholder);
        case 'timestamptz':
          return format(new Date(rowData[columnName]), 'MM/dd/yyyy');
        case 'Boolean':
          return rowData[columnName] ? 'True' : 'False';
        case 'Image':
          const uploadEndpoint = process.env.REACT_APP_FILE_URL;
          let images: string[] = [];
          try {
            images = JSON.parse(rowData[columnName]) || [];
          } catch (_error) {}
          return (
            <AvatarGroup>
              {images.map(image => (
                <Avatar image={`${uploadEndpoint}/?id=${image}`} size="large" shape="circle" />
              ))}
            </AvatarGroup>
          );
        case 'Richtext':
          return <div className="cell-data">{parse(rowData[columnName] || '')}</div>;
        default:
          return <div className="cell-data">{rowData[columnName]}</div>;
      }
    }

    return '';
  };

  const esIndex = entityName => {
    return entityName === 'SigmaPrimeAddress' ? 'crwd_wallet_sigmaprime_address' : '';
  };

  return (
    <>
      <Toast ref={toast} />

      <AdminTable
        filterDisplay="menu"
        size="small"
        sortField="updatedAt"
        sortOrder={-1}
        filters={filters}
        onFilter={onFilter}
        ref={dataTable}
        elasticIndex={esIndex(props.entityName)}
        adapter={props.tableAdapter}
        emptyMessage={noDataTemplate}
      >
        <Column body={ActionBodyTemplate} exportable={false} style={{ width: '1rem' }} />
        {columns.map(column => {
          const columnName = getFieldName(column);
          return (
            <Column
              key={columnName}
              field={columnName}
              header={column.label}
              sortable={column.type !== 'Object'}
              filter={!!filters[columnName]}
              body={rowData => fieldTemplate(rowData, column)}
              dataType={column.type === 'timestamptz' ? 'date' : undefined}
              filterType={column.type === 'timestamptz' ? 'date' : undefined}
            />
          );
        })}
      </AdminTable>

      {isMutationAvailable && (
        <Dialog
          visible={editRecordState.visible}
          onHide={() =>
            setEditRecordState(prevState => ({
              ...prevState,
              visible: false,
            }))
          }
          breakpoints={{ '960px': '80vw', '640px': '90vw' }}
          style={{
            maxHeight: '95%',
            height: '95%',
            width: columns.filter(col => ['col-3', 'col-4', 'col-5'].includes(col.cssStyle)).length
              ? '80vw'
              : columns.filter(col => ['col-6', 'col-7', 'col-8'].includes(col.cssStyle)).length
              ? '70vw'
              : '50vw',
          }}
          // closable={!entityUpsertState.isLoading}
          dismissableMask={true}
          header={`Update ${startCase(props.entityName)}`}
        >
          <EntityRecordEdit
            rowData={editRecordState.rowData}
            entityName={props.entityName}
            onSubmitSuccess={() => {
              setEditRecordState(prevState => ({
                ...prevState,
                visible: false,
              }));
              toast.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Record updated successfully..',
              });
              props.tableAdapter.reload();
            }}
            onSubmitError={(error: any) => {
              toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.response?.errors[0]?.message,
              });
            }}
          />
        </Dialog>
      )}

      {isMutationAvailable && (
        <Dialog
          visible={viewRecordState.visible}
          onHide={() =>
            setViewRecordState(prevState => ({
              ...prevState,
              visible: false,
            }))
          }
          breakpoints={{ '960px': '80vw', '640px': '90vw' }}
          style={{
            maxHeight: '95%',
            height: '95%',
            width: columns.filter(col => ['col-3', 'col-4', 'col-5'].includes(col.cssStyle)).length
              ? '80vw'
              : columns.filter(col => ['col-6', 'col-7', 'col-8'].includes(col.cssStyle)).length
              ? '70vw'
              : '50vw',
          }}
          // closable={!entityUpsertState.isLoading}
          dismissableMask={true}
          header={`View ${startCase(props.entityName)}`}
        >
          <EntityRecordView rowData={viewRecordState.rowData} entityName={props.entityName} />
        </Dialog>
      )}

      {isMutationAvailable && (
        <EntityRecordDeletePopup
          rowData={deleteRecordState.rowData}
          entityName={props.entityName}
          visible={deleteRecordState.visible}
          onHide={() =>
            setDeleteRecordState(prevState => ({
              ...prevState,
              visible: false,
            }))
          }
          onDeleteSuccess={() => {
            // console.log(result);
            setDeleteRecordState(prevState => ({
              ...prevState,
              visible: false,
            }));
            toast.current?.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Record deleted successfully..',
            });
            props.tableAdapter.reload();
          }}
          onDeleteError={(error: any) => {
            console.log(error);
            toast.current?.show({
              severity: 'error',
              summary: 'Error',
              detail: error.response?.errors[0]?.message,
            });
          }}
        />
      )}
    </>
  );
});

export default Listing;
