import React, { useEffect, useState } from 'react';
import { AutoComplete, AutoCompleteCompleteMethodParams, AutoCompleteProps } from 'primereact/autocomplete';
import { Controller } from 'react-hook-form';
import { nanoid } from 'nanoid';
import { CountryFieldsFragment } from 'graphql/generated/resourceApi';
import { AdminInputBaseProps, buildClassName, validateProps } from '../AdminForm';
import { useUserContext } from 'shared/UserContext';
// import dataCountries from "../../data/countries.json";
// import { AddressCountry } from "../../types";

const searchCountry = (
  query: string | CountryFieldsFragment,
  dataCountries: CountryFieldsFragment[],
): {
  country: CountryFieldsFragment | undefined;
  filteredCountries: CountryFieldsFragment[];
} => {
  if (query === undefined) {
    return { country: undefined, filteredCountries: dataCountries };
  }

  const normalizedQuery = typeof query === 'string' ? query.toLowerCase().trim() : query.name.toLowerCase().trim();

  let found: CountryFieldsFragment | undefined = dataCountries.find(
    country =>
      country.name.toLowerCase() === normalizedQuery ||
      country.iso3?.toLowerCase() === normalizedQuery ||
      country.code.toLowerCase() === normalizedQuery,
  );
  const filteredCountries = dataCountries.filter(
    country =>
      country.name.toLowerCase().includes(normalizedQuery) ||
      country.iso3?.toLowerCase().includes(normalizedQuery) ||
      country.code.toLowerCase().includes(normalizedQuery),
  );
  if (filteredCountries.length === 1) {
    found = filteredCountries[0] as CountryFieldsFragment;
  }

  return { country: found, filteredCountries };
};

export type AdminInputCountryProps = AutoCompleteProps &
  AdminInputBaseProps & {
    defaultCountryCode?: string;
    onCountrySelect?: React.Dispatch<React.SetStateAction<CountryFieldsFragment | undefined>>;
    autofill?: boolean;
  };

const AdminInputCountry: React.FC<AdminInputCountryProps> = props => {
  validateProps(props);

  const {
    control,
    name,
    label,
    helpText,
    containerClassName,
    required,
    attributeType,
    defaultCountryCode,
    isLabelFloatable,
    onCountrySelect,
    autofill,
    ...baseProps
  } = props;

  const [id] = useState(nanoid());
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [hasFocus, setHasFocus] = useState(false);
  const [allCountries, setAllCountries] = useState<CountryFieldsFragment[]>([]);
  const [countries, setCountries] = useState<CountryFieldsFragment[]>([]);
  const [currentInput, setCurrentInput] = useState(() => {
    const { country } = searchCountry(defaultCountryCode || '', allCountries);
    return country?.name || '';
  });

  const { sdkClient } = useUserContext();

  useEffect(() => {
    sdkClient.countries().then(c => {
      setAllCountries(c.countries);
    });
  }, []);

  const nameValue = attributeType?.name || name;
  const priorityLabel = attributeType?.label ?? label ?? name;

  const countryOptionTemplate = (option: CountryFieldsFragment): JSX.Element => (
    <div className="flex items-center">
      <img
        alt={option.name}
        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${option.code.toLocaleUpperCase()}.svg`}
        width={25}
        className={`flag flag-${option.code.toLowerCase()} mr-3`}
      />
      <div className="mr-2">{option.name}</div>
      <div>({option.code})</div>
    </div>
  );

  const fieldProps = {
    ...baseProps,
    ...attributeType?.props,
  };

  useEffect(() => {
    if (autofill !== false) {
      const countryInput = document.getElementById(id)?.firstElementChild;
      if (countryInput) {
        countryInput.setAttribute('autocomplete', 'country');
      }
    }
  }, [autofill, id]);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  return (
    <Controller
      control={control}
      name={nameValue}
      rules={{
        required,
        ...attributeType?.validation,
      }}
      render={({ field, fieldState, formState: _formState }) => {
        if (field.value && !currentInput && isFirstRender) {
          const { country } = searchCountry(field.value, allCountries);
          onCountrySelect?.(country);
          setCurrentInput(country?.name || '');
        }

        const errorMessage = fieldState.error?.message || fieldState.error?.type;

        return (
          <div className={containerClassName}>
            <div className={isLabelFloatable ? 'p-float-label' : ''}>
              <label className="p-inputtext-label">
                {priorityLabel}
                {required && <i className="text-red">*</i>}
              </label>
              <AutoComplete
                {...fieldProps}
                id={id}
                className={buildClassName(fieldProps.className, errorMessage)}
                value={currentInput}
                field="name"
                dropdown
                delay={0}
                suggestions={countries}
                completeMethod={(e: AutoCompleteCompleteMethodParams) => {
                  setCurrentInput(e.query);
                  const { country, filteredCountries } = searchCountry(e.query, allCountries);
                  setCountries(filteredCountries);
                  onCountrySelect?.(country);
                  if (country) {
                    field.onChange(country.code);
                  }

                  if (!hasFocus) {
                    // is autofill
                    if (country) {
                      setCurrentInput(country.name);
                      setCountries([]);
                    }
                  }
                }}
                itemTemplate={countryOptionTemplate}
                aria-label={nameValue}
                onChange={e => {
                  setCurrentInput(e.value?.name || e.value);
                  const { country } = searchCountry(e.value, allCountries);
                  onCountrySelect?.(country);
                  if (country) {
                    field.onChange(country.code);
                  }
                  if (!e.value) {
                    field.onChange(null);
                  }
                }}
                onFocus={() => {
                  setHasFocus(true);
                }}
                onBlur={() => {
                  setHasFocus(false);
                  const { country } = searchCountry(currentInput, allCountries);
                  onCountrySelect?.(country);
                  if (country) {
                    field.onChange(country.code);
                    setCurrentInput(country.name || '');
                  } else {
                    setCurrentInput('');
                  }
                }}
              />
            </div>
            <small id={`${nameValue}-help`} className="p-d-block">
              {helpText}
            </small>
            <small className="p-error p-d-block">{errorMessage}</small>
          </div>
        );
      }}
    />
  );
};

export default AdminInputCountry;
