import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { useState } from 'react';
import ReactDropzone, { IDropzoneProps, IFileWithMeta, IUploadParams, StatusValue } from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import { useUserContext } from 'shared/UserContext';
import CustomPreview from './CustomPreview';
import { Box } from '@material-ui/core';
import { useEffect } from 'react';

// prevents 'Element type is invalid' error in build
const Dropzone = (ReactDropzone as any).default ? (ReactDropzone as any).default : ReactDropzone;

//
// == TYPES ==
//
export interface FileUploaderProps extends Partial<IDropzoneProps> {
  label?: string;
  required?: boolean;
  /**
   * Full URL of where to upload
   * eg: https://example.com/uploads
   *
   */
  uploadEndpoint?: string;
  /**
   * Allows you to override all params (including uploadEndpoint if provided)
   * Defaults to a PUT with the user bearer authorization and passes along those defaults
   */
  uploadParamsOverride?: (file: IFileWithMeta, defaultParams: IUploadParams) => IUploadParams;
  /**
   * Event handler that fires when upload is done
   */
  onUploaded: (file: IFileWithMeta) => void;
  /**
   * For a preexisting file so we can display the ID
   */
  fileId?: string;

  /**
   * Event handler that receives all status changes
   *
   * Example workflow of meta changes (besides errors):
   * select/drag file then
   * - preparing
   * - ready
   * click x
   *    - removed
   * click play/upload
   *    - started
   *    - getting_upload_params
   *    - uploading
   *    - headers_received
   *    - done
   */
  onChangeStatus?: (file: IFileWithMeta, meta: StatusValue, allFiles: IFileWithMeta[]) => void;
  /**
   * May want to expand to allow more detailed styles
   */
  errorBorder?: boolean;
  /**
   * File type(s) to accept
   */
  accept?: string;
  /**
   * For preexisting files
   */
  initialFiles?: File[];

  /**
   * Defaults to false, allows you to make file uploaded public
   */
  isPublic?: boolean;
  /**
   * Ref for calling image upload
   */
  uploadImageRef?: any;

  /**
   * Edit loading state
   */
  loadingFn?: () => void;
  multiple?: boolean;
  disabled?: boolean;
}

//
// VARIABLES
//
const MISSING_URL_ERROR_TEXT = 'Missing props for FileUploader. Include either: uploadEndpoint OR uploadParamsOverride';

/**
 * A component for uploading a SINGLE file at a time (for now)
 * @param props
 * @returns
 */
function FileUploader(props: FileUploaderProps): JSX.Element {
  const {
    label,
    required,
    uploadEndpoint = process.env.REACT_APP_FILE_URL,
    uploadParamsOverride,
    onUploaded,
    onChangeStatus,
    errorBorder,
    accept,
    initialFiles,
    isPublic = true,
    multiple = false,
    uploadImageRef,
    loadingFn,
    inputContent,
    fileId,
    disabled,
    ...passthroughProps
  } = props;
  const { token } = useUserContext();

  if (!uploadEndpoint && !uploadParamsOverride) {
    throw new Error(MISSING_URL_ERROR_TEXT);
  }

  // TODO:ENHANCE make overriding styles easier
  const styles = {
    preview: {
      // if there's only 1 item it doesn't fill the box, this fixes it.
      flexGrow: 1,
      paddingLeft: '2rem',
      paddingRight: '2rem',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    dropzone: {
      overflow: 'auto',
      borderWidth: '',
      background: 'var(--form-background)',
      border: '1px dashed var(--form-border)',
      borderRadius: 'var(--border-radius)',
    },
  };

  if (errorBorder) {
    // @ts-ignore
    styles.dropzone = {
      // @ts-ignore
      borderColor: 'red',
      borderWidth: '1px',
      // minWidth: '20rem',
      overflow: 'auto',
    };
  }

  let filesWithMeta: IFileWithMeta[] | null = null;

  function callUpload(): void {
    if (filesWithMeta) {
      filesWithMeta.forEach((file: IFileWithMeta) => {
        if (file?.meta?.status !== 'removed') {
          file.restart();
        }
      });
    }
  }

  const [enableEditing, setEditing] = useState(false);
  const [uploadReadyFiles, setUploadReadyFiles] = useState([]) as any;
  const [finishedFiles, setFinishedFiles] = useState([]) as any;
  let images: string[] = [];
  if (fileId?.startsWith('http')) {
    images = [fileId];
  } else if (fileId) {
    try {
      const parsedImagePath = (JSON.parse(fileId) as any[]) || [];
      const uploadEndpoint = process.env.REACT_APP_FILE_URL;
      images = parsedImagePath.map(image => `${uploadEndpoint}/?id=${image}`);
    } catch {}
  }
  useEffect(() => {
    if (finishedFiles.length && finishedFiles.length === uploadReadyFiles.length) {
      onUploaded(finishedFiles);
    }
  }, [finishedFiles]);
  return (
    <>
      {label && (
        <label className="p-inputtext-label">
          {label}
          {required && <i className="text-red">*</i>}
        </label>
      )}
      {!enableEditing && fileId && (
        <div>
          <Box className="display">
            <Image src={images[0]} />
            {!disabled && (
              <Button
                onClick={() => setEditing(true)}
                icon="pi pi-pencil"
                className="p-button-rounded p-button-text absolute bottom right"
              />
            )}
          </Box>
        </div>
      )}
      {(enableEditing || !fileId) && (
        <Dropzone
          autoUpload={false}
          multiple={multiple}
          styles={styles}
          initialFiles={initialFiles}
          // customized to always disable the buttons on this project
          PreviewComponent={(previewProps: any) => <CustomPreview {...previewProps} disableUpload />}
          accept={accept || '*'}
          submitButtonDisabled={true}
          getUploadParams={(p: IFileWithMeta): IUploadParams => {
            if (loadingFn) {
              loadingFn();
            }
            if (!uploadEndpoint) {
              throw new Error(MISSING_URL_ERROR_TEXT);
            }

            const uploadParams: IUploadParams = {
              url: `${uploadEndpoint}/${p.meta.name}`,
              method: 'PUT',
              headers: {
                'Content-Type': p.meta.type,
              },
              body: p.file,
            };

            // tell API to make file publicly accessible if necessary
            if (isPublic) {
              uploadParams.url = `${uploadParams.url}?is_public=true`;
            } else {
              uploadParams.headers = {
                ...uploadParams.headers,
                Authorization: `Bearer ${token}`,
              };
            }

            if (uploadParamsOverride) {
              return uploadParamsOverride(p, uploadParams);
            }
            return uploadParams;
          }}
          onChangeStatus={(file: IFileWithMeta, meta: StatusValue, allFiles: IFileWithMeta[]) => {
            console.log(file);
            console.log(allFiles);
            if (allFiles[0].meta.status !== 'removed') {
              uploadImageRef.current = callUpload;
              filesWithMeta = allFiles;
            } else {
              uploadImageRef.current = null;
            }
            if (file.meta.status === 'preparing') {
              const alreadyIncluded = uploadReadyFiles.find((uploadedFile: any) => uploadedFile.id === file.meta.id);
              if (!alreadyIncluded) {
                setUploadReadyFiles([...uploadReadyFiles, file.meta]);
              }
            }

            if (onChangeStatus) {
              onChangeStatus(file, meta, allFiles);
            }
            // StatusValue is not an enum unfortunately as of Oct 2021
            if (meta === 'done') {
              const alreadyFinished = finishedFiles.find((finishedFile: any) => finishedFile.id === file.meta.id);
              if (!alreadyFinished) {
                const response = JSON.parse(file?.xhr?.responseText!);
                console.log(response);
                setFinishedFiles([...finishedFiles, response.id]);
              }
              // setTimeout(() => {
              //   if (finishedFiles.length === uploadReadyFiles.length) {
              //     onUploaded(finishedFiles, meta, allFiles);
              //   }
              // });
            }
          }}
          inputContent={
            <div className="text-center">
              <i className="display-6 text-muted ri-upload-cloud-2-fill" />
              <p className="text-muted text-sm m-0 mt-1">Drag & Drop File or Click to Browse</p>
            </div>
          }
          {...passthroughProps}
        />
      )}
    </>
  );
}

export default FileUploader;
