import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';

// Import Chart
import { PopularityChart } from './DashboardNFTCharts';

//SimpleBar
import SimpleBar from 'simplebar-react';
import { Link, useHistory } from 'react-router-dom';
import { useClient } from '../../shared/UserContext';
import { useGetAskByAssetQuery } from '../../graphql/generated/resourceApi';

const Popularity = ({ assetId, dashboardPageId }: { assetId: string; dashboardPageId: 'ESG | ALL' | 'crwdunit' }) => {
  const client = useClient();
  const history = useHistory();
  const askByAssetQueryState = useGetAskByAssetQuery(client, { assetId: assetId });
  const asks = (askByAssetQueryState.data?.getAskByAsset || []).filter(type => !['formId'].includes(type.name));
  const goToDetails = ask => history.push(`/ask-details/${dashboardPageId}/${ask.id}`);
  return (
    <React.Fragment>
      <Col xxl={3}>
        <Card>
          <CardHeader className="border-0 pb-0">
            <h6 className="card-title mb-0">Popularity</h6>
          </CardHeader>
          <div>
            <PopularityChart dataColors='["--vz-success", "--vz-warning"]' />
          </div>
        </Card>

        <Card>
          <CardHeader className="d-flex align-items-center">
            <h6 className="card-title mb-0 flex-grow-1">History of Bids / Asks</h6>
            <Link className="text-muted" to="/asks">
              See All <i className="ri-arrow-right-line align-bottom"></i>
            </Link>
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <SimpleBar style={{ maxHeight: '365px' }}>
                <ul className="list-group list-group-flush">
                  {asks?.map((item, key) => (
                    <li key={key} className="list-group-item list-group-item-action" onClick={() => goToDetails(item)}>
                      <div className="d-flex align-items-center">
                        <div className="circle ask"></div>
                        <div className="ms-2 flex-grow-1 my-auto">
                          <Link to="#!" className="stretched-link">
                            <h6 className="fs-14 truncate-text m-0">{item.name}</h6>
                          </Link>
                          {/* <p className="mb-0 text-muted">{item.username}</p> */}
                        </div>
                        <h6 className="m-0 p-0">{item.askNumber}</h6>
                      </div>
                    </li>
                  ))}
                </ul>
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Popularity;
