import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Link } from 'react-router-dom';

import { topLineage } from '../../../common/data';

const MyLineage = () => {
  document.title = 'CONTRACT & LINEAGE | crwdcapital - universal wallet';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="CONTRACT & LINEAGE" pageTitle="My Contract" />
          <Row className="g-4 mb-3 align-items-center">
            <Col className="col-sm-auto">
              <div>
                <h5 className="mb-0">Top LINEAGE</h5>
              </div>
            </Col>
            <Col className="col-sm">
              <div className="d-flex justify-content-sm-end gap-2">
                <div className="search-box ms-2">
                  <input type="text" className="form-control" placeholder="Search..." />
                  <i className="ri-search-line search-icon"></i>
                </div>
                <div className="choices">
                  <select className="form-control w-md">
                    <option value="All">All</option>
                    <option value="Today" defaultValue>
                      Today
                    </option>
                    <option value="Yesterday">Yesterday</option>
                    <option value="Last 7 Days">Last 7 Days</option>
                    <option value="Last 30 Days">Last 30 Days</option>
                    <option value="This Month">This Month</option>
                    <option value="Last Year">Last Year</option>
                  </select>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            {topLineage.map((item, key) => (
              <Col lg={3} key={key}>
                <Card>
                  <CardBody>
                    <Row className="g-1 mb-3">
                      {item.subItem.map((item, key) => (
                        <Col lg={12} key={key}>
                          {item.isChildren.map((item, key) => (
                            <img src={item.img} alt="" className={'img-fluid rounded ' + item.imgClass} key={key} />
                          ))}
                        </Col>
                      ))}
                    </Row>
                    <Link to="#!" className="float-end">
                      {' '}
                      View All <i className="ri-arrow-right-line align-bottom"></i>
                    </Link>
                    <h5 className="mb-0 fs-16">
                      <Link to="#!">
                        {item.category} <span className="badge badge-soft-success">{item.quantity}</span>
                      </Link>
                    </h5>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <Row className="g-0 text-center text-sm-start align-items-center mb-4">
            <Col sm={6}>
              <div>
                <p className="mb-sm-0 text-muted">
                  Showing <span className="fw-semibold">1</span> to <span className="fw-semibold">16</span> of{' '}
                  <span className="fw-semibold text-decoration-underline">16</span> entries
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyLineage;
