import { useEffect, useState } from 'react';
import Lineage from './index.ui';
import _ from 'lodash';
import { useUserContext } from 'shared/UserContext';
import { useGetSmartContractLineagesQuery } from 'graphql/generated/resourceApi';

const LineageHOC = () => {
  const { client } = useUserContext();
  const { data, error, isLoading } = useGetSmartContractLineagesQuery(client);

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data) {
      const qData = data?.smartContractLineage;

      //Removing secondry parent(Logical issue)
      let filterData = qData.filter(obj => _.map(qData, 'user.uniqueHash'));
      let lineages = filterData.map(obj => {
        return {
          id: obj.user.uniqueHash,
          parentId: obj.parentUser?.uniqueHash,
          rememberToken: obj.user.rememberToken,
          name: obj.user.sigmaPrime
            ? `${obj.user.sigmaPrime.personFirstName || ''} ${obj.user.sigmaPrime.personLastName || ''}`
            : '',
          _expanded: true,
        };
      });
      console.log(lineages);
      setChartData([...lineages]);
    }
  }, [data]);

  return <Lineage chartData={chartData} loading={isLoading} error={error} />;
};

export default LineageHOC;
