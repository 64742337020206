import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

// Import Images
import multiUser from '../../assets/images/users/multi-user.jpg';

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  ModalBody,
  Label,
  Input,
  Modal,
  ModalHeader,
  Form,
  ModalFooter,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DeleteModal from '../../Components/Common/DeleteModal';
import { isEmpty } from 'lodash';

//Import actions
import {
  getCompanies as onGetCompanies,
  addNewCompanies as onAddNewCompanies,
  updateCompanies as onUpdateCompanies,
  deleteCompanies as onDeleteCompanies,
} from '../../store/actions';
//redux
import { useSelector, useDispatch } from 'react-redux';
import TableContainer from '../../Components/Common/TableContainer';

// Formik
import * as Yup from 'yup';
import { useFormik } from 'formik';

// Export Modal
import ExportCSVModal from '../../Components/Common/ExportCSVModal';

import Loader from '../../Components/Common/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CrmCompanies = () => {
  const dispatch = useDispatch();
  const { companies, isCompaniesCreated, isCompaniesSuccess, error } = useSelector(state => ({
    companies: state.Crm.companies,
    isCompaniesCreated: state.Crm.isCompaniesCreated,
    isCompaniesSuccess: state.Crm.isCompaniesSuccess,
    error: state.Crm.error,
  }));

  useEffect(() => {
    if (companies && !companies.length) {
      dispatch(onGetCompanies());
    }
  }, [dispatch, companies]);

  useEffect(() => {
    setCompany(companies);
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(companies)) {
      setCompany(companies);
      setIsEdit(false);
    }
  }, [companies]);

  const [isEdit, setIsEdit] = useState(false);
  const [company, setCompany] = useState([]);

  //delete Company
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [modal, setModal] = useState(false);

  const industrytype = [
    {
      options: [
        { label: 'Select industry type', value: 'Select industry type' },
        { label: 'Computer Industry', value: 'Computer Industry' },
        { label: 'Chemical Industries', value: 'Chemical Industries' },
        { label: 'Health Services', value: 'Health Services' },
        {
          label: 'Telecommunications Services',
          value: 'Telecommunications Services',
        },
        {
          label: 'Textiles: Clothing, Footwear',
          value: 'Textiles: Clothing, Footwear',
        },
      ],
    },
  ];

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCompany(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // Delete Data
  const handleDeleteCompany = () => {
    if (company) {
      dispatch(onDeleteCompanies(company._id));
      setDeleteModal(false);
    }
  };

  const onClickDelete = company => {
    setCompany(company);
    setDeleteModal(true);
  };

  // Add Data
  const handleCompanyClicks = () => {
    setCompany('');
    setIsEdit(false);
    toggle();
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (company && company.img) || '',
      name: (company && company.name) || '',
      owner: (company && company.owner) || '',
      industry_type: (company && company.industry_type) || '',
      star_value: (company && company.star_value) || '',
      location: (company && company.location) || '',
      employee: (company && company.employee) || '',
      website: (company && company.website) || '',
      contact_email: (company && company.contact_email) || '',
      since: (company && company.since) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Company Name'),
      owner: Yup.string().required('Please Enter Owner name'),
      industry_type: Yup.string().required('Please Enter Industry Type'),
      star_value: Yup.string().required('Please Enter Rating'),
      location: Yup.string().required('Please Enter Location'),
      employee: Yup.string().required('Please Enter Employee'),
      website: Yup.string().required('Please Enter Website'),
      contact_email: Yup.string().required('Please Enter Contact Email'),
      since: Yup.string().required('Please Enter Since'),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateCompany = {
          _id: company ? company._id : 0,
          // img: values.img,
          name: values.name,
          owner: values.owner,
          industry_type: values.industry_type,
          star_value: values.star_value,
          location: values.location,
          employee: values.employee,
          website: values.website,
          contact_email: values.contact_email,
          since: values.since,
        };
        // update Company
        dispatch(onUpdateCompanies(updateCompany));
        validation.resetForm();
      } else {
        const newCompany = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values['name'],
          owner: values['owner'],
          industry_type: values['industry_type'],
          star_value: values['star_value'],
          location: values['location'],
          employee: values['employee'],
          website: values['website'],
          contact_email: values['contact_email'],
          since: values['since'],
        };
        // save new Company
        dispatch(onAddNewCompanies(newCompany));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Update Data
  const handleCompanyClick = useCallback(
    arg => {
      const company = arg;

      setCompany({
        _id: company._id,
        // img: company.img,
        name: company.name,
        owner: company.owner,
        industry_type: company.industry_type,
        star_value: company.star_value,
        location: company.location,
        employee: company.employee,
        website: company.website,
        contact_email: company.contact_email,
        since: company.since,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle],
  );

  // Node API
  // useEffect(() => {
  //   if (isCompaniesCreated) {
  //     setCompany(null);
  //     dispatch(onGetCompanies());
  //   }
  // }, [
  //   dispatch,
  //   isCompaniesCreated,
  // ]);

  // Checked All
  const checkedAll = () => {
    const checkall = document.getElementById('checkBoxAll');
    const ele = document.querySelectorAll('.companyCheckBox');

    if (checkall.checked) {
      ele.forEach(ele => {
        ele.checked = true;
      });
    } else {
      ele.forEach(ele => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll('.companyCheckBox:checked');
    const checkall = document.getElementById('checkBoxAll');
    ele.forEach(element => {
      dispatch(onDeleteCompanies(element.value));
    });
    checkall.checked = false;
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
        Cell: cellProps => {
          return <input type="checkbox" className="companyCheckBox" value={cellProps.row.original._id} />;
        },
        id: '#',
      },
      {
        Header: 'Company Name',
        Cell: company => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {company.row.original.image_src ? (
                  <img
                    src={process.env.REACT_APP_API_URL + '/images/' + company.row.original.image_src}
                    alt=""
                    className="avatar-xxs rounded-circle"
                  />
                ) : (
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      {company.row.original.name.charAt(0)}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-grow-1 ms-2 name">{company.row.original.name}</div>
            </div>
          </>
        ),
      },
      {
        Header: 'Owner',
        accessor: 'owner',
        filterable: false,
      },
      {
        Header: 'Industry Type',
        accessor: 'industry_type',
        filterable: false,
      },
      {
        Header: 'Rating',
        accessor: 'star_value',
        filterable: false,
        Cell: cellProps => (
          <>
            {cellProps.value} <i className="ri-star-fill text-warning align-bottom"></i>
          </>
        ),
      },
      {
        Header: 'Location',
        accessor: 'location',
        filterable: false,
      },
      {
        Header: 'Action',
        Cell: cellProps => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Call">
                <Link to="#" className="text-muted d-inline-block">
                  <i className="ri-phone-line fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item edit" title="Message">
                <Link to="#" className="text-muted d-inline-block">
                  <i className="ri-question-answer-line fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="View">
                <Link
                  to="#"
                  onClick={() => {
                    const companyData = cellProps.row.original;
                    setInfo(companyData);
                  }}
                >
                  <i className="ri-eye-fill align-bottom text-muted"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Edit">
                <Link
                  className="edit-item-btn"
                  to="#"
                  onClick={() => {
                    const companyData = cellProps.row.original;
                    handleCompanyClick(companyData);
                  }}
                >
                  <i className="ri-pencil-fill align-bottom text-muted"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Delete">
                <Link
                  className="remove-item-btn"
                  onClick={() => {
                    const companyData = cellProps.row.original;
                    onClickDelete(companyData);
                  }}
                  to="#"
                >
                  <i className="ri-delete-bin-fill align-bottom text-muted"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleCompanyClick],
  );

  // SideBar Company Deatail
  const [info, setInfo] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = 'Companies | crwdcapital - universal wallet';
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={companies} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCompany}
          onCloseClick={() => setDeleteModal(false)}
        />
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />

        <Container fluid>
          <BreadCrumb title="Companies" pageTitle="CRM" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <button
                        className="btn btn-info add-btn"
                        onClick={() => {
                          setIsEdit(false);
                          toggle();
                        }}
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> Edit Company
                      </button>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        <button className="btn btn-soft-danger" onClick={() => setDeleteModalMulti(true)}>
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                        <button className="btn btn-danger">
                          <i className="ri-filter-2-line me-1 align-bottom"></i> Filters
                        </button>
                        <button className="btn btn-soft-success" onClick={() => setIsExportCSV(true)}>
                          Export
                        </button>
                        <UncontrolledDropdown>
                          <DropdownToggle href="#" className="btn-soft-info btn-icon" tag="button">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col xxl={9}>
              <Card id="companyList">
                <CardBody className="pt-0">
                  <div>
                    {isCompaniesSuccess && companies?.length ? (
                      <TableContainer
                        columns={columns}
                        data={companies || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={7}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-2"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        handleCompanyClick={handleCompanyClicks}
                        isCompaniesFilter={true}
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>
                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered size="lg">
                    <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                      {!!isEdit ? 'Edit Company' : 'Edit Company'}
                    </ModalHeader>
                    <Form
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <input type="hidden" id="id-field" />
                        <Row className="g-3">
                          <Col lg={12}>
                            <div className="text-center">
                              <div className="position-relative d-inline-block">
                                <div className="position-absolute bottom-0 end-0">
                                  <Label htmlFor="company-logo-input" className="mb-0">
                                    <div className="avatar-xs cursor-pointer">
                                      <div className="avatar-title bg-light border rounded-circle text-muted">
                                        <i className="ri-image-fill"></i>
                                      </div>
                                    </div>
                                  </Label>
                                  <Input
                                    name="img"
                                    className="form-control d-none"
                                    id="company-logo-input"
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.img || ''}
                                    invalid={validation.touched.img && validation.errors.img ? true : false}
                                  />
                                </div>
                                <div className="avatar-lg p-1">
                                  <div className="avatar-title bg-light rounded-circle">
                                    <img
                                      src={multiUser}
                                      alt="multiUser"
                                      id="companylogo-img"
                                      className="avatar-md rounded-circle object-cover"
                                    />
                                  </div>
                                </div>
                              </div>
                              <h5 className="fs-13 mt-3">Company Logo</h5>
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div>
                              <Label htmlFor="name-field" className="form-label">
                                Name
                              </Label>

                              <Input
                                name="name"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter Company Name"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ''}
                                invalid={validation.touched.name && validation.errors.name ? true : false}
                              />
                              {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label htmlFor="owner-field" className="form-label">
                                Owner Name
                              </Label>
                              <Input
                                name="owner"
                                id="owner-field"
                                className="form-control"
                                placeholder="Enter Owner Name"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.owner || ''}
                                invalid={validation.touched.owner && validation.errors.owner ? true : false}
                              />
                              {validation.touched.owner && validation.errors.owner ? (
                                <FormFeedback type="invalid">{validation.errors.owner}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label htmlFor="industry_type-field" className="form-label">
                                Industry Type
                              </Label>

                              <Input
                                name="industry_type"
                                type="select"
                                className="form-select"
                                id="industry_type-field"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.industry_type || ''}
                              >
                                {industrytype.map((item, key) => (
                                  <React.Fragment key={key}>
                                    {item.options.map((item, key) => (
                                      <option value={item.value} key={key}>
                                        {item.label}
                                      </option>
                                    ))}
                                  </React.Fragment>
                                ))}
                              </Input>
                              {validation.touched.industry_type && validation.errors.industry_type ? (
                                <FormFeedback type="invalid">{validation.errors.industry_type}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div>
                              <Label htmlFor="star_value-field" className="form-label">
                                Rating
                              </Label>
                              <Input
                                name="star_value"
                                id="star_value-field"
                                className="form-control"
                                placeholder="Enter Rating"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.star_value || ''}
                                invalid={validation.touched.star_value && validation.errors.star_value ? true : false}
                              />
                              {validation.touched.star_value && validation.errors.star_value ? (
                                <FormFeedback type="invalid">{validation.errors.star_value}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div>
                              <Label htmlFor="location-field" className="form-label">
                                location
                              </Label>
                              <Input
                                name="location"
                                id="star_value-field"
                                className="form-control"
                                placeholder="Enter Location"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.location || ''}
                                invalid={validation.touched.location && validation.errors.location ? true : false}
                              />
                              {validation.touched.location && validation.errors.location ? (
                                <FormFeedback type="invalid">{validation.errors.location}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div>
                              <Label htmlFor="employee-field" className="form-label">
                                Employee
                              </Label>
                              <Input
                                name="employee"
                                id="employee-field"
                                className="form-control"
                                placeholder="Enter employee"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.employee || ''}
                                invalid={validation.touched.employee && validation.errors.employee ? true : false}
                              />
                              {validation.touched.employee && validation.errors.employee ? (
                                <FormFeedback type="invalid">{validation.errors.employee}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label htmlFor="website-field" className="form-label">
                                Website
                              </Label>
                              <Input
                                name="website"
                                id="website-field"
                                className="form-control"
                                placeholder="Enter website"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.website || ''}
                                invalid={validation.touched.website && validation.errors.website ? true : false}
                              />
                              {validation.touched.website && validation.errors.website ? (
                                <FormFeedback type="invalid">{validation.errors.website}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label htmlFor="contact_email-field" className="form-label">
                                Contact Email
                              </Label>
                              <Input
                                name="contact_email"
                                id="contact_email-field"
                                className="form-control"
                                placeholder="Enter Contact email"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.contact_email || ''}
                                invalid={
                                  validation.touched.contact_email && validation.errors.contact_email ? true : false
                                }
                              />
                              {validation.touched.contact_email && validation.errors.contact_email ? (
                                <FormFeedback type="invalid">{validation.errors.contact_email}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div>
                              <Label htmlFor="since-field" className="form-label">
                                Since
                              </Label>
                              <Input
                                name="since"
                                id="since-field"
                                className="form-control"
                                placeholder="Enter since"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.since || ''}
                                invalid={validation.touched.since && validation.errors.since ? true : false}
                              />
                              {validation.touched.since && validation.errors.since ? (
                                <FormFeedback type="invalid">{validation.errors.since}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <Button
                            color="light"
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            {' '}
                            Close{' '}
                          </Button>
                          <Button type="submit" color="success" id="add-btn">
                            {' '}
                            {!!isEdit ? 'Update' : 'Add Company'}{' '}
                          </Button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} />
                </CardBody>
              </Card>
            </Col>
            <Col xxl={3}>
              <Card id="company-view-detail">
                <CardBody className="text-center">
                  <div className="position-relative d-inline-block">
                    <div className="avatar-md">
                      <div className="avatar-title bg-light rounded-circle">
                        <img
                          src={process.env.REACT_APP_API_URL + '/images/' + (info.image_src || 'brands/mail_chimp.png')}
                          alt=""
                          className="avatar-sm rounded-circle object-cover"
                        />
                      </div>
                    </div>
                  </div>
                  <h5 className="mt-3 mb-1">{info.name || 'Syntyce Solution'}</h5>
                  <p className="text-muted">{info.owner || 'Michael Morris'}</p>

                  <ul className="list-inline mb-0">
                    <li className="list-inline-item avatar-xs">
                      <Link to="#" className="avatar-title bg-soft-success text-success fs-15 rounded">
                        <i className="ri-global-line"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                      <Link to="#" className="avatar-title bg-soft-danger text-danger fs-15 rounded">
                        <i className="ri-mail-line"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                      <Link to="#" className="avatar-title bg-soft-warning text-warning fs-15 rounded">
                        <i className="ri-question-answer-line"></i>
                      </Link>
                    </li>
                  </ul>
                </CardBody>
                <div className="card-body">
                  <h6 className="text-muted text-uppercase fw-semibold mb-3">Information</h6>
                  <p className="text-muted mb-4">
                    A company incurs fixed and variable costs such as the purchase of raw materials, salaries and
                    overhead, as explained by AccountingTools, Inc. Business owners have the discretion to determine the
                    actions.
                  </p>
                  <div className="table-responsive table-card">
                    <table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td className="fw-medium">Industry Type</td>
                          <td>{info.industry_type || 'Chemical Industries'}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Location</td>
                          <td>{info.location || 'Damascus, Syria'}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Employee</td>
                          <td>{info.employee || '10-50'}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Rating</td>
                          <td>
                            {info.star_value || '4.0'} <i className="ri-star-fill text-warning align-bottom"></i>
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Website</td>
                          <td>
                            <Link to="#" className="link-primary text-decoration-underline">
                              {info.website || 'www.syntycesolution.com'}
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Contact Email</td>
                          <td>{info.contact_email || 'info@syntycesolution.com'}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Since</td>
                          <td>{info.since || '1995'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CrmCompanies;
