const OpportunityRiskReport = ({ details }) => {
  return (
    <>
      <h4>Required Risk and Opportunity Reports</h4>
      <ul>
        {details.requiredRiskOpportunityReports.map((report, index) => (
          <li key={index}>
            <h5>{report.name}</h5>
            <p>{report.description}</p>
            <a href={report.link}>View Report</a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default OpportunityRiskReport;
