import { HasuraDataAdapter } from 'Components/Admin/DataAdapter';
import { keys } from 'lodash';

export const getCapitalizedText = (text: string): string => {
  const words = text.split(' ');
  return words.map(word => word[0].toUpperCase() + word.substring(1)).join(' ');
};

export const nullStringify = (text: string | null | undefined): string => {
  if (text === null || text === undefined) return '';
  return text;
};

export const getThemePropertyValue = (property: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(property);
};

export const getExportObjectString = (
  objectArray: { [x: string]: any }[],
  excludedKeys: string[],
  nestedObjectKeys?: string[],
): string => {
  let objectString = '';
  objectArray.forEach(object => {
    objectString += '{';
    for (const objKey in object) {
      if (excludedKeys.includes(objKey)) continue;
      if (nestedObjectKeys && nestedObjectKeys.includes(objKey)) {
        objectString += `${objKey} : {data : {`;
        keys(object[objKey]).forEach((nestedKey: string) => {
          if (excludedKeys.includes(nestedKey)) return;
          objectString += `${nestedKey} : ${object[objKey][nestedKey]}, `;
        });
        objectString = `${objectString.slice(0, -2)}}}, `;
        continue;
      }
      objectString += `${objKey} : ${object[objKey]}, `;
    }
    objectString = `${objectString.slice(0, -2)}}, `;
  });
  return `[${objectString.slice(0, -2)}]`;
};

export const getRelationshipFieldDataAdapter = (client: any, entity: any, fragmentDoc: any) => {
  return new HasuraDataAdapter(client, entity, fragmentDoc, 'graphqlDefault');
};

var SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

export const abbreviateNumber = number => {
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;
  if (tier == 0) return number;
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);
  var scaled = number / scale;
  return { number: scaled.toFixed(1), suffix: suffix, numberWithSuffix: scaled.toFixed(1) + suffix };
};

export const toggleBodyBgClass = () => {
  const className = document.body.classList;
  if (className.contains('full-bg-primary')) {
    document.body.classList.remove('full-bg-primary');
  } else {
    document.body.classList.add('full-bg-primary');
  }
};

const getTabIndex = (errorKey, groupedColumns) => {
  let tabIndex = -1;
  Object.keys(groupedColumns).forEach((tabName, index) => {
    groupedColumns[tabName].forEach(column => {
      if (column.name === errorKey) {
        tabIndex = index;
        return;
      }
    });
  });
  return tabIndex;
};

export const navigateToErrorField = (errors, groupedColumns, setActiveIndex) => {
  const errorKeys = Object.keys(errors);
  if (errorKeys.length) {
    const firstErrorKey = errorKeys[0];
    const indexOfTab = getTabIndex(firstErrorKey, groupedColumns);
    setActiveIndex(indexOfTab); // navigate to the tab where the error field is located
  }
};

export const uploadFile = (file: File, isPublic: boolean) => {
  const uploadParams = {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    body: file,
  };

  let url = `${process.env.REACT_APP_FILE_URL}/${file.name}`;
  if (isPublic) url = `${url}?is_public=true`;

  return fetch(url, uploadParams);
};
