import React, { useState } from 'react';
import FinancialSummary from '../OverviewTab/FinancialSummary';

import CompanyOverviewData from '../OverviewTab/company_overview.json';
import FinancialSummaryData from '../OverviewTab/Financial_Summary.json';
import LegalReportsData from './required_legal_reports.json';
import BadActorData from './bad_actor_report.json';
import RiskAssessmentData from './company_risk_assessment_report.json';
import FinancialRiskData from './company_financial_risk_assessment.json';

import CompanyDocument from '../CompanyDocument';
import RequiredLegalDocuments from './RequiredLegalDocuments';
import BadActorReport from './BadActorReport';
import RiskAssessmentReport from './RiskAssessmentReport';
import FinancialRisk from './FinancialRisk';

const FinancialAndLegalTab = () => {
  const [selectedSection, setSelectedSection] = useState('financialSummary');

  const toggleDocumentSection = value => {
    setSelectedSection(value);
  };
  return (
    <React.Fragment>
      <div className="company-category">
        <span
          className={selectedSection === 'financialSummary' ? 'active' : ''}
          onClick={() => toggleDocumentSection('financialSummary')}
        >
          Company Financial Summary
        </span>
        <span
          className={selectedSection === 'requiredLegalDocuments' ? 'active' : ''}
          onClick={() => toggleDocumentSection('requiredLegalDocuments')}
        >
          Company Required Legal Documents
        </span>
        <span
          className={selectedSection === 'badActorReport' ? 'active' : ''}
          onClick={() => toggleDocumentSection('badActorReport')}
        >
          Company Bad Actor Report
        </span>
        <span
          className={selectedSection === 'riskAssessmentReports' ? 'active' : ''}
          onClick={() => toggleDocumentSection('riskAssessmentReports')}
        >
          Company Risk Assessment Reports
        </span>
        <span
          className={selectedSection === 'financialRisk' ? 'active' : ''}
          onClick={() => toggleDocumentSection('financialRisk')}
        >
          Company Financial Risk
        </span>
      </div>
      <CompanyDocument header={CompanyOverviewData.header} details={CompanyOverviewData}>
        {selectedSection === 'financialSummary' && <FinancialSummary details={FinancialSummaryData} />}
        {selectedSection === 'requiredLegalDocuments' && <RequiredLegalDocuments details={LegalReportsData} />}
        {selectedSection === 'badActorReport' && <BadActorReport details={BadActorData} />}
        {selectedSection === 'riskAssessmentReports' && <RiskAssessmentReport details={RiskAssessmentData} />}
        {selectedSection === 'financialRisk' && <FinancialRisk details={FinancialRiskData} />}
      </CompanyDocument>
    </React.Fragment>
  );
};

export default FinancialAndLegalTab;
