import abcLogo from '../../../../assets/images/abc_corporation.png';
import verticalLogo from '../../../../assets/images/logo-light.svg';

const CompanyDocument = ({ header, children, details }) => {
  return (
    <div className="container myorg-content">
      <div className="text-center mt-4">
        <img src={abcLogo} alt="Company Logo" className="img-fluid" style={{ width: '120px' }} />
        <h1 className="mt-4">{header.companyName}</h1>
        <h4>{header.industry}</h4>
      </div>
      <hr className="my-4" />
      {children}
      <hr className="my-4" />
      <div className="text-center mt-4">
        <img src={verticalLogo} alt="Exchange Logo" className="img-fluid" style={{ width: '80px' }} />
        <p>
          <strong>Transfer Agent:</strong> {details.transferAgent}
        </p>
        <p>
          <strong>Broker Dealer:</strong> {details.brokerDealer}
        </p>
      </div>
    </div>
  );
};

export default CompanyDocument;
