import Iframe from 'Components/Common/Iframe';

const GlobalOrganization = () => {
  return (
    <Iframe
      url={`https://dashboard.crwd.id/embed/datamarket/embed?dataType=companies&splash=false`}
      breadcrumbTitle="Global Organizations"
    />
  );
};

export default GlobalOrganization;
