const FinancialRisk = ({ details }) => {
  return (
    <>
      <h2>Financial Risk Analysis Report</h2>
      <section>
        <h3>Liquidity Risks</h3>
        <ul>
          <li>{details.financialRiskAnalysisReport.liquidityRisks.subsection1}</li>
          <li>{details.financialRiskAnalysisReport.liquidityRisks.subsection2}</li>
          <li>{details.financialRiskAnalysisReport.liquidityRisks.subsection3}</li>
        </ul>
      </section>
      <section>
        <h3>Credit Risks</h3>
        <ul>
          <li>{details.financialRiskAnalysisReport.creditRisks.subsection1}</li>
          <li>{details.financialRiskAnalysisReport.creditRisks.subsection2}</li>
          <li>{details.financialRiskAnalysisReport.creditRisks.subsection3}</li>
        </ul>
      </section>
      <section>
        <h3>Market Risks</h3>
        <ul>
          <li>{details.financialRiskAnalysisReport.marketRisks.subsection1}</li>
          <li>{details.financialRiskAnalysisReport.marketRisks.subsection2}</li>
          <li>{details.financialRiskAnalysisReport.marketRisks.subsection3}</li>
        </ul>
      </section>
      <section>
        <h3>Other Financial Vulnerabilities</h3>
        <ul>
          <li>{details.financialRiskAnalysisReport.otherFinancialVulnerabilities.subsection1}</li>
          <li>{details.financialRiskAnalysisReport.otherFinancialVulnerabilities.subsection2}</li>
          <li>{details.financialRiskAnalysisReport.otherFinancialVulnerabilities.subsection3}</li>
        </ul>
      </section>
    </>
  );
};

export default FinancialRisk;
