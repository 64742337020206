import axios from 'axios';
import { oidcSettings } from './authConfig';
import { SignupInput } from '../shared/UserContext';

// TODO: 'https://dev.dashboard-api.crwd.id/api',
const Axios = axios.create({
  baseURL: 'https://dev.dashboard-api.crwd.id/api',
});

const esRequest = async (url, options, config) => {
  const payload = {
    ...options,
  };
  return Axios.post(url, payload, config).catch(error => {
    return Promise.resolve({ data: {} });
  });
};

export { esRequest };

export async function loginApi(email, password) {
  const url = `${oidcSettings.authority}/trusted/login`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      password,
      clientId: oidcSettings.client_id,
      scope: oidcSettings.scope,
    }),
  });
  const body = await response.json();
  const { id_token } = body;
  if (id_token) {
    return id_token;
  }

  throw new Error(body.error);
}

export async function signupApi(signupInput) {
  const url = `${oidcSettings.authority}/trusted/register`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...signupInput,
      clientId: oidcSettings.client_id,
    }),
  });
  const body = await response.json();
  if (!body.error) {
    return;
  }

  throw new Error(body.error);
}
