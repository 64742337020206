import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';

import Widgets from './Widgets';
import Marketplace from './Marketplace';
import Popularity from './Popularity';
import FeaturedNFT from './FeaturedNFT';
import RecentNFTs from './RecentNFTs';
import { useParams } from 'react-router-dom';

const DashboardNFT = () => {
  const { name } = useParams();
  document.title = `${name} Dashboard`;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={`${name} Dashboard`} pageTitle="Dashboard" />
          <Row className="dash-nft">
            <Col xxl={9}>
              <Widgets name={name} />
              <Marketplace />
            </Col>
            <Popularity />
          </Row>
          {!['crwdunit+', 'commodities', 'nfa'].includes(name) && (
            <>
              <FeaturedNFT />
              <RecentNFTs />
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardNFT;
