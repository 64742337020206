import { Card, Button } from 'reactstrap';

const LinkTemplate = ({ onClick, img, title }) => (
  <Button className="image-popup p-0 bg-transparent border-0 w-full h-full" onClick={onClick}>
    <Card className="gallery-box">
      <div className="gallery-container">
        <img className="gallery-img img-fluid mx-auto" src={img} alt="" />
        <div className="gallery-overlay">
          <h5 className="overlay-caption">{title}</h5>
        </div>
      </div>
    </Card>
  </Button>
);

export default LinkTemplate;
