import BreadCrumb from 'Components/Common/BreadCrumb';
import Iframe from 'Components/Common/Iframe';
import { useState } from 'react';
import { Container } from 'reactstrap';

const BlockchainViewer = () => {
  const [data, setData] = useState([]);
  if (!data.length) {
    fetch('https://dashboard-api.crwd.id/api/open/blockchain', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setData(data);
      });
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Vogon Viewer" pageTitle="Vogon" />

        <pre>{JSON.stringify(data, null, 2)}</pre>
      </Container>
    </div>
  );
};

export default BlockchainViewer;
