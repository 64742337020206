const RiskAssessmentReport = ({ details }) => {
  return (
    <>
      <h2>Assessment Report</h2>
      <section>
        <h3>Financial Performance</h3>
        <p>{details.assessmentReport.financialPerformance.description}</p>
        <p>{details.assessmentReport.financialPerformance.evaluation}</p>
      </section>
      <section>
        <h3>Reputation</h3>
        <p>{details.assessmentReport.reputation.description}</p>
        <p>{details.assessmentReport.reputation.evaluation}</p>
      </section>
      <section>
        <h3>Industry Risks</h3>
        <p>{details.assessmentReport.industryRisks.description}</p>
        <p>{details.assessmentReport.industryRisks.evaluation}</p>
      </section>
      <section>
        <h3>Regulatory Risks</h3>
        <p>{details.assessmentReport.regulatoryRisks.description}</p>
        <p>{details.assessmentReport.regulatoryRisks.evaluation}</p>
      </section>
      <section>
        <h3>Internal Risks</h3>
        <p>{details.assessmentReport.internalRisks.description}</p>
        <p>{details.assessmentReport.internalRisks.evaluation}</p>
      </section>
    </>
  );
};

export default RiskAssessmentReport;
