import React from 'react';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//import images
import crwdCapital from '../../assets/images/crwd-capital.png';
import { Link } from 'react-router-dom';

const UpgradeAccountNotise = () => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody className="p-0">
              <Alert className="alert alert-warning border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
                <FeatherIcon icon="alert-triangle" className="text-warning me-2 icon-sm" />
                <div className="flex-grow-1 text-truncate">
                  Your free trial expired in <b>17</b> days.
                </div>
                <div className="flex-shrink-0">
                  <Link to="/pages-pricing" className="text-reset text-decoration-underline">
                    <b>Upgrade</b>
                  </Link>
                </div>
              </Alert>

              <Row>
                <Col sm={6}>
                  <div className="p-3">
                    <p className="fs-16 lh-base">
                      CURRENT PLAN: FREE
                      <div className="fw-semibold">UPGRADE PLAN</div>
                    </p>
                    <div className="mt-3">
                      <Link
                        to="/pages-pricing"
                        className="btn btn-success"
                        style={{
                          backgroundColor: '#6195ED',
                          borderColor: '#6195ED',
                        }}
                      >
                        PREMIUM PLAN
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <img src={crwdCapital} className="img-fluid" alt="" />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UpgradeAccountNotise;
