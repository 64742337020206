import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';
// Import Data
import navdata from '../LayoutMenuData';
//i18n
import { withTranslation } from 'react-i18next';

const VerticalLayout = props => {
  const history = useHistory();

  const navData = navdata().props.children;
  const [expandedItems, setExpandedItems] = useState([]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const initMenu = () => {
      const pathName = process.env.PUBLIC_URL + props.location.pathname;
      const ul = document.getElementById('navbar-nav');
      const items = ul.getElementsByTagName('a');
      let itemsArray = [...items]; // converts NodeList to Array
      let matchingMenuItem = itemsArray.find(x => {
        return x.pathname === pathName;
      });
      console.log(pathName, matchingMenuItem);

      // if (matchingMenuItem) {
      //   removeActivation(itemsArray);
      //   activateParentDropdown(matchingMenuItem);
      // }
    };
    if (props.layoutType === 'vertical') {
      initMenu();
    }
  }, [props.location.pathname, props.layoutType]);

  function activateParentDropdown(item) {
    item.classList.add('active');
    let parentCollapseDiv = item.closest('.collapse.menu-dropdown');

    if (parentCollapseDiv) {
      // to set aria expand true remaining
      parentCollapseDiv.classList.add('show');
      parentCollapseDiv.parentElement.children[0].classList.add('active');
      parentCollapseDiv.parentElement.children[0].setAttribute('aria-expanded', 'true');
      if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown')) {
        parentCollapseDiv.parentElement.closest('.collapse').classList.add('show');
        if (parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling)
          parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling.classList.add('active');
        if (parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling.closest('.collapse')) {
          parentCollapseDiv.parentElement
            .closest('.collapse')
            .previousElementSibling.closest('.collapse')
            .classList.add('show');
          parentCollapseDiv.parentElement
            .closest('.collapse')
            .previousElementSibling.closest('.collapse')
            .previousElementSibling.classList.add('active');
        }
      }
      return false;
    }
    return false;
  }

  const removeActivation = items => {
    let actiItems = items.filter(x => x.classList.contains('active'));

    actiItems.forEach(item => {
      if (item.classList.contains('menu-link')) {
        if (!item.classList.contains('active')) {
          item.setAttribute('aria-expanded', false);
        }
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove('show');
        }
      }
      if (item.classList.contains('nav-link')) {
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove('show');
        }
        item.setAttribute('aria-expanded', false);
      }
      item.classList.remove('active');
    });
  };

  const toggleLinks = (event, item, index) => {
    event.preventDefault();
    const items = [...expandedItems];
    if (items[index] === item.id) {
      items[index] = null;
    } else {
      items[index] = item.id;
    }
    setExpandedItems(items);
    item?.click && item.click(event);
    if (item.link) {
      history.push(item.link);
    }
  };

  const isOpen = item => {
    return expandedItems.includes(item.id);
  };

  return (
    <React.Fragment>
      {/* menu Items */}
      {(navData || []).map((item, key) => {
        return (
          <React.Fragment key={key}>
            {/* Main Header */}
            {item['isHeader'] ? (
              <li className="menu-title">
                <span onClick={item.click} data-key="t-menu">
                  {props.t(item.label)}{' '}
                </span>
              </li>
            ) : item.subItems ? (
              <li className="nav-item">
                <Link
                  onClick={e => toggleLinks(e, item, 0)}
                  className="nav-link menu-link"
                  to={item.link ? item.link : '/#'}
                  data-bs-toggle="collapse"
                >
                  <i className={item.icon}></i>
                  <span data-key="t-apps">{props.t(item.label)}</span>
                  {item.badgeName ? (
                    <span className={'badge badge-pill bg-' + item.badgeColor} data-key="t-new">
                      {item.badgeName}
                    </span>
                  ) : null}
                </Link>
                <Collapse className="menu-dropdown" isOpen={expandedItems.includes(item.id)} id={item.id}>
                  <ul className="nav nav-sm flex-column test">
                    {/* subItms  */}
                    {item.subItems &&
                      (item.subItems || []).map((subItem, key) => (
                        <React.Fragment key={key}>
                          {!subItem.childItems ? (
                            <li className="nav-item">
                              <Link to={subItem.link ? subItem.link : '/#'} className="nav-link">
                                {props.t(subItem.label)}
                                {subItem.badgeName ? (
                                  <span className={'badge badge-pill bg-' + subItem.badgeColor} data-key="t-new">
                                    {subItem.badgeName}
                                  </span>
                                ) : null}
                              </Link>
                            </li>
                          ) : (
                            <li className="nav-item">
                              <Link
                                onClick={e => toggleLinks(e, subItem, 1)}
                                className="nav-link"
                                to={subItem.link ? subItem.link : '/#'}
                                data-bs-toggle="collapse"
                              >
                                {' '}
                                {props.t(subItem.label)}
                              </Link>
                              <Collapse
                                className="menu-dropdown"
                                isOpen={expandedItems.includes(subItem.id)}
                                id={subItem.id}
                              >
                                <ul className="nav nav-sm flex-column">
                                  {/* child subItms  */}
                                  {subItem.childItems &&
                                    (subItem.childItems || []).map((childItem, key) => (
                                      <React.Fragment key={key}>
                                        {!childItem.childItems ? (
                                          <li className="nav-item">
                                            <Link to={childItem.link ? childItem.link : '/#'} className="nav-link">
                                              {props.t(childItem.label)}
                                            </Link>
                                          </li>
                                        ) : (
                                          <li className="nav-item">
                                            <Link
                                              to="/#"
                                              className="nav-link"
                                              onClick={e => toggleLinks(e, childItem, 2)}
                                              data-bs-toggle="collapse"
                                            >
                                              {props.t(childItem.label)}{' '}
                                            </Link>
                                            <Collapse
                                              className="menu-dropdown"
                                              isOpen={expandedItems.includes(childItem.id)}
                                              id={childItem.id}
                                            >
                                              <ul className="nav nav-sm flex-column">
                                                {childItem.childItems.map((subChildItem, key) => (
                                                  <>
                                                    {!subChildItem.childItems ? (
                                                      <li className="nav-item" key={key}>
                                                        <Link to={subChildItem.link} className="nav-link">
                                                          {props.t(subChildItem.label)}{' '}
                                                        </Link>
                                                      </li>
                                                    ) : (
                                                      <li className="nav-item" key={key}>
                                                        <Link
                                                          to={subChildItem.link}
                                                          className="nav-link"
                                                          onClick={e => toggleLinks(e, subChildItem, 3)}
                                                          data-key="t-basic-action"
                                                          data-bs-toggle="collapse"
                                                        >
                                                          {props.t(subChildItem.label)}{' '}
                                                        </Link>

                                                        <Collapse
                                                          className="menu-dropdown"
                                                          isOpen={expandedItems.includes(subChildItem.id)}
                                                          id={subChildItem.id}
                                                        >
                                                          <ul className="nav nav-sm flex-column">
                                                            {/* child subItms  */}
                                                            {subChildItem.childItems?.map((grandSubChildItem, key) => (
                                                              <React.Fragment key={key}>
                                                                <li
                                                                  className="nav-item"
                                                                  onClick={e => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                  }}
                                                                >
                                                                  <Link
                                                                    to={grandSubChildItem.link}
                                                                    className="nav-link"
                                                                  >
                                                                    {props.t(grandSubChildItem.label)}
                                                                  </Link>
                                                                </li>
                                                              </React.Fragment>
                                                            ))}
                                                          </ul>
                                                        </Collapse>
                                                      </li>
                                                    )}
                                                  </>
                                                ))}
                                              </ul>
                                            </Collapse>
                                          </li>
                                        )}
                                      </React.Fragment>
                                    ))}
                                </ul>
                              </Collapse>
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                  </ul>
                </Collapse>
              </li>
            ) : (
              <li className="nav-item">
                <Link className="nav-link menu-link" to={item.link ? item.link : '/#'}>
                  <i className={item.icon}></i> <span>{props.t(item.label)}</span>
                  {item.badgeName ? (
                    <span className={'badge badge-pill bg-' + item.badgeColor} data-key="t-new">
                      {item.badgeName}
                    </span>
                  ) : null}
                </Link>
              </li>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

VerticalLayout.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(VerticalLayout));
