import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTableFilterMeta, DataTableFilterMetaData, DataTableOperatorFilterMetaData } from 'primereact/datatable';
import { EntityFieldProps } from 'utils/schema';

interface Filter {
  name: string;
  metaData: DataTableFilterMetaData | DataTableOperatorFilterMetaData;
}

export function getFieldName(field: EntityFieldProps): string {
  if (field.type === 'Object') {
    return `${field.name}.${field.entityFieldLabel}`;
  }
  return field.name;
}

function createDefaultFilter(field: EntityFieldProps): Filter | undefined {
  switch (field.type) {
    case 'Object':
      return {
        name: `${field.name}.${field.entityFieldLabel}`,
        metaData: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    case 'String':
    case 'citext':
    case 'Phone':
    case 'Email':
      return {
        name: `${field.name}`,
        metaData: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };

    case 'timestamptz':
      return {
        name: `${field.name}`,
        metaData: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
        },
      };
    case 'Boolean':
      return {
        name: `${field.name}`,
        metaData: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      };
    case 'Int':
    case 'Float':
    case 'bigint':
    case 'uuid':
      return {
        name: `${field.name}`,
        metaData: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      };
    case 'Image':
    case 'staticDropdown':
    default:
      return undefined;
  }
}

export function createFilters(fields: Array<EntityFieldProps>): DataTableFilterMeta {
  const filters: DataTableFilterMeta = {};

  for (const field of fields) {
    const filter = createDefaultFilter(field);
    if (filter) {
      filters[filter.name] = filter.metaData;
    }
  }

  return filters;
}
