import React from 'react';
import { ListBox } from 'primereact/listbox';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { groupBy, sortBy } from 'lodash';
import data from './data.json';
import './index.styled.scss';

interface EntityProps {
  name: string;
  path: string;
}

const CognitiveLibraryPage: React.FC = () => {
  const history = useHistory();
  let entities;

  const entityTemplate = (option: EntityProps) => {
    return <Button label={option.name} onClick={() => history.push(option.path)} className="p-button-link" />;
  };
  const sortedEntities = sortBy(data, item => item.name);
  const groupedEntities = groupBy(sortedEntities, item => item.name[0].toUpperCase());
  entities = Object.keys(groupedEntities).map(key => {
    return {
      label: key,
      items: groupedEntities[key],
    };
  });

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="entity-list-card">
          <div className="img-wrapper" />
          <ListBox
            options={entities}
            filter
            filterBy="name"
            filterMatchMode="contains"
            optionLabel="label"
            optionGroupLabel="label"
            optionGroupChildren="items"
            itemTemplate={entityTemplate}
            filterPlaceholder="Filter entity name"
          />
        </div>
      </div>
    </div>
  );
};

export default CognitiveLibraryPage;
