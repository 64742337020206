import React, { useState } from 'react';

import CompanyOverviewData from './company_overview.json';
import FinancialSummaryData from './Financial_Summary.json';
import CompetitorRiskData from './company_competitor_report.json';
import IndustryOutlookPerformanceData from './company_industry_outlook_performanc.json';
import OpportunityRiskReportData from './company_opportunity_risk_report.json';
import UseOfFundsData from './company_use_of_funds.json';
import opportunityReportData from './company_opportunity_report.json';
import competitorRiskAssessmentData from './company_competitor_risk_assessment.json';

import CompanyOverview from './CompanyOverview';
import CompanyDocument from '../CompanyDocument';
import FinancialSummary from './FinancialSummary';
import CompetitorReport from './CompetitorReport';
import IndustryOutlookPerformance from './IndustryOutlookPerformance';
import OpportunityRiskReport from './OpportunityRiskReport';
import UseOfFunds from './UseOfFunds';
import OpportunityReport from './OpportunityReport';
import CompetitorRiskAssessment from './CompetitorRiskAssessment';

const OverviewTab = () => {
  const [selectedSection, setSelectedSection] = useState('overview');

  const toggleDocumentSection = value => {
    setSelectedSection(value);
  };
  return (
    <React.Fragment>
      <div className="company-category">
        <span
          className={selectedSection === 'overview' ? 'active' : ''}
          onClick={() => toggleDocumentSection('overview')}
        >
          Overview
        </span>
        <span
          className={selectedSection === 'financialSummary' ? 'active' : ''}
          onClick={() => toggleDocumentSection('financialSummary')}
        >
          Financial Summary
        </span>
        <span
          className={selectedSection === 'competitorRisk' ? 'active' : ''}
          onClick={() => toggleDocumentSection('competitorRisk')}
        >
          Competitor Reports
        </span>
        <span
          className={selectedSection === 'companyIndustryOutlook' ? 'active' : ''}
          onClick={() => toggleDocumentSection('companyIndustryOutlook')}
        >
          Industry Outlook
        </span>
        <span
          className={selectedSection === 'opportunityRiskReport' ? 'active' : ''}
          onClick={() => toggleDocumentSection('opportunityRiskReport')}
        >
          Opportunity and Risk Reports
        </span>
        <span
          className={selectedSection === 'useOfFunds' ? 'active' : ''}
          onClick={() => toggleDocumentSection('useOfFunds')}
        >
          Use of Funds
        </span>
        <span
          className={selectedSection === 'opportunityReport' ? 'active' : ''}
          onClick={() => toggleDocumentSection('opportunityReport')}
        >
          Opportunity Report
        </span>
        <span
          className={selectedSection === 'competitorRiskAssessment' ? 'active' : ''}
          onClick={() => toggleDocumentSection('competitorRiskAssessment')}
        >
          Competitor Risk Report
        </span>
      </div>
      <CompanyDocument header={CompanyOverviewData.header} details={CompanyOverviewData}>
        {selectedSection === 'overview' && <CompanyOverview details={CompanyOverviewData} />}
        {selectedSection === 'financialSummary' && <FinancialSummary details={FinancialSummaryData} />}
        {selectedSection === 'competitorRisk' && <CompetitorReport details={CompetitorRiskData} />}
        {selectedSection === 'companyIndustryOutlook' && (
          <IndustryOutlookPerformance details={IndustryOutlookPerformanceData} />
        )}
        {selectedSection === 'opportunityRiskReport' && <OpportunityRiskReport details={OpportunityRiskReportData} />}
        {selectedSection === 'useOfFunds' && <UseOfFunds details={UseOfFundsData} />}
        {selectedSection === 'opportunityReport' && <OpportunityReport details={opportunityReportData} />}
        {selectedSection === 'competitorRiskAssessment' && (
          <CompetitorRiskAssessment details={competitorRiskAssessmentData} />
        )}
      </CompanyDocument>
    </React.Fragment>
  );
};

export default OverviewTab;
