import { Redirect } from 'react-router-dom';

//Dashboard
import DashboardAnalytics from '../pages/DashboardAnalytics';
import DashboardCrm from '../pages/DashboardCrm';
import DashboardEcommerce from '../pages/DashboardEcommerce';

import DashboardCrypto from '../pages/DashboardCrypto';
import DashboardProject from '../pages/DashboardProject';
import DashboardNFT from '../pages/DashboardNFT';

//Calendar
// Email box
import MailInbox from '../pages/EmailInbox';
import BasicAction from '../pages/Email/EmailTemplates/BasicAction';
import EcommerceAction from '../pages/Email/EmailTemplates/EcommerceAction';

//CHat
import Chat from '../pages/Chat';
import Calendar from '../pages/Calendar';

// Project
import ProjectList from '../pages/Projects/ProjectList';
import ProjectOverview from '../pages/Projects/ProjectOverview';
import CreateProject from '../pages/Projects/CreateProject';

//Task
import TaskDetails from '../pages/Tasks/TaskDetails';
import TaskList from '../pages/Tasks/TaskList';
import KanbanBoard from '../pages/Tasks/KanbanBoard/Index';

//Transactions
import Transactions from '../pages/Crypto/Transactions';
import BuySell from '../pages/Crypto/BuySell';
import CryproOrder from '../pages/Crypto/CryptoOrder';
import MyWallet from '../pages/Crypto/MyWallet';
import ICOList from '../pages/Crypto/ICOList';
import KYCVerification from '../pages/Crypto/KYCVerification';

//Crm Pages
import CrmCompanies from '../pages/Crm/CrmCompanies';
import CrmContacts from '../pages/Crm/CrmContacts';
import CrmDeals from '../pages/Crm/CrmDeals/index';
import CrmLeads from '../pages/Crm/CrmLeads/index';

//Invoices
import InvoiceList from '../pages/Invoices/InvoiceList';
import InvoiceCreate from '../pages/Invoices/InvoiceCreate';
import InvoiceDetails from '../pages/Invoices/InvoiceDetails';

// Support Tickets
import ListView from '../pages/SupportTickets/ListView';
import TicketsDetails from '../pages/SupportTickets/TicketsDetails';

// //Ecommerce Pages
import EcommerceProducts from '../pages/Ecommerce/EcommerceProducts/index';
import EcommerceProductDetail from '../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail';
import EcommerceAddProduct from '../pages/Ecommerce/EcommerceProducts/EcommerceAddProduct';
import EcommerceOrders from '../pages/Ecommerce/EcommerceOrders/index';
import EcommerceOrderDetail from '../pages/Ecommerce/EcommerceOrders/EcommerceOrderDetail';
import EcommerceCustomers from '../pages/Ecommerce/EcommerceCustomers/index';
import EcommerceCart from '../pages/Ecommerce/EcommerceCart';
import EcommerceCheckout from '../pages/Ecommerce/EcommerceCheckout';
import EcommerceSellers from '../pages/Ecommerce/EcommerceSellers/index';
import EcommerceSellerDetail from '../pages/Ecommerce/EcommerceSellers/EcommerceSellerDetail';

// NFT Marketplace Pages
import Marketplace from '../pages/NFTMarketplace/Marketplace';
import Collections from '../pages/NFTMarketplace/Collections';
import CreateNFT from '../pages/NFTMarketplace/CreateNFT';
import Creators from '../pages/NFTMarketplace/Creators';
import ExploreNow from '../pages/NFTMarketplace/ExploreNow';
import ItemDetails from '../pages/NFTMarketplace/Itemdetails';
import LiveAuction from '../pages/NFTMarketplace/LiveAuction';
import Ranking from '../pages/NFTMarketplace/Ranking';
import WalletConnect from '../pages/NFTMarketplace/WalletConnect';

// Base Ui
import UiAlerts from '../pages/BaseUi/UiAlerts/UiAlerts';
import UiBadges from '../pages/BaseUi/UiBadges/UiBadges';
import UiButtons from '../pages/BaseUi/UiButtons/UiButtons';
import UiColors from '../pages/BaseUi/UiColors/UiColors';
import UiCards from '../pages/BaseUi/UiCards/UiCards';
import UiCarousel from '../pages/BaseUi/UiCarousel/UiCarousel';
import UiDropdowns from '../pages/BaseUi/UiDropdowns/UiDropdowns';
import UiGrid from '../pages/BaseUi/UiGrid/UiGrid';
import UiImages from '../pages/BaseUi/UiImages/UiImages';
import UiTabs from '../pages/BaseUi/UiTabs/UiTabs';
import UiAccordions from '../pages/BaseUi/UiAccordion&Collapse/UiAccordion&Collapse';
import UiModals from '../pages/BaseUi/UiModals/UiModals';
import UiOffcanvas from '../pages/BaseUi/UiOffcanvas/UiOffcanvas';
import UiPlaceholders from '../pages/BaseUi/UiPlaceholders/UiPlaceholders';
import UiProgress from '../pages/BaseUi/UiProgress/UiProgress';
import UiNotifications from '../pages/BaseUi/UiNotifications/UiNotifications';
import UiMediaobject from '../pages/BaseUi/UiMediaobject/UiMediaobject';
import UiEmbedVideo from '../pages/BaseUi/UiEmbedVideo/UiEmbedVideo';
import UiTypography from '../pages/BaseUi/UiTypography/UiTypography';
import UiList from '../pages/BaseUi/UiLists/UiLists';
import UiGeneral from '../pages/BaseUi/UiGeneral/UiGeneral';
import UiRibbons from '../pages/BaseUi/UiRibbons/UiRibbons';
import UiUtilities from '../pages/BaseUi/UiUtilities/UiUtilities';

// Advance Ui
import UiNestableList from '../pages/AdvanceUi/UiNestableList/UiNestableList';
import UiScrollbar from '../pages/AdvanceUi/UiScrollbar/UiScrollbar';
import UiAnimation from '../pages/AdvanceUi/UiAnimation/UiAnimation';
import UiTour from '../pages/AdvanceUi/UiTour/UiTour';
import UiSwiperSlider from '../pages/AdvanceUi/UiSwiperSlider/UiSwiperSlider';
import UiRatings from '../pages/AdvanceUi/UiRatings/UiRatings';
import UiHighlight from '../pages/AdvanceUi/UiHighlight/UiHighlight';

// Widgets
import Widgets from '../pages/Widgets/Index';

//Forms
import BasicElements from '../pages/Forms/BasicElements/BasicElements';
import FormSelect from '../pages/Forms/FormSelect/FormSelect';
import FormEditor from '../pages/Forms/FormEditor/FormEditor';
import CheckBoxAndRadio from '../pages/Forms/CheckboxAndRadio/CheckBoxAndRadio';
import Masks from '../pages/Forms/Masks/Masks';
import FileUpload from '../pages/Forms/FileUpload/FileUpload';
import FormPickers from '../pages/Forms/FormPickers/FormPickers';
import FormRangeSlider from '../pages/Forms/FormRangeSlider/FormRangeSlider';
import Formlayouts from '../pages/Forms/FormLayouts/Formlayouts';
import FormValidation from '../pages/Forms/FormValidation/FormValidation';
import FormWizard from '../pages/Forms/FormWizard/FormWizard';
import FormAdvanced from '../pages/Forms/FormAdvanced/FormAdvanced';
import Select2 from '../pages/Forms/Select2/Select2';

//Tables
import BasicTables from '../pages/Tables/BasicTables/BasicTables';
import GridTables from '../pages/Tables/GridTables/GridTables';
import ListTables from '../pages/Tables/ListTables/ListTables';
import DataTables from '../pages/Tables/DataTables/DataTables';

//Icon pages
import RemixIcons from '../pages/Icons/RemixIcons/RemixIcons';
import BoxIcons from '../pages/Icons/BoxIcons/BoxIcons';
import MaterialDesign from '../pages/Icons/MaterialDesign/MaterialDesign';
import FeatherIcons from '../pages/Icons/FeatherIcons/FeatherIcons';
import LineAwesomeIcons from '../pages/Icons/LineAwesomeIcons/LineAwesomeIcons';

//Maps
import GoogleMaps from '../pages/Maps/GoogleMaps/GoogleMaps';
import VectorMaps from '../pages/Maps/VectorMaps/VectorMaps';
import LeafletMaps from '../pages/Maps/LeafletMaps/LeafletMaps';

//AuthenticationInner pages
import BasicSignIn from '../pages/AuthenticationInner/Login/BasicSignIn';
import CoverSignIn from '../pages/AuthenticationInner/Login/CoverSignIn';
import BasicSignUp from '../pages/AuthenticationInner/Register/BasicSignUp';
import CoverSignUp from '../pages/AuthenticationInner/Register/CoverSignUp';
import BasicPasswReset from '../pages/AuthenticationInner/PasswordReset/BasicPasswReset';
//pages
import Starter from '../pages/Pages/Starter/Starter';
import ProfileDisplay from '../pages/Pages/Profile/Display/ProfileDisplay';
import ProfileSettings from '../pages/Pages/Profile/Settings/ProfileSettings';
import Lineage from '../pages/Pages/Profile/Lineage';
import Team from '../pages/Pages/Team/Team';
import Timeline from '../pages/Pages/Timeline/Timeline';
import Faqs from '../pages/Pages/Faqs/Faqs';
import Pricing from '../pages/Pages/Pricing/Pricing';
import Marketplaces from '../pages/Pages/Gallery/Marketplaces';
import Maintenance from '../pages/Pages/Maintenance/Maintenance';
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';
import SiteMap from '../pages/Pages/SiteMap/SiteMap';
import SearchResults from '../pages/Pages/SearchResults/SearchResults';

import CoverPasswReset from '../pages/AuthenticationInner/PasswordReset/CoverPasswReset';
import BasicLockScreen from '../pages/AuthenticationInner/LockScreen/BasicLockScr';
import CoverLockScreen from '../pages/AuthenticationInner/LockScreen/CoverLockScr';
import BasicLogout from '../pages/AuthenticationInner/Logout/BasicLogout';
import CoverLogout from '../pages/AuthenticationInner/Logout/CoverLogout';
import BasicSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg';
import CoverSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg';
import BasicTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify';
import CoverTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify';
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
import Cover404 from '../pages/AuthenticationInner/Errors/Cover404';
import Alt404 from '../pages/AuthenticationInner/Errors/Alt404';
import Error500 from '../pages/AuthenticationInner/Errors/Error500';

import BasicPasswCreate from '../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate';
import CoverPasswCreate from '../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate';
import Offlinepage from '../pages/AuthenticationInner/Errors/Offlinepage';

//login
import Login from '../pages/Authentication/Login';
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';

//Charts
import LineCharts from '../pages/Charts/ApexCharts/LineCharts';
import AreaCharts from '../pages/Charts/ApexCharts/AreaCharts';
import ColumnCharts from '../pages/Charts/ApexCharts/ColumnCharts';
import BarCharts from '../pages/Charts/ApexCharts/BarCharts';
import MixedCharts from '../pages/Charts/ApexCharts/MixedCharts';
import TimelineCharts from '../pages/Charts/ApexCharts/TimelineCharts';
import CandlestickChart from '../pages/Charts/ApexCharts/CandlestickChart';
import BoxplotCharts from '../pages/Charts/ApexCharts/BoxplotCharts';
import BubbleChart from '../pages/Charts/ApexCharts/BubbleChart';
import ScatterCharts from '../pages/Charts/ApexCharts/ScatterCharts';
import HeatmapCharts from '../pages/Charts/ApexCharts/HeatmapCharts';
import TreemapCharts from '../pages/Charts/ApexCharts/TreemapCharts';
import PieCharts from '../pages/Charts/ApexCharts/PieCharts';
import RadialbarCharts from '../pages/Charts/ApexCharts/RadialbarCharts';
import RadarCharts from '../pages/Charts/ApexCharts/RadarCharts';
import PolarCharts from '../pages/Charts/ApexCharts/PolarCharts';

import ChartsJs from '../pages/Charts/ChartsJs/index';
import Echarts from '../pages/Charts/ECharts/index';

// Landing Index
import OnePage from '../pages/Landing/OnePage';
import NFTLanding from '../pages/Landing/NFTLanding';

// User Profile
import UserProfile from '../pages/Authentication/user-profile';

import OrganizationPage from '../pages/Pages/Finance/organization';
import CognitiveLibraryPage from 'pages/Admin/cognitive-library';
import ListPage from 'pages/Admin/entity-table';
import AffiliatePage from '../pages/Pages/Finance/affiliate';
import BlockchainViewer from 'pages/Tasks/BlockchainViewer';
import BlockchainBrowser from 'pages/Tasks/BlockchainBrowser';
import GlobalIdentities from 'pages/Pages/Identity/GlobalIdentities';
import GlobalOrganization from 'pages/Pages/Finance/GlobalOrganization';
import MarketCap from 'pages/Pages/Finance/MarketCap';
import SectorActivity from 'pages/Pages/Finance/SectorActivity';
import DPO from 'pages/Pages/Finance/DPO';
import PropertyTickers from 'pages/Pages/Finance/PropertyTickers';
import ISPPage from 'pages/Tasks/ISP';
import MyIdentity from 'pages/Pages/Identity/MyIdentity';
import DashboardCrwdunit from 'pages/DashboardCrwdunit';
import ListAsks from 'pages/NFTMarketplace/ListAsks';

import Shop from '../pages/Pages/Markets/Shop';
import { Marketplaces as GICSMarketplaces } from 'pages/Pages/Markets/Marketplaces/Marketplaces';
import { GICS } from 'pages/Pages/Markets/Marketplaces/GICS';
import GICSLinks from 'pages/Pages/Markets/Marketplaces/GICSLinks';
import GICSLinkView from 'pages/Pages/Markets/Marketplaces/GICSLinkView';
import Iframe from 'Components/Common/Iframe';
import ImagePage from 'Components/Common/ImagePage';
import MyContracts from 'pages/Pages/Ellipsis/MyContracts';
import SocialImage from '../assets/images/enter_social.png';
import IdentityResolution from '../assets/images/identity/identity_resolution.png';
import DPOFacts from 'pages/Pages/DPOFacts';
import CreateDigitalAsset from 'pages/Pages/Capital/CreateDigitalAsset';
import DPOEnrollment from 'pages/Pages/Finance/DPOEnrollment';
import ESGAuction from 'pages/Pages/Capital/ESGAuction';
import AppEllipsis from 'pages/NFTMarketplace/AppEllipsis';
import MyDashboard from 'pages/MyDashboard/Index';
import MyContract from 'pages/Crypto/MyContract';
import ContractPortfolio from 'pages/NFTMarketplace/ContractPortfolio';
import AssetPortolio from 'pages/NFTMarketplace/AssetPortolio';
import MyProducts from 'pages/NFTMarketplace/MyProducts';
import MyLineage from 'pages/NFTMarketplace/MyLineage';
import MyVault from 'pages/Crypto/MyVault';
import ChartsGICS from 'pages/Pages/GICS';
import MyOrders from 'pages/MyOrders';
import MyLearning from 'pages/Ecommerce/MyLearning';
import MyOrganization from 'pages/Projects/MyOrganization';
import IdentityCompliance from 'pages/Forms/IdentityCompliance/IdentityCompliance';
import IdentityAml from 'pages/Forms/IdentityAml/IdentityAml';
import SocialIdentity from 'pages/Charts/ApexCharts/SocialIdentity';
import SocialChat from 'pages/Ecommerce/SocialChat';
import MarketSummary from 'pages/Projects/MarketSummary';
import MyCart from 'pages/Ecommerce/MyCart';
import MyOrderList from 'pages/Tables/MyOrderList/MyOrderList';
import MarketshopSellers from 'pages/Ecommerce/MarketshopSellers';
import MarketShopDetails from 'pages/Ecommerce/MarketshopSellers/MarketshopDetails';
import ShopTransactions from 'pages/Charts/ApexCharts/ShopTransactions';
import CapitalSummary from 'pages/Projects/CapitalSummary';
import EsgAssets from 'pages/Charts/ApexCharts/EsgAssets';
import CommoditiesCharts from 'pages/Charts/ApexCharts/CommoditiesCharts';
import FinancialSummary from 'pages/Projects/FinancialSummary';
import PortfolioPerformance from 'pages/Charts/ApexCharts/PortfolioPerformance';
import FundRaising from 'pages/Charts/FundRaising';
import DealFlow from 'pages/Charts/ApexCharts/DealFlow';
import DigitalAssets from 'pages/Charts/ApexCharts/DigitalAssets';
import ProfileOrganization from 'pages/Pages/Profile/ProfileOrganization/ProfileOrganization';
import IdentitySummary from 'pages/Projects/IdentitySummary';
import MyWatchlist from 'pages/Crypto/MyWatchlist';
import SecuritiesWatchlist from 'pages/Projects/SecuritiesWatchlist';
import VerticalSummary from 'pages/VerticalSummary';

const authProtectedRoutes = [
  { path: '/dashboard-analytics', component: DashboardAnalytics },
  { path: '/dashboard-crm', component: DashboardCrm },
  { path: '/identity', component: DashboardCrm },
  { path: '/dashboard', component: DashboardEcommerce },
  { path: '/vertical-summary', component: VerticalSummary },
  { path: '/ellipsis', component: Iframe, props: { url: 'https://km.crowdpointtech.com' } },
  { path: '/dashboard-markets', component: DashboardEcommerce },
  { path: '/dashboard-identity', component: DashboardEcommerce },
  { path: '/dashboard-capital', component: DashboardEcommerce },
  { path: '/dashboard-finance', component: DashboardEcommerce },
  { path: '/dashboard-private', component: DashboardEcommerce },

  { path: '/index', component: DashboardEcommerce },
  { path: '/dashboard-crypto', component: DashboardCrypto },
  { path: '/my-orders', component: MyOrders },
  { path: '/dashboard-projects', component: DashboardProject },
  { path: '/dashboard-nft', component: DashboardNFT },
  {
    path: '/capital/crwdunit+',
    component: DashboardCrwdunit,
    props: {
      assetId: '9d0d9499-ba3b-4f5a-8a78-cf3f944d206a',
      title: 'crwdunit+ Dashboard',
    },
  },
  {
    path: '/capital/esg-dashboard',
    component: DashboardCrwdunit,
    props: {
      assetId: '6463dae0-251a-4552-89cf-4f72c4444c4e',
      title: 'ESG | ALL',
    },
  },

  { path: '/capital/create-digital-asset', component: CreateDigitalAsset },
  { path: '/capital/esg-auction', component: ESGAuction },
  { path: '/capital/:name', component: DashboardNFT },
  { path: '/apps-calendar', component: Calendar },
  { path: '/apps-ecommerce-products', component: EcommerceProducts },
  {
    path: '/apps-ecommerce-product-details',
    component: EcommerceProductDetail,
  },
  { path: '/apps-ecommerce-add-product', component: EcommerceAddProduct },
  { path: '/apps-ecommerce-orders', component: EcommerceOrders },
  { path: '/apps-ecommerce-order-details', component: EcommerceOrderDetail },
  { path: '/apps-ecommerce-customers', component: EcommerceCustomers },
  { path: '/apps-ecommerce-cart', component: EcommerceCart },
  { path: '/my-cart', component: MyCart },
  { path: '/apps-ecommerce-checkout', component: EcommerceCheckout },
  { path: '/apps-ecommerce-sellers', component: EcommerceSellers },
  { path: '/marketshop-sellers', component: MarketshopSellers },
  { path: '/social-chat', component: SocialChat },
  { path: '/my-learning', component: MyLearning },
  { path: '/apps-ecommerce-seller-details', component: EcommerceSellerDetail },
  { path: '/marketshop-details', component: MarketShopDetails },

  // Identity
  { path: '/my-identity', component: MyIdentity },
  { path: '/global-identities', component: GlobalIdentities },
  { path: '/social-identities', component: ImagePage, props: { img: SocialImage, url: 'http://app.crwd.world' } },
  {
    path: '/identity-resolution',
    component: ImagePage,
    props: { img: IdentityResolution, url: 'https://matomo.crowdpoint.cloud', cssClass: 'dark' },
  },

  //Finance
  { path: '/finance/market-cap', component: MarketCap },
  { path: '/finance/total-sector-activity', component: SectorActivity },
  { path: '/finance/DPO', component: DPO },
  { path: '/finance/property-tickers', component: PropertyTickers },
  { path: '/finance/global-org', component: GlobalOrganization },
  { path: '/finance/DPO-enrollment', component: DPOEnrollment },
  { path: '/finance/org-page', component: OrganizationPage },

  //Chat
  { path: '/apps-chat', component: Chat },

  //EMail
  { path: '/apps-mailbox', component: MailInbox },
  { path: '/apps-email-basic', component: BasicAction },
  { path: '/apps-email-ecommerce', component: EcommerceAction },

  //Projects
  { path: '/apps-projects-list', component: ProjectList },
  { path: '/apps-projects-overview', component: ProjectOverview },
  { path: '/identity-summary', component: IdentitySummary },
  { path: '/financial-summary', component: FinancialSummary },
  { path: '/capital-summary', component: CapitalSummary },
  { path: '/market-summary', component: MarketSummary },
  { path: '/my-organization', component: MyOrganization },
  { path: '/securities-watchlist', component: SecuritiesWatchlist },
  { path: '/apps-projects-create', component: CreateProject },

  //Systems
  { path: '/isp', component: ISPPage },
  { path: '/vogon-browser', component: BlockchainBrowser },
  { path: '/vogon-viewer', component: BlockchainViewer },
  {
    path: '/gaao',
    component: Iframe,
    url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    props: {
      url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    },
  },
  {
    path: '/gaao/energy',
    component: Iframe,
    url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    props: {
      url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    },
  },
  {
    path: '/gaao/materials',
    component: Iframe,
    url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    props: {
      url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    },
  },
  {
    path: '/gaao/industrials',
    component: Iframe,
    url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    props: {
      url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    },
  },
  {
    path: '/gaao/consumer-discretionary',
    component: Iframe,
    url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    props: {
      url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    },
  },
  {
    path: '/gaao/consumer-staples',
    component: Iframe,
    url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    props: {
      url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    },
  },
  {
    path: '/gaao/health-care',
    component: Iframe,
    url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    props: {
      url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    },
  },
  {
    path: '/gaao/financials',
    component: Iframe,
    url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    props: {
      url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    },
  },
  {
    path: '/gaao/information-technology',
    component: Iframe,
    url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    props: {
      url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    },
  },
  {
    path: '/gaao/communication-services',
    component: Iframe,
    url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    props: {
      url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    },
  },
  {
    path: '/gaao/utilities',
    component: Iframe,
    url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    props: {
      url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    },
  },
  {
    path: '/gaao/real-estate',
    component: Iframe,
    url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    props: {
      url: 'https://dashboard.crwd.id/embed/vogon_dashboard?splash=true',
    },
  },
  { path: '/apps-tasks-list-view', component: TaskList },
  { path: '/apps-tasks-details', component: TaskDetails },
  { path: '/apps-tasks-kanban', component: KanbanBoard },
  //Crm
  { path: '/apps-crm-contacts', component: CrmContacts },
  { path: '/apps-crm-companies', component: CrmCompanies },
  { path: '/apps-crm-deals', component: CrmDeals },
  { path: '/apps-crm-leads', component: CrmLeads },

  //Invoices
  { path: '/apps-invoices-list', component: InvoiceList },
  { path: '/apps-invoices-details', component: InvoiceDetails },
  { path: '/apps-invoices-create', component: InvoiceCreate },

  //Supports Tickets
  { path: '/apps-tickets-list', component: ListView },
  { path: '/apps-tickets-details', component: TicketsDetails },

  //transactions
  { path: '/apps-crypto-transactions', component: Transactions },
  { path: '/my-watchlist', component: MyWatchlist },
  { path: '/apps-crypto-buy-sell', component: BuySell },
  { path: '/apps-crypto-orders', component: CryproOrder },
  { path: '/apps-crypto-wallet', component: MyWallet },
  { path: '/my-vault', component: MyVault },
  { path: '/apps-crypto-ico', component: ICOList },
  { path: '/my-contracts', component: MyContract },
  { path: '/apps-crypto-kyc', component: KYCVerification },

  // NFT Marketplace
  { path: '/apps-nft-marketplace', component: Marketplace },
  { path: '/apps-nft-collections', component: Collections },
  { path: '/my-communication', component: Collections },
  { path: '/my-lineage', component: MyLineage },
  { path: '/my-products', component: MyProducts },
  { path: '/asset-portolio', component: AssetPortolio },
  { path: '/apps-nft-create', component: CreateNFT },
  { path: '/apps-nft-creators', component: Creators },
  { path: '/contract-portfolio', component: ContractPortfolio },
  { path: '/apps-nft-explore', component: ExploreNow },
  { path: '/ask-details/:dashboardName/:id', component: ItemDetails },
  { path: '/ask-details/:id', component: ItemDetails },
  { path: '/apps-nft-item-details', component: ItemDetails },
  { path: '/apps-nft-auction', component: LiveAuction },
  { path: '/apps-ellipsis-summary', component: AppEllipsis },
  { path: '/asks', component: ListAsks },
  { path: '/apps-nft-ranking', component: Ranking },
  { path: '/apps-nft-wallet', component: WalletConnect },

  //charts
  { path: '/charts-apex-line', component: LineCharts },
  { path: '/charts-apex-area', component: AreaCharts },
  { path: '/charts-apex-column', component: ColumnCharts },
  { path: '/charts-apex-bar', component: BarCharts },
  { path: '/gics-pubic-securities', component: ChartsGICS },
  { path: '/charts-apex-mixed', component: MixedCharts },
  { path: '/digital-assets', component: DigitalAssets },
  { path: '/deal-flow', component: DealFlow },
  { path: '/commodities', component: CommoditiesCharts },
  { path: '/esg-assets', component: EsgAssets },
  { path: '/shop-transactions', component: ShopTransactions },
  { path: '/charts-apex-timeline', component: TimelineCharts },
  { path: '/charts-apex-candlestick', component: CandlestickChart },
  { path: '/portfolio-performance', component: PortfolioPerformance },
  { path: '/charts-apex-boxplot', component: BoxplotCharts },
  { path: '/charts-apex-bubble', component: BubbleChart },
  { path: '/charts-apex-scatter', component: ScatterCharts },
  { path: '/charts-apex-heatmap', component: HeatmapCharts },
  { path: '/charts-apex-treemap', component: TreemapCharts },
  { path: '/social-identity', component: SocialIdentity },
  { path: '/charts-apex-pie', component: PieCharts },
  { path: '/charts-apex-radialbar', component: RadialbarCharts },
  { path: '/charts-apex-radar', component: RadarCharts },
  { path: '/charts-apex-polar', component: PolarCharts },

  { path: '/charts-chartjs', component: ChartsJs },
  { path: '/fund-raising', component: FundRaising },
  { path: '/charts-echarts', component: Echarts },

  // Base Ui
  { path: '/ui-alerts', component: UiAlerts },
  { path: '/ui-badges', component: UiBadges },
  { path: '/ui-buttons', component: UiButtons },
  { path: '/ui-colors', component: UiColors },
  { path: '/ui-cards', component: UiCards },
  { path: '/ui-carousel', component: UiCarousel },
  { path: '/ui-dropdowns', component: UiDropdowns },
  { path: '/ui-grid', component: UiGrid },
  { path: '/ui-images', component: UiImages },
  { path: '/ui-tabs', component: UiTabs },
  { path: '/ui-accordions', component: UiAccordions },
  { path: '/ui-modals', component: UiModals },
  { path: '/ui-offcanvas', component: UiOffcanvas },
  { path: '/ui-placeholders', component: UiPlaceholders },
  { path: '/ui-progress', component: UiProgress },
  { path: '/ui-notifications', component: UiNotifications },
  { path: '/ui-media', component: UiMediaobject },
  { path: '/ui-embed-video', component: UiEmbedVideo },
  { path: '/ui-typography', component: UiTypography },
  { path: '/ui-lists', component: UiList },
  { path: '/ui-general', component: UiGeneral },
  { path: '/ui-ribbons', component: UiRibbons },
  { path: '/ui-utilities', component: UiUtilities },

  // Advance Ui
  { path: '/advance-ui-nestable', component: UiNestableList },
  { path: '/advance-ui-scrollbar', component: UiScrollbar },
  { path: '/advance-ui-animation', component: UiAnimation },
  { path: '/advance-ui-tour', component: UiTour },
  { path: '/advance-ui-swiper', component: UiSwiperSlider },
  { path: '/advance-ui-ratings', component: UiRatings },
  { path: '/advance-ui-highlight', component: UiHighlight },

  // Widgets
  { path: '/widgets', component: Widgets },
  { path: '/my-dashboard', component: MyDashboard },

  // Forms
  { path: '/forms-elements', component: BasicElements },
  { path: '/forms-select', component: FormSelect },
  { path: '/forms-editors', component: FormEditor },
  { path: '/forms-checkboxes-radios', component: CheckBoxAndRadio },
  { path: '/forms-masks', component: Masks },
  { path: '/forms-file-uploads', component: FileUpload },
  { path: '/forms-pickers', component: FormPickers },
  { path: '/forms-range-sliders', component: FormRangeSlider },
  { path: '/forms-layouts', component: Formlayouts },
  { path: '/forms-validation', component: FormValidation },
  { path: '/identity-compliance', component: IdentityCompliance },
  { path: '/identity-aml', component: IdentityAml },
  { path: '/forms-wizard', component: FormWizard },
  { path: '/forms-advanced', component: FormAdvanced },
  { path: '/forms-select2', component: Select2 },

  //Tables
  { path: '/tables-basic', component: BasicTables },
  { path: '/tables-gridjs', component: GridTables },
  { path: '/tables-listjs', component: ListTables },
  { path: '/my-orders-list', component: MyOrderList },
  { path: '/tables-datatables', component: DataTables },

  //Icons
  { path: '/icons-remix', component: RemixIcons },
  { path: '/icons-boxicons', component: BoxIcons },
  { path: '/icons-materialdesign', component: MaterialDesign },
  { path: '/icons-feather', component: FeatherIcons },
  { path: '/icons-lineawesome', component: LineAwesomeIcons },

  //Maps
  { path: '/maps-google', component: GoogleMaps },
  { path: '/maps-vector', component: VectorMaps },
  { path: '/maps-leaflet', component: LeafletMaps },

  //Pages
  { path: '/pages-starter', component: Starter },
  { path: '/pages-profile', component: ProfileDisplay },
  { path: '/organization-profile', component: ProfileOrganization },
  { path: '/pages-profile-settings', component: ProfileSettings },
  { path: '/pages-lineage', component: Lineage },
  { path: '/pages-team', component: Team },
  { path: '/support-team', component: Team },
  { path: '/pages-timeline', component: Timeline },
  { path: '/pages-faqs', component: Faqs },
  { path: '/dpo-facts', component: DPOFacts },
  { path: '/marketplaces', component: Marketplaces },
  { path: '/pages-pricing', component: Pricing },
  { path: '/pages-sitemap', component: SiteMap },
  { path: '/pages-search-results', component: SearchResults },

  //User Profile
  { path: '/profile', component: UserProfile },

  // Admin Routes
  { path: '/admin/cognitive-library', component: CognitiveLibraryPage },
  { path: '/admin/list/:table', component: ListPage },

  //markets
  { path: '/markets/shop', component: Shop },
  { path: '/markets/marketplaces', component: GICSMarketplaces },
  { path: '/markets/marketplaces/gics', component: GICS },
  { path: '/markets/marketplaces/gics/:id', component: GICSLinks },
  { path: '/markets/marketplaces/gics/:id/view/:link', component: GICSLinkView },

  //ellipsis
  { path: '/ellipsis/my-contracts', component: MyContracts },

  { path: '/capital-transactions', component: ChartsGICS },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPasswordPage },
  { path: '/register', component: Register },

  //AuthenticationInner pages
  { path: '/auth-signin-basic', component: BasicSignIn },
  { path: '/auth-signin-cover', component: CoverSignIn },
  { path: '/auth-signup-basic', component: BasicSignUp },
  { path: '/auth-signup-cover', component: CoverSignUp },
  { path: '/auth-pass-reset-basic', component: BasicPasswReset },
  { path: '/auth-pass-reset-cover', component: CoverPasswReset },
  { path: '/auth-lockscreen-basic', component: BasicLockScreen },
  { path: '/auth-lockscreen-cover', component: CoverLockScreen },
  { path: '/auth-logout-basic', component: BasicLogout },
  { path: '/auth-logout-cover', component: CoverLogout },
  { path: '/auth-success-msg-basic', component: BasicSuccessMsg },
  { path: '/auth-success-msg-cover', component: CoverSuccessMsg },
  { path: '/auth-twostep-basic', component: BasicTwosVerify },
  { path: '/auth-twostep-cover', component: CoverTwosVerify },
  { path: '/auth-404-basic', component: Basic404 },
  { path: '/auth-404-cover', component: Cover404 },
  { path: '/auth-404-alt', component: Alt404 },
  { path: '/auth-500', component: Error500 },
  { path: '/pages-maintenance', component: Maintenance },
  { path: '/pages-coming-soon', component: ComingSoon },

  { path: '/landing', component: OnePage },
  { path: '/nft-landing', component: NFTLanding },

  { path: '/auth-pass-change-basic', component: BasicPasswCreate },
  { path: '/auth-pass-change-cover', component: CoverPasswCreate },
  { path: '/auth-offline', component: Offlinepage },
  { path: '/affiliate', component: AffiliatePage },
];

export { authProtectedRoutes, publicRoutes };
