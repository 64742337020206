import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { OrgChart } from 'd3-org-chart';
import { toast } from 'react-toastify';
import NoDataImg from '../../../../assets/images/nodata.png';
import ErrorImg from '../../../../assets/images/error.svg';

const Lineage = ({ chartData, loading, error }) => {
  document.title = 'Lineage - universal wallet';

  const d3Container = useRef(null);
  const [errors, setErrors] = useState(error?.response?.message);

  // We need to manipulate DOM
  useLayoutEffect(() => {
    let chart = null;

    if (chartData) {
      try {
        if (!chart) {
          chart = new OrgChart();
        }
        chart
          .container(d3Container.current)
          .data(chartData)
          .nodeHeight(d => 50)
          .compact(true)
          .onNodeClick((d, i, arr) => {
            console.log(d, 'Id of clicked node ');
          })
          .nodeContent((node, i, arr, state) => {
            return `<div class="${node.data.name && 'tooltip-container'} level-${
              node.data.level
            }" style="position:relative;height: 50px;border: 1px solid #fff;background: #000; display: flex;justify-content: center;align-items: center;font-size: 12px;border-radius: 3px;">
              <div class="name" style="padding: 3px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                ${node.data.rememberToken || node.data.name}
              </div>
              <span class="tooltips">${node.data.name}</span>
            </div>`;
          })
          .buttonContent(({ node, state }) => {
            if (node.data._directSubordinates > 1) {
              return `<div style="color:#2CAAE5;border-radius:5px;padding:3px;font-size:10px;margin:auto auto;background-color:#040910;border: 1px solid #2CAAE5">
                <i class="${node.children ? `mdi mdi-chevron-down` : `mdi mdi-chevron-up`}"></i>
                ${node.data._directSubordinates}
              </div>`;
            } else {
              return '';
            }
          })
          .render();
      } catch (err) {
        setErrors(err.message);
      }
    }
  }, [chartData]);

  useEffect(() => {
    if (errors) {
      toast.error(errors);
    }
  }, [errors]);

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Lineage" pageTitle="Pages" />
          <Row className="justify-content-center align-items-center" style={{ minHeight: 'calc(100vh - 200px)' }}>
            <Col className="text-center" lg={12}>
              {loading && <Spinner color="primary">Loading...</Spinner>}
              {!loading && !errors && chartData && <div ref={d3Container} />}
              {!loading && !errors && !chartData && <img src={NoDataImg} width={300} />}
              {!loading && errors && <img src={ErrorImg} width={300} />}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Lineage;
