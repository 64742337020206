const UseOfFunds = ({ details }) => {
  return (
    <>
      <h4>Use of Funds Report</h4>
      {details.useOfFundsReport.organizationalUnits.map((unit, index) => (
        <div key={index}>
          <h5>{unit.name}</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {unit.spend.map((item, idx) => (
                <tr key={idx}>
                  <td>{item.category}</td>
                  <td>${item.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </>
  );
};

export default UseOfFunds;
