import React from 'react';
import { CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { Link, useLocation } from 'react-router-dom';
import GICSImage from '../../../../assets/images/marketplaces/gics_logo_white.png';

export const Marketplaces = () => {
  document.title = 'Marketplaces | crwdcapital - universal wallet';

  const location = useLocation();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Marketplaces" pageTitle="Markets" />
          <Row>
            <Col lg={12}>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <div className="text-center">
                      <Link className="" to={`${location.pathname}/gics`}>
                        <img className="gallery-img img-fluid mx-auto" src={GICSImage} alt="gics-img" width="500px" />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
