// Import Images
import dashboard from '../../assets/images/vertical/dashboard.jpg';
import contracts from '../../assets/images/vertical/contracts.jpg';
import lineage from '../../assets/images/vertical/lineage.jpg';
import communication from '../../assets/images/vertical/communication.jpg';
import portfolio from '../../assets/images/vertical/my_portolio.jpg';
import watchlist from '../../assets/images/vertical/watchlist.jpg';
import orders from '../../assets/images/vertical/orders.jpg';
import learning from '../../assets/images/vertical/learning.jpg';
import identification from '../../assets/images/vertical/identification.jpg';
import organization from '../../assets/images/vertical/organization.jpg';
import compliance from '../../assets/images/vertical/identity_compliance.jpg';
import social from '../../assets/images/vertical/social_media.jpg';
import ecommerce from '../../assets/images/vertical/market_place.jpg';
import marketplace from '../../assets/images/vertical/gics_marketplace.jpg';
import ShopBuilder from '../../assets/images/vertical/website_builder.jpg';
import transactions from '../../assets/images/vertical/transactions.jpg';
import capitalSummary from '../../assets/images/vertical/capital_summary.jpg';
import commodities from '../../assets/images/vertical/commodities.jpg';
import financialSummary from '../../assets/images/vertical/financial_summary.jpg';
import organizationProfile from '../../assets/images/vertical/organization-profile.jpg';
import privateSecurities from '../../assets/images/vertical/gics_private_securities.jpg';
import digitalAsset from '../../assets/images/vertical/digital_assets.jpg';
import privateMarket from '../../assets/images/vertical/private_market.jpg';
import fundraising from '../../assets/images/vertical/fundraising.jpg';
import portfolioPerf from '../../assets/images/vertical/portfolio_performance.jpg';
import dealFlow from '../../assets/images/vertical/deal_flow.jpg';
import esgAsset from '../../assets/images/vertical/esg_asset.jpg';

import Img2 from '../../assets/images/nft/img-02.jpg';
import crwdUnit from '../../assets/images/vertical/crwdunit_circle.png';
import Img4 from '../../assets/images/nft/img-04.jpg';
import Img5 from '../../assets/images/nft/img-05.jpg';

import ImgGif3 from '../../assets/images/nft/gif/img-3.gif';
import ImgGif4 from '../../assets/images/nft/gif/img-4.gif';

import btcCoin from '../../assets/images/svg/crypto-icons/btc.svg';
import ltcCoin from '../../assets/images/svg/crypto-icons/ltc.svg';
import ethCoin from '../../assets/images/svg/crypto-icons/eth.svg';
import moneroCoin from '../../assets/images/svg/crypto-icons/xmr.svg';
import makerCoin from '../../assets/images/svg/crypto-icons/mkr.svg';
import dashCoin from '../../assets/images/svg/crypto-icons/dash.svg';
import neoCoin from '../../assets/images/svg/crypto-icons/neo.svg';

import lineage1 from '../../assets/images/vertical/lineage/AdobeStock_568457182.jpeg';
import lineage2 from '../../assets/images/vertical/lineage/AdobeStock_568457239.jpeg';
import lineage3 from '../../assets/images/vertical/lineage/AdobeStock_569787976.jpeg';
import lineage4 from '../../assets/images/vertical/lineage/AdobeStock_569788001.jpeg';
import lineage5 from '../../assets/images/vertical/lineage/AdobeStock_582843716.jpeg';
import lineage6 from '../../assets/images/vertical/lineage/AdobeStock_589718592.jpeg';
import lineage7 from '../../assets/images/vertical/lineage/AdobeStock_589718860.jpeg';
import lineage8 from '../../assets/images/vertical/lineage/AdobeStock_596602830.jpeg';
import lineage9 from '../../assets/images/vertical/lineage/AdobeStock_602241417.jpeg';
import lineage10 from '../../assets/images/vertical/lineage/AdobeStock_583260468.jpeg';
import lineage12 from '../../assets/images/vertical/lineage/AdobeStock_589718673.jpeg';
import lineage13 from '../../assets/images/vertical/lineage/AdobeStock_593887557.jpeg';
import lineage14 from '../../assets/images/vertical/lineage/AdobeStock_595334571.jpeg';
import lineage15 from '../../assets/images/vertical/lineage/AdobeStock_596870925.jpeg';

const ellipsis = [
  {
    id: 1,
    img: dashboard,
    title: 'DASHBOARD',
    link: '/my-dashboard',
    category: 'ellipsis',
  },
  {
    id: 1,
    img: contracts,
    title: 'CONTRACTS',
    link: '/my-contracts',
    category: 'ellipsis',
  },
  {
    id: 1,
    img: lineage,
    title: 'LINEAGE',
    link: '/my-lineage',
  },
  {
    id: 1,
    img: communication,
    title: 'COMMUNICATION',
    link: '/my-communication',
    category: 'ellipsis',
  },
  {
    id: 1,
    img: portfolio,
    title: 'PORTFOLIO',
    link: '/my-vault',
    category: 'vault',
  },
  {
    id: 1,
    img: watchlist,
    title: 'WATCHLIST',
    link: '/my-watchlist',
    category: 'vault',
  },
  {
    id: 1,
    img: orders,
    title: 'ORDERS',
    link: '/my-orders',
    category: 'vault',
  },
  {
    id: 1,
    img: learning,
    title: 'LEARNING',
    link: '/my-learning',
    category: 'vault',
  },
  {
    id: 1,
    img: identification,
    title: 'IDENTIFICATION',
    link: '/identity-summary',
    category: 'identity',
  },
  {
    id: 1,
    img: organization,
    title: 'ORGANIZATION',
    link: '/my-organization',
    category: 'identity',
  },
  {
    id: 1,
    img: compliance,
    title: 'COMPLIANCE',
    link: '/identity-compliance',
    category: 'identity',
  },
  {
    id: 1,
    img: social,
    title: 'SOCIAL',
    link: '/social-identity',
    category: 'identity',
  },
  {
    id: 1,
    img: ecommerce,
    title: 'eCOMMERCE',
    link: '/market-summary',
    category: 'market',
  },
  {
    id: 1,
    img: marketplace,
    title: 'MARKETPLACE',
    link: '/markets/shop',
    category: 'market',
  },
  {
    id: 1,
    img: ShopBuilder,
    title: 'SHOP BUILDER',
    link: 'https://app.crwd.shop/dashboard',
    category: 'market',
  },
  {
    id: 1,
    img: transactions,
    title: 'TRANSACTIONS',
    link: '/shop-transactions',
    category: 'market',
  },
  {
    id: 1,
    img: capitalSummary,
    title: 'CAPITAL SUMMARY',
    link: '/capital-summary',
    category: 'capital',
  },
  {
    id: 1,
    img: transactions,
    title: 'CAPITAL TRANSACTIONS',
    link: '/capital-transactions',
    category: 'capital',
  },
  {
    id: 1,
    img: esgAsset,
    title: 'ESG Assets',
    link: '/esg-assets',
    category: 'finance',
  },
  {
    id: 1,
    img: commodities,
    title: 'COMMODITIES',
    link: '/commodities',
    category: 'finance',
  },
  {
    id: 1,
    img: financialSummary,
    title: 'Financial Summary',
    link: '/financial-summary',
    category: 'finance',
  },
  {
    id: 1,
    img: organizationProfile,
    title: 'Organization Security',
    link: '/organization-profile',
    category: 'finance',
  },
  {
    id: 1,
    img: privateSecurities,
    title: 'Capital Stock',
    link: '/finance/property-tickers',
    category: 'finance',
  },
  {
    id: 1,
    img: digitalAsset,
    title: 'Digital Assets',
    link: '/digital-assets',
    category: 'finance',
  },
  {
    id: 1,
    img: privateMarket,
    title: 'Private Market',
    link: '/dashboard-private',
    category: 'market',
  },
  {
    id: 1,
    img: dealFlow,
    title: 'Deal Flow',
    link: '/deal-flow',
    category: 'finance',
  },
  {
    id: 1,
    img: fundraising,
    title: 'Fundraising',
    link: '/fund-raising',
    category: 'finance',
  },
  {
    id: 1,
    img: portfolioPerf,
    title: 'Portfolio Performance',
    link: '/portfolio-performance',
    category: 'finance',
  },
  // {
  //   id: 8,
  //   img: ImgGif4,
  //   date: '2023/6/25',
  //   likes: '94.1',
  //   price: '245.23',
  //   title: 'EVOLVED REALITY',
  //   available: '26',
  //   sold: '9974',
  //   progressClass: 'danger',
  //   size: '97%',
  //   fileType: 'gif',
  //   category: 'upto-15',
  // },
];

const ellipsisAssets = [
  {
    id: 1,
    img: crwdUnit,
    title: 'crwdunit',
    price: '0.01 USD',
    link: '/capital/crwdunit+',
    time: 'Just Now',
  },
  {
    id: 2,
    img: ImgGif4,
    title: 'Patterns arts & culture',
    price: '147.83 ETH',
    link: '/apps-nft-item-details',
    time: '3 sec ago',
  },
  {
    id: 3,
    img: ImgGif3,
    title: 'Evolved Reality',
    price: '34.81 ETH',
    link: '/apps-nft-item-details',
    time: '2 min ago',
  },
  {
    id: 4,
    img: Img4,
    title: 'Smillevers Crypto',
    price: '47.9 ETH',
    link: '/apps-nft-item-details',
    time: '26 min ago',
  },
  {
    id: 5,
    img: Img5,
    title: 'Robotic Body Art',
    price: '134.32 ETH',
    link: '/apps-nft-item-details',
    time: '1 hrs ago',
  },
  {
    id: 6,
    img: Img2,
    title: 'Trendy fashion portraits',
    price: '643.19 ETH',
    link: '/apps-nft-item-details',
    time: '3 hrs ago',
  },
];

const dealFlowWatchlist = [
  {
    id: 1,
    img: btcCoin,
    coinName: 'Deal 1',
    price: '$46,335.40',
    percentage: '+0.63%',
    percentageClass: 'success',
    coinNameAbbr: 'BTC',
    chartsColor: '#0ab39c',
    series: [
      {
        name: 'Bitcoin',
        data: [85, 68, 35, 90, 8, 11, 26, 54],
      },
    ],
  },
  {
    id: 2,
    img: ltcCoin,
    coinName: 'Deal 2',
    price: '$65.64',
    percentage: '-3.42%',
    percentageClass: 'danger',
    coinNameAbbr: 'LTC',
    chartsColor: '#f06548',
    series: [
      {
        name: 'Litecoin',
        data: [25, 50, 41, 87, 12, 36, 9, 54],
      },
    ],
  },
  {
    id: 3,
    img: ethCoin,
    coinName: 'Deal 3',
    price: '$3,748.66',
    percentage: '+0.42%',
    percentageClass: 'danger',
    coinNameAbbr: 'ETH',
    chartsColor: '#f06548',
    series: [
      {
        name: 'Eathereum',
        data: [36, 21, 65, 22, 35, 50, 29, 44],
      },
    ],
  },
  {
    id: 4,
    img: moneroCoin,
    coinName: 'Deal 4',
    price: '$226.55',
    percentage: '-1.92%',
    percentageClass: 'danger',
    coinNameAbbr: 'XMR',
    chartsColor: '#f06548',
    series: [
      {
        name: 'Binance',
        data: [30, 58, 29, 89, 12, 36, 9, 54],
      },
    ],
  },
  {
    id: 5,
    img: dashCoin,
    coinName: 'Deal 5',
    price: '$142.5',
    percentage: '+16.38%',
    percentageClass: 'success',
    coinNameAbbr: 'DASH',
    chartsColor: '#0ab39c',
    series: [
      {
        name: 'Dash',
        data: [24, 68, 39, 86, 29, 42, 11, 58],
      },
    ],
  },
  {
    id: 6,
    img: makerCoin,
    coinName: 'Deal 6',
    price: '$2,390.75',
    percentage: '+0.36%',
    percentageClass: 'success',
    coinNameAbbr: 'MKR',
    chartsColor: '#0ab39c',
    series: [
      {
        name: 'Dash',
        data: [13, 76, 12, 85, 25, 60, 9, 54],
      },
    ],
  },
  {
    id: 7,
    img: neoCoin,
    coinName: 'Deal 7',
    price: '$2,145.65',
    percentage: '32.07%',
    percentageClass: 'success',
    coinNameAbbr: 'NEO',
    chartsColor: '#0ab39c',
    series: [
      {
        name: 'Neo',
        data: [9, 66, 41, 89, 12, 36, 25, 54],
      },
    ],
  },
];

const topLineage = [
  {
    id: 1,
    category: 'E-commerce',
    quantity: '206',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage1 }],
      },
    ],
  },
  {
    id: 2,
    category: 'Afiliate',
    quantity: '743',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage2 }],
      },
    ],
  },
  {
    id: 3,
    category: 'Terms and Conditions',
    quantity: '679',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage3 }],
      },
    ],
  },
  {
    id: 4,
    category: 'Privacy Policy',
    quantity: '341',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage4 }],
      },
    ],
  },
  {
    id: 5,
    category: 'Sales',
    quantity: '1452',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage5 }],
      },
    ],
  },
  {
    id: 6,
    category: 'Sales Contracts',
    quantity: '4781',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage6 }],
      },
    ],
  },
  {
    id: 7,
    category: 'Distribution Agreements',
    quantity: '3468',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage7 }],
      },
    ],
  },
  {
    id: 8,
    category: 'Consignment Agreements',
    quantity: '1674',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage8 }],
      },
    ],
  },
  {
    id: 8,
    category: 'Capital Raise',
    quantity: '1674',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage9 }],
      },
    ],
  },
  {
    id: 8,
    category: 'Investment Agreements',
    quantity: '1674',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage10 }],
      },
    ],
  },
  {
    id: 8,
    category: 'Subscription Agreements',
    quantity: '1674',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage12 }],
      },
    ],
  },
  {
    id: 8,
    category: 'Loan Agreements',
    quantity: '1674',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage13 }],
      },
    ],
  },
  {
    id: 8,
    category: 'Mortgage Agreements',
    quantity: '1674',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage14 }],
      },
    ],
  },
  {
    id: 8,
    category: 'Account Agreements',
    quantity: '1674',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage15 }],
      },
    ],
  },
  {
    id: 8,
    category: 'Credit Card Agreements',
    quantity: '1674',
    subItem: [
      {
        id: 1,
        isChildren: [{ id: 1, img: lineage6 }],
      },
    ],
  },
];

export { ellipsis, ellipsisAssets, dealFlowWatchlist, topLineage };
