import React, { useState } from 'react';

import CompanyDocumentData from './company_documents.json';
import CompanyOverviewData from '../OverviewTab/company_overview.json';
import OperationalReportsData from './company_operational_reports.json';
import TechnologyReportsData from './company_technology_report.json';

import CompanyDocument from '../CompanyDocument';
import CompanyDocuments from './CompanyDocuments';
import OperationalReports from './OperationalReports';
import ManagementReports from './ManagementReports';

const GovernanceOperationsTab = () => {
  const [selectedSection, setSelectedSection] = useState('companyDocuments');

  const toggleDocumentSection = value => {
    setSelectedSection(value);
  };
  return (
    <React.Fragment>
      <div className="company-category">
        <span
          className={selectedSection === 'companyDocuments' ? 'active' : ''}
          onClick={() => toggleDocumentSection('companyDocuments')}
        >
          Company Documents
        </span>
        <span
          className={selectedSection === 'operationalReports' ? 'active' : ''}
          onClick={() => toggleDocumentSection('operationalReports')}
        >
          Company Operational Reports
        </span>
        <span
          className={selectedSection === 'managementReports' ? 'active' : ''}
          onClick={() => toggleDocumentSection('managementReports')}
        >
          Company Management Reports
        </span>
      </div>
      <CompanyDocument header={CompanyOverviewData.header} details={CompanyOverviewData}>
        {selectedSection === 'companyDocuments' && <CompanyDocuments details={CompanyDocumentData} />}
        {selectedSection === 'operationalReports' && <OperationalReports details={OperationalReportsData} />}
        {selectedSection === 'managementReports' && <ManagementReports details={TechnologyReportsData} />}
      </CompanyDocument>
    </React.Fragment>
  );
};

export default GovernanceOperationsTab;
