import Loader from 'Components/Common/Loader';
import React, { useState } from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import { useAuth } from 'shared/auth';
import { esRequest } from 'utils/api';
import { abbreviateNumber } from 'utils/helpers';
import { ecomWidgets } from '../../common/data';

const Widgets = () => {
  const [customerCount, setCustomerCount] = useState(undefined);
  const [ecomWidgetsData, setEcomWidgetsData] = useState([]);
  const auth = useAuth();
  const getCustomerCount = () =>
    esRequest(
      'esmaster/crwd_wallet_sigmaprime_address/_count',
      {},
      {
        headers: {
          authorization: `Bearer ${auth.user?.id_token}`,
        },
      },
    ).then(response => {
      setCustomerCount(response.data.count);
      setEcomWidgetsData([
        {
          id: 3,
          cardColor: 'success',
          label: 'Total identities',
          badge: 'ri-arrow-right-up-line',
          badgeClass: 'success',
          percentage: '+29.08',
          counter: abbreviateNumber(response.data.count).number,
          link: 'View Identities',
          page: '/dashboard-identity',
          bgcolor: 'warning',
          icon: 'bx bx-user-circle',
          decimals: 2,
          prefix: '',
          suffix: abbreviateNumber(response.data.count).suffix,
        },
        ...ecomWidgets,
      ]);
    });
  if (customerCount === undefined) {
    getCustomerCount();
  }

  return (
    <React.Fragment>
      {customerCount !== undefined ? (
        ecomWidgetsData.map((item, key) => (
          <Col xl={3} md={6} key={key}>
            <Card className="card-animate">
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{item.label}</p>
                  </div>
                  <div className="flex-shrink-0">
                    <h5 className={'fs-14 mb-0 text-' + item.badgeClass}>
                      {item.badge ? <i className={'fs-13 align-middle ' + item.badge}></i> : null} {item.percentage} %
                    </h5>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                      <span className="counter-value" data-target="559.25">
                        <CountUp
                          start={0}
                          prefix={item.prefix}
                          suffix={item.suffix}
                          separator={item.separator}
                          end={item.counter}
                          decimals={item.decimals}
                          duration={4}
                        />
                      </span>
                    </h4>
                    <Link to={item.page} className="text-decoration-underline">
                      {item.link}
                    </Link>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className={'avatar-title rounded fs-3 bg-soft-' + item.bgcolor}>
                      <i className={`text-${item.bgcolor} ${item.icon}`}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
};

export default Widgets;
