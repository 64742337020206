import { useMemo } from 'react';
import { GraphQLClient } from 'graphql-request';
import { DocumentNode } from 'graphql';
import { HasuraDataAdapter, HasuraGraphQLNamingConvention } from 'Components/Admin/DataAdapter';
import { AdminTableHasuraAdapter } from 'Components/Admin/AdminTable/AdminTableAdapter';
import { useClient } from 'shared/UserContext';

const namingConvention: HasuraGraphQLNamingConvention = 'graphqlDefault';

export interface DataAdapters {
  dataAdapter: HasuraDataAdapter;
  tableAdapter: AdminTableHasuraAdapter;
}

export function useDataAdapter(typename: string, fieldsFragment: DocumentNode, client?: GraphQLClient): DataAdapters {
  const contextClient = useClient();
  const dataAdapter = useMemo(
    () => new HasuraDataAdapter(client || contextClient, typename, fieldsFragment, namingConvention),
    [typename, fieldsFragment, client, contextClient],
  );
  const tableAdapter = useMemo(
    () => new AdminTableHasuraAdapter(dataAdapter, { isDeleted: { _eq: false } }),
    [typename, fieldsFragment, client, contextClient],
  );
  return { dataAdapter, tableAdapter };
}
