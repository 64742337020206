import React, { useEffect, useState } from 'react';
import { CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { useHistory } from 'react-router-dom';
import Masonry from 'react-masonry-component';
import { gics, gics_logo } from '../../../../common/data';
import { NoDataTemplate } from 'Components/NoDataTemplate';
import LinkTemplate from './LinkTemplate';

const GICSLinks = props => {
  document.title = 'GICS Links | crwdcapital - universal wallet';

  const [sectorLinks, setSectorLinks] = useState<any[]>([]);
  const history = useHistory();

  useEffect(() => {
    const gicsName = props.match?.params?.id;
    const sectors = gics.filter(sector => sector.id === gicsName);
    if (sectors.length) {
      const sector = sectors[0];
      setSectorLinks(sector.links);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Links" pageTitle="GICS" />
          <Row>
            <Col lg={12}>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    {sectorLinks.length ? (
                      <Masonry className="row gallery-wrapper">
                        {sectorLinks.map(({ id, img, title, link }, key) => (
                          <Col xxl={3} xl={4} sm={6} className="element-item project designing development" key={key}>
                            <LinkTemplate
                              img={img}
                              title={title}
                              onClick={() => link && history.push(`${history.location.pathname}/view/${id}`)}
                            />
                          </Col>
                        ))}
                        <Col
                          xxl={3}
                          xl={4}
                          sm={6}
                          className="element-item project designing development"
                          key={sectorLinks.length}
                        >
                          <LinkTemplate
                            img={gics_logo.img}
                            title={gics_logo.title}
                            onClick={() => history.push(`/markets/marketplaces/gics`)}
                          />
                        </Col>
                      </Masonry>
                    ) : (
                      <NoDataTemplate />
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GICSLinks;
