import React from 'react';
import { Col, Container, Row } from 'reactstrap';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Pagination, Navigation, Autoplay } from 'swiper';

const Reviews = () => {
  return (
    <React.Fragment>
      <section className="section bg-primary" id="reviews">
        <div className="bg-overlay bg-overlay-pattern"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <div className="text-center">
                <div>
                  <i className="ri-double-quotes-l text-success display-3"></i>
                </div>
                <h4 className="text-white mb-5">
                  <span className="text-success">19k</span>+ Satisfied clients
                </h4>

                <Swiper
                  modules={[Navigation, Pagination, Autoplay]}
                  pagination={{ clickable: true }}
                  navigation={true}
                  loop={true}
                  autoplay={{ delay: 2500, disableOnInteraction: false }}
                  className="mySwiper swiper client-review-swiper rounded"
                >
                  <div className="swiper-wrapper">
                    <SwiperSlide>
                      <div className="row justify-content-center">
                        <div className="col-10">
                          <div className="text-white-50">
                            <p className="fs-20 ff-secondary mb-4">
                              " I am givng 5 stars. Theme is great and everyone one stuff everything in theme. Future
                              request should not affect current state of theme. "
                            </p>

                            <div>
                              <h5 className="text-white">gregoriusus</h5>
                              <p>- Skote User</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row justify-content-center">
                        <div className="col-10">
                          <div className="text-white-50">
                            <p className="fs-20 ff-secondary mb-4">
                              " Awesome support. Had few issues while setting up because of my device, the support team
                              helped me fix them up in a day. Everything looks clean and good. Highly recommended! "
                            </p>

                            <div>
                              <h5 className="text-white">GeekyGreenOwl</h5>
                              <p>- Skote User</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row justify-content-center">
                        <div className="col-10">
                          <div className="text-white-50">
                            <p className="fs-20 ff-secondary mb-4">
                              " Amazing template, Redux store and components is nicely designed. It's a great start
                              point for an admin based project. Clean Code and good documentation. Template is
                              completely in React and absolutely no usage of jQuery "
                            </p>

                            <div>
                              <h5 className="text-white">sreeks456</h5>
                              <p>- Veltrix User</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </div>
                  <div className="swiper-button-next bg-white rounded-circle"></div>
                  <div className="swiper-button-prev bg-white rounded-circle"></div>
                  <div className="swiper-pagination position-relative mt-2"></div>
                </Swiper>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Reviews;
