import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Watchlist from './Watchlist';
import PortfolioStatistics from './PortfolioStatistics';
import MarketStatus from './MarketStatus';
import Widgets from './Widgets';
import RecentTransaction from './RecentTransaction';
import SilverPriceChart from './SilverPriceChart';

const MyVault = () => {
  document.title = 'MY VAULT | crwdcapital - universal wallet';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Portfolio" pageTitle="My Vault" />
          <Row>
            <Col xxl={9}>
              <PortfolioStatistics dataColors='["--vz-info"]' />
              <Watchlist />
              <SilverPriceChart />
              <MarketStatus />
            </Col>
            <Col xxl={3}>
              <Widgets />
              <RecentTransaction />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyVault;
