import React from 'react';
import ReactApexChart from 'react-apexcharts';

const GicsBar = ({ data }) => {
  const series = [
    {
      name: 'Performance',
      type: 'column',
      data: data,
    },
  ];
  const options = {
    plotOptions: {
      bar: {
        horizontal: false,
      },
      dataLabels: {
        enabled: false,
      },
      columnWidth: '80%',
    },
    colors: [
      function ({ value }) {
        if (value > 0) {
          return '#00cfe5';
        } else {
          return '#e80074';
        }
      },
    ],
    yaxis: {
      title: {
        text: 'Performance',
      },
      labels: {
        formatter: function (y) {
          return y.toFixed(0) + '%';
        },
      },
    },
    xaxis: {
      // type: 'category',
      categories: [
        'Today',
        '1 Day',
        '5 Day',
        '1 Month',
        '3 Month',
        'Year-to-Date',
        '1 Year',
        '3 Year',
        '5 Year',
        '10 Year',
      ],
      labels: {
        rotate: -90,
      },
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </React.Fragment>
  );
};

export { GicsBar };
