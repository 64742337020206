import NoDataImg from '../assets/images/nodata2.png';

export const NoDataTemplate = () => (
  <div
    className="w-full h-full flex justify-content-center align-items-center"
    style={{ minHeight: 'calc(100vh - 175px)' }}
  >
    <img src={NoDataImg} width="300px" />
  </div>
);
