import { useState } from 'react';
import { Card, Col, Collapse, Container, Row } from 'reactstrap';
import classnames from 'classnames';
import DPOFactsData from './dpo.json';
// Import Images
import DpoBuySellImg from '../../../assets/images/dpo_buy_sell.png';

const DPOFacts = () => {
  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);

  const toggleItems = (rowIndex, columnIndex, accordionIndex) => {
    const key: string = `${rowIndex}_${columnIndex}_${accordionIndex}`;
    if (collapsedItems.includes(key)) {
      const collapsedItemLists = collapsedItems.filter(item => item !== key);
      setCollapsedItems([...collapsedItemLists]);
    } else {
      setCollapsedItems([...collapsedItems, key]);
    }
  };

  const isCollapsed = (rowIndex, columnIndex, accordionIndex) => {
    const key: string = `${rowIndex}_${columnIndex}_${accordionIndex}`;
    return collapsedItems.includes(key);
  };

  document.title = 'FAQs | crwdcapital - universal wallet';

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            {/* TOP HEADER */}
            <Card className="rounded-0 bg-soft-success mx-n4 mt-n4 border-top">
              <div className="px-4">
                <Row>
                  <Col xxl={5} className="align-self-center">
                    <div className="py-4">
                      <h4 className="display-6 coming-soon-text">DIRECT PUBLIC OFFERING HANDBOOK</h4>
                      <p className="text-success fs-15 mt-3">
                        By now, you've probably heard of the concepts associated with Stock Markets, Initial Public
                        Offerings (IPO), or maybe even the current Crypto Currency craze called Initial Crypto Offerings
                        (ICO). But have you heard of Direct Public Offerings (DPO)? The Jumpstart Our Business Startups
                        Act, or JOBS Act, is a law intended to encourage funding of small businesses in the United
                        States by easing many of the country's securities regulations. It was passed with bipartisan
                        support and was signed into law by President Barack Obama on April 5, 2012. The JOBS Act has
                        successfully incentivized pre-IPO financing rounds and provided companies with more flexibility
                        for those who might want to do an IPO in the future.
                      </p>
                      <div className="hstack flex-wrap gap-2">
                        <button type="button" className="btn btn-primary btn-label rounded-pill">
                          <i className="ri-mail-line label-icon align-middle rounded-pill fs-16 me-2"></i> Email Us
                        </button>
                        <button type="button" className="btn btn-info btn-label rounded-pill">
                          <i className="ri-message-line label-icon align-middle rounded-pill fs-16 me-2"></i> Post A
                          Comment
                        </button>
                      </div>
                    </div>
                  </Col>
                  <div className="col-xxl-7 ms-auto">
                    <img src={DpoBuySellImg} alt="" />
                  </div>
                </Row>
              </div>
            </Card>
            {/* ROW-1 */}

            {DPOFactsData.map((DPOFact, rowIndex) => (
              <Row className="justify-content-evenly">
                {DPOFact.map((dpoData, columnIndex) => (
                  <Col lg={4}>
                    <div className="mt-3">
                      <div className="d-flex align-items-center mb-2">
                        <div className="flex-shrink-0 me-1">
                          <i className={dpoData.icon}></i>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="fs-16 mb-0 fw-semibold">{dpoData.heading}</h5>
                        </div>
                      </div>

                      {dpoData.accordions.map((accordion, accordionIndex) => (
                        <div className="accordion accordion-border-box" id="genques-accordion">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="genques-headingOne">
                              <button
                                className={classnames('accordion-button', 'fw-medium', {
                                  collapsed: (rowIndex, columnIndex, accordionIndex) =>
                                    !isCollapsed(rowIndex, columnIndex, accordionIndex),
                                })}
                                type="button"
                                onClick={() => toggleItems(rowIndex, columnIndex, accordionIndex)}
                                style={{ cursor: 'pointer' }}
                              >
                                {accordion.heading}
                              </button>
                            </h2>
                            <Collapse
                              isOpen={isCollapsed(rowIndex, columnIndex, accordionIndex)}
                              className="accordion-collapse"
                            >
                              <div className="accordion-body">{accordion.details}</div>
                            </Collapse>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>
                ))}
              </Row>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DPOFacts;
