import { useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { FeedItem } from './interface';
import './style.scss';

const CapitalTab = () => {
  const [feed, setFeed] = useState<FeedItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/get_all_news_sentiment');
      const data = await response.json();
      setFeed(data);
    };
    fetchData();
  }, []);

  const getDisplayDate = (date: string) => {
    const dateFormat = "yyyyMMdd'T'HHmmss";
    const formattedDate = parse(date, dateFormat, new Date());
    return format(formattedDate, 'PPpp');
  };

  return (
    <>
      <div className="masonry-grid">
        {feed.map((item, key) => (
          <div className="masonry-grid-item" key={key}>
            <Card className="border">
              <CardBody>
                <a href={item.url} target="_blank">
                  <div className="d-sm-flex">
                    <div className="flex-shrink-0 image-container">
                      {item.banner_image ? (
                        <img src={item.banner_image} alt="" width="115" className="rounded-1" />
                      ) : null}
                    </div>
                    <div className="flex-grow-1 ms-sm-4  mt-sm-0">
                      <div className="d-flex justify-content-between my-2">
                        <div className="">
                          {item.topics.map((topic, index) => (
                            <span key={index} className={`badge badge-soft-info fs-11`}>
                              {topic.topic}
                            </span>
                          ))}
                        </div>
                        <span className={`badge badge-soft-success fs-11 sentiment-label`}>
                          {item.overall_sentiment_label}
                        </span>
                      </div>
                      <h5>{item.title}</h5>
                      <p className="summary">{item.summary}</p>
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <i className="ri-user-3-fill text-success align-middle me-1"></i> {item.authors.join(', ')}
                        </li>
                        <li className="list-inline-item">
                          <i className="ri-calendar-2-fill text-success align-middle me-1"></i>{' '}
                          {getDisplayDate(item.time_published)}
                        </li>
                      </ul>
                    </div>
                  </div>
                </a>
              </CardBody>
            </Card>
          </div>
        ))}
      </div>
      {feed.length ? (
        <div className="d-flex mb-4">
          <Link to="dashboard-markets" className="btn btn-primary btn-lg mx-auto">
            LEARN MORE
          </Link>
        </div>
      ) : null}
    </>
  );
};

export default CapitalTab;
