import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import jsonData from './data.json';
import { OrgChart } from 'd3-org-chart';

const SiteMap = () => {
  document.title = 'SiteMap | crwdcapital - universal wallet';

  const d3Container = useRef(null);
  const [chartData, setChartData] = useState([]);

  const getData = (node, color) => {
    const nodeSettings = { color: 'white', width: 200, height: 60 };
    let name;
    if (node.personLastName || node.personFirstName) {
      name = `${node.personFirstName || ''} ${node.personLastName || ''}`;
    } else {
      name = node.email || node.personEmail;
    }
    return {
      data: { profileId: node.profileId, name: name },
      ...nodeSettings,
      linkColor: color,
      background: color,
    };
  };

  let root = {
    ...getData(jsonData, '#080717'),
    children: [],
  };
  let currentChartData = {
    name: root.data.name,
    id: root.data.profileId,
    parentId: null,
    level: 1,
  };
  chartData.push(currentChartData);
  let userNode = root;
  // Add referrers (parent)
  let referrer = jsonData.referrer;
  while (referrer) {
    currentChartData.parentId = referrer.profileId;
    root = {
      ...getData(referrer, '#a62383'),
      children: [root],
    };

    currentChartData = {
      name: root.data.name,
      id: root.data.profileId,
      parentId: null,
      level: 0,
    };
    chartData.push(currentChartData);

    referrer = referrer.referrer;
  }

  // Add referrals (children)
  userNode.children = jsonData.referrals.map(childLevel1 => {
    const data = getData(childLevel1, '#040305');
    chartData.push({
      name: data.data.name,
      id: data.data.profileId,
      parentId: jsonData.profileId,
      level: 2,
    });

    return {
      ...data,
      children: childLevel1.referrals.map(childLevel2 => {
        const data = getData(childLevel2, '#040305');
        chartData.push({
          name: data.data.name,
          id: data.data.profileId,
          parentId: childLevel1.profileId,
          level: 2,
        });
        return {
          ...data,
          children: childLevel2.referrals.map(childLevel3 => {
            const data = getData(childLevel3, '#040305');
            chartData.push({
              name: data.data.name,
              id: data.data.profileId,
              parentId: childLevel2.profileId,
              level: 2,
            });
            return {
              ...data,
            };
          }),
        };
      }),
    };
  });
  chartData.forEach(d => (d._expanded = true));
  if (!chartData.length) {
    setChartData([...chartData]);
  }

  // We need to manipulate DOM
  useLayoutEffect(() => {
    let chart = null;

    if (chartData && d3Container.current) {
      if (!chart) {
        chart = new OrgChart();
      }
      chart
        .container(d3Container.current)
        .data(chartData)
        .nodeWidth(d => 130)
        .nodeHeight(d => 50)
        .compact(true)
        .onNodeClick((d, i, arr) => {
          console.log(d, 'Id of clicked node ');
        })
        .nodeContent((node, i, arr, state) => {
          return `<div class="level-${node.data.level}" style="height: 50px;border: 1px solid #fff;background: #000; display: flex;justify-content: center;align-items: center;font-size: 12px;border-radius: 3px;">
            <div class="name">${node.data.name}</div>
          </div>`;
        })
        .buttonContent(({ node, state }) => {
          if (node.data._directSubordinates > 1) {
            return `<div style="color:#2CAAE5;border-radius:5px;padding:3px;font-size:10px;margin:auto auto;background-color:#040910;border: 1px solid #2CAAE5">
              <i class="${node.children ? `mdi mdi-chevron-down` : `mdi mdi-chevron-up`}"></i>
              ${node.data._directSubordinates}
            </div>`;
          } else {
            return '';
          }
        })
        .render();
    }
  }, [chartData]);

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="SiteMap" pageTitle="Pages" />
          <Row>
            <Col lg={12}>
              <div ref={d3Container} />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default SiteMap;
