import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const FinanceTab = () => {
  const [loading, setLoading] = useState(false);
  const [commodities, setCommodities] = useState() as any;
  const getCommodities = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_COMMODITIES_API_ENDPOINT}?access_key=${process.env.REACT_APP_COMMODITIES_API_KEY}`,
      );
      debugger;
      const data = await response.json();
      setCommodities(data);
      setLoading(false);
    } catch (error) {
      console.error('Error querying Alpha Vantage API:', error);
    }
  };

  useEffect(() => {
    if (!loading && !commodities) {
      getCommodities();
    }
  });

  const options = {
    plotOptions: {
      bar: {
        horizontal: false,
      },
      dataLabels: {
        enabled: false,
      },
      columnWidth: '80%',
    },
    colors: [
      function ({ value }) {
        if (value > 0) {
          return '#00cfe5';
        } else {
          return '#e80074';
        }
      },
    ],
    yaxis: {
      title: {
        text: 'Performance',
      },
      labels: {
        formatter: function (y) {
          return y.toFixed(0) + '%';
        },
      },
    },
    xaxis: {
      // type: 'category',
      labels: {
        rotate: -90,
      },
    },
  };

  return <ReactApexChart options={options} series={commodities} type="bar" height={350} />;
};
export default FinanceTab;
