const CompetitorReport = ({ details }) => {
  return (
    <>
      <h4>Competitor Risk</h4>
      <ul>
        {details.requiredCompetitorReports.map((report, index) => (
          <li key={index}>
            <h5>{report.name}</h5>
            <p>{report.description}</p>
            <a href={report.link}>View Report</a>
          </li>
        ))}
      </ul>
      <hr className="my-4" />

      <h4>Contact Information</h4>
      <div className="row">
        <div className="col">
          <p>
            <strong>Email:</strong> {details.contactInformation.email}
          </p>
          <p>
            <strong>Phone:</strong> {details.contactInformation.phone}
          </p>
          <p>
            <strong>Address:</strong> {details.contactInformation.address}
          </p>
        </div>
      </div>
    </>
  );
};

export default CompetitorReport;
