import { startCase } from 'lodash';
import Status from './status.json';
import StatusKind from './statusKind.json';
import Organization from './oragnization.json';
import OrganizationKind from './organizationkind.json';
import SicCode from './sicCode.json';
import Subindustry from './subIndustry.json';
import Industry from './industry.json';
import Group from './group.json';
import GicsSector from './gicsSector.json';
import Country from './country.json';
import CurrencyKind from './currencyKind.json';
import Currency from './currency.json';
import CurrencyOwner from './currencyOwner.json';
import WalletKind from './walletKind.json';
import Wallet from './wallet.json';
import Certification from './certification.json';
import ClearinghouseKind from './clearinghouseKind.json';
import Clearinghouse from './clearinghouse.json';
import ClearinghouseCertification from './clearinghouseCertification.json';
import Merchant from './merchant.json';
import MerchantCurrency from './merchantCurrency.json';
import MerchantOrganization from './merchantOrganization.json';
import Settings from './settings.json';
import Sic from './sicCode.json';
import Transaction from './transaction.json';
import TransactionKind from './transactionKind.json';
import TradeKind from './tradeKind.json';
import Exchange from './exchange.json';
import ExchangeKind from './exchangeKind.json';
import SmartContractKind from './smartContractKind.json';
import SmartContract from './smartContract.json';
import Appraisal from './appraisal.json';
import AssetKind from './assetKind.json';
import Asset from './asset.json';
import Trade from './trade.json';
import Ask from './ask.json';
import Interaction from './interaction.json';
import Put from './put.json';
import SigmaPrime from './sigmaPrime.json';
import SigmaPrimeAddress from './sigmaPrimeAddress.json';
import SigmaPrimeKind from './sigmaPrimeKind.json';
import User from './user.json';
import AddressAttributes from './addressAttributes.json';
import SmartContractLineage from './smartContractLineage.json';
import ProductStatus from './productStatus.json';
import DocumentUser from './documentUser.json';
import SmartContractCommission from './smartContractCommission.json';
import SmartContractDocument from './smartContractDocument.json';
import WalletInventory from './walletInventory.json';
import AssetAccount from './assetAccount.json';
import ProductDistribution from './productDistribution.json';
import Order from './order.json';
import Product from './product.json';
import ProductKind from './productKind.json';
import Material from './material.json';
import Cart from './cart.json';
import Market from './market.json';
import OrganizationStatus from './organizationStatus.json';
import Shipping from './shipping.json';
import Shop from './shop.json';
import SupplyChainWaypoint from './supplyChainWaypoint.json';
import { SelectItemOptionsType } from 'primereact/selectitem';
import Payment from './payment.json';
import AskPutTransaction from './askPutTransaction.json';
import Datasource from './datasource.json';
import KycKind from './kycKind.json';
import KycStatus from './kycStatus.json';
// import Schemas from "../../graphql/generated/schema.json";

export const Schema: any = {
  Status,
  StatusKind,
  Organization,
  OrganizationKind,
  GicsSector,
  Group,
  Industry,
  SicCode,
  Subindustry,
  Country,
  CurrencyKind,
  Currency,
  CurrencyOwner,
  WalletKind,
  Wallet,
  Certification,
  ClearinghouseKind,
  Clearinghouse,
  ClearinghouseCertification,
  Merchant,
  MerchantCurrency,
  MerchantOrganization,
  Settings,
  Sic,
  Transaction,
  TransactionKind,
  TradeKind,
  Exchange,
  SmartContractKind,
  SmartContract,
  SmartContractCommission,
  SmartContractDocument,
  Appraisal,
  AssetKind,
  Asset,
  Trade,
  Ask,
  Put,
  Interaction,
  SigmaPrime,
  SigmaPrimeKind,
  ExchangeKind,
  User,
  SigmaPrimeAddress,
  AddressAttributes,
  SmartContractLineage,
  ProductStatus,
  DocumentUser,
  WalletInventory,
  AssetAccount,
  ProductDistribution,
  Order,
  Product,
  ProductKind,
  Material,
  Cart,
  Market,
  OrganizationStatus,
  Shipping,
  Shop,
  SupplyChainWaypoint,
  Payment,
  AskPutTransaction,
  Datasource,
  KycKind,
  KycStatus,
};

export interface EntityFieldProps {
  name: string;
  label: string;
  type: string;
  entityName: string;
  entityFieldLabel: string;
  required: boolean;
  staticOptions?: SelectItemOptionsType;
  blankValuePlaceholder: string;
  cssStyle: string;
  tabName: string;
  disabled?: boolean;
}

export const getEntityFields = (
  entityName: string,
  excludeColumns: Array<string> = [],
  hideUUIDs: boolean = false,
): Array<EntityFieldProps> => {
  const defaultExcludeColumn = ['id'];

  let data = Schema[entityName]
    .filter((field: any) => !excludeColumns.concat(defaultExcludeColumn).includes(field.name))
    .map(field => {
      field = {
        ...field,
        type: field.type.ofType ?? field.type,
        required: field.type.ofType ? true : false,
      };

      let fieldObj: EntityFieldProps = {
        name: field.name,
        label: field.label ?? startCase(field.name),
        required: field.required,
        type: '',
        entityName: '',
        entityFieldLabel: '',
        blankValuePlaceholder: field.type.blankValuePlaceholder ?? '',
        cssStyle: field.cssStyle ?? 'col-12',
        tabName: field.tabName ?? 'General',
        disabled: field.disabled,
      };

      if (field.type.kind === 'OBJECT') {
        Object.assign(fieldObj, {
          type: 'Object',
          entityName: field.type.name,
          entityFieldLabel: field.type.relationshipLabel ?? 'name',
        });
      } else {
        const isStaticDropdown = field.staticOptions?.length;
        Object.assign(fieldObj, {
          type: isStaticDropdown ? 'staticDropdown' : field.type.ofType || field.type.name,
          staticOptions: field.staticOptions,
          entityName: field.type.relationship,
          entityFieldLabel: field.type.relationship ? field.type.relationshipLabel ?? 'name' : undefined,
          label: field.type.relationship ? field.label ?? startCase(field.name.replace('Id', '')) : fieldObj.label,
        });
      }
      return fieldObj;
    });
  // console.log(data);

  if (hideUUIDs) {
    data = data.filter(field => field.type !== 'uuid');
  }
  return data;
};
