import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { setAuthorization } from '../helpers/api_helper';

import { useUserContext } from 'shared/UserContext';

const AuthProtected = ({ component: Component, location, componentProps, ...rest }) => {
  const { token, isFetchingToken } = useUserContext();

  useEffect(() => {
    if (!isFetchingToken && token) {
      setAuthorization(token);
    } else if (isFetchingToken && !token) {
      <Redirect to={{ pathname: '/login', state: { from: location } }} />;
    }
  }, [isFetchingToken, location, token]);

  /*
    redirect is un-auth access protected routes via url
    */

  if (!token) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  return (
    <Route
      {...rest}
      render={props => {
        return (
          <>
            <Component {...{ ...props, ...componentProps }} />
          </>
        );
      }}
    />
  );
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <>
            <Component {...props} />{' '}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
