import { Col, Row } from 'reactstrap';
import { VectorMap } from 'react-jvectormap';
import { useAuth } from 'shared/auth';
import { esRequest } from 'utils/api';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const IdentityTab = ({}) => {
  const auth = useAuth();
  console.log(auth)
  const options = {
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    colors: [
      function ({ value }) {
        if (value > 0) {
          return '#00cfe5';
        } else {
          return '#e80074';
        }
      },
    ],
    yaxis: {
      title: {
        text: 'Top Countries',
      },
    },
  };

  const [topCountryData, setTopCountryData] = useState([]) as any;

  const getCountryCounts = () => {
    esRequest(
      'esmaster/crwd_wallet_sigmaprime_address/_count',
      {},
      {
        headers: {
          authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InBNU2xJMVNKN3A1Tm5qeWRCUS16T2ZCWUxudnJSaGZmak5ZTHJLaE5udjQifQ.eyJzdWIiOiIxMGFlNTdkOC1jODExLTRkNTMtYjJhZi05M2VkNTczMDI2YzEiLCJhdWQiOiI0NjRiNzVhYS0xYmQwLTQxYzktOTI2Mi0yZTgzM2RiYmYwZGUiLCJlbWFpbCI6InJzaW5naEBjcm93ZHBvaW50dGVjaC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOi8vYXV0aC1kZXYuY3J3ZC53b3JsZCIsImlhdCI6MTY4NTk0MDQ4MSwiZXhwIjoxNjg1OTgzNjgxLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsiYWRtaW4iLCJhbm9ueW1vdXMiLCJ1c2VyIl0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6ImFkbWluIiwieC1oYXN1cmEtdXNlci1pZCI6IjEwYWU1N2Q4LWM4MTEtNGQ1My1iMmFmLTkzZWQ1NzMwMjZjMSJ9fQ.UMk8Z9b9GET1BPTBjqeuL8cJIsIS4vUsRRHs3Wyzho_hrkxPhRz2YBUzQCA4KtFRdEd7G8JcNTgbQcIygpQN0mYXV7gIq8grDlaD1qYnZPlOeVFg9KV29fUxvXqGQ3LDFMFfbbLI_w1baLxfnJSGGUbT8KJvjhb0A5Rd84xY8pX1kyBTUdQ-4UjUYoDSqsRk-CwSwa7-bn9fWShzqEUFnb_t-nbkt-ndLStpgMu9-HRdmdmm5172MLiAPQq44tvRt731uTO_O86bVoUPwZjuqSr5zRAXAlg8TSDCI69VoZEOBqvUIwWTMJM21N99HiViva3vFl4O8On-XNWMDcsbSQ`,
        },
      },
    ).then(response => {
      setTopCountryData(response);
    });
  };

  useEffect(() => {
    // if (!topCountryData.length) {
    getCountryCounts();
    // }
  }, []);

  return (
    <Row>
      <Col xl={6}>
        <div style={{ height: '469px' }}>
          <VectorMap
            map="world_mill"
            backgroundColor="transparent"
            containerStyle={{
              width: '100%',
              height: '80%',
            }}
            regionStyle={{
              initial: {
                stroke: '#9599ad',
                strokeWidth: 0.25,
                fill: '#f3f6f9',
                fillOpacity: 1,
              },
            }}
            lineStyle={{
              animation: true,
              strokeDasharray: '6 3 6',
            }}
          />
        </div>
      </Col>
      <Col xl={6}>
        <ReactApexChart options={options} series={topCountryData} type="bar" height={350} />
      </Col>
    </Row>
  );
};

export default IdentityTab;
