import React from 'react';

const Price = cell => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const Pairs = cell => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const HighPrice = cell => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const LowPrice = cell => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const MarketVolume = cell => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

export { Price, Pairs, HighPrice, LowPrice, MarketVolume };
