import React, { useState } from 'react';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import UiContent from '../../../Components/Common/UiContent';
import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
import { BrowserDefaults, CustomStyles, SupportedElements } from './FormValidationCode';

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
  CardHeader,
} from 'reactstrap';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DiffColorTreemap } from 'pages/Charts/ApexCharts/TreemapCharts/TreemapCharts';

const IdentityCompliance = () => {
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: '',
      lastname: '',
      city: '',
      state: '',
      zip: '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required('Please Enter Your First Name'),
      lastname: Yup.string().required('Please Enter Your Last Name'),
      city: Yup.string().required('Please Enter Your City'),
      state: Yup.string().required('Please Enter Your State'),
      zip: Yup.string().required('Please Enter Your Zip'),
    }),
    onSubmit: values => {
      console.log('values', values);
    },
  });

  const [formValidation, setValidation] = useState({
    fnm: null,
    lnm: null,
    unm: null,
    city: null,
    stateV: null,
  });

  function handleSubmit(e) {
    e.preventDefault();
    const modifiedV = { ...formValidation };
    var fnm = document.getElementById('validationTooltip01').value;
    var lnm = document.getElementById('validationTooltip02').value;
    var unm = document.getElementById('validationTooltipUsername').value;
    var city = document.getElementById('validationTooltip03').value;
    var stateV = document.getElementById('validationTooltip04').value;

    if (fnm === '') {
      modifiedV['fnm'] = false;
    } else {
      modifiedV['fnm'] = true;
    }

    if (lnm === '') {
      modifiedV['lnm'] = false;
    } else {
      modifiedV['lnm'] = true;
    }

    if (unm === '') {
      modifiedV['unm'] = false;
    } else {
      modifiedV['unm'] = true;
    }

    if (city === '') {
      modifiedV['city'] = false;
    } else {
      modifiedV['city'] = true;
    }

    if (stateV === '') {
      modifiedV['stateV'] = false;
    } else {
      modifiedV['stateV'] = true;
    }
    setValidation(modifiedV);
  }

  //for change tooltip display propery
  const onChangeValidation = (fieldName, value) => {
    const modifiedV = { ...validation };
    if (value !== '') {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    setValidation(modifiedV);
  };
  document.title = 'Validation | crwdcapital - universal wallet';

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="IDENTITY VALIDATION" pageTitle="Identity Compliance" />
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Distributed Treemap Chart (Different Color for each Cell)</h4>
            </CardHeader>
            <CardBody>
              <DiffColorTreemap dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-info","--vz-warning", "--vz-danger"]' />
            </CardBody>
          </Card>
          <Row>
            <Col lg={12}>
              <Card>
                <PreviewCardHeader title="IDENTITY" />
                <CardBody>
                  <p className="text-muted">
                    Not interested in custom validation feedback messages or writing JavaScript to change form
                    behaviors? All good, you can use the browser defaults. Try submitting the form below. Depending on
                    your browser and OS, you’ll see a slightly different style of feedback.While these feedback styles
                    cannot be styled with CSS, you can still customize the feedback text through JavaScript.
                  </p>

                  <div className="live-preview">
                    <form className="row g-3">
                      <Col md={4}>
                        <Label for="validationDefault01" className="form-label">
                          First name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault01"
                          defaultValue="Mark"
                          required
                        />
                      </Col>
                      <Col md={4}>
                        <Label for="validationDefault02" className="form-label">
                          Last name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault02"
                          defaultValue="Otto"
                          required
                        />
                      </Col>
                      <Col md={4}>
                        <Label for="validationDefaultUsername" className="form-label">
                          Username
                        </Label>
                        <InputGroup>
                          <span className="input-group-text" id="inputGroupPrepend2">
                            @
                          </span>
                          <Input
                            type="text"
                            className="form-control"
                            id="validationDefaultUsername"
                            aria-describedby="inputGroupPrepend2"
                            required
                          />
                        </InputGroup>
                      </Col>
                      <Col md={6}>
                        <Label for="validationDefault03" className="form-label">
                          City
                        </Label>
                        <Input type="text" className="form-control" id="validationDefault03" required />
                      </Col>
                      <Col md={3}>
                        <Label for="validationDefault04" className="form-label">
                          State
                        </Label>
                        <select className="form-select" id="validationDefault04" required>
                          <option disabled defaultValue="">
                            Choose...
                          </option>
                          <option>...</option>
                        </select>
                      </Col>
                      <Col md={3}>
                        <Label for="validationDefault05" className="form-label">
                          Zip
                        </Label>
                        <Input type="text" className="form-control" id="validationDefault05" required />
                      </Col>
                      <Col xs={12}>
                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue=""
                            id="invalidCheck2"
                            required
                          />
                          <Label className="form-check-label" for="invalidCheck2">
                            Agree to terms and conditions
                          </Label>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <button className="btn btn-primary" type="submit">
                          Submit form
                        </button>
                      </Col>
                    </form>
                  </div>
                  <div className="d-none code-view">
                    <pre className="language-markup" style={{ height: '352px' }}>
                      <code>
                        <BrowserDefaults />
                      </code>
                    </pre>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IdentityCompliance;
