const IndustryOutlookPerformance = ({ details }) => {
  return (
    <>
      <h4>Industry Outlook Report</h4>
      <h5>Industry Landscape</h5>
      <ul>
        <li>{details.industryOutlookReport.industryLandscape.subsection1}</li>
        <li>{details.industryOutlookReport.industryLandscape.subsection2}</li>
        <li>{details.industryOutlookReport.industryLandscape.subsection3}</li>
      </ul>
      <h5>Market Size and Growth</h5>
      <ul>
        <li>{details.industryOutlookReport.marketSizeAndGrowth.subsection1}</li>
        <li>{details.industryOutlookReport.marketSizeAndGrowth.subsection2}</li>
        <li>{details.industryOutlookReport.marketSizeAndGrowth.subsection3}</li>
      </ul>
      <h5>Regulatory Developments</h5>
      <ul>
        <li>{details.industryOutlookReport.regulatoryDevelopments.subsection1}</li>
        <li>{details.industryOutlookReport.regulatoryDevelopments.subsection2}</li>
        <li>{details.industryOutlookReport.regulatoryDevelopments.subsection3}</li>
      </ul>
      <h5>Challenges and Opportunities</h5>
      <ul>
        <li>{details.industryOutlookReport.challengesAndOpportunities.subsection1}</li>
        <li>{details.industryOutlookReport.challengesAndOpportunities.subsection2}</li>
        <li>{details.industryOutlookReport.challengesAndOpportunities.subsection3}</li>
      </ul>
    </>
  );
};

export default IndustryOutlookPerformance;
