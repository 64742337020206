import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

//Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout';
import VerticalLayout from '../Layouts/index';
//routes
import { authProtectedRoutes, publicRoutes } from './allRoutes';
import { AuthProtected, AccessRoute } from './AuthProtected';

const Index = () => {
  const availablePublicRoutesPaths = publicRoutes.map(r => r.path);
  const availableAuthRoutesPath = authProtectedRoutes.map(r => r.path);

  const location = useLocation();

  return (
    <React.Fragment>
      <Switch>
        <Route path={availablePublicRoutesPaths}>
          <NonAuthLayout>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AccessRoute path={route.path} component={route.component} key={idx} exact={true} />
              ))}
            </Switch>
          </NonAuthLayout>
        </Route>

        <Route path={availableAuthRoutesPath}>
          <VerticalLayout>
            <Switch>
              {authProtectedRoutes.map((route, idx) => (
                <AuthProtected
                  path={route.path}
                  component={route.component}
                  location={location}
                  key={idx}
                  exact={true}
                  componentProps={route.props}
                />
              ))}
            </Switch>
          </VerticalLayout>
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default Index;
