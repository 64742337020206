import BreadCrumb from 'Components/Common/BreadCrumb';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from 'reactstrap';
import { esRequest } from 'utils/api';

const PropertyTickers = () => {
  const [data, setData] = useState([]);
  const [availableRAGAs, setAvailableRAGAs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData([]);
    getData();
    setAvailableRAGAs([]);
  }, []);

  const getData = () => {
    try {
      setLoading(true);
      esRequest(
        'open/escloud/rega/_search',
        {
          size: 0,
          aggs: {
            rega: {
              terms: { field: 'stock_index', size: 1000, order: { _key: 'asc' } },
              aggs: { entry: { top_hits: { size: 1 } } },
            },
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      ).then(response => {
        const data = response?.data?.aggregations?.rega?.buckets?.map(
          (bucket: any) => bucket?.entry?.hits?.hits[0]._source || [],
        );
        setData(data);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const removeHandle = ({ id }) => {
    console.log(id);

    //Refresh list
    //getData()
  };

  const addToWatchHandle = ({ id }) => {
    console.log(id);

    //Refresh list
    //getData()
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Security Token Listing</span>,
      selector: (row: any) => row.stock_index,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row: any) => row.EMPLOYEES_INFO[0].ISSUERNAME,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Sector</span>,
      selector: (row: any) => row.EMPLOYEES_INFO[0].GICS.sector_name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Industry group</span>,
      selector: (row: any) => row.EMPLOYEES_INFO[0].GICS.industry_group_name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Industry</span>,
      selector: (row: any) => row.EMPLOYEES_INFO[0].GICS.industry_name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Sub-industry</span>,
      selector: (row: any) => row.EMPLOYEES_INFO[0].GICS.sub_industry_name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Securities Offered</span>,
      selector: (row: any) => row.SUMMARY_INFO[0].SECURITIESOFFERED,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Outstanding Securities</span>,
      selector: (row: any) => row.SUMMARY_INFO[0].OUTSTANDINGSECURITIES,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Price per security</span>,
      selector: (row: any) => row.SUMMARY_INFO[0].PRICEPERSECURITY,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      cell: (row: any) => {
        console.log(row.stock_index);

        const isAvailable = availableRAGAs.some(raga => raga === row.stock_index);

        return (
          <>
            {isAvailable ? (
              <button className="btn btn-sm btn-info" onClick={() => removeHandle(row.stock_index)}>
                Remove
              </button>
            ) : (
              <button className="btn btn-sm btn-soft-success" onClick={() => addToWatchHandle(row.stock_index)}>
                Add to watch
              </button>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Property Tickers" pageTitle="Finance" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col className="col-sm-auto py-0">
                        <h5 className="card-title mb-0">Proprietary Tickers</h5>
                      </Col>
                      <Col className="col-sm py-0">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input type="text" className="form-control search" placeholder="Search..." />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="p-0">
                    {loading ? (
                      <Row className="h-15rem align-items-center justify-content-center">
                        <Spinner color="primary">Loading...</Spinner>
                      </Row>
                    ) : (
                      <DataTable columns={columns} data={data} pagination highlightOnHover />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};
export default PropertyTickers;
