import React, { Suspense } from 'react';

//import Scss
import './assets/scss/themes.scss';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

//imoprt Route
import Route from './Routes';

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Fake Backend
import fakeBackend from './helpers/AuthType/fakeBackend';
import { Switch } from 'react-router-dom';
import { AuthProvider } from 'shared/auth';
import { UserProvider } from 'shared/UserContext';
import { ToastContainer } from 'react-toastify';

// Activating fake backend
fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const authUrl: string = process.env.REACT_APP_AUTH_URL!;
const siteUrl: string = process.env.REACT_APP_SITE_URL!;
const clientId: string = process.env.REACT_APP_AUTH_CLIENT_ID!;

function onSigninCallback(_user) {
  window.history.replaceState({}, document.title, window.location.pathname);
}

function App() {
  return (
    <React.Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <AuthProvider
            authority={authUrl}
            client_id={clientId}
            redirect_uri={siteUrl}
            response_type="code"
            onSigninCallback={onSigninCallback}
          >
            <UserProvider>
              <Route />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick={true}
                pauseOnHover={true}
                theme="light"
                icon={true}
              />
            </UserProvider>
          </AuthProvider>
        </Switch>
      </Suspense>
    </React.Fragment>
  );
}

export default App;
