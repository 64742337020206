const OpportunityReport = ({ details }) => {
  return (
    <>
      <h4>Operational Analysis</h4>

      <div className="row">
        <div className="col">
          <h5>Market Trends</h5>
          <ul>
            <li>{details.operationalAnalysis.marketTrends.subsection1}</li>
            <li>{details.operationalAnalysis.marketTrends.subsection2}</li>
            <li>{details.operationalAnalysis.marketTrends.subsection3}</li>
          </ul>
        </div>
        <div className="col">
          <h5>Customer Preferences</h5>
          <ul>
            <li>{details.operationalAnalysis.customerPreferences.subsection1}</li>
            <li>{details.operationalAnalysis.customerPreferences.subsection2}</li>
            <li>{details.operationalAnalysis.customerPreferences.subsection3}</li>
          </ul>
        </div>
        <div className="col">
          <h5>Emerging Technologies</h5>
          <ul>
            <li>{details.operationalAnalysis.emergingTechnologies.subsection1}</li>
            <li>{details.operationalAnalysis.emergingTechnologies.subsection2}</li>
            <li>{details.operationalAnalysis.emergingTechnologies.subsection3}</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default OpportunityReport;
