import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Button,
  Spinner,
} from 'reactstrap';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUserContext } from '../../shared/UserContext';
import { Link, useHistory } from 'react-router-dom';

//import images
import logoLight from '../../assets/images/logo-light.svg';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';
import { toggleBodyBgClass } from 'utils/helpers';

const Register = () => {
  useEffect(() => {
    toggleBodyBgClass();
    return toggleBodyBgClass;
  }, []);

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { signup } = useUserContext();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      firstName: Yup.string().required('Please Enter Your First Name'),
      lastName: Yup.string().required('Please Enter Your Last Name'),
      password: Yup.string().required('Please Enter Your Password'),
      confirm_password: Yup.string()
        .required('Please Re-enter Your Password')
        .when('password', {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf([Yup.ref('password')], "Confirm Password Doesn't Match"),
        }),
    }),
    onSubmit: async values => {
      try {
        setLoading(true);
        const payload = { ...values, countryCode: 'US', phone: '' };
        console.log(payload);

        await signup({ ...payload });

        toast.success('User Registered Successfully. We are redirecting to login.', {
          theme: 'colored',
          onClose: props => history.push('login'),
        });
      } catch (error) {
        toast.error(error.message, {
          theme: 'colored',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  document.title = 'Basic SignUp | crwdcapital - universal wallet';

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="20" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">
                    vertical by &nbsp;
                    <span className="crwd-font">
                      crwd<span className="crwd-font-bold">world</span>
                    </span>
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Create New Account</h5>
                      <p className="text-muted">
                        Get your free{' '}
                        <span className="crwd-font">
                          crwd
                          <span className="crwd-font-bold">world</span>
                        </span>{' '}
                        account now
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        className="needs-validation"
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="useremail" className="form-label">
                            Email <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter email address"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={validation.touched.email && validation.errors.email ? true : false}
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.email}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="firstName" className="form-label">
                            First Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="firstName"
                            type="text"
                            placeholder="Enter firstName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ''}
                            invalid={validation.touched.firstName && validation.errors.firstName ? true : false}
                          />
                          {validation.touched.firstName && validation.errors.firstName ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.firstName}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="lastName" className="form-label">
                            Last Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="lastName"
                            type="text"
                            placeholder="Enter lastName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ''}
                            invalid={validation.touched.lastName && validation.errors.lastName ? true : false}
                          />
                          {validation.touched.lastName && validation.errors.lastName ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.lastName}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="userpassword" className="form-label">
                            Password <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ''}
                            invalid={validation.touched.password && validation.errors.password ? true : false}
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.password}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-2">
                          <Label htmlFor="confirmPassword" className="form-label">
                            Confirm Password <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="confirm_password"
                            type="password"
                            placeholder="Confirm Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirm_password || ''}
                            invalid={
                              validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                            }
                          />
                          {validation.touched.confirm_password && validation.errors.confirm_password ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.confirm_password}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-4">
                          <p className="mb-0 fs-12 text-muted fst-italic">
                            By registering you agree to the &nbsp;
                            <span className="crwd-font">
                              crwd
                              <span className="crwd-font-bold">world</span>
                            </span>
                            &nbsp;
                            <Link to="#" className="text-primary text-decoration-underline fst-normal fw-medium">
                              Terms of Use
                            </Link>
                          </p>
                        </div>

                        <div className="mt-4">
                          <button
                            className={`${loading ? 'pointer-events-none' : ''} btn btn-success w-100`}
                            type="submit"
                          >
                            <span className="d-flex align-items-center">
                              {loading && <Spinner size="sm" className="flex-shrink-0" />}
                              <span className="flex-grow-1 ms-2">Sign Up</span>
                            </span>
                          </button>
                        </div>

                        {/* <div className="mt-4 text-center">
                          <div className="signin-other-title">
                            <h5 className="fs-13 mb-4 title text-muted">
                              Create account with
                            </h5>
                          </div>

                          <div>
                            <button
                              type="button"
                              className="btn btn-primary btn-icon waves-effect waves-light"
                            >
                              <i className="ri-facebook-fill fs-16"></i>
                            </button>{" "}
                            <button
                              type="button"
                              className="btn btn-danger btn-icon waves-effect waves-light"
                            >
                              <i className="ri-google-fill fs-16"></i>
                            </button>{" "}
                            <button
                              type="button"
                              className="btn btn-dark btn-icon waves-effect waves-light"
                            >
                              <i className="ri-github-fill fs-16"></i>
                            </button>{" "}
                            <button
                              type="button"
                              className="btn btn-info btn-icon waves-effect waves-light"
                            >
                              <i className="ri-twitter-fill fs-16"></i>
                            </button>
                          </div>
                        </div> */}
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Already have an account?{' '}
                    <Link to="/login" className="fw-semibold text-primary text-decoration-underline">
                      {' '}
                      Signin{' '}
                    </Link>{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Register;
