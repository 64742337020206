import {
  useUpsertStatusesMutation,
  useUpsertStatusKindsMutation,
  useUpsertIndustriesMutation,
  useUpsertGroupsMutation,
  useUpsertOrganizationsMutation,
  useUpsertOrganizationKindMutation,
  useUpsertSubindustriesMutation,
  useUpsertCurrencyMutation,
  useUpsertCurrencyKindMutation,
  useUpsertCurrencyOwnerMutation,
  useUpsertCountriesMutation,
  useUpsertExchangeMutation,
  useUpsertSicCodeMutation,
  useUpsertGicsSectorMutation,
  useUpsertAppraisalMutation,
  useUpsertAskMutation,
  useUpsertCertificationMutation,
  useUpsertClearinghouseCertificationMutation,
  useUpsertClearinghouseKindMutation,
  useUpsertClearinghouseMutation,
  useUpsertInteractionMutation,
  useUpsertMerchantCurrencyMutation,
  useUpsertMerchantMutation,
  useUpsertMerchantOrganizationMutation,
  useUpsertAssetKindMutation,
  useUpsertPutMutation,
  useUpsertSettingsMutation,
  useUpsertSmartContractLineageMutation,
  useUpsertSmartContractKindMutation,
  useUpsertSmartContractMutation,
  useUpsertTradeKindMutation,
  useUpsertTradeMutation,
  useUpsertWalletKindMutation,
  useUpsertWalletMutation,
  useUpsertSigmaPrimeMutation,
  useUpsertSigmaPrimeKindMutation,
  useUpsertExchangeKindMutation,
  useUpsertUserMutation,
  useUpsertSigmaPrimeAddressMutation,
  useUpsertAddressAttributesMutation,
  useUpsertDatasourceMutation,
  useUpsertSmartContractCommissionMutation,
  useUpsertSmartContractDocumentMutation,
  useUpsertWalletInventoryMutation,
  useUpsertAssetAccountMutation,
  useUpsertAssetMutation,
  useUpsertOrderMutation,
  useUpsertProductDistributionMutation,
  useUpsertProductStatusMutation,
  useUpsertDocumentUserMutation,
  useUpsertProductMutation,
  useUpsertProductKindMutation,
  useUpsertMaterialMutation,
  useUpsertCartMutation,
  useUpsertMarketMutation,
  useUpsertOrganizationStatusMutation,
  useUpsertShippingMutation,
  useUpsertShopMutation,
  useUpsertSupplyChainWaypointsMutation,
  useUpsertTransactionMutation,
  useUpsertPaymentMutation,
  useUpsertTransactionKindMutation,
  useUpsertAskPutTransactionMutation,
  useUpsertKycStatusMutation,
  useUpsertKycKindMutation,
} from 'graphql/generated/resourceApi';

const MutationDocs = {
  AddressAttributes: useUpsertAddressAttributesMutation,
  Appraisal: useUpsertAppraisalMutation,
  Ask: useUpsertAskMutation,
  Certification: useUpsertCertificationMutation,
  Clearinghouse: useUpsertClearinghouseMutation,
  ClearinghouseCertification: useUpsertClearinghouseCertificationMutation,
  ClearinghouseKind: useUpsertClearinghouseKindMutation,
  Country: useUpsertCountriesMutation,
  Currency: useUpsertCurrencyMutation,
  CurrencyKind: useUpsertCurrencyKindMutation,
  CurrencyOwner: useUpsertCurrencyOwnerMutation,
  Datasource: useUpsertDatasourceMutation,
  Exchange: useUpsertExchangeMutation,
  ExchangeKind: useUpsertExchangeKindMutation,
  GicsSector: useUpsertGicsSectorMutation,
  Group: useUpsertGroupsMutation,
  Industry: useUpsertIndustriesMutation,
  Interaction: useUpsertInteractionMutation,
  Merchant: useUpsertMerchantMutation,
  MerchantCurrency: useUpsertMerchantCurrencyMutation,
  MerchantOrganization: useUpsertMerchantOrganizationMutation,
  Asset: useUpsertAssetMutation,
  AssetKind: useUpsertAssetKindMutation,
  Organization: useUpsertOrganizationsMutation,
  OrganizationKind: useUpsertOrganizationKindMutation,
  Put: useUpsertPutMutation,
  SicCode: useUpsertSicCodeMutation,
  SigmaPrime: useUpsertSigmaPrimeMutation,
  SigmaPrimeAddress: useUpsertSigmaPrimeAddressMutation,
  SigmaPrimeKind: useUpsertSigmaPrimeKindMutation,
  Settings: useUpsertSettingsMutation,
  SmartContract: useUpsertSmartContractMutation,
  SmartContractKind: useUpsertSmartContractKindMutation,
  SmartContractLineage: useUpsertSmartContractLineageMutation,
  ProductStatus: useUpsertProductStatusMutation,
  DocumentUser: useUpsertDocumentUserMutation,
  SmartContractCommission: useUpsertSmartContractCommissionMutation,
  SmartContractDocument: useUpsertSmartContractDocumentMutation,
  Status: useUpsertStatusesMutation,
  StatusKind: useUpsertStatusKindsMutation,
  Subindustry: useUpsertSubindustriesMutation,
  Transaction: useUpsertTransactionMutation,
  TransactionKind: useUpsertTransactionKindMutation,
  Trade: useUpsertTradeMutation,
  TradeKind: useUpsertTradeKindMutation,
  User: useUpsertUserMutation,
  Wallet: useUpsertWalletMutation,
  WalletKind: useUpsertWalletKindMutation,
  WalletInventory: useUpsertWalletInventoryMutation,
  AssetAccount: useUpsertAssetAccountMutation,
  ProductDistribution: useUpsertProductDistributionMutation,
  Order: useUpsertOrderMutation,
  Product: useUpsertProductMutation,
  ProductKind: useUpsertProductKindMutation,
  Material: useUpsertMaterialMutation,
  Cart: useUpsertCartMutation,
  Market: useUpsertMarketMutation,
  OrganizationStatus: useUpsertOrganizationStatusMutation,
  Shipping: useUpsertShippingMutation,
  Shop: useUpsertShopMutation,
  SupplyChainWaypoint: useUpsertSupplyChainWaypointsMutation,
  Payment: useUpsertPaymentMutation,
  AskPutTransaction: useUpsertAskPutTransactionMutation,
  KycStatus: useUpsertKycStatusMutation,
  KycKind: useUpsertKycKindMutation,
};

export default MutationDocs;
