import { useEffect, useState } from 'react';
import { gics, gics_logo } from '../../../../common/data';
import { NoDataTemplate } from 'Components/NoDataTemplate';
import { Container, Row, Col, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import BreadCrumb from 'Components/Common/BreadCrumb';

const GICSLinkView = props => {
  document.title = 'GICS Link view | crwdcapital - universal wallet';

  const [sectorLink, setSectorLink] = useState<string>('');
  const history = useHistory();
  const gicsName = props.match?.params?.id;
  const gicsLink = props.match?.params?.link;

  useEffect(() => {
    const sectors = gics.filter(sector => sector.id === gicsName);
    if (sectors.length) {
      const sector = sectors[0];
      const links = sector.links.filter(link => link.id === gicsLink);
      if (links.length) {
        setSectorLink(links[0].link);
      }
    }
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="View" pageTitle="GICS" />
        <Row>
          <Col lg={12}>
            <Button
              className="image-popup p-0 bg-transparent border-0 w-full my-2 text-start"
              onClick={() => history.push(`/markets/marketplaces/gics/${gicsName}`)}
            >
              <img className="gallery-img img-fluid mx-auto" src={gics_logo.img} alt="gics-img" width="50px" />
            </Button>

            {sectorLink ? (
              <iframe
                src={sectorLink}
                style={{ padding: '0px', width: '100%', height: '90vh', backgroundColor: '#ddd' || '#FFF' }}
              />
            ) : (
              <NoDataTemplate />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GICSLinkView;
