import React, { useState } from 'react';
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import SwiperCore, { Autoplay } from 'swiper';

import IdentityTab from './IdentityTab';
import MarketTab from './MarketTab';
import CapitalTab from './Capital/CapitalTab';
import FinanceTab from './FinanceTab';

const VerticalSummary = () => {
  SwiperCore.use([Autoplay]);

  const [activeTab, setActiveTab] = useState('1');

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.title = 'Profile | crwdcapital - universal wallet';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div>
                <div className="d-flex">
                  <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                    <NavItem>
                      <NavLink
                        href="#overview-tab"
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          toggleTab('1');
                        }}
                      >
                        <i className="ri-airplay-fill d-inline-block d-md-none"></i>{' '}
                        <span className="d-none d-md-inline-block">Identity</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#activities"
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          toggleTab('2');
                        }}
                      >
                        <i className="ri-list-unordered d-inline-block d-md-none"></i>{' '}
                        <span className="d-none d-md-inline-block">Market</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#projects"
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => {
                          toggleTab('3');
                        }}
                      >
                        <i className="ri-price-tag-line d-inline-block d-md-none"></i>{' '}
                        <span className="d-none d-md-inline-block">Capital</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#documents"
                        className={classnames({ active: activeTab === '4' })}
                        onClick={() => {
                          toggleTab('4');
                        }}
                      >
                        <i className="ri-folder-4-line d-inline-block d-md-none"></i>{' '}
                        <span className="d-none d-md-inline-block">Finance</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>

                <TabContent activeTab={activeTab} className="pt-4">
                  <TabPane tabId="1">
                    <IdentityTab />
                  </TabPane>

                  <TabPane tabId="2">
                    <MarketTab />
                  </TabPane>

                  <TabPane tabId="3">
                    <CapitalTab />
                  </TabPane>

                  <TabPane tabId="4">{activeTab === '4' && <FinanceTab />}</TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VerticalSummary;
