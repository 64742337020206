import { useRef, useState } from 'react';
import AdminInputCountry from 'Components/Admin/AdminForm/AdminFormInputs/AdminInputCountry';
import AdminInputEmail from 'Components/Admin/AdminForm/AdminFormInputs/AdminInputEmail';
import AdminInputPhone from 'Components/Admin/AdminForm/AdminFormInputs/AdminInputPhone/AdminInputPhone';
import AdminInputBoolean from 'Components/Admin/AdminForm/AdminFormInputs/AdminInputBoolean';
import AdminInputDate from 'Components/Admin/AdminForm/AdminFormInputs/AdminInputDate';
import AdminInputNumber from 'Components/Admin/AdminForm/AdminFormInputs/AdminInputNumber';
import { Nav, NavItem, NavLink, Row, Col, TabContent, TabPane } from 'reactstrap';
import AdminInputRelation, {
  AdminInputRelationProps,
} from 'Components/Admin/AdminForm/AdminFormInputs/AdminInputRelation';
import AdminInputText from 'Components/Admin/AdminForm/AdminFormInputs/AdminInputText';
import FragmentsDocs from 'Components/List/fragmentDoc';
import _ from 'lodash';
import { Button } from 'primereact/button';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUserContext } from 'shared/UserContext';
import { getRelationshipFieldDataAdapter, navigateToErrorField } from 'utils/helpers';
import { EntityFieldProps, getEntityFields } from 'utils/schema';
import MutationDocs from './mutationDoc';
import { EntityRecordGenericProps } from './type';
import AdminInputState from '../../Admin/AdminForm/AdminFormInputs/AdminInputState';
import { CountryFieldsFragment } from 'graphql/generated/graphqlRequest';
import classnames from 'classnames';
import { Player } from '@lottiefiles/react-lottie-player';
import { TabView, TabPanel } from 'primereact/tabview';

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import '../../../prime-theme.css';
import 'primeflex/primeflex.scss';
import FileUploader from '../../FileUploader';
import { getFieldName } from 'Components/List/listUtils';
import AdminInputRichText from '../../Admin/AdminForm/AdminFormInputs/AdminInputRichText';

const EntityRecordGeneric: React.FC<EntityRecordGenericProps> = ({ wizardView = false, ...props }) => {
  const { client, userId } = useUserContext();
  const entityUpsertState = MutationDocs[props.entityName](client);
  const {
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
    getValues,
  } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
  });

  const [country, setCountry] = useState<CountryFieldsFragment>();
  const [activeIndex, setActiveIndex] = useState(0);
  const [tabState, setTabState] = useState<{
    prevIndex: number;
    currIndex: number;
    done: number[];
    submitted: boolean;
  }>({
    prevIndex: -1,
    currIndex: 0,
    done: [],
    submitted: false,
  });

  useEffect(() => {
    if (props.rowData && props.genericType !== 'ADD') {
      let resetValues = {};
      columns?.map(col => {
        if (col.type !== 'Object' && props.rowData[col.name])
          Object.assign(resetValues, {
            [col.name]: props.rowData[col.name],
          });
      });
      reset(resetValues);
    }
  }, [props.rowData]);

  const columns = getEntityFields(props.entityName, ['createdAt', 'updatedAt', 'isDeleted']);

  const groupedColumns = _.groupBy(
    columns.filter(col => col.type !== 'Object'),
    col => col.tabName,
  );

  const groupedColumnsLength = Object.keys(groupedColumns).length;

  interface IUploadData {
    name: string;
    loading: boolean;
    url: string;
  }
  const uploadImageRef = useRef<() => void | null>(null);
  const [uploadImageData, setUploadImageData] = useState<IUploadData | null>(null);

  useEffect(() => {
    if (uploadImageData && !uploadImageData?.loading) {
      const imgObj = {};
      imgObj[uploadImageData?.name] = uploadImageData?.url;
      onSubmit(getValues(), imgObj);
    }
  }, [uploadImageData]);

  const onSubmit = (values: Record<string, any>, imageObj) => {
    const data = imageObj ? { ...values, ...imageObj } : values;

    if (props.rowData && props.genericType === 'EDIT') Object.assign(data, { id: props.rowData.id });
    if (props.entityName === 'Organization') {
      Object.assign(data, { crwdsystemsApiReference: userId });
    }

    entityUpsertState
      .mutateAsync({ objects: [data] })
      .then(result => {
        if (wizardView) {
          onTabNextOrClick(groupedColumnsLength, true);
        }
        props?.onSubmitSuccess && props.onSubmitSuccess(result);
      })
      .catch((error: any) => {
        props.onSubmitError?.(error);
      });
  };

  const renderInput = (column: EntityFieldProps) => {
    const columnName = getFieldName(column);
    const commonProps = {
      label: column.label,
      name: columnName,
      control: control,
      required: column.required,
      disabled: column.disabled || props.genericType === 'VIEW',
    };
    // Render Email component for any `email` field
    if (column.name.toLowerCase().includes('email') && column.name.toLowerCase() !== 'emailverified') {
      return <AdminInputEmail {...commonProps} />;
    }
    switch (column.type) {
      case 'String':
      case 'citext':
        return <AdminInputText {...commonProps} />;
      case 'Boolean':
        return <AdminInputBoolean {...commonProps} />;
      case 'Int':
        return <AdminInputNumber {...commonProps} minFractionDigits={0} />;
      case 'Float':
        return <AdminInputNumber {...commonProps} />;
      case 'bigint':
        return <AdminInputNumber {...commonProps} minFractionDigits={0} />;
      case 'timestamptz':
        return <AdminInputDate {...commonProps} />;
      case 'Phone':
        return <AdminInputPhone {...commonProps} />;
      case 'Email':
        return <AdminInputEmail {...commonProps} />;
      case 'Richtext':
        return <AdminInputRichText {...commonProps} />;
      case 'Image':
        return (
          <>
            <FileUploader
              fileId={props.rowData && props.rowData[columnName]}
              label={column.label}
              multiple={true}
              required={column.required}
              uploadImageRef={uploadImageRef}
              loadingFn={() => setUploadImageData({ name: columnName, loading: true, url: '' })}
              disabled={commonProps.disabled}
              onUploaded={(finishedFiles: any) => {
                setUploadImageData({
                  name: columnName,
                  loading: false,
                  url: JSON.stringify(finishedFiles),
                });
              }}
            />
          </>
        );
      case 'uuid':
        if (column.entityName === 'Country') {
          return <AdminInputCountry {...commonProps} onCountrySelect={setCountry} />;
        } else if (column.entityName === 'States') {
          return <AdminInputState {...commonProps} countryCode={country?.code} />;
        } else {
          let labelFunc;
          const fragmentDoc = FragmentsDocs[`${column.entityName}DropDown`] || FragmentsDocs[column.entityName];
          if (!column.entityName) {
            console.log('error entityName', column);
          }
          if (!fragmentDoc) {
            console.log('error fragment', column);
          }

          const adapter = getRelationshipFieldDataAdapter(client, _.camelCase(column.entityName), fragmentDoc);
          if (column.entityName === 'SigmaPrime') {
            labelFunc = data => {
              return `${data.personFirstName} ${data.personLastName}`;
            };
          }
          const rProps: AdminInputRelationProps = {
            ...commonProps,
            adapter,
            relationshipColumnNameForLabel: column.entityFieldLabel,
            relationshipColumnNameForLabelFunc: labelFunc,
            relationshipColumnNameForValue: 'id',
            where: { isDeleted: { _eq: false } },
          };

          return <AdminInputRelation {...rProps} />;
        }
      case 'staticDropdown':
        if (!column.staticOptions) {
          console.log('error no static option', column);
        }
        const arProps: AdminInputRelationProps = {
          ...commonProps,
          relationshipColumnNameForLabel: 'label',
          relationshipColumnNameForValue: 'value',
          staticOptions: column.staticOptions?.map(staticOption => ({
            label: staticOption,
            value: staticOption,
          })),
        };
        return <AdminInputRelation {...arProps} />;
      default:
        return null;
    }
  };

  const renderInputWrapper = (column: EntityFieldProps) => {
    const columnName = getFieldName(column);
    return (
      <div className={column.cssStyle} key={columnName}>
        {renderInput(column)}
      </div>
    );
  };

  const onTabNextOrClick = async (nextIndex: number, submitted = false) => {
    const result = await trigger(
      tabState.currIndex < groupedColumnsLength
        ? groupedColumns[Object.keys(groupedColumns)[tabState.currIndex]].map(f => f.name)
        : [],
      { shouldFocus: true },
    );
    if (result) {
      const nextTabState = {
        ...tabState,
        prevIndex: tabState.currIndex,
        currIndex: nextIndex,
        done: _.uniq([...tabState.done, tabState.currIndex]),
      };

      if (submitted) _.assign(nextTabState, { submitted });

      setTabState(nextTabState);
    } else {
      const updateCurrTabState = {
        ...tabState,
        done: _.without(tabState.done, tabState.currIndex),
      };
      setTabState(updateCurrTabState);
    }
  };
  const onError = errors => {
    navigateToErrorField(errors, groupedColumns, setActiveIndex);
  };

  return (
    <form
      className="vertical-navs-step"
      onSubmit={handleSubmit(
        values => (uploadImageRef.current ? uploadImageRef.current() : onSubmit(values, null)),
        onError,
      )}
    >
      {wizardView && (
        <Row className="gy-5 form-steps">
          <Col lg={3}>
            <Nav className="flex-column custom-nav nav-pills">
              {Object.keys(groupedColumns).map((tabName, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      disabled={
                        !tabState.done.includes(index) &&
                        // tabState.currIndex + 1 != index &&
                        tabState.currIndex != index
                      }
                      active={tabState.currIndex === index}
                      className={classnames({
                        done: tabState.done.includes(index),
                      })}
                      onClick={() => index != tabState.currIndex && onTabNextOrClick(index)}
                      href="#"
                    >
                      <span className="step-title me-2">
                        <i className={'ri-close-circle-fill step-icon me-2'}></i>
                        Step {index + 1}
                      </span>
                      {tabName}
                    </NavLink>
                  </NavItem>
                );
              })}
              <NavItem>
                <NavLink
                  disabled={
                    !tabState.done.includes(groupedColumnsLength - 1) &&
                    // tabState.currIndex + 1 != index &&
                    tabState.currIndex != groupedColumnsLength - 1
                  }
                  active={tabState.currIndex === groupedColumnsLength}
                  className={classnames({
                    done: tabState.done.includes(groupedColumnsLength),
                  })}
                  onClick={() =>
                    groupedColumnsLength != tabState.currIndex &&
                    (tabState.submitted || props.genericType == 'VIEW') &&
                    onTabNextOrClick(groupedColumnsLength)
                  }
                  href="#"
                >
                  <span className="step-title me-2">
                    <i className={'ri-close-circle-fill step-icon me-2'}></i>
                    Step {groupedColumnsLength + 1}
                  </span>
                  Finish
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col lg={9}>
            <TabContent activeTab={tabState.currIndex}>
              {Object.keys(groupedColumns).map((tabName, index) => (
                <TabPane tabId={index} key={index}>
                  <div className="grid">{groupedColumns[tabName].map(col => renderInputWrapper(col))}</div>
                  <div className="d-flex align-items-start gap-3 mt-4">
                    {tabState.currIndex && tabState.currIndex < groupedColumnsLength ? (
                      <button
                        type="button"
                        className="btn btn-light btn-label previestab"
                        onClick={() => setTabState({ ...tabState, currIndex: tabState.prevIndex })}
                      >
                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back
                      </button>
                    ) : null}

                    {tabState.currIndex < groupedColumnsLength - 1 ? (
                      <button
                        type="button"
                        className="btn btn-success btn-label right ms-auto nexttab nexttab"
                        onClick={() => onTabNextOrClick(tabState.currIndex + 1)}
                      >
                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                        Next
                      </button>
                    ) : (
                      props.genericType !== 'VIEW' &&
                      !tabState.submitted && (
                        <Button
                          className="btn btn-success btn-label ms-auto nexttab px-2 py-2"
                          label={props.genericType === 'EDIT' ? 'Update' : 'Completed'}
                          loading={entityUpsertState.isLoading}
                        />
                      )
                    )}
                  </div>
                </TabPane>
              ))}
              <TabPane tabId={groupedColumnsLength}>
                <div className="text-center pt-4 pb-2">
                  <div className="mb-4">
                    <Player
                      autoplay
                      loop
                      src="https://cdn.lordicon.com/lupuorrc.json"
                      style={{ width: '120px', height: '120px' }}
                    />
                  </div>
                  <h5>
                    {props.entityName} is {props.genericType === 'ADD' ? 'created' : 'updated'} successfully !
                  </h5>
                </div>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      )}
      {!wizardView && (
        <>
          <TabView activeIndex={activeIndex} onTabChange={e => setActiveIndex(e.index)} renderActiveOnly={false}>
            {Object.keys(groupedColumns).map((tabName, i) => (
              <TabPanel
                header={tabName}
                rightIcon={
                  groupedColumns[tabName].filter(f => Object.keys(errors).includes(f.name)).length
                    ? 'color-red ml-2 pi pi-times-circle'
                    : ''
                }
                key={i}
                className="grid"
                contentClassName={activeIndex != i ? 'hidden' : ''}
              >
                {groupedColumns[tabName].map(col => renderInputWrapper(col))}
              </TabPanel>
            ))}
          </TabView>
          {props.genericType !== 'VIEW' && (
            <div className="col-12 px-0 text-center">
              <Button
                style={{ width: '25%' }}
                label={props.genericType === 'EDIT' ? 'Update' : 'Create'}
                loading={entityUpsertState.isLoading || uploadImageData?.loading}
              />
            </div>
          )}
        </>
      )}
    </form>
  );
};

export default EntityRecordGeneric;
