import flagus from '../assets/images/flags/us.svg';
import flagspain from '../assets/images/flags/spain.svg';
import flaggermany from '../assets/images/flags/germany.svg';
import flagarab from '../assets/images/flags/arabic.svg';
import flagrussia from '../assets/images/flags/russia.svg';
import flagchina from '../assets/images/flags/china.svg';
import flagfrench from '../assets/images/flags/french.svg';

const languages = {
  en: {
    label: 'English',
    flag: flagus,
  },
  sp: {
    label: 'Española',
    flag: flagspain,
  },
  gr: {
    label: 'Deutsche',
    flag: flaggermany,
  },
  rs: {
    label: 'русский',
    flag: flagrussia,
  },
  cn: {
    label: '中国人',
    flag: flagchina,
  },
  fr: {
    label: 'français',
    flag: flagfrench,
  },
  ar: {
    label: 'Arabic',
    flag: flagarab,
  },
};

export default languages;
