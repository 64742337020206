import { Container } from 'reactstrap';
import BreadCrumb from 'Components/Common/BreadCrumb';
interface ImagePageProps {
  imageUrl: string;
  background?: string;
  breadcrumbTitle?: string;
  pageTitle?: string;
  img: string;
  url: string;
  cssClass?: string;
}

const ImagePage = ({ breadcrumbTitle, pageTitle, img, url, cssClass }: ImagePageProps) => {
  return (
    <div className="image-page page-content">
      <Container fluid className={cssClass}>
        <BreadCrumb title={breadcrumbTitle} pageTitle={pageTitle} />
        <a href={url} target="_blank">
          <img src={img} style={{ width: '50%', margin: 'auto', display: 'block' }} />
        </a>
      </Container>
    </div>
  );
};

export default ImagePage;
