import React from 'react';
import { Container } from 'reactstrap';

// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';

import TileBoxesMyDashboard from './TileBoxesMyDashboard';
import OtherWidgets from './OtherWidgets';
import UpcomingActivity from './UpcomingActivities';
import ChartMapWidgets from './Chart&MapWidgets';
import ListTableMyDashboard from '../Tables/ListTables/ListTableMyDashboard';

const MyDashboard = () => {
  document.title = 'Ellipsis - My Dashboard';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="MY Dashboard" pageTitle="Ellipsis" />

          {/* Other Widgets */}
          <OtherWidgets />

          {/* Tile Boxs Widgets */}
          <TileBoxesMyDashboard />

          <ListTableMyDashboard />
          {/* Upcoming Activity */}
          {/* <UpcomingActivity /> */}

          {/* Chart & Map Widgets */}
          {/* <ChartMapWidgets /> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyDashboard;
