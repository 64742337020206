const RequiredLegalDocuments = ({ details }) => {
  return (
    <>
      <h2>Legal Reports and Documents</h2>
      {details.requiredLegalReports.map((report, index) => (
        <section key={index}>
          <h3>{report.name}</h3>
          <p>{report.description}</p>
          <p>
            <a href={report.link}>Download</a>
          </p>
        </section>
      ))}
    </>
  );
};

export default RequiredLegalDocuments;
