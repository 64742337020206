const CompanyOverview = ({ details }) => {
  return (
    <>
      <h4>Contact Information</h4>
      <div className="row">
        <div className="col">
          <p>
            <strong>Email:</strong> {details.contactInformation.email}
          </p>
          <p>
            <strong>Phone:</strong> {details.contactInformation.phone}
          </p>
          <p>
            <strong>Address:</strong> {details.contactInformation.address}
          </p>
        </div>
        <div className="col">
          <p>
            <strong>Submitting Executive:</strong> {details.contactInformation.submittingExecutive}
          </p>
          <p>
            <strong>Approving Executive:</strong> {details.contactInformation.approvingExecutive}
          </p>
        </div>
      </div>
      <hr className="my-4" />

      <h4>Company Details</h4>
      <p>
        <strong>Primary GICS Industry:</strong> {details.primaryGICSIndustry}
      </p>
      <p>
        <strong>Secondary GICS Sub-Industries:</strong> {details.secondaryGICSSubIndustries.join(', ')}
      </p>
      <p>
        <strong>Location:</strong> {details.location}
      </p>
      <p>
        <strong>Date of Creation:</strong> {details.dateOfCreation}
      </p>
      <p>
        <strong>Current Offering Link:</strong> <a href={details.currentOfferingLink}>{details.currentOfferingLink}</a>
      </p>
      <p>
        <strong>Asset Security Link:</strong> <a href={details.assetSecurityLink}>{details.assetSecurityLink}</a>
      </p>
      <p>{details.description}</p>
      <p>
        <strong>AI Learning Concepts:</strong> {details.aiLearningConcepts}
      </p>
      <hr className="my-4" />

      <h4>Key Metrics</h4>
      <div className="row">
        <div className="col">
          <p>
            <strong>Revenue:</strong> {details.keyMetrics.revenue}
          </p>
          <p>
            <strong>Profit Margin:</strong> {details.keyMetrics.profitMargin}
          </p>
        </div>
        <div className="col">
          <p>
            <strong>Customer Base:</strong> {details.keyMetrics.customerBase}
          </p>
          <p>
            <strong>Employees:</strong> {details.keyMetrics.employees}
          </p>
        </div>
      </div>
      <hr className="my-4" />

      <h4>Competitive Advantage</h4>
      <ul>
        {details.competitiveAdvantage.map((advantage, index) => (
          <li key={index}>{advantage}</li>
        ))}
      </ul>
      <hr className="my-4" />

      <h4>Target Market</h4>
      <p>{details.targetMarket}</p>
      <hr className="my-4" />

      <h4>Growth Strategy</h4>
      <p>{details.growthStrategy}</p>
      <hr className="my-4" />

      <h4>Financial Highlights</h4>
      <div className="row">
        <div className="col">
          <p>
            <strong>Annual Revenue:</strong>
          </p>
          <ul>
            {details.financialHighlights.annualRevenue.map((revenue, index) => (
              <li key={index}>
                {revenue.year}: {revenue.amount}
              </li>
            ))}
          </ul>
        </div>
        <div className="col">
          <p>
            <strong>Net Income:</strong>
          </p>
          <ul>
            {details.financialHighlights.netIncome.map((income, index) => (
              <li key={index}>
                {income.year}: {income.amount}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <p>
        <strong>Total Assets:</strong> {details.financialHighlights.totalAssets}
      </p>
      <p>
        <strong>Total Liabilities:</strong> {details.financialHighlights.totalLiabilities}
      </p>
      <hr className="my-4" />

      <h4>Management Team</h4>
      <ul>
        {details.managementTeam.map((member, index) => (
          <li key={index}>
            {member.name} - {member.position}
          </li>
        ))}
      </ul>
      <hr className="my-4" />

      <p>
        <strong>Date Submitted:</strong> {details.dateSubmitted}
      </p>
      <p>
        <strong>Certification ID:</strong> {details.certificationId}
      </p>
      <p>
        <strong>Date Posted:</strong> {details.datePosted}
      </p>
    </>
  );
};

export default CompanyOverview;
