import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { useUserContext } from 'shared/UserContext';
import MutationDocs from '../Generic/mutationDoc';
import { EntityRecordDeleteProps } from '../Generic/type';

const EntityRecordDeletePopup: React.FC<EntityRecordDeleteProps> = props => {
  const { client } = useUserContext();
  const entityUpsertState = MutationDocs[props.entityName](client);
  const [rowData, setRowData] = useState(props.rowData);

  useEffect(() => {
    setRowData(props.rowData);
  }, [props.rowData]);

  const deleteEntityRecord = () => {
    const data = { ...rowData };
    for (const [key, value] of Object.entries(data)) {
      if (typeof value === 'object') delete data[key];
    }
    Object.assign(data, { isDeleted: true });

    entityUpsertState
      .mutateAsync({
        objects: [data],
      })
      .then(props.onDeleteSuccess)
      .catch(props.onDeleteError);
  };

  return (
    <Dialog
      visible={props.visible}
      onHide={props.onHide}
      breakpoints={{ '576px': '80vw' }}
      style={{ width: '40vw' }}
      header="Confirmation"
      closable={!entityUpsertState.isLoading}
      footer={
        <Button
          className="p-button-danger"
          label="Delete !"
          onClick={deleteEntityRecord}
          loading={entityUpsertState.isLoading}
        />
      }
    >
      <h4 className="text-lg py-3 text-center">
        <span className="pi pi-exclamation-triangle mr-3"></span>
        {`Are you sure to delete "${rowData?.name ?? rowData?.id}"?`}
      </h4>
    </Dialog>
  );
};

export default EntityRecordDeletePopup;
