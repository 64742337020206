import React from 'react';
import { Container } from 'reactstrap';
import Section from './Section';

const FinancialSummary = () => {
  document.title = 'Financial Summary | crwdcapital - universal wallet';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Section />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FinancialSummary;
