import BreadCrumb from 'Components/Common/BreadCrumb';
import EntityRecordAdd from 'Components/EntityRecord/Add';
import EntityRecordEdit from 'Components/EntityRecord/Edit';
import { useGetOrganizationByUserIdQuery } from 'graphql/generated/resourceApi';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from 'reactstrap';
import { useUserContext } from 'shared/UserContext';
import ErrorImg from '../../../../assets/images/error.svg';

const OrganizationPage = () => {
  const { userId, client } = useUserContext();
  const { data, isLoading, error, refetch } = useGetOrganizationByUserIdQuery(client, { userId: userId || '' });

  useEffect(() => {
    if (error) {
      toast.error((error as any)?.response?.errors[0]?.message);
    }
  }, [error]);

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Organization" pageTitle="Pages" />
          <Row
            className={isLoading || error ? 'justify-content-center align-items-center' : ''}
            style={{ minHeight: 'calc(100vh - 200px)' }}
          >
            <Col className={isLoading || error ? 'text-center' : ''} lg={12}>
              {isLoading && <Spinner color="primary">Loading...</Spinner>}
              {!isLoading && error && <img src={ErrorImg} width={300} />}
              {!isLoading &&
                !error &&
                (data?.organization?.length ? (
                  <Card>
                    <CardHeader>
                      <h4 className="card-title mb-0">Update Organization</h4>
                    </CardHeader>
                    <CardBody>
                      <EntityRecordEdit
                        rowData={data?.organization[0]}
                        entityName="Organization"
                        wizardView={true}
                        onSubmitSuccess={() => {
                          toast.success('Record Updated successfully !');
                          refetch();
                        }}
                        onSubmitError={(error: any) => {
                          toast.error(error.response?.errors[0]?.message);
                        }}
                      />
                    </CardBody>
                  </Card>
                ) : (
                  <Card>
                    <CardHeader>
                      <h4 className="card-title mb-0">Create Organization</h4>
                    </CardHeader>
                    <CardBody>
                      <EntityRecordAdd
                        entityName="Organization"
                        wizardView={true}
                        onSubmitSuccess={() => {
                          toast.success('Record created successfully !');
                          refetch();
                        }}
                        onSubmitError={(error: any) => {
                          toast.error(error.response?.errors[0]?.message);
                        }}
                      />
                    </CardBody>
                  </Card>
                ))}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default OrganizationPage;
