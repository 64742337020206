const CompanyDocuments = ({ details }) => {
  return (
    <>
      <h2>Company Documents</h2>
      <ul>
        {details.companyDocuments.map((document, index) => (
          <li key={index}>
            <a href={document.link}>{document.title}</a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default CompanyDocuments;
