const CompetitorRiskAssessment = ({ details }) => {
  return (
    <>
      <div className="competitor-risk-assessment">
        <h2 className="section-title">Competitor Risk Assessment</h2>

        <div className="section">
          <h3 className="subsection-title">Competitive Strategies</h3>
          {details.competitorRiskAssessment.competitiveStrategies.sections.map((section, index) => (
            <div key={index} className="subsection">
              <h4 className="subsection-heading">{section.title}</h4>
              <p className="subsection-content">{section.content}</p>
            </div>
          ))}
          <p className="closing-statement">{details.competitorRiskAssessment.competitiveStrategies.closing}</p>
        </div>

        <div className="section">
          <h3 className="subsection-title">Market Share</h3>
          {details.competitorRiskAssessment.marketShare.sections.map((section, index) => (
            <div key={index} className="subsection">
              <h4 className="subsection-heading">{section.title}</h4>
              <p className="subsection-content">{section.content}</p>
            </div>
          ))}
          <p className="closing-statement">{details.competitorRiskAssessment.marketShare.closing}</p>
        </div>

        <div className="section">
          <h3 className="subsection-title">Potential Threats</h3>
          {details.competitorRiskAssessment.potentialThreats.sections.map((section, index) => (
            <div key={index} className="subsection">
              <h4 className="subsection-heading">{section.title}</h4>
              <p className="subsection-content">{section.content}</p>
            </div>
          ))}
          <p className="closing-statement">{details.competitorRiskAssessment.potentialThreats.closing}</p>
        </div>
      </div>
    </>
  );
};

export default CompetitorRiskAssessment;
