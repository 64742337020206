import { Container } from 'reactstrap';
import BreadCrumb from 'Components/Common/BreadCrumb';

interface IframeProps {
  url: string;
  background?: string;
  breadcrumbTitle?: string;
  pageTitle?: string;
}

const Iframe = ({ url, background, breadcrumbTitle, pageTitle }: IframeProps) => {
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={breadcrumbTitle} pageTitle={pageTitle} />
        <iframe src={url} style={{ width: '100%', height: '90vh', backgroundColor: background || '#FFF' }} />
      </Container>
    </div>
  );
};

export default Iframe;
