import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const buttons = [
  { text: 'Live', rank: 'Rank A: Real-Time Performance' },
  { text: '1D', rank: 'Rank B: 1 Day Performance' },
  { text: '5D', rank: 'Rank C: 5 Day Performance' },
  { text: '1M', rank: 'Rank D: 1 Month Performance' },
  { text: '3M', rank: 'Rank E: 3 Month Performance' },
  { text: 'YTD', rank: 'Rank F: Year-to-Date (YTD) Performance' },
  { text: '1Y', rank: 'Rank G: 1 Year Performance' },
  { text: '3Y', rank: 'Rank H: 3 Year Performance' },
  { text: '5Y', rank: 'Rank I: 5 Year Performance' },
  { text: '10Y', rank: 'Rank J: 10 Year Performance' },
];

const SectorPerformanceChart = ({ data, horizontal = false }) => {
  const [selectedRank, setSelectedRank] = useState(buttons[0].rank);
  const [series, setSeries] = useState([] as any);
  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      stacked: false,
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    colors: [
      function ({ value }) {
        if (value > 0) {
          return '#00cfe5';
        } else {
          return '#e80074';
        }
      },
    ],
    plotOptions: {
      bar: {
        horizontal: horizontal,
      },
    },
    xaxis: {
      categories: [],
      labels: {
        trim: !horizontal,
        style: {
          fontSize: '12px',
          colors: [],
        },
        formatter: function (val) {
          if (horizontal) {
            if (Number.isInteger(val)) {
              return `${val}%`;
            } else {
              return `${parseFloat(val).toFixed(2)}%`;
            }
          }
          return val;
        },
      },
    },
    yaxis: {
      title: {
        text: 'Performance',
      },
    },
    title: {
      text: 'Sector Performance',
      align: 'left',
      style: {
        fontWeight: 500,
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
      floating: false,
      offsetY: 10,
      offsetX: 0,
    },
    grid: {
      row: {
        colors: ['transparent', 'transparent'],
        opacity: 0.2,
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value + '%';
        },
      },
    },
    dataLabels: {
      formatter: function (value) {
        return value + '%';
      },
    },
  } as any);

  useEffect(() => {
    const sectors = Object.keys(data[selectedRank]);
    const performance = Object.values(data[selectedRank]).map((value: any) => parseFloat(value));

    setSeries([{ name: selectedRank, data: performance }]);
    setOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        categories: sectors,
      },
    }));
  }, [data, selectedRank]);

  return (
    <div>
      <div className="flex space-x-4 mb-4">
        {buttons.map((button, i) => (
          <button
            key={i}
            className={`py-2 px-4 border-0 rounded ${
              selectedRank === button.rank ? 'bg-white text-black' : 'bg-blue-200 text-white'
            }`}
            onClick={() => setSelectedRank(button.rank)}
          >
            {button.text}
          </button>
        ))}
      </div>
      <ReactApexChart options={options} series={series} type="bar" height={450} />
    </div>
  );
};

export default SectorPerformanceChart;
