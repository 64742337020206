import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import Countdown from 'react-countdown';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Link, useHistory } from 'react-router-dom';

import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';

// Import Images
import Img2 from '../../../assets/images/nft/img-02.jpg';
import Img3 from '../../../assets/images/nft/img-03.jpg';
import Img4 from '../../../assets/images/nft/img-04.jpg';
import Img5 from '../../../assets/images/nft/img-05.jpg';

import ImgGif3 from '../../../assets/images/nft/gif/img-3.gif';
import ImgGif4 from '../../../assets/images/nft/gif/img-4.gif';

import avatar1 from '../../../assets/images/users/avatar-1.jpg';
import avatar3 from '../../../assets/images/users/avatar-3.jpg';
import avatar5 from '../../../assets/images/users/avatar-5.jpg';
import avatar8 from '../../../assets/images/users/avatar-8.jpg';
import avatar10 from '../../../assets/images/users/avatar-10.jpg';

import { aution, topCreator, topDrop } from '../../../common/data';
import { useGetAskByAssetQuery } from 'graphql/generated/resourceApi';
import { useClient } from 'shared/UserContext';

const ESGAuction = props => {
  document.title = 'ESG Auction | crwdcapital - universal wallet';
  const client = useClient();
  const history = useHistory();
  const [displayCategory, setCategory] = useState('All');
  const [NFTList, setNFTList] = useState(aution);
  const { isComponentLayout, dashboardId, assetId } = props;
  const askByAssetQueryState = useGetAskByAssetQuery(client, { assetId: assetId });
  const uploadEndpoint = process.env.REACT_APP_FILE_URL;
  let esgAsks = [];
  const esgAsksData = askByAssetQueryState.data?.getAskByAsset || [];
  let esgAskImages = [];
  if (esgAsksData?.length) {
    console.log(esgAsksData);
    esgAsks = esgAsksData.map(esgAsk => ({ ...esgAsk, image: JSON.parse(esgAsk.image) || [] }));
  }
  const goToDetails = ask => history.push(`/ask-details/${dashboardId}/${ask.id}`);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>You are good to go!</span>;
    } else {
      return (
        <>
          <h5 id="auction-time-1" className="mb-0 text-white">
            {days} : {hours} : {minutes} : {seconds}
          </h5>
        </>
      );
    }
  };

  const onUpdate = value => {
    setNFTList(aution.filter(NFT => NFT.price >= value[0] && NFT.price <= value[1]));
  };

  const category = e => {
    const data = aution.filter(item => item.category === e);
    console.log(e, data);
    setNFTList(data);
  };

  const fileType = e => {
    setNFTList(aution.filter(item => item.fileType === e));
  };

  const salesType = e => {
    setNFTList(aution.filter(item => item.sales === e));
  };

  const searchNFT = e => {
    console.log(e);
    var inputVal = e.target.value.toLowerCase();
    function filterItems(arr, query) {
      return arr.filter(function (el) {
        return el.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
    }
    var filterData = filterItems(aution, inputVal);
    if (filterData.length === 0) {
      document.getElementById('noresult').style.display = 'block';
      document.getElementById('loadmore').style.display = 'none';
    } else {
      document.getElementById('noresult').style.display = 'none';
      document.getElementById('loadmore').style.display = 'block';
    }
    setNFTList(filterData);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {!isComponentLayout && <BreadCrumb title="ESG Auction" pageTitle="NFT Marketplace" />}
          <Row>
            <Col xxl={12}>
              <Card>
                <CardHeader className="border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">Explore Product</h5>
                    <div>
                      <Link
                        className="btn btn-success"
                        id="filter-collapse"
                        data-bs-toggle="collapse"
                        to="#collapseExample"
                      >
                        <i className="ri-filter-2-line align-bottom"></i>
                        Filters
                      </Link>
                    </div>
                  </div>

                  <UncontrolledCollapse toggler="#filter-collapse" defaultOpen>
                    <Row className="row-cols-xxl-5 row-cols-lg-3 row-cols-md-2 row-cols-1 mt-3 g-3">
                      <Col>
                        <h6 className="text-uppercase fs-12 mb-2">Search</h6>

                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search product name"
                          autoComplete="off"
                          id="searchProductList"
                          onChange={searchNFT}
                        />
                      </Col>
                      <Col>
                        <h6 className="text-uppercase fs-12 mb-2">Select Category</h6>
                        <select
                          className="form-control"
                          data-choices
                          name="select-category"
                          data-choices-search-false
                          id="select-category"
                          onChange={e => category(e.target.value)}
                        >
                          <option value="">Select Category</option>
                          <option value="upto-15">upto-15</option>
                          <option value="upto-20">upto-20</option>
                          <option value="upto-25">upto-25</option>
                          <option value="upto-30">upto-30</option>
                          <option value="upto-35">upto-35</option>
                          <option value="upto-40">upto-40</option>
                        </select>
                      </Col>
                      <Col>
                        <h6 className="text-uppercase fs-12 mb-2">File Type</h6>
                        <select
                          className="form-control"
                          data-choices
                          name="file-type"
                          data-choices-search-false
                          id="file-type"
                          onChange={e => fileType(e.target.value)}
                        >
                          <option value="">File Type</option>
                          <option value="jpg">Images</option>
                          <option value="mp4">Video</option>
                          <option value="mp3">Audio</option>
                          <option value="gif">Gif</option>
                        </select>
                      </Col>
                      <Col>
                        <h6 className="text-uppercase fs-12 mb-4">Price</h6>

                        <Nouislider
                          range={{ min: 0, max: 2000 }}
                          tooltips={true}
                          start={[0, 1000]}
                          connect
                          className="slider"
                          id="range-product-price"
                          onSlide={onUpdate}
                        />
                      </Col>
                    </Row>
                  </UncontrolledCollapse>
                </CardHeader>
              </Card>
            </Col>
            <Col xxl={9}>
              <Row>
                {esgAsks
                  .filter(({ category }) => displayCategory === category || displayCategory === 'All')
                  .map((item, key) => (
                    <Col
                      xxl={3}
                      lg={4}
                      md={6}
                      className="product-item upto-15"
                      style={{ cursor: 'pointer' }}
                      key={key}
                      onClick={() => goToDetails(item)}
                    >
                      <Card className="explore-box card-animate">
                        <div className="position-relative rounded overflow-hidden">
                          <img
                            src={`${uploadEndpoint}/?id=${item.image[0]}`}
                            alt=""
                            className="card-img-top explore-img"
                          />
                          <div className="discount-time">
                            <Countdown date={item.endDate} renderer={renderer} />
                          </div>
                        </div>
                        <CardBody>
                          <h5 className="text-success">
                            <i className="mdi mdi-ethereum"></i> {item.askNumber} {item.currency.name}
                          </h5>
                          <h6 className="fs-16 mb-3">
                            <Link to="/apps-nft-item-details">{item.name}</Link>
                          </h6>
                          <div>
                            <span className="text-muted float-end">Available: {item.totalAvailable}</span>
                            <span className="text-muted">Sold: {item.sold}</span>
                            <div className="progress progress-sm mt-2">
                              <div
                                className={'progress-bar progress-bar-striped bg-' + item.progressClass}
                                role="progressbar"
                                style={{ width: item.size }}
                                aria-valuenow="67"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
              </Row>
              {!esgAsks?.length && (
                <Row>
                  <Col lg={12}>
                    <div className="text-center mb-3">
                      <button className="btn btn-link text-success mt-2" id="loadmore">
                        <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load more{' '}
                      </button>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="py-4 text-center" id="noresult" style={{ display: 'none' }}>
                      <lord-icon
                        src="https://cdn.lordicon.com/msoeawqm.json"
                        trigger="loop"
                        colors="primary:#405189,secondary:#0ab39c"
                        style={{ width: '72px', height: '72px' }}
                      ></lord-icon>
                      <h5 className="mt-4">Sorry! No Result Found</h5>
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            <Col xxl={3}>
              <Card>
                <CardHeader className="d-flex align-items-center">
                  <h6 className="card-title mb-0 flex-grow-1">Top Drop</h6>
                  <Link className="text-muted" to="#">
                    See All <i className="ri-arrow-right-line align-bottom"></i>
                  </Link>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive table-card">
                    <table className="table table-borderless align-middle">
                      <tbody>
                        {topDrop.map((item, key) => (
                          <tr key={key}>
                            <td>
                              <div className="d-flex align-items-center">
                                <img src={item.img} alt="" className="avatar-sm object-cover rounded-circle" />
                                <div className="ms-2">
                                  <Link to="/apps-nft-item-details">
                                    <h6 className="fs-15 mb-1">{item.title}</h6>
                                  </Link>
                                  <p className="mb-0 text-muted">Sold at {item.price} ETH</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <small>{item.time}</small>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardHeader className="d-flex align-items-center">
                  <h6 className="card-title mb-0 flex-grow-1">Top Creator</h6>
                  <Link className="text-muted" to="/apps-nft-item-details">
                    See All <i className="ri-arrow-right-line align-bottom"></i>
                  </Link>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive table-card">
                    <table className="table table-borderless align-middle">
                      <tbody>
                        {topCreator.map((item, key) => (
                          <tr key={key}>
                            <td>
                              <div className="d-flex align-items-center">
                                <img src={item.img} alt="" className="avatar-sm object-cover rounded-circle" />
                                <div className="ms-2">
                                  <Link to="#!">
                                    <h6 className="fs-15 mb-1">{item.name}</h6>
                                  </Link>
                                  <p className="mb-0 text-muted">{item.products} Products</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                className={item.isFollow ? 'btn btn-sm btn-success' : 'btn btn-sm btn-soft-success'}
                              >
                                {item.isFollow ? 'Follow' : 'Unfollow'}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ESGAuction;
