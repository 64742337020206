import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { ProgressBar } from 'primereact/progressbar';
import './style.scss';

/**
 * This is a custom preview component using PrimeReact components.
 * It is based on the default one from react-dropzone-uploader but gives much better feedback the upload has completed
 * TODO:ENHANCE so it handles _all_ the cases the default one does. Currently seems to handle most scenarios though
 * @param props See prop types from https://github.com/fortana-co/react-dropzone-uploader/blob/master/src/Preview.tsx
 * @returns Preview component for react-dropzone-uploader
 */
function CustomPreview(props: any): JSX.Element {
  const {
    className,
    imageClassName,
    style,
    imageStyle,
    fileWithMeta: { cancel, remove, restart },
    meta: { name = '', percent = 0, size = 0, previewUrl, status, validationError },
    isUpload,
    canCancel,
    canRemove,
    canRestart,
    disableUpload = false,
    extra: { minSizeBytes },
  } = props;

  // error statuses
  const errored = status === 'error_upload_params' || status === 'exception_upload' || status === 'error_upload';

  // Messages to display about file state
  let fileState = '';
  if (errored) {
    fileState = ` (upload failed)`;
  }
  if (status === 'aborted') {
    fileState = ` (cancelled)`;
  }

  if (status === 'error_file_size' || status === 'error_validation') {
    return (
      <div className={className} style={style}>
        <span style={{ color: 'red' }}>
          {name}
          {fileState}
        </span>
        {status === 'error_file_size' && <span>{size < minSizeBytes ? 'File too small' : 'File too big'}</span>}
        {status === 'error_validation' && <span>{JSON.stringify(validationError)}</span>}
        {canRemove && (
          <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" onClick={remove} />
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-row w-full justify-between items-center" style={style}>
      {/* Show a preview image or text if preview isn't possible */}
      {previewUrl ? (
        <img
          className={imageClassName}
          style={imageStyle}
          src={previewUrl}
          alt={`${name}${fileState}`}
          title={`${name}${fileState}`}
        />
      ) : (
        <span>
          {name}
          {fileState}
        </span>
      )}

      {/* Display Progress or a completed message */}
      {isUpload && status !== 'done' && status !== 'headers_received' ? (
        <>
          <div style={{ minWidth: '25%' }}>
            {!!percent && <ProgressBar value={parseInt(percent)} className="progress_bar" />}
            <Message
              severity="info"
              text={status === 'ready' ? 'Ready to upload' : status}
              className="message no_icon"
            />
          </div>
        </>
      ) : (
        <Message severity="success" text="File Uploaded" className="message" />
      )}

      {/* Show different buttons depending on state */}
      <div id="buttonGroup">
        {!disableUpload &&
          canRestart &&
          ['error_upload_params', 'exception_upload', 'error_upload', 'aborted', 'ready'].includes(status) && (
            <Button
              icon="pi pi-upload"
              type="button"
              className="p-button-rounded p-button-text p-button-success"
              tooltip="Upload"
              onClick={restart}
            />
          )}
        {canCancel && status === 'uploading' && (
          <Button
            icon="pi pi-times"
            type="button"
            className="p-button-rounded p-button-warning p-button-text"
            tooltip="Cancel"
            onClick={cancel}
          />
        )}
        {canRemove && status !== 'preparing' && status !== 'getting_upload_params' && status !== 'uploading' && (
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger p-button-text"
            type="button"
            tooltip="Remove"
            onClick={remove}
          />
        )}
      </div>
    </div>
  );
}

export default CustomPreview;
