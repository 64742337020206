import React from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { BasicTreemap, MultiTreemap, DiffColorTreemap, ColorRangeTreemap } from './TreemapCharts';
import ListTables from 'pages/Tables/ListTables/ListTables';

const SocialIdentity = () => {
  document.title = 'Social Identity | crwdcapital - universal wallet';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Social Identity" pageTitle="Identity Compliance" />

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Distributed Social Identity Chart (Different Color for each Cell)</h4>
                </CardHeader>

                <CardBody>
                  <DiffColorTreemap dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-info","--vz-warning", "--vz-danger"]' />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ListTables />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SocialIdentity;
