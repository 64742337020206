export const SigmaPrimeAddressInitialValues = {
  //Required fields
  personUniqueHash: '',

  //Availabel Fields
  personAddress1: '',
  personAddress2: '',
  personAddressLatitude: null,
  personAddressLongitude: null,
  personCity: '',
  personCountryCode: '',
  personEmail: null,
  personFirstName: null,
  personLastName: null,
  personMiddleName: null,
  personPhoneNumber: null,
  personPostal: '',
  personPostal4: '',
  personProvinceState: '',
  personRegionCounty: '',
  sigmaPrimeId: null,

  //Default passing fields
  addressEmail: null,
  addressIp: null,
  addressRecordType: null,
  addressSsn: null,
  addressSubindustry: null,
  addressType: null,
  ageRange: null,
  carrierRoute: null,
  childPresent: null,
  congressionalDistrict: null,
  dataPedigree: null,
  dpvCode: null,
  dwellingType: null,
  elotSequence: null,
  estimatedAge: null,
  estimatedIncome: null,
  estimatedWealth: null,
  ethnicCd: null,
  exactAge: null,
  firstInHouse: null,
  geocode: null,
  homeownerType: null,
  marital: null,
  medianHomeValue: null,
  medianYrsEducation: null,
  mfdu: null,
  personDob: null,
  personSector: null,
  property: null,
  rdi: null,
  sex: null,
  timeZone: null,
  title: null,
};

export const SigmaPrimeInitialValues = {
  //Required fields
  personExchangeRequest: 'd',
  personFirstName: '',
  personPrimaryEmail: '',
  personPrimaryKycStatus: 'd',
  personPrivacyPolicy: 'd',
  personStatus: 'd',

  //Availabel Fields
  personFamilyName: null,
  personHandleName: null,
  personLastName: null,
  personMiddleName: null,
  personPrimaryMobile: null,

  //Default passing fields
  crwdId: null,
  isAccredited: null,
  personBusinessEmail: null,
  personNameSuffix: null,
  personParent1SigmaId: null,
  personParent2SigmaId: null,
  personPrimaryAmlStatus: null,
  personPrimaryFacePhoto: null,
  personPrimaryIpAddress: null,
  personPrimaryLanguageId: null,
  personPrimaryPhone: null,
  personTitleName: null,
  personUniqueHash: null,
  sigmaPrimeKindId: null,
};

export const UserInitialValues = {
  //Required fields
  password: 'password',
  uniqueHash: 'uuid',

  //Availabel Fields
  sigmaPrimeId: null,
  statusId: null,

  //Default passing fields
  countryCode: null,
  crwdsystemsApi: null,
  isAdmin: null,
  organizationId: null,
  preferences: null,
  rememberToken: null,
  smartContractKindId: null,
};
