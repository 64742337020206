import { KycStatusFieldsFragmentDoc, KycKindFieldsFragmentDoc } from '../../graphql/generated/graphqlRequest';
import {
  CountryFieldsFragmentDoc,
  GicsSectorFieldsFragmentDoc,
  GroupFieldsFragmentDoc,
  IndustryFieldsFragmentDoc,
  SicCodeFieldsFragmentDoc,
  SubindustryFieldsFragmentDoc,
  CurrencyKindFieldsFragmentDoc,
  CurrencyFieldsFragmentDoc,
  OrganizationFieldsFragmentDoc,
  CurrencyOwnerFieldsFragmentDoc,
  WalletKindFieldsFragmentDoc,
  WalletFieldsFragmentDoc,
  CertificationFieldsFragmentDoc,
  ClearinghouseKindFieldsFragmentDoc,
  ClearinghouseFieldsFragmentDoc,
  ClearinghouseCertificationFieldsFragmentDoc,
  StatusKindFieldsFragmentDoc,
  StatusFieldsFragmentDoc,
  MerchantFieldsFragmentDoc,
  MerchantCurrencyFieldsFragmentDoc,
  MerchantOrganizationFieldsFragmentDoc,
  SettingFieldsFragmentDoc,
  TradeKindFieldsFragmentDoc,
  ExchangeFieldsFragmentDoc,
  SmartContractKindFieldsFragmentDoc,
  SmartContractFieldsFragmentDoc,
  AppraisalFieldsFragmentDoc,
  AssetKindFieldsFragmentDoc,
  AssetFieldsFragmentDoc,
  TradeFieldsFragmentDoc,
  AskFieldsFragmentDoc,
  AskDropDownFieldsFragmentDoc,
  PutFieldsFragmentDoc,
  PutDropDownFragmentDoc,
  InteractionFieldsFragmentDoc,
  ExchangeKindFieldsFragmentDoc,
  FavoriteFieldsFragmentDoc,
  // PasswordResetsFieldsFragmentDoc,
  PaymentFieldsFragmentDoc,
  ProductFieldsFragmentDoc,
  // StatesFieldsFragmentDoc,
  TransactionFieldsFragmentDoc,
  TransactionKindFieldsFragmentDoc,
  UserFieldsFragmentDoc,
  OrganizationKindFieldsFragmentDoc,
  SigmaPrimeFieldsFragmentDoc,
  SigmaPrimeKindFieldsFragmentDoc,
  SigmaPrimeAddressFieldsFragmentDoc,
  AddressAttributesFieldsFragmentDoc,
  DatasourceFieldsFragmentDoc,
  DocumentUserFieldsFragmentDoc,
  SmartContractLineageFieldsFragmentDoc,
  SmartContractCommissionFieldsFragmentDoc,
  SmartContractDocumentFieldsFragmentDoc,
  WalletInventoryFieldsFragmentDoc,
  AssetAccountFieldsFragmentDoc,
  OrderFieldsFragmentDoc,
  ProductDistributionFieldsFragmentDoc,
  ProductStatusFieldsFragmentDoc,
  ProductKindFieldsFragmentDoc,
  MaterialFieldsFragmentDoc,
  CartFieldsFragmentDoc,
  MarketFieldsFragmentDoc,
  OrganizationStatusFieldsFragmentDoc,
  ShippingFieldsFragmentDoc,
  ShopFieldsFragmentDoc,
  SupplyChainWaypointFieldsFragmentDoc,
  AskPutTransactionFieldsFragmentDoc,
} from 'graphql/generated/graphqlRequest';

const FragmentsDocs: any = {
  AddressAttributes: AddressAttributesFieldsFragmentDoc,
  Appraisal: AppraisalFieldsFragmentDoc,
  Ask: AskFieldsFragmentDoc,
  AskDropDown: AskDropDownFieldsFragmentDoc,
  Certification: CertificationFieldsFragmentDoc,
  Clearinghouse: ClearinghouseFieldsFragmentDoc,
  ClearinghouseCertification: ClearinghouseCertificationFieldsFragmentDoc,
  ClearinghouseKind: ClearinghouseKindFieldsFragmentDoc,
  Country: CountryFieldsFragmentDoc,
  Currency: CurrencyFieldsFragmentDoc,
  CurrencyKind: CurrencyKindFieldsFragmentDoc,
  CurrencyOwner: CurrencyOwnerFieldsFragmentDoc,
  Datasource: DatasourceFieldsFragmentDoc,
  Exchange: ExchangeFieldsFragmentDoc,
  ExchangeKind: ExchangeKindFieldsFragmentDoc,
  GicsSector: GicsSectorFieldsFragmentDoc,
  Group: GroupFieldsFragmentDoc,
  Industry: IndustryFieldsFragmentDoc,
  Interaction: InteractionFieldsFragmentDoc,
  Merchant: MerchantFieldsFragmentDoc,
  MerchantCurrency: MerchantCurrencyFieldsFragmentDoc,
  MerchantOrganization: MerchantOrganizationFieldsFragmentDoc,
  Asset: AssetFieldsFragmentDoc,
  AssetKind: AssetKindFieldsFragmentDoc,
  Organization: OrganizationFieldsFragmentDoc,
  OrganizationKind: OrganizationKindFieldsFragmentDoc,
  Put: PutFieldsFragmentDoc,
  PutDropDown: PutDropDownFragmentDoc,
  SicCode: SicCodeFieldsFragmentDoc,
  SigmaPrime: SigmaPrimeFieldsFragmentDoc,
  SigmaPrimeAddress: SigmaPrimeAddressFieldsFragmentDoc,
  SigmaPrimeKind: SigmaPrimeKindFieldsFragmentDoc,
  Settings: SettingFieldsFragmentDoc,
  SmartContract: SmartContractFieldsFragmentDoc,
  SmartContractKind: SmartContractKindFieldsFragmentDoc,
  SmartContractLineage: SmartContractLineageFieldsFragmentDoc,
  ProductStatus: ProductStatusFieldsFragmentDoc,
  SmartContractCommission: SmartContractCommissionFieldsFragmentDoc,
  SmartContractDocument: SmartContractDocumentFieldsFragmentDoc,
  Status: StatusFieldsFragmentDoc,
  StatusKind: StatusKindFieldsFragmentDoc,
  Subindustry: SubindustryFieldsFragmentDoc,
  Trade: TradeFieldsFragmentDoc,
  TradeKind: TradeKindFieldsFragmentDoc,
  Wallet: WalletFieldsFragmentDoc,
  WalletKind: WalletKindFieldsFragmentDoc,
  WalletInventory: WalletInventoryFieldsFragmentDoc,
  /* Dependency*/
  User: UserFieldsFragmentDoc,
  Product: ProductFieldsFragmentDoc,
  Transaction: TransactionFieldsFragmentDoc,
  DocumentUser: DocumentUserFieldsFragmentDoc,
  // States: StatesFieldsFragmentDoc,
  Favorite: FavoriteFieldsFragmentDoc,
  // PasswordResets: PasswordResetsFieldsFragmentDoc,
  Payment: PaymentFieldsFragmentDoc,
  TransactionKind: TransactionKindFieldsFragmentDoc,
  AssetAccount: AssetAccountFieldsFragmentDoc,
  ProductDistribution: ProductDistributionFieldsFragmentDoc,
  Order: OrderFieldsFragmentDoc,
  ProductKind: ProductKindFieldsFragmentDoc,
  Material: MaterialFieldsFragmentDoc,
  Cart: CartFieldsFragmentDoc,
  Market: MarketFieldsFragmentDoc,
  OrganizationStatus: OrganizationStatusFieldsFragmentDoc,
  Shipping: ShippingFieldsFragmentDoc,
  Shop: ShopFieldsFragmentDoc,
  SupplyChainWaypoint: SupplyChainWaypointFieldsFragmentDoc,
  AskPutTransaction: AskPutTransactionFieldsFragmentDoc,
  KycStatus: KycStatusFieldsFragmentDoc,
  KycKind: KycKindFieldsFragmentDoc,
};

export default FragmentsDocs;
