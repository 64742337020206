import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { useClient, useUserContext } from 'shared/UserContext';
import FeatherIcon from 'feather-icons-react';

//import images
import avatar from '../../assets/images/users/user-dummy-img.jpg';
import { useUserByIdQuery } from 'graphql/generated/resourceApi';

const ProfileDropdown = () => {
  const { userId, userRole, logout } = useUserContext();

  const client = useClient();
  const userData = useUserByIdQuery(client, { id: userId });

  const userName = userData?.data?.userById?.sigmaPrime?.personFirstName
    ? userData?.data?.userById?.sigmaPrime?.personFirstName
    : userRole?.charAt(0).toUpperCase() + userRole?.slice(1)!;

  //Dropdown Toggle
  const [modal, setModal] = useState(false);
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const onConfirmLogout = async () => {
    await logout();
    setModal(false);
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={
                userData?.data?.userById?.sigmaPrime?.personPrimaryFacePhoto
                  ? `${process.env.REACT_APP_FILE_URL}/?id=${userData?.data?.userById?.sigmaPrime?.personPrimaryFacePhoto}`
                  : avatar
              }
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
              {/* <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span> */}
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {userName}!</h6>
          <DropdownItem href="/pages-profile">
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </DropdownItem>
          <DropdownItem href="/apps-chat">
            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Messages</span>
          </DropdownItem>
          <DropdownItem href="/apps-tasks-kanban">
            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Taskboard</span>
          </DropdownItem>
          <DropdownItem href="/pages-faqs">
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Help</span>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem href="/pages-profile">
            <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle">
              Balance : <b>$5971.67</b>
            </span>
          </DropdownItem>
          <DropdownItem href="/pages-profile-settings">
            <span className="badge bg-soft-success text-success mt-1 float-end">New</span>
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Settings</span>
          </DropdownItem>
          <DropdownItem href="/auth-lockscreen-basic">
            <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Lock screen</span>
          </DropdownItem>
          <DropdownItem onClick={() => setModal(true)}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{' '}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Modal id="logout" isOpen={modal} toggle={() => setModal(!modal)} centered backdrop="static">
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalBody>
          <h3>
            <FeatherIcon icon="log-out" /> You want to Sign out?
          </h3>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-soft-success" onClick={() => setModal(false)}>
            No
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              onConfirmLogout();
            }}
          >
            Yes, Logout
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ProfileDropdown;
