import React from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Section from './Section';
import VectorMaps from 'pages/Maps/VectorMaps/VectorMaps';
import Vectormap from 'pages/DashboardNFT/VectorMap';

const MyOrganization = () => {
  document.title = 'My Company | crwdcapital - universal wallet';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Section />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyOrganization;
