import React, { useState } from 'react';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import UiContent from '../../../Components/Common/UiContent';
import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
import { BrowserDefaults, CustomStyles, SupportedElements } from './FormValidationCode';

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
  CardHeader,
} from 'reactstrap';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DiffColorTreemap } from 'pages/Charts/ApexCharts/TreemapCharts/TreemapCharts';

const IdentityAml = () => {
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: '',
      lastname: '',
      city: '',
      state: '',
      zip: '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required('Please Enter Your First Name'),
      lastname: Yup.string().required('Please Enter Your Last Name'),
      city: Yup.string().required('Please Enter Your City'),
      state: Yup.string().required('Please Enter Your State'),
      zip: Yup.string().required('Please Enter Your Zip'),
    }),
    onSubmit: values => {
      console.log('values', values);
    },
  });

  const [formValidation, setValidation] = useState({
    fnm: null,
    lnm: null,
    unm: null,
    city: null,
    stateV: null,
  });

  function handleSubmit(e) {
    e.preventDefault();
    const modifiedV = { ...formValidation };
    var fnm = document.getElementById('validationTooltip01').value;
    var lnm = document.getElementById('validationTooltip02').value;
    var unm = document.getElementById('validationTooltipUsername').value;
    var city = document.getElementById('validationTooltip03').value;
    var stateV = document.getElementById('validationTooltip04').value;

    if (fnm === '') {
      modifiedV['fnm'] = false;
    } else {
      modifiedV['fnm'] = true;
    }

    if (lnm === '') {
      modifiedV['lnm'] = false;
    } else {
      modifiedV['lnm'] = true;
    }

    if (unm === '') {
      modifiedV['unm'] = false;
    } else {
      modifiedV['unm'] = true;
    }

    if (city === '') {
      modifiedV['city'] = false;
    } else {
      modifiedV['city'] = true;
    }

    if (stateV === '') {
      modifiedV['stateV'] = false;
    } else {
      modifiedV['stateV'] = true;
    }
    setValidation(modifiedV);
  }

  //for change tooltip display propery
  const onChangeValidation = (fieldName, value) => {
    const modifiedV = { ...validation };
    if (value !== '') {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    setValidation(modifiedV);
  };
  document.title = 'Validation | crwdcapital - universal wallet';

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="IDENTITY AML" pageTitle="Identity Compliance" />
          <Row>
            <Col lg={12}>
              <Card>
                <PreviewCardHeader title="Full Name" />
                <CardBody>
                  <div className="live-preview">
                    <form className="row g-3">
                      <Col md={4}>
                        <Label for="validationDefault01" className="form-label">
                          First name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault01"
                          defaultValue="Mark"
                          required
                        />
                      </Col>
                      <Col md={4}>
                        <Label for="validationDefault02" className="form-label">
                          Middle Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault02"
                          defaultValue="Otto"
                          required
                        />
                      </Col>
                      <Col md={4}>
                        <Label for="validationDefault02" className="form-label">
                          Last Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault02"
                          defaultValue="Otto"
                          required
                        />
                      </Col>
                      <Col xs={12}>
                        <button className="btn btn-primary" type="submit">
                          Submit form
                        </button>
                      </Col>
                    </form>
                  </div>
                  <div className="d-none code-view">
                    <pre className="language-markup" style={{ height: '352px' }}>
                      <code>
                        <BrowserDefaults />
                      </code>
                    </pre>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <PreviewCardHeader title="Contact Information" />
                <CardBody>
                  <div className="live-preview">
                    <form className="row g-3">
                      <Col md={4}>
                        <Label for="validationDefault01" className="form-label">
                          Address
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault01"
                          defaultValue="Mark"
                          required
                        />
                      </Col>
                      <Col md={4}>
                        <Label for="validationDefault02" className="form-label">
                          Phone Number
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault02"
                          defaultValue="Otto"
                          required
                        />
                      </Col>
                      <Col md={4}>
                        <Label for="validationDefault02" className="form-label">
                          Email Address
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault02"
                          defaultValue="Otto"
                          required
                        />
                      </Col>
                      <Col xs={12}>
                        <button className="btn btn-primary" type="submit">
                          Submit form
                        </button>
                      </Col>
                    </form>
                  </div>
                  <div className="d-none code-view">
                    <pre className="language-markup" style={{ height: '352px' }}>
                      <code>
                        <BrowserDefaults />
                      </code>
                    </pre>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <PreviewCardHeader title="Full Name" />
                <CardBody>
                  <div className="live-preview">
                    <form className="row g-3">
                      <Col md={4}>
                        <Label for="validationDefault01" className="form-label">
                          Date of Birth
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault01"
                          defaultValue="Mark"
                          required
                        />
                      </Col>
                      <Col md={4}>
                        <Label for="validationDefault02" className="form-label">
                          Nationality/Citizenship
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault02"
                          defaultValue="Otto"
                          required
                        />
                      </Col>
                      <Col xs={12}>
                        <button className="btn btn-primary" type="submit">
                          Submit form
                        </button>
                      </Col>
                    </form>
                  </div>
                  <div className="d-none code-view">
                    <pre className="language-markup" style={{ height: '352px' }}>
                      <code>
                        <BrowserDefaults />
                      </code>
                    </pre>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <PreviewCardHeader title="Identification Documents" />
                <CardBody>
                  <div className="live-preview">
                    <form className="row g-3">
                      <Col md={4}>
                        <Label for="validationDefault01" className="form-label">
                          Passport
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault01"
                          defaultValue="Mark"
                          required
                        />
                      </Col>
                      <Col md={4}>
                        <Label for="validationDefault02" className="form-label">
                          Driver's License
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault02"
                          defaultValue="Otto"
                          required
                        />
                      </Col>
                      <Col md={4}>
                        <Label for="validationDefault02" className="form-label">
                          National Identity Card"
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault02"
                          defaultValue="Otto"
                          required
                        />
                      </Col>
                      <Col xs={12}>
                        <button className="btn btn-primary" type="submit">
                          Submit form
                        </button>
                      </Col>
                    </form>
                  </div>
                  <div className="d-none code-view">
                    <pre className="language-markup" style={{ height: '352px' }}>
                      <code>
                        <BrowserDefaults />
                      </code>
                    </pre>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <PreviewCardHeader title="Employment Details" />
                <CardBody>
                  <div className="live-preview">
                    <form className="row g-3">
                      <Col md={4}>
                        <Label for="validationDefault01" className="form-label">
                          Organization
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault01"
                          defaultValue="Mark"
                          required
                        />
                      </Col>
                      <Col md={4}>
                        <Label for="validationDefault02" className="form-label">
                          Job Title
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault02"
                          defaultValue="Otto"
                          required
                        />
                      </Col>
                      <Col md={4}>
                        <Label for="validationDefault02" className="form-label">
                          Duration
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault02"
                          defaultValue="Otto"
                          required
                        />
                      </Col>
                      <Col xs={12}>
                        <button className="btn btn-primary" type="submit">
                          Submit form
                        </button>
                      </Col>
                    </form>
                  </div>
                  <div className="d-none code-view">
                    <pre className="language-markup" style={{ height: '352px' }}>
                      <code>
                        <BrowserDefaults />
                      </code>
                    </pre>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <PreviewCardHeader title="AML Training and Experience" />
                <CardBody>
                  <div className="live-preview">
                    <form className="row g-3">
                      <Col md={4}>
                        <Label for="validationDefault01" className="form-label">
                          Organization
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault01"
                          defaultValue="Mark"
                          required
                        />
                      </Col>
                      <Col md={4}>
                        <Label for="validationDefault02" className="form-label">
                          Job Title
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault02"
                          defaultValue="Otto"
                          required
                        />
                      </Col>
                      <Col md={4}>
                        <Label for="validationDefault02" className="form-label">
                          Duration
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="validationDefault02"
                          defaultValue="Otto"
                          required
                        />
                      </Col>
                      <Col xs={12}>
                        <button className="btn btn-primary" type="submit">
                          Submit form
                        </button>
                      </Col>
                    </form>
                  </div>
                  <div className="d-none code-view">
                    <pre className="language-markup" style={{ height: '352px' }}>
                      <code>
                        <BrowserDefaults />
                      </code>
                    </pre>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IdentityAml;
