import React from 'react';
import { Controller } from 'react-hook-form';
import { MultiSelect, MultiSelectProps } from 'primereact/multiselect';
import { AdminInputBaseProps, buildClassName, validateProps } from '../AdminForm';

export type AdminInputMultiSelectProps = MultiSelectProps &
  AdminInputBaseProps & {
    optionValueType?: 'string' | 'number';
    isLabelFloatable?: boolean;
  };

const AdminInputMultiSelect: React.FC<AdminInputMultiSelectProps> = props => {
  validateProps(props);
  const {
    control,
    name,
    label,
    helpText,
    containerClassName,
    required,
    attributeType,
    defaultValue,
    options,
    isLabelFloatable = false,
    ...baseProps
  } = props;

  const fieldProps = {
    ...baseProps,
    ...attributeType?.props,
  };

  const priorityLabel = attributeType?.label ?? label ?? name;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{ required, ...attributeType?.validation }}
      render={({ field, fieldState, formState: _formState }) => {
        const errorMessage = fieldState.error?.message || fieldState.error?.type;

        return (
          <div className={containerClassName}>
            <div className={isLabelFloatable ? 'p-float-label' : ''}>
              <label className="p-inputtext-label">
                {priorityLabel}
                {required && <i className="text-red">*</i>}
              </label>
              <MultiSelect
                {...baseProps}
                id={name}
                className={buildClassName(fieldProps.className, errorMessage)}
                value={field.value}
                options={options || fieldProps.options}
                onChange={field.onChange}
                showClear={baseProps.showClear ?? true}
                showSelectAll={baseProps.showSelectAll ?? false}
                ref={field.ref}
              />
            </div>
            <small id={`${name}-help`} className="p-d-block">
              {helpText}
            </small>
            <small className="p-error p-d-block">{errorMessage}</small>
          </div>
        );
      }}
    />
  );
};

export default AdminInputMultiSelect;
