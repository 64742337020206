import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  citext: any;
  date: any;
  float8: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

/** columns and relationships of "address_attributes" */
export type AddressAttributes = {
  __typename?: 'AddressAttributes';
  addressEmail?: Maybe<Scalars['String']>;
  addressSubindustry?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  datasource?: Maybe<Datasource>;
  datasourceId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  personPhoneNumber?: Maybe<Scalars['String']>;
  personUniqueHash?: Maybe<Scalars['String']>;
  /** An object relationship */
  sigmaPrimeAddress?: Maybe<SigmaPrimeAddress>;
  sigmaPrimeAddressId?: Maybe<Scalars['uuid']>;
  uniqueSubindustry?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "address_attributes" */
export type AddressAttributesAggregate = {
  __typename?: 'AddressAttributesAggregate';
  aggregate?: Maybe<AddressAttributesAggregateFields>;
  nodes: Array<AddressAttributes>;
};

/** aggregate fields of "address_attributes" */
export type AddressAttributesAggregateFields = {
  __typename?: 'AddressAttributesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<AddressAttributesMaxFields>;
  min?: Maybe<AddressAttributesMinFields>;
};

/** aggregate fields of "address_attributes" */
export type AddressAttributesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AddressAttributesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "address_attributes". All fields are combined with a logical 'AND'. */
export type AddressAttributesBoolExp = {
  _and?: InputMaybe<Array<AddressAttributesBoolExp>>;
  _not?: InputMaybe<AddressAttributesBoolExp>;
  _or?: InputMaybe<Array<AddressAttributesBoolExp>>;
  addressEmail?: InputMaybe<StringComparisonExp>;
  addressSubindustry?: InputMaybe<StringComparisonExp>;
  comments?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  datasource?: InputMaybe<DatasourceBoolExp>;
  datasourceId?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  personPhoneNumber?: InputMaybe<StringComparisonExp>;
  personUniqueHash?: InputMaybe<StringComparisonExp>;
  sigmaPrimeAddress?: InputMaybe<SigmaPrimeAddressBoolExp>;
  sigmaPrimeAddressId?: InputMaybe<UuidComparisonExp>;
  uniqueSubindustry?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "address_attributes" */
export enum AddressAttributesConstraint {
  /** unique or primary key constraint on columns "id" */
  AddressAttributesPkey = 'address_attributes_pkey',
}

/** input type for inserting data into table "address_attributes" */
export type AddressAttributesInsertInput = {
  addressEmail?: InputMaybe<Scalars['String']>;
  addressSubindustry?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  datasource?: InputMaybe<DatasourceObjRelInsertInput>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  personPhoneNumber?: InputMaybe<Scalars['String']>;
  personUniqueHash?: InputMaybe<Scalars['String']>;
  sigmaPrimeAddress?: InputMaybe<SigmaPrimeAddressObjRelInsertInput>;
  sigmaPrimeAddressId?: InputMaybe<Scalars['uuid']>;
  uniqueSubindustry?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AddressAttributesMaxFields = {
  __typename?: 'AddressAttributesMaxFields';
  addressEmail?: Maybe<Scalars['String']>;
  addressSubindustry?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datasourceId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  personPhoneNumber?: Maybe<Scalars['String']>;
  personUniqueHash?: Maybe<Scalars['String']>;
  sigmaPrimeAddressId?: Maybe<Scalars['uuid']>;
  uniqueSubindustry?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type AddressAttributesMinFields = {
  __typename?: 'AddressAttributesMinFields';
  addressEmail?: Maybe<Scalars['String']>;
  addressSubindustry?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datasourceId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  personPhoneNumber?: Maybe<Scalars['String']>;
  personUniqueHash?: Maybe<Scalars['String']>;
  sigmaPrimeAddressId?: Maybe<Scalars['uuid']>;
  uniqueSubindustry?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "address_attributes" */
export type AddressAttributesMutationResponse = {
  __typename?: 'AddressAttributesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AddressAttributes>;
};

/** on_conflict condition type for table "address_attributes" */
export type AddressAttributesOnConflict = {
  constraint: AddressAttributesConstraint;
  updateColumns?: Array<AddressAttributesUpdateColumn>;
  where?: InputMaybe<AddressAttributesBoolExp>;
};

/** Ordering options when selecting data from "address_attributes". */
export type AddressAttributesOrderBy = {
  addressEmail?: InputMaybe<OrderBy>;
  addressSubindustry?: InputMaybe<OrderBy>;
  comments?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  datasource?: InputMaybe<DatasourceOrderBy>;
  datasourceId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  personPhoneNumber?: InputMaybe<OrderBy>;
  personUniqueHash?: InputMaybe<OrderBy>;
  sigmaPrimeAddress?: InputMaybe<SigmaPrimeAddressOrderBy>;
  sigmaPrimeAddressId?: InputMaybe<OrderBy>;
  uniqueSubindustry?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: address_attributes */
export type AddressAttributesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "address_attributes" */
export enum AddressAttributesSelectColumn {
  /** column name */
  AddressEmail = 'addressEmail',
  /** column name */
  AddressSubindustry = 'addressSubindustry',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatasourceId = 'datasourceId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  PersonPhoneNumber = 'personPhoneNumber',
  /** column name */
  PersonUniqueHash = 'personUniqueHash',
  /** column name */
  SigmaPrimeAddressId = 'sigmaPrimeAddressId',
  /** column name */
  UniqueSubindustry = 'uniqueSubindustry',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "address_attributes" */
export type AddressAttributesSetInput = {
  addressEmail?: InputMaybe<Scalars['String']>;
  addressSubindustry?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  personPhoneNumber?: InputMaybe<Scalars['String']>;
  personUniqueHash?: InputMaybe<Scalars['String']>;
  sigmaPrimeAddressId?: InputMaybe<Scalars['uuid']>;
  uniqueSubindustry?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "address_attributes" */
export type AddressAttributesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AddressAttributesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AddressAttributesStreamCursorValueInput = {
  addressEmail?: InputMaybe<Scalars['String']>;
  addressSubindustry?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  personPhoneNumber?: InputMaybe<Scalars['String']>;
  personUniqueHash?: InputMaybe<Scalars['String']>;
  sigmaPrimeAddressId?: InputMaybe<Scalars['uuid']>;
  uniqueSubindustry?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "address_attributes" */
export enum AddressAttributesUpdateColumn {
  /** column name */
  AddressEmail = 'addressEmail',
  /** column name */
  AddressSubindustry = 'addressSubindustry',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatasourceId = 'datasourceId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  PersonPhoneNumber = 'personPhoneNumber',
  /** column name */
  PersonUniqueHash = 'personUniqueHash',
  /** column name */
  SigmaPrimeAddressId = 'sigmaPrimeAddressId',
  /** column name */
  UniqueSubindustry = 'uniqueSubindustry',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type AddressAttributesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AddressAttributesSetInput>;
  /** filter the rows which have to be updated */
  where: AddressAttributesBoolExp;
};

/** columns and relationships of "appraisal" */
export type Appraisal = {
  __typename?: 'Appraisal';
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An array relationship */
  nfas: Array<Asset>;
  /** An aggregate relationship */
  nfasAggregate: AssetAggregate;
  /** An object relationship */
  organization: Organization;
  organizationId: Scalars['uuid'];
  /** An object relationship */
  smartContract: SmartContract;
  smartContractId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** columns and relationships of "appraisal" */
export type AppraisalNfasArgs = {
  distinctOn?: InputMaybe<Array<AssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetOrderBy>>;
  where?: InputMaybe<AssetBoolExp>;
};

/** columns and relationships of "appraisal" */
export type AppraisalNfasAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetOrderBy>>;
  where?: InputMaybe<AssetBoolExp>;
};

/** aggregated selection of "appraisal" */
export type AppraisalAggregate = {
  __typename?: 'AppraisalAggregate';
  aggregate?: Maybe<AppraisalAggregateFields>;
  nodes: Array<Appraisal>;
};

export type AppraisalAggregateBoolExp = {
  bool_and?: InputMaybe<AppraisalAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<AppraisalAggregateBoolExpBool_Or>;
  count?: InputMaybe<AppraisalAggregateBoolExpCount>;
};

/** aggregate fields of "appraisal" */
export type AppraisalAggregateFields = {
  __typename?: 'AppraisalAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<AppraisalMaxFields>;
  min?: Maybe<AppraisalMinFields>;
};

/** aggregate fields of "appraisal" */
export type AppraisalAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AppraisalSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "appraisal" */
export type AppraisalAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AppraisalMaxOrderBy>;
  min?: InputMaybe<AppraisalMinOrderBy>;
};

/** input type for inserting array relation for remote table "appraisal" */
export type AppraisalArrRelInsertInput = {
  data: Array<AppraisalInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AppraisalOnConflict>;
};

/** Boolean expression to filter rows from the table "appraisal". All fields are combined with a logical 'AND'. */
export type AppraisalBoolExp = {
  _and?: InputMaybe<Array<AppraisalBoolExp>>;
  _not?: InputMaybe<AppraisalBoolExp>;
  _or?: InputMaybe<Array<AppraisalBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nfas?: InputMaybe<AssetBoolExp>;
  nfasAggregate?: InputMaybe<AssetAggregateBoolExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  smartContract?: InputMaybe<SmartContractBoolExp>;
  smartContractId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "appraisal" */
export enum AppraisalConstraint {
  /** unique or primary key constraint on columns "id" */
  AppraisalPkey = 'appraisal_pkey',
  /** unique or primary key constraint on columns "id" */
  AppraisalXidKey = 'appraisal_xid_key',
}

/** input type for inserting data into table "appraisal" */
export type AppraisalInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nfas?: InputMaybe<AssetArrRelInsertInput>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  smartContract?: InputMaybe<SmartContractObjRelInsertInput>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AppraisalMaxFields = {
  __typename?: 'AppraisalMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "appraisal" */
export type AppraisalMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AppraisalMinFields = {
  __typename?: 'AppraisalMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "appraisal" */
export type AppraisalMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "appraisal" */
export type AppraisalMutationResponse = {
  __typename?: 'AppraisalMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Appraisal>;
};

/** input type for inserting object relation for remote table "appraisal" */
export type AppraisalObjRelInsertInput = {
  data: AppraisalInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AppraisalOnConflict>;
};

/** on_conflict condition type for table "appraisal" */
export type AppraisalOnConflict = {
  constraint: AppraisalConstraint;
  updateColumns?: Array<AppraisalUpdateColumn>;
  where?: InputMaybe<AppraisalBoolExp>;
};

/** Ordering options when selecting data from "appraisal". */
export type AppraisalOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nfasAggregate?: InputMaybe<AssetAggregateOrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  smartContract?: InputMaybe<SmartContractOrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: appraisal */
export type AppraisalPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "appraisal" */
export enum AppraisalSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** select "appraisalAggregateBoolExpBool_andArgumentsColumns" columns of table "appraisal" */
export enum AppraisalSelectColumnAppraisalAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "appraisalAggregateBoolExpBool_orArgumentsColumns" columns of table "appraisal" */
export enum AppraisalSelectColumnAppraisalAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "appraisal" */
export type AppraisalSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "appraisal" */
export type AppraisalStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AppraisalStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AppraisalStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "appraisal" */
export enum AppraisalUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type AppraisalUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AppraisalSetInput>;
  /** filter the rows which have to be updated */
  where: AppraisalBoolExp;
};

/** columns and relationships of "ask" */
export type Ask = {
  __typename?: 'Ask';
  askNumber: Scalars['float8'];
  /** An object relationship */
  asset: Asset;
  assetId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  currency: Currency;
  currencyId: Scalars['uuid'];
  description: Scalars['String'];
  endDate?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  exchange?: Maybe<Exchange>;
  exchangeId?: Maybe<Scalars['uuid']>;
  firmPrice?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  image?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  puts: Array<Put>;
  /** An aggregate relationship */
  putsAggregate: PutAggregate;
  startDate?: Maybe<Scalars['timestamptz']>;
  startingAsk: Scalars['float8'];
  totalAvailable?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  trade: Trade;
  tradeId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  wallet: Wallet;
  walletId: Scalars['uuid'];
};

/** columns and relationships of "ask" */
export type AskPutsArgs = {
  distinctOn?: InputMaybe<Array<PutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PutOrderBy>>;
  where?: InputMaybe<PutBoolExp>;
};

/** columns and relationships of "ask" */
export type AskPutsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PutOrderBy>>;
  where?: InputMaybe<PutBoolExp>;
};

/** aggregated selection of "ask" */
export type AskAggregate = {
  __typename?: 'AskAggregate';
  aggregate?: Maybe<AskAggregateFields>;
  nodes: Array<Ask>;
};

export type AskAggregateBoolExp = {
  avg?: InputMaybe<AskAggregateBoolExpAvg>;
  bool_and?: InputMaybe<AskAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<AskAggregateBoolExpBool_Or>;
  corr?: InputMaybe<AskAggregateBoolExpCorr>;
  count?: InputMaybe<AskAggregateBoolExpCount>;
  covar_samp?: InputMaybe<AskAggregateBoolExpCovar_Samp>;
  max?: InputMaybe<AskAggregateBoolExpMax>;
  min?: InputMaybe<AskAggregateBoolExpMin>;
  stddev_samp?: InputMaybe<AskAggregateBoolExpStddev_Samp>;
  sum?: InputMaybe<AskAggregateBoolExpSum>;
  var_samp?: InputMaybe<AskAggregateBoolExpVar_Samp>;
};

/** aggregate fields of "ask" */
export type AskAggregateFields = {
  __typename?: 'AskAggregateFields';
  avg?: Maybe<AskAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AskMaxFields>;
  min?: Maybe<AskMinFields>;
  stddev?: Maybe<AskStddevFields>;
  stddevPop?: Maybe<AskStddevPopFields>;
  stddevSamp?: Maybe<AskStddevSampFields>;
  sum?: Maybe<AskSumFields>;
  varPop?: Maybe<AskVarPopFields>;
  varSamp?: Maybe<AskVarSampFields>;
  variance?: Maybe<AskVarianceFields>;
};

/** aggregate fields of "ask" */
export type AskAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ask" */
export type AskAggregateOrderBy = {
  avg?: InputMaybe<AskAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AskMaxOrderBy>;
  min?: InputMaybe<AskMinOrderBy>;
  stddev?: InputMaybe<AskStddevOrderBy>;
  stddevPop?: InputMaybe<AskStddevPopOrderBy>;
  stddevSamp?: InputMaybe<AskStddevSampOrderBy>;
  sum?: InputMaybe<AskSumOrderBy>;
  varPop?: InputMaybe<AskVarPopOrderBy>;
  varSamp?: InputMaybe<AskVarSampOrderBy>;
  variance?: InputMaybe<AskVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "ask" */
export type AskArrRelInsertInput = {
  data: Array<AskInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AskOnConflict>;
};

/** aggregate avg on columns */
export type AskAvgFields = {
  __typename?: 'AskAvgFields';
  askNumber?: Maybe<Scalars['Float']>;
  startingAsk?: Maybe<Scalars['Float']>;
  totalAvailable?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ask" */
export type AskAvgOrderBy = {
  askNumber?: InputMaybe<OrderBy>;
  startingAsk?: InputMaybe<OrderBy>;
  totalAvailable?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "ask". All fields are combined with a logical 'AND'. */
export type AskBoolExp = {
  _and?: InputMaybe<Array<AskBoolExp>>;
  _not?: InputMaybe<AskBoolExp>;
  _or?: InputMaybe<Array<AskBoolExp>>;
  askNumber?: InputMaybe<Float8ComparisonExp>;
  asset?: InputMaybe<AssetBoolExp>;
  assetId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrencyBoolExp>;
  currencyId?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  endDate?: InputMaybe<TimestamptzComparisonExp>;
  exchange?: InputMaybe<ExchangeBoolExp>;
  exchangeId?: InputMaybe<UuidComparisonExp>;
  firmPrice?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  image?: InputMaybe<StringComparisonExp>;
  isActive?: InputMaybe<BooleanComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  product?: InputMaybe<ProductBoolExp>;
  productId?: InputMaybe<UuidComparisonExp>;
  puts?: InputMaybe<PutBoolExp>;
  putsAggregate?: InputMaybe<PutAggregateBoolExp>;
  startDate?: InputMaybe<TimestamptzComparisonExp>;
  startingAsk?: InputMaybe<Float8ComparisonExp>;
  totalAvailable?: InputMaybe<BigintComparisonExp>;
  trade?: InputMaybe<TradeBoolExp>;
  tradeId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  wallet?: InputMaybe<WalletBoolExp>;
  walletId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ask" */
export enum AskConstraint {
  /** unique or primary key constraint on columns "id" */
  AskPkey = 'ask_pkey',
  /** unique or primary key constraint on columns "id" */
  AskXidKey = 'ask_xid_key',
}

/** input type for incrementing numeric columns in table "ask" */
export type AskIncInput = {
  askNumber?: InputMaybe<Scalars['float8']>;
  startingAsk?: InputMaybe<Scalars['float8']>;
  totalAvailable?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "ask" */
export type AskInsertInput = {
  askNumber?: InputMaybe<Scalars['float8']>;
  asset?: InputMaybe<AssetObjRelInsertInput>;
  assetId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<CurrencyObjRelInsertInput>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamptz']>;
  exchange?: InputMaybe<ExchangeObjRelInsertInput>;
  exchangeId?: InputMaybe<Scalars['uuid']>;
  firmPrice?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductObjRelInsertInput>;
  productId?: InputMaybe<Scalars['uuid']>;
  puts?: InputMaybe<PutArrRelInsertInput>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  startingAsk?: InputMaybe<Scalars['float8']>;
  totalAvailable?: InputMaybe<Scalars['bigint']>;
  trade?: InputMaybe<TradeObjRelInsertInput>;
  tradeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  wallet?: InputMaybe<WalletObjRelInsertInput>;
  walletId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AskMaxFields = {
  __typename?: 'AskMaxFields';
  askNumber?: Maybe<Scalars['float8']>;
  assetId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  exchangeId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  startingAsk?: Maybe<Scalars['float8']>;
  totalAvailable?: Maybe<Scalars['bigint']>;
  tradeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  walletId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ask" */
export type AskMaxOrderBy = {
  askNumber?: InputMaybe<OrderBy>;
  assetId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  exchangeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  startingAsk?: InputMaybe<OrderBy>;
  totalAvailable?: InputMaybe<OrderBy>;
  tradeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  walletId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AskMinFields = {
  __typename?: 'AskMinFields';
  askNumber?: Maybe<Scalars['float8']>;
  assetId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  exchangeId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  startingAsk?: Maybe<Scalars['float8']>;
  totalAvailable?: Maybe<Scalars['bigint']>;
  tradeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  walletId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ask" */
export type AskMinOrderBy = {
  askNumber?: InputMaybe<OrderBy>;
  assetId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  exchangeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  startingAsk?: InputMaybe<OrderBy>;
  totalAvailable?: InputMaybe<OrderBy>;
  tradeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  walletId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "ask" */
export type AskMutationResponse = {
  __typename?: 'AskMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ask>;
};

/** input type for inserting object relation for remote table "ask" */
export type AskObjRelInsertInput = {
  data: AskInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AskOnConflict>;
};

/** on_conflict condition type for table "ask" */
export type AskOnConflict = {
  constraint: AskConstraint;
  updateColumns?: Array<AskUpdateColumn>;
  where?: InputMaybe<AskBoolExp>;
};

/** Ordering options when selecting data from "ask". */
export type AskOrderBy = {
  askNumber?: InputMaybe<OrderBy>;
  asset?: InputMaybe<AssetOrderBy>;
  assetId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrencyOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  exchange?: InputMaybe<ExchangeOrderBy>;
  exchangeId?: InputMaybe<OrderBy>;
  firmPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  isActive?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  product?: InputMaybe<ProductOrderBy>;
  productId?: InputMaybe<OrderBy>;
  putsAggregate?: InputMaybe<PutAggregateOrderBy>;
  startDate?: InputMaybe<OrderBy>;
  startingAsk?: InputMaybe<OrderBy>;
  totalAvailable?: InputMaybe<OrderBy>;
  trade?: InputMaybe<TradeOrderBy>;
  tradeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  wallet?: InputMaybe<WalletOrderBy>;
  walletId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ask */
export type AskPkColumnsInput = {
  id: Scalars['uuid'];
};

/** columns and relationships of "ask_put_transaction" */
export type AskPutTransaction = {
  __typename?: 'AskPutTransaction';
  /** An object relationship */
  ask?: Maybe<Ask>;
  askId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  put?: Maybe<Put>;
  putId: Scalars['uuid'];
  /** An object relationship */
  transaction?: Maybe<Transaction>;
  transactionId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "ask_put_transaction" */
export type AskPutTransactionAggregate = {
  __typename?: 'AskPutTransactionAggregate';
  aggregate?: Maybe<AskPutTransactionAggregateFields>;
  nodes: Array<AskPutTransaction>;
};

/** aggregate fields of "ask_put_transaction" */
export type AskPutTransactionAggregateFields = {
  __typename?: 'AskPutTransactionAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<AskPutTransactionMaxFields>;
  min?: Maybe<AskPutTransactionMinFields>;
};

/** aggregate fields of "ask_put_transaction" */
export type AskPutTransactionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AskPutTransactionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ask_put_transaction". All fields are combined with a logical 'AND'. */
export type AskPutTransactionBoolExp = {
  _and?: InputMaybe<Array<AskPutTransactionBoolExp>>;
  _not?: InputMaybe<AskPutTransactionBoolExp>;
  _or?: InputMaybe<Array<AskPutTransactionBoolExp>>;
  ask?: InputMaybe<AskBoolExp>;
  askId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isActive?: InputMaybe<BooleanComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  put?: InputMaybe<PutBoolExp>;
  putId?: InputMaybe<UuidComparisonExp>;
  transaction?: InputMaybe<TransactionBoolExp>;
  transactionId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "ask_put_transaction" */
export enum AskPutTransactionConstraint {
  /** unique or primary key constraint on columns "id" */
  AskPutTransactionPkey = 'ask_put_transaction_pkey',
}

/** input type for inserting data into table "ask_put_transaction" */
export type AskPutTransactionInsertInput = {
  ask?: InputMaybe<AskObjRelInsertInput>;
  askId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  put?: InputMaybe<PutObjRelInsertInput>;
  putId?: InputMaybe<Scalars['uuid']>;
  transaction?: InputMaybe<TransactionObjRelInsertInput>;
  transactionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AskPutTransactionMaxFields = {
  __typename?: 'AskPutTransactionMaxFields';
  askId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  putId?: Maybe<Scalars['uuid']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type AskPutTransactionMinFields = {
  __typename?: 'AskPutTransactionMinFields';
  askId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  putId?: Maybe<Scalars['uuid']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "ask_put_transaction" */
export type AskPutTransactionMutationResponse = {
  __typename?: 'AskPutTransactionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AskPutTransaction>;
};

/** on_conflict condition type for table "ask_put_transaction" */
export type AskPutTransactionOnConflict = {
  constraint: AskPutTransactionConstraint;
  updateColumns?: Array<AskPutTransactionUpdateColumn>;
  where?: InputMaybe<AskPutTransactionBoolExp>;
};

/** Ordering options when selecting data from "ask_put_transaction". */
export type AskPutTransactionOrderBy = {
  ask?: InputMaybe<AskOrderBy>;
  askId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isActive?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  put?: InputMaybe<PutOrderBy>;
  putId?: InputMaybe<OrderBy>;
  transaction?: InputMaybe<TransactionOrderBy>;
  transactionId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ask_put_transaction */
export type AskPutTransactionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "ask_put_transaction" */
export enum AskPutTransactionSelectColumn {
  /** column name */
  AskId = 'askId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  PutId = 'putId',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "ask_put_transaction" */
export type AskPutTransactionSetInput = {
  askId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  putId?: InputMaybe<Scalars['uuid']>;
  transactionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ask_put_transaction" */
export type AskPutTransactionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AskPutTransactionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AskPutTransactionStreamCursorValueInput = {
  askId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  putId?: InputMaybe<Scalars['uuid']>;
  transactionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ask_put_transaction" */
export enum AskPutTransactionUpdateColumn {
  /** column name */
  AskId = 'askId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  PutId = 'putId',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type AskPutTransactionUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AskPutTransactionSetInput>;
  /** filter the rows which have to be updated */
  where: AskPutTransactionBoolExp;
};

/** select columns of table "ask" */
export enum AskSelectColumn {
  /** column name */
  AskNumber = 'askNumber',
  /** column name */
  AssetId = 'assetId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  ExchangeId = 'exchangeId',
  /** column name */
  FirmPrice = 'firmPrice',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  ProductId = 'productId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  StartingAsk = 'startingAsk',
  /** column name */
  TotalAvailable = 'totalAvailable',
  /** column name */
  TradeId = 'tradeId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WalletId = 'walletId',
}

/** select "askAggregateBoolExpAvgArgumentsColumns" columns of table "ask" */
export enum AskSelectColumnAskAggregateBoolExpAvgArgumentsColumns {
  /** column name */
  AskNumber = 'askNumber',
  /** column name */
  StartingAsk = 'startingAsk',
}

/** select "askAggregateBoolExpBool_andArgumentsColumns" columns of table "ask" */
export enum AskSelectColumnAskAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  FirmPrice = 'firmPrice',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "askAggregateBoolExpBool_orArgumentsColumns" columns of table "ask" */
export enum AskSelectColumnAskAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  FirmPrice = 'firmPrice',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "askAggregateBoolExpCorrArgumentsColumns" columns of table "ask" */
export enum AskSelectColumnAskAggregateBoolExpCorrArgumentsColumns {
  /** column name */
  AskNumber = 'askNumber',
  /** column name */
  StartingAsk = 'startingAsk',
}

/** select "askAggregateBoolExpCovar_sampArgumentsColumns" columns of table "ask" */
export enum AskSelectColumnAskAggregateBoolExpCovar_SampArgumentsColumns {
  /** column name */
  AskNumber = 'askNumber',
  /** column name */
  StartingAsk = 'startingAsk',
}

/** select "askAggregateBoolExpMaxArgumentsColumns" columns of table "ask" */
export enum AskSelectColumnAskAggregateBoolExpMaxArgumentsColumns {
  /** column name */
  AskNumber = 'askNumber',
  /** column name */
  StartingAsk = 'startingAsk',
}

/** select "askAggregateBoolExpMinArgumentsColumns" columns of table "ask" */
export enum AskSelectColumnAskAggregateBoolExpMinArgumentsColumns {
  /** column name */
  AskNumber = 'askNumber',
  /** column name */
  StartingAsk = 'startingAsk',
}

/** select "askAggregateBoolExpStddev_sampArgumentsColumns" columns of table "ask" */
export enum AskSelectColumnAskAggregateBoolExpStddev_SampArgumentsColumns {
  /** column name */
  AskNumber = 'askNumber',
  /** column name */
  StartingAsk = 'startingAsk',
}

/** select "askAggregateBoolExpSumArgumentsColumns" columns of table "ask" */
export enum AskSelectColumnAskAggregateBoolExpSumArgumentsColumns {
  /** column name */
  AskNumber = 'askNumber',
  /** column name */
  StartingAsk = 'startingAsk',
}

/** select "askAggregateBoolExpVar_sampArgumentsColumns" columns of table "ask" */
export enum AskSelectColumnAskAggregateBoolExpVar_SampArgumentsColumns {
  /** column name */
  AskNumber = 'askNumber',
  /** column name */
  StartingAsk = 'startingAsk',
}

/** input type for updating data in table "ask" */
export type AskSetInput = {
  askNumber?: InputMaybe<Scalars['float8']>;
  assetId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamptz']>;
  exchangeId?: InputMaybe<Scalars['uuid']>;
  firmPrice?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['uuid']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  startingAsk?: InputMaybe<Scalars['float8']>;
  totalAvailable?: InputMaybe<Scalars['bigint']>;
  tradeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  walletId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type AskStddevFields = {
  __typename?: 'AskStddevFields';
  askNumber?: Maybe<Scalars['Float']>;
  startingAsk?: Maybe<Scalars['Float']>;
  totalAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ask" */
export type AskStddevOrderBy = {
  askNumber?: InputMaybe<OrderBy>;
  startingAsk?: InputMaybe<OrderBy>;
  totalAvailable?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type AskStddevPopFields = {
  __typename?: 'AskStddevPopFields';
  askNumber?: Maybe<Scalars['Float']>;
  startingAsk?: Maybe<Scalars['Float']>;
  totalAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "ask" */
export type AskStddevPopOrderBy = {
  askNumber?: InputMaybe<OrderBy>;
  startingAsk?: InputMaybe<OrderBy>;
  totalAvailable?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type AskStddevSampFields = {
  __typename?: 'AskStddevSampFields';
  askNumber?: Maybe<Scalars['Float']>;
  startingAsk?: Maybe<Scalars['Float']>;
  totalAvailable?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "ask" */
export type AskStddevSampOrderBy = {
  askNumber?: InputMaybe<OrderBy>;
  startingAsk?: InputMaybe<OrderBy>;
  totalAvailable?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "ask" */
export type AskStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AskStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AskStreamCursorValueInput = {
  askNumber?: InputMaybe<Scalars['float8']>;
  assetId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamptz']>;
  exchangeId?: InputMaybe<Scalars['uuid']>;
  firmPrice?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['uuid']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  startingAsk?: InputMaybe<Scalars['float8']>;
  totalAvailable?: InputMaybe<Scalars['bigint']>;
  tradeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  walletId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type AskSumFields = {
  __typename?: 'AskSumFields';
  askNumber?: Maybe<Scalars['float8']>;
  startingAsk?: Maybe<Scalars['float8']>;
  totalAvailable?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "ask" */
export type AskSumOrderBy = {
  askNumber?: InputMaybe<OrderBy>;
  startingAsk?: InputMaybe<OrderBy>;
  totalAvailable?: InputMaybe<OrderBy>;
};

/** update columns of table "ask" */
export enum AskUpdateColumn {
  /** column name */
  AskNumber = 'askNumber',
  /** column name */
  AssetId = 'assetId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  ExchangeId = 'exchangeId',
  /** column name */
  FirmPrice = 'firmPrice',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  ProductId = 'productId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  StartingAsk = 'startingAsk',
  /** column name */
  TotalAvailable = 'totalAvailable',
  /** column name */
  TradeId = 'tradeId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WalletId = 'walletId',
}

export type AskUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AskIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AskSetInput>;
  /** filter the rows which have to be updated */
  where: AskBoolExp;
};

/** aggregate varPop on columns */
export type AskVarPopFields = {
  __typename?: 'AskVarPopFields';
  askNumber?: Maybe<Scalars['Float']>;
  startingAsk?: Maybe<Scalars['Float']>;
  totalAvailable?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "ask" */
export type AskVarPopOrderBy = {
  askNumber?: InputMaybe<OrderBy>;
  startingAsk?: InputMaybe<OrderBy>;
  totalAvailable?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type AskVarSampFields = {
  __typename?: 'AskVarSampFields';
  askNumber?: Maybe<Scalars['Float']>;
  startingAsk?: Maybe<Scalars['Float']>;
  totalAvailable?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "ask" */
export type AskVarSampOrderBy = {
  askNumber?: InputMaybe<OrderBy>;
  startingAsk?: InputMaybe<OrderBy>;
  totalAvailable?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type AskVarianceFields = {
  __typename?: 'AskVarianceFields';
  askNumber?: Maybe<Scalars['Float']>;
  startingAsk?: Maybe<Scalars['Float']>;
  totalAvailable?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ask" */
export type AskVarianceOrderBy = {
  askNumber?: InputMaybe<OrderBy>;
  startingAsk?: InputMaybe<OrderBy>;
  totalAvailable?: InputMaybe<OrderBy>;
};

/** columns and relationships of "asset" */
export type Asset = {
  __typename?: 'Asset';
  /** An object relationship */
  account?: Maybe<AssetAccount>;
  /** An object relationship */
  appraisal: Appraisal;
  appraisalId: Scalars['uuid'];
  /** An array relationship */
  asks: Array<Ask>;
  /** An aggregate relationship */
  asksAggregate: AskAggregate;
  assetAccountId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  assetKind: Array<AssetKind>;
  /** An aggregate relationship */
  assetKindAggregate: AssetKindAggregate;
  assetKindId: Scalars['uuid'];
  authorized?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  country: Country;
  countryCode: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  datasource?: Maybe<Datasource>;
  datasourceId?: Maybe<Scalars['uuid']>;
  description: Scalars['String'];
  dimensions: Scalars['String'];
  embedCode?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  images?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  issued?: Maybe<Scalars['String']>;
  /** An object relationship */
  kind: AssetKind;
  /** An object relationship */
  material?: Maybe<Material>;
  materialId?: Maybe<Scalars['uuid']>;
  materials: Scalars['String'];
  name: Scalars['String'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['uuid']>;
  parentCategory?: Maybe<Scalars['String']>;
  /** An object relationship */
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['uuid']>;
  sourceUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  sourceUser?: Maybe<User>;
  sourceUserId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  subindustry: Subindustry;
  subindustryId: Scalars['uuid'];
  uom: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** columns and relationships of "asset" */
export type AssetAsksArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

/** columns and relationships of "asset" */
export type AssetAsksAggregateArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

/** columns and relationships of "asset" */
export type AssetAssetKindArgs = {
  distinctOn?: InputMaybe<Array<AssetKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetKindOrderBy>>;
  where?: InputMaybe<AssetKindBoolExp>;
};

/** columns and relationships of "asset" */
export type AssetAssetKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssetKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetKindOrderBy>>;
  where?: InputMaybe<AssetKindBoolExp>;
};

/** columns and relationships of "asset_account" */
export type AssetAccount = {
  __typename?: 'AssetAccount';
  accountKind?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  achNumber?: Maybe<Scalars['String']>;
  availableAt: Scalars['timestamptz'];
  balanceAmount?: Maybe<Scalars['float8']>;
  ccNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  cvv: Scalars['String'];
  expirationDate: Scalars['timestamptz'];
  ibanNumber: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  issueDate: Scalars['timestamptz'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['uuid']>;
  routingNumber: Scalars['String'];
  securityCode: Scalars['String'];
  swiftCode: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "asset_account" */
export type AssetAccountAggregate = {
  __typename?: 'AssetAccountAggregate';
  aggregate?: Maybe<AssetAccountAggregateFields>;
  nodes: Array<AssetAccount>;
};

/** aggregate fields of "asset_account" */
export type AssetAccountAggregateFields = {
  __typename?: 'AssetAccountAggregateFields';
  avg?: Maybe<AssetAccountAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AssetAccountMaxFields>;
  min?: Maybe<AssetAccountMinFields>;
  stddev?: Maybe<AssetAccountStddevFields>;
  stddevPop?: Maybe<AssetAccountStddevPopFields>;
  stddevSamp?: Maybe<AssetAccountStddevSampFields>;
  sum?: Maybe<AssetAccountSumFields>;
  varPop?: Maybe<AssetAccountVarPopFields>;
  varSamp?: Maybe<AssetAccountVarSampFields>;
  variance?: Maybe<AssetAccountVarianceFields>;
};

/** aggregate fields of "asset_account" */
export type AssetAccountAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssetAccountSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AssetAccountAvgFields = {
  __typename?: 'AssetAccountAvgFields';
  accountNumber?: Maybe<Scalars['Float']>;
  balanceAmount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "asset_account". All fields are combined with a logical 'AND'. */
export type AssetAccountBoolExp = {
  _and?: InputMaybe<Array<AssetAccountBoolExp>>;
  _not?: InputMaybe<AssetAccountBoolExp>;
  _or?: InputMaybe<Array<AssetAccountBoolExp>>;
  accountKind?: InputMaybe<StringComparisonExp>;
  accountName?: InputMaybe<StringComparisonExp>;
  accountNumber?: InputMaybe<IntComparisonExp>;
  achNumber?: InputMaybe<StringComparisonExp>;
  availableAt?: InputMaybe<TimestamptzComparisonExp>;
  balanceAmount?: InputMaybe<Float8ComparisonExp>;
  ccNumber?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  cvv?: InputMaybe<StringComparisonExp>;
  expirationDate?: InputMaybe<TimestamptzComparisonExp>;
  ibanNumber?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  issueDate?: InputMaybe<TimestamptzComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  routingNumber?: InputMaybe<StringComparisonExp>;
  securityCode?: InputMaybe<StringComparisonExp>;
  swiftCode?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "asset_account" */
export enum AssetAccountConstraint {
  /** unique or primary key constraint on columns "id" */
  AssetAccountPkey = 'asset_account_pkey',
}

/** input type for incrementing numeric columns in table "asset_account" */
export type AssetAccountIncInput = {
  accountNumber?: InputMaybe<Scalars['Int']>;
  balanceAmount?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "asset_account" */
export type AssetAccountInsertInput = {
  accountKind?: InputMaybe<Scalars['String']>;
  accountName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['Int']>;
  achNumber?: InputMaybe<Scalars['String']>;
  availableAt?: InputMaybe<Scalars['timestamptz']>;
  balanceAmount?: InputMaybe<Scalars['float8']>;
  ccNumber?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  cvv?: InputMaybe<Scalars['String']>;
  expirationDate?: InputMaybe<Scalars['timestamptz']>;
  ibanNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  issueDate?: InputMaybe<Scalars['timestamptz']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  routingNumber?: InputMaybe<Scalars['String']>;
  securityCode?: InputMaybe<Scalars['String']>;
  swiftCode?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AssetAccountMaxFields = {
  __typename?: 'AssetAccountMaxFields';
  accountKind?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  achNumber?: Maybe<Scalars['String']>;
  availableAt?: Maybe<Scalars['timestamptz']>;
  balanceAmount?: Maybe<Scalars['float8']>;
  ccNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  cvv?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['timestamptz']>;
  ibanNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  issueDate?: Maybe<Scalars['timestamptz']>;
  organizationId?: Maybe<Scalars['uuid']>;
  routingNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type AssetAccountMinFields = {
  __typename?: 'AssetAccountMinFields';
  accountKind?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  achNumber?: Maybe<Scalars['String']>;
  availableAt?: Maybe<Scalars['timestamptz']>;
  balanceAmount?: Maybe<Scalars['float8']>;
  ccNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  cvv?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['timestamptz']>;
  ibanNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  issueDate?: Maybe<Scalars['timestamptz']>;
  organizationId?: Maybe<Scalars['uuid']>;
  routingNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "asset_account" */
export type AssetAccountMutationResponse = {
  __typename?: 'AssetAccountMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AssetAccount>;
};

/** input type for inserting object relation for remote table "asset_account" */
export type AssetAccountObjRelInsertInput = {
  data: AssetAccountInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AssetAccountOnConflict>;
};

/** on_conflict condition type for table "asset_account" */
export type AssetAccountOnConflict = {
  constraint: AssetAccountConstraint;
  updateColumns?: Array<AssetAccountUpdateColumn>;
  where?: InputMaybe<AssetAccountBoolExp>;
};

/** Ordering options when selecting data from "asset_account". */
export type AssetAccountOrderBy = {
  accountKind?: InputMaybe<OrderBy>;
  accountName?: InputMaybe<OrderBy>;
  accountNumber?: InputMaybe<OrderBy>;
  achNumber?: InputMaybe<OrderBy>;
  availableAt?: InputMaybe<OrderBy>;
  balanceAmount?: InputMaybe<OrderBy>;
  ccNumber?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  cvv?: InputMaybe<OrderBy>;
  expirationDate?: InputMaybe<OrderBy>;
  ibanNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  issueDate?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  routingNumber?: InputMaybe<OrderBy>;
  securityCode?: InputMaybe<OrderBy>;
  swiftCode?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: asset_account */
export type AssetAccountPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "asset_account" */
export enum AssetAccountSelectColumn {
  /** column name */
  AccountKind = 'accountKind',
  /** column name */
  AccountName = 'accountName',
  /** column name */
  AccountNumber = 'accountNumber',
  /** column name */
  AchNumber = 'achNumber',
  /** column name */
  AvailableAt = 'availableAt',
  /** column name */
  BalanceAmount = 'balanceAmount',
  /** column name */
  CcNumber = 'ccNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Cvv = 'cvv',
  /** column name */
  ExpirationDate = 'expirationDate',
  /** column name */
  IbanNumber = 'ibanNumber',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IssueDate = 'issueDate',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  RoutingNumber = 'routingNumber',
  /** column name */
  SecurityCode = 'securityCode',
  /** column name */
  SwiftCode = 'swiftCode',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "asset_account" */
export type AssetAccountSetInput = {
  accountKind?: InputMaybe<Scalars['String']>;
  accountName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['Int']>;
  achNumber?: InputMaybe<Scalars['String']>;
  availableAt?: InputMaybe<Scalars['timestamptz']>;
  balanceAmount?: InputMaybe<Scalars['float8']>;
  ccNumber?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  cvv?: InputMaybe<Scalars['String']>;
  expirationDate?: InputMaybe<Scalars['timestamptz']>;
  ibanNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  issueDate?: InputMaybe<Scalars['timestamptz']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  routingNumber?: InputMaybe<Scalars['String']>;
  securityCode?: InputMaybe<Scalars['String']>;
  swiftCode?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type AssetAccountStddevFields = {
  __typename?: 'AssetAccountStddevFields';
  accountNumber?: Maybe<Scalars['Float']>;
  balanceAmount?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type AssetAccountStddevPopFields = {
  __typename?: 'AssetAccountStddevPopFields';
  accountNumber?: Maybe<Scalars['Float']>;
  balanceAmount?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type AssetAccountStddevSampFields = {
  __typename?: 'AssetAccountStddevSampFields';
  accountNumber?: Maybe<Scalars['Float']>;
  balanceAmount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "asset_account" */
export type AssetAccountStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AssetAccountStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssetAccountStreamCursorValueInput = {
  accountKind?: InputMaybe<Scalars['String']>;
  accountName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['Int']>;
  achNumber?: InputMaybe<Scalars['String']>;
  availableAt?: InputMaybe<Scalars['timestamptz']>;
  balanceAmount?: InputMaybe<Scalars['float8']>;
  ccNumber?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  cvv?: InputMaybe<Scalars['String']>;
  expirationDate?: InputMaybe<Scalars['timestamptz']>;
  ibanNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  issueDate?: InputMaybe<Scalars['timestamptz']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  routingNumber?: InputMaybe<Scalars['String']>;
  securityCode?: InputMaybe<Scalars['String']>;
  swiftCode?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type AssetAccountSumFields = {
  __typename?: 'AssetAccountSumFields';
  accountNumber?: Maybe<Scalars['Int']>;
  balanceAmount?: Maybe<Scalars['float8']>;
};

/** update columns of table "asset_account" */
export enum AssetAccountUpdateColumn {
  /** column name */
  AccountKind = 'accountKind',
  /** column name */
  AccountName = 'accountName',
  /** column name */
  AccountNumber = 'accountNumber',
  /** column name */
  AchNumber = 'achNumber',
  /** column name */
  AvailableAt = 'availableAt',
  /** column name */
  BalanceAmount = 'balanceAmount',
  /** column name */
  CcNumber = 'ccNumber',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Cvv = 'cvv',
  /** column name */
  ExpirationDate = 'expirationDate',
  /** column name */
  IbanNumber = 'ibanNumber',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IssueDate = 'issueDate',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  RoutingNumber = 'routingNumber',
  /** column name */
  SecurityCode = 'securityCode',
  /** column name */
  SwiftCode = 'swiftCode',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type AssetAccountUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AssetAccountIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssetAccountSetInput>;
  /** filter the rows which have to be updated */
  where: AssetAccountBoolExp;
};

/** aggregate varPop on columns */
export type AssetAccountVarPopFields = {
  __typename?: 'AssetAccountVarPopFields';
  accountNumber?: Maybe<Scalars['Float']>;
  balanceAmount?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type AssetAccountVarSampFields = {
  __typename?: 'AssetAccountVarSampFields';
  accountNumber?: Maybe<Scalars['Float']>;
  balanceAmount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AssetAccountVarianceFields = {
  __typename?: 'AssetAccountVarianceFields';
  accountNumber?: Maybe<Scalars['Float']>;
  balanceAmount?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "asset" */
export type AssetAggregate = {
  __typename?: 'AssetAggregate';
  aggregate?: Maybe<AssetAggregateFields>;
  nodes: Array<Asset>;
};

export type AssetAggregateBoolExp = {
  bool_and?: InputMaybe<AssetAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<AssetAggregateBoolExpBool_Or>;
  count?: InputMaybe<AssetAggregateBoolExpCount>;
};

/** aggregate fields of "asset" */
export type AssetAggregateFields = {
  __typename?: 'AssetAggregateFields';
  avg?: Maybe<AssetAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AssetMaxFields>;
  min?: Maybe<AssetMinFields>;
  stddev?: Maybe<AssetStddevFields>;
  stddevPop?: Maybe<AssetStddevPopFields>;
  stddevSamp?: Maybe<AssetStddevSampFields>;
  sum?: Maybe<AssetSumFields>;
  varPop?: Maybe<AssetVarPopFields>;
  varSamp?: Maybe<AssetVarSampFields>;
  variance?: Maybe<AssetVarianceFields>;
};

/** aggregate fields of "asset" */
export type AssetAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "asset" */
export type AssetAggregateOrderBy = {
  avg?: InputMaybe<AssetAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssetMaxOrderBy>;
  min?: InputMaybe<AssetMinOrderBy>;
  stddev?: InputMaybe<AssetStddevOrderBy>;
  stddevPop?: InputMaybe<AssetStddevPopOrderBy>;
  stddevSamp?: InputMaybe<AssetStddevSampOrderBy>;
  sum?: InputMaybe<AssetSumOrderBy>;
  varPop?: InputMaybe<AssetVarPopOrderBy>;
  varSamp?: InputMaybe<AssetVarSampOrderBy>;
  variance?: InputMaybe<AssetVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "asset" */
export type AssetArrRelInsertInput = {
  data: Array<AssetInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AssetOnConflict>;
};

/** aggregate avg on columns */
export type AssetAvgFields = {
  __typename?: 'AssetAvgFields';
  classification?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "asset" */
export type AssetAvgOrderBy = {
  classification?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "asset". All fields are combined with a logical 'AND'. */
export type AssetBoolExp = {
  _and?: InputMaybe<Array<AssetBoolExp>>;
  _not?: InputMaybe<AssetBoolExp>;
  _or?: InputMaybe<Array<AssetBoolExp>>;
  account?: InputMaybe<AssetAccountBoolExp>;
  appraisal?: InputMaybe<AppraisalBoolExp>;
  appraisalId?: InputMaybe<UuidComparisonExp>;
  asks?: InputMaybe<AskBoolExp>;
  asksAggregate?: InputMaybe<AskAggregateBoolExp>;
  assetAccountId?: InputMaybe<UuidComparisonExp>;
  assetKind?: InputMaybe<AssetKindBoolExp>;
  assetKindAggregate?: InputMaybe<AssetKindAggregateBoolExp>;
  assetKindId?: InputMaybe<UuidComparisonExp>;
  authorized?: InputMaybe<StringComparisonExp>;
  category?: InputMaybe<StringComparisonExp>;
  classification?: InputMaybe<NumericComparisonExp>;
  country?: InputMaybe<CountryBoolExp>;
  countryCode?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  datasource?: InputMaybe<DatasourceBoolExp>;
  datasourceId?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  dimensions?: InputMaybe<StringComparisonExp>;
  embedCode?: InputMaybe<StringComparisonExp>;
  format?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  images?: InputMaybe<StringComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  issued?: InputMaybe<StringComparisonExp>;
  kind?: InputMaybe<AssetKindBoolExp>;
  material?: InputMaybe<MaterialBoolExp>;
  materialId?: InputMaybe<UuidComparisonExp>;
  materials?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  parentCategory?: InputMaybe<StringComparisonExp>;
  product?: InputMaybe<ProductBoolExp>;
  productId?: InputMaybe<UuidComparisonExp>;
  sourceUrl?: InputMaybe<StringComparisonExp>;
  sourceUser?: InputMaybe<UserBoolExp>;
  sourceUserId?: InputMaybe<UuidComparisonExp>;
  subindustry?: InputMaybe<SubindustryBoolExp>;
  subindustryId?: InputMaybe<UuidComparisonExp>;
  uom?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "asset" */
export enum AssetConstraint {
  /** unique or primary key constraint on columns "id" */
  NfaPkey = 'nfa_pkey',
  /** unique or primary key constraint on columns "id" */
  NfaXidKey = 'nfa_xid_key',
}

/** input type for incrementing numeric columns in table "asset" */
export type AssetIncInput = {
  classification?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "asset" */
export type AssetInsertInput = {
  account?: InputMaybe<AssetAccountObjRelInsertInput>;
  appraisal?: InputMaybe<AppraisalObjRelInsertInput>;
  appraisalId?: InputMaybe<Scalars['uuid']>;
  asks?: InputMaybe<AskArrRelInsertInput>;
  assetAccountId?: InputMaybe<Scalars['uuid']>;
  assetKind?: InputMaybe<AssetKindArrRelInsertInput>;
  assetKindId?: InputMaybe<Scalars['uuid']>;
  authorized?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  classification?: InputMaybe<Scalars['numeric']>;
  country?: InputMaybe<CountryObjRelInsertInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  datasource?: InputMaybe<DatasourceObjRelInsertInput>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<Scalars['String']>;
  embedCode?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  issued?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<AssetKindObjRelInsertInput>;
  material?: InputMaybe<MaterialObjRelInsertInput>;
  materialId?: InputMaybe<Scalars['uuid']>;
  materials?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  parentCategory?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductObjRelInsertInput>;
  productId?: InputMaybe<Scalars['uuid']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  sourceUser?: InputMaybe<UserObjRelInsertInput>;
  sourceUserId?: InputMaybe<Scalars['uuid']>;
  subindustry?: InputMaybe<SubindustryObjRelInsertInput>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  uom?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "asset_kind" */
export type AssetKind = {
  __typename?: 'AssetKind';
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  images?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An array relationship */
  nfas: Array<Asset>;
  /** An aggregate relationship */
  nfasAggregate: AssetAggregate;
  /** An object relationship */
  subindustry: Subindustry;
  subindustryId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "asset_kind" */
export type AssetKindNfasArgs = {
  distinctOn?: InputMaybe<Array<AssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetOrderBy>>;
  where?: InputMaybe<AssetBoolExp>;
};

/** columns and relationships of "asset_kind" */
export type AssetKindNfasAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetOrderBy>>;
  where?: InputMaybe<AssetBoolExp>;
};

/** aggregated selection of "asset_kind" */
export type AssetKindAggregate = {
  __typename?: 'AssetKindAggregate';
  aggregate?: Maybe<AssetKindAggregateFields>;
  nodes: Array<AssetKind>;
};

export type AssetKindAggregateBoolExp = {
  bool_and?: InputMaybe<AssetKindAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<AssetKindAggregateBoolExpBool_Or>;
  count?: InputMaybe<AssetKindAggregateBoolExpCount>;
};

/** aggregate fields of "asset_kind" */
export type AssetKindAggregateFields = {
  __typename?: 'AssetKindAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<AssetKindMaxFields>;
  min?: Maybe<AssetKindMinFields>;
};

/** aggregate fields of "asset_kind" */
export type AssetKindAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssetKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "asset_kind" */
export type AssetKindAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssetKindMaxOrderBy>;
  min?: InputMaybe<AssetKindMinOrderBy>;
};

/** input type for inserting array relation for remote table "asset_kind" */
export type AssetKindArrRelInsertInput = {
  data: Array<AssetKindInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AssetKindOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_kind". All fields are combined with a logical 'AND'. */
export type AssetKindBoolExp = {
  _and?: InputMaybe<Array<AssetKindBoolExp>>;
  _not?: InputMaybe<AssetKindBoolExp>;
  _or?: InputMaybe<Array<AssetKindBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  images?: InputMaybe<StringComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nfas?: InputMaybe<AssetBoolExp>;
  nfasAggregate?: InputMaybe<AssetAggregateBoolExp>;
  subindustry?: InputMaybe<SubindustryBoolExp>;
  subindustryId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "asset_kind" */
export enum AssetKindConstraint {
  /** unique or primary key constraint on columns "id" */
  NfaKindPkey = 'nfa_kind_pkey',
  /** unique or primary key constraint on columns "id" */
  NfaKindXidKey = 'nfa_kind_xid_key',
}

/** input type for inserting data into table "asset_kind" */
export type AssetKindInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nfas?: InputMaybe<AssetArrRelInsertInput>;
  subindustry?: InputMaybe<SubindustryObjRelInsertInput>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AssetKindMaxFields = {
  __typename?: 'AssetKindMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  images?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subindustryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "asset_kind" */
export type AssetKindMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  images?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AssetKindMinFields = {
  __typename?: 'AssetKindMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  images?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subindustryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "asset_kind" */
export type AssetKindMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  images?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "asset_kind" */
export type AssetKindMutationResponse = {
  __typename?: 'AssetKindMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AssetKind>;
};

/** input type for inserting object relation for remote table "asset_kind" */
export type AssetKindObjRelInsertInput = {
  data: AssetKindInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AssetKindOnConflict>;
};

/** on_conflict condition type for table "asset_kind" */
export type AssetKindOnConflict = {
  constraint: AssetKindConstraint;
  updateColumns?: Array<AssetKindUpdateColumn>;
  where?: InputMaybe<AssetKindBoolExp>;
};

/** Ordering options when selecting data from "asset_kind". */
export type AssetKindOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  images?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nfasAggregate?: InputMaybe<AssetAggregateOrderBy>;
  subindustry?: InputMaybe<SubindustryOrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: asset_kind */
export type AssetKindPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "asset_kind" */
export enum AssetKindSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SubindustryId = 'subindustryId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "assetKindAggregateBoolExpBool_andArgumentsColumns" columns of table "asset_kind" */
export enum AssetKindSelectColumnAssetKindAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "assetKindAggregateBoolExpBool_orArgumentsColumns" columns of table "asset_kind" */
export enum AssetKindSelectColumnAssetKindAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "asset_kind" */
export type AssetKindSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "asset_kind" */
export type AssetKindStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AssetKindStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssetKindStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "asset_kind" */
export enum AssetKindUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SubindustryId = 'subindustryId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type AssetKindUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssetKindSetInput>;
  /** filter the rows which have to be updated */
  where: AssetKindBoolExp;
};

/** aggregate max on columns */
export type AssetMaxFields = {
  __typename?: 'AssetMaxFields';
  appraisalId?: Maybe<Scalars['uuid']>;
  assetAccountId?: Maybe<Scalars['uuid']>;
  assetKindId?: Maybe<Scalars['uuid']>;
  authorized?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['numeric']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datasourceId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  images?: Maybe<Scalars['String']>;
  issued?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['uuid']>;
  materials?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  parentCategory?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['uuid']>;
  sourceUrl?: Maybe<Scalars['String']>;
  sourceUserId?: Maybe<Scalars['uuid']>;
  subindustryId?: Maybe<Scalars['uuid']>;
  uom?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "asset" */
export type AssetMaxOrderBy = {
  appraisalId?: InputMaybe<OrderBy>;
  assetAccountId?: InputMaybe<OrderBy>;
  assetKindId?: InputMaybe<OrderBy>;
  authorized?: InputMaybe<OrderBy>;
  category?: InputMaybe<OrderBy>;
  classification?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  datasourceId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  dimensions?: InputMaybe<OrderBy>;
  embedCode?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  images?: InputMaybe<OrderBy>;
  issued?: InputMaybe<OrderBy>;
  materialId?: InputMaybe<OrderBy>;
  materials?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  parentCategory?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  sourceUrl?: InputMaybe<OrderBy>;
  sourceUserId?: InputMaybe<OrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  uom?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AssetMinFields = {
  __typename?: 'AssetMinFields';
  appraisalId?: Maybe<Scalars['uuid']>;
  assetAccountId?: Maybe<Scalars['uuid']>;
  assetKindId?: Maybe<Scalars['uuid']>;
  authorized?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['numeric']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datasourceId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Scalars['String']>;
  embedCode?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  images?: Maybe<Scalars['String']>;
  issued?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['uuid']>;
  materials?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  parentCategory?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['uuid']>;
  sourceUrl?: Maybe<Scalars['String']>;
  sourceUserId?: Maybe<Scalars['uuid']>;
  subindustryId?: Maybe<Scalars['uuid']>;
  uom?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "asset" */
export type AssetMinOrderBy = {
  appraisalId?: InputMaybe<OrderBy>;
  assetAccountId?: InputMaybe<OrderBy>;
  assetKindId?: InputMaybe<OrderBy>;
  authorized?: InputMaybe<OrderBy>;
  category?: InputMaybe<OrderBy>;
  classification?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  datasourceId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  dimensions?: InputMaybe<OrderBy>;
  embedCode?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  images?: InputMaybe<OrderBy>;
  issued?: InputMaybe<OrderBy>;
  materialId?: InputMaybe<OrderBy>;
  materials?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  parentCategory?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  sourceUrl?: InputMaybe<OrderBy>;
  sourceUserId?: InputMaybe<OrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  uom?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "asset" */
export type AssetMutationResponse = {
  __typename?: 'AssetMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Asset>;
};

/** input type for inserting object relation for remote table "asset" */
export type AssetObjRelInsertInput = {
  data: AssetInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AssetOnConflict>;
};

/** on_conflict condition type for table "asset" */
export type AssetOnConflict = {
  constraint: AssetConstraint;
  updateColumns?: Array<AssetUpdateColumn>;
  where?: InputMaybe<AssetBoolExp>;
};

/** Ordering options when selecting data from "asset". */
export type AssetOrderBy = {
  account?: InputMaybe<AssetAccountOrderBy>;
  appraisal?: InputMaybe<AppraisalOrderBy>;
  appraisalId?: InputMaybe<OrderBy>;
  asksAggregate?: InputMaybe<AskAggregateOrderBy>;
  assetAccountId?: InputMaybe<OrderBy>;
  assetKindAggregate?: InputMaybe<AssetKindAggregateOrderBy>;
  assetKindId?: InputMaybe<OrderBy>;
  authorized?: InputMaybe<OrderBy>;
  category?: InputMaybe<OrderBy>;
  classification?: InputMaybe<OrderBy>;
  country?: InputMaybe<CountryOrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  datasource?: InputMaybe<DatasourceOrderBy>;
  datasourceId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  dimensions?: InputMaybe<OrderBy>;
  embedCode?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  images?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  issued?: InputMaybe<OrderBy>;
  kind?: InputMaybe<AssetKindOrderBy>;
  material?: InputMaybe<MaterialOrderBy>;
  materialId?: InputMaybe<OrderBy>;
  materials?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  parentCategory?: InputMaybe<OrderBy>;
  product?: InputMaybe<ProductOrderBy>;
  productId?: InputMaybe<OrderBy>;
  sourceUrl?: InputMaybe<OrderBy>;
  sourceUser?: InputMaybe<UserOrderBy>;
  sourceUserId?: InputMaybe<OrderBy>;
  subindustry?: InputMaybe<SubindustryOrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  uom?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: asset */
export type AssetPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "asset" */
export enum AssetSelectColumn {
  /** column name */
  AppraisalId = 'appraisalId',
  /** column name */
  AssetAccountId = 'assetAccountId',
  /** column name */
  AssetKindId = 'assetKindId',
  /** column name */
  Authorized = 'authorized',
  /** column name */
  Category = 'category',
  /** column name */
  Classification = 'classification',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatasourceId = 'datasourceId',
  /** column name */
  Description = 'description',
  /** column name */
  Dimensions = 'dimensions',
  /** column name */
  EmbedCode = 'embedCode',
  /** column name */
  Format = 'format',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Issued = 'issued',
  /** column name */
  MaterialId = 'materialId',
  /** column name */
  Materials = 'materials',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  ParentCategory = 'parentCategory',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SourceUrl = 'sourceUrl',
  /** column name */
  SourceUserId = 'sourceUserId',
  /** column name */
  SubindustryId = 'subindustryId',
  /** column name */
  Uom = 'uom',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** select "assetAggregateBoolExpBool_andArgumentsColumns" columns of table "asset" */
export enum AssetSelectColumnAssetAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "assetAggregateBoolExpBool_orArgumentsColumns" columns of table "asset" */
export enum AssetSelectColumnAssetAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "asset" */
export type AssetSetInput = {
  appraisalId?: InputMaybe<Scalars['uuid']>;
  assetAccountId?: InputMaybe<Scalars['uuid']>;
  assetKindId?: InputMaybe<Scalars['uuid']>;
  authorized?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  classification?: InputMaybe<Scalars['numeric']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<Scalars['String']>;
  embedCode?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  issued?: InputMaybe<Scalars['String']>;
  materialId?: InputMaybe<Scalars['uuid']>;
  materials?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  parentCategory?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['uuid']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  sourceUserId?: InputMaybe<Scalars['uuid']>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  uom?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type AssetStddevFields = {
  __typename?: 'AssetStddevFields';
  classification?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "asset" */
export type AssetStddevOrderBy = {
  classification?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type AssetStddevPopFields = {
  __typename?: 'AssetStddevPopFields';
  classification?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "asset" */
export type AssetStddevPopOrderBy = {
  classification?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type AssetStddevSampFields = {
  __typename?: 'AssetStddevSampFields';
  classification?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "asset" */
export type AssetStddevSampOrderBy = {
  classification?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "asset" */
export type AssetStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AssetStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssetStreamCursorValueInput = {
  appraisalId?: InputMaybe<Scalars['uuid']>;
  assetAccountId?: InputMaybe<Scalars['uuid']>;
  assetKindId?: InputMaybe<Scalars['uuid']>;
  authorized?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  classification?: InputMaybe<Scalars['numeric']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<Scalars['String']>;
  embedCode?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  issued?: InputMaybe<Scalars['String']>;
  materialId?: InputMaybe<Scalars['uuid']>;
  materials?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  parentCategory?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['uuid']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  sourceUserId?: InputMaybe<Scalars['uuid']>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  uom?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type AssetSumFields = {
  __typename?: 'AssetSumFields';
  classification?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "asset" */
export type AssetSumOrderBy = {
  classification?: InputMaybe<OrderBy>;
};

/** update columns of table "asset" */
export enum AssetUpdateColumn {
  /** column name */
  AppraisalId = 'appraisalId',
  /** column name */
  AssetAccountId = 'assetAccountId',
  /** column name */
  AssetKindId = 'assetKindId',
  /** column name */
  Authorized = 'authorized',
  /** column name */
  Category = 'category',
  /** column name */
  Classification = 'classification',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatasourceId = 'datasourceId',
  /** column name */
  Description = 'description',
  /** column name */
  Dimensions = 'dimensions',
  /** column name */
  EmbedCode = 'embedCode',
  /** column name */
  Format = 'format',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Issued = 'issued',
  /** column name */
  MaterialId = 'materialId',
  /** column name */
  Materials = 'materials',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  ParentCategory = 'parentCategory',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SourceUrl = 'sourceUrl',
  /** column name */
  SourceUserId = 'sourceUserId',
  /** column name */
  SubindustryId = 'subindustryId',
  /** column name */
  Uom = 'uom',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type AssetUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AssetIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssetSetInput>;
  /** filter the rows which have to be updated */
  where: AssetBoolExp;
};

/** aggregate varPop on columns */
export type AssetVarPopFields = {
  __typename?: 'AssetVarPopFields';
  classification?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "asset" */
export type AssetVarPopOrderBy = {
  classification?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type AssetVarSampFields = {
  __typename?: 'AssetVarSampFields';
  classification?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "asset" */
export type AssetVarSampOrderBy = {
  classification?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type AssetVarianceFields = {
  __typename?: 'AssetVarianceFields';
  classification?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "asset" */
export type AssetVarianceOrderBy = {
  classification?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "cache" */
export type Cache = {
  __typename?: 'Cache';
  createdAt: Scalars['timestamptz'];
  expiresAt: Scalars['timestamptz'];
  key: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "cache" */
export type CacheAggregate = {
  __typename?: 'CacheAggregate';
  aggregate?: Maybe<CacheAggregateFields>;
  nodes: Array<Cache>;
};

/** aggregate fields of "cache" */
export type CacheAggregateFields = {
  __typename?: 'CacheAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CacheMaxFields>;
  min?: Maybe<CacheMinFields>;
};

/** aggregate fields of "cache" */
export type CacheAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CacheSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cache". All fields are combined with a logical 'AND'. */
export type CacheBoolExp = {
  _and?: InputMaybe<Array<CacheBoolExp>>;
  _not?: InputMaybe<CacheBoolExp>;
  _or?: InputMaybe<Array<CacheBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  expiresAt?: InputMaybe<TimestamptzComparisonExp>;
  key?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "cache" */
export enum CacheConstraint {
  /** unique or primary key constraint on columns "key" */
  CachePkey = 'cache_pkey',
}

/** input type for inserting data into table "cache" */
export type CacheInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CacheMaxFields = {
  __typename?: 'CacheMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CacheMinFields = {
  __typename?: 'CacheMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "cache" */
export type CacheMutationResponse = {
  __typename?: 'CacheMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cache>;
};

/** on_conflict condition type for table "cache" */
export type CacheOnConflict = {
  constraint: CacheConstraint;
  updateColumns?: Array<CacheUpdateColumn>;
  where?: InputMaybe<CacheBoolExp>;
};

/** Ordering options when selecting data from "cache". */
export type CacheOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  key?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cache */
export type CachePkColumnsInput = {
  key: Scalars['String'];
};

/** select columns of table "cache" */
export enum CacheSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "cache" */
export type CacheSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "cache" */
export type CacheStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CacheStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CacheStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "cache" */
export enum CacheUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
}

export type CacheUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CacheSetInput>;
  /** filter the rows which have to be updated */
  where: CacheBoolExp;
};

/** columns and relationships of "cart" */
export type Cart = {
  __typename?: 'Cart';
  createdAt: Scalars['timestamptz'];
  emailAddress: Scalars['String'];
  id: Scalars['uuid'];
  ipaddress: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  market?: Maybe<Market>;
  marketId: Scalars['uuid'];
  /** An object relationship */
  product?: Maybe<Product>;
  productId: Scalars['uuid'];
  rememberToken: Scalars['String'];
  /** An object relationship */
  shop?: Maybe<Shop>;
  shopId: Scalars['uuid'];
  /** An object relationship */
  sigmaPrime?: Maybe<SigmaPrime>;
  sigmaPrimeAddress: Scalars['uuid'];
  sigmaPrimeId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "cart" */
export type CartAggregate = {
  __typename?: 'CartAggregate';
  aggregate?: Maybe<CartAggregateFields>;
  nodes: Array<Cart>;
};

/** aggregate fields of "cart" */
export type CartAggregateFields = {
  __typename?: 'CartAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CartMaxFields>;
  min?: Maybe<CartMinFields>;
};

/** aggregate fields of "cart" */
export type CartAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CartSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cart". All fields are combined with a logical 'AND'. */
export type CartBoolExp = {
  _and?: InputMaybe<Array<CartBoolExp>>;
  _not?: InputMaybe<CartBoolExp>;
  _or?: InputMaybe<Array<CartBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  emailAddress?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  ipaddress?: InputMaybe<StringComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  market?: InputMaybe<MarketBoolExp>;
  marketId?: InputMaybe<UuidComparisonExp>;
  product?: InputMaybe<ProductBoolExp>;
  productId?: InputMaybe<UuidComparisonExp>;
  rememberToken?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  sigmaPrime?: InputMaybe<SigmaPrimeBoolExp>;
  sigmaPrimeAddress?: InputMaybe<UuidComparisonExp>;
  sigmaPrimeId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "cart" */
export enum CartConstraint {
  /** unique or primary key constraint on columns "id" */
  CartPkey = 'cart_pkey',
}

/** input type for inserting data into table "cart" */
export type CartInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipaddress?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  market?: InputMaybe<MarketObjRelInsertInput>;
  marketId?: InputMaybe<Scalars['uuid']>;
  product?: InputMaybe<ProductObjRelInsertInput>;
  productId?: InputMaybe<Scalars['uuid']>;
  rememberToken?: InputMaybe<Scalars['String']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId?: InputMaybe<Scalars['uuid']>;
  sigmaPrime?: InputMaybe<SigmaPrimeObjRelInsertInput>;
  sigmaPrimeAddress?: InputMaybe<Scalars['uuid']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CartMaxFields = {
  __typename?: 'CartMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  emailAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ipaddress?: Maybe<Scalars['String']>;
  marketId?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  rememberToken?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['uuid']>;
  sigmaPrimeAddress?: Maybe<Scalars['uuid']>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CartMinFields = {
  __typename?: 'CartMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  emailAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ipaddress?: Maybe<Scalars['String']>;
  marketId?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  rememberToken?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['uuid']>;
  sigmaPrimeAddress?: Maybe<Scalars['uuid']>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "cart" */
export type CartMutationResponse = {
  __typename?: 'CartMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cart>;
};

/** on_conflict condition type for table "cart" */
export type CartOnConflict = {
  constraint: CartConstraint;
  updateColumns?: Array<CartUpdateColumn>;
  where?: InputMaybe<CartBoolExp>;
};

/** Ordering options when selecting data from "cart". */
export type CartOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  emailAddress?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ipaddress?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  market?: InputMaybe<MarketOrderBy>;
  marketId?: InputMaybe<OrderBy>;
  product?: InputMaybe<ProductOrderBy>;
  productId?: InputMaybe<OrderBy>;
  rememberToken?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  sigmaPrime?: InputMaybe<SigmaPrimeOrderBy>;
  sigmaPrimeAddress?: InputMaybe<OrderBy>;
  sigmaPrimeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cart */
export type CartPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "cart" */
export enum CartSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EmailAddress = 'emailAddress',
  /** column name */
  Id = 'id',
  /** column name */
  Ipaddress = 'ipaddress',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MarketId = 'marketId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  RememberToken = 'rememberToken',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  SigmaPrimeAddress = 'sigmaPrimeAddress',
  /** column name */
  SigmaPrimeId = 'sigmaPrimeId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "cart" */
export type CartSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipaddress?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  marketId?: InputMaybe<Scalars['uuid']>;
  productId?: InputMaybe<Scalars['uuid']>;
  rememberToken?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  sigmaPrimeAddress?: InputMaybe<Scalars['uuid']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "cart" */
export type CartStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CartStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CartStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipaddress?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  marketId?: InputMaybe<Scalars['uuid']>;
  productId?: InputMaybe<Scalars['uuid']>;
  rememberToken?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  sigmaPrimeAddress?: InputMaybe<Scalars['uuid']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "cart" */
export enum CartUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EmailAddress = 'emailAddress',
  /** column name */
  Id = 'id',
  /** column name */
  Ipaddress = 'ipaddress',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MarketId = 'marketId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  RememberToken = 'rememberToken',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  SigmaPrimeAddress = 'sigmaPrimeAddress',
  /** column name */
  SigmaPrimeId = 'sigmaPrimeId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type CartUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CartSetInput>;
  /** filter the rows which have to be updated */
  where: CartBoolExp;
};

/** columns and relationships of "certification" */
export type Certification = {
  __typename?: 'Certification';
  /** An array relationship */
  clearinghouseCertifications: Array<ClearinghouseCertification>;
  /** An aggregate relationship */
  clearinghouseCertificationsAggregate: ClearinghouseCertificationAggregate;
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  /** An object relationship */
  gicsSector: GicsSector;
  gicsSectorId: Scalars['uuid'];
  /** An object relationship */
  group: Group;
  groupId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  industry?: Maybe<Industry>;
  industryId?: Maybe<Scalars['uuid']>;
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  organization: Organization;
  organizationId: Scalars['uuid'];
  /** An object relationship */
  subindustry: Subindustry;
  subindustryId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "certification" */
export type CertificationClearinghouseCertificationsArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseCertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseCertificationOrderBy>>;
  where?: InputMaybe<ClearinghouseCertificationBoolExp>;
};

/** columns and relationships of "certification" */
export type CertificationClearinghouseCertificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseCertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseCertificationOrderBy>>;
  where?: InputMaybe<ClearinghouseCertificationBoolExp>;
};

/** aggregated selection of "certification" */
export type CertificationAggregate = {
  __typename?: 'CertificationAggregate';
  aggregate?: Maybe<CertificationAggregateFields>;
  nodes: Array<Certification>;
};

export type CertificationAggregateBoolExp = {
  bool_and?: InputMaybe<CertificationAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<CertificationAggregateBoolExpBool_Or>;
  count?: InputMaybe<CertificationAggregateBoolExpCount>;
};

/** aggregate fields of "certification" */
export type CertificationAggregateFields = {
  __typename?: 'CertificationAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CertificationMaxFields>;
  min?: Maybe<CertificationMinFields>;
};

/** aggregate fields of "certification" */
export type CertificationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CertificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "certification" */
export type CertificationAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CertificationMaxOrderBy>;
  min?: InputMaybe<CertificationMinOrderBy>;
};

/** input type for inserting array relation for remote table "certification" */
export type CertificationArrRelInsertInput = {
  data: Array<CertificationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CertificationOnConflict>;
};

/** Boolean expression to filter rows from the table "certification". All fields are combined with a logical 'AND'. */
export type CertificationBoolExp = {
  _and?: InputMaybe<Array<CertificationBoolExp>>;
  _not?: InputMaybe<CertificationBoolExp>;
  _or?: InputMaybe<Array<CertificationBoolExp>>;
  clearinghouseCertifications?: InputMaybe<ClearinghouseCertificationBoolExp>;
  clearinghouseCertificationsAggregate?: InputMaybe<ClearinghouseCertificationAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  gicsSector?: InputMaybe<GicsSectorBoolExp>;
  gicsSectorId?: InputMaybe<UuidComparisonExp>;
  group?: InputMaybe<GroupBoolExp>;
  groupId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  industry?: InputMaybe<IndustryBoolExp>;
  industryId?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  subindustry?: InputMaybe<SubindustryBoolExp>;
  subindustryId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "certification" */
export enum CertificationConstraint {
  /** unique or primary key constraint on columns "id" */
  CertificationPkey = 'certification_pkey',
  /** unique or primary key constraint on columns "id" */
  CertificationXidKey = 'certification_xid_key',
}

/** input type for inserting data into table "certification" */
export type CertificationInsertInput = {
  clearinghouseCertifications?: InputMaybe<ClearinghouseCertificationArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  gicsSector?: InputMaybe<GicsSectorObjRelInsertInput>;
  gicsSectorId?: InputMaybe<Scalars['uuid']>;
  group?: InputMaybe<GroupObjRelInsertInput>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industry?: InputMaybe<IndustryObjRelInsertInput>;
  industryId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  subindustry?: InputMaybe<SubindustryObjRelInsertInput>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CertificationMaxFields = {
  __typename?: 'CertificationMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  gicsSectorId?: Maybe<Scalars['uuid']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  industryId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  subindustryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "certification" */
export type CertificationMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  gicsSectorId?: InputMaybe<OrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  industryId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CertificationMinFields = {
  __typename?: 'CertificationMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  gicsSectorId?: Maybe<Scalars['uuid']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  industryId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  subindustryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "certification" */
export type CertificationMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  gicsSectorId?: InputMaybe<OrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  industryId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "certification" */
export type CertificationMutationResponse = {
  __typename?: 'CertificationMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Certification>;
};

/** input type for inserting object relation for remote table "certification" */
export type CertificationObjRelInsertInput = {
  data: CertificationInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CertificationOnConflict>;
};

/** on_conflict condition type for table "certification" */
export type CertificationOnConflict = {
  constraint: CertificationConstraint;
  updateColumns?: Array<CertificationUpdateColumn>;
  where?: InputMaybe<CertificationBoolExp>;
};

/** Ordering options when selecting data from "certification". */
export type CertificationOrderBy = {
  clearinghouseCertificationsAggregate?: InputMaybe<ClearinghouseCertificationAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  gicsSector?: InputMaybe<GicsSectorOrderBy>;
  gicsSectorId?: InputMaybe<OrderBy>;
  group?: InputMaybe<GroupOrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  industry?: InputMaybe<IndustryOrderBy>;
  industryId?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  subindustry?: InputMaybe<SubindustryOrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: certification */
export type CertificationPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "certification" */
export enum CertificationSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  GicsSectorId = 'gicsSectorId',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  IndustryId = 'industryId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SubindustryId = 'subindustryId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "certificationAggregateBoolExpBool_andArgumentsColumns" columns of table "certification" */
export enum CertificationSelectColumnCertificationAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "certificationAggregateBoolExpBool_orArgumentsColumns" columns of table "certification" */
export enum CertificationSelectColumnCertificationAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "certification" */
export type CertificationSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  gicsSectorId?: InputMaybe<Scalars['uuid']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industryId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "certification" */
export type CertificationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CertificationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CertificationStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  gicsSectorId?: InputMaybe<Scalars['uuid']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industryId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "certification" */
export enum CertificationUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  GicsSectorId = 'gicsSectorId',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  IndustryId = 'industryId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SubindustryId = 'subindustryId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type CertificationUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CertificationSetInput>;
  /** filter the rows which have to be updated */
  where: CertificationBoolExp;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type CitextComparisonExp = {
  _eq?: InputMaybe<Scalars['citext']>;
  _gt?: InputMaybe<Scalars['citext']>;
  _gte?: InputMaybe<Scalars['citext']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']>;
  _in?: InputMaybe<Array<Scalars['citext']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']>;
  _lt?: InputMaybe<Scalars['citext']>;
  _lte?: InputMaybe<Scalars['citext']>;
  _neq?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']>;
  _nin?: InputMaybe<Array<Scalars['citext']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']>;
};

/** columns and relationships of "clearinghouse" */
export type Clearinghouse = {
  __typename?: 'Clearinghouse';
  /** An array relationship */
  clearinghouseCertifications: Array<ClearinghouseCertification>;
  /** An aggregate relationship */
  clearinghouseCertificationsAggregate: ClearinghouseCertificationAggregate;
  clearinghouseKindId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  kind: ClearinghouseKind;
  name: Scalars['String'];
  /** An array relationship */
  smartContracts: Array<SmartContract>;
  /** An aggregate relationship */
  smartContractsAggregate: SmartContractAggregate;
  /** An array relationship */
  trades: Array<Trade>;
  /** An aggregate relationship */
  tradesAggregate: TradeAggregate;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** columns and relationships of "clearinghouse" */
export type ClearinghouseClearinghouseCertificationsArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseCertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseCertificationOrderBy>>;
  where?: InputMaybe<ClearinghouseCertificationBoolExp>;
};

/** columns and relationships of "clearinghouse" */
export type ClearinghouseClearinghouseCertificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseCertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseCertificationOrderBy>>;
  where?: InputMaybe<ClearinghouseCertificationBoolExp>;
};

/** columns and relationships of "clearinghouse" */
export type ClearinghouseSmartContractsArgs = {
  distinctOn?: InputMaybe<Array<SmartContractSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractOrderBy>>;
  where?: InputMaybe<SmartContractBoolExp>;
};

/** columns and relationships of "clearinghouse" */
export type ClearinghouseSmartContractsAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmartContractSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractOrderBy>>;
  where?: InputMaybe<SmartContractBoolExp>;
};

/** columns and relationships of "clearinghouse" */
export type ClearinghouseTradesArgs = {
  distinctOn?: InputMaybe<Array<TradeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TradeOrderBy>>;
  where?: InputMaybe<TradeBoolExp>;
};

/** columns and relationships of "clearinghouse" */
export type ClearinghouseTradesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TradeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TradeOrderBy>>;
  where?: InputMaybe<TradeBoolExp>;
};

/** aggregated selection of "clearinghouse" */
export type ClearinghouseAggregate = {
  __typename?: 'ClearinghouseAggregate';
  aggregate?: Maybe<ClearinghouseAggregateFields>;
  nodes: Array<Clearinghouse>;
};

export type ClearinghouseAggregateBoolExp = {
  bool_and?: InputMaybe<ClearinghouseAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ClearinghouseAggregateBoolExpBool_Or>;
  count?: InputMaybe<ClearinghouseAggregateBoolExpCount>;
};

/** aggregate fields of "clearinghouse" */
export type ClearinghouseAggregateFields = {
  __typename?: 'ClearinghouseAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ClearinghouseMaxFields>;
  min?: Maybe<ClearinghouseMinFields>;
};

/** aggregate fields of "clearinghouse" */
export type ClearinghouseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClearinghouseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clearinghouse" */
export type ClearinghouseAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClearinghouseMaxOrderBy>;
  min?: InputMaybe<ClearinghouseMinOrderBy>;
};

/** input type for inserting array relation for remote table "clearinghouse" */
export type ClearinghouseArrRelInsertInput = {
  data: Array<ClearinghouseInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClearinghouseOnConflict>;
};

/** Boolean expression to filter rows from the table "clearinghouse". All fields are combined with a logical 'AND'. */
export type ClearinghouseBoolExp = {
  _and?: InputMaybe<Array<ClearinghouseBoolExp>>;
  _not?: InputMaybe<ClearinghouseBoolExp>;
  _or?: InputMaybe<Array<ClearinghouseBoolExp>>;
  clearinghouseCertifications?: InputMaybe<ClearinghouseCertificationBoolExp>;
  clearinghouseCertificationsAggregate?: InputMaybe<ClearinghouseCertificationAggregateBoolExp>;
  clearinghouseKindId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  kind?: InputMaybe<ClearinghouseKindBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  smartContracts?: InputMaybe<SmartContractBoolExp>;
  smartContractsAggregate?: InputMaybe<SmartContractAggregateBoolExp>;
  trades?: InputMaybe<TradeBoolExp>;
  tradesAggregate?: InputMaybe<TradeAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** columns and relationships of "clearinghouse_certification" */
export type ClearinghouseCertification = {
  __typename?: 'ClearinghouseCertification';
  /** An object relationship */
  certification: Certification;
  certificationId: Scalars['uuid'];
  /** An object relationship */
  clearinghouse: Clearinghouse;
  clearinghouseId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "clearinghouse_certification" */
export type ClearinghouseCertificationAggregate = {
  __typename?: 'ClearinghouseCertificationAggregate';
  aggregate?: Maybe<ClearinghouseCertificationAggregateFields>;
  nodes: Array<ClearinghouseCertification>;
};

export type ClearinghouseCertificationAggregateBoolExp = {
  bool_and?: InputMaybe<ClearinghouseCertificationAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ClearinghouseCertificationAggregateBoolExpBool_Or>;
  count?: InputMaybe<ClearinghouseCertificationAggregateBoolExpCount>;
};

/** aggregate fields of "clearinghouse_certification" */
export type ClearinghouseCertificationAggregateFields = {
  __typename?: 'ClearinghouseCertificationAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ClearinghouseCertificationMaxFields>;
  min?: Maybe<ClearinghouseCertificationMinFields>;
};

/** aggregate fields of "clearinghouse_certification" */
export type ClearinghouseCertificationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClearinghouseCertificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clearinghouse_certification" */
export type ClearinghouseCertificationAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClearinghouseCertificationMaxOrderBy>;
  min?: InputMaybe<ClearinghouseCertificationMinOrderBy>;
};

/** input type for inserting array relation for remote table "clearinghouse_certification" */
export type ClearinghouseCertificationArrRelInsertInput = {
  data: Array<ClearinghouseCertificationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClearinghouseCertificationOnConflict>;
};

/** Boolean expression to filter rows from the table "clearinghouse_certification". All fields are combined with a logical 'AND'. */
export type ClearinghouseCertificationBoolExp = {
  _and?: InputMaybe<Array<ClearinghouseCertificationBoolExp>>;
  _not?: InputMaybe<ClearinghouseCertificationBoolExp>;
  _or?: InputMaybe<Array<ClearinghouseCertificationBoolExp>>;
  certification?: InputMaybe<CertificationBoolExp>;
  certificationId?: InputMaybe<UuidComparisonExp>;
  clearinghouse?: InputMaybe<ClearinghouseBoolExp>;
  clearinghouseId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isActive?: InputMaybe<BooleanComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "clearinghouse_certification" */
export enum ClearinghouseCertificationConstraint {
  /** unique or primary key constraint on columns "id" */
  ClearinghouseCertificationPkey = 'clearinghouse_certification_pkey',
  /** unique or primary key constraint on columns "id" */
  ClearinghouseCertificationXidKey = 'clearinghouse_certification_xid_key',
}

/** input type for inserting data into table "clearinghouse_certification" */
export type ClearinghouseCertificationInsertInput = {
  certification?: InputMaybe<CertificationObjRelInsertInput>;
  certificationId?: InputMaybe<Scalars['uuid']>;
  clearinghouse?: InputMaybe<ClearinghouseObjRelInsertInput>;
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ClearinghouseCertificationMaxFields = {
  __typename?: 'ClearinghouseCertificationMaxFields';
  certificationId?: Maybe<Scalars['uuid']>;
  clearinghouseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "clearinghouse_certification" */
export type ClearinghouseCertificationMaxOrderBy = {
  certificationId?: InputMaybe<OrderBy>;
  clearinghouseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClearinghouseCertificationMinFields = {
  __typename?: 'ClearinghouseCertificationMinFields';
  certificationId?: Maybe<Scalars['uuid']>;
  clearinghouseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "clearinghouse_certification" */
export type ClearinghouseCertificationMinOrderBy = {
  certificationId?: InputMaybe<OrderBy>;
  clearinghouseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "clearinghouse_certification" */
export type ClearinghouseCertificationMutationResponse = {
  __typename?: 'ClearinghouseCertificationMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClearinghouseCertification>;
};

/** on_conflict condition type for table "clearinghouse_certification" */
export type ClearinghouseCertificationOnConflict = {
  constraint: ClearinghouseCertificationConstraint;
  updateColumns?: Array<ClearinghouseCertificationUpdateColumn>;
  where?: InputMaybe<ClearinghouseCertificationBoolExp>;
};

/** Ordering options when selecting data from "clearinghouse_certification". */
export type ClearinghouseCertificationOrderBy = {
  certification?: InputMaybe<CertificationOrderBy>;
  certificationId?: InputMaybe<OrderBy>;
  clearinghouse?: InputMaybe<ClearinghouseOrderBy>;
  clearinghouseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isActive?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: clearinghouse_certification */
export type ClearinghouseCertificationPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "clearinghouse_certification" */
export enum ClearinghouseCertificationSelectColumn {
  /** column name */
  CertificationId = 'certificationId',
  /** column name */
  ClearinghouseId = 'clearinghouseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "clearinghouseCertificationAggregateBoolExpBool_andArgumentsColumns" columns of table "clearinghouse_certification" */
export enum ClearinghouseCertificationSelectColumnClearinghouseCertificationAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "clearinghouseCertificationAggregateBoolExpBool_orArgumentsColumns" columns of table "clearinghouse_certification" */
export enum ClearinghouseCertificationSelectColumnClearinghouseCertificationAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "clearinghouse_certification" */
export type ClearinghouseCertificationSetInput = {
  certificationId?: InputMaybe<Scalars['uuid']>;
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "clearinghouse_certification" */
export type ClearinghouseCertificationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClearinghouseCertificationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClearinghouseCertificationStreamCursorValueInput = {
  certificationId?: InputMaybe<Scalars['uuid']>;
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "clearinghouse_certification" */
export enum ClearinghouseCertificationUpdateColumn {
  /** column name */
  CertificationId = 'certificationId',
  /** column name */
  ClearinghouseId = 'clearinghouseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type ClearinghouseCertificationUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClearinghouseCertificationSetInput>;
  /** filter the rows which have to be updated */
  where: ClearinghouseCertificationBoolExp;
};

/** unique or primary key constraints on table "clearinghouse" */
export enum ClearinghouseConstraint {
  /** unique or primary key constraint on columns "id" */
  ClearinghousePkey = 'clearinghouse_pkey',
  /** unique or primary key constraint on columns "id" */
  ClearinghouseXidKey = 'clearinghouse_xid_key',
}

/** input type for inserting data into table "clearinghouse" */
export type ClearinghouseInsertInput = {
  clearinghouseCertifications?: InputMaybe<ClearinghouseCertificationArrRelInsertInput>;
  clearinghouseKindId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<ClearinghouseKindObjRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  smartContracts?: InputMaybe<SmartContractArrRelInsertInput>;
  trades?: InputMaybe<TradeArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "clearinghouse_kind" */
export type ClearinghouseKind = {
  __typename?: 'ClearinghouseKind';
  /** An array relationship */
  clearinghouses: Array<Clearinghouse>;
  /** An aggregate relationship */
  clearinghousesAggregate: ClearinghouseAggregate;
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  /** An object relationship */
  gicsSector: GicsSector;
  gicsSectorId: Scalars['uuid'];
  /** An object relationship */
  group: Group;
  groupId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  industry: Industry;
  industryId: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  subindustry: Subindustry;
  subindustryId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "clearinghouse_kind" */
export type ClearinghouseKindClearinghousesArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseOrderBy>>;
  where?: InputMaybe<ClearinghouseBoolExp>;
};

/** columns and relationships of "clearinghouse_kind" */
export type ClearinghouseKindClearinghousesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseOrderBy>>;
  where?: InputMaybe<ClearinghouseBoolExp>;
};

/** aggregated selection of "clearinghouse_kind" */
export type ClearinghouseKindAggregate = {
  __typename?: 'ClearinghouseKindAggregate';
  aggregate?: Maybe<ClearinghouseKindAggregateFields>;
  nodes: Array<ClearinghouseKind>;
};

export type ClearinghouseKindAggregateBoolExp = {
  bool_and?: InputMaybe<ClearinghouseKindAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ClearinghouseKindAggregateBoolExpBool_Or>;
  count?: InputMaybe<ClearinghouseKindAggregateBoolExpCount>;
};

/** aggregate fields of "clearinghouse_kind" */
export type ClearinghouseKindAggregateFields = {
  __typename?: 'ClearinghouseKindAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ClearinghouseKindMaxFields>;
  min?: Maybe<ClearinghouseKindMinFields>;
};

/** aggregate fields of "clearinghouse_kind" */
export type ClearinghouseKindAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClearinghouseKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clearinghouse_kind" */
export type ClearinghouseKindAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClearinghouseKindMaxOrderBy>;
  min?: InputMaybe<ClearinghouseKindMinOrderBy>;
};

/** input type for inserting array relation for remote table "clearinghouse_kind" */
export type ClearinghouseKindArrRelInsertInput = {
  data: Array<ClearinghouseKindInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClearinghouseKindOnConflict>;
};

/** Boolean expression to filter rows from the table "clearinghouse_kind". All fields are combined with a logical 'AND'. */
export type ClearinghouseKindBoolExp = {
  _and?: InputMaybe<Array<ClearinghouseKindBoolExp>>;
  _not?: InputMaybe<ClearinghouseKindBoolExp>;
  _or?: InputMaybe<Array<ClearinghouseKindBoolExp>>;
  clearinghouses?: InputMaybe<ClearinghouseBoolExp>;
  clearinghousesAggregate?: InputMaybe<ClearinghouseAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  gicsSector?: InputMaybe<GicsSectorBoolExp>;
  gicsSectorId?: InputMaybe<UuidComparisonExp>;
  group?: InputMaybe<GroupBoolExp>;
  groupId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  industry?: InputMaybe<IndustryBoolExp>;
  industryId?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  subindustry?: InputMaybe<SubindustryBoolExp>;
  subindustryId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "clearinghouse_kind" */
export enum ClearinghouseKindConstraint {
  /** unique or primary key constraint on columns "id" */
  ClearinghouseKindPkey = 'clearinghouse_kind_pkey',
  /** unique or primary key constraint on columns "id" */
  ClearinghouseKindXidKey = 'clearinghouse_kind_xid_key',
}

/** input type for inserting data into table "clearinghouse_kind" */
export type ClearinghouseKindInsertInput = {
  clearinghouses?: InputMaybe<ClearinghouseArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  gicsSector?: InputMaybe<GicsSectorObjRelInsertInput>;
  gicsSectorId?: InputMaybe<Scalars['uuid']>;
  group?: InputMaybe<GroupObjRelInsertInput>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industry?: InputMaybe<IndustryObjRelInsertInput>;
  industryId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  subindustry?: InputMaybe<SubindustryObjRelInsertInput>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ClearinghouseKindMaxFields = {
  __typename?: 'ClearinghouseKindMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  gicsSectorId?: Maybe<Scalars['uuid']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  industryId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subindustryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "clearinghouse_kind" */
export type ClearinghouseKindMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  gicsSectorId?: InputMaybe<OrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  industryId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClearinghouseKindMinFields = {
  __typename?: 'ClearinghouseKindMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  gicsSectorId?: Maybe<Scalars['uuid']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  industryId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subindustryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "clearinghouse_kind" */
export type ClearinghouseKindMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  gicsSectorId?: InputMaybe<OrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  industryId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "clearinghouse_kind" */
export type ClearinghouseKindMutationResponse = {
  __typename?: 'ClearinghouseKindMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClearinghouseKind>;
};

/** input type for inserting object relation for remote table "clearinghouse_kind" */
export type ClearinghouseKindObjRelInsertInput = {
  data: ClearinghouseKindInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ClearinghouseKindOnConflict>;
};

/** on_conflict condition type for table "clearinghouse_kind" */
export type ClearinghouseKindOnConflict = {
  constraint: ClearinghouseKindConstraint;
  updateColumns?: Array<ClearinghouseKindUpdateColumn>;
  where?: InputMaybe<ClearinghouseKindBoolExp>;
};

/** Ordering options when selecting data from "clearinghouse_kind". */
export type ClearinghouseKindOrderBy = {
  clearinghousesAggregate?: InputMaybe<ClearinghouseAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  gicsSector?: InputMaybe<GicsSectorOrderBy>;
  gicsSectorId?: InputMaybe<OrderBy>;
  group?: InputMaybe<GroupOrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  industry?: InputMaybe<IndustryOrderBy>;
  industryId?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  subindustry?: InputMaybe<SubindustryOrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: clearinghouse_kind */
export type ClearinghouseKindPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "clearinghouse_kind" */
export enum ClearinghouseKindSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  GicsSectorId = 'gicsSectorId',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  IndustryId = 'industryId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SubindustryId = 'subindustryId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "clearinghouseKindAggregateBoolExpBool_andArgumentsColumns" columns of table "clearinghouse_kind" */
export enum ClearinghouseKindSelectColumnClearinghouseKindAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "clearinghouseKindAggregateBoolExpBool_orArgumentsColumns" columns of table "clearinghouse_kind" */
export enum ClearinghouseKindSelectColumnClearinghouseKindAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "clearinghouse_kind" */
export type ClearinghouseKindSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  gicsSectorId?: InputMaybe<Scalars['uuid']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industryId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "clearinghouse_kind" */
export type ClearinghouseKindStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClearinghouseKindStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClearinghouseKindStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  gicsSectorId?: InputMaybe<Scalars['uuid']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industryId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "clearinghouse_kind" */
export enum ClearinghouseKindUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  GicsSectorId = 'gicsSectorId',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  IndustryId = 'industryId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SubindustryId = 'subindustryId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type ClearinghouseKindUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClearinghouseKindSetInput>;
  /** filter the rows which have to be updated */
  where: ClearinghouseKindBoolExp;
};

/** aggregate max on columns */
export type ClearinghouseMaxFields = {
  __typename?: 'ClearinghouseMaxFields';
  clearinghouseKindId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "clearinghouse" */
export type ClearinghouseMaxOrderBy = {
  clearinghouseKindId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClearinghouseMinFields = {
  __typename?: 'ClearinghouseMinFields';
  clearinghouseKindId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "clearinghouse" */
export type ClearinghouseMinOrderBy = {
  clearinghouseKindId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "clearinghouse" */
export type ClearinghouseMutationResponse = {
  __typename?: 'ClearinghouseMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clearinghouse>;
};

/** input type for inserting object relation for remote table "clearinghouse" */
export type ClearinghouseObjRelInsertInput = {
  data: ClearinghouseInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ClearinghouseOnConflict>;
};

/** on_conflict condition type for table "clearinghouse" */
export type ClearinghouseOnConflict = {
  constraint: ClearinghouseConstraint;
  updateColumns?: Array<ClearinghouseUpdateColumn>;
  where?: InputMaybe<ClearinghouseBoolExp>;
};

/** Ordering options when selecting data from "clearinghouse". */
export type ClearinghouseOrderBy = {
  clearinghouseCertificationsAggregate?: InputMaybe<ClearinghouseCertificationAggregateOrderBy>;
  clearinghouseKindId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  kind?: InputMaybe<ClearinghouseKindOrderBy>;
  name?: InputMaybe<OrderBy>;
  smartContractsAggregate?: InputMaybe<SmartContractAggregateOrderBy>;
  tradesAggregate?: InputMaybe<TradeAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: clearinghouse */
export type ClearinghousePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "clearinghouse" */
export enum ClearinghouseSelectColumn {
  /** column name */
  ClearinghouseKindId = 'clearinghouseKindId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** select "clearinghouseAggregateBoolExpBool_andArgumentsColumns" columns of table "clearinghouse" */
export enum ClearinghouseSelectColumnClearinghouseAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "clearinghouseAggregateBoolExpBool_orArgumentsColumns" columns of table "clearinghouse" */
export enum ClearinghouseSelectColumnClearinghouseAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "clearinghouse" */
export type ClearinghouseSetInput = {
  clearinghouseKindId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "clearinghouse" */
export type ClearinghouseStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClearinghouseStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClearinghouseStreamCursorValueInput = {
  clearinghouseKindId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "clearinghouse" */
export enum ClearinghouseUpdateColumn {
  /** column name */
  ClearinghouseKindId = 'clearinghouseKindId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type ClearinghouseUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClearinghouseSetInput>;
  /** filter the rows which have to be updated */
  where: ClearinghouseBoolExp;
};

/** columns and relationships of "country" */
export type Country = {
  __typename?: 'Country';
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  currencies: Array<Currency>;
  /** An aggregate relationship */
  currenciesAggregate: CurrencyAggregate;
  currencyCode: Scalars['String'];
  flagEmoji: Scalars['String'];
  flagEmojiUnicode: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  iso3: Scalars['String'];
  isoNumber: Scalars['Int'];
  latitude: Scalars['float8'];
  longitude: Scalars['float8'];
  name: Scalars['String'];
  nameNative?: Maybe<Scalars['String']>;
  nameTranslations: Scalars['jsonb'];
  /** An array relationship */
  nfas: Array<Asset>;
  /** An aggregate relationship */
  nfasAggregate: AssetAggregate;
  phoneCode: Scalars['Int'];
  region: Scalars['String'];
  secCode?: Maybe<Scalars['String']>;
  stateLabel: Scalars['String'];
  /** An array relationship */
  states: Array<State>;
  /** An aggregate relationship */
  statesAggregate: StateAggregate;
  subregion: Scalars['String'];
  tld: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "country" */
export type CountryCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<CurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOrderBy>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

/** columns and relationships of "country" */
export type CountryCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOrderBy>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

/** columns and relationships of "country" */
export type CountryNameTranslationsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "country" */
export type CountryNfasArgs = {
  distinctOn?: InputMaybe<Array<AssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetOrderBy>>;
  where?: InputMaybe<AssetBoolExp>;
};

/** columns and relationships of "country" */
export type CountryNfasAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetOrderBy>>;
  where?: InputMaybe<AssetBoolExp>;
};

/** columns and relationships of "country" */
export type CountryStatesArgs = {
  distinctOn?: InputMaybe<Array<StateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StateOrderBy>>;
  where?: InputMaybe<StateBoolExp>;
};

/** columns and relationships of "country" */
export type CountryStatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<StateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StateOrderBy>>;
  where?: InputMaybe<StateBoolExp>;
};

/** aggregated selection of "country" */
export type CountryAggregate = {
  __typename?: 'CountryAggregate';
  aggregate?: Maybe<CountryAggregateFields>;
  nodes: Array<Country>;
};

/** aggregate fields of "country" */
export type CountryAggregateFields = {
  __typename?: 'CountryAggregateFields';
  avg?: Maybe<CountryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CountryMaxFields>;
  min?: Maybe<CountryMinFields>;
  stddev?: Maybe<CountryStddevFields>;
  stddevPop?: Maybe<CountryStddevPopFields>;
  stddevSamp?: Maybe<CountryStddevSampFields>;
  sum?: Maybe<CountrySumFields>;
  varPop?: Maybe<CountryVarPopFields>;
  varSamp?: Maybe<CountryVarSampFields>;
  variance?: Maybe<CountryVarianceFields>;
};

/** aggregate fields of "country" */
export type CountryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CountrySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CountryAppendInput = {
  nameTranslations?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type CountryAvgFields = {
  __typename?: 'CountryAvgFields';
  isoNumber?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phoneCode?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "country". All fields are combined with a logical 'AND'. */
export type CountryBoolExp = {
  _and?: InputMaybe<Array<CountryBoolExp>>;
  _not?: InputMaybe<CountryBoolExp>;
  _or?: InputMaybe<Array<CountryBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currencies?: InputMaybe<CurrencyBoolExp>;
  currenciesAggregate?: InputMaybe<CurrencyAggregateBoolExp>;
  currencyCode?: InputMaybe<StringComparisonExp>;
  flagEmoji?: InputMaybe<StringComparisonExp>;
  flagEmojiUnicode?: InputMaybe<StringComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  iso3?: InputMaybe<StringComparisonExp>;
  isoNumber?: InputMaybe<IntComparisonExp>;
  latitude?: InputMaybe<Float8ComparisonExp>;
  longitude?: InputMaybe<Float8ComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nameNative?: InputMaybe<StringComparisonExp>;
  nameTranslations?: InputMaybe<JsonbComparisonExp>;
  nfas?: InputMaybe<AssetBoolExp>;
  nfasAggregate?: InputMaybe<AssetAggregateBoolExp>;
  phoneCode?: InputMaybe<IntComparisonExp>;
  region?: InputMaybe<StringComparisonExp>;
  secCode?: InputMaybe<StringComparisonExp>;
  stateLabel?: InputMaybe<StringComparisonExp>;
  states?: InputMaybe<StateBoolExp>;
  statesAggregate?: InputMaybe<StateAggregateBoolExp>;
  subregion?: InputMaybe<StringComparisonExp>;
  tld?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "country" */
export enum CountryConstraint {
  /** unique or primary key constraint on columns "code" */
  AddressCountryCodeKey = 'address_country_code_key',
  /** unique or primary key constraint on columns "code" */
  AddressCountryPkey = 'address_country_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CountryDeleteAtPathInput = {
  nameTranslations?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CountryDeleteElemInput = {
  nameTranslations?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CountryDeleteKeyInput = {
  nameTranslations?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "country" */
export type CountryIncInput = {
  isoNumber?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  phoneCode?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "country" */
export type CountryInsertInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencies?: InputMaybe<CurrencyArrRelInsertInput>;
  currencyCode?: InputMaybe<Scalars['String']>;
  flagEmoji?: InputMaybe<Scalars['String']>;
  flagEmojiUnicode?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  iso3?: InputMaybe<Scalars['String']>;
  isoNumber?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  nameNative?: InputMaybe<Scalars['String']>;
  nameTranslations?: InputMaybe<Scalars['jsonb']>;
  nfas?: InputMaybe<AssetArrRelInsertInput>;
  phoneCode?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  secCode?: InputMaybe<Scalars['String']>;
  stateLabel?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<StateArrRelInsertInput>;
  subregion?: InputMaybe<Scalars['String']>;
  tld?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CountryMaxFields = {
  __typename?: 'CountryMaxFields';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyCode?: Maybe<Scalars['String']>;
  flagEmoji?: Maybe<Scalars['String']>;
  flagEmojiUnicode?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  isoNumber?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  nameNative?: Maybe<Scalars['String']>;
  phoneCode?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  secCode?: Maybe<Scalars['String']>;
  stateLabel?: Maybe<Scalars['String']>;
  subregion?: Maybe<Scalars['String']>;
  tld?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type CountryMinFields = {
  __typename?: 'CountryMinFields';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyCode?: Maybe<Scalars['String']>;
  flagEmoji?: Maybe<Scalars['String']>;
  flagEmojiUnicode?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  isoNumber?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  nameNative?: Maybe<Scalars['String']>;
  phoneCode?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  secCode?: Maybe<Scalars['String']>;
  stateLabel?: Maybe<Scalars['String']>;
  subregion?: Maybe<Scalars['String']>;
  tld?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "country" */
export type CountryMutationResponse = {
  __typename?: 'CountryMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Country>;
};

/** input type for inserting object relation for remote table "country" */
export type CountryObjRelInsertInput = {
  data: CountryInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CountryOnConflict>;
};

/** on_conflict condition type for table "country" */
export type CountryOnConflict = {
  constraint: CountryConstraint;
  updateColumns?: Array<CountryUpdateColumn>;
  where?: InputMaybe<CountryBoolExp>;
};

/** Ordering options when selecting data from "country". */
export type CountryOrderBy = {
  code?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currenciesAggregate?: InputMaybe<CurrencyAggregateOrderBy>;
  currencyCode?: InputMaybe<OrderBy>;
  flagEmoji?: InputMaybe<OrderBy>;
  flagEmojiUnicode?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  iso3?: InputMaybe<OrderBy>;
  isoNumber?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameNative?: InputMaybe<OrderBy>;
  nameTranslations?: InputMaybe<OrderBy>;
  nfasAggregate?: InputMaybe<AssetAggregateOrderBy>;
  phoneCode?: InputMaybe<OrderBy>;
  region?: InputMaybe<OrderBy>;
  secCode?: InputMaybe<OrderBy>;
  stateLabel?: InputMaybe<OrderBy>;
  statesAggregate?: InputMaybe<StateAggregateOrderBy>;
  subregion?: InputMaybe<OrderBy>;
  tld?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: country */
export type CountryPkColumnsInput = {
  code: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CountryPrependInput = {
  nameTranslations?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "country_region" */
export type CountryRegion = {
  __typename?: 'CountryRegion';
  countryCode: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "country_region" */
export type CountryRegionAggregate = {
  __typename?: 'CountryRegionAggregate';
  aggregate?: Maybe<CountryRegionAggregateFields>;
  nodes: Array<CountryRegion>;
};

/** aggregate fields of "country_region" */
export type CountryRegionAggregateFields = {
  __typename?: 'CountryRegionAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CountryRegionMaxFields>;
  min?: Maybe<CountryRegionMinFields>;
};

/** aggregate fields of "country_region" */
export type CountryRegionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CountryRegionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "country_region". All fields are combined with a logical 'AND'. */
export type CountryRegionBoolExp = {
  _and?: InputMaybe<Array<CountryRegionBoolExp>>;
  _not?: InputMaybe<CountryRegionBoolExp>;
  _or?: InputMaybe<Array<CountryRegionBoolExp>>;
  countryCode?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "country_region" */
export enum CountryRegionConstraint {
  /** unique or primary key constraint on columns "id" */
  CountryRegionPkey = 'country_region_pkey',
}

/** input type for inserting data into table "country_region" */
export type CountryRegionInsertInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CountryRegionMaxFields = {
  __typename?: 'CountryRegionMaxFields';
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type CountryRegionMinFields = {
  __typename?: 'CountryRegionMinFields';
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "country_region" */
export type CountryRegionMutationResponse = {
  __typename?: 'CountryRegionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CountryRegion>;
};

/** input type for inserting object relation for remote table "country_region" */
export type CountryRegionObjRelInsertInput = {
  data: CountryRegionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CountryRegionOnConflict>;
};

/** on_conflict condition type for table "country_region" */
export type CountryRegionOnConflict = {
  constraint: CountryRegionConstraint;
  updateColumns?: Array<CountryRegionUpdateColumn>;
  where?: InputMaybe<CountryRegionBoolExp>;
};

/** Ordering options when selecting data from "country_region". */
export type CountryRegionOrderBy = {
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: country_region */
export type CountryRegionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "country_region" */
export enum CountryRegionSelectColumn {
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "country_region" */
export type CountryRegionSetInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "country_region" */
export type CountryRegionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CountryRegionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CountryRegionStreamCursorValueInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "country_region" */
export enum CountryRegionUpdateColumn {
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type CountryRegionUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CountryRegionSetInput>;
  /** filter the rows which have to be updated */
  where: CountryRegionBoolExp;
};

/** select columns of table "country" */
export enum CountrySelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyCode = 'currencyCode',
  /** column name */
  FlagEmoji = 'flagEmoji',
  /** column name */
  FlagEmojiUnicode = 'flagEmojiUnicode',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Iso3 = 'iso3',
  /** column name */
  IsoNumber = 'isoNumber',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  NameNative = 'nameNative',
  /** column name */
  NameTranslations = 'nameTranslations',
  /** column name */
  PhoneCode = 'phoneCode',
  /** column name */
  Region = 'region',
  /** column name */
  SecCode = 'secCode',
  /** column name */
  StateLabel = 'stateLabel',
  /** column name */
  Subregion = 'subregion',
  /** column name */
  Tld = 'tld',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "country" */
export type CountrySetInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  flagEmoji?: InputMaybe<Scalars['String']>;
  flagEmojiUnicode?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  iso3?: InputMaybe<Scalars['String']>;
  isoNumber?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  nameNative?: InputMaybe<Scalars['String']>;
  nameTranslations?: InputMaybe<Scalars['jsonb']>;
  phoneCode?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  secCode?: InputMaybe<Scalars['String']>;
  stateLabel?: InputMaybe<Scalars['String']>;
  subregion?: InputMaybe<Scalars['String']>;
  tld?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CountryStddevFields = {
  __typename?: 'CountryStddevFields';
  isoNumber?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phoneCode?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type CountryStddevPopFields = {
  __typename?: 'CountryStddevPopFields';
  isoNumber?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phoneCode?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type CountryStddevSampFields = {
  __typename?: 'CountryStddevSampFields';
  isoNumber?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phoneCode?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "country" */
export type CountryStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CountryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CountryStreamCursorValueInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  flagEmoji?: InputMaybe<Scalars['String']>;
  flagEmojiUnicode?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  iso3?: InputMaybe<Scalars['String']>;
  isoNumber?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  nameNative?: InputMaybe<Scalars['String']>;
  nameTranslations?: InputMaybe<Scalars['jsonb']>;
  phoneCode?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  secCode?: InputMaybe<Scalars['String']>;
  stateLabel?: InputMaybe<Scalars['String']>;
  subregion?: InputMaybe<Scalars['String']>;
  tld?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type CountrySumFields = {
  __typename?: 'CountrySumFields';
  isoNumber?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  phoneCode?: Maybe<Scalars['Int']>;
};

/** update columns of table "country" */
export enum CountryUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyCode = 'currencyCode',
  /** column name */
  FlagEmoji = 'flagEmoji',
  /** column name */
  FlagEmojiUnicode = 'flagEmojiUnicode',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Iso3 = 'iso3',
  /** column name */
  IsoNumber = 'isoNumber',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  NameNative = 'nameNative',
  /** column name */
  NameTranslations = 'nameTranslations',
  /** column name */
  PhoneCode = 'phoneCode',
  /** column name */
  Region = 'region',
  /** column name */
  SecCode = 'secCode',
  /** column name */
  StateLabel = 'stateLabel',
  /** column name */
  Subregion = 'subregion',
  /** column name */
  Tld = 'tld',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type CountryUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CountryAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<CountryDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<CountryDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<CountryDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CountryIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CountryPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CountrySetInput>;
  /** filter the rows which have to be updated */
  where: CountryBoolExp;
};

/** aggregate varPop on columns */
export type CountryVarPopFields = {
  __typename?: 'CountryVarPopFields';
  isoNumber?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phoneCode?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type CountryVarSampFields = {
  __typename?: 'CountryVarSampFields';
  isoNumber?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phoneCode?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CountryVarianceFields = {
  __typename?: 'CountryVarianceFields';
  isoNumber?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phoneCode?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "currency" */
export type Currency = {
  __typename?: 'Currency';
  abbreviation?: Maybe<Scalars['String']>;
  /** An array relationship */
  asks: Array<Ask>;
  /** An aggregate relationship */
  asksAggregate: AskAggregate;
  authorized?: Maybe<Scalars['bigint']>;
  cc?: Maybe<Scalars['String']>;
  /** An object relationship */
  country?: Maybe<Country>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  currencyKindId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  exchanges: Array<Exchange>;
  /** An aggregate relationship */
  exchangesAggregate: ExchangeAggregate;
  /** An array relationship */
  favoriteCurrencies: Array<FavoriteComment>;
  /** An aggregate relationship */
  favoriteCurrenciesAggregate: FavoriteCommentAggregate;
  /** An object relationship */
  gicsSector?: Maybe<GicsSector>;
  /** An object relationship */
  group?: Maybe<Group>;
  groupsId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  image?: Maybe<Scalars['String']>;
  /** An array relationship */
  interactions: Array<Interaction>;
  /** An aggregate relationship */
  interactionsAggregate: InteractionAggregate;
  isDeleted: Scalars['Boolean'];
  issued?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  kind?: Maybe<CurrencyKind>;
  /** An array relationship */
  merchantCurrencies: Array<MerchantCurrency>;
  /** An aggregate relationship */
  merchantCurrenciesAggregate: MerchantCurrencyAggregate;
  minorUnit?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  numericCode?: Maybe<Scalars['String']>;
  /** An array relationship */
  owners: Array<CurrencyOwner>;
  /** An aggregate relationship */
  ownersAggregate: CurrencyOwnerAggregate;
  /** An array relationship */
  payments: Array<Payment>;
  /** An aggregate relationship */
  paymentsAggregate: PaymentAggregate;
  /** An array relationship */
  puts: Array<Put>;
  /** An aggregate relationship */
  putsAggregate: PutAggregate;
  reserved?: Maybe<Scalars['bigint']>;
  sectorId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  subindustry?: Maybe<Subindustry>;
  subindustryId?: Maybe<Scalars['uuid']>;
  symbol?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  wallets: Array<Wallet>;
  /** An aggregate relationship */
  walletsAggregate: WalletAggregate;
};

/** columns and relationships of "currency" */
export type CurrencyAsksArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyAsksAggregateArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyExchangesArgs = {
  distinctOn?: InputMaybe<Array<ExchangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeOrderBy>>;
  where?: InputMaybe<ExchangeBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyExchangesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExchangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeOrderBy>>;
  where?: InputMaybe<ExchangeBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyFavoriteCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<FavoriteCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteCommentOrderBy>>;
  where?: InputMaybe<FavoriteCommentBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyFavoriteCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<FavoriteCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteCommentOrderBy>>;
  where?: InputMaybe<FavoriteCommentBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyInteractionsArgs = {
  distinctOn?: InputMaybe<Array<InteractionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionOrderBy>>;
  where?: InputMaybe<InteractionBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyInteractionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<InteractionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionOrderBy>>;
  where?: InputMaybe<InteractionBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyMerchantCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<MerchantCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantCurrencyOrderBy>>;
  where?: InputMaybe<MerchantCurrencyBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyMerchantCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MerchantCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantCurrencyOrderBy>>;
  where?: InputMaybe<MerchantCurrencyBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyOwnersArgs = {
  distinctOn?: InputMaybe<Array<CurrencyOwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOwnerOrderBy>>;
  where?: InputMaybe<CurrencyOwnerBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyOwnersAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencyOwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOwnerOrderBy>>;
  where?: InputMaybe<CurrencyOwnerBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyPaymentsArgs = {
  distinctOn?: InputMaybe<Array<PaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentOrderBy>>;
  where?: InputMaybe<PaymentBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyPaymentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentOrderBy>>;
  where?: InputMaybe<PaymentBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyPutsArgs = {
  distinctOn?: InputMaybe<Array<PutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PutOrderBy>>;
  where?: InputMaybe<PutBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyPutsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PutOrderBy>>;
  where?: InputMaybe<PutBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyWalletsArgs = {
  distinctOn?: InputMaybe<Array<WalletSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletOrderBy>>;
  where?: InputMaybe<WalletBoolExp>;
};

/** columns and relationships of "currency" */
export type CurrencyWalletsAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletOrderBy>>;
  where?: InputMaybe<WalletBoolExp>;
};

/** aggregated selection of "currency" */
export type CurrencyAggregate = {
  __typename?: 'CurrencyAggregate';
  aggregate?: Maybe<CurrencyAggregateFields>;
  nodes: Array<Currency>;
};

export type CurrencyAggregateBoolExp = {
  bool_and?: InputMaybe<CurrencyAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<CurrencyAggregateBoolExpBool_Or>;
  count?: InputMaybe<CurrencyAggregateBoolExpCount>;
};

/** aggregate fields of "currency" */
export type CurrencyAggregateFields = {
  __typename?: 'CurrencyAggregateFields';
  avg?: Maybe<CurrencyAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CurrencyMaxFields>;
  min?: Maybe<CurrencyMinFields>;
  stddev?: Maybe<CurrencyStddevFields>;
  stddevPop?: Maybe<CurrencyStddevPopFields>;
  stddevSamp?: Maybe<CurrencyStddevSampFields>;
  sum?: Maybe<CurrencySumFields>;
  varPop?: Maybe<CurrencyVarPopFields>;
  varSamp?: Maybe<CurrencyVarSampFields>;
  variance?: Maybe<CurrencyVarianceFields>;
};

/** aggregate fields of "currency" */
export type CurrencyAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CurrencySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "currency" */
export type CurrencyAggregateOrderBy = {
  avg?: InputMaybe<CurrencyAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CurrencyMaxOrderBy>;
  min?: InputMaybe<CurrencyMinOrderBy>;
  stddev?: InputMaybe<CurrencyStddevOrderBy>;
  stddevPop?: InputMaybe<CurrencyStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CurrencyStddevSampOrderBy>;
  sum?: InputMaybe<CurrencySumOrderBy>;
  varPop?: InputMaybe<CurrencyVarPopOrderBy>;
  varSamp?: InputMaybe<CurrencyVarSampOrderBy>;
  variance?: InputMaybe<CurrencyVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "currency" */
export type CurrencyArrRelInsertInput = {
  data: Array<CurrencyInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CurrencyOnConflict>;
};

/** aggregate avg on columns */
export type CurrencyAvgFields = {
  __typename?: 'CurrencyAvgFields';
  authorized?: Maybe<Scalars['Float']>;
  issued?: Maybe<Scalars['Float']>;
  reserved?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "currency" */
export type CurrencyAvgOrderBy = {
  authorized?: InputMaybe<OrderBy>;
  issued?: InputMaybe<OrderBy>;
  reserved?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "currency". All fields are combined with a logical 'AND'. */
export type CurrencyBoolExp = {
  _and?: InputMaybe<Array<CurrencyBoolExp>>;
  _not?: InputMaybe<CurrencyBoolExp>;
  _or?: InputMaybe<Array<CurrencyBoolExp>>;
  abbreviation?: InputMaybe<StringComparisonExp>;
  asks?: InputMaybe<AskBoolExp>;
  asksAggregate?: InputMaybe<AskAggregateBoolExp>;
  authorized?: InputMaybe<BigintComparisonExp>;
  cc?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<CountryBoolExp>;
  countryCode?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currencyKindId?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  exchanges?: InputMaybe<ExchangeBoolExp>;
  exchangesAggregate?: InputMaybe<ExchangeAggregateBoolExp>;
  favoriteCurrencies?: InputMaybe<FavoriteCommentBoolExp>;
  favoriteCurrenciesAggregate?: InputMaybe<FavoriteCommentAggregateBoolExp>;
  gicsSector?: InputMaybe<GicsSectorBoolExp>;
  group?: InputMaybe<GroupBoolExp>;
  groupsId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  image?: InputMaybe<StringComparisonExp>;
  interactions?: InputMaybe<InteractionBoolExp>;
  interactionsAggregate?: InputMaybe<InteractionAggregateBoolExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  issued?: InputMaybe<BigintComparisonExp>;
  kind?: InputMaybe<CurrencyKindBoolExp>;
  merchantCurrencies?: InputMaybe<MerchantCurrencyBoolExp>;
  merchantCurrenciesAggregate?: InputMaybe<MerchantCurrencyAggregateBoolExp>;
  minorUnit?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  numericCode?: InputMaybe<StringComparisonExp>;
  owners?: InputMaybe<CurrencyOwnerBoolExp>;
  ownersAggregate?: InputMaybe<CurrencyOwnerAggregateBoolExp>;
  payments?: InputMaybe<PaymentBoolExp>;
  paymentsAggregate?: InputMaybe<PaymentAggregateBoolExp>;
  puts?: InputMaybe<PutBoolExp>;
  putsAggregate?: InputMaybe<PutAggregateBoolExp>;
  reserved?: InputMaybe<BigintComparisonExp>;
  sectorId?: InputMaybe<UuidComparisonExp>;
  subindustry?: InputMaybe<SubindustryBoolExp>;
  subindustryId?: InputMaybe<UuidComparisonExp>;
  symbol?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  wallets?: InputMaybe<WalletBoolExp>;
  walletsAggregate?: InputMaybe<WalletAggregateBoolExp>;
};

/** unique or primary key constraints on table "currency" */
export enum CurrencyConstraint {
  /** unique or primary key constraint on columns "id" */
  CurrencyPkey = 'currency_pkey',
  /** unique or primary key constraint on columns "id" */
  CurrencyXidKey = 'currency_xid_key',
}

/** input type for incrementing numeric columns in table "currency" */
export type CurrencyIncInput = {
  authorized?: InputMaybe<Scalars['bigint']>;
  issued?: InputMaybe<Scalars['bigint']>;
  reserved?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "currency" */
export type CurrencyInsertInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  asks?: InputMaybe<AskArrRelInsertInput>;
  authorized?: InputMaybe<Scalars['bigint']>;
  cc?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryObjRelInsertInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyKindId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  exchanges?: InputMaybe<ExchangeArrRelInsertInput>;
  favoriteCurrencies?: InputMaybe<FavoriteCommentArrRelInsertInput>;
  gicsSector?: InputMaybe<GicsSectorObjRelInsertInput>;
  group?: InputMaybe<GroupObjRelInsertInput>;
  groupsId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  interactions?: InputMaybe<InteractionArrRelInsertInput>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  issued?: InputMaybe<Scalars['bigint']>;
  kind?: InputMaybe<CurrencyKindObjRelInsertInput>;
  merchantCurrencies?: InputMaybe<MerchantCurrencyArrRelInsertInput>;
  minorUnit?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numericCode?: InputMaybe<Scalars['String']>;
  owners?: InputMaybe<CurrencyOwnerArrRelInsertInput>;
  payments?: InputMaybe<PaymentArrRelInsertInput>;
  puts?: InputMaybe<PutArrRelInsertInput>;
  reserved?: InputMaybe<Scalars['bigint']>;
  sectorId?: InputMaybe<Scalars['uuid']>;
  subindustry?: InputMaybe<SubindustryObjRelInsertInput>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  symbol?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  wallets?: InputMaybe<WalletArrRelInsertInput>;
};

/** columns and relationships of "currency_kind" */
export type CurrencyKind = {
  __typename?: 'CurrencyKind';
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  currencies: Array<Currency>;
  /** An aggregate relationship */
  currenciesAggregate: CurrencyAggregate;
  description: Scalars['String'];
  /** An object relationship */
  gicsSector?: Maybe<GicsSector>;
  gicsSectorId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  group?: Maybe<Group>;
  groupId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  industry?: Maybe<Industry>;
  industryId?: Maybe<Scalars['uuid']>;
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  subindustry?: Maybe<Subindustry>;
  subindustryId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "currency_kind" */
export type CurrencyKindCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<CurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOrderBy>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

/** columns and relationships of "currency_kind" */
export type CurrencyKindCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOrderBy>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

/** aggregated selection of "currency_kind" */
export type CurrencyKindAggregate = {
  __typename?: 'CurrencyKindAggregate';
  aggregate?: Maybe<CurrencyKindAggregateFields>;
  nodes: Array<CurrencyKind>;
};

export type CurrencyKindAggregateBoolExp = {
  bool_and?: InputMaybe<CurrencyKindAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<CurrencyKindAggregateBoolExpBool_Or>;
  count?: InputMaybe<CurrencyKindAggregateBoolExpCount>;
};

/** aggregate fields of "currency_kind" */
export type CurrencyKindAggregateFields = {
  __typename?: 'CurrencyKindAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CurrencyKindMaxFields>;
  min?: Maybe<CurrencyKindMinFields>;
};

/** aggregate fields of "currency_kind" */
export type CurrencyKindAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CurrencyKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "currency_kind" */
export type CurrencyKindAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CurrencyKindMaxOrderBy>;
  min?: InputMaybe<CurrencyKindMinOrderBy>;
};

/** input type for inserting array relation for remote table "currency_kind" */
export type CurrencyKindArrRelInsertInput = {
  data: Array<CurrencyKindInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CurrencyKindOnConflict>;
};

/** Boolean expression to filter rows from the table "currency_kind". All fields are combined with a logical 'AND'. */
export type CurrencyKindBoolExp = {
  _and?: InputMaybe<Array<CurrencyKindBoolExp>>;
  _not?: InputMaybe<CurrencyKindBoolExp>;
  _or?: InputMaybe<Array<CurrencyKindBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currencies?: InputMaybe<CurrencyBoolExp>;
  currenciesAggregate?: InputMaybe<CurrencyAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  gicsSector?: InputMaybe<GicsSectorBoolExp>;
  gicsSectorId?: InputMaybe<UuidComparisonExp>;
  group?: InputMaybe<GroupBoolExp>;
  groupId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  industry?: InputMaybe<IndustryBoolExp>;
  industryId?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  subindustry?: InputMaybe<SubindustryBoolExp>;
  subindustryId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "currency_kind" */
export enum CurrencyKindConstraint {
  /** unique or primary key constraint on columns "id" */
  CurrencyKindPkey = 'currency_kind_pkey',
  /** unique or primary key constraint on columns "id" */
  CurrencyKindXidKey = 'currency_kind_xid_key',
}

/** input type for inserting data into table "currency_kind" */
export type CurrencyKindInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencies?: InputMaybe<CurrencyArrRelInsertInput>;
  description?: InputMaybe<Scalars['String']>;
  gicsSector?: InputMaybe<GicsSectorObjRelInsertInput>;
  gicsSectorId?: InputMaybe<Scalars['uuid']>;
  group?: InputMaybe<GroupObjRelInsertInput>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industry?: InputMaybe<IndustryObjRelInsertInput>;
  industryId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  subindustry?: InputMaybe<SubindustryObjRelInsertInput>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CurrencyKindMaxFields = {
  __typename?: 'CurrencyKindMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  gicsSectorId?: Maybe<Scalars['uuid']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  industryId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subindustryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "currency_kind" */
export type CurrencyKindMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  gicsSectorId?: InputMaybe<OrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  industryId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CurrencyKindMinFields = {
  __typename?: 'CurrencyKindMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  gicsSectorId?: Maybe<Scalars['uuid']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  industryId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subindustryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "currency_kind" */
export type CurrencyKindMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  gicsSectorId?: InputMaybe<OrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  industryId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "currency_kind" */
export type CurrencyKindMutationResponse = {
  __typename?: 'CurrencyKindMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CurrencyKind>;
};

/** input type for inserting object relation for remote table "currency_kind" */
export type CurrencyKindObjRelInsertInput = {
  data: CurrencyKindInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CurrencyKindOnConflict>;
};

/** on_conflict condition type for table "currency_kind" */
export type CurrencyKindOnConflict = {
  constraint: CurrencyKindConstraint;
  updateColumns?: Array<CurrencyKindUpdateColumn>;
  where?: InputMaybe<CurrencyKindBoolExp>;
};

/** Ordering options when selecting data from "currency_kind". */
export type CurrencyKindOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currenciesAggregate?: InputMaybe<CurrencyAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  gicsSector?: InputMaybe<GicsSectorOrderBy>;
  gicsSectorId?: InputMaybe<OrderBy>;
  group?: InputMaybe<GroupOrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  industry?: InputMaybe<IndustryOrderBy>;
  industryId?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  subindustry?: InputMaybe<SubindustryOrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: currency_kind */
export type CurrencyKindPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "currency_kind" */
export enum CurrencyKindSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  GicsSectorId = 'gicsSectorId',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  IndustryId = 'industryId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SubindustryId = 'subindustryId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "currencyKindAggregateBoolExpBool_andArgumentsColumns" columns of table "currency_kind" */
export enum CurrencyKindSelectColumnCurrencyKindAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "currencyKindAggregateBoolExpBool_orArgumentsColumns" columns of table "currency_kind" */
export enum CurrencyKindSelectColumnCurrencyKindAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "currency_kind" */
export type CurrencyKindSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  gicsSectorId?: InputMaybe<Scalars['uuid']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industryId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "currency_kind" */
export type CurrencyKindStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CurrencyKindStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CurrencyKindStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  gicsSectorId?: InputMaybe<Scalars['uuid']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industryId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "currency_kind" */
export enum CurrencyKindUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  GicsSectorId = 'gicsSectorId',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  IndustryId = 'industryId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SubindustryId = 'subindustryId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type CurrencyKindUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CurrencyKindSetInput>;
  /** filter the rows which have to be updated */
  where: CurrencyKindBoolExp;
};

/** aggregate max on columns */
export type CurrencyMaxFields = {
  __typename?: 'CurrencyMaxFields';
  abbreviation?: Maybe<Scalars['String']>;
  authorized?: Maybe<Scalars['bigint']>;
  cc?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyKindId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  groupsId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  issued?: Maybe<Scalars['bigint']>;
  minorUnit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numericCode?: Maybe<Scalars['String']>;
  reserved?: Maybe<Scalars['bigint']>;
  sectorId?: Maybe<Scalars['uuid']>;
  subindustryId?: Maybe<Scalars['uuid']>;
  symbol?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "currency" */
export type CurrencyMaxOrderBy = {
  abbreviation?: InputMaybe<OrderBy>;
  authorized?: InputMaybe<OrderBy>;
  cc?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyKindId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  groupsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  issued?: InputMaybe<OrderBy>;
  minorUnit?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  numericCode?: InputMaybe<OrderBy>;
  reserved?: InputMaybe<OrderBy>;
  sectorId?: InputMaybe<OrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CurrencyMinFields = {
  __typename?: 'CurrencyMinFields';
  abbreviation?: Maybe<Scalars['String']>;
  authorized?: Maybe<Scalars['bigint']>;
  cc?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyKindId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  groupsId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  issued?: Maybe<Scalars['bigint']>;
  minorUnit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numericCode?: Maybe<Scalars['String']>;
  reserved?: Maybe<Scalars['bigint']>;
  sectorId?: Maybe<Scalars['uuid']>;
  subindustryId?: Maybe<Scalars['uuid']>;
  symbol?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "currency" */
export type CurrencyMinOrderBy = {
  abbreviation?: InputMaybe<OrderBy>;
  authorized?: InputMaybe<OrderBy>;
  cc?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyKindId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  groupsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  issued?: InputMaybe<OrderBy>;
  minorUnit?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  numericCode?: InputMaybe<OrderBy>;
  reserved?: InputMaybe<OrderBy>;
  sectorId?: InputMaybe<OrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "currency" */
export type CurrencyMutationResponse = {
  __typename?: 'CurrencyMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Currency>;
};

/** input type for inserting object relation for remote table "currency" */
export type CurrencyObjRelInsertInput = {
  data: CurrencyInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CurrencyOnConflict>;
};

/** on_conflict condition type for table "currency" */
export type CurrencyOnConflict = {
  constraint: CurrencyConstraint;
  updateColumns?: Array<CurrencyUpdateColumn>;
  where?: InputMaybe<CurrencyBoolExp>;
};

/** Ordering options when selecting data from "currency". */
export type CurrencyOrderBy = {
  abbreviation?: InputMaybe<OrderBy>;
  asksAggregate?: InputMaybe<AskAggregateOrderBy>;
  authorized?: InputMaybe<OrderBy>;
  cc?: InputMaybe<OrderBy>;
  country?: InputMaybe<CountryOrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyKindId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  exchangesAggregate?: InputMaybe<ExchangeAggregateOrderBy>;
  favoriteCurrenciesAggregate?: InputMaybe<FavoriteCommentAggregateOrderBy>;
  gicsSector?: InputMaybe<GicsSectorOrderBy>;
  group?: InputMaybe<GroupOrderBy>;
  groupsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  interactionsAggregate?: InputMaybe<InteractionAggregateOrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  issued?: InputMaybe<OrderBy>;
  kind?: InputMaybe<CurrencyKindOrderBy>;
  merchantCurrenciesAggregate?: InputMaybe<MerchantCurrencyAggregateOrderBy>;
  minorUnit?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  numericCode?: InputMaybe<OrderBy>;
  ownersAggregate?: InputMaybe<CurrencyOwnerAggregateOrderBy>;
  paymentsAggregate?: InputMaybe<PaymentAggregateOrderBy>;
  putsAggregate?: InputMaybe<PutAggregateOrderBy>;
  reserved?: InputMaybe<OrderBy>;
  sectorId?: InputMaybe<OrderBy>;
  subindustry?: InputMaybe<SubindustryOrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  walletsAggregate?: InputMaybe<WalletAggregateOrderBy>;
};

/** columns and relationships of "currency_owner" */
export type CurrencyOwner = {
  __typename?: 'CurrencyOwner';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  currency: Currency;
  currencyId: Scalars['uuid'];
  dateAuthorized: Scalars['timestamptz'];
  dateIssued: Scalars['timestamptz'];
  grantingOrganizationId: Scalars['uuid'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  /** An object relationship */
  organizationByGrantingOrganizationId: Organization;
  /** An object relationship */
  organizationByOriginalOrganizationId?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['uuid']>;
  originalOrganizationId?: Maybe<Scalars['uuid']>;
  ownerTransfer: Scalars['Boolean'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "currency_owner" */
export type CurrencyOwnerAggregate = {
  __typename?: 'CurrencyOwnerAggregate';
  aggregate?: Maybe<CurrencyOwnerAggregateFields>;
  nodes: Array<CurrencyOwner>;
};

export type CurrencyOwnerAggregateBoolExp = {
  bool_and?: InputMaybe<CurrencyOwnerAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<CurrencyOwnerAggregateBoolExpBool_Or>;
  count?: InputMaybe<CurrencyOwnerAggregateBoolExpCount>;
};

/** aggregate fields of "currency_owner" */
export type CurrencyOwnerAggregateFields = {
  __typename?: 'CurrencyOwnerAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CurrencyOwnerMaxFields>;
  min?: Maybe<CurrencyOwnerMinFields>;
};

/** aggregate fields of "currency_owner" */
export type CurrencyOwnerAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CurrencyOwnerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "currency_owner" */
export type CurrencyOwnerAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CurrencyOwnerMaxOrderBy>;
  min?: InputMaybe<CurrencyOwnerMinOrderBy>;
};

/** input type for inserting array relation for remote table "currency_owner" */
export type CurrencyOwnerArrRelInsertInput = {
  data: Array<CurrencyOwnerInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CurrencyOwnerOnConflict>;
};

/** Boolean expression to filter rows from the table "currency_owner". All fields are combined with a logical 'AND'. */
export type CurrencyOwnerBoolExp = {
  _and?: InputMaybe<Array<CurrencyOwnerBoolExp>>;
  _not?: InputMaybe<CurrencyOwnerBoolExp>;
  _or?: InputMaybe<Array<CurrencyOwnerBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrencyBoolExp>;
  currencyId?: InputMaybe<UuidComparisonExp>;
  dateAuthorized?: InputMaybe<TimestamptzComparisonExp>;
  dateIssued?: InputMaybe<TimestamptzComparisonExp>;
  grantingOrganizationId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationByGrantingOrganizationId?: InputMaybe<OrganizationBoolExp>;
  organizationByOriginalOrganizationId?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  originalOrganizationId?: InputMaybe<UuidComparisonExp>;
  ownerTransfer?: InputMaybe<BooleanComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "currency_owner" */
export enum CurrencyOwnerConstraint {
  /** unique or primary key constraint on columns "id" */
  CurrencyOwnerPkey = 'currency_owner_pkey',
  /** unique or primary key constraint on columns "id" */
  CurrencyOwnerXidKey = 'currency_owner_xid_key',
}

/** input type for inserting data into table "currency_owner" */
export type CurrencyOwnerInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<CurrencyObjRelInsertInput>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  dateAuthorized?: InputMaybe<Scalars['timestamptz']>;
  dateIssued?: InputMaybe<Scalars['timestamptz']>;
  grantingOrganizationId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationByGrantingOrganizationId?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationByOriginalOrganizationId?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  originalOrganizationId?: InputMaybe<Scalars['uuid']>;
  ownerTransfer?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CurrencyOwnerMaxFields = {
  __typename?: 'CurrencyOwnerMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  dateAuthorized?: Maybe<Scalars['timestamptz']>;
  dateIssued?: Maybe<Scalars['timestamptz']>;
  grantingOrganizationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  originalOrganizationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "currency_owner" */
export type CurrencyOwnerMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  dateAuthorized?: InputMaybe<OrderBy>;
  dateIssued?: InputMaybe<OrderBy>;
  grantingOrganizationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  originalOrganizationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CurrencyOwnerMinFields = {
  __typename?: 'CurrencyOwnerMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  dateAuthorized?: Maybe<Scalars['timestamptz']>;
  dateIssued?: Maybe<Scalars['timestamptz']>;
  grantingOrganizationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  originalOrganizationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "currency_owner" */
export type CurrencyOwnerMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  dateAuthorized?: InputMaybe<OrderBy>;
  dateIssued?: InputMaybe<OrderBy>;
  grantingOrganizationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  originalOrganizationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "currency_owner" */
export type CurrencyOwnerMutationResponse = {
  __typename?: 'CurrencyOwnerMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CurrencyOwner>;
};

/** on_conflict condition type for table "currency_owner" */
export type CurrencyOwnerOnConflict = {
  constraint: CurrencyOwnerConstraint;
  updateColumns?: Array<CurrencyOwnerUpdateColumn>;
  where?: InputMaybe<CurrencyOwnerBoolExp>;
};

/** Ordering options when selecting data from "currency_owner". */
export type CurrencyOwnerOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrencyOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  dateAuthorized?: InputMaybe<OrderBy>;
  dateIssued?: InputMaybe<OrderBy>;
  grantingOrganizationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationByGrantingOrganizationId?: InputMaybe<OrganizationOrderBy>;
  organizationByOriginalOrganizationId?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  originalOrganizationId?: InputMaybe<OrderBy>;
  ownerTransfer?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: currency_owner */
export type CurrencyOwnerPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "currency_owner" */
export enum CurrencyOwnerSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  DateAuthorized = 'dateAuthorized',
  /** column name */
  DateIssued = 'dateIssued',
  /** column name */
  GrantingOrganizationId = 'grantingOrganizationId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  OriginalOrganizationId = 'originalOrganizationId',
  /** column name */
  OwnerTransfer = 'ownerTransfer',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "currencyOwnerAggregateBoolExpBool_andArgumentsColumns" columns of table "currency_owner" */
export enum CurrencyOwnerSelectColumnCurrencyOwnerAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  OwnerTransfer = 'ownerTransfer',
}

/** select "currencyOwnerAggregateBoolExpBool_orArgumentsColumns" columns of table "currency_owner" */
export enum CurrencyOwnerSelectColumnCurrencyOwnerAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  OwnerTransfer = 'ownerTransfer',
}

/** input type for updating data in table "currency_owner" */
export type CurrencyOwnerSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  dateAuthorized?: InputMaybe<Scalars['timestamptz']>;
  dateIssued?: InputMaybe<Scalars['timestamptz']>;
  grantingOrganizationId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  originalOrganizationId?: InputMaybe<Scalars['uuid']>;
  ownerTransfer?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "currency_owner" */
export type CurrencyOwnerStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CurrencyOwnerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CurrencyOwnerStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  dateAuthorized?: InputMaybe<Scalars['timestamptz']>;
  dateIssued?: InputMaybe<Scalars['timestamptz']>;
  grantingOrganizationId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  originalOrganizationId?: InputMaybe<Scalars['uuid']>;
  ownerTransfer?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "currency_owner" */
export enum CurrencyOwnerUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  DateAuthorized = 'dateAuthorized',
  /** column name */
  DateIssued = 'dateIssued',
  /** column name */
  GrantingOrganizationId = 'grantingOrganizationId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  OriginalOrganizationId = 'originalOrganizationId',
  /** column name */
  OwnerTransfer = 'ownerTransfer',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type CurrencyOwnerUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CurrencyOwnerSetInput>;
  /** filter the rows which have to be updated */
  where: CurrencyOwnerBoolExp;
};

/** primary key columns input for table: currency */
export type CurrencyPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "currency" */
export enum CurrencySelectColumn {
  /** column name */
  Abbreviation = 'abbreviation',
  /** column name */
  Authorized = 'authorized',
  /** column name */
  Cc = 'cc',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyKindId = 'currencyKindId',
  /** column name */
  Description = 'description',
  /** column name */
  GroupsId = 'groupsId',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Issued = 'issued',
  /** column name */
  MinorUnit = 'minorUnit',
  /** column name */
  Name = 'name',
  /** column name */
  NumericCode = 'numericCode',
  /** column name */
  Reserved = 'reserved',
  /** column name */
  SectorId = 'sectorId',
  /** column name */
  SubindustryId = 'subindustryId',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "currencyAggregateBoolExpBool_andArgumentsColumns" columns of table "currency" */
export enum CurrencySelectColumnCurrencyAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "currencyAggregateBoolExpBool_orArgumentsColumns" columns of table "currency" */
export enum CurrencySelectColumnCurrencyAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "currency" */
export type CurrencySetInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  authorized?: InputMaybe<Scalars['bigint']>;
  cc?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyKindId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  groupsId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  issued?: InputMaybe<Scalars['bigint']>;
  minorUnit?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numericCode?: InputMaybe<Scalars['String']>;
  reserved?: InputMaybe<Scalars['bigint']>;
  sectorId?: InputMaybe<Scalars['uuid']>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  symbol?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CurrencyStddevFields = {
  __typename?: 'CurrencyStddevFields';
  authorized?: Maybe<Scalars['Float']>;
  issued?: Maybe<Scalars['Float']>;
  reserved?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "currency" */
export type CurrencyStddevOrderBy = {
  authorized?: InputMaybe<OrderBy>;
  issued?: InputMaybe<OrderBy>;
  reserved?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CurrencyStddevPopFields = {
  __typename?: 'CurrencyStddevPopFields';
  authorized?: Maybe<Scalars['Float']>;
  issued?: Maybe<Scalars['Float']>;
  reserved?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "currency" */
export type CurrencyStddevPopOrderBy = {
  authorized?: InputMaybe<OrderBy>;
  issued?: InputMaybe<OrderBy>;
  reserved?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CurrencyStddevSampFields = {
  __typename?: 'CurrencyStddevSampFields';
  authorized?: Maybe<Scalars['Float']>;
  issued?: Maybe<Scalars['Float']>;
  reserved?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "currency" */
export type CurrencyStddevSampOrderBy = {
  authorized?: InputMaybe<OrderBy>;
  issued?: InputMaybe<OrderBy>;
  reserved?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "currency" */
export type CurrencyStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CurrencyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CurrencyStreamCursorValueInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  authorized?: InputMaybe<Scalars['bigint']>;
  cc?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyKindId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  groupsId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  issued?: InputMaybe<Scalars['bigint']>;
  minorUnit?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numericCode?: InputMaybe<Scalars['String']>;
  reserved?: InputMaybe<Scalars['bigint']>;
  sectorId?: InputMaybe<Scalars['uuid']>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  symbol?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type CurrencySumFields = {
  __typename?: 'CurrencySumFields';
  authorized?: Maybe<Scalars['bigint']>;
  issued?: Maybe<Scalars['bigint']>;
  reserved?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "currency" */
export type CurrencySumOrderBy = {
  authorized?: InputMaybe<OrderBy>;
  issued?: InputMaybe<OrderBy>;
  reserved?: InputMaybe<OrderBy>;
};

/** update columns of table "currency" */
export enum CurrencyUpdateColumn {
  /** column name */
  Abbreviation = 'abbreviation',
  /** column name */
  Authorized = 'authorized',
  /** column name */
  Cc = 'cc',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyKindId = 'currencyKindId',
  /** column name */
  Description = 'description',
  /** column name */
  GroupsId = 'groupsId',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Issued = 'issued',
  /** column name */
  MinorUnit = 'minorUnit',
  /** column name */
  Name = 'name',
  /** column name */
  NumericCode = 'numericCode',
  /** column name */
  Reserved = 'reserved',
  /** column name */
  SectorId = 'sectorId',
  /** column name */
  SubindustryId = 'subindustryId',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type CurrencyUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CurrencyIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CurrencySetInput>;
  /** filter the rows which have to be updated */
  where: CurrencyBoolExp;
};

/** aggregate varPop on columns */
export type CurrencyVarPopFields = {
  __typename?: 'CurrencyVarPopFields';
  authorized?: Maybe<Scalars['Float']>;
  issued?: Maybe<Scalars['Float']>;
  reserved?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "currency" */
export type CurrencyVarPopOrderBy = {
  authorized?: InputMaybe<OrderBy>;
  issued?: InputMaybe<OrderBy>;
  reserved?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CurrencyVarSampFields = {
  __typename?: 'CurrencyVarSampFields';
  authorized?: Maybe<Scalars['Float']>;
  issued?: Maybe<Scalars['Float']>;
  reserved?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "currency" */
export type CurrencyVarSampOrderBy = {
  authorized?: InputMaybe<OrderBy>;
  issued?: InputMaybe<OrderBy>;
  reserved?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CurrencyVarianceFields = {
  __typename?: 'CurrencyVarianceFields';
  authorized?: Maybe<Scalars['Float']>;
  issued?: Maybe<Scalars['Float']>;
  reserved?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "currency" */
export type CurrencyVarianceOrderBy = {
  authorized?: InputMaybe<OrderBy>;
  issued?: InputMaybe<OrderBy>;
  reserved?: InputMaybe<OrderBy>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** columns and relationships of "datasource" */
export type Datasource = {
  __typename?: 'Datasource';
  /** An object relationship */
  authorizedUser?: Maybe<User>;
  authorizedUserId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  governance?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['uuid']>;
  pedigree?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "datasource" */
export type DatasourceAggregate = {
  __typename?: 'DatasourceAggregate';
  aggregate?: Maybe<DatasourceAggregateFields>;
  nodes: Array<Datasource>;
};

/** aggregate fields of "datasource" */
export type DatasourceAggregateFields = {
  __typename?: 'DatasourceAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DatasourceMaxFields>;
  min?: Maybe<DatasourceMinFields>;
};

/** aggregate fields of "datasource" */
export type DatasourceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DatasourceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "datasource". All fields are combined with a logical 'AND'. */
export type DatasourceBoolExp = {
  _and?: InputMaybe<Array<DatasourceBoolExp>>;
  _not?: InputMaybe<DatasourceBoolExp>;
  _or?: InputMaybe<Array<DatasourceBoolExp>>;
  authorizedUser?: InputMaybe<UserBoolExp>;
  authorizedUserId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  governance?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  location?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  pedigree?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "datasource" */
export enum DatasourceConstraint {
  /** unique or primary key constraint on columns "id" */
  DatasourncePkey = 'datasournce_pkey',
}

/** input type for inserting data into table "datasource" */
export type DatasourceInsertInput = {
  authorizedUser?: InputMaybe<UserObjRelInsertInput>;
  authorizedUserId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  governance?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  pedigree?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DatasourceMaxFields = {
  __typename?: 'DatasourceMaxFields';
  authorizedUserId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  governance?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  pedigree?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type DatasourceMinFields = {
  __typename?: 'DatasourceMinFields';
  authorizedUserId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  governance?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  pedigree?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "datasource" */
export type DatasourceMutationResponse = {
  __typename?: 'DatasourceMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Datasource>;
};

/** input type for inserting object relation for remote table "datasource" */
export type DatasourceObjRelInsertInput = {
  data: DatasourceInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<DatasourceOnConflict>;
};

/** on_conflict condition type for table "datasource" */
export type DatasourceOnConflict = {
  constraint: DatasourceConstraint;
  updateColumns?: Array<DatasourceUpdateColumn>;
  where?: InputMaybe<DatasourceBoolExp>;
};

/** Ordering options when selecting data from "datasource". */
export type DatasourceOrderBy = {
  authorizedUser?: InputMaybe<UserOrderBy>;
  authorizedUserId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  governance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  pedigree?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: datasource */
export type DatasourcePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "datasource" */
export enum DatasourceSelectColumn {
  /** column name */
  AuthorizedUserId = 'authorizedUserId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Governance = 'governance',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Pedigree = 'pedigree',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "datasource" */
export type DatasourceSetInput = {
  authorizedUserId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  governance?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  pedigree?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "datasource" */
export type DatasourceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DatasourceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DatasourceStreamCursorValueInput = {
  authorizedUserId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  governance?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  pedigree?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "datasource" */
export enum DatasourceUpdateColumn {
  /** column name */
  AuthorizedUserId = 'authorizedUserId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Governance = 'governance',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Pedigree = 'pedigree',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type DatasourceUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DatasourceSetInput>;
  /** filter the rows which have to be updated */
  where: DatasourceBoolExp;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "document_user" */
export type DocumentUser = {
  __typename?: 'DocumentUser';
  createdAt: Scalars['timestamptz'];
  documentLocation?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  smartContractDocument?: Maybe<SmartContractDocument>;
  smartContractDocumentId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  userId: Scalars['uuid'];
  /** An object relationship */
  wallet?: Maybe<Wallet>;
  walletId: Scalars['uuid'];
};

/** aggregated selection of "document_user" */
export type DocumentUserAggregate = {
  __typename?: 'DocumentUserAggregate';
  aggregate?: Maybe<DocumentUserAggregateFields>;
  nodes: Array<DocumentUser>;
};

/** aggregate fields of "document_user" */
export type DocumentUserAggregateFields = {
  __typename?: 'DocumentUserAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DocumentUserMaxFields>;
  min?: Maybe<DocumentUserMinFields>;
};

/** aggregate fields of "document_user" */
export type DocumentUserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "document_user". All fields are combined with a logical 'AND'. */
export type DocumentUserBoolExp = {
  _and?: InputMaybe<Array<DocumentUserBoolExp>>;
  _not?: InputMaybe<DocumentUserBoolExp>;
  _or?: InputMaybe<Array<DocumentUserBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  documentLocation?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  smartContractDocument?: InputMaybe<SmartContractDocumentBoolExp>;
  smartContractDocumentId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
  wallet?: InputMaybe<WalletBoolExp>;
  walletId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "document_user" */
export enum DocumentUserConstraint {
  /** unique or primary key constraint on columns "id" */
  DocumentUserPkey = 'document_user_pkey',
}

/** input type for inserting data into table "document_user" */
export type DocumentUserInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documentLocation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  smartContractDocument?: InputMaybe<SmartContractDocumentObjRelInsertInput>;
  smartContractDocumentId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
  wallet?: InputMaybe<WalletObjRelInsertInput>;
  walletId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type DocumentUserMaxFields = {
  __typename?: 'DocumentUserMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  documentLocation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  smartContractDocumentId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  walletId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type DocumentUserMinFields = {
  __typename?: 'DocumentUserMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  documentLocation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  smartContractDocumentId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  walletId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "document_user" */
export type DocumentUserMutationResponse = {
  __typename?: 'DocumentUserMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentUser>;
};

/** input type for inserting object relation for remote table "document_user" */
export type DocumentUserObjRelInsertInput = {
  data: DocumentUserInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<DocumentUserOnConflict>;
};

/** on_conflict condition type for table "document_user" */
export type DocumentUserOnConflict = {
  constraint: DocumentUserConstraint;
  updateColumns?: Array<DocumentUserUpdateColumn>;
  where?: InputMaybe<DocumentUserBoolExp>;
};

/** Ordering options when selecting data from "document_user". */
export type DocumentUserOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  documentLocation?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  smartContractDocument?: InputMaybe<SmartContractDocumentOrderBy>;
  smartContractDocumentId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  wallet?: InputMaybe<WalletOrderBy>;
  walletId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: document_user */
export type DocumentUserPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "document_user" */
export enum DocumentUserSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DocumentLocation = 'documentLocation',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  SmartContractDocumentId = 'smartContractDocumentId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WalletId = 'walletId',
}

/** input type for updating data in table "document_user" */
export type DocumentUserSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documentLocation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  smartContractDocumentId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  walletId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "document_user" */
export type DocumentUserStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DocumentUserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentUserStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documentLocation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  smartContractDocumentId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  walletId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "document_user" */
export enum DocumentUserUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DocumentLocation = 'documentLocation',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  SmartContractDocumentId = 'smartContractDocumentId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WalletId = 'walletId',
}

export type DocumentUserUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentUserSetInput>;
  /** filter the rows which have to be updated */
  where: DocumentUserBoolExp;
};

/** columns and relationships of "exchange" */
export type Exchange = {
  __typename?: 'Exchange';
  amount: Scalars['bigint'];
  /** An array relationship */
  asks: Array<Ask>;
  /** An aggregate relationship */
  asksAggregate: AskAggregate;
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  exchangeKindId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  kind?: Maybe<ExchangeKind>;
  name: Scalars['String'];
  /** An object relationship */
  swapCurrency: Currency;
  swapCurrencyId: Scalars['uuid'];
  /** An object relationship */
  tradeKind: TradeKind;
  tradeKindId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** columns and relationships of "exchange" */
export type ExchangeAsksArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

/** columns and relationships of "exchange" */
export type ExchangeAsksAggregateArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

/** aggregated selection of "exchange" */
export type ExchangeAggregate = {
  __typename?: 'ExchangeAggregate';
  aggregate?: Maybe<ExchangeAggregateFields>;
  nodes: Array<Exchange>;
};

export type ExchangeAggregateBoolExp = {
  bool_and?: InputMaybe<ExchangeAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ExchangeAggregateBoolExpBool_Or>;
  count?: InputMaybe<ExchangeAggregateBoolExpCount>;
};

/** aggregate fields of "exchange" */
export type ExchangeAggregateFields = {
  __typename?: 'ExchangeAggregateFields';
  avg?: Maybe<ExchangeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ExchangeMaxFields>;
  min?: Maybe<ExchangeMinFields>;
  stddev?: Maybe<ExchangeStddevFields>;
  stddevPop?: Maybe<ExchangeStddevPopFields>;
  stddevSamp?: Maybe<ExchangeStddevSampFields>;
  sum?: Maybe<ExchangeSumFields>;
  varPop?: Maybe<ExchangeVarPopFields>;
  varSamp?: Maybe<ExchangeVarSampFields>;
  variance?: Maybe<ExchangeVarianceFields>;
};

/** aggregate fields of "exchange" */
export type ExchangeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ExchangeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "exchange" */
export type ExchangeAggregateOrderBy = {
  avg?: InputMaybe<ExchangeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ExchangeMaxOrderBy>;
  min?: InputMaybe<ExchangeMinOrderBy>;
  stddev?: InputMaybe<ExchangeStddevOrderBy>;
  stddevPop?: InputMaybe<ExchangeStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ExchangeStddevSampOrderBy>;
  sum?: InputMaybe<ExchangeSumOrderBy>;
  varPop?: InputMaybe<ExchangeVarPopOrderBy>;
  varSamp?: InputMaybe<ExchangeVarSampOrderBy>;
  variance?: InputMaybe<ExchangeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "exchange" */
export type ExchangeArrRelInsertInput = {
  data: Array<ExchangeInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ExchangeOnConflict>;
};

/** aggregate avg on columns */
export type ExchangeAvgFields = {
  __typename?: 'ExchangeAvgFields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "exchange" */
export type ExchangeAvgOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "exchange". All fields are combined with a logical 'AND'. */
export type ExchangeBoolExp = {
  _and?: InputMaybe<Array<ExchangeBoolExp>>;
  _not?: InputMaybe<ExchangeBoolExp>;
  _or?: InputMaybe<Array<ExchangeBoolExp>>;
  amount?: InputMaybe<BigintComparisonExp>;
  asks?: InputMaybe<AskBoolExp>;
  asksAggregate?: InputMaybe<AskAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  exchangeKindId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  kind?: InputMaybe<ExchangeKindBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  swapCurrency?: InputMaybe<CurrencyBoolExp>;
  swapCurrencyId?: InputMaybe<UuidComparisonExp>;
  tradeKind?: InputMaybe<TradeKindBoolExp>;
  tradeKindId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "exchange" */
export enum ExchangeConstraint {
  /** unique or primary key constraint on columns "id" */
  ExchangePkey = 'exchange_pkey',
  /** unique or primary key constraint on columns "id" */
  ExchangeXidKey = 'exchange_xid_key',
}

/** input type for incrementing numeric columns in table "exchange" */
export type ExchangeIncInput = {
  amount?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "exchange" */
export type ExchangeInsertInput = {
  amount?: InputMaybe<Scalars['bigint']>;
  asks?: InputMaybe<AskArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  exchangeKindId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<ExchangeKindObjRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  swapCurrency?: InputMaybe<CurrencyObjRelInsertInput>;
  swapCurrencyId?: InputMaybe<Scalars['uuid']>;
  tradeKind?: InputMaybe<TradeKindObjRelInsertInput>;
  tradeKindId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "exchange_kind" */
export type ExchangeKind = {
  __typename?: 'ExchangeKind';
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  exchanges: Array<Exchange>;
  /** An aggregate relationship */
  exchangesAggregate: ExchangeAggregate;
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "exchange_kind" */
export type ExchangeKindExchangesArgs = {
  distinctOn?: InputMaybe<Array<ExchangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeOrderBy>>;
  where?: InputMaybe<ExchangeBoolExp>;
};

/** columns and relationships of "exchange_kind" */
export type ExchangeKindExchangesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExchangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeOrderBy>>;
  where?: InputMaybe<ExchangeBoolExp>;
};

/** aggregated selection of "exchange_kind" */
export type ExchangeKindAggregate = {
  __typename?: 'ExchangeKindAggregate';
  aggregate?: Maybe<ExchangeKindAggregateFields>;
  nodes: Array<ExchangeKind>;
};

/** aggregate fields of "exchange_kind" */
export type ExchangeKindAggregateFields = {
  __typename?: 'ExchangeKindAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ExchangeKindMaxFields>;
  min?: Maybe<ExchangeKindMinFields>;
};

/** aggregate fields of "exchange_kind" */
export type ExchangeKindAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ExchangeKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "exchange_kind". All fields are combined with a logical 'AND'. */
export type ExchangeKindBoolExp = {
  _and?: InputMaybe<Array<ExchangeKindBoolExp>>;
  _not?: InputMaybe<ExchangeKindBoolExp>;
  _or?: InputMaybe<Array<ExchangeKindBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  exchanges?: InputMaybe<ExchangeBoolExp>;
  exchangesAggregate?: InputMaybe<ExchangeAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "exchange_kind" */
export enum ExchangeKindConstraint {
  /** unique or primary key constraint on columns "id" */
  ExchangeKindPkey = 'exchange_kind_pkey',
}

/** input type for inserting data into table "exchange_kind" */
export type ExchangeKindInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  exchanges?: InputMaybe<ExchangeArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ExchangeKindMaxFields = {
  __typename?: 'ExchangeKindMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ExchangeKindMinFields = {
  __typename?: 'ExchangeKindMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "exchange_kind" */
export type ExchangeKindMutationResponse = {
  __typename?: 'ExchangeKindMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExchangeKind>;
};

/** input type for inserting object relation for remote table "exchange_kind" */
export type ExchangeKindObjRelInsertInput = {
  data: ExchangeKindInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ExchangeKindOnConflict>;
};

/** on_conflict condition type for table "exchange_kind" */
export type ExchangeKindOnConflict = {
  constraint: ExchangeKindConstraint;
  updateColumns?: Array<ExchangeKindUpdateColumn>;
  where?: InputMaybe<ExchangeKindBoolExp>;
};

/** Ordering options when selecting data from "exchange_kind". */
export type ExchangeKindOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  exchangesAggregate?: InputMaybe<ExchangeAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: exchange_kind */
export type ExchangeKindPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "exchange_kind" */
export enum ExchangeKindSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "exchange_kind" */
export type ExchangeKindSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "exchange_kind" */
export type ExchangeKindStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ExchangeKindStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ExchangeKindStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "exchange_kind" */
export enum ExchangeKindUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type ExchangeKindUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExchangeKindSetInput>;
  /** filter the rows which have to be updated */
  where: ExchangeKindBoolExp;
};

/** aggregate max on columns */
export type ExchangeMaxFields = {
  __typename?: 'ExchangeMaxFields';
  amount?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  exchangeKindId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  swapCurrencyId?: Maybe<Scalars['uuid']>;
  tradeKindId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "exchange" */
export type ExchangeMaxOrderBy = {
  amount?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  exchangeKindId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  swapCurrencyId?: InputMaybe<OrderBy>;
  tradeKindId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ExchangeMinFields = {
  __typename?: 'ExchangeMinFields';
  amount?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  exchangeKindId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  swapCurrencyId?: Maybe<Scalars['uuid']>;
  tradeKindId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "exchange" */
export type ExchangeMinOrderBy = {
  amount?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  exchangeKindId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  swapCurrencyId?: InputMaybe<OrderBy>;
  tradeKindId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "exchange" */
export type ExchangeMutationResponse = {
  __typename?: 'ExchangeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Exchange>;
};

/** input type for inserting object relation for remote table "exchange" */
export type ExchangeObjRelInsertInput = {
  data: ExchangeInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ExchangeOnConflict>;
};

/** on_conflict condition type for table "exchange" */
export type ExchangeOnConflict = {
  constraint: ExchangeConstraint;
  updateColumns?: Array<ExchangeUpdateColumn>;
  where?: InputMaybe<ExchangeBoolExp>;
};

/** Ordering options when selecting data from "exchange". */
export type ExchangeOrderBy = {
  amount?: InputMaybe<OrderBy>;
  asksAggregate?: InputMaybe<AskAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  exchangeKindId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  kind?: InputMaybe<ExchangeKindOrderBy>;
  name?: InputMaybe<OrderBy>;
  swapCurrency?: InputMaybe<CurrencyOrderBy>;
  swapCurrencyId?: InputMaybe<OrderBy>;
  tradeKind?: InputMaybe<TradeKindOrderBy>;
  tradeKindId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: exchange */
export type ExchangePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "exchange" */
export enum ExchangeSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  ExchangeKindId = 'exchangeKindId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SwapCurrencyId = 'swapCurrencyId',
  /** column name */
  TradeKindId = 'tradeKindId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** select "exchangeAggregateBoolExpBool_andArgumentsColumns" columns of table "exchange" */
export enum ExchangeSelectColumnExchangeAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "exchangeAggregateBoolExpBool_orArgumentsColumns" columns of table "exchange" */
export enum ExchangeSelectColumnExchangeAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "exchange" */
export type ExchangeSetInput = {
  amount?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  exchangeKindId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  swapCurrencyId?: InputMaybe<Scalars['uuid']>;
  tradeKindId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ExchangeStddevFields = {
  __typename?: 'ExchangeStddevFields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "exchange" */
export type ExchangeStddevOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ExchangeStddevPopFields = {
  __typename?: 'ExchangeStddevPopFields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "exchange" */
export type ExchangeStddevPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ExchangeStddevSampFields = {
  __typename?: 'ExchangeStddevSampFields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "exchange" */
export type ExchangeStddevSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "exchange" */
export type ExchangeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ExchangeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ExchangeStreamCursorValueInput = {
  amount?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  exchangeKindId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  swapCurrencyId?: InputMaybe<Scalars['uuid']>;
  tradeKindId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ExchangeSumFields = {
  __typename?: 'ExchangeSumFields';
  amount?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "exchange" */
export type ExchangeSumOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** update columns of table "exchange" */
export enum ExchangeUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  ExchangeKindId = 'exchangeKindId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SwapCurrencyId = 'swapCurrencyId',
  /** column name */
  TradeKindId = 'tradeKindId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type ExchangeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ExchangeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExchangeSetInput>;
  /** filter the rows which have to be updated */
  where: ExchangeBoolExp;
};

/** aggregate varPop on columns */
export type ExchangeVarPopFields = {
  __typename?: 'ExchangeVarPopFields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "exchange" */
export type ExchangeVarPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ExchangeVarSampFields = {
  __typename?: 'ExchangeVarSampFields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "exchange" */
export type ExchangeVarSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ExchangeVarianceFields = {
  __typename?: 'ExchangeVarianceFields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "exchange" */
export type ExchangeVarianceOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** columns and relationships of "favorite" */
export type Favorite = {
  __typename?: 'Favorite';
  blog?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  favoriteCurrencies: Array<FavoriteComment>;
  /** An aggregate relationship */
  favoriteCurrenciesAggregate: FavoriteCommentAggregate;
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  wallet?: Maybe<Wallet>;
  walletId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "favorite" */
export type FavoriteFavoriteCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<FavoriteCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteCommentOrderBy>>;
  where?: InputMaybe<FavoriteCommentBoolExp>;
};

/** columns and relationships of "favorite" */
export type FavoriteFavoriteCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<FavoriteCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteCommentOrderBy>>;
  where?: InputMaybe<FavoriteCommentBoolExp>;
};

/** aggregated selection of "favorite" */
export type FavoriteAggregate = {
  __typename?: 'FavoriteAggregate';
  aggregate?: Maybe<FavoriteAggregateFields>;
  nodes: Array<Favorite>;
};

/** aggregate fields of "favorite" */
export type FavoriteAggregateFields = {
  __typename?: 'FavoriteAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<FavoriteMaxFields>;
  min?: Maybe<FavoriteMinFields>;
};

/** aggregate fields of "favorite" */
export type FavoriteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FavoriteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "favorite". All fields are combined with a logical 'AND'. */
export type FavoriteBoolExp = {
  _and?: InputMaybe<Array<FavoriteBoolExp>>;
  _not?: InputMaybe<FavoriteBoolExp>;
  _or?: InputMaybe<Array<FavoriteBoolExp>>;
  blog?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  favoriteCurrencies?: InputMaybe<FavoriteCommentBoolExp>;
  favoriteCurrenciesAggregate?: InputMaybe<FavoriteCommentAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  wallet?: InputMaybe<WalletBoolExp>;
  walletId?: InputMaybe<UuidComparisonExp>;
};

/** columns and relationships of "favorite_comment" */
export type FavoriteComment = {
  __typename?: 'FavoriteComment';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  currency: Currency;
  currencyId: Scalars['uuid'];
  exchangeId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  favorite: Favorite;
  favoriteId: Scalars['uuid'];
  id: Scalars['uuid'];
  imageUpload?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  merchantId?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "favorite_comment" */
export type FavoriteCommentAggregate = {
  __typename?: 'FavoriteCommentAggregate';
  aggregate?: Maybe<FavoriteCommentAggregateFields>;
  nodes: Array<FavoriteComment>;
};

export type FavoriteCommentAggregateBoolExp = {
  bool_and?: InputMaybe<FavoriteCommentAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<FavoriteCommentAggregateBoolExpBool_Or>;
  count?: InputMaybe<FavoriteCommentAggregateBoolExpCount>;
};

/** aggregate fields of "favorite_comment" */
export type FavoriteCommentAggregateFields = {
  __typename?: 'FavoriteCommentAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<FavoriteCommentMaxFields>;
  min?: Maybe<FavoriteCommentMinFields>;
};

/** aggregate fields of "favorite_comment" */
export type FavoriteCommentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FavoriteCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "favorite_comment" */
export type FavoriteCommentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FavoriteCommentMaxOrderBy>;
  min?: InputMaybe<FavoriteCommentMinOrderBy>;
};

/** input type for inserting array relation for remote table "favorite_comment" */
export type FavoriteCommentArrRelInsertInput = {
  data: Array<FavoriteCommentInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<FavoriteCommentOnConflict>;
};

/** Boolean expression to filter rows from the table "favorite_comment". All fields are combined with a logical 'AND'. */
export type FavoriteCommentBoolExp = {
  _and?: InputMaybe<Array<FavoriteCommentBoolExp>>;
  _not?: InputMaybe<FavoriteCommentBoolExp>;
  _or?: InputMaybe<Array<FavoriteCommentBoolExp>>;
  comment?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrencyBoolExp>;
  currencyId?: InputMaybe<UuidComparisonExp>;
  exchangeId?: InputMaybe<UuidComparisonExp>;
  favorite?: InputMaybe<FavoriteBoolExp>;
  favoriteId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageUpload?: InputMaybe<StringComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  productId?: InputMaybe<UuidComparisonExp>;
  smartContractId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "favorite_comment" */
export enum FavoriteCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  FavoriteCurrencyPkey = 'favorite_currency_pkey',
  /** unique or primary key constraint on columns "id" */
  FavoriteCurrencyXidKey = 'favorite_currency_xid_key',
}

/** input type for inserting data into table "favorite_comment" */
export type FavoriteCommentInsertInput = {
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<CurrencyObjRelInsertInput>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  exchangeId?: InputMaybe<Scalars['uuid']>;
  favorite?: InputMaybe<FavoriteObjRelInsertInput>;
  favoriteId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUpload?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  productId?: InputMaybe<Scalars['uuid']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type FavoriteCommentMaxFields = {
  __typename?: 'FavoriteCommentMaxFields';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  exchangeId?: Maybe<Scalars['uuid']>;
  favoriteId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  imageUpload?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "favorite_comment" */
export type FavoriteCommentMaxOrderBy = {
  comment?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  exchangeId?: InputMaybe<OrderBy>;
  favoriteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUpload?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FavoriteCommentMinFields = {
  __typename?: 'FavoriteCommentMinFields';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  exchangeId?: Maybe<Scalars['uuid']>;
  favoriteId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  imageUpload?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "favorite_comment" */
export type FavoriteCommentMinOrderBy = {
  comment?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  exchangeId?: InputMaybe<OrderBy>;
  favoriteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUpload?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "favorite_comment" */
export type FavoriteCommentMutationResponse = {
  __typename?: 'FavoriteCommentMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FavoriteComment>;
};

/** on_conflict condition type for table "favorite_comment" */
export type FavoriteCommentOnConflict = {
  constraint: FavoriteCommentConstraint;
  updateColumns?: Array<FavoriteCommentUpdateColumn>;
  where?: InputMaybe<FavoriteCommentBoolExp>;
};

/** Ordering options when selecting data from "favorite_comment". */
export type FavoriteCommentOrderBy = {
  comment?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrencyOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  exchangeId?: InputMaybe<OrderBy>;
  favorite?: InputMaybe<FavoriteOrderBy>;
  favoriteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUpload?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: favorite_comment */
export type FavoriteCommentPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "favorite_comment" */
export enum FavoriteCommentSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  ExchangeId = 'exchangeId',
  /** column name */
  FavoriteId = 'favoriteId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUpload = 'imageUpload',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "favoriteCommentAggregateBoolExpBool_andArgumentsColumns" columns of table "favorite_comment" */
export enum FavoriteCommentSelectColumnFavoriteCommentAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "favoriteCommentAggregateBoolExpBool_orArgumentsColumns" columns of table "favorite_comment" */
export enum FavoriteCommentSelectColumnFavoriteCommentAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "favorite_comment" */
export type FavoriteCommentSetInput = {
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  exchangeId?: InputMaybe<Scalars['uuid']>;
  favoriteId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUpload?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  productId?: InputMaybe<Scalars['uuid']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "favorite_comment" */
export type FavoriteCommentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FavoriteCommentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FavoriteCommentStreamCursorValueInput = {
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  exchangeId?: InputMaybe<Scalars['uuid']>;
  favoriteId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUpload?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  productId?: InputMaybe<Scalars['uuid']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "favorite_comment" */
export enum FavoriteCommentUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  ExchangeId = 'exchangeId',
  /** column name */
  FavoriteId = 'favoriteId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUpload = 'imageUpload',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type FavoriteCommentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FavoriteCommentSetInput>;
  /** filter the rows which have to be updated */
  where: FavoriteCommentBoolExp;
};

/** unique or primary key constraints on table "favorite" */
export enum FavoriteConstraint {
  /** unique or primary key constraint on columns "id" */
  FavoritePkey = 'favorite_pkey',
  /** unique or primary key constraint on columns "id" */
  FavoriteXidKey = 'favorite_xid_key',
}

/** input type for inserting data into table "favorite" */
export type FavoriteInsertInput = {
  blog?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  favoriteCurrencies?: InputMaybe<FavoriteCommentArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  wallet?: InputMaybe<WalletObjRelInsertInput>;
  walletId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type FavoriteMaxFields = {
  __typename?: 'FavoriteMaxFields';
  blog?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  walletId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type FavoriteMinFields = {
  __typename?: 'FavoriteMinFields';
  blog?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  walletId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "favorite" */
export type FavoriteMutationResponse = {
  __typename?: 'FavoriteMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Favorite>;
};

/** input type for inserting object relation for remote table "favorite" */
export type FavoriteObjRelInsertInput = {
  data: FavoriteInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<FavoriteOnConflict>;
};

/** on_conflict condition type for table "favorite" */
export type FavoriteOnConflict = {
  constraint: FavoriteConstraint;
  updateColumns?: Array<FavoriteUpdateColumn>;
  where?: InputMaybe<FavoriteBoolExp>;
};

/** Ordering options when selecting data from "favorite". */
export type FavoriteOrderBy = {
  blog?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  favoriteCurrenciesAggregate?: InputMaybe<FavoriteCommentAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  wallet?: InputMaybe<WalletOrderBy>;
  walletId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: favorite */
export type FavoritePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "favorite" */
export enum FavoriteSelectColumn {
  /** column name */
  Blog = 'blog',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WalletId = 'walletId',
}

/** input type for updating data in table "favorite" */
export type FavoriteSetInput = {
  blog?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  walletId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "favorite" */
export type FavoriteStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FavoriteStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FavoriteStreamCursorValueInput = {
  blog?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  walletId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "favorite" */
export enum FavoriteUpdateColumn {
  /** column name */
  Blog = 'blog',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WalletId = 'walletId',
}

export type FavoriteUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FavoriteSetInput>;
  /** filter the rows which have to be updated */
  where: FavoriteBoolExp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8ComparisonExp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "gics_sector" */
export type GicsSector = {
  __typename?: 'GicsSector';
  /** An array relationship */
  certifications: Array<Certification>;
  /** An aggregate relationship */
  certificationsAggregate: CertificationAggregate;
  /** An array relationship */
  clearinghouseKinds: Array<ClearinghouseKind>;
  /** An aggregate relationship */
  clearinghouseKindsAggregate: ClearinghouseKindAggregate;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  currencies: Array<Currency>;
  /** An aggregate relationship */
  currenciesAggregate: CurrencyAggregate;
  /** An array relationship */
  currencyKinds: Array<CurrencyKind>;
  /** An aggregate relationship */
  currencyKindsAggregate: CurrencyKindAggregate;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  groups: Array<Group>;
  /** An aggregate relationship */
  groupsAggregate: GroupAggregate;
  id: Scalars['uuid'];
  imageSector?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  sicCode?: Maybe<SicCode>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "gics_sector" */
export type GicsSectorCertificationsArgs = {
  distinctOn?: InputMaybe<Array<CertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CertificationOrderBy>>;
  where?: InputMaybe<CertificationBoolExp>;
};

/** columns and relationships of "gics_sector" */
export type GicsSectorCertificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CertificationOrderBy>>;
  where?: InputMaybe<CertificationBoolExp>;
};

/** columns and relationships of "gics_sector" */
export type GicsSectorClearinghouseKindsArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseKindOrderBy>>;
  where?: InputMaybe<ClearinghouseKindBoolExp>;
};

/** columns and relationships of "gics_sector" */
export type GicsSectorClearinghouseKindsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseKindOrderBy>>;
  where?: InputMaybe<ClearinghouseKindBoolExp>;
};

/** columns and relationships of "gics_sector" */
export type GicsSectorCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<CurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOrderBy>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

/** columns and relationships of "gics_sector" */
export type GicsSectorCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOrderBy>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

/** columns and relationships of "gics_sector" */
export type GicsSectorCurrencyKindsArgs = {
  distinctOn?: InputMaybe<Array<CurrencyKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyKindOrderBy>>;
  where?: InputMaybe<CurrencyKindBoolExp>;
};

/** columns and relationships of "gics_sector" */
export type GicsSectorCurrencyKindsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencyKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyKindOrderBy>>;
  where?: InputMaybe<CurrencyKindBoolExp>;
};

/** columns and relationships of "gics_sector" */
export type GicsSectorGroupsArgs = {
  distinctOn?: InputMaybe<Array<GroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GroupOrderBy>>;
  where?: InputMaybe<GroupBoolExp>;
};

/** columns and relationships of "gics_sector" */
export type GicsSectorGroupsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GroupOrderBy>>;
  where?: InputMaybe<GroupBoolExp>;
};

/** aggregated selection of "gics_sector" */
export type GicsSectorAggregate = {
  __typename?: 'GicsSectorAggregate';
  aggregate?: Maybe<GicsSectorAggregateFields>;
  nodes: Array<GicsSector>;
};

/** aggregate fields of "gics_sector" */
export type GicsSectorAggregateFields = {
  __typename?: 'GicsSectorAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<GicsSectorMaxFields>;
  min?: Maybe<GicsSectorMinFields>;
};

/** aggregate fields of "gics_sector" */
export type GicsSectorAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GicsSectorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "gics_sector". All fields are combined with a logical 'AND'. */
export type GicsSectorBoolExp = {
  _and?: InputMaybe<Array<GicsSectorBoolExp>>;
  _not?: InputMaybe<GicsSectorBoolExp>;
  _or?: InputMaybe<Array<GicsSectorBoolExp>>;
  certifications?: InputMaybe<CertificationBoolExp>;
  certificationsAggregate?: InputMaybe<CertificationAggregateBoolExp>;
  clearinghouseKinds?: InputMaybe<ClearinghouseKindBoolExp>;
  clearinghouseKindsAggregate?: InputMaybe<ClearinghouseKindAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currencies?: InputMaybe<CurrencyBoolExp>;
  currenciesAggregate?: InputMaybe<CurrencyAggregateBoolExp>;
  currencyKinds?: InputMaybe<CurrencyKindBoolExp>;
  currencyKindsAggregate?: InputMaybe<CurrencyKindAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  groups?: InputMaybe<GroupBoolExp>;
  groupsAggregate?: InputMaybe<GroupAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageSector?: InputMaybe<StringComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  sicCode?: InputMaybe<SicCodeBoolExp>;
  sicCodeId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "gics_sector" */
export enum GicsSectorConstraint {
  /** unique or primary key constraint on columns "id" */
  GicsSectorPkey = 'gics_sector_pkey',
  /** unique or primary key constraint on columns "id" */
  GicsSectorXidKey = 'gics_sector_xid_key',
}

/** input type for inserting data into table "gics_sector" */
export type GicsSectorInsertInput = {
  certifications?: InputMaybe<CertificationArrRelInsertInput>;
  clearinghouseKinds?: InputMaybe<ClearinghouseKindArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencies?: InputMaybe<CurrencyArrRelInsertInput>;
  currencyKinds?: InputMaybe<CurrencyKindArrRelInsertInput>;
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<GroupArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  imageSector?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sicCode?: InputMaybe<SicCodeObjRelInsertInput>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type GicsSectorMaxFields = {
  __typename?: 'GicsSectorMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageSector?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type GicsSectorMinFields = {
  __typename?: 'GicsSectorMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageSector?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "gics_sector" */
export type GicsSectorMutationResponse = {
  __typename?: 'GicsSectorMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GicsSector>;
};

/** input type for inserting object relation for remote table "gics_sector" */
export type GicsSectorObjRelInsertInput = {
  data: GicsSectorInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<GicsSectorOnConflict>;
};

/** on_conflict condition type for table "gics_sector" */
export type GicsSectorOnConflict = {
  constraint: GicsSectorConstraint;
  updateColumns?: Array<GicsSectorUpdateColumn>;
  where?: InputMaybe<GicsSectorBoolExp>;
};

/** Ordering options when selecting data from "gics_sector". */
export type GicsSectorOrderBy = {
  certificationsAggregate?: InputMaybe<CertificationAggregateOrderBy>;
  clearinghouseKindsAggregate?: InputMaybe<ClearinghouseKindAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currenciesAggregate?: InputMaybe<CurrencyAggregateOrderBy>;
  currencyKindsAggregate?: InputMaybe<CurrencyKindAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  groupsAggregate?: InputMaybe<GroupAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  imageSector?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sicCode?: InputMaybe<SicCodeOrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: gics_sector */
export type GicsSectorPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "gics_sector" */
export enum GicsSectorSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageSector = 'imageSector',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SicCodeId = 'sicCodeId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "gics_sector" */
export type GicsSectorSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageSector?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "gics_sector" */
export type GicsSectorStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GicsSectorStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GicsSectorStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageSector?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "gics_sector" */
export enum GicsSectorUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageSector = 'imageSector',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SicCodeId = 'sicCodeId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type GicsSectorUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GicsSectorSetInput>;
  /** filter the rows which have to be updated */
  where: GicsSectorBoolExp;
};

/** columns and relationships of "group" */
export type Group = {
  __typename?: 'Group';
  /** An array relationship */
  certifications: Array<Certification>;
  /** An aggregate relationship */
  certificationsAggregate: CertificationAggregate;
  /** An array relationship */
  clearinghouseKinds: Array<ClearinghouseKind>;
  /** An aggregate relationship */
  clearinghouseKindsAggregate: ClearinghouseKindAggregate;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  currencies: Array<Currency>;
  /** An aggregate relationship */
  currenciesAggregate: CurrencyAggregate;
  /** An array relationship */
  currencyKinds: Array<CurrencyKind>;
  /** An aggregate relationship */
  currencyKindsAggregate: CurrencyKindAggregate;
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  gicsSector: GicsSector;
  gicsSectorId: Scalars['uuid'];
  id: Scalars['uuid'];
  imageGroup?: Maybe<Scalars['String']>;
  /** An array relationship */
  industries: Array<Industry>;
  /** An aggregate relationship */
  industriesAggregate: IndustryAggregate;
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  sicCode?: Maybe<SicCode>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "group" */
export type GroupCertificationsArgs = {
  distinctOn?: InputMaybe<Array<CertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CertificationOrderBy>>;
  where?: InputMaybe<CertificationBoolExp>;
};

/** columns and relationships of "group" */
export type GroupCertificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CertificationOrderBy>>;
  where?: InputMaybe<CertificationBoolExp>;
};

/** columns and relationships of "group" */
export type GroupClearinghouseKindsArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseKindOrderBy>>;
  where?: InputMaybe<ClearinghouseKindBoolExp>;
};

/** columns and relationships of "group" */
export type GroupClearinghouseKindsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseKindOrderBy>>;
  where?: InputMaybe<ClearinghouseKindBoolExp>;
};

/** columns and relationships of "group" */
export type GroupCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<CurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOrderBy>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

/** columns and relationships of "group" */
export type GroupCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOrderBy>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

/** columns and relationships of "group" */
export type GroupCurrencyKindsArgs = {
  distinctOn?: InputMaybe<Array<CurrencyKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyKindOrderBy>>;
  where?: InputMaybe<CurrencyKindBoolExp>;
};

/** columns and relationships of "group" */
export type GroupCurrencyKindsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencyKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyKindOrderBy>>;
  where?: InputMaybe<CurrencyKindBoolExp>;
};

/** columns and relationships of "group" */
export type GroupIndustriesArgs = {
  distinctOn?: InputMaybe<Array<IndustrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IndustryOrderBy>>;
  where?: InputMaybe<IndustryBoolExp>;
};

/** columns and relationships of "group" */
export type GroupIndustriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<IndustrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IndustryOrderBy>>;
  where?: InputMaybe<IndustryBoolExp>;
};

/** aggregated selection of "group" */
export type GroupAggregate = {
  __typename?: 'GroupAggregate';
  aggregate?: Maybe<GroupAggregateFields>;
  nodes: Array<Group>;
};

export type GroupAggregateBoolExp = {
  bool_and?: InputMaybe<GroupAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<GroupAggregateBoolExpBool_Or>;
  count?: InputMaybe<GroupAggregateBoolExpCount>;
};

/** aggregate fields of "group" */
export type GroupAggregateFields = {
  __typename?: 'GroupAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<GroupMaxFields>;
  min?: Maybe<GroupMinFields>;
};

/** aggregate fields of "group" */
export type GroupAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "group" */
export type GroupAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GroupMaxOrderBy>;
  min?: InputMaybe<GroupMinOrderBy>;
};

/** input type for inserting array relation for remote table "group" */
export type GroupArrRelInsertInput = {
  data: Array<GroupInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<GroupOnConflict>;
};

/** Boolean expression to filter rows from the table "group". All fields are combined with a logical 'AND'. */
export type GroupBoolExp = {
  _and?: InputMaybe<Array<GroupBoolExp>>;
  _not?: InputMaybe<GroupBoolExp>;
  _or?: InputMaybe<Array<GroupBoolExp>>;
  certifications?: InputMaybe<CertificationBoolExp>;
  certificationsAggregate?: InputMaybe<CertificationAggregateBoolExp>;
  clearinghouseKinds?: InputMaybe<ClearinghouseKindBoolExp>;
  clearinghouseKindsAggregate?: InputMaybe<ClearinghouseKindAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currencies?: InputMaybe<CurrencyBoolExp>;
  currenciesAggregate?: InputMaybe<CurrencyAggregateBoolExp>;
  currencyKinds?: InputMaybe<CurrencyKindBoolExp>;
  currencyKindsAggregate?: InputMaybe<CurrencyKindAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  gicsSector?: InputMaybe<GicsSectorBoolExp>;
  gicsSectorId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageGroup?: InputMaybe<StringComparisonExp>;
  industries?: InputMaybe<IndustryBoolExp>;
  industriesAggregate?: InputMaybe<IndustryAggregateBoolExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  sicCode?: InputMaybe<SicCodeBoolExp>;
  sicCodeId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "group" */
export enum GroupConstraint {
  /** unique or primary key constraint on columns "id" */
  GroupPkey = 'group_pkey',
  /** unique or primary key constraint on columns "id" */
  GroupXidKey = 'group_xid_key',
}

/** input type for inserting data into table "group" */
export type GroupInsertInput = {
  certifications?: InputMaybe<CertificationArrRelInsertInput>;
  clearinghouseKinds?: InputMaybe<ClearinghouseKindArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencies?: InputMaybe<CurrencyArrRelInsertInput>;
  currencyKinds?: InputMaybe<CurrencyKindArrRelInsertInput>;
  description?: InputMaybe<Scalars['String']>;
  gicsSector?: InputMaybe<GicsSectorObjRelInsertInput>;
  gicsSectorId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageGroup?: InputMaybe<Scalars['String']>;
  industries?: InputMaybe<IndustryArrRelInsertInput>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sicCode?: InputMaybe<SicCodeObjRelInsertInput>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type GroupMaxFields = {
  __typename?: 'GroupMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  gicsSectorId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  imageGroup?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "group" */
export type GroupMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  gicsSectorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageGroup?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type GroupMinFields = {
  __typename?: 'GroupMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  gicsSectorId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  imageGroup?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "group" */
export type GroupMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  gicsSectorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageGroup?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "group" */
export type GroupMutationResponse = {
  __typename?: 'GroupMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Group>;
};

/** input type for inserting object relation for remote table "group" */
export type GroupObjRelInsertInput = {
  data: GroupInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<GroupOnConflict>;
};

/** on_conflict condition type for table "group" */
export type GroupOnConflict = {
  constraint: GroupConstraint;
  updateColumns?: Array<GroupUpdateColumn>;
  where?: InputMaybe<GroupBoolExp>;
};

/** Ordering options when selecting data from "group". */
export type GroupOrderBy = {
  certificationsAggregate?: InputMaybe<CertificationAggregateOrderBy>;
  clearinghouseKindsAggregate?: InputMaybe<ClearinghouseKindAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currenciesAggregate?: InputMaybe<CurrencyAggregateOrderBy>;
  currencyKindsAggregate?: InputMaybe<CurrencyKindAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  gicsSector?: InputMaybe<GicsSectorOrderBy>;
  gicsSectorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageGroup?: InputMaybe<OrderBy>;
  industriesAggregate?: InputMaybe<IndustryAggregateOrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sicCode?: InputMaybe<SicCodeOrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: group */
export type GroupPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "group" */
export enum GroupSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  GicsSectorId = 'gicsSectorId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageGroup = 'imageGroup',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SicCodeId = 'sicCodeId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "groupAggregateBoolExpBool_andArgumentsColumns" columns of table "group" */
export enum GroupSelectColumnGroupAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "groupAggregateBoolExpBool_orArgumentsColumns" columns of table "group" */
export enum GroupSelectColumnGroupAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "group" */
export type GroupSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  gicsSectorId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageGroup?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "group" */
export type GroupStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GroupStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  gicsSectorId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageGroup?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "group" */
export enum GroupUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  GicsSectorId = 'gicsSectorId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageGroup = 'imageGroup',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SicCodeId = 'sicCodeId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type GroupUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GroupSetInput>;
  /** filter the rows which have to be updated */
  where: GroupBoolExp;
};

/** columns and relationships of "industry" */
export type Industry = {
  __typename?: 'Industry';
  /** An array relationship */
  clearinghouseKinds: Array<ClearinghouseKind>;
  /** An aggregate relationship */
  clearinghouseKindsAggregate: ClearinghouseKindAggregate;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  currencyKinds: Array<CurrencyKind>;
  /** An aggregate relationship */
  currencyKindsAggregate: CurrencyKindAggregate;
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  group?: Maybe<Group>;
  groupId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  imageIndustry?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  sicCode?: Maybe<SicCode>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  subindustries: Array<Subindustry>;
  /** An aggregate relationship */
  subindustriesAggregate: SubindustryAggregate;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "industry" */
export type IndustryClearinghouseKindsArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseKindOrderBy>>;
  where?: InputMaybe<ClearinghouseKindBoolExp>;
};

/** columns and relationships of "industry" */
export type IndustryClearinghouseKindsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseKindOrderBy>>;
  where?: InputMaybe<ClearinghouseKindBoolExp>;
};

/** columns and relationships of "industry" */
export type IndustryCurrencyKindsArgs = {
  distinctOn?: InputMaybe<Array<CurrencyKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyKindOrderBy>>;
  where?: InputMaybe<CurrencyKindBoolExp>;
};

/** columns and relationships of "industry" */
export type IndustryCurrencyKindsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencyKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyKindOrderBy>>;
  where?: InputMaybe<CurrencyKindBoolExp>;
};

/** columns and relationships of "industry" */
export type IndustrySubindustriesArgs = {
  distinctOn?: InputMaybe<Array<SubindustrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubindustryOrderBy>>;
  where?: InputMaybe<SubindustryBoolExp>;
};

/** columns and relationships of "industry" */
export type IndustrySubindustriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<SubindustrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubindustryOrderBy>>;
  where?: InputMaybe<SubindustryBoolExp>;
};

/** aggregated selection of "industry" */
export type IndustryAggregate = {
  __typename?: 'IndustryAggregate';
  aggregate?: Maybe<IndustryAggregateFields>;
  nodes: Array<Industry>;
};

export type IndustryAggregateBoolExp = {
  bool_and?: InputMaybe<IndustryAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<IndustryAggregateBoolExpBool_Or>;
  count?: InputMaybe<IndustryAggregateBoolExpCount>;
};

/** aggregate fields of "industry" */
export type IndustryAggregateFields = {
  __typename?: 'IndustryAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<IndustryMaxFields>;
  min?: Maybe<IndustryMinFields>;
};

/** aggregate fields of "industry" */
export type IndustryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IndustrySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "industry" */
export type IndustryAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<IndustryMaxOrderBy>;
  min?: InputMaybe<IndustryMinOrderBy>;
};

/** input type for inserting array relation for remote table "industry" */
export type IndustryArrRelInsertInput = {
  data: Array<IndustryInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<IndustryOnConflict>;
};

/** Boolean expression to filter rows from the table "industry". All fields are combined with a logical 'AND'. */
export type IndustryBoolExp = {
  _and?: InputMaybe<Array<IndustryBoolExp>>;
  _not?: InputMaybe<IndustryBoolExp>;
  _or?: InputMaybe<Array<IndustryBoolExp>>;
  clearinghouseKinds?: InputMaybe<ClearinghouseKindBoolExp>;
  clearinghouseKindsAggregate?: InputMaybe<ClearinghouseKindAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currencyKinds?: InputMaybe<CurrencyKindBoolExp>;
  currencyKindsAggregate?: InputMaybe<CurrencyKindAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  group?: InputMaybe<GroupBoolExp>;
  groupId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageIndustry?: InputMaybe<StringComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  sicCode?: InputMaybe<SicCodeBoolExp>;
  sicCodeId?: InputMaybe<UuidComparisonExp>;
  subindustries?: InputMaybe<SubindustryBoolExp>;
  subindustriesAggregate?: InputMaybe<SubindustryAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "industry" */
export enum IndustryConstraint {
  /** unique or primary key constraint on columns "id" */
  IndustryPkey = 'industry_pkey',
  /** unique or primary key constraint on columns "id" */
  IndustryXidKey = 'industry_xid_key',
}

/** input type for inserting data into table "industry" */
export type IndustryInsertInput = {
  clearinghouseKinds?: InputMaybe<ClearinghouseKindArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyKinds?: InputMaybe<CurrencyKindArrRelInsertInput>;
  description?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<GroupObjRelInsertInput>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageIndustry?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sicCode?: InputMaybe<SicCodeObjRelInsertInput>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  subindustries?: InputMaybe<SubindustryArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type IndustryMaxFields = {
  __typename?: 'IndustryMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  imageIndustry?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "industry" */
export type IndustryMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageIndustry?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type IndustryMinFields = {
  __typename?: 'IndustryMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  imageIndustry?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "industry" */
export type IndustryMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageIndustry?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "industry" */
export type IndustryMutationResponse = {
  __typename?: 'IndustryMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Industry>;
};

/** input type for inserting object relation for remote table "industry" */
export type IndustryObjRelInsertInput = {
  data: IndustryInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<IndustryOnConflict>;
};

/** on_conflict condition type for table "industry" */
export type IndustryOnConflict = {
  constraint: IndustryConstraint;
  updateColumns?: Array<IndustryUpdateColumn>;
  where?: InputMaybe<IndustryBoolExp>;
};

/** Ordering options when selecting data from "industry". */
export type IndustryOrderBy = {
  clearinghouseKindsAggregate?: InputMaybe<ClearinghouseKindAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyKindsAggregate?: InputMaybe<CurrencyKindAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  group?: InputMaybe<GroupOrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageIndustry?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sicCode?: InputMaybe<SicCodeOrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  subindustriesAggregate?: InputMaybe<SubindustryAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: industry */
export type IndustryPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "industry" */
export enum IndustrySelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageIndustry = 'imageIndustry',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SicCodeId = 'sicCodeId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "industryAggregateBoolExpBool_andArgumentsColumns" columns of table "industry" */
export enum IndustrySelectColumnIndustryAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "industryAggregateBoolExpBool_orArgumentsColumns" columns of table "industry" */
export enum IndustrySelectColumnIndustryAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "industry" */
export type IndustrySetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageIndustry?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "industry" */
export type IndustryStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: IndustryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IndustryStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageIndustry?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "industry" */
export enum IndustryUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageIndustry = 'imageIndustry',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SicCodeId = 'sicCodeId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type IndustryUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IndustrySetInput>;
  /** filter the rows which have to be updated */
  where: IndustryBoolExp;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** columns and relationships of "interaction" */
export type Interaction = {
  __typename?: 'Interaction';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  currency: Currency;
  currencyId: Scalars['uuid'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  liked: Scalars['Boolean'];
  /** An object relationship */
  merchant: Merchant;
  merchantId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  useCount: Scalars['Int'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** aggregated selection of "interaction" */
export type InteractionAggregate = {
  __typename?: 'InteractionAggregate';
  aggregate?: Maybe<InteractionAggregateFields>;
  nodes: Array<Interaction>;
};

export type InteractionAggregateBoolExp = {
  bool_and?: InputMaybe<InteractionAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<InteractionAggregateBoolExpBool_Or>;
  count?: InputMaybe<InteractionAggregateBoolExpCount>;
};

/** aggregate fields of "interaction" */
export type InteractionAggregateFields = {
  __typename?: 'InteractionAggregateFields';
  avg?: Maybe<InteractionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<InteractionMaxFields>;
  min?: Maybe<InteractionMinFields>;
  stddev?: Maybe<InteractionStddevFields>;
  stddevPop?: Maybe<InteractionStddevPopFields>;
  stddevSamp?: Maybe<InteractionStddevSampFields>;
  sum?: Maybe<InteractionSumFields>;
  varPop?: Maybe<InteractionVarPopFields>;
  varSamp?: Maybe<InteractionVarSampFields>;
  variance?: Maybe<InteractionVarianceFields>;
};

/** aggregate fields of "interaction" */
export type InteractionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InteractionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "interaction" */
export type InteractionAggregateOrderBy = {
  avg?: InputMaybe<InteractionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InteractionMaxOrderBy>;
  min?: InputMaybe<InteractionMinOrderBy>;
  stddev?: InputMaybe<InteractionStddevOrderBy>;
  stddevPop?: InputMaybe<InteractionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<InteractionStddevSampOrderBy>;
  sum?: InputMaybe<InteractionSumOrderBy>;
  varPop?: InputMaybe<InteractionVarPopOrderBy>;
  varSamp?: InputMaybe<InteractionVarSampOrderBy>;
  variance?: InputMaybe<InteractionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "interaction" */
export type InteractionArrRelInsertInput = {
  data: Array<InteractionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<InteractionOnConflict>;
};

/** aggregate avg on columns */
export type InteractionAvgFields = {
  __typename?: 'InteractionAvgFields';
  useCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "interaction" */
export type InteractionAvgOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "interaction". All fields are combined with a logical 'AND'. */
export type InteractionBoolExp = {
  _and?: InputMaybe<Array<InteractionBoolExp>>;
  _not?: InputMaybe<InteractionBoolExp>;
  _or?: InputMaybe<Array<InteractionBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrencyBoolExp>;
  currencyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  liked?: InputMaybe<BooleanComparisonExp>;
  merchant?: InputMaybe<MerchantBoolExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  useCount?: InputMaybe<IntComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "interaction" */
export enum InteractionConstraint {
  /** unique or primary key constraint on columns "id" */
  InteractionPkey = 'interaction_pkey',
  /** unique or primary key constraint on columns "id" */
  InteractionXidKey = 'interaction_xid_key',
}

/** input type for incrementing numeric columns in table "interaction" */
export type InteractionIncInput = {
  useCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "interaction" */
export type InteractionInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<CurrencyObjRelInsertInput>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  liked?: InputMaybe<Scalars['Boolean']>;
  merchant?: InputMaybe<MerchantObjRelInsertInput>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  useCount?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type InteractionMaxFields = {
  __typename?: 'InteractionMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  useCount?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "interaction" */
export type InteractionMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  useCount?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type InteractionMinFields = {
  __typename?: 'InteractionMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  useCount?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "interaction" */
export type InteractionMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  useCount?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "interaction" */
export type InteractionMutationResponse = {
  __typename?: 'InteractionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Interaction>;
};

/** on_conflict condition type for table "interaction" */
export type InteractionOnConflict = {
  constraint: InteractionConstraint;
  updateColumns?: Array<InteractionUpdateColumn>;
  where?: InputMaybe<InteractionBoolExp>;
};

/** Ordering options when selecting data from "interaction". */
export type InteractionOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrencyOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  liked?: InputMaybe<OrderBy>;
  merchant?: InputMaybe<MerchantOrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  useCount?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: interaction */
export type InteractionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "interaction" */
export enum InteractionSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Liked = 'liked',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UseCount = 'useCount',
  /** column name */
  UserId = 'userId',
}

/** select "interactionAggregateBoolExpBool_andArgumentsColumns" columns of table "interaction" */
export enum InteractionSelectColumnInteractionAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Liked = 'liked',
}

/** select "interactionAggregateBoolExpBool_orArgumentsColumns" columns of table "interaction" */
export enum InteractionSelectColumnInteractionAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Liked = 'liked',
}

/** input type for updating data in table "interaction" */
export type InteractionSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  liked?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  useCount?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type InteractionStddevFields = {
  __typename?: 'InteractionStddevFields';
  useCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "interaction" */
export type InteractionStddevOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type InteractionStddevPopFields = {
  __typename?: 'InteractionStddevPopFields';
  useCount?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "interaction" */
export type InteractionStddevPopOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type InteractionStddevSampFields = {
  __typename?: 'InteractionStddevSampFields';
  useCount?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "interaction" */
export type InteractionStddevSampOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "interaction" */
export type InteractionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: InteractionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InteractionStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  liked?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  useCount?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type InteractionSumFields = {
  __typename?: 'InteractionSumFields';
  useCount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "interaction" */
export type InteractionSumOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** update columns of table "interaction" */
export enum InteractionUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Liked = 'liked',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UseCount = 'useCount',
  /** column name */
  UserId = 'userId',
}

export type InteractionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InteractionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InteractionSetInput>;
  /** filter the rows which have to be updated */
  where: InteractionBoolExp;
};

/** aggregate varPop on columns */
export type InteractionVarPopFields = {
  __typename?: 'InteractionVarPopFields';
  useCount?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "interaction" */
export type InteractionVarPopOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type InteractionVarSampFields = {
  __typename?: 'InteractionVarSampFields';
  useCount?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "interaction" */
export type InteractionVarSampOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type InteractionVarianceFields = {
  __typename?: 'InteractionVarianceFields';
  useCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "interaction" */
export type InteractionVarianceOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

export type IsHandleAvailable = {
  __typename?: 'IsHandleAvailable';
  isAvailable: Scalars['Boolean'];
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _containedIn?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "kyc_kind" */
export type KycKind = {
  __typename?: 'KycKind';
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "kyc_kind" */
export type KycKindAggregate = {
  __typename?: 'KycKindAggregate';
  aggregate?: Maybe<KycKindAggregateFields>;
  nodes: Array<KycKind>;
};

/** aggregate fields of "kyc_kind" */
export type KycKindAggregateFields = {
  __typename?: 'KycKindAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<KycKindMaxFields>;
  min?: Maybe<KycKindMinFields>;
};

/** aggregate fields of "kyc_kind" */
export type KycKindAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<KycKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "kyc_kind". All fields are combined with a logical 'AND'. */
export type KycKindBoolExp = {
  _and?: InputMaybe<Array<KycKindBoolExp>>;
  _not?: InputMaybe<KycKindBoolExp>;
  _or?: InputMaybe<Array<KycKindBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "kyc_kind" */
export enum KycKindConstraint {
  /** unique or primary key constraint on columns "id" */
  KycKindPkey = 'kyc_kind_pkey',
}

/** input type for inserting data into table "kyc_kind" */
export type KycKindInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type KycKindMaxFields = {
  __typename?: 'KycKindMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type KycKindMinFields = {
  __typename?: 'KycKindMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "kyc_kind" */
export type KycKindMutationResponse = {
  __typename?: 'KycKindMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<KycKind>;
};

/** input type for inserting object relation for remote table "kyc_kind" */
export type KycKindObjRelInsertInput = {
  data: KycKindInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<KycKindOnConflict>;
};

/** on_conflict condition type for table "kyc_kind" */
export type KycKindOnConflict = {
  constraint: KycKindConstraint;
  updateColumns?: Array<KycKindUpdateColumn>;
  where?: InputMaybe<KycKindBoolExp>;
};

/** Ordering options when selecting data from "kyc_kind". */
export type KycKindOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: kyc_kind */
export type KycKindPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "kyc_kind" */
export enum KycKindSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "kyc_kind" */
export type KycKindSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "kyc_kind" */
export type KycKindStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: KycKindStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type KycKindStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "kyc_kind" */
export enum KycKindUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type KycKindUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<KycKindSetInput>;
  /** filter the rows which have to be updated */
  where: KycKindBoolExp;
};

/** columns and relationships of "kyc_status" */
export type KycStatus = {
  __typename?: 'KycStatus';
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  crwdsystemsApi?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  kycKind?: Maybe<KycKind>;
  kycKindId?: Maybe<Scalars['uuid']>;
  kycToken?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  sigmaPrime?: Maybe<SigmaPrime>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "kyc_status" */
export type KycStatusAggregate = {
  __typename?: 'KycStatusAggregate';
  aggregate?: Maybe<KycStatusAggregateFields>;
  nodes: Array<KycStatus>;
};

/** aggregate fields of "kyc_status" */
export type KycStatusAggregateFields = {
  __typename?: 'KycStatusAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<KycStatusMaxFields>;
  min?: Maybe<KycStatusMinFields>;
};

/** aggregate fields of "kyc_status" */
export type KycStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<KycStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "kyc_status". All fields are combined with a logical 'AND'. */
export type KycStatusBoolExp = {
  _and?: InputMaybe<Array<KycStatusBoolExp>>;
  _not?: InputMaybe<KycStatusBoolExp>;
  _or?: InputMaybe<Array<KycStatusBoolExp>>;
  countryCode?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  crwdsystemsApi?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  kycKind?: InputMaybe<KycKindBoolExp>;
  kycKindId?: InputMaybe<UuidComparisonExp>;
  kycToken?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  sigmaPrime?: InputMaybe<SigmaPrimeBoolExp>;
  sigmaPrimeId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "kyc_status" */
export enum KycStatusConstraint {
  /** unique or primary key constraint on columns "id" */
  KycStatusPkey = 'kyc_status_pkey',
}

/** input type for inserting data into table "kyc_status" */
export type KycStatusInsertInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdsystemsApi?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  kycKind?: InputMaybe<KycKindObjRelInsertInput>;
  kycKindId?: InputMaybe<Scalars['uuid']>;
  kycToken?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  sigmaPrime?: InputMaybe<SigmaPrimeObjRelInsertInput>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type KycStatusMaxFields = {
  __typename?: 'KycStatusMaxFields';
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crwdsystemsApi?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  kycKindId?: Maybe<Scalars['uuid']>;
  kycToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type KycStatusMinFields = {
  __typename?: 'KycStatusMinFields';
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crwdsystemsApi?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  kycKindId?: Maybe<Scalars['uuid']>;
  kycToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "kyc_status" */
export type KycStatusMutationResponse = {
  __typename?: 'KycStatusMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<KycStatus>;
};

/** on_conflict condition type for table "kyc_status" */
export type KycStatusOnConflict = {
  constraint: KycStatusConstraint;
  updateColumns?: Array<KycStatusUpdateColumn>;
  where?: InputMaybe<KycStatusBoolExp>;
};

/** Ordering options when selecting data from "kyc_status". */
export type KycStatusOrderBy = {
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  crwdsystemsApi?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  kycKind?: InputMaybe<KycKindOrderBy>;
  kycKindId?: InputMaybe<OrderBy>;
  kycToken?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  sigmaPrime?: InputMaybe<SigmaPrimeOrderBy>;
  sigmaPrimeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: kyc_status */
export type KycStatusPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "kyc_status" */
export enum KycStatusSelectColumn {
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrwdsystemsApi = 'crwdsystemsApi',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  KycKindId = 'kycKindId',
  /** column name */
  KycToken = 'kycToken',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SigmaPrimeId = 'sigmaPrimeId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "kyc_status" */
export type KycStatusSetInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdsystemsApi?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  kycKindId?: InputMaybe<Scalars['uuid']>;
  kycToken?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "kyc_status" */
export type KycStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: KycStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type KycStatusStreamCursorValueInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdsystemsApi?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  kycKindId?: InputMaybe<Scalars['uuid']>;
  kycToken?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "kyc_status" */
export enum KycStatusUpdateColumn {
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrwdsystemsApi = 'crwdsystemsApi',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  KycKindId = 'kycKindId',
  /** column name */
  KycToken = 'kycToken',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SigmaPrimeId = 'sigmaPrimeId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type KycStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<KycStatusSetInput>;
  /** filter the rows which have to be updated */
  where: KycStatusBoolExp;
};

/** columns and relationships of "market" */
export type Market = {
  __typename?: 'Market';
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  images?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organizationId: Scalars['uuid'];
  /** An object relationship */
  smartContract?: Maybe<SmartContract>;
  smartContractId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  subindustry1?: Maybe<Subindustry>;
  subindustry1Id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  subindustry2?: Maybe<Subindustry>;
  subindustry2Id?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "market" */
export type MarketAggregate = {
  __typename?: 'MarketAggregate';
  aggregate?: Maybe<MarketAggregateFields>;
  nodes: Array<Market>;
};

/** aggregate fields of "market" */
export type MarketAggregateFields = {
  __typename?: 'MarketAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<MarketMaxFields>;
  min?: Maybe<MarketMinFields>;
};

/** aggregate fields of "market" */
export type MarketAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MarketSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "market". All fields are combined with a logical 'AND'. */
export type MarketBoolExp = {
  _and?: InputMaybe<Array<MarketBoolExp>>;
  _not?: InputMaybe<MarketBoolExp>;
  _or?: InputMaybe<Array<MarketBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  images?: InputMaybe<StringComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  logo?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  smartContract?: InputMaybe<SmartContractBoolExp>;
  smartContractId?: InputMaybe<UuidComparisonExp>;
  subindustry1?: InputMaybe<SubindustryBoolExp>;
  subindustry1Id?: InputMaybe<UuidComparisonExp>;
  subindustry2?: InputMaybe<SubindustryBoolExp>;
  subindustry2Id?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "market" */
export enum MarketConstraint {
  /** unique or primary key constraint on columns "id" */
  MarketPkey = 'market_pkey',
}

/** input type for inserting data into table "market" */
export type MarketInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  smartContract?: InputMaybe<SmartContractObjRelInsertInput>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  subindustry1?: InputMaybe<SubindustryObjRelInsertInput>;
  subindustry1Id?: InputMaybe<Scalars['uuid']>;
  subindustry2?: InputMaybe<SubindustryObjRelInsertInput>;
  subindustry2Id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type MarketMaxFields = {
  __typename?: 'MarketMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  images?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  subindustry1Id?: Maybe<Scalars['uuid']>;
  subindustry2Id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type MarketMinFields = {
  __typename?: 'MarketMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  images?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  subindustry1Id?: Maybe<Scalars['uuid']>;
  subindustry2Id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "market" */
export type MarketMutationResponse = {
  __typename?: 'MarketMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Market>;
};

/** input type for inserting object relation for remote table "market" */
export type MarketObjRelInsertInput = {
  data: MarketInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MarketOnConflict>;
};

/** on_conflict condition type for table "market" */
export type MarketOnConflict = {
  constraint: MarketConstraint;
  updateColumns?: Array<MarketUpdateColumn>;
  where?: InputMaybe<MarketBoolExp>;
};

/** Ordering options when selecting data from "market". */
export type MarketOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  images?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  logo?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  smartContract?: InputMaybe<SmartContractOrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  subindustry1?: InputMaybe<SubindustryOrderBy>;
  subindustry1Id?: InputMaybe<OrderBy>;
  subindustry2?: InputMaybe<SubindustryOrderBy>;
  subindustry2Id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: market */
export type MarketPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "market" */
export enum MarketSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  Subindustry1Id = 'subindustry1Id',
  /** column name */
  Subindustry2Id = 'subindustry2Id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "market" */
export type MarketSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  subindustry1Id?: InputMaybe<Scalars['uuid']>;
  subindustry2Id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "market" */
export type MarketStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MarketStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MarketStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  subindustry1Id?: InputMaybe<Scalars['uuid']>;
  subindustry2Id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "market" */
export enum MarketUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  Subindustry1Id = 'subindustry1Id',
  /** column name */
  Subindustry2Id = 'subindustry2Id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'userId',
}

export type MarketUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MarketSetInput>;
  /** filter the rows which have to be updated */
  where: MarketBoolExp;
};

/** columns and relationships of "material" */
export type Material = {
  __typename?: 'Material';
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  detailsUrl?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  image?: Maybe<Scalars['String']>;
  isCommodity?: Maybe<Scalars['Boolean']>;
  isDeleted: Scalars['Boolean'];
  materialCategory: Scalars['String'];
  materialSubcategory?: Maybe<Scalars['String']>;
  materialType: Scalars['String'];
  name: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "material" */
export type MaterialAggregate = {
  __typename?: 'MaterialAggregate';
  aggregate?: Maybe<MaterialAggregateFields>;
  nodes: Array<Material>;
};

/** aggregate fields of "material" */
export type MaterialAggregateFields = {
  __typename?: 'MaterialAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<MaterialMaxFields>;
  min?: Maybe<MaterialMinFields>;
};

/** aggregate fields of "material" */
export type MaterialAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MaterialSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "material". All fields are combined with a logical 'AND'. */
export type MaterialBoolExp = {
  _and?: InputMaybe<Array<MaterialBoolExp>>;
  _not?: InputMaybe<MaterialBoolExp>;
  _or?: InputMaybe<Array<MaterialBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  detailsUrl?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  image?: InputMaybe<StringComparisonExp>;
  isCommodity?: InputMaybe<BooleanComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  materialCategory?: InputMaybe<StringComparisonExp>;
  materialSubcategory?: InputMaybe<StringComparisonExp>;
  materialType?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  symbol?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "material" */
export enum MaterialConstraint {
  /** unique or primary key constraint on columns "id" */
  MaterialPkey = 'material_pkey',
}

/** input type for inserting data into table "material" */
export type MaterialInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  detailsUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isCommodity?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  materialCategory?: InputMaybe<Scalars['String']>;
  materialSubcategory?: InputMaybe<Scalars['String']>;
  materialType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MaterialMaxFields = {
  __typename?: 'MaterialMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  detailsUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  materialCategory?: Maybe<Scalars['String']>;
  materialSubcategory?: Maybe<Scalars['String']>;
  materialType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type MaterialMinFields = {
  __typename?: 'MaterialMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  detailsUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  materialCategory?: Maybe<Scalars['String']>;
  materialSubcategory?: Maybe<Scalars['String']>;
  materialType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "material" */
export type MaterialMutationResponse = {
  __typename?: 'MaterialMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Material>;
};

/** input type for inserting object relation for remote table "material" */
export type MaterialObjRelInsertInput = {
  data: MaterialInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MaterialOnConflict>;
};

/** on_conflict condition type for table "material" */
export type MaterialOnConflict = {
  constraint: MaterialConstraint;
  updateColumns?: Array<MaterialUpdateColumn>;
  where?: InputMaybe<MaterialBoolExp>;
};

/** Ordering options when selecting data from "material". */
export type MaterialOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  detailsUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  isCommodity?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  materialCategory?: InputMaybe<OrderBy>;
  materialSubcategory?: InputMaybe<OrderBy>;
  materialType?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: material */
export type MaterialPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "material" */
export enum MaterialSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DetailsUrl = 'detailsUrl',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsCommodity = 'isCommodity',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MaterialCategory = 'materialCategory',
  /** column name */
  MaterialSubcategory = 'materialSubcategory',
  /** column name */
  MaterialType = 'materialType',
  /** column name */
  Name = 'name',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "material" */
export type MaterialSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  detailsUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isCommodity?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  materialCategory?: InputMaybe<Scalars['String']>;
  materialSubcategory?: InputMaybe<Scalars['String']>;
  materialType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "material" */
export type MaterialStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MaterialStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MaterialStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  detailsUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isCommodity?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  materialCategory?: InputMaybe<Scalars['String']>;
  materialSubcategory?: InputMaybe<Scalars['String']>;
  materialType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "material" */
export enum MaterialUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DetailsUrl = 'detailsUrl',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsCommodity = 'isCommodity',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MaterialCategory = 'materialCategory',
  /** column name */
  MaterialSubcategory = 'materialSubcategory',
  /** column name */
  MaterialType = 'materialType',
  /** column name */
  Name = 'name',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MaterialUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MaterialSetInput>;
  /** filter the rows which have to be updated */
  where: MaterialBoolExp;
};

/** columns and relationships of "merchant" */
export type Merchant = {
  __typename?: 'Merchant';
  createdAt: Scalars['timestamptz'];
  crwdsystemsApi: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  interactions: Array<Interaction>;
  /** An aggregate relationship */
  interactionsAggregate: InteractionAggregate;
  isDeleted: Scalars['Boolean'];
  /** An array relationship */
  merchantCurrencies: Array<MerchantCurrency>;
  /** An aggregate relationship */
  merchantCurrenciesAggregate: MerchantCurrencyAggregate;
  /** An array relationship */
  merchantOrganizations: Array<MerchantOrganization>;
  /** An aggregate relationship */
  merchantOrganizationsAggregate: MerchantOrganizationAggregate;
  name: Scalars['String'];
  notifyUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  status?: Maybe<Status>;
  statusId?: Maybe<Scalars['uuid']>;
  uniqueHash: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** columns and relationships of "merchant" */
export type MerchantInteractionsArgs = {
  distinctOn?: InputMaybe<Array<InteractionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionOrderBy>>;
  where?: InputMaybe<InteractionBoolExp>;
};

/** columns and relationships of "merchant" */
export type MerchantInteractionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<InteractionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionOrderBy>>;
  where?: InputMaybe<InteractionBoolExp>;
};

/** columns and relationships of "merchant" */
export type MerchantMerchantCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<MerchantCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantCurrencyOrderBy>>;
  where?: InputMaybe<MerchantCurrencyBoolExp>;
};

/** columns and relationships of "merchant" */
export type MerchantMerchantCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MerchantCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantCurrencyOrderBy>>;
  where?: InputMaybe<MerchantCurrencyBoolExp>;
};

/** columns and relationships of "merchant" */
export type MerchantMerchantOrganizationsArgs = {
  distinctOn?: InputMaybe<Array<MerchantOrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantOrganizationOrderBy>>;
  where?: InputMaybe<MerchantOrganizationBoolExp>;
};

/** columns and relationships of "merchant" */
export type MerchantMerchantOrganizationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MerchantOrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantOrganizationOrderBy>>;
  where?: InputMaybe<MerchantOrganizationBoolExp>;
};

/** aggregated selection of "merchant" */
export type MerchantAggregate = {
  __typename?: 'MerchantAggregate';
  aggregate?: Maybe<MerchantAggregateFields>;
  nodes: Array<Merchant>;
};

export type MerchantAggregateBoolExp = {
  bool_and?: InputMaybe<MerchantAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<MerchantAggregateBoolExpBool_Or>;
  count?: InputMaybe<MerchantAggregateBoolExpCount>;
};

/** aggregate fields of "merchant" */
export type MerchantAggregateFields = {
  __typename?: 'MerchantAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<MerchantMaxFields>;
  min?: Maybe<MerchantMinFields>;
};

/** aggregate fields of "merchant" */
export type MerchantAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchant" */
export type MerchantAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchantMaxOrderBy>;
  min?: InputMaybe<MerchantMinOrderBy>;
};

/** input type for inserting array relation for remote table "merchant" */
export type MerchantArrRelInsertInput = {
  data: Array<MerchantInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MerchantOnConflict>;
};

/** Boolean expression to filter rows from the table "merchant". All fields are combined with a logical 'AND'. */
export type MerchantBoolExp = {
  _and?: InputMaybe<Array<MerchantBoolExp>>;
  _not?: InputMaybe<MerchantBoolExp>;
  _or?: InputMaybe<Array<MerchantBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  crwdsystemsApi?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  interactions?: InputMaybe<InteractionBoolExp>;
  interactionsAggregate?: InputMaybe<InteractionAggregateBoolExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  merchantCurrencies?: InputMaybe<MerchantCurrencyBoolExp>;
  merchantCurrenciesAggregate?: InputMaybe<MerchantCurrencyAggregateBoolExp>;
  merchantOrganizations?: InputMaybe<MerchantOrganizationBoolExp>;
  merchantOrganizationsAggregate?: InputMaybe<MerchantOrganizationAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  notifyUrl?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StatusBoolExp>;
  statusId?: InputMaybe<UuidComparisonExp>;
  uniqueHash?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "merchant" */
export enum MerchantConstraint {
  /** unique or primary key constraint on columns "id" */
  MerchantPkey = 'merchant_pkey',
  /** unique or primary key constraint on columns "id" */
  MerchantXidKey = 'merchant_xid_key',
}

/** columns and relationships of "merchant_currency" */
export type MerchantCurrency = {
  __typename?: 'MerchantCurrency';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  currency: Currency;
  currencyId: Scalars['uuid'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  merchant: Merchant;
  merchantId: Scalars['uuid'];
  /** An object relationship */
  status?: Maybe<Status>;
  statusId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "merchant_currency" */
export type MerchantCurrencyAggregate = {
  __typename?: 'MerchantCurrencyAggregate';
  aggregate?: Maybe<MerchantCurrencyAggregateFields>;
  nodes: Array<MerchantCurrency>;
};

export type MerchantCurrencyAggregateBoolExp = {
  bool_and?: InputMaybe<MerchantCurrencyAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<MerchantCurrencyAggregateBoolExpBool_Or>;
  count?: InputMaybe<MerchantCurrencyAggregateBoolExpCount>;
};

/** aggregate fields of "merchant_currency" */
export type MerchantCurrencyAggregateFields = {
  __typename?: 'MerchantCurrencyAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<MerchantCurrencyMaxFields>;
  min?: Maybe<MerchantCurrencyMinFields>;
};

/** aggregate fields of "merchant_currency" */
export type MerchantCurrencyAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantCurrencySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchant_currency" */
export type MerchantCurrencyAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchantCurrencyMaxOrderBy>;
  min?: InputMaybe<MerchantCurrencyMinOrderBy>;
};

/** input type for inserting array relation for remote table "merchant_currency" */
export type MerchantCurrencyArrRelInsertInput = {
  data: Array<MerchantCurrencyInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MerchantCurrencyOnConflict>;
};

/** Boolean expression to filter rows from the table "merchant_currency". All fields are combined with a logical 'AND'. */
export type MerchantCurrencyBoolExp = {
  _and?: InputMaybe<Array<MerchantCurrencyBoolExp>>;
  _not?: InputMaybe<MerchantCurrencyBoolExp>;
  _or?: InputMaybe<Array<MerchantCurrencyBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrencyBoolExp>;
  currencyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  merchant?: InputMaybe<MerchantBoolExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  status?: InputMaybe<StatusBoolExp>;
  statusId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "merchant_currency" */
export enum MerchantCurrencyConstraint {
  /** unique or primary key constraint on columns "id" */
  MerchantCurrencyPkey = 'merchant_currency_pkey',
  /** unique or primary key constraint on columns "id" */
  MerchantCurrencyXidKey = 'merchant_currency_xid_key',
}

/** input type for inserting data into table "merchant_currency" */
export type MerchantCurrencyInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<CurrencyObjRelInsertInput>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  merchant?: InputMaybe<MerchantObjRelInsertInput>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<StatusObjRelInsertInput>;
  statusId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MerchantCurrencyMaxFields = {
  __typename?: 'MerchantCurrencyMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  statusId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "merchant_currency" */
export type MerchantCurrencyMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchantCurrencyMinFields = {
  __typename?: 'MerchantCurrencyMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  statusId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "merchant_currency" */
export type MerchantCurrencyMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchant_currency" */
export type MerchantCurrencyMutationResponse = {
  __typename?: 'MerchantCurrencyMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchantCurrency>;
};

/** on_conflict condition type for table "merchant_currency" */
export type MerchantCurrencyOnConflict = {
  constraint: MerchantCurrencyConstraint;
  updateColumns?: Array<MerchantCurrencyUpdateColumn>;
  where?: InputMaybe<MerchantCurrencyBoolExp>;
};

/** Ordering options when selecting data from "merchant_currency". */
export type MerchantCurrencyOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrencyOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  merchant?: InputMaybe<MerchantOrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  status?: InputMaybe<StatusOrderBy>;
  statusId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchant_currency */
export type MerchantCurrencyPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "merchant_currency" */
export enum MerchantCurrencySelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  StatusId = 'statusId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "merchantCurrencyAggregateBoolExpBool_andArgumentsColumns" columns of table "merchant_currency" */
export enum MerchantCurrencySelectColumnMerchantCurrencyAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "merchantCurrencyAggregateBoolExpBool_orArgumentsColumns" columns of table "merchant_currency" */
export enum MerchantCurrencySelectColumnMerchantCurrencyAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "merchant_currency" */
export type MerchantCurrencySetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  statusId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "merchant_currency" */
export type MerchantCurrencyStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MerchantCurrencyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantCurrencyStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  statusId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "merchant_currency" */
export enum MerchantCurrencyUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  StatusId = 'statusId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MerchantCurrencyUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantCurrencySetInput>;
  /** filter the rows which have to be updated */
  where: MerchantCurrencyBoolExp;
};

/** input type for inserting data into table "merchant" */
export type MerchantInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdsystemsApi?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  interactions?: InputMaybe<InteractionArrRelInsertInput>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  merchantCurrencies?: InputMaybe<MerchantCurrencyArrRelInsertInput>;
  merchantOrganizations?: InputMaybe<MerchantOrganizationArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  notifyUrl?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusObjRelInsertInput>;
  statusId?: InputMaybe<Scalars['uuid']>;
  uniqueHash?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type MerchantMaxFields = {
  __typename?: 'MerchantMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  crwdsystemsApi?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  notifyUrl?: Maybe<Scalars['String']>;
  statusId?: Maybe<Scalars['uuid']>;
  uniqueHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "merchant" */
export type MerchantMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  crwdsystemsApi?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  notifyUrl?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  uniqueHash?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchantMinFields = {
  __typename?: 'MerchantMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  crwdsystemsApi?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  notifyUrl?: Maybe<Scalars['String']>;
  statusId?: Maybe<Scalars['uuid']>;
  uniqueHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "merchant" */
export type MerchantMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  crwdsystemsApi?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  notifyUrl?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  uniqueHash?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchant" */
export type MerchantMutationResponse = {
  __typename?: 'MerchantMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Merchant>;
};

/** input type for inserting object relation for remote table "merchant" */
export type MerchantObjRelInsertInput = {
  data: MerchantInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MerchantOnConflict>;
};

/** on_conflict condition type for table "merchant" */
export type MerchantOnConflict = {
  constraint: MerchantConstraint;
  updateColumns?: Array<MerchantUpdateColumn>;
  where?: InputMaybe<MerchantBoolExp>;
};

/** Ordering options when selecting data from "merchant". */
export type MerchantOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  crwdsystemsApi?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  interactionsAggregate?: InputMaybe<InteractionAggregateOrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  merchantCurrenciesAggregate?: InputMaybe<MerchantCurrencyAggregateOrderBy>;
  merchantOrganizationsAggregate?: InputMaybe<MerchantOrganizationAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  notifyUrl?: InputMaybe<OrderBy>;
  status?: InputMaybe<StatusOrderBy>;
  statusId?: InputMaybe<OrderBy>;
  uniqueHash?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "merchant_organization" */
export type MerchantOrganization = {
  __typename?: 'MerchantOrganization';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  merchant?: Maybe<Merchant>;
  merchantId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "merchant_organization" */
export type MerchantOrganizationAggregate = {
  __typename?: 'MerchantOrganizationAggregate';
  aggregate?: Maybe<MerchantOrganizationAggregateFields>;
  nodes: Array<MerchantOrganization>;
};

export type MerchantOrganizationAggregateBoolExp = {
  bool_and?: InputMaybe<MerchantOrganizationAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<MerchantOrganizationAggregateBoolExpBool_Or>;
  count?: InputMaybe<MerchantOrganizationAggregateBoolExpCount>;
};

/** aggregate fields of "merchant_organization" */
export type MerchantOrganizationAggregateFields = {
  __typename?: 'MerchantOrganizationAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<MerchantOrganizationMaxFields>;
  min?: Maybe<MerchantOrganizationMinFields>;
};

/** aggregate fields of "merchant_organization" */
export type MerchantOrganizationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantOrganizationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchant_organization" */
export type MerchantOrganizationAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchantOrganizationMaxOrderBy>;
  min?: InputMaybe<MerchantOrganizationMinOrderBy>;
};

/** input type for inserting array relation for remote table "merchant_organization" */
export type MerchantOrganizationArrRelInsertInput = {
  data: Array<MerchantOrganizationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MerchantOrganizationOnConflict>;
};

/** Boolean expression to filter rows from the table "merchant_organization". All fields are combined with a logical 'AND'. */
export type MerchantOrganizationBoolExp = {
  _and?: InputMaybe<Array<MerchantOrganizationBoolExp>>;
  _not?: InputMaybe<MerchantOrganizationBoolExp>;
  _or?: InputMaybe<Array<MerchantOrganizationBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  merchant?: InputMaybe<MerchantBoolExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "merchant_organization" */
export enum MerchantOrganizationConstraint {
  /** unique or primary key constraint on columns "id" */
  MerchantOrganizationPkey = 'merchant_organization_pkey',
  /** unique or primary key constraint on columns "id" */
  MerchantOrganizationXidKey = 'merchant_organization_xid_key',
}

/** input type for inserting data into table "merchant_organization" */
export type MerchantOrganizationInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  merchant?: InputMaybe<MerchantObjRelInsertInput>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MerchantOrganizationMaxFields = {
  __typename?: 'MerchantOrganizationMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "merchant_organization" */
export type MerchantOrganizationMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchantOrganizationMinFields = {
  __typename?: 'MerchantOrganizationMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "merchant_organization" */
export type MerchantOrganizationMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchant_organization" */
export type MerchantOrganizationMutationResponse = {
  __typename?: 'MerchantOrganizationMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchantOrganization>;
};

/** on_conflict condition type for table "merchant_organization" */
export type MerchantOrganizationOnConflict = {
  constraint: MerchantOrganizationConstraint;
  updateColumns?: Array<MerchantOrganizationUpdateColumn>;
  where?: InputMaybe<MerchantOrganizationBoolExp>;
};

/** Ordering options when selecting data from "merchant_organization". */
export type MerchantOrganizationOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  merchant?: InputMaybe<MerchantOrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchant_organization */
export type MerchantOrganizationPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "merchant_organization" */
export enum MerchantOrganizationSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "merchantOrganizationAggregateBoolExpBool_andArgumentsColumns" columns of table "merchant_organization" */
export enum MerchantOrganizationSelectColumnMerchantOrganizationAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "merchantOrganizationAggregateBoolExpBool_orArgumentsColumns" columns of table "merchant_organization" */
export enum MerchantOrganizationSelectColumnMerchantOrganizationAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "merchant_organization" */
export type MerchantOrganizationSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "merchant_organization" */
export type MerchantOrganizationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MerchantOrganizationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantOrganizationStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "merchant_organization" */
export enum MerchantOrganizationUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MerchantOrganizationUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantOrganizationSetInput>;
  /** filter the rows which have to be updated */
  where: MerchantOrganizationBoolExp;
};

/** primary key columns input for table: merchant */
export type MerchantPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "merchant" */
export enum MerchantSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrwdsystemsApi = 'crwdsystemsApi',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  NotifyUrl = 'notifyUrl',
  /** column name */
  StatusId = 'statusId',
  /** column name */
  UniqueHash = 'uniqueHash',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'userId',
}

/** select "merchantAggregateBoolExpBool_andArgumentsColumns" columns of table "merchant" */
export enum MerchantSelectColumnMerchantAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "merchantAggregateBoolExpBool_orArgumentsColumns" columns of table "merchant" */
export enum MerchantSelectColumnMerchantAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "merchant" */
export type MerchantSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdsystemsApi?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  notifyUrl?: InputMaybe<Scalars['String']>;
  statusId?: InputMaybe<Scalars['uuid']>;
  uniqueHash?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "merchant" */
export type MerchantStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MerchantStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdsystemsApi?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  notifyUrl?: InputMaybe<Scalars['String']>;
  statusId?: InputMaybe<Scalars['uuid']>;
  uniqueHash?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "merchant" */
export enum MerchantUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrwdsystemsApi = 'crwdsystemsApi',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  NotifyUrl = 'notifyUrl',
  /** column name */
  StatusId = 'statusId',
  /** column name */
  UniqueHash = 'uniqueHash',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'userId',
}

export type MerchantUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantSetInput>;
  /** filter the rows which have to be updated */
  where: MerchantBoolExp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "order" */
export type Order = {
  __typename?: 'Order';
  /** An object relationship */
  ask?: Maybe<Ask>;
  askId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  orderToken?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  productDistribution?: Maybe<ProductDistribution>;
  productDistributionId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  put?: Maybe<Put>;
  putId: Scalars['uuid'];
  shipCountyRegion?: Maybe<Scalars['String']>;
  shipEmail: Scalars['String'];
  shipFee: Scalars['Int'];
  shipGeo?: Maybe<Scalars['String']>;
  shipMethod: Scalars['String'];
  shipPhone: Scalars['String'];
  shipPostal4: Scalars['String'];
  shipPostalCode?: Maybe<Scalars['String']>;
  shipProvinceState?: Maybe<Scalars['String']>;
  shipSector?: Maybe<Scalars['String']>;
  shipStreet1?: Maybe<Scalars['String']>;
  shipStreet2?: Maybe<Scalars['String']>;
  /** An object relationship */
  sigmaPrimeAddress?: Maybe<SigmaPrimeAddress>;
  sigmaPrimeAddressId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "order" */
export type OrderAggregate = {
  __typename?: 'OrderAggregate';
  aggregate?: Maybe<OrderAggregateFields>;
  nodes: Array<Order>;
};

/** aggregate fields of "order" */
export type OrderAggregateFields = {
  __typename?: 'OrderAggregateFields';
  avg?: Maybe<OrderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderMaxFields>;
  min?: Maybe<OrderMinFields>;
  stddev?: Maybe<OrderStddevFields>;
  stddevPop?: Maybe<OrderStddevPopFields>;
  stddevSamp?: Maybe<OrderStddevSampFields>;
  sum?: Maybe<OrderSumFields>;
  varPop?: Maybe<OrderVarPopFields>;
  varSamp?: Maybe<OrderVarSampFields>;
  variance?: Maybe<OrderVarianceFields>;
};

/** aggregate fields of "order" */
export type OrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type OrderAvgFields = {
  __typename?: 'OrderAvgFields';
  orderToken?: Maybe<Scalars['Float']>;
  shipFee?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "order". All fields are combined with a logical 'AND'. */
export type OrderBoolExp = {
  _and?: InputMaybe<Array<OrderBoolExp>>;
  _not?: InputMaybe<OrderBoolExp>;
  _or?: InputMaybe<Array<OrderBoolExp>>;
  ask?: InputMaybe<AskBoolExp>;
  askId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  orderToken?: InputMaybe<BigintComparisonExp>;
  productDistribution?: InputMaybe<ProductDistributionBoolExp>;
  productDistributionId?: InputMaybe<UuidComparisonExp>;
  put?: InputMaybe<PutBoolExp>;
  putId?: InputMaybe<UuidComparisonExp>;
  shipCountyRegion?: InputMaybe<StringComparisonExp>;
  shipEmail?: InputMaybe<StringComparisonExp>;
  shipFee?: InputMaybe<IntComparisonExp>;
  shipGeo?: InputMaybe<StringComparisonExp>;
  shipMethod?: InputMaybe<StringComparisonExp>;
  shipPhone?: InputMaybe<StringComparisonExp>;
  shipPostal4?: InputMaybe<StringComparisonExp>;
  shipPostalCode?: InputMaybe<StringComparisonExp>;
  shipProvinceState?: InputMaybe<StringComparisonExp>;
  shipSector?: InputMaybe<StringComparisonExp>;
  shipStreet1?: InputMaybe<StringComparisonExp>;
  shipStreet2?: InputMaybe<StringComparisonExp>;
  sigmaPrimeAddress?: InputMaybe<SigmaPrimeAddressBoolExp>;
  sigmaPrimeAddressId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'ASC',
  /** in ascending order, nulls first */
  AscNullsFirst = 'ASC_NULLS_FIRST',
  /** in ascending order, nulls last */
  AscNullsLast = 'ASC_NULLS_LAST',
  /** in descending order, nulls first */
  Desc = 'DESC',
  /** in descending order, nulls first */
  DescNullsFirst = 'DESC_NULLS_FIRST',
  /** in descending order, nulls last */
  DescNullsLast = 'DESC_NULLS_LAST',
}

/** unique or primary key constraints on table "order" */
export enum OrderConstraint {
  /** unique or primary key constraint on columns "id" */
  OrderPkey = 'order_pkey',
}

/** input type for incrementing numeric columns in table "order" */
export type OrderIncInput = {
  orderToken?: InputMaybe<Scalars['bigint']>;
  shipFee?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "order" */
export type OrderInsertInput = {
  ask?: InputMaybe<AskObjRelInsertInput>;
  askId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  orderToken?: InputMaybe<Scalars['bigint']>;
  productDistribution?: InputMaybe<ProductDistributionObjRelInsertInput>;
  productDistributionId?: InputMaybe<Scalars['uuid']>;
  put?: InputMaybe<PutObjRelInsertInput>;
  putId?: InputMaybe<Scalars['uuid']>;
  shipCountyRegion?: InputMaybe<Scalars['String']>;
  shipEmail?: InputMaybe<Scalars['String']>;
  shipFee?: InputMaybe<Scalars['Int']>;
  shipGeo?: InputMaybe<Scalars['String']>;
  shipMethod?: InputMaybe<Scalars['String']>;
  shipPhone?: InputMaybe<Scalars['String']>;
  shipPostal4?: InputMaybe<Scalars['String']>;
  shipPostalCode?: InputMaybe<Scalars['String']>;
  shipProvinceState?: InputMaybe<Scalars['String']>;
  shipSector?: InputMaybe<Scalars['String']>;
  shipStreet1?: InputMaybe<Scalars['String']>;
  shipStreet2?: InputMaybe<Scalars['String']>;
  sigmaPrimeAddress?: InputMaybe<SigmaPrimeAddressObjRelInsertInput>;
  sigmaPrimeAddressId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type OrderMaxFields = {
  __typename?: 'OrderMaxFields';
  askId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  orderToken?: Maybe<Scalars['bigint']>;
  productDistributionId?: Maybe<Scalars['uuid']>;
  putId?: Maybe<Scalars['uuid']>;
  shipCountyRegion?: Maybe<Scalars['String']>;
  shipEmail?: Maybe<Scalars['String']>;
  shipFee?: Maybe<Scalars['Int']>;
  shipGeo?: Maybe<Scalars['String']>;
  shipMethod?: Maybe<Scalars['String']>;
  shipPhone?: Maybe<Scalars['String']>;
  shipPostal4?: Maybe<Scalars['String']>;
  shipPostalCode?: Maybe<Scalars['String']>;
  shipProvinceState?: Maybe<Scalars['String']>;
  shipSector?: Maybe<Scalars['String']>;
  shipStreet1?: Maybe<Scalars['String']>;
  shipStreet2?: Maybe<Scalars['String']>;
  sigmaPrimeAddressId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type OrderMinFields = {
  __typename?: 'OrderMinFields';
  askId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  orderToken?: Maybe<Scalars['bigint']>;
  productDistributionId?: Maybe<Scalars['uuid']>;
  putId?: Maybe<Scalars['uuid']>;
  shipCountyRegion?: Maybe<Scalars['String']>;
  shipEmail?: Maybe<Scalars['String']>;
  shipFee?: Maybe<Scalars['Int']>;
  shipGeo?: Maybe<Scalars['String']>;
  shipMethod?: Maybe<Scalars['String']>;
  shipPhone?: Maybe<Scalars['String']>;
  shipPostal4?: Maybe<Scalars['String']>;
  shipPostalCode?: Maybe<Scalars['String']>;
  shipProvinceState?: Maybe<Scalars['String']>;
  shipSector?: Maybe<Scalars['String']>;
  shipStreet1?: Maybe<Scalars['String']>;
  shipStreet2?: Maybe<Scalars['String']>;
  sigmaPrimeAddressId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "order" */
export type OrderMutationResponse = {
  __typename?: 'OrderMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order>;
};

/** input type for inserting object relation for remote table "order" */
export type OrderObjRelInsertInput = {
  data: OrderInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrderOnConflict>;
};

/** on_conflict condition type for table "order" */
export type OrderOnConflict = {
  constraint: OrderConstraint;
  updateColumns?: Array<OrderUpdateColumn>;
  where?: InputMaybe<OrderBoolExp>;
};

/** Ordering options when selecting data from "order". */
export type OrderOrderBy = {
  ask?: InputMaybe<AskOrderBy>;
  askId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  orderToken?: InputMaybe<OrderBy>;
  productDistribution?: InputMaybe<ProductDistributionOrderBy>;
  productDistributionId?: InputMaybe<OrderBy>;
  put?: InputMaybe<PutOrderBy>;
  putId?: InputMaybe<OrderBy>;
  shipCountyRegion?: InputMaybe<OrderBy>;
  shipEmail?: InputMaybe<OrderBy>;
  shipFee?: InputMaybe<OrderBy>;
  shipGeo?: InputMaybe<OrderBy>;
  shipMethod?: InputMaybe<OrderBy>;
  shipPhone?: InputMaybe<OrderBy>;
  shipPostal4?: InputMaybe<OrderBy>;
  shipPostalCode?: InputMaybe<OrderBy>;
  shipProvinceState?: InputMaybe<OrderBy>;
  shipSector?: InputMaybe<OrderBy>;
  shipStreet1?: InputMaybe<OrderBy>;
  shipStreet2?: InputMaybe<OrderBy>;
  sigmaPrimeAddress?: InputMaybe<SigmaPrimeAddressOrderBy>;
  sigmaPrimeAddressId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: order */
export type OrderPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "order" */
export enum OrderSelectColumn {
  /** column name */
  AskId = 'askId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  OrderToken = 'orderToken',
  /** column name */
  ProductDistributionId = 'productDistributionId',
  /** column name */
  PutId = 'putId',
  /** column name */
  ShipCountyRegion = 'shipCountyRegion',
  /** column name */
  ShipEmail = 'shipEmail',
  /** column name */
  ShipFee = 'shipFee',
  /** column name */
  ShipGeo = 'shipGeo',
  /** column name */
  ShipMethod = 'shipMethod',
  /** column name */
  ShipPhone = 'shipPhone',
  /** column name */
  ShipPostal4 = 'shipPostal4',
  /** column name */
  ShipPostalCode = 'shipPostalCode',
  /** column name */
  ShipProvinceState = 'shipProvinceState',
  /** column name */
  ShipSector = 'shipSector',
  /** column name */
  ShipStreet1 = 'shipStreet1',
  /** column name */
  ShipStreet2 = 'shipStreet2',
  /** column name */
  SigmaPrimeAddressId = 'sigmaPrimeAddressId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "order" */
export type OrderSetInput = {
  askId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  orderToken?: InputMaybe<Scalars['bigint']>;
  productDistributionId?: InputMaybe<Scalars['uuid']>;
  putId?: InputMaybe<Scalars['uuid']>;
  shipCountyRegion?: InputMaybe<Scalars['String']>;
  shipEmail?: InputMaybe<Scalars['String']>;
  shipFee?: InputMaybe<Scalars['Int']>;
  shipGeo?: InputMaybe<Scalars['String']>;
  shipMethod?: InputMaybe<Scalars['String']>;
  shipPhone?: InputMaybe<Scalars['String']>;
  shipPostal4?: InputMaybe<Scalars['String']>;
  shipPostalCode?: InputMaybe<Scalars['String']>;
  shipProvinceState?: InputMaybe<Scalars['String']>;
  shipSector?: InputMaybe<Scalars['String']>;
  shipStreet1?: InputMaybe<Scalars['String']>;
  shipStreet2?: InputMaybe<Scalars['String']>;
  sigmaPrimeAddressId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type OrderStddevFields = {
  __typename?: 'OrderStddevFields';
  orderToken?: Maybe<Scalars['Float']>;
  shipFee?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type OrderStddevPopFields = {
  __typename?: 'OrderStddevPopFields';
  orderToken?: Maybe<Scalars['Float']>;
  shipFee?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type OrderStddevSampFields = {
  __typename?: 'OrderStddevSampFields';
  orderToken?: Maybe<Scalars['Float']>;
  shipFee?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "order" */
export type OrderStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrderStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrderStreamCursorValueInput = {
  askId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  orderToken?: InputMaybe<Scalars['bigint']>;
  productDistributionId?: InputMaybe<Scalars['uuid']>;
  putId?: InputMaybe<Scalars['uuid']>;
  shipCountyRegion?: InputMaybe<Scalars['String']>;
  shipEmail?: InputMaybe<Scalars['String']>;
  shipFee?: InputMaybe<Scalars['Int']>;
  shipGeo?: InputMaybe<Scalars['String']>;
  shipMethod?: InputMaybe<Scalars['String']>;
  shipPhone?: InputMaybe<Scalars['String']>;
  shipPostal4?: InputMaybe<Scalars['String']>;
  shipPostalCode?: InputMaybe<Scalars['String']>;
  shipProvinceState?: InputMaybe<Scalars['String']>;
  shipSector?: InputMaybe<Scalars['String']>;
  shipStreet1?: InputMaybe<Scalars['String']>;
  shipStreet2?: InputMaybe<Scalars['String']>;
  sigmaPrimeAddressId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type OrderSumFields = {
  __typename?: 'OrderSumFields';
  orderToken?: Maybe<Scalars['bigint']>;
  shipFee?: Maybe<Scalars['Int']>;
};

/** update columns of table "order" */
export enum OrderUpdateColumn {
  /** column name */
  AskId = 'askId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  OrderToken = 'orderToken',
  /** column name */
  ProductDistributionId = 'productDistributionId',
  /** column name */
  PutId = 'putId',
  /** column name */
  ShipCountyRegion = 'shipCountyRegion',
  /** column name */
  ShipEmail = 'shipEmail',
  /** column name */
  ShipFee = 'shipFee',
  /** column name */
  ShipGeo = 'shipGeo',
  /** column name */
  ShipMethod = 'shipMethod',
  /** column name */
  ShipPhone = 'shipPhone',
  /** column name */
  ShipPostal4 = 'shipPostal4',
  /** column name */
  ShipPostalCode = 'shipPostalCode',
  /** column name */
  ShipProvinceState = 'shipProvinceState',
  /** column name */
  ShipSector = 'shipSector',
  /** column name */
  ShipStreet1 = 'shipStreet1',
  /** column name */
  ShipStreet2 = 'shipStreet2',
  /** column name */
  SigmaPrimeAddressId = 'sigmaPrimeAddressId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type OrderUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrderIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrderSetInput>;
  /** filter the rows which have to be updated */
  where: OrderBoolExp;
};

/** aggregate varPop on columns */
export type OrderVarPopFields = {
  __typename?: 'OrderVarPopFields';
  orderToken?: Maybe<Scalars['Float']>;
  shipFee?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type OrderVarSampFields = {
  __typename?: 'OrderVarSampFields';
  orderToken?: Maybe<Scalars['Float']>;
  shipFee?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrderVarianceFields = {
  __typename?: 'OrderVarianceFields';
  orderToken?: Maybe<Scalars['Float']>;
  shipFee?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "organization" */
export type Organization = {
  __typename?: 'Organization';
  addressRecordType?: Maybe<Scalars['String']>;
  /** An array relationship */
  appraisals: Array<Appraisal>;
  /** An aggregate relationship */
  appraisalsAggregate: AppraisalAggregate;
  authorId?: Maybe<Scalars['String']>;
  carrierRoute?: Maybe<Scalars['String']>;
  /** An array relationship */
  certifications: Array<Certification>;
  /** An aggregate relationship */
  certificationsAggregate: CertificationAggregate;
  comments?: Maybe<Scalars['String']>;
  companyEmployeeCount?: Maybe<Scalars['String']>;
  companyPrimaryWebsite?: Maybe<Scalars['String']>;
  companyRevenue?: Maybe<Scalars['String']>;
  companyTitleName?: Maybe<Scalars['String']>;
  congressionalDistrict?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  crwdTicker?: Maybe<Scalars['String']>;
  crwdsystemsApiReference?: Maybe<Scalars['String']>;
  /** An aggregate relationship */
  currencyOwnersAggregate: CurrencyOwnerAggregate;
  /** An array relationship */
  currencyOwnersByGrantingOrganizationId: Array<CurrencyOwner>;
  /** An aggregate relationship */
  currencyOwnersByGrantingOrganizationIdAggregate: CurrencyOwnerAggregate;
  /** An array relationship */
  currencyOwnersByOriginalOrganizationId: Array<CurrencyOwner>;
  /** An aggregate relationship */
  currencyOwnersByOriginalOrganizationIdAggregate: CurrencyOwnerAggregate;
  /** An array relationship */
  currency_owners: Array<CurrencyOwner>;
  datasourceId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  elotSequence?: Maybe<Scalars['String']>;
  geocode?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  industry?: Maybe<Industry>;
  industryId?: Maybe<Scalars['uuid']>;
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  kind?: Maybe<OrganizationKind>;
  lastDateActive?: Maybe<Scalars['timestamptz']>;
  locationName?: Maybe<Scalars['String']>;
  /** An aggregate relationship */
  merchantOrganizationsAggregate: MerchantOrganizationAggregate;
  /** An array relationship */
  merchant_organizations: Array<MerchantOrganization>;
  name: Scalars['String'];
  organizationHash: Scalars['String'];
  organizationKindId?: Maybe<Scalars['uuid']>;
  organizationPersonStatus?: Maybe<Scalars['String']>;
  organizationPrimaryAddress1?: Maybe<Scalars['String']>;
  organizationPrimaryAddress2?: Maybe<Scalars['String']>;
  organizationPrimaryCity?: Maybe<Scalars['String']>;
  organizationPrimaryCountryCode?: Maybe<Scalars['String']>;
  organizationPrimaryPhone?: Maybe<Scalars['String']>;
  organizationPrimaryPostalCode?: Maybe<Scalars['String']>;
  organizationPrimaryRegion?: Maybe<Scalars['String']>;
  organizationPrimaryState?: Maybe<Scalars['String']>;
  personBusinessEmail?: Maybe<Scalars['String']>;
  personFirstName?: Maybe<Scalars['String']>;
  personLastName?: Maybe<Scalars['String']>;
  personMiddleName?: Maybe<Scalars['String']>;
  personPrimaryMobile?: Maybe<Scalars['String']>;
  personUniqueHash?: Maybe<Scalars['String']>;
  rdi?: Maybe<Scalars['String']>;
  /** An object relationship */
  sicCode?: Maybe<SicCode>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  sub1IndustryId?: Maybe<Scalars['uuid']>;
  sub2IndustryId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  subindustry1?: Maybe<Subindustry>;
  /** An object relationship */
  subindustry2?: Maybe<Subindustry>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  usersAggregate: UserAggregate;
};

/** columns and relationships of "organization" */
export type OrganizationAppraisalsArgs = {
  distinctOn?: InputMaybe<Array<AppraisalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppraisalOrderBy>>;
  where?: InputMaybe<AppraisalBoolExp>;
};

/** columns and relationships of "organization" */
export type OrganizationAppraisalsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AppraisalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppraisalOrderBy>>;
  where?: InputMaybe<AppraisalBoolExp>;
};

/** columns and relationships of "organization" */
export type OrganizationCertificationsArgs = {
  distinctOn?: InputMaybe<Array<CertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CertificationOrderBy>>;
  where?: InputMaybe<CertificationBoolExp>;
};

/** columns and relationships of "organization" */
export type OrganizationCertificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CertificationOrderBy>>;
  where?: InputMaybe<CertificationBoolExp>;
};

/** columns and relationships of "organization" */
export type OrganizationCurrencyOwnersAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencyOwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOwnerOrderBy>>;
  where?: InputMaybe<CurrencyOwnerBoolExp>;
};

/** columns and relationships of "organization" */
export type OrganizationCurrencyOwnersByGrantingOrganizationIdArgs = {
  distinctOn?: InputMaybe<Array<CurrencyOwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOwnerOrderBy>>;
  where?: InputMaybe<CurrencyOwnerBoolExp>;
};

/** columns and relationships of "organization" */
export type OrganizationCurrencyOwnersByGrantingOrganizationIdAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencyOwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOwnerOrderBy>>;
  where?: InputMaybe<CurrencyOwnerBoolExp>;
};

/** columns and relationships of "organization" */
export type OrganizationCurrencyOwnersByOriginalOrganizationIdArgs = {
  distinctOn?: InputMaybe<Array<CurrencyOwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOwnerOrderBy>>;
  where?: InputMaybe<CurrencyOwnerBoolExp>;
};

/** columns and relationships of "organization" */
export type OrganizationCurrencyOwnersByOriginalOrganizationIdAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencyOwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOwnerOrderBy>>;
  where?: InputMaybe<CurrencyOwnerBoolExp>;
};

/** columns and relationships of "organization" */
export type OrganizationCurrency_OwnersArgs = {
  distinctOn?: InputMaybe<Array<CurrencyOwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOwnerOrderBy>>;
  where?: InputMaybe<CurrencyOwnerBoolExp>;
};

/** columns and relationships of "organization" */
export type OrganizationMerchantOrganizationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MerchantOrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantOrganizationOrderBy>>;
  where?: InputMaybe<MerchantOrganizationBoolExp>;
};

/** columns and relationships of "organization" */
export type OrganizationMerchant_OrganizationsArgs = {
  distinctOn?: InputMaybe<Array<MerchantOrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantOrganizationOrderBy>>;
  where?: InputMaybe<MerchantOrganizationBoolExp>;
};

/** columns and relationships of "organization" */
export type OrganizationUsersArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

/** columns and relationships of "organization" */
export type OrganizationUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

/** aggregated selection of "organization" */
export type OrganizationAggregate = {
  __typename?: 'OrganizationAggregate';
  aggregate?: Maybe<OrganizationAggregateFields>;
  nodes: Array<Organization>;
};

export type OrganizationAggregateBoolExp = {
  bool_and?: InputMaybe<OrganizationAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<OrganizationAggregateBoolExpBool_Or>;
  count?: InputMaybe<OrganizationAggregateBoolExpCount>;
};

/** aggregate fields of "organization" */
export type OrganizationAggregateFields = {
  __typename?: 'OrganizationAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<OrganizationMaxFields>;
  min?: Maybe<OrganizationMinFields>;
};

/** aggregate fields of "organization" */
export type OrganizationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization" */
export type OrganizationAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrganizationMaxOrderBy>;
  min?: InputMaybe<OrganizationMinOrderBy>;
};

/** input type for inserting array relation for remote table "organization" */
export type OrganizationArrRelInsertInput = {
  data: Array<OrganizationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrganizationOnConflict>;
};

/** Boolean expression to filter rows from the table "organization". All fields are combined with a logical 'AND'. */
export type OrganizationBoolExp = {
  _and?: InputMaybe<Array<OrganizationBoolExp>>;
  _not?: InputMaybe<OrganizationBoolExp>;
  _or?: InputMaybe<Array<OrganizationBoolExp>>;
  addressRecordType?: InputMaybe<StringComparisonExp>;
  appraisals?: InputMaybe<AppraisalBoolExp>;
  appraisalsAggregate?: InputMaybe<AppraisalAggregateBoolExp>;
  authorId?: InputMaybe<StringComparisonExp>;
  carrierRoute?: InputMaybe<StringComparisonExp>;
  certifications?: InputMaybe<CertificationBoolExp>;
  certificationsAggregate?: InputMaybe<CertificationAggregateBoolExp>;
  comments?: InputMaybe<StringComparisonExp>;
  companyEmployeeCount?: InputMaybe<StringComparisonExp>;
  companyPrimaryWebsite?: InputMaybe<StringComparisonExp>;
  companyRevenue?: InputMaybe<StringComparisonExp>;
  companyTitleName?: InputMaybe<StringComparisonExp>;
  congressionalDistrict?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  crwdTicker?: InputMaybe<StringComparisonExp>;
  crwdsystemsApiReference?: InputMaybe<StringComparisonExp>;
  currencyOwnersByGrantingOrganizationId?: InputMaybe<CurrencyOwnerBoolExp>;
  currencyOwnersByGrantingOrganizationIdAggregate?: InputMaybe<CurrencyOwnerAggregateBoolExp>;
  currencyOwnersByOriginalOrganizationId?: InputMaybe<CurrencyOwnerBoolExp>;
  currencyOwnersByOriginalOrganizationIdAggregate?: InputMaybe<CurrencyOwnerAggregateBoolExp>;
  currency_owners?: InputMaybe<CurrencyOwnerBoolExp>;
  currency_ownersAggregate?: InputMaybe<CurrencyOwnerAggregateBoolExp>;
  datasourceId?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  elotSequence?: InputMaybe<StringComparisonExp>;
  geocode?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  industry?: InputMaybe<IndustryBoolExp>;
  industryId?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  kind?: InputMaybe<OrganizationKindBoolExp>;
  lastDateActive?: InputMaybe<TimestamptzComparisonExp>;
  locationName?: InputMaybe<StringComparisonExp>;
  merchant_organizations?: InputMaybe<MerchantOrganizationBoolExp>;
  merchant_organizationsAggregate?: InputMaybe<MerchantOrganizationAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  organizationHash?: InputMaybe<StringComparisonExp>;
  organizationKindId?: InputMaybe<UuidComparisonExp>;
  organizationPersonStatus?: InputMaybe<StringComparisonExp>;
  organizationPrimaryAddress1?: InputMaybe<StringComparisonExp>;
  organizationPrimaryAddress2?: InputMaybe<StringComparisonExp>;
  organizationPrimaryCity?: InputMaybe<StringComparisonExp>;
  organizationPrimaryCountryCode?: InputMaybe<StringComparisonExp>;
  organizationPrimaryPhone?: InputMaybe<StringComparisonExp>;
  organizationPrimaryPostalCode?: InputMaybe<StringComparisonExp>;
  organizationPrimaryRegion?: InputMaybe<StringComparisonExp>;
  organizationPrimaryState?: InputMaybe<StringComparisonExp>;
  personBusinessEmail?: InputMaybe<StringComparisonExp>;
  personFirstName?: InputMaybe<StringComparisonExp>;
  personLastName?: InputMaybe<StringComparisonExp>;
  personMiddleName?: InputMaybe<StringComparisonExp>;
  personPrimaryMobile?: InputMaybe<StringComparisonExp>;
  personUniqueHash?: InputMaybe<StringComparisonExp>;
  rdi?: InputMaybe<StringComparisonExp>;
  sicCode?: InputMaybe<SicCodeBoolExp>;
  sicCodeId?: InputMaybe<UuidComparisonExp>;
  sub1IndustryId?: InputMaybe<UuidComparisonExp>;
  sub2IndustryId?: InputMaybe<UuidComparisonExp>;
  subindustry1?: InputMaybe<SubindustryBoolExp>;
  subindustry2?: InputMaybe<SubindustryBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  users?: InputMaybe<UserBoolExp>;
  usersAggregate?: InputMaybe<UserAggregateBoolExp>;
};

/** unique or primary key constraints on table "organization" */
export enum OrganizationConstraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationPkey = 'organization_pkey',
  /** unique or primary key constraint on columns "id" */
  OrganizationXidKey = 'organization_xid_key',
}

/** input type for inserting data into table "organization" */
export type OrganizationInsertInput = {
  addressRecordType?: InputMaybe<Scalars['String']>;
  appraisals?: InputMaybe<AppraisalArrRelInsertInput>;
  authorId?: InputMaybe<Scalars['String']>;
  carrierRoute?: InputMaybe<Scalars['String']>;
  certifications?: InputMaybe<CertificationArrRelInsertInput>;
  comments?: InputMaybe<Scalars['String']>;
  companyEmployeeCount?: InputMaybe<Scalars['String']>;
  companyPrimaryWebsite?: InputMaybe<Scalars['String']>;
  companyRevenue?: InputMaybe<Scalars['String']>;
  companyTitleName?: InputMaybe<Scalars['String']>;
  congressionalDistrict?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdTicker?: InputMaybe<Scalars['String']>;
  crwdsystemsApiReference?: InputMaybe<Scalars['String']>;
  currencyOwnersByGrantingOrganizationId?: InputMaybe<CurrencyOwnerArrRelInsertInput>;
  currencyOwnersByOriginalOrganizationId?: InputMaybe<CurrencyOwnerArrRelInsertInput>;
  currency_owners?: InputMaybe<CurrencyOwnerArrRelInsertInput>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  elotSequence?: InputMaybe<Scalars['String']>;
  geocode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  industry?: InputMaybe<IndustryObjRelInsertInput>;
  industryId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<OrganizationKindObjRelInsertInput>;
  lastDateActive?: InputMaybe<Scalars['timestamptz']>;
  locationName?: InputMaybe<Scalars['String']>;
  merchant_organizations?: InputMaybe<MerchantOrganizationArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  organizationHash?: InputMaybe<Scalars['String']>;
  organizationKindId?: InputMaybe<Scalars['uuid']>;
  organizationPersonStatus?: InputMaybe<Scalars['String']>;
  organizationPrimaryAddress1?: InputMaybe<Scalars['String']>;
  organizationPrimaryAddress2?: InputMaybe<Scalars['String']>;
  organizationPrimaryCity?: InputMaybe<Scalars['String']>;
  organizationPrimaryCountryCode?: InputMaybe<Scalars['String']>;
  organizationPrimaryPhone?: InputMaybe<Scalars['String']>;
  organizationPrimaryPostalCode?: InputMaybe<Scalars['String']>;
  organizationPrimaryRegion?: InputMaybe<Scalars['String']>;
  organizationPrimaryState?: InputMaybe<Scalars['String']>;
  personBusinessEmail?: InputMaybe<Scalars['String']>;
  personFirstName?: InputMaybe<Scalars['String']>;
  personLastName?: InputMaybe<Scalars['String']>;
  personMiddleName?: InputMaybe<Scalars['String']>;
  personPrimaryMobile?: InputMaybe<Scalars['String']>;
  personUniqueHash?: InputMaybe<Scalars['String']>;
  rdi?: InputMaybe<Scalars['String']>;
  sicCode?: InputMaybe<SicCodeObjRelInsertInput>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  sub1IndustryId?: InputMaybe<Scalars['uuid']>;
  sub2IndustryId?: InputMaybe<Scalars['uuid']>;
  subindustry1?: InputMaybe<SubindustryObjRelInsertInput>;
  subindustry2?: InputMaybe<SubindustryObjRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  users?: InputMaybe<UserArrRelInsertInput>;
};

/** columns and relationships of "organization_kind" */
export type OrganizationKind = {
  __typename?: 'OrganizationKind';
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** An array relationship */
  organizations: Array<Organization>;
  /** An aggregate relationship */
  organizationsAggregate: OrganizationAggregate;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "organization_kind" */
export type OrganizationKindOrganizationsArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};

/** columns and relationships of "organization_kind" */
export type OrganizationKindOrganizationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};

/** aggregated selection of "organization_kind" */
export type OrganizationKindAggregate = {
  __typename?: 'OrganizationKindAggregate';
  aggregate?: Maybe<OrganizationKindAggregateFields>;
  nodes: Array<OrganizationKind>;
};

/** aggregate fields of "organization_kind" */
export type OrganizationKindAggregateFields = {
  __typename?: 'OrganizationKindAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<OrganizationKindMaxFields>;
  min?: Maybe<OrganizationKindMinFields>;
};

/** aggregate fields of "organization_kind" */
export type OrganizationKindAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "organization_kind". All fields are combined with a logical 'AND'. */
export type OrganizationKindBoolExp = {
  _and?: InputMaybe<Array<OrganizationKindBoolExp>>;
  _not?: InputMaybe<OrganizationKindBoolExp>;
  _or?: InputMaybe<Array<OrganizationKindBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organizations?: InputMaybe<OrganizationBoolExp>;
  organizationsAggregate?: InputMaybe<OrganizationAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "organization_kind" */
export enum OrganizationKindConstraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationKindPkey = 'organization_kind_pkey',
}

/** input type for inserting data into table "organization_kind" */
export type OrganizationKindInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizations?: InputMaybe<OrganizationArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type OrganizationKindMaxFields = {
  __typename?: 'OrganizationKindMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type OrganizationKindMinFields = {
  __typename?: 'OrganizationKindMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "organization_kind" */
export type OrganizationKindMutationResponse = {
  __typename?: 'OrganizationKindMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrganizationKind>;
};

/** input type for inserting object relation for remote table "organization_kind" */
export type OrganizationKindObjRelInsertInput = {
  data: OrganizationKindInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrganizationKindOnConflict>;
};

/** on_conflict condition type for table "organization_kind" */
export type OrganizationKindOnConflict = {
  constraint: OrganizationKindConstraint;
  updateColumns?: Array<OrganizationKindUpdateColumn>;
  where?: InputMaybe<OrganizationKindBoolExp>;
};

/** Ordering options when selecting data from "organization_kind". */
export type OrganizationKindOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationsAggregate?: InputMaybe<OrganizationAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: organization_kind */
export type OrganizationKindPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_kind" */
export enum OrganizationKindSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "organization_kind" */
export type OrganizationKindSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "organization_kind" */
export type OrganizationKindStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrganizationKindStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationKindStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "organization_kind" */
export enum OrganizationKindUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type OrganizationKindUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizationKindSetInput>;
  /** filter the rows which have to be updated */
  where: OrganizationKindBoolExp;
};

/** aggregate max on columns */
export type OrganizationMaxFields = {
  __typename?: 'OrganizationMaxFields';
  addressRecordType?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  carrierRoute?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  companyEmployeeCount?: Maybe<Scalars['String']>;
  companyPrimaryWebsite?: Maybe<Scalars['String']>;
  companyRevenue?: Maybe<Scalars['String']>;
  companyTitleName?: Maybe<Scalars['String']>;
  congressionalDistrict?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crwdTicker?: Maybe<Scalars['String']>;
  crwdsystemsApiReference?: Maybe<Scalars['String']>;
  datasourceId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  elotSequence?: Maybe<Scalars['String']>;
  geocode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  industryId?: Maybe<Scalars['uuid']>;
  lastDateActive?: Maybe<Scalars['timestamptz']>;
  locationName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationHash?: Maybe<Scalars['String']>;
  organizationKindId?: Maybe<Scalars['uuid']>;
  organizationPersonStatus?: Maybe<Scalars['String']>;
  organizationPrimaryAddress1?: Maybe<Scalars['String']>;
  organizationPrimaryAddress2?: Maybe<Scalars['String']>;
  organizationPrimaryCity?: Maybe<Scalars['String']>;
  organizationPrimaryCountryCode?: Maybe<Scalars['String']>;
  organizationPrimaryPhone?: Maybe<Scalars['String']>;
  organizationPrimaryPostalCode?: Maybe<Scalars['String']>;
  organizationPrimaryRegion?: Maybe<Scalars['String']>;
  organizationPrimaryState?: Maybe<Scalars['String']>;
  personBusinessEmail?: Maybe<Scalars['String']>;
  personFirstName?: Maybe<Scalars['String']>;
  personLastName?: Maybe<Scalars['String']>;
  personMiddleName?: Maybe<Scalars['String']>;
  personPrimaryMobile?: Maybe<Scalars['String']>;
  personUniqueHash?: Maybe<Scalars['String']>;
  rdi?: Maybe<Scalars['String']>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  sub1IndustryId?: Maybe<Scalars['uuid']>;
  sub2IndustryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "organization" */
export type OrganizationMaxOrderBy = {
  addressRecordType?: InputMaybe<OrderBy>;
  authorId?: InputMaybe<OrderBy>;
  carrierRoute?: InputMaybe<OrderBy>;
  comments?: InputMaybe<OrderBy>;
  companyEmployeeCount?: InputMaybe<OrderBy>;
  companyPrimaryWebsite?: InputMaybe<OrderBy>;
  companyRevenue?: InputMaybe<OrderBy>;
  companyTitleName?: InputMaybe<OrderBy>;
  congressionalDistrict?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  crwdTicker?: InputMaybe<OrderBy>;
  crwdsystemsApiReference?: InputMaybe<OrderBy>;
  datasourceId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  elotSequence?: InputMaybe<OrderBy>;
  geocode?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  industryId?: InputMaybe<OrderBy>;
  lastDateActive?: InputMaybe<OrderBy>;
  locationName?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationHash?: InputMaybe<OrderBy>;
  organizationKindId?: InputMaybe<OrderBy>;
  organizationPersonStatus?: InputMaybe<OrderBy>;
  organizationPrimaryAddress1?: InputMaybe<OrderBy>;
  organizationPrimaryAddress2?: InputMaybe<OrderBy>;
  organizationPrimaryCity?: InputMaybe<OrderBy>;
  organizationPrimaryCountryCode?: InputMaybe<OrderBy>;
  organizationPrimaryPhone?: InputMaybe<OrderBy>;
  organizationPrimaryPostalCode?: InputMaybe<OrderBy>;
  organizationPrimaryRegion?: InputMaybe<OrderBy>;
  organizationPrimaryState?: InputMaybe<OrderBy>;
  personBusinessEmail?: InputMaybe<OrderBy>;
  personFirstName?: InputMaybe<OrderBy>;
  personLastName?: InputMaybe<OrderBy>;
  personMiddleName?: InputMaybe<OrderBy>;
  personPrimaryMobile?: InputMaybe<OrderBy>;
  personUniqueHash?: InputMaybe<OrderBy>;
  rdi?: InputMaybe<OrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  sub1IndustryId?: InputMaybe<OrderBy>;
  sub2IndustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OrganizationMinFields = {
  __typename?: 'OrganizationMinFields';
  addressRecordType?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  carrierRoute?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  companyEmployeeCount?: Maybe<Scalars['String']>;
  companyPrimaryWebsite?: Maybe<Scalars['String']>;
  companyRevenue?: Maybe<Scalars['String']>;
  companyTitleName?: Maybe<Scalars['String']>;
  congressionalDistrict?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crwdTicker?: Maybe<Scalars['String']>;
  crwdsystemsApiReference?: Maybe<Scalars['String']>;
  datasourceId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  elotSequence?: Maybe<Scalars['String']>;
  geocode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  industryId?: Maybe<Scalars['uuid']>;
  lastDateActive?: Maybe<Scalars['timestamptz']>;
  locationName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationHash?: Maybe<Scalars['String']>;
  organizationKindId?: Maybe<Scalars['uuid']>;
  organizationPersonStatus?: Maybe<Scalars['String']>;
  organizationPrimaryAddress1?: Maybe<Scalars['String']>;
  organizationPrimaryAddress2?: Maybe<Scalars['String']>;
  organizationPrimaryCity?: Maybe<Scalars['String']>;
  organizationPrimaryCountryCode?: Maybe<Scalars['String']>;
  organizationPrimaryPhone?: Maybe<Scalars['String']>;
  organizationPrimaryPostalCode?: Maybe<Scalars['String']>;
  organizationPrimaryRegion?: Maybe<Scalars['String']>;
  organizationPrimaryState?: Maybe<Scalars['String']>;
  personBusinessEmail?: Maybe<Scalars['String']>;
  personFirstName?: Maybe<Scalars['String']>;
  personLastName?: Maybe<Scalars['String']>;
  personMiddleName?: Maybe<Scalars['String']>;
  personPrimaryMobile?: Maybe<Scalars['String']>;
  personUniqueHash?: Maybe<Scalars['String']>;
  rdi?: Maybe<Scalars['String']>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  sub1IndustryId?: Maybe<Scalars['uuid']>;
  sub2IndustryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "organization" */
export type OrganizationMinOrderBy = {
  addressRecordType?: InputMaybe<OrderBy>;
  authorId?: InputMaybe<OrderBy>;
  carrierRoute?: InputMaybe<OrderBy>;
  comments?: InputMaybe<OrderBy>;
  companyEmployeeCount?: InputMaybe<OrderBy>;
  companyPrimaryWebsite?: InputMaybe<OrderBy>;
  companyRevenue?: InputMaybe<OrderBy>;
  companyTitleName?: InputMaybe<OrderBy>;
  congressionalDistrict?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  crwdTicker?: InputMaybe<OrderBy>;
  crwdsystemsApiReference?: InputMaybe<OrderBy>;
  datasourceId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  elotSequence?: InputMaybe<OrderBy>;
  geocode?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  industryId?: InputMaybe<OrderBy>;
  lastDateActive?: InputMaybe<OrderBy>;
  locationName?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationHash?: InputMaybe<OrderBy>;
  organizationKindId?: InputMaybe<OrderBy>;
  organizationPersonStatus?: InputMaybe<OrderBy>;
  organizationPrimaryAddress1?: InputMaybe<OrderBy>;
  organizationPrimaryAddress2?: InputMaybe<OrderBy>;
  organizationPrimaryCity?: InputMaybe<OrderBy>;
  organizationPrimaryCountryCode?: InputMaybe<OrderBy>;
  organizationPrimaryPhone?: InputMaybe<OrderBy>;
  organizationPrimaryPostalCode?: InputMaybe<OrderBy>;
  organizationPrimaryRegion?: InputMaybe<OrderBy>;
  organizationPrimaryState?: InputMaybe<OrderBy>;
  personBusinessEmail?: InputMaybe<OrderBy>;
  personFirstName?: InputMaybe<OrderBy>;
  personLastName?: InputMaybe<OrderBy>;
  personMiddleName?: InputMaybe<OrderBy>;
  personPrimaryMobile?: InputMaybe<OrderBy>;
  personUniqueHash?: InputMaybe<OrderBy>;
  rdi?: InputMaybe<OrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  sub1IndustryId?: InputMaybe<OrderBy>;
  sub2IndustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "organization" */
export type OrganizationMutationResponse = {
  __typename?: 'OrganizationMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization>;
};

/** input type for inserting object relation for remote table "organization" */
export type OrganizationObjRelInsertInput = {
  data: OrganizationInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrganizationOnConflict>;
};

/** on_conflict condition type for table "organization" */
export type OrganizationOnConflict = {
  constraint: OrganizationConstraint;
  updateColumns?: Array<OrganizationUpdateColumn>;
  where?: InputMaybe<OrganizationBoolExp>;
};

/** Ordering options when selecting data from "organization". */
export type OrganizationOrderBy = {
  addressRecordType?: InputMaybe<OrderBy>;
  appraisalsAggregate?: InputMaybe<AppraisalAggregateOrderBy>;
  authorId?: InputMaybe<OrderBy>;
  carrierRoute?: InputMaybe<OrderBy>;
  certificationsAggregate?: InputMaybe<CertificationAggregateOrderBy>;
  comments?: InputMaybe<OrderBy>;
  companyEmployeeCount?: InputMaybe<OrderBy>;
  companyPrimaryWebsite?: InputMaybe<OrderBy>;
  companyRevenue?: InputMaybe<OrderBy>;
  companyTitleName?: InputMaybe<OrderBy>;
  congressionalDistrict?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  crwdTicker?: InputMaybe<OrderBy>;
  crwdsystemsApiReference?: InputMaybe<OrderBy>;
  currencyOwnersByGrantingOrganizationIdAggregate?: InputMaybe<CurrencyOwnerAggregateOrderBy>;
  currencyOwnersByOriginalOrganizationIdAggregate?: InputMaybe<CurrencyOwnerAggregateOrderBy>;
  currency_ownersAggregate?: InputMaybe<CurrencyOwnerAggregateOrderBy>;
  datasourceId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  elotSequence?: InputMaybe<OrderBy>;
  geocode?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  industry?: InputMaybe<IndustryOrderBy>;
  industryId?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  kind?: InputMaybe<OrganizationKindOrderBy>;
  lastDateActive?: InputMaybe<OrderBy>;
  locationName?: InputMaybe<OrderBy>;
  merchant_organizationsAggregate?: InputMaybe<MerchantOrganizationAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationHash?: InputMaybe<OrderBy>;
  organizationKindId?: InputMaybe<OrderBy>;
  organizationPersonStatus?: InputMaybe<OrderBy>;
  organizationPrimaryAddress1?: InputMaybe<OrderBy>;
  organizationPrimaryAddress2?: InputMaybe<OrderBy>;
  organizationPrimaryCity?: InputMaybe<OrderBy>;
  organizationPrimaryCountryCode?: InputMaybe<OrderBy>;
  organizationPrimaryPhone?: InputMaybe<OrderBy>;
  organizationPrimaryPostalCode?: InputMaybe<OrderBy>;
  organizationPrimaryRegion?: InputMaybe<OrderBy>;
  organizationPrimaryState?: InputMaybe<OrderBy>;
  personBusinessEmail?: InputMaybe<OrderBy>;
  personFirstName?: InputMaybe<OrderBy>;
  personLastName?: InputMaybe<OrderBy>;
  personMiddleName?: InputMaybe<OrderBy>;
  personPrimaryMobile?: InputMaybe<OrderBy>;
  personUniqueHash?: InputMaybe<OrderBy>;
  rdi?: InputMaybe<OrderBy>;
  sicCode?: InputMaybe<SicCodeOrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  sub1IndustryId?: InputMaybe<OrderBy>;
  sub2IndustryId?: InputMaybe<OrderBy>;
  subindustry1?: InputMaybe<SubindustryOrderBy>;
  subindustry2?: InputMaybe<SubindustryOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  usersAggregate?: InputMaybe<UserAggregateOrderBy>;
};

/** columns and relationships of "organization_person" */
export type OrganizationPerson = {
  __typename?: 'OrganizationPerson';
  comments?: Maybe<Scalars['String']>;
  companyTitleName: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  datasource?: Maybe<Datasource>;
  datasourceId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['timestamptz'];
  id: Scalars['uuid'];
  lastDateActive?: Maybe<Scalars['timestamptz']>;
  locationName?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization: Organization;
  organizationId: Scalars['uuid'];
  /** An object relationship */
  sigmaPrime: SigmaPrime;
  sigmaPrimeId: Scalars['uuid'];
  startDate: Scalars['timestamptz'];
  subIndustry1Id?: Maybe<Scalars['uuid']>;
  subIndustry2Id?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "organization_person" */
export type OrganizationPersonAggregate = {
  __typename?: 'OrganizationPersonAggregate';
  aggregate?: Maybe<OrganizationPersonAggregateFields>;
  nodes: Array<OrganizationPerson>;
};

/** aggregate fields of "organization_person" */
export type OrganizationPersonAggregateFields = {
  __typename?: 'OrganizationPersonAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<OrganizationPersonMaxFields>;
  min?: Maybe<OrganizationPersonMinFields>;
};

/** aggregate fields of "organization_person" */
export type OrganizationPersonAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationPersonSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "organization_person". All fields are combined with a logical 'AND'. */
export type OrganizationPersonBoolExp = {
  _and?: InputMaybe<Array<OrganizationPersonBoolExp>>;
  _not?: InputMaybe<OrganizationPersonBoolExp>;
  _or?: InputMaybe<Array<OrganizationPersonBoolExp>>;
  comments?: InputMaybe<StringComparisonExp>;
  companyTitleName?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  datasource?: InputMaybe<DatasourceBoolExp>;
  datasourceId?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  endDate?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lastDateActive?: InputMaybe<TimestamptzComparisonExp>;
  locationName?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  sigmaPrime?: InputMaybe<SigmaPrimeBoolExp>;
  sigmaPrimeId?: InputMaybe<UuidComparisonExp>;
  startDate?: InputMaybe<TimestamptzComparisonExp>;
  subIndustry1Id?: InputMaybe<UuidComparisonExp>;
  subIndustry2Id?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "organization_person" */
export enum OrganizationPersonConstraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationPersonPkey = 'organization_person_pkey',
}

/** input type for inserting data into table "organization_person" */
export type OrganizationPersonInsertInput = {
  comments?: InputMaybe<Scalars['String']>;
  companyTitleName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  datasource?: InputMaybe<DatasourceObjRelInsertInput>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastDateActive?: InputMaybe<Scalars['timestamptz']>;
  locationName?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  sigmaPrime?: InputMaybe<SigmaPrimeObjRelInsertInput>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  subIndustry1Id?: InputMaybe<Scalars['uuid']>;
  subIndustry2Id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type OrganizationPersonMaxFields = {
  __typename?: 'OrganizationPersonMaxFields';
  comments?: Maybe<Scalars['String']>;
  companyTitleName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datasourceId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lastDateActive?: Maybe<Scalars['timestamptz']>;
  locationName?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  subIndustry1Id?: Maybe<Scalars['uuid']>;
  subIndustry2Id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type OrganizationPersonMinFields = {
  __typename?: 'OrganizationPersonMinFields';
  comments?: Maybe<Scalars['String']>;
  companyTitleName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  datasourceId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lastDateActive?: Maybe<Scalars['timestamptz']>;
  locationName?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  subIndustry1Id?: Maybe<Scalars['uuid']>;
  subIndustry2Id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "organization_person" */
export type OrganizationPersonMutationResponse = {
  __typename?: 'OrganizationPersonMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrganizationPerson>;
};

/** on_conflict condition type for table "organization_person" */
export type OrganizationPersonOnConflict = {
  constraint: OrganizationPersonConstraint;
  updateColumns?: Array<OrganizationPersonUpdateColumn>;
  where?: InputMaybe<OrganizationPersonBoolExp>;
};

/** Ordering options when selecting data from "organization_person". */
export type OrganizationPersonOrderBy = {
  comments?: InputMaybe<OrderBy>;
  companyTitleName?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  datasource?: InputMaybe<DatasourceOrderBy>;
  datasourceId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastDateActive?: InputMaybe<OrderBy>;
  locationName?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  sigmaPrime?: InputMaybe<SigmaPrimeOrderBy>;
  sigmaPrimeId?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  subIndustry1Id?: InputMaybe<OrderBy>;
  subIndustry2Id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: organization_person */
export type OrganizationPersonPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_person" */
export enum OrganizationPersonSelectColumn {
  /** column name */
  Comments = 'comments',
  /** column name */
  CompanyTitleName = 'companyTitleName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatasourceId = 'datasourceId',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  LastDateActive = 'lastDateActive',
  /** column name */
  LocationName = 'locationName',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SigmaPrimeId = 'sigmaPrimeId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  SubIndustry1Id = 'subIndustry1Id',
  /** column name */
  SubIndustry2Id = 'subIndustry2Id',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "organization_person" */
export type OrganizationPersonSetInput = {
  comments?: InputMaybe<Scalars['String']>;
  companyTitleName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastDateActive?: InputMaybe<Scalars['timestamptz']>;
  locationName?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  subIndustry1Id?: InputMaybe<Scalars['uuid']>;
  subIndustry2Id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "organization_person" */
export type OrganizationPersonStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrganizationPersonStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationPersonStreamCursorValueInput = {
  comments?: InputMaybe<Scalars['String']>;
  companyTitleName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastDateActive?: InputMaybe<Scalars['timestamptz']>;
  locationName?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  subIndustry1Id?: InputMaybe<Scalars['uuid']>;
  subIndustry2Id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "organization_person" */
export enum OrganizationPersonUpdateColumn {
  /** column name */
  Comments = 'comments',
  /** column name */
  CompanyTitleName = 'companyTitleName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatasourceId = 'datasourceId',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  LastDateActive = 'lastDateActive',
  /** column name */
  LocationName = 'locationName',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SigmaPrimeId = 'sigmaPrimeId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  SubIndustry1Id = 'subIndustry1Id',
  /** column name */
  SubIndustry2Id = 'subIndustry2Id',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type OrganizationPersonUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizationPersonSetInput>;
  /** filter the rows which have to be updated */
  where: OrganizationPersonBoolExp;
};

/** primary key columns input for table: organization */
export type OrganizationPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "organization" */
export enum OrganizationSelectColumn {
  /** column name */
  AddressRecordType = 'addressRecordType',
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  CarrierRoute = 'carrierRoute',
  /** column name */
  Comments = 'comments',
  /** column name */
  CompanyEmployeeCount = 'companyEmployeeCount',
  /** column name */
  CompanyPrimaryWebsite = 'companyPrimaryWebsite',
  /** column name */
  CompanyRevenue = 'companyRevenue',
  /** column name */
  CompanyTitleName = 'companyTitleName',
  /** column name */
  CongressionalDistrict = 'congressionalDistrict',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrwdTicker = 'crwdTicker',
  /** column name */
  CrwdsystemsApiReference = 'crwdsystemsApiReference',
  /** column name */
  DatasourceId = 'datasourceId',
  /** column name */
  Description = 'description',
  /** column name */
  ElotSequence = 'elotSequence',
  /** column name */
  Geocode = 'geocode',
  /** column name */
  Id = 'id',
  /** column name */
  IndustryId = 'industryId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  LastDateActive = 'lastDateActive',
  /** column name */
  LocationName = 'locationName',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationHash = 'organizationHash',
  /** column name */
  OrganizationKindId = 'organizationKindId',
  /** column name */
  OrganizationPersonStatus = 'organizationPersonStatus',
  /** column name */
  OrganizationPrimaryAddress1 = 'organizationPrimaryAddress1',
  /** column name */
  OrganizationPrimaryAddress2 = 'organizationPrimaryAddress2',
  /** column name */
  OrganizationPrimaryCity = 'organizationPrimaryCity',
  /** column name */
  OrganizationPrimaryCountryCode = 'organizationPrimaryCountryCode',
  /** column name */
  OrganizationPrimaryPhone = 'organizationPrimaryPhone',
  /** column name */
  OrganizationPrimaryPostalCode = 'organizationPrimaryPostalCode',
  /** column name */
  OrganizationPrimaryRegion = 'organizationPrimaryRegion',
  /** column name */
  OrganizationPrimaryState = 'organizationPrimaryState',
  /** column name */
  PersonBusinessEmail = 'personBusinessEmail',
  /** column name */
  PersonFirstName = 'personFirstName',
  /** column name */
  PersonLastName = 'personLastName',
  /** column name */
  PersonMiddleName = 'personMiddleName',
  /** column name */
  PersonPrimaryMobile = 'personPrimaryMobile',
  /** column name */
  PersonUniqueHash = 'personUniqueHash',
  /** column name */
  Rdi = 'rdi',
  /** column name */
  SicCodeId = 'sicCodeId',
  /** column name */
  Sub1IndustryId = 'sub1IndustryId',
  /** column name */
  Sub2IndustryId = 'sub2IndustryId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "organizationAggregateBoolExpBool_andArgumentsColumns" columns of table "organization" */
export enum OrganizationSelectColumnOrganizationAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "organizationAggregateBoolExpBool_orArgumentsColumns" columns of table "organization" */
export enum OrganizationSelectColumnOrganizationAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "organization" */
export type OrganizationSetInput = {
  addressRecordType?: InputMaybe<Scalars['String']>;
  authorId?: InputMaybe<Scalars['String']>;
  carrierRoute?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  companyEmployeeCount?: InputMaybe<Scalars['String']>;
  companyPrimaryWebsite?: InputMaybe<Scalars['String']>;
  companyRevenue?: InputMaybe<Scalars['String']>;
  companyTitleName?: InputMaybe<Scalars['String']>;
  congressionalDistrict?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdTicker?: InputMaybe<Scalars['String']>;
  crwdsystemsApiReference?: InputMaybe<Scalars['String']>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  elotSequence?: InputMaybe<Scalars['String']>;
  geocode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  industryId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lastDateActive?: InputMaybe<Scalars['timestamptz']>;
  locationName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationHash?: InputMaybe<Scalars['String']>;
  organizationKindId?: InputMaybe<Scalars['uuid']>;
  organizationPersonStatus?: InputMaybe<Scalars['String']>;
  organizationPrimaryAddress1?: InputMaybe<Scalars['String']>;
  organizationPrimaryAddress2?: InputMaybe<Scalars['String']>;
  organizationPrimaryCity?: InputMaybe<Scalars['String']>;
  organizationPrimaryCountryCode?: InputMaybe<Scalars['String']>;
  organizationPrimaryPhone?: InputMaybe<Scalars['String']>;
  organizationPrimaryPostalCode?: InputMaybe<Scalars['String']>;
  organizationPrimaryRegion?: InputMaybe<Scalars['String']>;
  organizationPrimaryState?: InputMaybe<Scalars['String']>;
  personBusinessEmail?: InputMaybe<Scalars['String']>;
  personFirstName?: InputMaybe<Scalars['String']>;
  personLastName?: InputMaybe<Scalars['String']>;
  personMiddleName?: InputMaybe<Scalars['String']>;
  personPrimaryMobile?: InputMaybe<Scalars['String']>;
  personUniqueHash?: InputMaybe<Scalars['String']>;
  rdi?: InputMaybe<Scalars['String']>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  sub1IndustryId?: InputMaybe<Scalars['uuid']>;
  sub2IndustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "organization_status" */
export type OrganizationStatus = {
  __typename?: 'OrganizationStatus';
  /** An object relationship */
  clearinghouse?: Maybe<Clearinghouse>;
  clearinghouseId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organizationId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "organization_status" */
export type OrganizationStatusAggregate = {
  __typename?: 'OrganizationStatusAggregate';
  aggregate?: Maybe<OrganizationStatusAggregateFields>;
  nodes: Array<OrganizationStatus>;
};

/** aggregate fields of "organization_status" */
export type OrganizationStatusAggregateFields = {
  __typename?: 'OrganizationStatusAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<OrganizationStatusMaxFields>;
  min?: Maybe<OrganizationStatusMinFields>;
};

/** aggregate fields of "organization_status" */
export type OrganizationStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "organization_status". All fields are combined with a logical 'AND'. */
export type OrganizationStatusBoolExp = {
  _and?: InputMaybe<Array<OrganizationStatusBoolExp>>;
  _not?: InputMaybe<OrganizationStatusBoolExp>;
  _or?: InputMaybe<Array<OrganizationStatusBoolExp>>;
  clearinghouse?: InputMaybe<ClearinghouseBoolExp>;
  clearinghouseId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "organization_status" */
export enum OrganizationStatusConstraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationStatusPkey = 'organization_status_pkey',
}

/** input type for inserting data into table "organization_status" */
export type OrganizationStatusInsertInput = {
  clearinghouse?: InputMaybe<ClearinghouseObjRelInsertInput>;
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type OrganizationStatusMaxFields = {
  __typename?: 'OrganizationStatusMaxFields';
  clearinghouseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type OrganizationStatusMinFields = {
  __typename?: 'OrganizationStatusMinFields';
  clearinghouseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "organization_status" */
export type OrganizationStatusMutationResponse = {
  __typename?: 'OrganizationStatusMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrganizationStatus>;
};

/** on_conflict condition type for table "organization_status" */
export type OrganizationStatusOnConflict = {
  constraint: OrganizationStatusConstraint;
  updateColumns?: Array<OrganizationStatusUpdateColumn>;
  where?: InputMaybe<OrganizationStatusBoolExp>;
};

/** Ordering options when selecting data from "organization_status". */
export type OrganizationStatusOrderBy = {
  clearinghouse?: InputMaybe<ClearinghouseOrderBy>;
  clearinghouseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: organization_status */
export type OrganizationStatusPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_status" */
export enum OrganizationStatusSelectColumn {
  /** column name */
  ClearinghouseId = 'clearinghouseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "organization_status" */
export type OrganizationStatusSetInput = {
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "organization_status" */
export type OrganizationStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrganizationStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationStatusStreamCursorValueInput = {
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "organization_status" */
export enum OrganizationStatusUpdateColumn {
  /** column name */
  ClearinghouseId = 'clearinghouseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type OrganizationStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizationStatusSetInput>;
  /** filter the rows which have to be updated */
  where: OrganizationStatusBoolExp;
};

/** Streaming cursor of the table "organization" */
export type OrganizationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrganizationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationStreamCursorValueInput = {
  addressRecordType?: InputMaybe<Scalars['String']>;
  authorId?: InputMaybe<Scalars['String']>;
  carrierRoute?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  companyEmployeeCount?: InputMaybe<Scalars['String']>;
  companyPrimaryWebsite?: InputMaybe<Scalars['String']>;
  companyRevenue?: InputMaybe<Scalars['String']>;
  companyTitleName?: InputMaybe<Scalars['String']>;
  congressionalDistrict?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdTicker?: InputMaybe<Scalars['String']>;
  crwdsystemsApiReference?: InputMaybe<Scalars['String']>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  elotSequence?: InputMaybe<Scalars['String']>;
  geocode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  industryId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lastDateActive?: InputMaybe<Scalars['timestamptz']>;
  locationName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationHash?: InputMaybe<Scalars['String']>;
  organizationKindId?: InputMaybe<Scalars['uuid']>;
  organizationPersonStatus?: InputMaybe<Scalars['String']>;
  organizationPrimaryAddress1?: InputMaybe<Scalars['String']>;
  organizationPrimaryAddress2?: InputMaybe<Scalars['String']>;
  organizationPrimaryCity?: InputMaybe<Scalars['String']>;
  organizationPrimaryCountryCode?: InputMaybe<Scalars['String']>;
  organizationPrimaryPhone?: InputMaybe<Scalars['String']>;
  organizationPrimaryPostalCode?: InputMaybe<Scalars['String']>;
  organizationPrimaryRegion?: InputMaybe<Scalars['String']>;
  organizationPrimaryState?: InputMaybe<Scalars['String']>;
  personBusinessEmail?: InputMaybe<Scalars['String']>;
  personFirstName?: InputMaybe<Scalars['String']>;
  personLastName?: InputMaybe<Scalars['String']>;
  personMiddleName?: InputMaybe<Scalars['String']>;
  personPrimaryMobile?: InputMaybe<Scalars['String']>;
  personUniqueHash?: InputMaybe<Scalars['String']>;
  rdi?: InputMaybe<Scalars['String']>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  sub1IndustryId?: InputMaybe<Scalars['uuid']>;
  sub2IndustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "organization" */
export enum OrganizationUpdateColumn {
  /** column name */
  AddressRecordType = 'addressRecordType',
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  CarrierRoute = 'carrierRoute',
  /** column name */
  Comments = 'comments',
  /** column name */
  CompanyEmployeeCount = 'companyEmployeeCount',
  /** column name */
  CompanyPrimaryWebsite = 'companyPrimaryWebsite',
  /** column name */
  CompanyRevenue = 'companyRevenue',
  /** column name */
  CompanyTitleName = 'companyTitleName',
  /** column name */
  CongressionalDistrict = 'congressionalDistrict',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrwdTicker = 'crwdTicker',
  /** column name */
  CrwdsystemsApiReference = 'crwdsystemsApiReference',
  /** column name */
  DatasourceId = 'datasourceId',
  /** column name */
  Description = 'description',
  /** column name */
  ElotSequence = 'elotSequence',
  /** column name */
  Geocode = 'geocode',
  /** column name */
  Id = 'id',
  /** column name */
  IndustryId = 'industryId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  LastDateActive = 'lastDateActive',
  /** column name */
  LocationName = 'locationName',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationHash = 'organizationHash',
  /** column name */
  OrganizationKindId = 'organizationKindId',
  /** column name */
  OrganizationPersonStatus = 'organizationPersonStatus',
  /** column name */
  OrganizationPrimaryAddress1 = 'organizationPrimaryAddress1',
  /** column name */
  OrganizationPrimaryAddress2 = 'organizationPrimaryAddress2',
  /** column name */
  OrganizationPrimaryCity = 'organizationPrimaryCity',
  /** column name */
  OrganizationPrimaryCountryCode = 'organizationPrimaryCountryCode',
  /** column name */
  OrganizationPrimaryPhone = 'organizationPrimaryPhone',
  /** column name */
  OrganizationPrimaryPostalCode = 'organizationPrimaryPostalCode',
  /** column name */
  OrganizationPrimaryRegion = 'organizationPrimaryRegion',
  /** column name */
  OrganizationPrimaryState = 'organizationPrimaryState',
  /** column name */
  PersonBusinessEmail = 'personBusinessEmail',
  /** column name */
  PersonFirstName = 'personFirstName',
  /** column name */
  PersonLastName = 'personLastName',
  /** column name */
  PersonMiddleName = 'personMiddleName',
  /** column name */
  PersonPrimaryMobile = 'personPrimaryMobile',
  /** column name */
  PersonUniqueHash = 'personUniqueHash',
  /** column name */
  Rdi = 'rdi',
  /** column name */
  SicCodeId = 'sicCodeId',
  /** column name */
  Sub1IndustryId = 'sub1IndustryId',
  /** column name */
  Sub2IndustryId = 'sub2IndustryId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type OrganizationUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizationSetInput>;
  /** filter the rows which have to be updated */
  where: OrganizationBoolExp;
};

/** columns and relationships of "password_reset" */
export type PasswordReset = {
  __typename?: 'PasswordReset';
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  token: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "password_reset" */
export type PasswordResetAggregate = {
  __typename?: 'PasswordResetAggregate';
  aggregate?: Maybe<PasswordResetAggregateFields>;
  nodes: Array<PasswordReset>;
};

/** aggregate fields of "password_reset" */
export type PasswordResetAggregateFields = {
  __typename?: 'PasswordResetAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<PasswordResetMaxFields>;
  min?: Maybe<PasswordResetMinFields>;
};

/** aggregate fields of "password_reset" */
export type PasswordResetAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PasswordResetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "password_reset". All fields are combined with a logical 'AND'. */
export type PasswordResetBoolExp = {
  _and?: InputMaybe<Array<PasswordResetBoolExp>>;
  _not?: InputMaybe<PasswordResetBoolExp>;
  _or?: InputMaybe<Array<PasswordResetBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  token?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "password_reset" */
export enum PasswordResetConstraint {
  /** unique or primary key constraint on columns "id" */
  PasswordResetsPkey = 'password_resets_pkey',
  /** unique or primary key constraint on columns "id" */
  PasswordResetsXidKey = 'password_resets_xid_key',
}

/** input type for inserting data into table "password_reset" */
export type PasswordResetInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  token?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type PasswordResetMaxFields = {
  __typename?: 'PasswordResetMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type PasswordResetMinFields = {
  __typename?: 'PasswordResetMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "password_reset" */
export type PasswordResetMutationResponse = {
  __typename?: 'PasswordResetMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PasswordReset>;
};

/** on_conflict condition type for table "password_reset" */
export type PasswordResetOnConflict = {
  constraint: PasswordResetConstraint;
  updateColumns?: Array<PasswordResetUpdateColumn>;
  where?: InputMaybe<PasswordResetBoolExp>;
};

/** Ordering options when selecting data from "password_reset". */
export type PasswordResetOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  token?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: password_reset */
export type PasswordResetPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "password_reset" */
export enum PasswordResetSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "password_reset" */
export type PasswordResetSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  token?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "password_reset" */
export type PasswordResetStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PasswordResetStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PasswordResetStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  token?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "password_reset" */
export enum PasswordResetUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type PasswordResetUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PasswordResetSetInput>;
  /** filter the rows which have to be updated */
  where: PasswordResetBoolExp;
};

/** columns and relationships of "payment" */
export type Payment = {
  __typename?: 'Payment';
  amountCrwdunit?: Maybe<Scalars['bigint']>;
  amountCurrency?: Maybe<Scalars['bigint']>;
  amountUsd?: Maybe<Scalars['Int']>;
  /** An object relationship */
  ask?: Maybe<Ask>;
  askId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  assetAccount?: Maybe<AssetAccount>;
  assetAccountId?: Maybe<Scalars['uuid']>;
  cancelUrl: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  currency?: Maybe<Currency>;
  currencyId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['uuid']>;
  paymentDetails: Scalars['String'];
  /** An object relationship */
  put?: Maybe<Put>;
  putId?: Maybe<Scalars['uuid']>;
  receiveWalletTxn: Scalars['String'];
  sendWalletTxn: Scalars['String'];
  /** An object relationship */
  status?: Maybe<Status>;
  statusId?: Maybe<Scalars['uuid']>;
  successUrl: Scalars['String'];
  /** An array relationship */
  transactions: Array<Transaction>;
  /** An aggregate relationship */
  transactionsAggregate: TransactionAggregate;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** columns and relationships of "payment" */
export type PaymentTransactionsArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

/** columns and relationships of "payment" */
export type PaymentTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

/** aggregated selection of "payment" */
export type PaymentAggregate = {
  __typename?: 'PaymentAggregate';
  aggregate?: Maybe<PaymentAggregateFields>;
  nodes: Array<Payment>;
};

export type PaymentAggregateBoolExp = {
  bool_and?: InputMaybe<PaymentAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<PaymentAggregateBoolExpBool_Or>;
  count?: InputMaybe<PaymentAggregateBoolExpCount>;
};

/** aggregate fields of "payment" */
export type PaymentAggregateFields = {
  __typename?: 'PaymentAggregateFields';
  avg?: Maybe<PaymentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PaymentMaxFields>;
  min?: Maybe<PaymentMinFields>;
  stddev?: Maybe<PaymentStddevFields>;
  stddevPop?: Maybe<PaymentStddevPopFields>;
  stddevSamp?: Maybe<PaymentStddevSampFields>;
  sum?: Maybe<PaymentSumFields>;
  varPop?: Maybe<PaymentVarPopFields>;
  varSamp?: Maybe<PaymentVarSampFields>;
  variance?: Maybe<PaymentVarianceFields>;
};

/** aggregate fields of "payment" */
export type PaymentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PaymentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment" */
export type PaymentAggregateOrderBy = {
  avg?: InputMaybe<PaymentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PaymentMaxOrderBy>;
  min?: InputMaybe<PaymentMinOrderBy>;
  stddev?: InputMaybe<PaymentStddevOrderBy>;
  stddevPop?: InputMaybe<PaymentStddevPopOrderBy>;
  stddevSamp?: InputMaybe<PaymentStddevSampOrderBy>;
  sum?: InputMaybe<PaymentSumOrderBy>;
  varPop?: InputMaybe<PaymentVarPopOrderBy>;
  varSamp?: InputMaybe<PaymentVarSampOrderBy>;
  variance?: InputMaybe<PaymentVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "payment" */
export type PaymentArrRelInsertInput = {
  data: Array<PaymentInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentOnConflict>;
};

/** aggregate avg on columns */
export type PaymentAvgFields = {
  __typename?: 'PaymentAvgFields';
  amountCrwdunit?: Maybe<Scalars['Float']>;
  amountCurrency?: Maybe<Scalars['Float']>;
  amountUsd?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment" */
export type PaymentAvgOrderBy = {
  amountCrwdunit?: InputMaybe<OrderBy>;
  amountCurrency?: InputMaybe<OrderBy>;
  amountUsd?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "payment". All fields are combined with a logical 'AND'. */
export type PaymentBoolExp = {
  _and?: InputMaybe<Array<PaymentBoolExp>>;
  _not?: InputMaybe<PaymentBoolExp>;
  _or?: InputMaybe<Array<PaymentBoolExp>>;
  amountCrwdunit?: InputMaybe<BigintComparisonExp>;
  amountCurrency?: InputMaybe<BigintComparisonExp>;
  amountUsd?: InputMaybe<IntComparisonExp>;
  ask?: InputMaybe<AskBoolExp>;
  askId?: InputMaybe<UuidComparisonExp>;
  assetAccount?: InputMaybe<AssetAccountBoolExp>;
  assetAccountId?: InputMaybe<UuidComparisonExp>;
  cancelUrl?: InputMaybe<StringComparisonExp>;
  comment?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrencyBoolExp>;
  currencyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  order?: InputMaybe<OrderBoolExp>;
  orderId?: InputMaybe<UuidComparisonExp>;
  paymentDetails?: InputMaybe<StringComparisonExp>;
  put?: InputMaybe<PutBoolExp>;
  putId?: InputMaybe<UuidComparisonExp>;
  receiveWalletTxn?: InputMaybe<StringComparisonExp>;
  sendWalletTxn?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StatusBoolExp>;
  statusId?: InputMaybe<UuidComparisonExp>;
  successUrl?: InputMaybe<StringComparisonExp>;
  transactions?: InputMaybe<TransactionBoolExp>;
  transactionsAggregate?: InputMaybe<TransactionAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "payment" */
export enum PaymentConstraint {
  /** unique or primary key constraint on columns "id" */
  PaymentPkey = 'payment_pkey',
  /** unique or primary key constraint on columns "id" */
  PaymentXidKey = 'payment_xid_key',
}

/** input type for incrementing numeric columns in table "payment" */
export type PaymentIncInput = {
  amountCrwdunit?: InputMaybe<Scalars['bigint']>;
  amountCurrency?: InputMaybe<Scalars['bigint']>;
  amountUsd?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment" */
export type PaymentInsertInput = {
  amountCrwdunit?: InputMaybe<Scalars['bigint']>;
  amountCurrency?: InputMaybe<Scalars['bigint']>;
  amountUsd?: InputMaybe<Scalars['Int']>;
  ask?: InputMaybe<AskObjRelInsertInput>;
  askId?: InputMaybe<Scalars['uuid']>;
  assetAccount?: InputMaybe<AssetAccountObjRelInsertInput>;
  assetAccountId?: InputMaybe<Scalars['uuid']>;
  cancelUrl?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<CurrencyObjRelInsertInput>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<OrderObjRelInsertInput>;
  orderId?: InputMaybe<Scalars['uuid']>;
  paymentDetails?: InputMaybe<Scalars['String']>;
  put?: InputMaybe<PutObjRelInsertInput>;
  putId?: InputMaybe<Scalars['uuid']>;
  receiveWalletTxn?: InputMaybe<Scalars['String']>;
  sendWalletTxn?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusObjRelInsertInput>;
  statusId?: InputMaybe<Scalars['uuid']>;
  successUrl?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PaymentMaxFields = {
  __typename?: 'PaymentMaxFields';
  amountCrwdunit?: Maybe<Scalars['bigint']>;
  amountCurrency?: Maybe<Scalars['bigint']>;
  amountUsd?: Maybe<Scalars['Int']>;
  askId?: Maybe<Scalars['uuid']>;
  assetAccountId?: Maybe<Scalars['uuid']>;
  cancelUrl?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['uuid']>;
  paymentDetails?: Maybe<Scalars['String']>;
  putId?: Maybe<Scalars['uuid']>;
  receiveWalletTxn?: Maybe<Scalars['String']>;
  sendWalletTxn?: Maybe<Scalars['String']>;
  statusId?: Maybe<Scalars['uuid']>;
  successUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "payment" */
export type PaymentMaxOrderBy = {
  amountCrwdunit?: InputMaybe<OrderBy>;
  amountCurrency?: InputMaybe<OrderBy>;
  amountUsd?: InputMaybe<OrderBy>;
  askId?: InputMaybe<OrderBy>;
  assetAccountId?: InputMaybe<OrderBy>;
  cancelUrl?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  paymentDetails?: InputMaybe<OrderBy>;
  putId?: InputMaybe<OrderBy>;
  receiveWalletTxn?: InputMaybe<OrderBy>;
  sendWalletTxn?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  successUrl?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PaymentMinFields = {
  __typename?: 'PaymentMinFields';
  amountCrwdunit?: Maybe<Scalars['bigint']>;
  amountCurrency?: Maybe<Scalars['bigint']>;
  amountUsd?: Maybe<Scalars['Int']>;
  askId?: Maybe<Scalars['uuid']>;
  assetAccountId?: Maybe<Scalars['uuid']>;
  cancelUrl?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['uuid']>;
  paymentDetails?: Maybe<Scalars['String']>;
  putId?: Maybe<Scalars['uuid']>;
  receiveWalletTxn?: Maybe<Scalars['String']>;
  sendWalletTxn?: Maybe<Scalars['String']>;
  statusId?: Maybe<Scalars['uuid']>;
  successUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "payment" */
export type PaymentMinOrderBy = {
  amountCrwdunit?: InputMaybe<OrderBy>;
  amountCurrency?: InputMaybe<OrderBy>;
  amountUsd?: InputMaybe<OrderBy>;
  askId?: InputMaybe<OrderBy>;
  assetAccountId?: InputMaybe<OrderBy>;
  cancelUrl?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  paymentDetails?: InputMaybe<OrderBy>;
  putId?: InputMaybe<OrderBy>;
  receiveWalletTxn?: InputMaybe<OrderBy>;
  sendWalletTxn?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  successUrl?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "payment" */
export type PaymentMutationResponse = {
  __typename?: 'PaymentMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment>;
};

/** input type for inserting object relation for remote table "payment" */
export type PaymentObjRelInsertInput = {
  data: PaymentInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentOnConflict>;
};

/** on_conflict condition type for table "payment" */
export type PaymentOnConflict = {
  constraint: PaymentConstraint;
  updateColumns?: Array<PaymentUpdateColumn>;
  where?: InputMaybe<PaymentBoolExp>;
};

/** Ordering options when selecting data from "payment". */
export type PaymentOrderBy = {
  amountCrwdunit?: InputMaybe<OrderBy>;
  amountCurrency?: InputMaybe<OrderBy>;
  amountUsd?: InputMaybe<OrderBy>;
  ask?: InputMaybe<AskOrderBy>;
  askId?: InputMaybe<OrderBy>;
  assetAccount?: InputMaybe<AssetAccountOrderBy>;
  assetAccountId?: InputMaybe<OrderBy>;
  cancelUrl?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrencyOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderOrderBy>;
  orderId?: InputMaybe<OrderBy>;
  paymentDetails?: InputMaybe<OrderBy>;
  put?: InputMaybe<PutOrderBy>;
  putId?: InputMaybe<OrderBy>;
  receiveWalletTxn?: InputMaybe<OrderBy>;
  sendWalletTxn?: InputMaybe<OrderBy>;
  status?: InputMaybe<StatusOrderBy>;
  statusId?: InputMaybe<OrderBy>;
  successUrl?: InputMaybe<OrderBy>;
  transactionsAggregate?: InputMaybe<TransactionAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: payment */
export type PaymentPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "payment" */
export enum PaymentSelectColumn {
  /** column name */
  AmountCrwdunit = 'amountCrwdunit',
  /** column name */
  AmountCurrency = 'amountCurrency',
  /** column name */
  AmountUsd = 'amountUsd',
  /** column name */
  AskId = 'askId',
  /** column name */
  AssetAccountId = 'assetAccountId',
  /** column name */
  CancelUrl = 'cancelUrl',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PaymentDetails = 'paymentDetails',
  /** column name */
  PutId = 'putId',
  /** column name */
  ReceiveWalletTxn = 'receiveWalletTxn',
  /** column name */
  SendWalletTxn = 'sendWalletTxn',
  /** column name */
  StatusId = 'statusId',
  /** column name */
  SuccessUrl = 'successUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** select "paymentAggregateBoolExpBool_andArgumentsColumns" columns of table "payment" */
export enum PaymentSelectColumnPaymentAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "paymentAggregateBoolExpBool_orArgumentsColumns" columns of table "payment" */
export enum PaymentSelectColumnPaymentAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "payment" */
export type PaymentSetInput = {
  amountCrwdunit?: InputMaybe<Scalars['bigint']>;
  amountCurrency?: InputMaybe<Scalars['bigint']>;
  amountUsd?: InputMaybe<Scalars['Int']>;
  askId?: InputMaybe<Scalars['uuid']>;
  assetAccountId?: InputMaybe<Scalars['uuid']>;
  cancelUrl?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['uuid']>;
  paymentDetails?: InputMaybe<Scalars['String']>;
  putId?: InputMaybe<Scalars['uuid']>;
  receiveWalletTxn?: InputMaybe<Scalars['String']>;
  sendWalletTxn?: InputMaybe<Scalars['String']>;
  statusId?: InputMaybe<Scalars['uuid']>;
  successUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type PaymentStddevFields = {
  __typename?: 'PaymentStddevFields';
  amountCrwdunit?: Maybe<Scalars['Float']>;
  amountCurrency?: Maybe<Scalars['Float']>;
  amountUsd?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment" */
export type PaymentStddevOrderBy = {
  amountCrwdunit?: InputMaybe<OrderBy>;
  amountCurrency?: InputMaybe<OrderBy>;
  amountUsd?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type PaymentStddevPopFields = {
  __typename?: 'PaymentStddevPopFields';
  amountCrwdunit?: Maybe<Scalars['Float']>;
  amountCurrency?: Maybe<Scalars['Float']>;
  amountUsd?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "payment" */
export type PaymentStddevPopOrderBy = {
  amountCrwdunit?: InputMaybe<OrderBy>;
  amountCurrency?: InputMaybe<OrderBy>;
  amountUsd?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type PaymentStddevSampFields = {
  __typename?: 'PaymentStddevSampFields';
  amountCrwdunit?: Maybe<Scalars['Float']>;
  amountCurrency?: Maybe<Scalars['Float']>;
  amountUsd?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "payment" */
export type PaymentStddevSampOrderBy = {
  amountCrwdunit?: InputMaybe<OrderBy>;
  amountCurrency?: InputMaybe<OrderBy>;
  amountUsd?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "payment" */
export type PaymentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentStreamCursorValueInput = {
  amountCrwdunit?: InputMaybe<Scalars['bigint']>;
  amountCurrency?: InputMaybe<Scalars['bigint']>;
  amountUsd?: InputMaybe<Scalars['Int']>;
  askId?: InputMaybe<Scalars['uuid']>;
  assetAccountId?: InputMaybe<Scalars['uuid']>;
  cancelUrl?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['uuid']>;
  paymentDetails?: InputMaybe<Scalars['String']>;
  putId?: InputMaybe<Scalars['uuid']>;
  receiveWalletTxn?: InputMaybe<Scalars['String']>;
  sendWalletTxn?: InputMaybe<Scalars['String']>;
  statusId?: InputMaybe<Scalars['uuid']>;
  successUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type PaymentSumFields = {
  __typename?: 'PaymentSumFields';
  amountCrwdunit?: Maybe<Scalars['bigint']>;
  amountCurrency?: Maybe<Scalars['bigint']>;
  amountUsd?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "payment" */
export type PaymentSumOrderBy = {
  amountCrwdunit?: InputMaybe<OrderBy>;
  amountCurrency?: InputMaybe<OrderBy>;
  amountUsd?: InputMaybe<OrderBy>;
};

/** update columns of table "payment" */
export enum PaymentUpdateColumn {
  /** column name */
  AmountCrwdunit = 'amountCrwdunit',
  /** column name */
  AmountCurrency = 'amountCurrency',
  /** column name */
  AmountUsd = 'amountUsd',
  /** column name */
  AskId = 'askId',
  /** column name */
  AssetAccountId = 'assetAccountId',
  /** column name */
  CancelUrl = 'cancelUrl',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PaymentDetails = 'paymentDetails',
  /** column name */
  PutId = 'putId',
  /** column name */
  ReceiveWalletTxn = 'receiveWalletTxn',
  /** column name */
  SendWalletTxn = 'sendWalletTxn',
  /** column name */
  StatusId = 'statusId',
  /** column name */
  SuccessUrl = 'successUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type PaymentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PaymentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentSetInput>;
  /** filter the rows which have to be updated */
  where: PaymentBoolExp;
};

/** aggregate varPop on columns */
export type PaymentVarPopFields = {
  __typename?: 'PaymentVarPopFields';
  amountCrwdunit?: Maybe<Scalars['Float']>;
  amountCurrency?: Maybe<Scalars['Float']>;
  amountUsd?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "payment" */
export type PaymentVarPopOrderBy = {
  amountCrwdunit?: InputMaybe<OrderBy>;
  amountCurrency?: InputMaybe<OrderBy>;
  amountUsd?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type PaymentVarSampFields = {
  __typename?: 'PaymentVarSampFields';
  amountCrwdunit?: Maybe<Scalars['Float']>;
  amountCurrency?: Maybe<Scalars['Float']>;
  amountUsd?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "payment" */
export type PaymentVarSampOrderBy = {
  amountCrwdunit?: InputMaybe<OrderBy>;
  amountCurrency?: InputMaybe<OrderBy>;
  amountUsd?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type PaymentVarianceFields = {
  __typename?: 'PaymentVarianceFields';
  amountCrwdunit?: Maybe<Scalars['Float']>;
  amountCurrency?: Maybe<Scalars['Float']>;
  amountUsd?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment" */
export type PaymentVarianceOrderBy = {
  amountCrwdunit?: InputMaybe<OrderBy>;
  amountCurrency?: InputMaybe<OrderBy>;
  amountUsd?: InputMaybe<OrderBy>;
};

/** columns and relationships of "product" */
export type Product = {
  __typename?: 'Product';
  actionUrlActivate?: Maybe<Scalars['String']>;
  actionUrlDeactivate?: Maybe<Scalars['String']>;
  /** An array relationship */
  asks: Array<Ask>;
  /** An aggregate relationship */
  asksAggregate: AskAggregate;
  /** An object relationship */
  asset?: Maybe<Asset>;
  assetId?: Maybe<Scalars['uuid']>;
  binId?: Maybe<Scalars['Int']>;
  caliberString?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  categoryNumber?: Maybe<Scalars['String']>;
  ceNumber?: Maybe<Scalars['String']>;
  chainNumberOfDevices?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  contentList?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  distanceUom?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  effectHeight?: Maybe<Scalars['String']>;
  effectWidth?: Maybe<Scalars['String']>;
  exNumberList?: Maybe<Scalars['String']>;
  facebookReferenceId?: Maybe<Scalars['String']>;
  glAccountList?: Maybe<Scalars['String']>;
  googleReferenceId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  images?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  itemCost?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  kind?: Maybe<ProductKind>;
  longDescription?: Maybe<Scalars['String']>;
  manufacturerBrand?: Maybe<Scalars['String']>;
  /** An object relationship */
  manufacturerOrg?: Maybe<Organization>;
  manufacturerOrgId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  manufacturerProduct?: Maybe<Product>;
  manufacturerProductId?: Maybe<Scalars['uuid']>;
  marketplaces?: Maybe<Scalars['String']>;
  markets?: Maybe<Scalars['String']>;
  /** An object relationship */
  material?: Maybe<Material>;
  materialId?: Maybe<Scalars['uuid']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  numberPackedItems?: Maybe<Scalars['String']>;
  price: Scalars['float8'];
  productKindId?: Maybe<Scalars['uuid']>;
  productStatusId?: Maybe<Scalars['uuid']>;
  qtyUom?: Maybe<Scalars['String']>;
  /** An object relationship */
  reorderSmartContract?: Maybe<SmartContract>;
  reorderSmartContractId?: Maybe<Scalars['uuid']>;
  shops?: Maybe<Scalars['String']>;
  /** An object relationship */
  sicCode?: Maybe<SicCode>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  size?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  /** An object relationship */
  smartContract?: Maybe<SmartContract>;
  smartContractId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  status?: Maybe<ProductStatus>;
  /** An object relationship */
  subindustry1?: Maybe<Subindustry>;
  subindustry1Id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  subindustry2?: Maybe<Subindustry>;
  subindustry2Id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  supplierOrgList: Array<Organization>;
  /** An aggregate relationship */
  supplierOrgListAggregate: OrganizationAggregate;
  supplierOrgListId?: Maybe<Scalars['uuid']>;
  unHazMatId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "product" */
export type ProductAsksArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

/** columns and relationships of "product" */
export type ProductAsksAggregateArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

/** columns and relationships of "product" */
export type ProductSupplierOrgListArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};

/** columns and relationships of "product" */
export type ProductSupplierOrgListAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};

/** aggregated selection of "product" */
export type ProductAggregate = {
  __typename?: 'ProductAggregate';
  aggregate?: Maybe<ProductAggregateFields>;
  nodes: Array<Product>;
};

export type ProductAggregateBoolExp = {
  avg?: InputMaybe<ProductAggregateBoolExpAvg>;
  bool_and?: InputMaybe<ProductAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ProductAggregateBoolExpBool_Or>;
  corr?: InputMaybe<ProductAggregateBoolExpCorr>;
  count?: InputMaybe<ProductAggregateBoolExpCount>;
  covar_samp?: InputMaybe<ProductAggregateBoolExpCovar_Samp>;
  max?: InputMaybe<ProductAggregateBoolExpMax>;
  min?: InputMaybe<ProductAggregateBoolExpMin>;
  stddev_samp?: InputMaybe<ProductAggregateBoolExpStddev_Samp>;
  sum?: InputMaybe<ProductAggregateBoolExpSum>;
  var_samp?: InputMaybe<ProductAggregateBoolExpVar_Samp>;
};

/** aggregate fields of "product" */
export type ProductAggregateFields = {
  __typename?: 'ProductAggregateFields';
  avg?: Maybe<ProductAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProductMaxFields>;
  min?: Maybe<ProductMinFields>;
  stddev?: Maybe<ProductStddevFields>;
  stddevPop?: Maybe<ProductStddevPopFields>;
  stddevSamp?: Maybe<ProductStddevSampFields>;
  sum?: Maybe<ProductSumFields>;
  varPop?: Maybe<ProductVarPopFields>;
  varSamp?: Maybe<ProductVarSampFields>;
  variance?: Maybe<ProductVarianceFields>;
};

/** aggregate fields of "product" */
export type ProductAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProductSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product" */
export type ProductAggregateOrderBy = {
  avg?: InputMaybe<ProductAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProductMaxOrderBy>;
  min?: InputMaybe<ProductMinOrderBy>;
  stddev?: InputMaybe<ProductStddevOrderBy>;
  stddevPop?: InputMaybe<ProductStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ProductStddevSampOrderBy>;
  sum?: InputMaybe<ProductSumOrderBy>;
  varPop?: InputMaybe<ProductVarPopOrderBy>;
  varSamp?: InputMaybe<ProductVarSampOrderBy>;
  variance?: InputMaybe<ProductVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "product" */
export type ProductArrRelInsertInput = {
  data: Array<ProductInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProductOnConflict>;
};

/** aggregate avg on columns */
export type ProductAvgFields = {
  __typename?: 'ProductAvgFields';
  binId?: Maybe<Scalars['Float']>;
  itemCost?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product" */
export type ProductAvgOrderBy = {
  binId?: InputMaybe<OrderBy>;
  itemCost?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type ProductBoolExp = {
  _and?: InputMaybe<Array<ProductBoolExp>>;
  _not?: InputMaybe<ProductBoolExp>;
  _or?: InputMaybe<Array<ProductBoolExp>>;
  actionUrlActivate?: InputMaybe<StringComparisonExp>;
  actionUrlDeactivate?: InputMaybe<StringComparisonExp>;
  asks?: InputMaybe<AskBoolExp>;
  asksAggregate?: InputMaybe<AskAggregateBoolExp>;
  asset?: InputMaybe<AssetBoolExp>;
  assetId?: InputMaybe<UuidComparisonExp>;
  binId?: InputMaybe<IntComparisonExp>;
  caliberString?: InputMaybe<StringComparisonExp>;
  categories?: InputMaybe<StringComparisonExp>;
  categoryNumber?: InputMaybe<StringComparisonExp>;
  ceNumber?: InputMaybe<StringComparisonExp>;
  chainNumberOfDevices?: InputMaybe<StringComparisonExp>;
  color?: InputMaybe<StringComparisonExp>;
  contentList?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  distanceUom?: InputMaybe<StringComparisonExp>;
  duration?: InputMaybe<StringComparisonExp>;
  effectHeight?: InputMaybe<StringComparisonExp>;
  effectWidth?: InputMaybe<StringComparisonExp>;
  exNumberList?: InputMaybe<StringComparisonExp>;
  facebookReferenceId?: InputMaybe<StringComparisonExp>;
  glAccountList?: InputMaybe<StringComparisonExp>;
  googleReferenceId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  images?: InputMaybe<StringComparisonExp>;
  internalName?: InputMaybe<StringComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  itemCost?: InputMaybe<BigintComparisonExp>;
  kind?: InputMaybe<ProductKindBoolExp>;
  longDescription?: InputMaybe<StringComparisonExp>;
  manufacturerBrand?: InputMaybe<StringComparisonExp>;
  manufacturerOrg?: InputMaybe<OrganizationBoolExp>;
  manufacturerOrgId?: InputMaybe<UuidComparisonExp>;
  manufacturerProduct?: InputMaybe<ProductBoolExp>;
  manufacturerProductId?: InputMaybe<UuidComparisonExp>;
  marketplaces?: InputMaybe<StringComparisonExp>;
  markets?: InputMaybe<StringComparisonExp>;
  material?: InputMaybe<MaterialBoolExp>;
  materialId?: InputMaybe<UuidComparisonExp>;
  metaDescription?: InputMaybe<StringComparisonExp>;
  metaKeywords?: InputMaybe<StringComparisonExp>;
  metaTitle?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  numberPackedItems?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<Float8ComparisonExp>;
  productKindId?: InputMaybe<UuidComparisonExp>;
  productStatusId?: InputMaybe<UuidComparisonExp>;
  qtyUom?: InputMaybe<StringComparisonExp>;
  reorderSmartContract?: InputMaybe<SmartContractBoolExp>;
  reorderSmartContractId?: InputMaybe<UuidComparisonExp>;
  shops?: InputMaybe<StringComparisonExp>;
  sicCode?: InputMaybe<SicCodeBoolExp>;
  sicCodeId?: InputMaybe<UuidComparisonExp>;
  size?: InputMaybe<StringComparisonExp>;
  sku?: InputMaybe<StringComparisonExp>;
  smartContract?: InputMaybe<SmartContractBoolExp>;
  smartContractId?: InputMaybe<UuidComparisonExp>;
  status?: InputMaybe<ProductStatusBoolExp>;
  subindustry1?: InputMaybe<SubindustryBoolExp>;
  subindustry1Id?: InputMaybe<UuidComparisonExp>;
  subindustry2?: InputMaybe<SubindustryBoolExp>;
  subindustry2Id?: InputMaybe<UuidComparisonExp>;
  supplierOrgList?: InputMaybe<OrganizationBoolExp>;
  supplierOrgListAggregate?: InputMaybe<OrganizationAggregateBoolExp>;
  supplierOrgListId?: InputMaybe<UuidComparisonExp>;
  unHazMatId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "product" */
export enum ProductConstraint {
  /** unique or primary key constraint on columns "id" */
  ProductPkey = 'product_pkey',
}

/** columns and relationships of "product_distribution" */
export type ProductDistribution = {
  __typename?: 'ProductDistribution';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['uuid']>;
  productCategory: Scalars['String'];
  /** An object relationship */
  productStatus?: Maybe<ProductStatus>;
  productStatusId: Scalars['uuid'];
  productTags: Scalars['String'];
  shortDescription: Scalars['String'];
  /** An object relationship */
  smartContract?: Maybe<SmartContract>;
  smartContractId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
  visibility: Scalars['String'];
};

/** aggregated selection of "product_distribution" */
export type ProductDistributionAggregate = {
  __typename?: 'ProductDistributionAggregate';
  aggregate?: Maybe<ProductDistributionAggregateFields>;
  nodes: Array<ProductDistribution>;
};

/** aggregate fields of "product_distribution" */
export type ProductDistributionAggregateFields = {
  __typename?: 'ProductDistributionAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ProductDistributionMaxFields>;
  min?: Maybe<ProductDistributionMinFields>;
};

/** aggregate fields of "product_distribution" */
export type ProductDistributionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProductDistributionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_distribution". All fields are combined with a logical 'AND'. */
export type ProductDistributionBoolExp = {
  _and?: InputMaybe<Array<ProductDistributionBoolExp>>;
  _not?: InputMaybe<ProductDistributionBoolExp>;
  _or?: InputMaybe<Array<ProductDistributionBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  productCategory?: InputMaybe<StringComparisonExp>;
  productStatus?: InputMaybe<ProductStatusBoolExp>;
  productStatusId?: InputMaybe<UuidComparisonExp>;
  productTags?: InputMaybe<StringComparisonExp>;
  shortDescription?: InputMaybe<StringComparisonExp>;
  smartContract?: InputMaybe<SmartContractBoolExp>;
  smartContractId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
  visibility?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "product_distribution" */
export enum ProductDistributionConstraint {
  /** unique or primary key constraint on columns "id" */
  ProductDistributionPkey = 'product_distribution_pkey',
}

/** input type for inserting data into table "product_distribution" */
export type ProductDistributionInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  productCategory?: InputMaybe<Scalars['String']>;
  productStatus?: InputMaybe<ProductStatusObjRelInsertInput>;
  productStatusId?: InputMaybe<Scalars['uuid']>;
  productTags?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  smartContract?: InputMaybe<SmartContractObjRelInsertInput>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
  visibility?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProductDistributionMaxFields = {
  __typename?: 'ProductDistributionMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  productCategory?: Maybe<Scalars['String']>;
  productStatusId?: Maybe<Scalars['uuid']>;
  productTags?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  visibility?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProductDistributionMinFields = {
  __typename?: 'ProductDistributionMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  productCategory?: Maybe<Scalars['String']>;
  productStatusId?: Maybe<Scalars['uuid']>;
  productTags?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  visibility?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "product_distribution" */
export type ProductDistributionMutationResponse = {
  __typename?: 'ProductDistributionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductDistribution>;
};

/** input type for inserting object relation for remote table "product_distribution" */
export type ProductDistributionObjRelInsertInput = {
  data: ProductDistributionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ProductDistributionOnConflict>;
};

/** on_conflict condition type for table "product_distribution" */
export type ProductDistributionOnConflict = {
  constraint: ProductDistributionConstraint;
  updateColumns?: Array<ProductDistributionUpdateColumn>;
  where?: InputMaybe<ProductDistributionBoolExp>;
};

/** Ordering options when selecting data from "product_distribution". */
export type ProductDistributionOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  productCategory?: InputMaybe<OrderBy>;
  productStatus?: InputMaybe<ProductStatusOrderBy>;
  productStatusId?: InputMaybe<OrderBy>;
  productTags?: InputMaybe<OrderBy>;
  shortDescription?: InputMaybe<OrderBy>;
  smartContract?: InputMaybe<SmartContractOrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  visibility?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: product_distribution */
export type ProductDistributionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "product_distribution" */
export enum ProductDistributionSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  ProductCategory = 'productCategory',
  /** column name */
  ProductStatusId = 'productStatusId',
  /** column name */
  ProductTags = 'productTags',
  /** column name */
  ShortDescription = 'shortDescription',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Visibility = 'visibility',
}

/** input type for updating data in table "product_distribution" */
export type ProductDistributionSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  productCategory?: InputMaybe<Scalars['String']>;
  productStatusId?: InputMaybe<Scalars['uuid']>;
  productTags?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  visibility?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "product_distribution" */
export type ProductDistributionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProductDistributionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductDistributionStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  productCategory?: InputMaybe<Scalars['String']>;
  productStatusId?: InputMaybe<Scalars['uuid']>;
  productTags?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  visibility?: InputMaybe<Scalars['String']>;
};

/** update columns of table "product_distribution" */
export enum ProductDistributionUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  ProductCategory = 'productCategory',
  /** column name */
  ProductStatusId = 'productStatusId',
  /** column name */
  ProductTags = 'productTags',
  /** column name */
  ShortDescription = 'shortDescription',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Visibility = 'visibility',
}

export type ProductDistributionUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProductDistributionSetInput>;
  /** filter the rows which have to be updated */
  where: ProductDistributionBoolExp;
};

/** input type for incrementing numeric columns in table "product" */
export type ProductIncInput = {
  binId?: InputMaybe<Scalars['Int']>;
  itemCost?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "product" */
export type ProductInsertInput = {
  actionUrlActivate?: InputMaybe<Scalars['String']>;
  actionUrlDeactivate?: InputMaybe<Scalars['String']>;
  asks?: InputMaybe<AskArrRelInsertInput>;
  asset?: InputMaybe<AssetObjRelInsertInput>;
  assetId?: InputMaybe<Scalars['uuid']>;
  binId?: InputMaybe<Scalars['Int']>;
  caliberString?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Scalars['String']>;
  categoryNumber?: InputMaybe<Scalars['String']>;
  ceNumber?: InputMaybe<Scalars['String']>;
  chainNumberOfDevices?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  contentList?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  distanceUom?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
  effectHeight?: InputMaybe<Scalars['String']>;
  effectWidth?: InputMaybe<Scalars['String']>;
  exNumberList?: InputMaybe<Scalars['String']>;
  facebookReferenceId?: InputMaybe<Scalars['String']>;
  glAccountList?: InputMaybe<Scalars['String']>;
  googleReferenceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['String']>;
  internalName?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  itemCost?: InputMaybe<Scalars['bigint']>;
  kind?: InputMaybe<ProductKindObjRelInsertInput>;
  longDescription?: InputMaybe<Scalars['String']>;
  manufacturerBrand?: InputMaybe<Scalars['String']>;
  manufacturerOrg?: InputMaybe<OrganizationObjRelInsertInput>;
  manufacturerOrgId?: InputMaybe<Scalars['uuid']>;
  manufacturerProduct?: InputMaybe<ProductObjRelInsertInput>;
  manufacturerProductId?: InputMaybe<Scalars['uuid']>;
  marketplaces?: InputMaybe<Scalars['String']>;
  markets?: InputMaybe<Scalars['String']>;
  material?: InputMaybe<MaterialObjRelInsertInput>;
  materialId?: InputMaybe<Scalars['uuid']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaKeywords?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberPackedItems?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['float8']>;
  productKindId?: InputMaybe<Scalars['uuid']>;
  productStatusId?: InputMaybe<Scalars['uuid']>;
  qtyUom?: InputMaybe<Scalars['String']>;
  reorderSmartContract?: InputMaybe<SmartContractObjRelInsertInput>;
  reorderSmartContractId?: InputMaybe<Scalars['uuid']>;
  shops?: InputMaybe<Scalars['String']>;
  sicCode?: InputMaybe<SicCodeObjRelInsertInput>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  size?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  smartContract?: InputMaybe<SmartContractObjRelInsertInput>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<ProductStatusObjRelInsertInput>;
  subindustry1?: InputMaybe<SubindustryObjRelInsertInput>;
  subindustry1Id?: InputMaybe<Scalars['uuid']>;
  subindustry2?: InputMaybe<SubindustryObjRelInsertInput>;
  subindustry2Id?: InputMaybe<Scalars['uuid']>;
  supplierOrgList?: InputMaybe<OrganizationArrRelInsertInput>;
  supplierOrgListId?: InputMaybe<Scalars['uuid']>;
  unHazMatId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "product_kind" */
export type ProductKind = {
  __typename?: 'ProductKind';
  /** An object relationship */
  clearinghouse?: Maybe<Clearinghouse>;
  clearinghouseId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "product_kind" */
export type ProductKindAggregate = {
  __typename?: 'ProductKindAggregate';
  aggregate?: Maybe<ProductKindAggregateFields>;
  nodes: Array<ProductKind>;
};

/** aggregate fields of "product_kind" */
export type ProductKindAggregateFields = {
  __typename?: 'ProductKindAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ProductKindMaxFields>;
  min?: Maybe<ProductKindMinFields>;
};

/** aggregate fields of "product_kind" */
export type ProductKindAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProductKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_kind". All fields are combined with a logical 'AND'. */
export type ProductKindBoolExp = {
  _and?: InputMaybe<Array<ProductKindBoolExp>>;
  _not?: InputMaybe<ProductKindBoolExp>;
  _or?: InputMaybe<Array<ProductKindBoolExp>>;
  clearinghouse?: InputMaybe<ClearinghouseBoolExp>;
  clearinghouseId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "product_kind" */
export enum ProductKindConstraint {
  /** unique or primary key constraint on columns "id" */
  ProductKindPkey = 'product_kind_pkey',
}

/** input type for inserting data into table "product_kind" */
export type ProductKindInsertInput = {
  clearinghouse?: InputMaybe<ClearinghouseObjRelInsertInput>;
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProductKindMaxFields = {
  __typename?: 'ProductKindMaxFields';
  clearinghouseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ProductKindMinFields = {
  __typename?: 'ProductKindMinFields';
  clearinghouseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "product_kind" */
export type ProductKindMutationResponse = {
  __typename?: 'ProductKindMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductKind>;
};

/** input type for inserting object relation for remote table "product_kind" */
export type ProductKindObjRelInsertInput = {
  data: ProductKindInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ProductKindOnConflict>;
};

/** on_conflict condition type for table "product_kind" */
export type ProductKindOnConflict = {
  constraint: ProductKindConstraint;
  updateColumns?: Array<ProductKindUpdateColumn>;
  where?: InputMaybe<ProductKindBoolExp>;
};

/** Ordering options when selecting data from "product_kind". */
export type ProductKindOrderBy = {
  clearinghouse?: InputMaybe<ClearinghouseOrderBy>;
  clearinghouseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: product_kind */
export type ProductKindPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "product_kind" */
export enum ProductKindSelectColumn {
  /** column name */
  ClearinghouseId = 'clearinghouseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "product_kind" */
export type ProductKindSetInput = {
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "product_kind" */
export type ProductKindStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProductKindStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductKindStreamCursorValueInput = {
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "product_kind" */
export enum ProductKindUpdateColumn {
  /** column name */
  ClearinghouseId = 'clearinghouseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type ProductKindUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProductKindSetInput>;
  /** filter the rows which have to be updated */
  where: ProductKindBoolExp;
};

/** aggregate max on columns */
export type ProductMaxFields = {
  __typename?: 'ProductMaxFields';
  actionUrlActivate?: Maybe<Scalars['String']>;
  actionUrlDeactivate?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['uuid']>;
  binId?: Maybe<Scalars['Int']>;
  caliberString?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  categoryNumber?: Maybe<Scalars['String']>;
  ceNumber?: Maybe<Scalars['String']>;
  chainNumberOfDevices?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  contentList?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  distanceUom?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  effectHeight?: Maybe<Scalars['String']>;
  effectWidth?: Maybe<Scalars['String']>;
  exNumberList?: Maybe<Scalars['String']>;
  facebookReferenceId?: Maybe<Scalars['String']>;
  glAccountList?: Maybe<Scalars['String']>;
  googleReferenceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  images?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  itemCost?: Maybe<Scalars['bigint']>;
  longDescription?: Maybe<Scalars['String']>;
  manufacturerBrand?: Maybe<Scalars['String']>;
  manufacturerOrgId?: Maybe<Scalars['uuid']>;
  manufacturerProductId?: Maybe<Scalars['uuid']>;
  marketplaces?: Maybe<Scalars['String']>;
  markets?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['uuid']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberPackedItems?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  productKindId?: Maybe<Scalars['uuid']>;
  productStatusId?: Maybe<Scalars['uuid']>;
  qtyUom?: Maybe<Scalars['String']>;
  reorderSmartContractId?: Maybe<Scalars['uuid']>;
  shops?: Maybe<Scalars['String']>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  size?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  subindustry1Id?: Maybe<Scalars['uuid']>;
  subindustry2Id?: Maybe<Scalars['uuid']>;
  supplierOrgListId?: Maybe<Scalars['uuid']>;
  unHazMatId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "product" */
export type ProductMaxOrderBy = {
  actionUrlActivate?: InputMaybe<OrderBy>;
  actionUrlDeactivate?: InputMaybe<OrderBy>;
  assetId?: InputMaybe<OrderBy>;
  binId?: InputMaybe<OrderBy>;
  caliberString?: InputMaybe<OrderBy>;
  categories?: InputMaybe<OrderBy>;
  categoryNumber?: InputMaybe<OrderBy>;
  ceNumber?: InputMaybe<OrderBy>;
  chainNumberOfDevices?: InputMaybe<OrderBy>;
  color?: InputMaybe<OrderBy>;
  contentList?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  distanceUom?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  effectHeight?: InputMaybe<OrderBy>;
  effectWidth?: InputMaybe<OrderBy>;
  exNumberList?: InputMaybe<OrderBy>;
  facebookReferenceId?: InputMaybe<OrderBy>;
  glAccountList?: InputMaybe<OrderBy>;
  googleReferenceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  images?: InputMaybe<OrderBy>;
  internalName?: InputMaybe<OrderBy>;
  itemCost?: InputMaybe<OrderBy>;
  longDescription?: InputMaybe<OrderBy>;
  manufacturerBrand?: InputMaybe<OrderBy>;
  manufacturerOrgId?: InputMaybe<OrderBy>;
  manufacturerProductId?: InputMaybe<OrderBy>;
  marketplaces?: InputMaybe<OrderBy>;
  markets?: InputMaybe<OrderBy>;
  materialId?: InputMaybe<OrderBy>;
  metaDescription?: InputMaybe<OrderBy>;
  metaKeywords?: InputMaybe<OrderBy>;
  metaTitle?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  numberPackedItems?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productKindId?: InputMaybe<OrderBy>;
  productStatusId?: InputMaybe<OrderBy>;
  qtyUom?: InputMaybe<OrderBy>;
  reorderSmartContractId?: InputMaybe<OrderBy>;
  shops?: InputMaybe<OrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  size?: InputMaybe<OrderBy>;
  sku?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  subindustry1Id?: InputMaybe<OrderBy>;
  subindustry2Id?: InputMaybe<OrderBy>;
  supplierOrgListId?: InputMaybe<OrderBy>;
  unHazMatId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ProductMinFields = {
  __typename?: 'ProductMinFields';
  actionUrlActivate?: Maybe<Scalars['String']>;
  actionUrlDeactivate?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['uuid']>;
  binId?: Maybe<Scalars['Int']>;
  caliberString?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  categoryNumber?: Maybe<Scalars['String']>;
  ceNumber?: Maybe<Scalars['String']>;
  chainNumberOfDevices?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  contentList?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  distanceUom?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  effectHeight?: Maybe<Scalars['String']>;
  effectWidth?: Maybe<Scalars['String']>;
  exNumberList?: Maybe<Scalars['String']>;
  facebookReferenceId?: Maybe<Scalars['String']>;
  glAccountList?: Maybe<Scalars['String']>;
  googleReferenceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  images?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  itemCost?: Maybe<Scalars['bigint']>;
  longDescription?: Maybe<Scalars['String']>;
  manufacturerBrand?: Maybe<Scalars['String']>;
  manufacturerOrgId?: Maybe<Scalars['uuid']>;
  manufacturerProductId?: Maybe<Scalars['uuid']>;
  marketplaces?: Maybe<Scalars['String']>;
  markets?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['uuid']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberPackedItems?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  productKindId?: Maybe<Scalars['uuid']>;
  productStatusId?: Maybe<Scalars['uuid']>;
  qtyUom?: Maybe<Scalars['String']>;
  reorderSmartContractId?: Maybe<Scalars['uuid']>;
  shops?: Maybe<Scalars['String']>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  size?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  subindustry1Id?: Maybe<Scalars['uuid']>;
  subindustry2Id?: Maybe<Scalars['uuid']>;
  supplierOrgListId?: Maybe<Scalars['uuid']>;
  unHazMatId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "product" */
export type ProductMinOrderBy = {
  actionUrlActivate?: InputMaybe<OrderBy>;
  actionUrlDeactivate?: InputMaybe<OrderBy>;
  assetId?: InputMaybe<OrderBy>;
  binId?: InputMaybe<OrderBy>;
  caliberString?: InputMaybe<OrderBy>;
  categories?: InputMaybe<OrderBy>;
  categoryNumber?: InputMaybe<OrderBy>;
  ceNumber?: InputMaybe<OrderBy>;
  chainNumberOfDevices?: InputMaybe<OrderBy>;
  color?: InputMaybe<OrderBy>;
  contentList?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  distanceUom?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  effectHeight?: InputMaybe<OrderBy>;
  effectWidth?: InputMaybe<OrderBy>;
  exNumberList?: InputMaybe<OrderBy>;
  facebookReferenceId?: InputMaybe<OrderBy>;
  glAccountList?: InputMaybe<OrderBy>;
  googleReferenceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  images?: InputMaybe<OrderBy>;
  internalName?: InputMaybe<OrderBy>;
  itemCost?: InputMaybe<OrderBy>;
  longDescription?: InputMaybe<OrderBy>;
  manufacturerBrand?: InputMaybe<OrderBy>;
  manufacturerOrgId?: InputMaybe<OrderBy>;
  manufacturerProductId?: InputMaybe<OrderBy>;
  marketplaces?: InputMaybe<OrderBy>;
  markets?: InputMaybe<OrderBy>;
  materialId?: InputMaybe<OrderBy>;
  metaDescription?: InputMaybe<OrderBy>;
  metaKeywords?: InputMaybe<OrderBy>;
  metaTitle?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  numberPackedItems?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productKindId?: InputMaybe<OrderBy>;
  productStatusId?: InputMaybe<OrderBy>;
  qtyUom?: InputMaybe<OrderBy>;
  reorderSmartContractId?: InputMaybe<OrderBy>;
  shops?: InputMaybe<OrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  size?: InputMaybe<OrderBy>;
  sku?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  subindustry1Id?: InputMaybe<OrderBy>;
  subindustry2Id?: InputMaybe<OrderBy>;
  supplierOrgListId?: InputMaybe<OrderBy>;
  unHazMatId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "product" */
export type ProductMutationResponse = {
  __typename?: 'ProductMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type ProductObjRelInsertInput = {
  data: ProductInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ProductOnConflict>;
};

/** on_conflict condition type for table "product" */
export type ProductOnConflict = {
  constraint: ProductConstraint;
  updateColumns?: Array<ProductUpdateColumn>;
  where?: InputMaybe<ProductBoolExp>;
};

/** Ordering options when selecting data from "product". */
export type ProductOrderBy = {
  actionUrlActivate?: InputMaybe<OrderBy>;
  actionUrlDeactivate?: InputMaybe<OrderBy>;
  asksAggregate?: InputMaybe<AskAggregateOrderBy>;
  asset?: InputMaybe<AssetOrderBy>;
  assetId?: InputMaybe<OrderBy>;
  binId?: InputMaybe<OrderBy>;
  caliberString?: InputMaybe<OrderBy>;
  categories?: InputMaybe<OrderBy>;
  categoryNumber?: InputMaybe<OrderBy>;
  ceNumber?: InputMaybe<OrderBy>;
  chainNumberOfDevices?: InputMaybe<OrderBy>;
  color?: InputMaybe<OrderBy>;
  contentList?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  distanceUom?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  effectHeight?: InputMaybe<OrderBy>;
  effectWidth?: InputMaybe<OrderBy>;
  exNumberList?: InputMaybe<OrderBy>;
  facebookReferenceId?: InputMaybe<OrderBy>;
  glAccountList?: InputMaybe<OrderBy>;
  googleReferenceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  images?: InputMaybe<OrderBy>;
  internalName?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  itemCost?: InputMaybe<OrderBy>;
  kind?: InputMaybe<ProductKindOrderBy>;
  longDescription?: InputMaybe<OrderBy>;
  manufacturerBrand?: InputMaybe<OrderBy>;
  manufacturerOrg?: InputMaybe<OrganizationOrderBy>;
  manufacturerOrgId?: InputMaybe<OrderBy>;
  manufacturerProduct?: InputMaybe<ProductOrderBy>;
  manufacturerProductId?: InputMaybe<OrderBy>;
  marketplaces?: InputMaybe<OrderBy>;
  markets?: InputMaybe<OrderBy>;
  material?: InputMaybe<MaterialOrderBy>;
  materialId?: InputMaybe<OrderBy>;
  metaDescription?: InputMaybe<OrderBy>;
  metaKeywords?: InputMaybe<OrderBy>;
  metaTitle?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  numberPackedItems?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productKindId?: InputMaybe<OrderBy>;
  productStatusId?: InputMaybe<OrderBy>;
  qtyUom?: InputMaybe<OrderBy>;
  reorderSmartContract?: InputMaybe<SmartContractOrderBy>;
  reorderSmartContractId?: InputMaybe<OrderBy>;
  shops?: InputMaybe<OrderBy>;
  sicCode?: InputMaybe<SicCodeOrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  size?: InputMaybe<OrderBy>;
  sku?: InputMaybe<OrderBy>;
  smartContract?: InputMaybe<SmartContractOrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  status?: InputMaybe<ProductStatusOrderBy>;
  subindustry1?: InputMaybe<SubindustryOrderBy>;
  subindustry1Id?: InputMaybe<OrderBy>;
  subindustry2?: InputMaybe<SubindustryOrderBy>;
  subindustry2Id?: InputMaybe<OrderBy>;
  supplierOrgListAggregate?: InputMaybe<OrganizationAggregateOrderBy>;
  supplierOrgListId?: InputMaybe<OrderBy>;
  unHazMatId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: product */
export type ProductPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "product" */
export enum ProductSelectColumn {
  /** column name */
  ActionUrlActivate = 'actionUrlActivate',
  /** column name */
  ActionUrlDeactivate = 'actionUrlDeactivate',
  /** column name */
  AssetId = 'assetId',
  /** column name */
  BinId = 'binId',
  /** column name */
  CaliberString = 'caliberString',
  /** column name */
  Categories = 'categories',
  /** column name */
  CategoryNumber = 'categoryNumber',
  /** column name */
  CeNumber = 'ceNumber',
  /** column name */
  ChainNumberOfDevices = 'chainNumberOfDevices',
  /** column name */
  Color = 'color',
  /** column name */
  ContentList = 'contentList',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DistanceUom = 'distanceUom',
  /** column name */
  Duration = 'duration',
  /** column name */
  EffectHeight = 'effectHeight',
  /** column name */
  EffectWidth = 'effectWidth',
  /** column name */
  ExNumberList = 'exNumberList',
  /** column name */
  FacebookReferenceId = 'facebookReferenceId',
  /** column name */
  GlAccountList = 'glAccountList',
  /** column name */
  GoogleReferenceId = 'googleReferenceId',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  InternalName = 'internalName',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  ItemCost = 'itemCost',
  /** column name */
  LongDescription = 'longDescription',
  /** column name */
  ManufacturerBrand = 'manufacturerBrand',
  /** column name */
  ManufacturerOrgId = 'manufacturerOrgId',
  /** column name */
  ManufacturerProductId = 'manufacturerProductId',
  /** column name */
  Marketplaces = 'marketplaces',
  /** column name */
  Markets = 'markets',
  /** column name */
  MaterialId = 'materialId',
  /** column name */
  MetaDescription = 'metaDescription',
  /** column name */
  MetaKeywords = 'metaKeywords',
  /** column name */
  MetaTitle = 'metaTitle',
  /** column name */
  Name = 'name',
  /** column name */
  NumberPackedItems = 'numberPackedItems',
  /** column name */
  Price = 'price',
  /** column name */
  ProductKindId = 'productKindId',
  /** column name */
  ProductStatusId = 'productStatusId',
  /** column name */
  QtyUom = 'qtyUom',
  /** column name */
  ReorderSmartContractId = 'reorderSmartContractId',
  /** column name */
  Shops = 'shops',
  /** column name */
  SicCodeId = 'sicCodeId',
  /** column name */
  Size = 'size',
  /** column name */
  Sku = 'sku',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  Subindustry1Id = 'subindustry1Id',
  /** column name */
  Subindustry2Id = 'subindustry2Id',
  /** column name */
  SupplierOrgListId = 'supplierOrgListId',
  /** column name */
  UnHazMatId = 'unHazMatId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "productAggregateBoolExpAvgArgumentsColumns" columns of table "product" */
export enum ProductSelectColumnProductAggregateBoolExpAvgArgumentsColumns {
  /** column name */
  Price = 'price',
}

/** select "productAggregateBoolExpBool_andArgumentsColumns" columns of table "product" */
export enum ProductSelectColumnProductAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "productAggregateBoolExpBool_orArgumentsColumns" columns of table "product" */
export enum ProductSelectColumnProductAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "productAggregateBoolExpCorrArgumentsColumns" columns of table "product" */
export enum ProductSelectColumnProductAggregateBoolExpCorrArgumentsColumns {
  /** column name */
  Price = 'price',
}

/** select "productAggregateBoolExpCovar_sampArgumentsColumns" columns of table "product" */
export enum ProductSelectColumnProductAggregateBoolExpCovar_SampArgumentsColumns {
  /** column name */
  Price = 'price',
}

/** select "productAggregateBoolExpMaxArgumentsColumns" columns of table "product" */
export enum ProductSelectColumnProductAggregateBoolExpMaxArgumentsColumns {
  /** column name */
  Price = 'price',
}

/** select "productAggregateBoolExpMinArgumentsColumns" columns of table "product" */
export enum ProductSelectColumnProductAggregateBoolExpMinArgumentsColumns {
  /** column name */
  Price = 'price',
}

/** select "productAggregateBoolExpStddev_sampArgumentsColumns" columns of table "product" */
export enum ProductSelectColumnProductAggregateBoolExpStddev_SampArgumentsColumns {
  /** column name */
  Price = 'price',
}

/** select "productAggregateBoolExpSumArgumentsColumns" columns of table "product" */
export enum ProductSelectColumnProductAggregateBoolExpSumArgumentsColumns {
  /** column name */
  Price = 'price',
}

/** select "productAggregateBoolExpVar_sampArgumentsColumns" columns of table "product" */
export enum ProductSelectColumnProductAggregateBoolExpVar_SampArgumentsColumns {
  /** column name */
  Price = 'price',
}

/** input type for updating data in table "product" */
export type ProductSetInput = {
  actionUrlActivate?: InputMaybe<Scalars['String']>;
  actionUrlDeactivate?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['uuid']>;
  binId?: InputMaybe<Scalars['Int']>;
  caliberString?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Scalars['String']>;
  categoryNumber?: InputMaybe<Scalars['String']>;
  ceNumber?: InputMaybe<Scalars['String']>;
  chainNumberOfDevices?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  contentList?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  distanceUom?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
  effectHeight?: InputMaybe<Scalars['String']>;
  effectWidth?: InputMaybe<Scalars['String']>;
  exNumberList?: InputMaybe<Scalars['String']>;
  facebookReferenceId?: InputMaybe<Scalars['String']>;
  glAccountList?: InputMaybe<Scalars['String']>;
  googleReferenceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['String']>;
  internalName?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  itemCost?: InputMaybe<Scalars['bigint']>;
  longDescription?: InputMaybe<Scalars['String']>;
  manufacturerBrand?: InputMaybe<Scalars['String']>;
  manufacturerOrgId?: InputMaybe<Scalars['uuid']>;
  manufacturerProductId?: InputMaybe<Scalars['uuid']>;
  marketplaces?: InputMaybe<Scalars['String']>;
  markets?: InputMaybe<Scalars['String']>;
  materialId?: InputMaybe<Scalars['uuid']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaKeywords?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberPackedItems?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['float8']>;
  productKindId?: InputMaybe<Scalars['uuid']>;
  productStatusId?: InputMaybe<Scalars['uuid']>;
  qtyUom?: InputMaybe<Scalars['String']>;
  reorderSmartContractId?: InputMaybe<Scalars['uuid']>;
  shops?: InputMaybe<Scalars['String']>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  size?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  subindustry1Id?: InputMaybe<Scalars['uuid']>;
  subindustry2Id?: InputMaybe<Scalars['uuid']>;
  supplierOrgListId?: InputMaybe<Scalars['uuid']>;
  unHazMatId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "product_status" */
export type ProductStatus = {
  __typename?: 'ProductStatus';
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "product_status" */
export type ProductStatusAggregate = {
  __typename?: 'ProductStatusAggregate';
  aggregate?: Maybe<ProductStatusAggregateFields>;
  nodes: Array<ProductStatus>;
};

/** aggregate fields of "product_status" */
export type ProductStatusAggregateFields = {
  __typename?: 'ProductStatusAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ProductStatusMaxFields>;
  min?: Maybe<ProductStatusMinFields>;
};

/** aggregate fields of "product_status" */
export type ProductStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProductStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_status". All fields are combined with a logical 'AND'. */
export type ProductStatusBoolExp = {
  _and?: InputMaybe<Array<ProductStatusBoolExp>>;
  _not?: InputMaybe<ProductStatusBoolExp>;
  _or?: InputMaybe<Array<ProductStatusBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "product_status" */
export enum ProductStatusConstraint {
  /** unique or primary key constraint on columns "id" */
  ProductStatusPkey = 'product_status_pkey',
}

/** input type for inserting data into table "product_status" */
export type ProductStatusInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProductStatusMaxFields = {
  __typename?: 'ProductStatusMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ProductStatusMinFields = {
  __typename?: 'ProductStatusMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "product_status" */
export type ProductStatusMutationResponse = {
  __typename?: 'ProductStatusMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductStatus>;
};

/** input type for inserting object relation for remote table "product_status" */
export type ProductStatusObjRelInsertInput = {
  data: ProductStatusInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ProductStatusOnConflict>;
};

/** on_conflict condition type for table "product_status" */
export type ProductStatusOnConflict = {
  constraint: ProductStatusConstraint;
  updateColumns?: Array<ProductStatusUpdateColumn>;
  where?: InputMaybe<ProductStatusBoolExp>;
};

/** Ordering options when selecting data from "product_status". */
export type ProductStatusOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: product_status */
export type ProductStatusPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "product_status" */
export enum ProductStatusSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "product_status" */
export type ProductStatusSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "product_status" */
export type ProductStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProductStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductStatusStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "product_status" */
export enum ProductStatusUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type ProductStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProductStatusSetInput>;
  /** filter the rows which have to be updated */
  where: ProductStatusBoolExp;
};

/** aggregate stddev on columns */
export type ProductStddevFields = {
  __typename?: 'ProductStddevFields';
  binId?: Maybe<Scalars['Float']>;
  itemCost?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product" */
export type ProductStddevOrderBy = {
  binId?: InputMaybe<OrderBy>;
  itemCost?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProductStddevPopFields = {
  __typename?: 'ProductStddevPopFields';
  binId?: Maybe<Scalars['Float']>;
  itemCost?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "product" */
export type ProductStddevPopOrderBy = {
  binId?: InputMaybe<OrderBy>;
  itemCost?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProductStddevSampFields = {
  __typename?: 'ProductStddevSampFields';
  binId?: Maybe<Scalars['Float']>;
  itemCost?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "product" */
export type ProductStddevSampOrderBy = {
  binId?: InputMaybe<OrderBy>;
  itemCost?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "product" */
export type ProductStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProductStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductStreamCursorValueInput = {
  actionUrlActivate?: InputMaybe<Scalars['String']>;
  actionUrlDeactivate?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['uuid']>;
  binId?: InputMaybe<Scalars['Int']>;
  caliberString?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Scalars['String']>;
  categoryNumber?: InputMaybe<Scalars['String']>;
  ceNumber?: InputMaybe<Scalars['String']>;
  chainNumberOfDevices?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  contentList?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  distanceUom?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
  effectHeight?: InputMaybe<Scalars['String']>;
  effectWidth?: InputMaybe<Scalars['String']>;
  exNumberList?: InputMaybe<Scalars['String']>;
  facebookReferenceId?: InputMaybe<Scalars['String']>;
  glAccountList?: InputMaybe<Scalars['String']>;
  googleReferenceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['String']>;
  internalName?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  itemCost?: InputMaybe<Scalars['bigint']>;
  longDescription?: InputMaybe<Scalars['String']>;
  manufacturerBrand?: InputMaybe<Scalars['String']>;
  manufacturerOrgId?: InputMaybe<Scalars['uuid']>;
  manufacturerProductId?: InputMaybe<Scalars['uuid']>;
  marketplaces?: InputMaybe<Scalars['String']>;
  markets?: InputMaybe<Scalars['String']>;
  materialId?: InputMaybe<Scalars['uuid']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaKeywords?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numberPackedItems?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['float8']>;
  productKindId?: InputMaybe<Scalars['uuid']>;
  productStatusId?: InputMaybe<Scalars['uuid']>;
  qtyUom?: InputMaybe<Scalars['String']>;
  reorderSmartContractId?: InputMaybe<Scalars['uuid']>;
  shops?: InputMaybe<Scalars['String']>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  size?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  subindustry1Id?: InputMaybe<Scalars['uuid']>;
  subindustry2Id?: InputMaybe<Scalars['uuid']>;
  supplierOrgListId?: InputMaybe<Scalars['uuid']>;
  unHazMatId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ProductSumFields = {
  __typename?: 'ProductSumFields';
  binId?: Maybe<Scalars['Int']>;
  itemCost?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "product" */
export type ProductSumOrderBy = {
  binId?: InputMaybe<OrderBy>;
  itemCost?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** update columns of table "product" */
export enum ProductUpdateColumn {
  /** column name */
  ActionUrlActivate = 'actionUrlActivate',
  /** column name */
  ActionUrlDeactivate = 'actionUrlDeactivate',
  /** column name */
  AssetId = 'assetId',
  /** column name */
  BinId = 'binId',
  /** column name */
  CaliberString = 'caliberString',
  /** column name */
  Categories = 'categories',
  /** column name */
  CategoryNumber = 'categoryNumber',
  /** column name */
  CeNumber = 'ceNumber',
  /** column name */
  ChainNumberOfDevices = 'chainNumberOfDevices',
  /** column name */
  Color = 'color',
  /** column name */
  ContentList = 'contentList',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DistanceUom = 'distanceUom',
  /** column name */
  Duration = 'duration',
  /** column name */
  EffectHeight = 'effectHeight',
  /** column name */
  EffectWidth = 'effectWidth',
  /** column name */
  ExNumberList = 'exNumberList',
  /** column name */
  FacebookReferenceId = 'facebookReferenceId',
  /** column name */
  GlAccountList = 'glAccountList',
  /** column name */
  GoogleReferenceId = 'googleReferenceId',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  InternalName = 'internalName',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  ItemCost = 'itemCost',
  /** column name */
  LongDescription = 'longDescription',
  /** column name */
  ManufacturerBrand = 'manufacturerBrand',
  /** column name */
  ManufacturerOrgId = 'manufacturerOrgId',
  /** column name */
  ManufacturerProductId = 'manufacturerProductId',
  /** column name */
  Marketplaces = 'marketplaces',
  /** column name */
  Markets = 'markets',
  /** column name */
  MaterialId = 'materialId',
  /** column name */
  MetaDescription = 'metaDescription',
  /** column name */
  MetaKeywords = 'metaKeywords',
  /** column name */
  MetaTitle = 'metaTitle',
  /** column name */
  Name = 'name',
  /** column name */
  NumberPackedItems = 'numberPackedItems',
  /** column name */
  Price = 'price',
  /** column name */
  ProductKindId = 'productKindId',
  /** column name */
  ProductStatusId = 'productStatusId',
  /** column name */
  QtyUom = 'qtyUom',
  /** column name */
  ReorderSmartContractId = 'reorderSmartContractId',
  /** column name */
  Shops = 'shops',
  /** column name */
  SicCodeId = 'sicCodeId',
  /** column name */
  Size = 'size',
  /** column name */
  Sku = 'sku',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  Subindustry1Id = 'subindustry1Id',
  /** column name */
  Subindustry2Id = 'subindustry2Id',
  /** column name */
  SupplierOrgListId = 'supplierOrgListId',
  /** column name */
  UnHazMatId = 'unHazMatId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type ProductUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProductIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProductSetInput>;
  /** filter the rows which have to be updated */
  where: ProductBoolExp;
};

/** aggregate varPop on columns */
export type ProductVarPopFields = {
  __typename?: 'ProductVarPopFields';
  binId?: Maybe<Scalars['Float']>;
  itemCost?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "product" */
export type ProductVarPopOrderBy = {
  binId?: InputMaybe<OrderBy>;
  itemCost?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProductVarSampFields = {
  __typename?: 'ProductVarSampFields';
  binId?: Maybe<Scalars['Float']>;
  itemCost?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "product" */
export type ProductVarSampOrderBy = {
  binId?: InputMaybe<OrderBy>;
  itemCost?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProductVarianceFields = {
  __typename?: 'ProductVarianceFields';
  binId?: Maybe<Scalars['Float']>;
  itemCost?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product" */
export type ProductVarianceOrderBy = {
  binId?: InputMaybe<OrderBy>;
  itemCost?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
};

/** columns and relationships of "put" */
export type Put = {
  __typename?: 'Put';
  amount: Scalars['float8'];
  /** An object relationship */
  ask: Ask;
  askId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  currency: Currency;
  currencyId: Scalars['uuid'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  smartContract: SmartContract;
  smartContractId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  wallet: Wallet;
  walletId: Scalars['uuid'];
  winningPut?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "put" */
export type PutAggregate = {
  __typename?: 'PutAggregate';
  aggregate?: Maybe<PutAggregateFields>;
  nodes: Array<Put>;
};

export type PutAggregateBoolExp = {
  avg?: InputMaybe<PutAggregateBoolExpAvg>;
  bool_and?: InputMaybe<PutAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<PutAggregateBoolExpBool_Or>;
  corr?: InputMaybe<PutAggregateBoolExpCorr>;
  count?: InputMaybe<PutAggregateBoolExpCount>;
  covar_samp?: InputMaybe<PutAggregateBoolExpCovar_Samp>;
  max?: InputMaybe<PutAggregateBoolExpMax>;
  min?: InputMaybe<PutAggregateBoolExpMin>;
  stddev_samp?: InputMaybe<PutAggregateBoolExpStddev_Samp>;
  sum?: InputMaybe<PutAggregateBoolExpSum>;
  var_samp?: InputMaybe<PutAggregateBoolExpVar_Samp>;
};

/** aggregate fields of "put" */
export type PutAggregateFields = {
  __typename?: 'PutAggregateFields';
  avg?: Maybe<PutAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PutMaxFields>;
  min?: Maybe<PutMinFields>;
  stddev?: Maybe<PutStddevFields>;
  stddevPop?: Maybe<PutStddevPopFields>;
  stddevSamp?: Maybe<PutStddevSampFields>;
  sum?: Maybe<PutSumFields>;
  varPop?: Maybe<PutVarPopFields>;
  varSamp?: Maybe<PutVarSampFields>;
  variance?: Maybe<PutVarianceFields>;
};

/** aggregate fields of "put" */
export type PutAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PutSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "put" */
export type PutAggregateOrderBy = {
  avg?: InputMaybe<PutAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PutMaxOrderBy>;
  min?: InputMaybe<PutMinOrderBy>;
  stddev?: InputMaybe<PutStddevOrderBy>;
  stddevPop?: InputMaybe<PutStddevPopOrderBy>;
  stddevSamp?: InputMaybe<PutStddevSampOrderBy>;
  sum?: InputMaybe<PutSumOrderBy>;
  varPop?: InputMaybe<PutVarPopOrderBy>;
  varSamp?: InputMaybe<PutVarSampOrderBy>;
  variance?: InputMaybe<PutVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "put" */
export type PutArrRelInsertInput = {
  data: Array<PutInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PutOnConflict>;
};

/** aggregate avg on columns */
export type PutAvgFields = {
  __typename?: 'PutAvgFields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "put" */
export type PutAvgOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "put". All fields are combined with a logical 'AND'. */
export type PutBoolExp = {
  _and?: InputMaybe<Array<PutBoolExp>>;
  _not?: InputMaybe<PutBoolExp>;
  _or?: InputMaybe<Array<PutBoolExp>>;
  amount?: InputMaybe<Float8ComparisonExp>;
  ask?: InputMaybe<AskBoolExp>;
  askId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrencyBoolExp>;
  currencyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  smartContract?: InputMaybe<SmartContractBoolExp>;
  smartContractId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  wallet?: InputMaybe<WalletBoolExp>;
  walletId?: InputMaybe<UuidComparisonExp>;
  winningPut?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "put" */
export enum PutConstraint {
  /** unique or primary key constraint on columns "id" */
  PutPkey = 'put_pkey',
  /** unique or primary key constraint on columns "id" */
  PutXidKey = 'put_xid_key',
}

/** input type for incrementing numeric columns in table "put" */
export type PutIncInput = {
  amount?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "put" */
export type PutInsertInput = {
  amount?: InputMaybe<Scalars['float8']>;
  ask?: InputMaybe<AskObjRelInsertInput>;
  askId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<CurrencyObjRelInsertInput>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  smartContract?: InputMaybe<SmartContractObjRelInsertInput>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  wallet?: InputMaybe<WalletObjRelInsertInput>;
  walletId?: InputMaybe<Scalars['uuid']>;
  winningPut?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type PutMaxFields = {
  __typename?: 'PutMaxFields';
  amount?: Maybe<Scalars['float8']>;
  askId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  walletId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "put" */
export type PutMaxOrderBy = {
  amount?: InputMaybe<OrderBy>;
  askId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  walletId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PutMinFields = {
  __typename?: 'PutMinFields';
  amount?: Maybe<Scalars['float8']>;
  askId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  walletId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "put" */
export type PutMinOrderBy = {
  amount?: InputMaybe<OrderBy>;
  askId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  walletId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "put" */
export type PutMutationResponse = {
  __typename?: 'PutMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Put>;
};

/** input type for inserting object relation for remote table "put" */
export type PutObjRelInsertInput = {
  data: PutInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PutOnConflict>;
};

/** on_conflict condition type for table "put" */
export type PutOnConflict = {
  constraint: PutConstraint;
  updateColumns?: Array<PutUpdateColumn>;
  where?: InputMaybe<PutBoolExp>;
};

/** Ordering options when selecting data from "put". */
export type PutOrderBy = {
  amount?: InputMaybe<OrderBy>;
  ask?: InputMaybe<AskOrderBy>;
  askId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrencyOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  smartContract?: InputMaybe<SmartContractOrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  wallet?: InputMaybe<WalletOrderBy>;
  walletId?: InputMaybe<OrderBy>;
  winningPut?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: put */
export type PutPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "put" */
export enum PutSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  AskId = 'askId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WalletId = 'walletId',
  /** column name */
  WinningPut = 'winningPut',
}

/** select "putAggregateBoolExpAvgArgumentsColumns" columns of table "put" */
export enum PutSelectColumnPutAggregateBoolExpAvgArgumentsColumns {
  /** column name */
  Amount = 'amount',
}

/** select "putAggregateBoolExpBool_andArgumentsColumns" columns of table "put" */
export enum PutSelectColumnPutAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  WinningPut = 'winningPut',
}

/** select "putAggregateBoolExpBool_orArgumentsColumns" columns of table "put" */
export enum PutSelectColumnPutAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  WinningPut = 'winningPut',
}

/** select "putAggregateBoolExpCorrArgumentsColumns" columns of table "put" */
export enum PutSelectColumnPutAggregateBoolExpCorrArgumentsColumns {
  /** column name */
  Amount = 'amount',
}

/** select "putAggregateBoolExpCovar_sampArgumentsColumns" columns of table "put" */
export enum PutSelectColumnPutAggregateBoolExpCovar_SampArgumentsColumns {
  /** column name */
  Amount = 'amount',
}

/** select "putAggregateBoolExpMaxArgumentsColumns" columns of table "put" */
export enum PutSelectColumnPutAggregateBoolExpMaxArgumentsColumns {
  /** column name */
  Amount = 'amount',
}

/** select "putAggregateBoolExpMinArgumentsColumns" columns of table "put" */
export enum PutSelectColumnPutAggregateBoolExpMinArgumentsColumns {
  /** column name */
  Amount = 'amount',
}

/** select "putAggregateBoolExpStddev_sampArgumentsColumns" columns of table "put" */
export enum PutSelectColumnPutAggregateBoolExpStddev_SampArgumentsColumns {
  /** column name */
  Amount = 'amount',
}

/** select "putAggregateBoolExpSumArgumentsColumns" columns of table "put" */
export enum PutSelectColumnPutAggregateBoolExpSumArgumentsColumns {
  /** column name */
  Amount = 'amount',
}

/** select "putAggregateBoolExpVar_sampArgumentsColumns" columns of table "put" */
export enum PutSelectColumnPutAggregateBoolExpVar_SampArgumentsColumns {
  /** column name */
  Amount = 'amount',
}

/** input type for updating data in table "put" */
export type PutSetInput = {
  amount?: InputMaybe<Scalars['float8']>;
  askId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  walletId?: InputMaybe<Scalars['uuid']>;
  winningPut?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type PutStddevFields = {
  __typename?: 'PutStddevFields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "put" */
export type PutStddevOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type PutStddevPopFields = {
  __typename?: 'PutStddevPopFields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "put" */
export type PutStddevPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type PutStddevSampFields = {
  __typename?: 'PutStddevSampFields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "put" */
export type PutStddevSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "put" */
export type PutStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PutStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PutStreamCursorValueInput = {
  amount?: InputMaybe<Scalars['float8']>;
  askId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  walletId?: InputMaybe<Scalars['uuid']>;
  winningPut?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type PutSumFields = {
  __typename?: 'PutSumFields';
  amount?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "put" */
export type PutSumOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** update columns of table "put" */
export enum PutUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  AskId = 'askId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WalletId = 'walletId',
  /** column name */
  WinningPut = 'winningPut',
}

export type PutUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PutIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PutSetInput>;
  /** filter the rows which have to be updated */
  where: PutBoolExp;
};

/** aggregate varPop on columns */
export type PutVarPopFields = {
  __typename?: 'PutVarPopFields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "put" */
export type PutVarPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type PutVarSampFields = {
  __typename?: 'PutVarSampFields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "put" */
export type PutVarSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type PutVarianceFields = {
  __typename?: 'PutVarianceFields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "put" */
export type PutVarianceOrderBy = {
  amount?: InputMaybe<OrderBy>;
};

/** columns and relationships of "setting" */
export type Setting = {
  __typename?: 'Setting';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  key: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "setting" */
export type SettingAggregate = {
  __typename?: 'SettingAggregate';
  aggregate?: Maybe<SettingAggregateFields>;
  nodes: Array<Setting>;
};

/** aggregate fields of "setting" */
export type SettingAggregateFields = {
  __typename?: 'SettingAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SettingMaxFields>;
  min?: Maybe<SettingMinFields>;
};

/** aggregate fields of "setting" */
export type SettingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SettingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "setting". All fields are combined with a logical 'AND'. */
export type SettingBoolExp = {
  _and?: InputMaybe<Array<SettingBoolExp>>;
  _not?: InputMaybe<SettingBoolExp>;
  _or?: InputMaybe<Array<SettingBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  key?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "setting" */
export enum SettingConstraint {
  /** unique or primary key constraint on columns "id" */
  SettingsPkey = 'settings_pkey',
}

/** input type for inserting data into table "setting" */
export type SettingInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SettingMaxFields = {
  __typename?: 'SettingMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SettingMinFields = {
  __typename?: 'SettingMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "setting" */
export type SettingMutationResponse = {
  __typename?: 'SettingMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Setting>;
};

/** on_conflict condition type for table "setting" */
export type SettingOnConflict = {
  constraint: SettingConstraint;
  updateColumns?: Array<SettingUpdateColumn>;
  where?: InputMaybe<SettingBoolExp>;
};

/** Ordering options when selecting data from "setting". */
export type SettingOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  key?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: setting */
export type SettingPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "setting" */
export enum SettingSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "setting" */
export type SettingSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "setting" */
export type SettingStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SettingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SettingStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "setting" */
export enum SettingUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
}

export type SettingUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SettingSetInput>;
  /** filter the rows which have to be updated */
  where: SettingBoolExp;
};

/** columns and relationships of "shipping" */
export type Shipping = {
  __typename?: 'Shipping';
  createdAt: Scalars['timestamptz'];
  deliveryDate: Scalars['date'];
  deliverytype: Scalars['String'];
  expectedDeliveryDate: Scalars['date'];
  /** An object relationship */
  fromOrganization?: Maybe<Organization>;
  fromOrganizationId: Scalars['uuid'];
  /** An object relationship */
  fromUser?: Maybe<User>;
  fromUserId: Scalars['uuid'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  material?: Maybe<Material>;
  materialId: Scalars['uuid'];
  /** An object relationship */
  order?: Maybe<Order>;
  orderId: Scalars['uuid'];
  /** An object relationship */
  product?: Maybe<Product>;
  productId: Scalars['uuid'];
  shipDate: Scalars['date'];
  /** An object relationship */
  shippingCarrierOrg?: Maybe<Organization>;
  shippingCarrierOrgId?: Maybe<Scalars['uuid']>;
  shippingDetails: Scalars['String'];
  /** An object relationship */
  toOrganization?: Maybe<Organization>;
  toOrganizationId: Scalars['uuid'];
  /** An object relationship */
  toUser?: Maybe<User>;
  toUserId: Scalars['uuid'];
  trackingNumber: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "shipping" */
export type ShippingAggregate = {
  __typename?: 'ShippingAggregate';
  aggregate?: Maybe<ShippingAggregateFields>;
  nodes: Array<Shipping>;
};

/** aggregate fields of "shipping" */
export type ShippingAggregateFields = {
  __typename?: 'ShippingAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ShippingMaxFields>;
  min?: Maybe<ShippingMinFields>;
};

/** aggregate fields of "shipping" */
export type ShippingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ShippingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "shipping". All fields are combined with a logical 'AND'. */
export type ShippingBoolExp = {
  _and?: InputMaybe<Array<ShippingBoolExp>>;
  _not?: InputMaybe<ShippingBoolExp>;
  _or?: InputMaybe<Array<ShippingBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deliveryDate?: InputMaybe<DateComparisonExp>;
  deliverytype?: InputMaybe<StringComparisonExp>;
  expectedDeliveryDate?: InputMaybe<DateComparisonExp>;
  fromOrganization?: InputMaybe<OrganizationBoolExp>;
  fromOrganizationId?: InputMaybe<UuidComparisonExp>;
  fromUser?: InputMaybe<UserBoolExp>;
  fromUserId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  material?: InputMaybe<MaterialBoolExp>;
  materialId?: InputMaybe<UuidComparisonExp>;
  order?: InputMaybe<OrderBoolExp>;
  orderId?: InputMaybe<UuidComparisonExp>;
  product?: InputMaybe<ProductBoolExp>;
  productId?: InputMaybe<UuidComparisonExp>;
  shipDate?: InputMaybe<DateComparisonExp>;
  shippingCarrierOrg?: InputMaybe<OrganizationBoolExp>;
  shippingCarrierOrgId?: InputMaybe<UuidComparisonExp>;
  shippingDetails?: InputMaybe<StringComparisonExp>;
  toOrganization?: InputMaybe<OrganizationBoolExp>;
  toOrganizationId?: InputMaybe<UuidComparisonExp>;
  toUser?: InputMaybe<UserBoolExp>;
  toUserId?: InputMaybe<UuidComparisonExp>;
  trackingNumber?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "shipping" */
export enum ShippingConstraint {
  /** unique or primary key constraint on columns "id" */
  ShippingPkey = 'shipping_pkey',
}

/** input type for inserting data into table "shipping" */
export type ShippingInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveryDate?: InputMaybe<Scalars['date']>;
  deliverytype?: InputMaybe<Scalars['String']>;
  expectedDeliveryDate?: InputMaybe<Scalars['date']>;
  fromOrganization?: InputMaybe<OrganizationObjRelInsertInput>;
  fromOrganizationId?: InputMaybe<Scalars['uuid']>;
  fromUser?: InputMaybe<UserObjRelInsertInput>;
  fromUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  material?: InputMaybe<MaterialObjRelInsertInput>;
  materialId?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<OrderObjRelInsertInput>;
  orderId?: InputMaybe<Scalars['uuid']>;
  product?: InputMaybe<ProductObjRelInsertInput>;
  productId?: InputMaybe<Scalars['uuid']>;
  shipDate?: InputMaybe<Scalars['date']>;
  shippingCarrierOrg?: InputMaybe<OrganizationObjRelInsertInput>;
  shippingCarrierOrgId?: InputMaybe<Scalars['uuid']>;
  shippingDetails?: InputMaybe<Scalars['String']>;
  toOrganization?: InputMaybe<OrganizationObjRelInsertInput>;
  toOrganizationId?: InputMaybe<Scalars['uuid']>;
  toUser?: InputMaybe<UserObjRelInsertInput>;
  toUserId?: InputMaybe<Scalars['uuid']>;
  trackingNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ShippingMaxFields = {
  __typename?: 'ShippingMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveryDate?: Maybe<Scalars['date']>;
  deliverytype?: Maybe<Scalars['String']>;
  expectedDeliveryDate?: Maybe<Scalars['date']>;
  fromOrganizationId?: Maybe<Scalars['uuid']>;
  fromUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  materialId?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  shipDate?: Maybe<Scalars['date']>;
  shippingCarrierOrgId?: Maybe<Scalars['uuid']>;
  shippingDetails?: Maybe<Scalars['String']>;
  toOrganizationId?: Maybe<Scalars['uuid']>;
  toUserId?: Maybe<Scalars['uuid']>;
  trackingNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ShippingMinFields = {
  __typename?: 'ShippingMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveryDate?: Maybe<Scalars['date']>;
  deliverytype?: Maybe<Scalars['String']>;
  expectedDeliveryDate?: Maybe<Scalars['date']>;
  fromOrganizationId?: Maybe<Scalars['uuid']>;
  fromUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  materialId?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  shipDate?: Maybe<Scalars['date']>;
  shippingCarrierOrgId?: Maybe<Scalars['uuid']>;
  shippingDetails?: Maybe<Scalars['String']>;
  toOrganizationId?: Maybe<Scalars['uuid']>;
  toUserId?: Maybe<Scalars['uuid']>;
  trackingNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "shipping" */
export type ShippingMutationResponse = {
  __typename?: 'ShippingMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Shipping>;
};

/** on_conflict condition type for table "shipping" */
export type ShippingOnConflict = {
  constraint: ShippingConstraint;
  updateColumns?: Array<ShippingUpdateColumn>;
  where?: InputMaybe<ShippingBoolExp>;
};

/** Ordering options when selecting data from "shipping". */
export type ShippingOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deliveryDate?: InputMaybe<OrderBy>;
  deliverytype?: InputMaybe<OrderBy>;
  expectedDeliveryDate?: InputMaybe<OrderBy>;
  fromOrganization?: InputMaybe<OrganizationOrderBy>;
  fromOrganizationId?: InputMaybe<OrderBy>;
  fromUser?: InputMaybe<UserOrderBy>;
  fromUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  material?: InputMaybe<MaterialOrderBy>;
  materialId?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderOrderBy>;
  orderId?: InputMaybe<OrderBy>;
  product?: InputMaybe<ProductOrderBy>;
  productId?: InputMaybe<OrderBy>;
  shipDate?: InputMaybe<OrderBy>;
  shippingCarrierOrg?: InputMaybe<OrganizationOrderBy>;
  shippingCarrierOrgId?: InputMaybe<OrderBy>;
  shippingDetails?: InputMaybe<OrderBy>;
  toOrganization?: InputMaybe<OrganizationOrderBy>;
  toOrganizationId?: InputMaybe<OrderBy>;
  toUser?: InputMaybe<UserOrderBy>;
  toUserId?: InputMaybe<OrderBy>;
  trackingNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: shipping */
export type ShippingPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "shipping" */
export enum ShippingSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  Deliverytype = 'deliverytype',
  /** column name */
  ExpectedDeliveryDate = 'expectedDeliveryDate',
  /** column name */
  FromOrganizationId = 'fromOrganizationId',
  /** column name */
  FromUserId = 'fromUserId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MaterialId = 'materialId',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ShipDate = 'shipDate',
  /** column name */
  ShippingCarrierOrgId = 'shippingCarrierOrgId',
  /** column name */
  ShippingDetails = 'shippingDetails',
  /** column name */
  ToOrganizationId = 'toOrganizationId',
  /** column name */
  ToUserId = 'toUserId',
  /** column name */
  TrackingNumber = 'trackingNumber',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "shipping" */
export type ShippingSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveryDate?: InputMaybe<Scalars['date']>;
  deliverytype?: InputMaybe<Scalars['String']>;
  expectedDeliveryDate?: InputMaybe<Scalars['date']>;
  fromOrganizationId?: InputMaybe<Scalars['uuid']>;
  fromUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  materialId?: InputMaybe<Scalars['uuid']>;
  orderId?: InputMaybe<Scalars['uuid']>;
  productId?: InputMaybe<Scalars['uuid']>;
  shipDate?: InputMaybe<Scalars['date']>;
  shippingCarrierOrgId?: InputMaybe<Scalars['uuid']>;
  shippingDetails?: InputMaybe<Scalars['String']>;
  toOrganizationId?: InputMaybe<Scalars['uuid']>;
  toUserId?: InputMaybe<Scalars['uuid']>;
  trackingNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "shipping" */
export type ShippingStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ShippingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ShippingStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveryDate?: InputMaybe<Scalars['date']>;
  deliverytype?: InputMaybe<Scalars['String']>;
  expectedDeliveryDate?: InputMaybe<Scalars['date']>;
  fromOrganizationId?: InputMaybe<Scalars['uuid']>;
  fromUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  materialId?: InputMaybe<Scalars['uuid']>;
  orderId?: InputMaybe<Scalars['uuid']>;
  productId?: InputMaybe<Scalars['uuid']>;
  shipDate?: InputMaybe<Scalars['date']>;
  shippingCarrierOrgId?: InputMaybe<Scalars['uuid']>;
  shippingDetails?: InputMaybe<Scalars['String']>;
  toOrganizationId?: InputMaybe<Scalars['uuid']>;
  toUserId?: InputMaybe<Scalars['uuid']>;
  trackingNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "shipping" */
export enum ShippingUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  Deliverytype = 'deliverytype',
  /** column name */
  ExpectedDeliveryDate = 'expectedDeliveryDate',
  /** column name */
  FromOrganizationId = 'fromOrganizationId',
  /** column name */
  FromUserId = 'fromUserId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MaterialId = 'materialId',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  ShipDate = 'shipDate',
  /** column name */
  ShippingCarrierOrgId = 'shippingCarrierOrgId',
  /** column name */
  ShippingDetails = 'shippingDetails',
  /** column name */
  ToOrganizationId = 'toOrganizationId',
  /** column name */
  ToUserId = 'toUserId',
  /** column name */
  TrackingNumber = 'trackingNumber',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type ShippingUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShippingSetInput>;
  /** filter the rows which have to be updated */
  where: ShippingBoolExp;
};

/** columns and relationships of "shop" */
export type Shop = {
  __typename?: 'Shop';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  market?: Maybe<Market>;
  marketId: Scalars['uuid'];
  shopDescription: Scalars['String'];
  shopKind: Scalars['String'];
  shopUrl: Scalars['String'];
  /** An object relationship */
  smartContract?: Maybe<SmartContract>;
  smartContractId: Scalars['uuid'];
  uniqueMarketUrl: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  userId: Scalars['uuid'];
};

/** aggregated selection of "shop" */
export type ShopAggregate = {
  __typename?: 'ShopAggregate';
  aggregate?: Maybe<ShopAggregateFields>;
  nodes: Array<Shop>;
};

/** aggregate fields of "shop" */
export type ShopAggregateFields = {
  __typename?: 'ShopAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ShopMaxFields>;
  min?: Maybe<ShopMinFields>;
};

/** aggregate fields of "shop" */
export type ShopAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ShopSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "shop". All fields are combined with a logical 'AND'. */
export type ShopBoolExp = {
  _and?: InputMaybe<Array<ShopBoolExp>>;
  _not?: InputMaybe<ShopBoolExp>;
  _or?: InputMaybe<Array<ShopBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  market?: InputMaybe<MarketBoolExp>;
  marketId?: InputMaybe<UuidComparisonExp>;
  shopDescription?: InputMaybe<StringComparisonExp>;
  shopKind?: InputMaybe<StringComparisonExp>;
  shopUrl?: InputMaybe<StringComparisonExp>;
  smartContract?: InputMaybe<SmartContractBoolExp>;
  smartContractId?: InputMaybe<UuidComparisonExp>;
  uniqueMarketUrl?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "shop" */
export enum ShopConstraint {
  /** unique or primary key constraint on columns "id" */
  ShopPkey = 'shop_pkey',
}

/** input type for inserting data into table "shop" */
export type ShopInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  market?: InputMaybe<MarketObjRelInsertInput>;
  marketId?: InputMaybe<Scalars['uuid']>;
  shopDescription?: InputMaybe<Scalars['String']>;
  shopKind?: InputMaybe<Scalars['String']>;
  shopUrl?: InputMaybe<Scalars['String']>;
  smartContract?: InputMaybe<SmartContractObjRelInsertInput>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  uniqueMarketUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ShopMaxFields = {
  __typename?: 'ShopMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  marketId?: Maybe<Scalars['uuid']>;
  shopDescription?: Maybe<Scalars['String']>;
  shopKind?: Maybe<Scalars['String']>;
  shopUrl?: Maybe<Scalars['String']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  uniqueMarketUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ShopMinFields = {
  __typename?: 'ShopMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  marketId?: Maybe<Scalars['uuid']>;
  shopDescription?: Maybe<Scalars['String']>;
  shopKind?: Maybe<Scalars['String']>;
  shopUrl?: Maybe<Scalars['String']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  uniqueMarketUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "shop" */
export type ShopMutationResponse = {
  __typename?: 'ShopMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Shop>;
};

/** input type for inserting object relation for remote table "shop" */
export type ShopObjRelInsertInput = {
  data: ShopInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ShopOnConflict>;
};

/** on_conflict condition type for table "shop" */
export type ShopOnConflict = {
  constraint: ShopConstraint;
  updateColumns?: Array<ShopUpdateColumn>;
  where?: InputMaybe<ShopBoolExp>;
};

/** Ordering options when selecting data from "shop". */
export type ShopOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  market?: InputMaybe<MarketOrderBy>;
  marketId?: InputMaybe<OrderBy>;
  shopDescription?: InputMaybe<OrderBy>;
  shopKind?: InputMaybe<OrderBy>;
  shopUrl?: InputMaybe<OrderBy>;
  smartContract?: InputMaybe<SmartContractOrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  uniqueMarketUrl?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: shop */
export type ShopPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "shop" */
export enum ShopSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MarketId = 'marketId',
  /** column name */
  ShopDescription = 'shopDescription',
  /** column name */
  ShopKind = 'shopKind',
  /** column name */
  ShopUrl = 'shopUrl',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  UniqueMarketUrl = 'uniqueMarketUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "shop" */
export type ShopSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  marketId?: InputMaybe<Scalars['uuid']>;
  shopDescription?: InputMaybe<Scalars['String']>;
  shopKind?: InputMaybe<Scalars['String']>;
  shopUrl?: InputMaybe<Scalars['String']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  uniqueMarketUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "shop" */
export type ShopStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ShopStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ShopStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  marketId?: InputMaybe<Scalars['uuid']>;
  shopDescription?: InputMaybe<Scalars['String']>;
  shopKind?: InputMaybe<Scalars['String']>;
  shopUrl?: InputMaybe<Scalars['String']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  uniqueMarketUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "shop" */
export enum ShopUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MarketId = 'marketId',
  /** column name */
  ShopDescription = 'shopDescription',
  /** column name */
  ShopKind = 'shopKind',
  /** column name */
  ShopUrl = 'shopUrl',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  UniqueMarketUrl = 'uniqueMarketUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type ShopUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopSetInput>;
  /** filter the rows which have to be updated */
  where: ShopBoolExp;
};

/** columns and relationships of "sic_code" */
export type SicCode = {
  __typename?: 'SicCode';
  code: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  gicsCode?: Maybe<Scalars['Int']>;
  gicsCodeName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  sicCodeOffice?: Maybe<Scalars['String']>;
  /** An array relationship */
  subindustries: Array<Subindustry>;
  /** An aggregate relationship */
  subindustriesAggregate: SubindustryAggregate;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "sic_code" */
export type SicCodeSubindustriesArgs = {
  distinctOn?: InputMaybe<Array<SubindustrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubindustryOrderBy>>;
  where?: InputMaybe<SubindustryBoolExp>;
};

/** columns and relationships of "sic_code" */
export type SicCodeSubindustriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<SubindustrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubindustryOrderBy>>;
  where?: InputMaybe<SubindustryBoolExp>;
};

/** aggregated selection of "sic_code" */
export type SicCodeAggregate = {
  __typename?: 'SicCodeAggregate';
  aggregate?: Maybe<SicCodeAggregateFields>;
  nodes: Array<SicCode>;
};

/** aggregate fields of "sic_code" */
export type SicCodeAggregateFields = {
  __typename?: 'SicCodeAggregateFields';
  avg?: Maybe<SicCodeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SicCodeMaxFields>;
  min?: Maybe<SicCodeMinFields>;
  stddev?: Maybe<SicCodeStddevFields>;
  stddevPop?: Maybe<SicCodeStddevPopFields>;
  stddevSamp?: Maybe<SicCodeStddevSampFields>;
  sum?: Maybe<SicCodeSumFields>;
  varPop?: Maybe<SicCodeVarPopFields>;
  varSamp?: Maybe<SicCodeVarSampFields>;
  variance?: Maybe<SicCodeVarianceFields>;
};

/** aggregate fields of "sic_code" */
export type SicCodeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SicCodeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SicCodeAvgFields = {
  __typename?: 'SicCodeAvgFields';
  code?: Maybe<Scalars['Float']>;
  gicsCode?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sic_code". All fields are combined with a logical 'AND'. */
export type SicCodeBoolExp = {
  _and?: InputMaybe<Array<SicCodeBoolExp>>;
  _not?: InputMaybe<SicCodeBoolExp>;
  _or?: InputMaybe<Array<SicCodeBoolExp>>;
  code?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  gicsCode?: InputMaybe<IntComparisonExp>;
  gicsCodeName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  sicCodeOffice?: InputMaybe<StringComparisonExp>;
  subindustries?: InputMaybe<SubindustryBoolExp>;
  subindustriesAggregate?: InputMaybe<SubindustryAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "sic_code" */
export enum SicCodeConstraint {
  /** unique or primary key constraint on columns "id" */
  SicCodePkey = 'sic_code_pkey',
  /** unique or primary key constraint on columns "id" */
  SicCodeXidKey = 'sic_code_xid_key',
}

/** input type for incrementing numeric columns in table "sic_code" */
export type SicCodeIncInput = {
  code?: InputMaybe<Scalars['Int']>;
  gicsCode?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "sic_code" */
export type SicCodeInsertInput = {
  code?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  gicsCode?: InputMaybe<Scalars['Int']>;
  gicsCodeName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sicCodeOffice?: InputMaybe<Scalars['String']>;
  subindustries?: InputMaybe<SubindustryArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SicCodeMaxFields = {
  __typename?: 'SicCodeMaxFields';
  code?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  gicsCode?: Maybe<Scalars['Int']>;
  gicsCodeName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sicCodeOffice?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type SicCodeMinFields = {
  __typename?: 'SicCodeMinFields';
  code?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  gicsCode?: Maybe<Scalars['Int']>;
  gicsCodeName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sicCodeOffice?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "sic_code" */
export type SicCodeMutationResponse = {
  __typename?: 'SicCodeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SicCode>;
};

/** input type for inserting object relation for remote table "sic_code" */
export type SicCodeObjRelInsertInput = {
  data: SicCodeInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<SicCodeOnConflict>;
};

/** on_conflict condition type for table "sic_code" */
export type SicCodeOnConflict = {
  constraint: SicCodeConstraint;
  updateColumns?: Array<SicCodeUpdateColumn>;
  where?: InputMaybe<SicCodeBoolExp>;
};

/** Ordering options when selecting data from "sic_code". */
export type SicCodeOrderBy = {
  code?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  gicsCode?: InputMaybe<OrderBy>;
  gicsCodeName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sicCodeOffice?: InputMaybe<OrderBy>;
  subindustriesAggregate?: InputMaybe<SubindustryAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: sic_code */
export type SicCodePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "sic_code" */
export enum SicCodeSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GicsCode = 'gicsCode',
  /** column name */
  GicsCodeName = 'gicsCodeName',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SicCodeOffice = 'sicCodeOffice',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "sic_code" */
export type SicCodeSetInput = {
  code?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  gicsCode?: InputMaybe<Scalars['Int']>;
  gicsCodeName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sicCodeOffice?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type SicCodeStddevFields = {
  __typename?: 'SicCodeStddevFields';
  code?: Maybe<Scalars['Float']>;
  gicsCode?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type SicCodeStddevPopFields = {
  __typename?: 'SicCodeStddevPopFields';
  code?: Maybe<Scalars['Float']>;
  gicsCode?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type SicCodeStddevSampFields = {
  __typename?: 'SicCodeStddevSampFields';
  code?: Maybe<Scalars['Float']>;
  gicsCode?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "sic_code" */
export type SicCodeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SicCodeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SicCodeStreamCursorValueInput = {
  code?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  gicsCode?: InputMaybe<Scalars['Int']>;
  gicsCodeName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sicCodeOffice?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type SicCodeSumFields = {
  __typename?: 'SicCodeSumFields';
  code?: Maybe<Scalars['Int']>;
  gicsCode?: Maybe<Scalars['Int']>;
};

/** update columns of table "sic_code" */
export enum SicCodeUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GicsCode = 'gicsCode',
  /** column name */
  GicsCodeName = 'gicsCodeName',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SicCodeOffice = 'sicCodeOffice',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type SicCodeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SicCodeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SicCodeSetInput>;
  /** filter the rows which have to be updated */
  where: SicCodeBoolExp;
};

/** aggregate varPop on columns */
export type SicCodeVarPopFields = {
  __typename?: 'SicCodeVarPopFields';
  code?: Maybe<Scalars['Float']>;
  gicsCode?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type SicCodeVarSampFields = {
  __typename?: 'SicCodeVarSampFields';
  code?: Maybe<Scalars['Float']>;
  gicsCode?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SicCodeVarianceFields = {
  __typename?: 'SicCodeVarianceFields';
  code?: Maybe<Scalars['Float']>;
  gicsCode?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "sigma_identity" */
export type SigmaIdentity = {
  __typename?: 'SigmaIdentity';
  amlStatus: Scalars['Int'];
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  dateExpired: Scalars['timestamptz'];
  dateIssued: Scalars['timestamptz'];
  dob: Scalars['timestamptz'];
  id: Scalars['uuid'];
  idAlpha: Scalars['String'];
  idDigital: Scalars['String'];
  idKind: Scalars['Int'];
  idNumeric: Scalars['bigint'];
  isDeleted: Scalars['Boolean'];
  issuingOrganization?: Maybe<Scalars['uuid']>;
  kycStatus: Scalars['Int'];
  mrvA: Scalars['String'];
  mrvB: Scalars['String'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  personalNumber: Scalars['String'];
  photoBack: Scalars['String'];
  photoFace: Scalars['String'];
  photoFront: Scalars['String'];
  sex: Scalars['Int'];
  /** An object relationship */
  sigmaPrime?: Maybe<SigmaPrime>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  stateProvinceName: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "sigma_identity" */
export type SigmaIdentityAggregate = {
  __typename?: 'SigmaIdentityAggregate';
  aggregate?: Maybe<SigmaIdentityAggregateFields>;
  nodes: Array<SigmaIdentity>;
};

/** aggregate fields of "sigma_identity" */
export type SigmaIdentityAggregateFields = {
  __typename?: 'SigmaIdentityAggregateFields';
  avg?: Maybe<SigmaIdentityAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SigmaIdentityMaxFields>;
  min?: Maybe<SigmaIdentityMinFields>;
  stddev?: Maybe<SigmaIdentityStddevFields>;
  stddevPop?: Maybe<SigmaIdentityStddevPopFields>;
  stddevSamp?: Maybe<SigmaIdentityStddevSampFields>;
  sum?: Maybe<SigmaIdentitySumFields>;
  varPop?: Maybe<SigmaIdentityVarPopFields>;
  varSamp?: Maybe<SigmaIdentityVarSampFields>;
  variance?: Maybe<SigmaIdentityVarianceFields>;
};

/** aggregate fields of "sigma_identity" */
export type SigmaIdentityAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SigmaIdentitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SigmaIdentityAvgFields = {
  __typename?: 'SigmaIdentityAvgFields';
  amlStatus?: Maybe<Scalars['Float']>;
  idKind?: Maybe<Scalars['Float']>;
  idNumeric?: Maybe<Scalars['Float']>;
  kycStatus?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sigma_identity". All fields are combined with a logical 'AND'. */
export type SigmaIdentityBoolExp = {
  _and?: InputMaybe<Array<SigmaIdentityBoolExp>>;
  _not?: InputMaybe<SigmaIdentityBoolExp>;
  _or?: InputMaybe<Array<SigmaIdentityBoolExp>>;
  amlStatus?: InputMaybe<IntComparisonExp>;
  countryCode?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  dateExpired?: InputMaybe<TimestamptzComparisonExp>;
  dateIssued?: InputMaybe<TimestamptzComparisonExp>;
  dob?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  idAlpha?: InputMaybe<StringComparisonExp>;
  idDigital?: InputMaybe<StringComparisonExp>;
  idKind?: InputMaybe<IntComparisonExp>;
  idNumeric?: InputMaybe<BigintComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  issuingOrganization?: InputMaybe<UuidComparisonExp>;
  kycStatus?: InputMaybe<IntComparisonExp>;
  mrvA?: InputMaybe<StringComparisonExp>;
  mrvB?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  personalNumber?: InputMaybe<StringComparisonExp>;
  photoBack?: InputMaybe<StringComparisonExp>;
  photoFace?: InputMaybe<StringComparisonExp>;
  photoFront?: InputMaybe<StringComparisonExp>;
  sex?: InputMaybe<IntComparisonExp>;
  sigmaPrime?: InputMaybe<SigmaPrimeBoolExp>;
  sigmaPrimeId?: InputMaybe<UuidComparisonExp>;
  stateProvinceName?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "sigma_identity" */
export enum SigmaIdentityConstraint {
  /** unique or primary key constraint on columns "id" */
  IdCardPkey = 'id_card_pkey',
}

/** input type for incrementing numeric columns in table "sigma_identity" */
export type SigmaIdentityIncInput = {
  amlStatus?: InputMaybe<Scalars['Int']>;
  idKind?: InputMaybe<Scalars['Int']>;
  idNumeric?: InputMaybe<Scalars['bigint']>;
  kycStatus?: InputMaybe<Scalars['Int']>;
  sex?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "sigma_identity" */
export type SigmaIdentityInsertInput = {
  amlStatus?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dateExpired?: InputMaybe<Scalars['timestamptz']>;
  dateIssued?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  idAlpha?: InputMaybe<Scalars['String']>;
  idDigital?: InputMaybe<Scalars['String']>;
  idKind?: InputMaybe<Scalars['Int']>;
  idNumeric?: InputMaybe<Scalars['bigint']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  issuingOrganization?: InputMaybe<Scalars['uuid']>;
  kycStatus?: InputMaybe<Scalars['Int']>;
  mrvA?: InputMaybe<Scalars['String']>;
  mrvB?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  personalNumber?: InputMaybe<Scalars['String']>;
  photoBack?: InputMaybe<Scalars['String']>;
  photoFace?: InputMaybe<Scalars['String']>;
  photoFront?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Int']>;
  sigmaPrime?: InputMaybe<SigmaPrimeObjRelInsertInput>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  stateProvinceName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SigmaIdentityMaxFields = {
  __typename?: 'SigmaIdentityMaxFields';
  amlStatus?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dateExpired?: Maybe<Scalars['timestamptz']>;
  dateIssued?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  idAlpha?: Maybe<Scalars['String']>;
  idDigital?: Maybe<Scalars['String']>;
  idKind?: Maybe<Scalars['Int']>;
  idNumeric?: Maybe<Scalars['bigint']>;
  issuingOrganization?: Maybe<Scalars['uuid']>;
  kycStatus?: Maybe<Scalars['Int']>;
  mrvA?: Maybe<Scalars['String']>;
  mrvB?: Maybe<Scalars['String']>;
  personalNumber?: Maybe<Scalars['String']>;
  photoBack?: Maybe<Scalars['String']>;
  photoFace?: Maybe<Scalars['String']>;
  photoFront?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['Int']>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  stateProvinceName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type SigmaIdentityMinFields = {
  __typename?: 'SigmaIdentityMinFields';
  amlStatus?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dateExpired?: Maybe<Scalars['timestamptz']>;
  dateIssued?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  idAlpha?: Maybe<Scalars['String']>;
  idDigital?: Maybe<Scalars['String']>;
  idKind?: Maybe<Scalars['Int']>;
  idNumeric?: Maybe<Scalars['bigint']>;
  issuingOrganization?: Maybe<Scalars['uuid']>;
  kycStatus?: Maybe<Scalars['Int']>;
  mrvA?: Maybe<Scalars['String']>;
  mrvB?: Maybe<Scalars['String']>;
  personalNumber?: Maybe<Scalars['String']>;
  photoBack?: Maybe<Scalars['String']>;
  photoFace?: Maybe<Scalars['String']>;
  photoFront?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['Int']>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  stateProvinceName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "sigma_identity" */
export type SigmaIdentityMutationResponse = {
  __typename?: 'SigmaIdentityMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SigmaIdentity>;
};

/** on_conflict condition type for table "sigma_identity" */
export type SigmaIdentityOnConflict = {
  constraint: SigmaIdentityConstraint;
  updateColumns?: Array<SigmaIdentityUpdateColumn>;
  where?: InputMaybe<SigmaIdentityBoolExp>;
};

/** Ordering options when selecting data from "sigma_identity". */
export type SigmaIdentityOrderBy = {
  amlStatus?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  dateExpired?: InputMaybe<OrderBy>;
  dateIssued?: InputMaybe<OrderBy>;
  dob?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  idAlpha?: InputMaybe<OrderBy>;
  idDigital?: InputMaybe<OrderBy>;
  idKind?: InputMaybe<OrderBy>;
  idNumeric?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  issuingOrganization?: InputMaybe<OrderBy>;
  kycStatus?: InputMaybe<OrderBy>;
  mrvA?: InputMaybe<OrderBy>;
  mrvB?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  personalNumber?: InputMaybe<OrderBy>;
  photoBack?: InputMaybe<OrderBy>;
  photoFace?: InputMaybe<OrderBy>;
  photoFront?: InputMaybe<OrderBy>;
  sex?: InputMaybe<OrderBy>;
  sigmaPrime?: InputMaybe<SigmaPrimeOrderBy>;
  sigmaPrimeId?: InputMaybe<OrderBy>;
  stateProvinceName?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: sigma_identity */
export type SigmaIdentityPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "sigma_identity" */
export enum SigmaIdentitySelectColumn {
  /** column name */
  AmlStatus = 'amlStatus',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateExpired = 'dateExpired',
  /** column name */
  DateIssued = 'dateIssued',
  /** column name */
  Dob = 'dob',
  /** column name */
  Id = 'id',
  /** column name */
  IdAlpha = 'idAlpha',
  /** column name */
  IdDigital = 'idDigital',
  /** column name */
  IdKind = 'idKind',
  /** column name */
  IdNumeric = 'idNumeric',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IssuingOrganization = 'issuingOrganization',
  /** column name */
  KycStatus = 'kycStatus',
  /** column name */
  MrvA = 'mrvA',
  /** column name */
  MrvB = 'mrvB',
  /** column name */
  PersonalNumber = 'personalNumber',
  /** column name */
  PhotoBack = 'photoBack',
  /** column name */
  PhotoFace = 'photoFace',
  /** column name */
  PhotoFront = 'photoFront',
  /** column name */
  Sex = 'sex',
  /** column name */
  SigmaPrimeId = 'sigmaPrimeId',
  /** column name */
  StateProvinceName = 'stateProvinceName',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "sigma_identity" */
export type SigmaIdentitySetInput = {
  amlStatus?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dateExpired?: InputMaybe<Scalars['timestamptz']>;
  dateIssued?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  idAlpha?: InputMaybe<Scalars['String']>;
  idDigital?: InputMaybe<Scalars['String']>;
  idKind?: InputMaybe<Scalars['Int']>;
  idNumeric?: InputMaybe<Scalars['bigint']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  issuingOrganization?: InputMaybe<Scalars['uuid']>;
  kycStatus?: InputMaybe<Scalars['Int']>;
  mrvA?: InputMaybe<Scalars['String']>;
  mrvB?: InputMaybe<Scalars['String']>;
  personalNumber?: InputMaybe<Scalars['String']>;
  photoBack?: InputMaybe<Scalars['String']>;
  photoFace?: InputMaybe<Scalars['String']>;
  photoFront?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Int']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  stateProvinceName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type SigmaIdentityStddevFields = {
  __typename?: 'SigmaIdentityStddevFields';
  amlStatus?: Maybe<Scalars['Float']>;
  idKind?: Maybe<Scalars['Float']>;
  idNumeric?: Maybe<Scalars['Float']>;
  kycStatus?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type SigmaIdentityStddevPopFields = {
  __typename?: 'SigmaIdentityStddevPopFields';
  amlStatus?: Maybe<Scalars['Float']>;
  idKind?: Maybe<Scalars['Float']>;
  idNumeric?: Maybe<Scalars['Float']>;
  kycStatus?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type SigmaIdentityStddevSampFields = {
  __typename?: 'SigmaIdentityStddevSampFields';
  amlStatus?: Maybe<Scalars['Float']>;
  idKind?: Maybe<Scalars['Float']>;
  idNumeric?: Maybe<Scalars['Float']>;
  kycStatus?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "sigma_identity" */
export type SigmaIdentityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SigmaIdentityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SigmaIdentityStreamCursorValueInput = {
  amlStatus?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dateExpired?: InputMaybe<Scalars['timestamptz']>;
  dateIssued?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  idAlpha?: InputMaybe<Scalars['String']>;
  idDigital?: InputMaybe<Scalars['String']>;
  idKind?: InputMaybe<Scalars['Int']>;
  idNumeric?: InputMaybe<Scalars['bigint']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  issuingOrganization?: InputMaybe<Scalars['uuid']>;
  kycStatus?: InputMaybe<Scalars['Int']>;
  mrvA?: InputMaybe<Scalars['String']>;
  mrvB?: InputMaybe<Scalars['String']>;
  personalNumber?: InputMaybe<Scalars['String']>;
  photoBack?: InputMaybe<Scalars['String']>;
  photoFace?: InputMaybe<Scalars['String']>;
  photoFront?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Int']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  stateProvinceName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type SigmaIdentitySumFields = {
  __typename?: 'SigmaIdentitySumFields';
  amlStatus?: Maybe<Scalars['Int']>;
  idKind?: Maybe<Scalars['Int']>;
  idNumeric?: Maybe<Scalars['bigint']>;
  kycStatus?: Maybe<Scalars['Int']>;
  sex?: Maybe<Scalars['Int']>;
};

/** update columns of table "sigma_identity" */
export enum SigmaIdentityUpdateColumn {
  /** column name */
  AmlStatus = 'amlStatus',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateExpired = 'dateExpired',
  /** column name */
  DateIssued = 'dateIssued',
  /** column name */
  Dob = 'dob',
  /** column name */
  Id = 'id',
  /** column name */
  IdAlpha = 'idAlpha',
  /** column name */
  IdDigital = 'idDigital',
  /** column name */
  IdKind = 'idKind',
  /** column name */
  IdNumeric = 'idNumeric',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IssuingOrganization = 'issuingOrganization',
  /** column name */
  KycStatus = 'kycStatus',
  /** column name */
  MrvA = 'mrvA',
  /** column name */
  MrvB = 'mrvB',
  /** column name */
  PersonalNumber = 'personalNumber',
  /** column name */
  PhotoBack = 'photoBack',
  /** column name */
  PhotoFace = 'photoFace',
  /** column name */
  PhotoFront = 'photoFront',
  /** column name */
  Sex = 'sex',
  /** column name */
  SigmaPrimeId = 'sigmaPrimeId',
  /** column name */
  StateProvinceName = 'stateProvinceName',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type SigmaIdentityUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SigmaIdentityIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SigmaIdentitySetInput>;
  /** filter the rows which have to be updated */
  where: SigmaIdentityBoolExp;
};

/** aggregate varPop on columns */
export type SigmaIdentityVarPopFields = {
  __typename?: 'SigmaIdentityVarPopFields';
  amlStatus?: Maybe<Scalars['Float']>;
  idKind?: Maybe<Scalars['Float']>;
  idNumeric?: Maybe<Scalars['Float']>;
  kycStatus?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type SigmaIdentityVarSampFields = {
  __typename?: 'SigmaIdentityVarSampFields';
  amlStatus?: Maybe<Scalars['Float']>;
  idKind?: Maybe<Scalars['Float']>;
  idNumeric?: Maybe<Scalars['Float']>;
  kycStatus?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SigmaIdentityVarianceFields = {
  __typename?: 'SigmaIdentityVarianceFields';
  amlStatus?: Maybe<Scalars['Float']>;
  idKind?: Maybe<Scalars['Float']>;
  idNumeric?: Maybe<Scalars['Float']>;
  kycStatus?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "sigma_prime" */
export type SigmaPrime = {
  __typename?: 'SigmaPrime';
  aboutMe?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  crwdId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isAccredited?: Maybe<Scalars['Boolean']>;
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  kind?: Maybe<SigmaPrimeKind>;
  personBusinessEmail?: Maybe<Scalars['citext']>;
  personExchangeRequest: Scalars['String'];
  personFamilyName?: Maybe<Scalars['String']>;
  personFirstName: Scalars['String'];
  personHandleName?: Maybe<Scalars['citext']>;
  personLastName?: Maybe<Scalars['String']>;
  personMiddleName?: Maybe<Scalars['String']>;
  personNameSuffix?: Maybe<Scalars['String']>;
  /** An object relationship */
  personParent1?: Maybe<SigmaPrime>;
  personParent1SigmaId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  personParent2?: Maybe<SigmaPrime>;
  personParent2SigmaId?: Maybe<Scalars['uuid']>;
  personPrimaryAmlStatus?: Maybe<Scalars['String']>;
  personPrimaryEmail: Scalars['citext'];
  personPrimaryFacePhoto?: Maybe<Scalars['String']>;
  personPrimaryIpAddress?: Maybe<Scalars['String']>;
  personPrimaryKycStatus: Scalars['String'];
  personPrimaryLanguageId?: Maybe<Scalars['String']>;
  personPrimaryMobile?: Maybe<Scalars['String']>;
  personPrimaryPhone?: Maybe<Scalars['String']>;
  personPrivacyPolicy: Scalars['String'];
  personStatus: Scalars['String'];
  personTitleName?: Maybe<Scalars['String']>;
  personUniqueHash: Scalars['String'];
  profilePhoto?: Maybe<Scalars['String']>;
  sigmaPrimeKindId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "sigma_prime_address" */
export type SigmaPrimeAddress = {
  __typename?: 'SigmaPrimeAddress';
  addressEmail?: Maybe<Scalars['String']>;
  addressIp?: Maybe<Scalars['String']>;
  addressRecordType?: Maybe<Scalars['String']>;
  addressSsn?: Maybe<Scalars['String']>;
  addressSubindustry?: Maybe<Scalars['String']>;
  addressType?: Maybe<Scalars['String']>;
  ageRange?: Maybe<Scalars['String']>;
  carrierRoute?: Maybe<Scalars['String']>;
  childPresent?: Maybe<Scalars['String']>;
  congressionalDistrict?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  dataPedigree?: Maybe<Scalars['String']>;
  dpvCode?: Maybe<Scalars['String']>;
  dwellingType?: Maybe<Scalars['String']>;
  elotSequence?: Maybe<Scalars['Int']>;
  estimatedAge?: Maybe<Scalars['String']>;
  estimatedIncome?: Maybe<Scalars['String']>;
  estimatedWealth?: Maybe<Scalars['String']>;
  ethnicCd?: Maybe<Scalars['String']>;
  exactAge?: Maybe<Scalars['String']>;
  firstInHouse?: Maybe<Scalars['String']>;
  geocode?: Maybe<Scalars['String']>;
  homeownerType?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  marital?: Maybe<Scalars['String']>;
  medianHomeValue?: Maybe<Scalars['String']>;
  medianYrsEducation?: Maybe<Scalars['String']>;
  mfdu?: Maybe<Scalars['String']>;
  personAddress1?: Maybe<Scalars['String']>;
  personAddress2?: Maybe<Scalars['String']>;
  personAddressLatitude?: Maybe<Scalars['String']>;
  personAddressLongitude?: Maybe<Scalars['String']>;
  personCity?: Maybe<Scalars['String']>;
  personCountryCode?: Maybe<Scalars['String']>;
  personDob?: Maybe<Scalars['String']>;
  personEmail?: Maybe<Scalars['String']>;
  personFirstName?: Maybe<Scalars['String']>;
  personLastName?: Maybe<Scalars['String']>;
  personMiddleName?: Maybe<Scalars['String']>;
  personPhoneNumber?: Maybe<Scalars['String']>;
  personPostal?: Maybe<Scalars['String']>;
  personPostal4?: Maybe<Scalars['String']>;
  personProvinceState?: Maybe<Scalars['String']>;
  personRegionCounty?: Maybe<Scalars['String']>;
  personSector?: Maybe<Scalars['String']>;
  personUniqueHash: Scalars['String'];
  property?: Maybe<Scalars['String']>;
  rdi?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  /** An object relationship */
  sigmaPrime?: Maybe<SigmaPrime>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  timeZone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "sigma_prime_address" */
export type SigmaPrimeAddressAggregate = {
  __typename?: 'SigmaPrimeAddressAggregate';
  aggregate?: Maybe<SigmaPrimeAddressAggregateFields>;
  nodes: Array<SigmaPrimeAddress>;
};

/** aggregate fields of "sigma_prime_address" */
export type SigmaPrimeAddressAggregateFields = {
  __typename?: 'SigmaPrimeAddressAggregateFields';
  avg?: Maybe<SigmaPrimeAddressAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SigmaPrimeAddressMaxFields>;
  min?: Maybe<SigmaPrimeAddressMinFields>;
  stddev?: Maybe<SigmaPrimeAddressStddevFields>;
  stddevPop?: Maybe<SigmaPrimeAddressStddevPopFields>;
  stddevSamp?: Maybe<SigmaPrimeAddressStddevSampFields>;
  sum?: Maybe<SigmaPrimeAddressSumFields>;
  varPop?: Maybe<SigmaPrimeAddressVarPopFields>;
  varSamp?: Maybe<SigmaPrimeAddressVarSampFields>;
  variance?: Maybe<SigmaPrimeAddressVarianceFields>;
};

/** aggregate fields of "sigma_prime_address" */
export type SigmaPrimeAddressAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SigmaPrimeAddressSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SigmaPrimeAddressAvgFields = {
  __typename?: 'SigmaPrimeAddressAvgFields';
  elotSequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sigma_prime_address". All fields are combined with a logical 'AND'. */
export type SigmaPrimeAddressBoolExp = {
  _and?: InputMaybe<Array<SigmaPrimeAddressBoolExp>>;
  _not?: InputMaybe<SigmaPrimeAddressBoolExp>;
  _or?: InputMaybe<Array<SigmaPrimeAddressBoolExp>>;
  addressEmail?: InputMaybe<StringComparisonExp>;
  addressIp?: InputMaybe<StringComparisonExp>;
  addressRecordType?: InputMaybe<StringComparisonExp>;
  addressSsn?: InputMaybe<StringComparisonExp>;
  addressSubindustry?: InputMaybe<StringComparisonExp>;
  addressType?: InputMaybe<StringComparisonExp>;
  ageRange?: InputMaybe<StringComparisonExp>;
  carrierRoute?: InputMaybe<StringComparisonExp>;
  childPresent?: InputMaybe<StringComparisonExp>;
  congressionalDistrict?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  dataPedigree?: InputMaybe<StringComparisonExp>;
  dpvCode?: InputMaybe<StringComparisonExp>;
  dwellingType?: InputMaybe<StringComparisonExp>;
  elotSequence?: InputMaybe<IntComparisonExp>;
  estimatedAge?: InputMaybe<StringComparisonExp>;
  estimatedIncome?: InputMaybe<StringComparisonExp>;
  estimatedWealth?: InputMaybe<StringComparisonExp>;
  ethnicCd?: InputMaybe<StringComparisonExp>;
  exactAge?: InputMaybe<StringComparisonExp>;
  firstInHouse?: InputMaybe<StringComparisonExp>;
  geocode?: InputMaybe<StringComparisonExp>;
  homeownerType?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  marital?: InputMaybe<StringComparisonExp>;
  medianHomeValue?: InputMaybe<StringComparisonExp>;
  medianYrsEducation?: InputMaybe<StringComparisonExp>;
  mfdu?: InputMaybe<StringComparisonExp>;
  personAddress1?: InputMaybe<StringComparisonExp>;
  personAddress2?: InputMaybe<StringComparisonExp>;
  personAddressLatitude?: InputMaybe<StringComparisonExp>;
  personAddressLongitude?: InputMaybe<StringComparisonExp>;
  personCity?: InputMaybe<StringComparisonExp>;
  personCountryCode?: InputMaybe<StringComparisonExp>;
  personDob?: InputMaybe<StringComparisonExp>;
  personEmail?: InputMaybe<StringComparisonExp>;
  personFirstName?: InputMaybe<StringComparisonExp>;
  personLastName?: InputMaybe<StringComparisonExp>;
  personMiddleName?: InputMaybe<StringComparisonExp>;
  personPhoneNumber?: InputMaybe<StringComparisonExp>;
  personPostal?: InputMaybe<StringComparisonExp>;
  personPostal4?: InputMaybe<StringComparisonExp>;
  personProvinceState?: InputMaybe<StringComparisonExp>;
  personRegionCounty?: InputMaybe<StringComparisonExp>;
  personSector?: InputMaybe<StringComparisonExp>;
  personUniqueHash?: InputMaybe<StringComparisonExp>;
  property?: InputMaybe<StringComparisonExp>;
  rdi?: InputMaybe<StringComparisonExp>;
  sex?: InputMaybe<StringComparisonExp>;
  sigmaPrime?: InputMaybe<SigmaPrimeBoolExp>;
  sigmaPrimeId?: InputMaybe<UuidComparisonExp>;
  timeZone?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "sigma_prime_address" */
export enum SigmaPrimeAddressConstraint {
  /** unique or primary key constraint on columns "person_unique_hash" */
  SigmaPrimeAddressPersonUuidKey = 'sigma_prime_address_person_uuid_key',
  /** unique or primary key constraint on columns "id" */
  SigmaPrimeAddressPkey = 'sigma_prime_address_pkey',
}

/** input type for incrementing numeric columns in table "sigma_prime_address" */
export type SigmaPrimeAddressIncInput = {
  elotSequence?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "sigma_prime_address" */
export type SigmaPrimeAddressInsertInput = {
  addressEmail?: InputMaybe<Scalars['String']>;
  addressIp?: InputMaybe<Scalars['String']>;
  addressRecordType?: InputMaybe<Scalars['String']>;
  addressSsn?: InputMaybe<Scalars['String']>;
  addressSubindustry?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<Scalars['String']>;
  ageRange?: InputMaybe<Scalars['String']>;
  carrierRoute?: InputMaybe<Scalars['String']>;
  childPresent?: InputMaybe<Scalars['String']>;
  congressionalDistrict?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dataPedigree?: InputMaybe<Scalars['String']>;
  dpvCode?: InputMaybe<Scalars['String']>;
  dwellingType?: InputMaybe<Scalars['String']>;
  elotSequence?: InputMaybe<Scalars['Int']>;
  estimatedAge?: InputMaybe<Scalars['String']>;
  estimatedIncome?: InputMaybe<Scalars['String']>;
  estimatedWealth?: InputMaybe<Scalars['String']>;
  ethnicCd?: InputMaybe<Scalars['String']>;
  exactAge?: InputMaybe<Scalars['String']>;
  firstInHouse?: InputMaybe<Scalars['String']>;
  geocode?: InputMaybe<Scalars['String']>;
  homeownerType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  marital?: InputMaybe<Scalars['String']>;
  medianHomeValue?: InputMaybe<Scalars['String']>;
  medianYrsEducation?: InputMaybe<Scalars['String']>;
  mfdu?: InputMaybe<Scalars['String']>;
  personAddress1?: InputMaybe<Scalars['String']>;
  personAddress2?: InputMaybe<Scalars['String']>;
  personAddressLatitude?: InputMaybe<Scalars['String']>;
  personAddressLongitude?: InputMaybe<Scalars['String']>;
  personCity?: InputMaybe<Scalars['String']>;
  personCountryCode?: InputMaybe<Scalars['String']>;
  personDob?: InputMaybe<Scalars['String']>;
  personEmail?: InputMaybe<Scalars['String']>;
  personFirstName?: InputMaybe<Scalars['String']>;
  personLastName?: InputMaybe<Scalars['String']>;
  personMiddleName?: InputMaybe<Scalars['String']>;
  personPhoneNumber?: InputMaybe<Scalars['String']>;
  personPostal?: InputMaybe<Scalars['String']>;
  personPostal4?: InputMaybe<Scalars['String']>;
  personProvinceState?: InputMaybe<Scalars['String']>;
  personRegionCounty?: InputMaybe<Scalars['String']>;
  personSector?: InputMaybe<Scalars['String']>;
  personUniqueHash?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  rdi?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  sigmaPrime?: InputMaybe<SigmaPrimeObjRelInsertInput>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  timeZone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SigmaPrimeAddressMaxFields = {
  __typename?: 'SigmaPrimeAddressMaxFields';
  addressEmail?: Maybe<Scalars['String']>;
  addressIp?: Maybe<Scalars['String']>;
  addressRecordType?: Maybe<Scalars['String']>;
  addressSsn?: Maybe<Scalars['String']>;
  addressSubindustry?: Maybe<Scalars['String']>;
  addressType?: Maybe<Scalars['String']>;
  ageRange?: Maybe<Scalars['String']>;
  carrierRoute?: Maybe<Scalars['String']>;
  childPresent?: Maybe<Scalars['String']>;
  congressionalDistrict?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dataPedigree?: Maybe<Scalars['String']>;
  dpvCode?: Maybe<Scalars['String']>;
  dwellingType?: Maybe<Scalars['String']>;
  elotSequence?: Maybe<Scalars['Int']>;
  estimatedAge?: Maybe<Scalars['String']>;
  estimatedIncome?: Maybe<Scalars['String']>;
  estimatedWealth?: Maybe<Scalars['String']>;
  ethnicCd?: Maybe<Scalars['String']>;
  exactAge?: Maybe<Scalars['String']>;
  firstInHouse?: Maybe<Scalars['String']>;
  geocode?: Maybe<Scalars['String']>;
  homeownerType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marital?: Maybe<Scalars['String']>;
  medianHomeValue?: Maybe<Scalars['String']>;
  medianYrsEducation?: Maybe<Scalars['String']>;
  mfdu?: Maybe<Scalars['String']>;
  personAddress1?: Maybe<Scalars['String']>;
  personAddress2?: Maybe<Scalars['String']>;
  personAddressLatitude?: Maybe<Scalars['String']>;
  personAddressLongitude?: Maybe<Scalars['String']>;
  personCity?: Maybe<Scalars['String']>;
  personCountryCode?: Maybe<Scalars['String']>;
  personDob?: Maybe<Scalars['String']>;
  personEmail?: Maybe<Scalars['String']>;
  personFirstName?: Maybe<Scalars['String']>;
  personLastName?: Maybe<Scalars['String']>;
  personMiddleName?: Maybe<Scalars['String']>;
  personPhoneNumber?: Maybe<Scalars['String']>;
  personPostal?: Maybe<Scalars['String']>;
  personPostal4?: Maybe<Scalars['String']>;
  personProvinceState?: Maybe<Scalars['String']>;
  personRegionCounty?: Maybe<Scalars['String']>;
  personSector?: Maybe<Scalars['String']>;
  personUniqueHash?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  rdi?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  timeZone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type SigmaPrimeAddressMinFields = {
  __typename?: 'SigmaPrimeAddressMinFields';
  addressEmail?: Maybe<Scalars['String']>;
  addressIp?: Maybe<Scalars['String']>;
  addressRecordType?: Maybe<Scalars['String']>;
  addressSsn?: Maybe<Scalars['String']>;
  addressSubindustry?: Maybe<Scalars['String']>;
  addressType?: Maybe<Scalars['String']>;
  ageRange?: Maybe<Scalars['String']>;
  carrierRoute?: Maybe<Scalars['String']>;
  childPresent?: Maybe<Scalars['String']>;
  congressionalDistrict?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dataPedigree?: Maybe<Scalars['String']>;
  dpvCode?: Maybe<Scalars['String']>;
  dwellingType?: Maybe<Scalars['String']>;
  elotSequence?: Maybe<Scalars['Int']>;
  estimatedAge?: Maybe<Scalars['String']>;
  estimatedIncome?: Maybe<Scalars['String']>;
  estimatedWealth?: Maybe<Scalars['String']>;
  ethnicCd?: Maybe<Scalars['String']>;
  exactAge?: Maybe<Scalars['String']>;
  firstInHouse?: Maybe<Scalars['String']>;
  geocode?: Maybe<Scalars['String']>;
  homeownerType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marital?: Maybe<Scalars['String']>;
  medianHomeValue?: Maybe<Scalars['String']>;
  medianYrsEducation?: Maybe<Scalars['String']>;
  mfdu?: Maybe<Scalars['String']>;
  personAddress1?: Maybe<Scalars['String']>;
  personAddress2?: Maybe<Scalars['String']>;
  personAddressLatitude?: Maybe<Scalars['String']>;
  personAddressLongitude?: Maybe<Scalars['String']>;
  personCity?: Maybe<Scalars['String']>;
  personCountryCode?: Maybe<Scalars['String']>;
  personDob?: Maybe<Scalars['String']>;
  personEmail?: Maybe<Scalars['String']>;
  personFirstName?: Maybe<Scalars['String']>;
  personLastName?: Maybe<Scalars['String']>;
  personMiddleName?: Maybe<Scalars['String']>;
  personPhoneNumber?: Maybe<Scalars['String']>;
  personPostal?: Maybe<Scalars['String']>;
  personPostal4?: Maybe<Scalars['String']>;
  personProvinceState?: Maybe<Scalars['String']>;
  personRegionCounty?: Maybe<Scalars['String']>;
  personSector?: Maybe<Scalars['String']>;
  personUniqueHash?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  rdi?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  timeZone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "sigma_prime_address" */
export type SigmaPrimeAddressMutationResponse = {
  __typename?: 'SigmaPrimeAddressMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SigmaPrimeAddress>;
};

/** input type for inserting object relation for remote table "sigma_prime_address" */
export type SigmaPrimeAddressObjRelInsertInput = {
  data: SigmaPrimeAddressInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<SigmaPrimeAddressOnConflict>;
};

/** on_conflict condition type for table "sigma_prime_address" */
export type SigmaPrimeAddressOnConflict = {
  constraint: SigmaPrimeAddressConstraint;
  updateColumns?: Array<SigmaPrimeAddressUpdateColumn>;
  where?: InputMaybe<SigmaPrimeAddressBoolExp>;
};

/** Ordering options when selecting data from "sigma_prime_address". */
export type SigmaPrimeAddressOrderBy = {
  addressEmail?: InputMaybe<OrderBy>;
  addressIp?: InputMaybe<OrderBy>;
  addressRecordType?: InputMaybe<OrderBy>;
  addressSsn?: InputMaybe<OrderBy>;
  addressSubindustry?: InputMaybe<OrderBy>;
  addressType?: InputMaybe<OrderBy>;
  ageRange?: InputMaybe<OrderBy>;
  carrierRoute?: InputMaybe<OrderBy>;
  childPresent?: InputMaybe<OrderBy>;
  congressionalDistrict?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  dataPedigree?: InputMaybe<OrderBy>;
  dpvCode?: InputMaybe<OrderBy>;
  dwellingType?: InputMaybe<OrderBy>;
  elotSequence?: InputMaybe<OrderBy>;
  estimatedAge?: InputMaybe<OrderBy>;
  estimatedIncome?: InputMaybe<OrderBy>;
  estimatedWealth?: InputMaybe<OrderBy>;
  ethnicCd?: InputMaybe<OrderBy>;
  exactAge?: InputMaybe<OrderBy>;
  firstInHouse?: InputMaybe<OrderBy>;
  geocode?: InputMaybe<OrderBy>;
  homeownerType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  marital?: InputMaybe<OrderBy>;
  medianHomeValue?: InputMaybe<OrderBy>;
  medianYrsEducation?: InputMaybe<OrderBy>;
  mfdu?: InputMaybe<OrderBy>;
  personAddress1?: InputMaybe<OrderBy>;
  personAddress2?: InputMaybe<OrderBy>;
  personAddressLatitude?: InputMaybe<OrderBy>;
  personAddressLongitude?: InputMaybe<OrderBy>;
  personCity?: InputMaybe<OrderBy>;
  personCountryCode?: InputMaybe<OrderBy>;
  personDob?: InputMaybe<OrderBy>;
  personEmail?: InputMaybe<OrderBy>;
  personFirstName?: InputMaybe<OrderBy>;
  personLastName?: InputMaybe<OrderBy>;
  personMiddleName?: InputMaybe<OrderBy>;
  personPhoneNumber?: InputMaybe<OrderBy>;
  personPostal?: InputMaybe<OrderBy>;
  personPostal4?: InputMaybe<OrderBy>;
  personProvinceState?: InputMaybe<OrderBy>;
  personRegionCounty?: InputMaybe<OrderBy>;
  personSector?: InputMaybe<OrderBy>;
  personUniqueHash?: InputMaybe<OrderBy>;
  property?: InputMaybe<OrderBy>;
  rdi?: InputMaybe<OrderBy>;
  sex?: InputMaybe<OrderBy>;
  sigmaPrime?: InputMaybe<SigmaPrimeOrderBy>;
  sigmaPrimeId?: InputMaybe<OrderBy>;
  timeZone?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: sigma_prime_address */
export type SigmaPrimeAddressPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "sigma_prime_address" */
export enum SigmaPrimeAddressSelectColumn {
  /** column name */
  AddressEmail = 'addressEmail',
  /** column name */
  AddressIp = 'addressIp',
  /** column name */
  AddressRecordType = 'addressRecordType',
  /** column name */
  AddressSsn = 'addressSsn',
  /** column name */
  AddressSubindustry = 'addressSubindustry',
  /** column name */
  AddressType = 'addressType',
  /** column name */
  AgeRange = 'ageRange',
  /** column name */
  CarrierRoute = 'carrierRoute',
  /** column name */
  ChildPresent = 'childPresent',
  /** column name */
  CongressionalDistrict = 'congressionalDistrict',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DataPedigree = 'dataPedigree',
  /** column name */
  DpvCode = 'dpvCode',
  /** column name */
  DwellingType = 'dwellingType',
  /** column name */
  ElotSequence = 'elotSequence',
  /** column name */
  EstimatedAge = 'estimatedAge',
  /** column name */
  EstimatedIncome = 'estimatedIncome',
  /** column name */
  EstimatedWealth = 'estimatedWealth',
  /** column name */
  EthnicCd = 'ethnicCd',
  /** column name */
  ExactAge = 'exactAge',
  /** column name */
  FirstInHouse = 'firstInHouse',
  /** column name */
  Geocode = 'geocode',
  /** column name */
  HomeownerType = 'homeownerType',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Marital = 'marital',
  /** column name */
  MedianHomeValue = 'medianHomeValue',
  /** column name */
  MedianYrsEducation = 'medianYrsEducation',
  /** column name */
  Mfdu = 'mfdu',
  /** column name */
  PersonAddress1 = 'personAddress1',
  /** column name */
  PersonAddress2 = 'personAddress2',
  /** column name */
  PersonAddressLatitude = 'personAddressLatitude',
  /** column name */
  PersonAddressLongitude = 'personAddressLongitude',
  /** column name */
  PersonCity = 'personCity',
  /** column name */
  PersonCountryCode = 'personCountryCode',
  /** column name */
  PersonDob = 'personDob',
  /** column name */
  PersonEmail = 'personEmail',
  /** column name */
  PersonFirstName = 'personFirstName',
  /** column name */
  PersonLastName = 'personLastName',
  /** column name */
  PersonMiddleName = 'personMiddleName',
  /** column name */
  PersonPhoneNumber = 'personPhoneNumber',
  /** column name */
  PersonPostal = 'personPostal',
  /** column name */
  PersonPostal4 = 'personPostal4',
  /** column name */
  PersonProvinceState = 'personProvinceState',
  /** column name */
  PersonRegionCounty = 'personRegionCounty',
  /** column name */
  PersonSector = 'personSector',
  /** column name */
  PersonUniqueHash = 'personUniqueHash',
  /** column name */
  Property = 'property',
  /** column name */
  Rdi = 'rdi',
  /** column name */
  Sex = 'sex',
  /** column name */
  SigmaPrimeId = 'sigmaPrimeId',
  /** column name */
  TimeZone = 'timeZone',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "sigma_prime_address" */
export type SigmaPrimeAddressSetInput = {
  addressEmail?: InputMaybe<Scalars['String']>;
  addressIp?: InputMaybe<Scalars['String']>;
  addressRecordType?: InputMaybe<Scalars['String']>;
  addressSsn?: InputMaybe<Scalars['String']>;
  addressSubindustry?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<Scalars['String']>;
  ageRange?: InputMaybe<Scalars['String']>;
  carrierRoute?: InputMaybe<Scalars['String']>;
  childPresent?: InputMaybe<Scalars['String']>;
  congressionalDistrict?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dataPedigree?: InputMaybe<Scalars['String']>;
  dpvCode?: InputMaybe<Scalars['String']>;
  dwellingType?: InputMaybe<Scalars['String']>;
  elotSequence?: InputMaybe<Scalars['Int']>;
  estimatedAge?: InputMaybe<Scalars['String']>;
  estimatedIncome?: InputMaybe<Scalars['String']>;
  estimatedWealth?: InputMaybe<Scalars['String']>;
  ethnicCd?: InputMaybe<Scalars['String']>;
  exactAge?: InputMaybe<Scalars['String']>;
  firstInHouse?: InputMaybe<Scalars['String']>;
  geocode?: InputMaybe<Scalars['String']>;
  homeownerType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  marital?: InputMaybe<Scalars['String']>;
  medianHomeValue?: InputMaybe<Scalars['String']>;
  medianYrsEducation?: InputMaybe<Scalars['String']>;
  mfdu?: InputMaybe<Scalars['String']>;
  personAddress1?: InputMaybe<Scalars['String']>;
  personAddress2?: InputMaybe<Scalars['String']>;
  personAddressLatitude?: InputMaybe<Scalars['String']>;
  personAddressLongitude?: InputMaybe<Scalars['String']>;
  personCity?: InputMaybe<Scalars['String']>;
  personCountryCode?: InputMaybe<Scalars['String']>;
  personDob?: InputMaybe<Scalars['String']>;
  personEmail?: InputMaybe<Scalars['String']>;
  personFirstName?: InputMaybe<Scalars['String']>;
  personLastName?: InputMaybe<Scalars['String']>;
  personMiddleName?: InputMaybe<Scalars['String']>;
  personPhoneNumber?: InputMaybe<Scalars['String']>;
  personPostal?: InputMaybe<Scalars['String']>;
  personPostal4?: InputMaybe<Scalars['String']>;
  personProvinceState?: InputMaybe<Scalars['String']>;
  personRegionCounty?: InputMaybe<Scalars['String']>;
  personSector?: InputMaybe<Scalars['String']>;
  personUniqueHash?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  rdi?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  timeZone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type SigmaPrimeAddressStddevFields = {
  __typename?: 'SigmaPrimeAddressStddevFields';
  elotSequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type SigmaPrimeAddressStddevPopFields = {
  __typename?: 'SigmaPrimeAddressStddevPopFields';
  elotSequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type SigmaPrimeAddressStddevSampFields = {
  __typename?: 'SigmaPrimeAddressStddevSampFields';
  elotSequence?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "sigma_prime_address" */
export type SigmaPrimeAddressStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SigmaPrimeAddressStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SigmaPrimeAddressStreamCursorValueInput = {
  addressEmail?: InputMaybe<Scalars['String']>;
  addressIp?: InputMaybe<Scalars['String']>;
  addressRecordType?: InputMaybe<Scalars['String']>;
  addressSsn?: InputMaybe<Scalars['String']>;
  addressSubindustry?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<Scalars['String']>;
  ageRange?: InputMaybe<Scalars['String']>;
  carrierRoute?: InputMaybe<Scalars['String']>;
  childPresent?: InputMaybe<Scalars['String']>;
  congressionalDistrict?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dataPedigree?: InputMaybe<Scalars['String']>;
  dpvCode?: InputMaybe<Scalars['String']>;
  dwellingType?: InputMaybe<Scalars['String']>;
  elotSequence?: InputMaybe<Scalars['Int']>;
  estimatedAge?: InputMaybe<Scalars['String']>;
  estimatedIncome?: InputMaybe<Scalars['String']>;
  estimatedWealth?: InputMaybe<Scalars['String']>;
  ethnicCd?: InputMaybe<Scalars['String']>;
  exactAge?: InputMaybe<Scalars['String']>;
  firstInHouse?: InputMaybe<Scalars['String']>;
  geocode?: InputMaybe<Scalars['String']>;
  homeownerType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  marital?: InputMaybe<Scalars['String']>;
  medianHomeValue?: InputMaybe<Scalars['String']>;
  medianYrsEducation?: InputMaybe<Scalars['String']>;
  mfdu?: InputMaybe<Scalars['String']>;
  personAddress1?: InputMaybe<Scalars['String']>;
  personAddress2?: InputMaybe<Scalars['String']>;
  personAddressLatitude?: InputMaybe<Scalars['String']>;
  personAddressLongitude?: InputMaybe<Scalars['String']>;
  personCity?: InputMaybe<Scalars['String']>;
  personCountryCode?: InputMaybe<Scalars['String']>;
  personDob?: InputMaybe<Scalars['String']>;
  personEmail?: InputMaybe<Scalars['String']>;
  personFirstName?: InputMaybe<Scalars['String']>;
  personLastName?: InputMaybe<Scalars['String']>;
  personMiddleName?: InputMaybe<Scalars['String']>;
  personPhoneNumber?: InputMaybe<Scalars['String']>;
  personPostal?: InputMaybe<Scalars['String']>;
  personPostal4?: InputMaybe<Scalars['String']>;
  personProvinceState?: InputMaybe<Scalars['String']>;
  personRegionCounty?: InputMaybe<Scalars['String']>;
  personSector?: InputMaybe<Scalars['String']>;
  personUniqueHash?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  rdi?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  timeZone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type SigmaPrimeAddressSumFields = {
  __typename?: 'SigmaPrimeAddressSumFields';
  elotSequence?: Maybe<Scalars['Int']>;
};

/** update columns of table "sigma_prime_address" */
export enum SigmaPrimeAddressUpdateColumn {
  /** column name */
  AddressEmail = 'addressEmail',
  /** column name */
  AddressIp = 'addressIp',
  /** column name */
  AddressRecordType = 'addressRecordType',
  /** column name */
  AddressSsn = 'addressSsn',
  /** column name */
  AddressSubindustry = 'addressSubindustry',
  /** column name */
  AddressType = 'addressType',
  /** column name */
  AgeRange = 'ageRange',
  /** column name */
  CarrierRoute = 'carrierRoute',
  /** column name */
  ChildPresent = 'childPresent',
  /** column name */
  CongressionalDistrict = 'congressionalDistrict',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DataPedigree = 'dataPedigree',
  /** column name */
  DpvCode = 'dpvCode',
  /** column name */
  DwellingType = 'dwellingType',
  /** column name */
  ElotSequence = 'elotSequence',
  /** column name */
  EstimatedAge = 'estimatedAge',
  /** column name */
  EstimatedIncome = 'estimatedIncome',
  /** column name */
  EstimatedWealth = 'estimatedWealth',
  /** column name */
  EthnicCd = 'ethnicCd',
  /** column name */
  ExactAge = 'exactAge',
  /** column name */
  FirstInHouse = 'firstInHouse',
  /** column name */
  Geocode = 'geocode',
  /** column name */
  HomeownerType = 'homeownerType',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Marital = 'marital',
  /** column name */
  MedianHomeValue = 'medianHomeValue',
  /** column name */
  MedianYrsEducation = 'medianYrsEducation',
  /** column name */
  Mfdu = 'mfdu',
  /** column name */
  PersonAddress1 = 'personAddress1',
  /** column name */
  PersonAddress2 = 'personAddress2',
  /** column name */
  PersonAddressLatitude = 'personAddressLatitude',
  /** column name */
  PersonAddressLongitude = 'personAddressLongitude',
  /** column name */
  PersonCity = 'personCity',
  /** column name */
  PersonCountryCode = 'personCountryCode',
  /** column name */
  PersonDob = 'personDob',
  /** column name */
  PersonEmail = 'personEmail',
  /** column name */
  PersonFirstName = 'personFirstName',
  /** column name */
  PersonLastName = 'personLastName',
  /** column name */
  PersonMiddleName = 'personMiddleName',
  /** column name */
  PersonPhoneNumber = 'personPhoneNumber',
  /** column name */
  PersonPostal = 'personPostal',
  /** column name */
  PersonPostal4 = 'personPostal4',
  /** column name */
  PersonProvinceState = 'personProvinceState',
  /** column name */
  PersonRegionCounty = 'personRegionCounty',
  /** column name */
  PersonSector = 'personSector',
  /** column name */
  PersonUniqueHash = 'personUniqueHash',
  /** column name */
  Property = 'property',
  /** column name */
  Rdi = 'rdi',
  /** column name */
  Sex = 'sex',
  /** column name */
  SigmaPrimeId = 'sigmaPrimeId',
  /** column name */
  TimeZone = 'timeZone',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type SigmaPrimeAddressUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SigmaPrimeAddressIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SigmaPrimeAddressSetInput>;
  /** filter the rows which have to be updated */
  where: SigmaPrimeAddressBoolExp;
};

/** aggregate varPop on columns */
export type SigmaPrimeAddressVarPopFields = {
  __typename?: 'SigmaPrimeAddressVarPopFields';
  elotSequence?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type SigmaPrimeAddressVarSampFields = {
  __typename?: 'SigmaPrimeAddressVarSampFields';
  elotSequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SigmaPrimeAddressVarianceFields = {
  __typename?: 'SigmaPrimeAddressVarianceFields';
  elotSequence?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "sigma_prime" */
export type SigmaPrimeAggregate = {
  __typename?: 'SigmaPrimeAggregate';
  aggregate?: Maybe<SigmaPrimeAggregateFields>;
  nodes: Array<SigmaPrime>;
};

/** aggregate fields of "sigma_prime" */
export type SigmaPrimeAggregateFields = {
  __typename?: 'SigmaPrimeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SigmaPrimeMaxFields>;
  min?: Maybe<SigmaPrimeMinFields>;
};

/** aggregate fields of "sigma_prime" */
export type SigmaPrimeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SigmaPrimeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sigma_prime". All fields are combined with a logical 'AND'. */
export type SigmaPrimeBoolExp = {
  _and?: InputMaybe<Array<SigmaPrimeBoolExp>>;
  _not?: InputMaybe<SigmaPrimeBoolExp>;
  _or?: InputMaybe<Array<SigmaPrimeBoolExp>>;
  aboutMe?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  crwdId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isAccredited?: InputMaybe<BooleanComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  kind?: InputMaybe<SigmaPrimeKindBoolExp>;
  personBusinessEmail?: InputMaybe<CitextComparisonExp>;
  personExchangeRequest?: InputMaybe<StringComparisonExp>;
  personFamilyName?: InputMaybe<StringComparisonExp>;
  personFirstName?: InputMaybe<StringComparisonExp>;
  personHandleName?: InputMaybe<CitextComparisonExp>;
  personLastName?: InputMaybe<StringComparisonExp>;
  personMiddleName?: InputMaybe<StringComparisonExp>;
  personNameSuffix?: InputMaybe<StringComparisonExp>;
  personParent1?: InputMaybe<SigmaPrimeBoolExp>;
  personParent1SigmaId?: InputMaybe<UuidComparisonExp>;
  personParent2?: InputMaybe<SigmaPrimeBoolExp>;
  personParent2SigmaId?: InputMaybe<UuidComparisonExp>;
  personPrimaryAmlStatus?: InputMaybe<StringComparisonExp>;
  personPrimaryEmail?: InputMaybe<CitextComparisonExp>;
  personPrimaryFacePhoto?: InputMaybe<StringComparisonExp>;
  personPrimaryIpAddress?: InputMaybe<StringComparisonExp>;
  personPrimaryKycStatus?: InputMaybe<StringComparisonExp>;
  personPrimaryLanguageId?: InputMaybe<StringComparisonExp>;
  personPrimaryMobile?: InputMaybe<StringComparisonExp>;
  personPrimaryPhone?: InputMaybe<StringComparisonExp>;
  personPrivacyPolicy?: InputMaybe<StringComparisonExp>;
  personStatus?: InputMaybe<StringComparisonExp>;
  personTitleName?: InputMaybe<StringComparisonExp>;
  personUniqueHash?: InputMaybe<StringComparisonExp>;
  profilePhoto?: InputMaybe<StringComparisonExp>;
  sigmaPrimeKindId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "sigma_prime" */
export enum SigmaPrimeConstraint {
  /** unique or primary key constraint on columns "person_handle_name" */
  SigmaPrimePersonHandleNameKey = 'sigma_prime_person_handle_name_key',
  /** unique or primary key constraint on columns "person_primary_email" */
  SigmaPrimePersonPrimaryEmailKey = 'sigma_prime_person_primary_email_key',
  /** unique or primary key constraint on columns "person_unique_hash" */
  SigmaPrimePersonUuidKey = 'sigma_prime_person_uuid_key',
  /** unique or primary key constraint on columns "id" */
  SigmaPrimePkey = 'sigma_prime_pkey',
}

/** input type for inserting data into table "sigma_prime" */
export type SigmaPrimeInsertInput = {
  aboutMe?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isAccredited?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<SigmaPrimeKindObjRelInsertInput>;
  personBusinessEmail?: InputMaybe<Scalars['citext']>;
  personExchangeRequest?: InputMaybe<Scalars['String']>;
  personFamilyName?: InputMaybe<Scalars['String']>;
  personFirstName?: InputMaybe<Scalars['String']>;
  personHandleName?: InputMaybe<Scalars['citext']>;
  personLastName?: InputMaybe<Scalars['String']>;
  personMiddleName?: InputMaybe<Scalars['String']>;
  personNameSuffix?: InputMaybe<Scalars['String']>;
  personParent1?: InputMaybe<SigmaPrimeObjRelInsertInput>;
  personParent1SigmaId?: InputMaybe<Scalars['uuid']>;
  personParent2?: InputMaybe<SigmaPrimeObjRelInsertInput>;
  personParent2SigmaId?: InputMaybe<Scalars['uuid']>;
  personPrimaryAmlStatus?: InputMaybe<Scalars['String']>;
  personPrimaryEmail?: InputMaybe<Scalars['citext']>;
  personPrimaryFacePhoto?: InputMaybe<Scalars['String']>;
  personPrimaryIpAddress?: InputMaybe<Scalars['String']>;
  personPrimaryKycStatus?: InputMaybe<Scalars['String']>;
  personPrimaryLanguageId?: InputMaybe<Scalars['String']>;
  personPrimaryMobile?: InputMaybe<Scalars['String']>;
  personPrimaryPhone?: InputMaybe<Scalars['String']>;
  personPrivacyPolicy?: InputMaybe<Scalars['String']>;
  personStatus?: InputMaybe<Scalars['String']>;
  personTitleName?: InputMaybe<Scalars['String']>;
  personUniqueHash?: InputMaybe<Scalars['String']>;
  profilePhoto?: InputMaybe<Scalars['String']>;
  sigmaPrimeKindId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "sigma_prime_kind" */
export type SigmaPrimeKind = {
  __typename?: 'SigmaPrimeKind';
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "sigma_prime_kind" */
export type SigmaPrimeKindAggregate = {
  __typename?: 'SigmaPrimeKindAggregate';
  aggregate?: Maybe<SigmaPrimeKindAggregateFields>;
  nodes: Array<SigmaPrimeKind>;
};

/** aggregate fields of "sigma_prime_kind" */
export type SigmaPrimeKindAggregateFields = {
  __typename?: 'SigmaPrimeKindAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SigmaPrimeKindMaxFields>;
  min?: Maybe<SigmaPrimeKindMinFields>;
};

/** aggregate fields of "sigma_prime_kind" */
export type SigmaPrimeKindAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SigmaPrimeKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sigma_prime_kind". All fields are combined with a logical 'AND'. */
export type SigmaPrimeKindBoolExp = {
  _and?: InputMaybe<Array<SigmaPrimeKindBoolExp>>;
  _not?: InputMaybe<SigmaPrimeKindBoolExp>;
  _or?: InputMaybe<Array<SigmaPrimeKindBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "sigma_prime_kind" */
export enum SigmaPrimeKindConstraint {
  /** unique or primary key constraint on columns "id" */
  SigmaPrimeKindPkey = 'sigma_prime_kind_pkey',
}

/** input type for inserting data into table "sigma_prime_kind" */
export type SigmaPrimeKindInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SigmaPrimeKindMaxFields = {
  __typename?: 'SigmaPrimeKindMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type SigmaPrimeKindMinFields = {
  __typename?: 'SigmaPrimeKindMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "sigma_prime_kind" */
export type SigmaPrimeKindMutationResponse = {
  __typename?: 'SigmaPrimeKindMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SigmaPrimeKind>;
};

/** input type for inserting object relation for remote table "sigma_prime_kind" */
export type SigmaPrimeKindObjRelInsertInput = {
  data: SigmaPrimeKindInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<SigmaPrimeKindOnConflict>;
};

/** on_conflict condition type for table "sigma_prime_kind" */
export type SigmaPrimeKindOnConflict = {
  constraint: SigmaPrimeKindConstraint;
  updateColumns?: Array<SigmaPrimeKindUpdateColumn>;
  where?: InputMaybe<SigmaPrimeKindBoolExp>;
};

/** Ordering options when selecting data from "sigma_prime_kind". */
export type SigmaPrimeKindOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: sigma_prime_kind */
export type SigmaPrimeKindPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "sigma_prime_kind" */
export enum SigmaPrimeKindSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "sigma_prime_kind" */
export type SigmaPrimeKindSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "sigma_prime_kind" */
export type SigmaPrimeKindStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SigmaPrimeKindStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SigmaPrimeKindStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "sigma_prime_kind" */
export enum SigmaPrimeKindUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type SigmaPrimeKindUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SigmaPrimeKindSetInput>;
  /** filter the rows which have to be updated */
  where: SigmaPrimeKindBoolExp;
};

/** aggregate max on columns */
export type SigmaPrimeMaxFields = {
  __typename?: 'SigmaPrimeMaxFields';
  aboutMe?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crwdId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  personBusinessEmail?: Maybe<Scalars['citext']>;
  personExchangeRequest?: Maybe<Scalars['String']>;
  personFamilyName?: Maybe<Scalars['String']>;
  personFirstName?: Maybe<Scalars['String']>;
  personHandleName?: Maybe<Scalars['citext']>;
  personLastName?: Maybe<Scalars['String']>;
  personMiddleName?: Maybe<Scalars['String']>;
  personNameSuffix?: Maybe<Scalars['String']>;
  personParent1SigmaId?: Maybe<Scalars['uuid']>;
  personParent2SigmaId?: Maybe<Scalars['uuid']>;
  personPrimaryAmlStatus?: Maybe<Scalars['String']>;
  personPrimaryEmail?: Maybe<Scalars['citext']>;
  personPrimaryFacePhoto?: Maybe<Scalars['String']>;
  personPrimaryIpAddress?: Maybe<Scalars['String']>;
  personPrimaryKycStatus?: Maybe<Scalars['String']>;
  personPrimaryLanguageId?: Maybe<Scalars['String']>;
  personPrimaryMobile?: Maybe<Scalars['String']>;
  personPrimaryPhone?: Maybe<Scalars['String']>;
  personPrivacyPolicy?: Maybe<Scalars['String']>;
  personStatus?: Maybe<Scalars['String']>;
  personTitleName?: Maybe<Scalars['String']>;
  personUniqueHash?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  sigmaPrimeKindId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type SigmaPrimeMinFields = {
  __typename?: 'SigmaPrimeMinFields';
  aboutMe?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crwdId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  personBusinessEmail?: Maybe<Scalars['citext']>;
  personExchangeRequest?: Maybe<Scalars['String']>;
  personFamilyName?: Maybe<Scalars['String']>;
  personFirstName?: Maybe<Scalars['String']>;
  personHandleName?: Maybe<Scalars['citext']>;
  personLastName?: Maybe<Scalars['String']>;
  personMiddleName?: Maybe<Scalars['String']>;
  personNameSuffix?: Maybe<Scalars['String']>;
  personParent1SigmaId?: Maybe<Scalars['uuid']>;
  personParent2SigmaId?: Maybe<Scalars['uuid']>;
  personPrimaryAmlStatus?: Maybe<Scalars['String']>;
  personPrimaryEmail?: Maybe<Scalars['citext']>;
  personPrimaryFacePhoto?: Maybe<Scalars['String']>;
  personPrimaryIpAddress?: Maybe<Scalars['String']>;
  personPrimaryKycStatus?: Maybe<Scalars['String']>;
  personPrimaryLanguageId?: Maybe<Scalars['String']>;
  personPrimaryMobile?: Maybe<Scalars['String']>;
  personPrimaryPhone?: Maybe<Scalars['String']>;
  personPrivacyPolicy?: Maybe<Scalars['String']>;
  personStatus?: Maybe<Scalars['String']>;
  personTitleName?: Maybe<Scalars['String']>;
  personUniqueHash?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  sigmaPrimeKindId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "sigma_prime" */
export type SigmaPrimeMutationResponse = {
  __typename?: 'SigmaPrimeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SigmaPrime>;
};

/** input type for inserting object relation for remote table "sigma_prime" */
export type SigmaPrimeObjRelInsertInput = {
  data: SigmaPrimeInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<SigmaPrimeOnConflict>;
};

/** on_conflict condition type for table "sigma_prime" */
export type SigmaPrimeOnConflict = {
  constraint: SigmaPrimeConstraint;
  updateColumns?: Array<SigmaPrimeUpdateColumn>;
  where?: InputMaybe<SigmaPrimeBoolExp>;
};

/** Ordering options when selecting data from "sigma_prime". */
export type SigmaPrimeOrderBy = {
  aboutMe?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  crwdId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAccredited?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  kind?: InputMaybe<SigmaPrimeKindOrderBy>;
  personBusinessEmail?: InputMaybe<OrderBy>;
  personExchangeRequest?: InputMaybe<OrderBy>;
  personFamilyName?: InputMaybe<OrderBy>;
  personFirstName?: InputMaybe<OrderBy>;
  personHandleName?: InputMaybe<OrderBy>;
  personLastName?: InputMaybe<OrderBy>;
  personMiddleName?: InputMaybe<OrderBy>;
  personNameSuffix?: InputMaybe<OrderBy>;
  personParent1?: InputMaybe<SigmaPrimeOrderBy>;
  personParent1SigmaId?: InputMaybe<OrderBy>;
  personParent2?: InputMaybe<SigmaPrimeOrderBy>;
  personParent2SigmaId?: InputMaybe<OrderBy>;
  personPrimaryAmlStatus?: InputMaybe<OrderBy>;
  personPrimaryEmail?: InputMaybe<OrderBy>;
  personPrimaryFacePhoto?: InputMaybe<OrderBy>;
  personPrimaryIpAddress?: InputMaybe<OrderBy>;
  personPrimaryKycStatus?: InputMaybe<OrderBy>;
  personPrimaryLanguageId?: InputMaybe<OrderBy>;
  personPrimaryMobile?: InputMaybe<OrderBy>;
  personPrimaryPhone?: InputMaybe<OrderBy>;
  personPrivacyPolicy?: InputMaybe<OrderBy>;
  personStatus?: InputMaybe<OrderBy>;
  personTitleName?: InputMaybe<OrderBy>;
  personUniqueHash?: InputMaybe<OrderBy>;
  profilePhoto?: InputMaybe<OrderBy>;
  sigmaPrimeKindId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: sigma_prime */
export type SigmaPrimePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "sigma_prime" */
export enum SigmaPrimeSelectColumn {
  /** column name */
  AboutMe = 'aboutMe',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrwdId = 'crwdId',
  /** column name */
  Id = 'id',
  /** column name */
  IsAccredited = 'isAccredited',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  PersonBusinessEmail = 'personBusinessEmail',
  /** column name */
  PersonExchangeRequest = 'personExchangeRequest',
  /** column name */
  PersonFamilyName = 'personFamilyName',
  /** column name */
  PersonFirstName = 'personFirstName',
  /** column name */
  PersonHandleName = 'personHandleName',
  /** column name */
  PersonLastName = 'personLastName',
  /** column name */
  PersonMiddleName = 'personMiddleName',
  /** column name */
  PersonNameSuffix = 'personNameSuffix',
  /** column name */
  PersonParent1SigmaId = 'personParent1SigmaId',
  /** column name */
  PersonParent2SigmaId = 'personParent2SigmaId',
  /** column name */
  PersonPrimaryAmlStatus = 'personPrimaryAmlStatus',
  /** column name */
  PersonPrimaryEmail = 'personPrimaryEmail',
  /** column name */
  PersonPrimaryFacePhoto = 'personPrimaryFacePhoto',
  /** column name */
  PersonPrimaryIpAddress = 'personPrimaryIpAddress',
  /** column name */
  PersonPrimaryKycStatus = 'personPrimaryKycStatus',
  /** column name */
  PersonPrimaryLanguageId = 'personPrimaryLanguageId',
  /** column name */
  PersonPrimaryMobile = 'personPrimaryMobile',
  /** column name */
  PersonPrimaryPhone = 'personPrimaryPhone',
  /** column name */
  PersonPrivacyPolicy = 'personPrivacyPolicy',
  /** column name */
  PersonStatus = 'personStatus',
  /** column name */
  PersonTitleName = 'personTitleName',
  /** column name */
  PersonUniqueHash = 'personUniqueHash',
  /** column name */
  ProfilePhoto = 'profilePhoto',
  /** column name */
  SigmaPrimeKindId = 'sigmaPrimeKindId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "sigma_prime" */
export type SigmaPrimeSetInput = {
  aboutMe?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isAccredited?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  personBusinessEmail?: InputMaybe<Scalars['citext']>;
  personExchangeRequest?: InputMaybe<Scalars['String']>;
  personFamilyName?: InputMaybe<Scalars['String']>;
  personFirstName?: InputMaybe<Scalars['String']>;
  personHandleName?: InputMaybe<Scalars['citext']>;
  personLastName?: InputMaybe<Scalars['String']>;
  personMiddleName?: InputMaybe<Scalars['String']>;
  personNameSuffix?: InputMaybe<Scalars['String']>;
  personParent1SigmaId?: InputMaybe<Scalars['uuid']>;
  personParent2SigmaId?: InputMaybe<Scalars['uuid']>;
  personPrimaryAmlStatus?: InputMaybe<Scalars['String']>;
  personPrimaryEmail?: InputMaybe<Scalars['citext']>;
  personPrimaryFacePhoto?: InputMaybe<Scalars['String']>;
  personPrimaryIpAddress?: InputMaybe<Scalars['String']>;
  personPrimaryKycStatus?: InputMaybe<Scalars['String']>;
  personPrimaryLanguageId?: InputMaybe<Scalars['String']>;
  personPrimaryMobile?: InputMaybe<Scalars['String']>;
  personPrimaryPhone?: InputMaybe<Scalars['String']>;
  personPrivacyPolicy?: InputMaybe<Scalars['String']>;
  personStatus?: InputMaybe<Scalars['String']>;
  personTitleName?: InputMaybe<Scalars['String']>;
  personUniqueHash?: InputMaybe<Scalars['String']>;
  profilePhoto?: InputMaybe<Scalars['String']>;
  sigmaPrimeKindId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "sigma_prime" */
export type SigmaPrimeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SigmaPrimeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SigmaPrimeStreamCursorValueInput = {
  aboutMe?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isAccredited?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  personBusinessEmail?: InputMaybe<Scalars['citext']>;
  personExchangeRequest?: InputMaybe<Scalars['String']>;
  personFamilyName?: InputMaybe<Scalars['String']>;
  personFirstName?: InputMaybe<Scalars['String']>;
  personHandleName?: InputMaybe<Scalars['citext']>;
  personLastName?: InputMaybe<Scalars['String']>;
  personMiddleName?: InputMaybe<Scalars['String']>;
  personNameSuffix?: InputMaybe<Scalars['String']>;
  personParent1SigmaId?: InputMaybe<Scalars['uuid']>;
  personParent2SigmaId?: InputMaybe<Scalars['uuid']>;
  personPrimaryAmlStatus?: InputMaybe<Scalars['String']>;
  personPrimaryEmail?: InputMaybe<Scalars['citext']>;
  personPrimaryFacePhoto?: InputMaybe<Scalars['String']>;
  personPrimaryIpAddress?: InputMaybe<Scalars['String']>;
  personPrimaryKycStatus?: InputMaybe<Scalars['String']>;
  personPrimaryLanguageId?: InputMaybe<Scalars['String']>;
  personPrimaryMobile?: InputMaybe<Scalars['String']>;
  personPrimaryPhone?: InputMaybe<Scalars['String']>;
  personPrivacyPolicy?: InputMaybe<Scalars['String']>;
  personStatus?: InputMaybe<Scalars['String']>;
  personTitleName?: InputMaybe<Scalars['String']>;
  personUniqueHash?: InputMaybe<Scalars['String']>;
  profilePhoto?: InputMaybe<Scalars['String']>;
  sigmaPrimeKindId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "sigma_prime" */
export enum SigmaPrimeUpdateColumn {
  /** column name */
  AboutMe = 'aboutMe',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrwdId = 'crwdId',
  /** column name */
  Id = 'id',
  /** column name */
  IsAccredited = 'isAccredited',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  PersonBusinessEmail = 'personBusinessEmail',
  /** column name */
  PersonExchangeRequest = 'personExchangeRequest',
  /** column name */
  PersonFamilyName = 'personFamilyName',
  /** column name */
  PersonFirstName = 'personFirstName',
  /** column name */
  PersonHandleName = 'personHandleName',
  /** column name */
  PersonLastName = 'personLastName',
  /** column name */
  PersonMiddleName = 'personMiddleName',
  /** column name */
  PersonNameSuffix = 'personNameSuffix',
  /** column name */
  PersonParent1SigmaId = 'personParent1SigmaId',
  /** column name */
  PersonParent2SigmaId = 'personParent2SigmaId',
  /** column name */
  PersonPrimaryAmlStatus = 'personPrimaryAmlStatus',
  /** column name */
  PersonPrimaryEmail = 'personPrimaryEmail',
  /** column name */
  PersonPrimaryFacePhoto = 'personPrimaryFacePhoto',
  /** column name */
  PersonPrimaryIpAddress = 'personPrimaryIpAddress',
  /** column name */
  PersonPrimaryKycStatus = 'personPrimaryKycStatus',
  /** column name */
  PersonPrimaryLanguageId = 'personPrimaryLanguageId',
  /** column name */
  PersonPrimaryMobile = 'personPrimaryMobile',
  /** column name */
  PersonPrimaryPhone = 'personPrimaryPhone',
  /** column name */
  PersonPrivacyPolicy = 'personPrivacyPolicy',
  /** column name */
  PersonStatus = 'personStatus',
  /** column name */
  PersonTitleName = 'personTitleName',
  /** column name */
  PersonUniqueHash = 'personUniqueHash',
  /** column name */
  ProfilePhoto = 'profilePhoto',
  /** column name */
  SigmaPrimeKindId = 'sigmaPrimeKindId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type SigmaPrimeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SigmaPrimeSetInput>;
  /** filter the rows which have to be updated */
  where: SigmaPrimeBoolExp;
};

/** columns and relationships of "sigma_social" */
export type SigmaSocial = {
  __typename?: 'SigmaSocial';
  biographyUrl: Scalars['String'];
  booklistUrl: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  crwdid?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  myesbriggsUrl: Scalars['String'];
  organizationId: Scalars['uuid'];
  sigmaPrimeId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  videoUrl: Scalars['String'];
  weblistUrl: Scalars['String'];
};

/** aggregated selection of "sigma_social" */
export type SigmaSocialAggregate = {
  __typename?: 'SigmaSocialAggregate';
  aggregate?: Maybe<SigmaSocialAggregateFields>;
  nodes: Array<SigmaSocial>;
};

/** aggregate fields of "sigma_social" */
export type SigmaSocialAggregateFields = {
  __typename?: 'SigmaSocialAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SigmaSocialMaxFields>;
  min?: Maybe<SigmaSocialMinFields>;
};

/** aggregate fields of "sigma_social" */
export type SigmaSocialAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SigmaSocialSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sigma_social". All fields are combined with a logical 'AND'. */
export type SigmaSocialBoolExp = {
  _and?: InputMaybe<Array<SigmaSocialBoolExp>>;
  _not?: InputMaybe<SigmaSocialBoolExp>;
  _or?: InputMaybe<Array<SigmaSocialBoolExp>>;
  biographyUrl?: InputMaybe<StringComparisonExp>;
  booklistUrl?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  crwdid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  myesbriggsUrl?: InputMaybe<StringComparisonExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  sigmaPrimeId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  videoUrl?: InputMaybe<StringComparisonExp>;
  weblistUrl?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "sigma_social" */
export enum SigmaSocialConstraint {
  /** unique or primary key constraint on columns "id" */
  SigmaSocialPkey = 'sigma_social_pkey',
}

/** input type for inserting data into table "sigma_social" */
export type SigmaSocialInsertInput = {
  biographyUrl?: InputMaybe<Scalars['String']>;
  booklistUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  myesbriggsUrl?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  videoUrl?: InputMaybe<Scalars['String']>;
  weblistUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SigmaSocialMaxFields = {
  __typename?: 'SigmaSocialMaxFields';
  biographyUrl?: Maybe<Scalars['String']>;
  booklistUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crwdid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  myesbriggsUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  videoUrl?: Maybe<Scalars['String']>;
  weblistUrl?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SigmaSocialMinFields = {
  __typename?: 'SigmaSocialMinFields';
  biographyUrl?: Maybe<Scalars['String']>;
  booklistUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crwdid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  myesbriggsUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  videoUrl?: Maybe<Scalars['String']>;
  weblistUrl?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "sigma_social" */
export type SigmaSocialMutationResponse = {
  __typename?: 'SigmaSocialMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SigmaSocial>;
};

/** on_conflict condition type for table "sigma_social" */
export type SigmaSocialOnConflict = {
  constraint: SigmaSocialConstraint;
  updateColumns?: Array<SigmaSocialUpdateColumn>;
  where?: InputMaybe<SigmaSocialBoolExp>;
};

/** Ordering options when selecting data from "sigma_social". */
export type SigmaSocialOrderBy = {
  biographyUrl?: InputMaybe<OrderBy>;
  booklistUrl?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  crwdid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  myesbriggsUrl?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  sigmaPrimeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  videoUrl?: InputMaybe<OrderBy>;
  weblistUrl?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: sigma_social */
export type SigmaSocialPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "sigma_social" */
export enum SigmaSocialSelectColumn {
  /** column name */
  BiographyUrl = 'biographyUrl',
  /** column name */
  BooklistUrl = 'booklistUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Crwdid = 'crwdid',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MyesbriggsUrl = 'myesbriggsUrl',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SigmaPrimeId = 'sigmaPrimeId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VideoUrl = 'videoUrl',
  /** column name */
  WeblistUrl = 'weblistUrl',
}

/** input type for updating data in table "sigma_social" */
export type SigmaSocialSetInput = {
  biographyUrl?: InputMaybe<Scalars['String']>;
  booklistUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  myesbriggsUrl?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  videoUrl?: InputMaybe<Scalars['String']>;
  weblistUrl?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "sigma_social" */
export type SigmaSocialStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SigmaSocialStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SigmaSocialStreamCursorValueInput = {
  biographyUrl?: InputMaybe<Scalars['String']>;
  booklistUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  myesbriggsUrl?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  videoUrl?: InputMaybe<Scalars['String']>;
  weblistUrl?: InputMaybe<Scalars['String']>;
};

/** update columns of table "sigma_social" */
export enum SigmaSocialUpdateColumn {
  /** column name */
  BiographyUrl = 'biographyUrl',
  /** column name */
  BooklistUrl = 'booklistUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Crwdid = 'crwdid',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  MyesbriggsUrl = 'myesbriggsUrl',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SigmaPrimeId = 'sigmaPrimeId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VideoUrl = 'videoUrl',
  /** column name */
  WeblistUrl = 'weblistUrl',
}

export type SigmaSocialUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SigmaSocialSetInput>;
  /** filter the rows which have to be updated */
  where: SigmaSocialBoolExp;
};

/** columns and relationships of "smart_contract" */
export type SmartContract = {
  __typename?: 'SmartContract';
  /** An array relationship */
  appraisals: Array<Appraisal>;
  /** An aggregate relationship */
  appraisalsAggregate: AppraisalAggregate;
  /** An object relationship */
  clearinghouse: Clearinghouse;
  clearinghouseId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  kind: SmartContractKind;
  name: Scalars['String'];
  /** An array relationship */
  puts: Array<Put>;
  /** An aggregate relationship */
  putsAggregate: PutAggregate;
  smartContractDocumentId?: Maybe<Scalars['uuid']>;
  smartContractKindId: Scalars['uuid'];
  terms: Scalars['String'];
  /** An array relationship */
  transactionKinds: Array<TransactionKind>;
  /** An aggregate relationship */
  transactionKindsAggregate: TransactionKindAggregate;
  /** An array relationship */
  transactions: Array<Transaction>;
  /** An aggregate relationship */
  transactionsAggregate: TransactionAggregate;
  updatedAt: Scalars['timestamptz'];
  useCount: Scalars['Int'];
};

/** columns and relationships of "smart_contract" */
export type SmartContractAppraisalsArgs = {
  distinctOn?: InputMaybe<Array<AppraisalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppraisalOrderBy>>;
  where?: InputMaybe<AppraisalBoolExp>;
};

/** columns and relationships of "smart_contract" */
export type SmartContractAppraisalsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AppraisalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppraisalOrderBy>>;
  where?: InputMaybe<AppraisalBoolExp>;
};

/** columns and relationships of "smart_contract" */
export type SmartContractPutsArgs = {
  distinctOn?: InputMaybe<Array<PutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PutOrderBy>>;
  where?: InputMaybe<PutBoolExp>;
};

/** columns and relationships of "smart_contract" */
export type SmartContractPutsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PutOrderBy>>;
  where?: InputMaybe<PutBoolExp>;
};

/** columns and relationships of "smart_contract" */
export type SmartContractTransactionKindsArgs = {
  distinctOn?: InputMaybe<Array<TransactionKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionKindOrderBy>>;
  where?: InputMaybe<TransactionKindBoolExp>;
};

/** columns and relationships of "smart_contract" */
export type SmartContractTransactionKindsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionKindOrderBy>>;
  where?: InputMaybe<TransactionKindBoolExp>;
};

/** columns and relationships of "smart_contract" */
export type SmartContractTransactionsArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

/** columns and relationships of "smart_contract" */
export type SmartContractTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

/** aggregated selection of "smart_contract" */
export type SmartContractAggregate = {
  __typename?: 'SmartContractAggregate';
  aggregate?: Maybe<SmartContractAggregateFields>;
  nodes: Array<SmartContract>;
};

export type SmartContractAggregateBoolExp = {
  bool_and?: InputMaybe<SmartContractAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<SmartContractAggregateBoolExpBool_Or>;
  count?: InputMaybe<SmartContractAggregateBoolExpCount>;
};

/** aggregate fields of "smart_contract" */
export type SmartContractAggregateFields = {
  __typename?: 'SmartContractAggregateFields';
  avg?: Maybe<SmartContractAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SmartContractMaxFields>;
  min?: Maybe<SmartContractMinFields>;
  stddev?: Maybe<SmartContractStddevFields>;
  stddevPop?: Maybe<SmartContractStddevPopFields>;
  stddevSamp?: Maybe<SmartContractStddevSampFields>;
  sum?: Maybe<SmartContractSumFields>;
  varPop?: Maybe<SmartContractVarPopFields>;
  varSamp?: Maybe<SmartContractVarSampFields>;
  variance?: Maybe<SmartContractVarianceFields>;
};

/** aggregate fields of "smart_contract" */
export type SmartContractAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SmartContractSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "smart_contract" */
export type SmartContractAggregateOrderBy = {
  avg?: InputMaybe<SmartContractAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SmartContractMaxOrderBy>;
  min?: InputMaybe<SmartContractMinOrderBy>;
  stddev?: InputMaybe<SmartContractStddevOrderBy>;
  stddevPop?: InputMaybe<SmartContractStddevPopOrderBy>;
  stddevSamp?: InputMaybe<SmartContractStddevSampOrderBy>;
  sum?: InputMaybe<SmartContractSumOrderBy>;
  varPop?: InputMaybe<SmartContractVarPopOrderBy>;
  varSamp?: InputMaybe<SmartContractVarSampOrderBy>;
  variance?: InputMaybe<SmartContractVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "smart_contract" */
export type SmartContractArrRelInsertInput = {
  data: Array<SmartContractInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<SmartContractOnConflict>;
};

/** aggregate avg on columns */
export type SmartContractAvgFields = {
  __typename?: 'SmartContractAvgFields';
  useCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "smart_contract" */
export type SmartContractAvgOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "smart_contract". All fields are combined with a logical 'AND'. */
export type SmartContractBoolExp = {
  _and?: InputMaybe<Array<SmartContractBoolExp>>;
  _not?: InputMaybe<SmartContractBoolExp>;
  _or?: InputMaybe<Array<SmartContractBoolExp>>;
  appraisals?: InputMaybe<AppraisalBoolExp>;
  appraisalsAggregate?: InputMaybe<AppraisalAggregateBoolExp>;
  clearinghouse?: InputMaybe<ClearinghouseBoolExp>;
  clearinghouseId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  kind?: InputMaybe<SmartContractKindBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  puts?: InputMaybe<PutBoolExp>;
  putsAggregate?: InputMaybe<PutAggregateBoolExp>;
  smartContractDocumentId?: InputMaybe<UuidComparisonExp>;
  smartContractKindId?: InputMaybe<UuidComparisonExp>;
  terms?: InputMaybe<StringComparisonExp>;
  transactionKinds?: InputMaybe<TransactionKindBoolExp>;
  transactionKindsAggregate?: InputMaybe<TransactionKindAggregateBoolExp>;
  transactions?: InputMaybe<TransactionBoolExp>;
  transactionsAggregate?: InputMaybe<TransactionAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  useCount?: InputMaybe<IntComparisonExp>;
};

/** columns and relationships of "smart_contract_commission" */
export type SmartContractCommission = {
  __typename?: 'SmartContractCommission';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  product?: Maybe<Product>;
  productId: Scalars['uuid'];
  /** fetch data from the table: "smart_contract_lineage" */
  smartContractLineage: Array<SmartContractLineage>;
  /** fetch aggregated fields from the table: "smart_contract_lineage" */
  smartContractLineageAggregate: SmartContractLineageAggregate;
  smartContractLineageId: Scalars['uuid'];
  tier1: Scalars['bigint'];
  tier2: Scalars['bigint'];
  tier3: Scalars['bigint'];
  tier4: Scalars['bigint'];
  tier5: Scalars['bigint'];
  tier6: Scalars['bigint'];
  tier7: Scalars['bigint'];
  tier8: Scalars['bigint'];
  tier9: Scalars['bigint'];
  tier10: Scalars['bigint'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "smart_contract_commission" */
export type SmartContractCommissionSmartContractLineageArgs = {
  distinctOn?: InputMaybe<Array<SmartContractLineageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractLineageOrderBy>>;
  where?: InputMaybe<SmartContractLineageBoolExp>;
};

/** columns and relationships of "smart_contract_commission" */
export type SmartContractCommissionSmartContractLineageAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmartContractLineageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractLineageOrderBy>>;
  where?: InputMaybe<SmartContractLineageBoolExp>;
};

/** aggregated selection of "smart_contract_commission" */
export type SmartContractCommissionAggregate = {
  __typename?: 'SmartContractCommissionAggregate';
  aggregate?: Maybe<SmartContractCommissionAggregateFields>;
  nodes: Array<SmartContractCommission>;
};

/** aggregate fields of "smart_contract_commission" */
export type SmartContractCommissionAggregateFields = {
  __typename?: 'SmartContractCommissionAggregateFields';
  avg?: Maybe<SmartContractCommissionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SmartContractCommissionMaxFields>;
  min?: Maybe<SmartContractCommissionMinFields>;
  stddev?: Maybe<SmartContractCommissionStddevFields>;
  stddevPop?: Maybe<SmartContractCommissionStddevPopFields>;
  stddevSamp?: Maybe<SmartContractCommissionStddevSampFields>;
  sum?: Maybe<SmartContractCommissionSumFields>;
  varPop?: Maybe<SmartContractCommissionVarPopFields>;
  varSamp?: Maybe<SmartContractCommissionVarSampFields>;
  variance?: Maybe<SmartContractCommissionVarianceFields>;
};

/** aggregate fields of "smart_contract_commission" */
export type SmartContractCommissionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SmartContractCommissionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SmartContractCommissionAvgFields = {
  __typename?: 'SmartContractCommissionAvgFields';
  tier1?: Maybe<Scalars['Float']>;
  tier2?: Maybe<Scalars['Float']>;
  tier3?: Maybe<Scalars['Float']>;
  tier4?: Maybe<Scalars['Float']>;
  tier5?: Maybe<Scalars['Float']>;
  tier6?: Maybe<Scalars['Float']>;
  tier7?: Maybe<Scalars['Float']>;
  tier8?: Maybe<Scalars['Float']>;
  tier9?: Maybe<Scalars['Float']>;
  tier10?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "smart_contract_commission". All fields are combined with a logical 'AND'. */
export type SmartContractCommissionBoolExp = {
  _and?: InputMaybe<Array<SmartContractCommissionBoolExp>>;
  _not?: InputMaybe<SmartContractCommissionBoolExp>;
  _or?: InputMaybe<Array<SmartContractCommissionBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  product?: InputMaybe<ProductBoolExp>;
  productId?: InputMaybe<UuidComparisonExp>;
  smartContractLineage?: InputMaybe<SmartContractLineageBoolExp>;
  smartContractLineageAggregate?: InputMaybe<SmartContractLineageAggregateBoolExp>;
  smartContractLineageId?: InputMaybe<UuidComparisonExp>;
  tier1?: InputMaybe<BigintComparisonExp>;
  tier2?: InputMaybe<BigintComparisonExp>;
  tier3?: InputMaybe<BigintComparisonExp>;
  tier4?: InputMaybe<BigintComparisonExp>;
  tier5?: InputMaybe<BigintComparisonExp>;
  tier6?: InputMaybe<BigintComparisonExp>;
  tier7?: InputMaybe<BigintComparisonExp>;
  tier8?: InputMaybe<BigintComparisonExp>;
  tier9?: InputMaybe<BigintComparisonExp>;
  tier10?: InputMaybe<BigintComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "smart_contract_commission" */
export enum SmartContractCommissionConstraint {
  /** unique or primary key constraint on columns "id" */
  SmartContractCommissionPkey = 'smart_contract_commission_pkey',
}

/** input type for incrementing numeric columns in table "smart_contract_commission" */
export type SmartContractCommissionIncInput = {
  tier1?: InputMaybe<Scalars['bigint']>;
  tier2?: InputMaybe<Scalars['bigint']>;
  tier3?: InputMaybe<Scalars['bigint']>;
  tier4?: InputMaybe<Scalars['bigint']>;
  tier5?: InputMaybe<Scalars['bigint']>;
  tier6?: InputMaybe<Scalars['bigint']>;
  tier7?: InputMaybe<Scalars['bigint']>;
  tier8?: InputMaybe<Scalars['bigint']>;
  tier9?: InputMaybe<Scalars['bigint']>;
  tier10?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "smart_contract_commission" */
export type SmartContractCommissionInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  product?: InputMaybe<ProductObjRelInsertInput>;
  productId?: InputMaybe<Scalars['uuid']>;
  smartContractLineage?: InputMaybe<SmartContractLineageArrRelInsertInput>;
  smartContractLineageId?: InputMaybe<Scalars['uuid']>;
  tier1?: InputMaybe<Scalars['bigint']>;
  tier2?: InputMaybe<Scalars['bigint']>;
  tier3?: InputMaybe<Scalars['bigint']>;
  tier4?: InputMaybe<Scalars['bigint']>;
  tier5?: InputMaybe<Scalars['bigint']>;
  tier6?: InputMaybe<Scalars['bigint']>;
  tier7?: InputMaybe<Scalars['bigint']>;
  tier8?: InputMaybe<Scalars['bigint']>;
  tier9?: InputMaybe<Scalars['bigint']>;
  tier10?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SmartContractCommissionMaxFields = {
  __typename?: 'SmartContractCommissionMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  smartContractLineageId?: Maybe<Scalars['uuid']>;
  tier1?: Maybe<Scalars['bigint']>;
  tier2?: Maybe<Scalars['bigint']>;
  tier3?: Maybe<Scalars['bigint']>;
  tier4?: Maybe<Scalars['bigint']>;
  tier5?: Maybe<Scalars['bigint']>;
  tier6?: Maybe<Scalars['bigint']>;
  tier7?: Maybe<Scalars['bigint']>;
  tier8?: Maybe<Scalars['bigint']>;
  tier9?: Maybe<Scalars['bigint']>;
  tier10?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type SmartContractCommissionMinFields = {
  __typename?: 'SmartContractCommissionMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  smartContractLineageId?: Maybe<Scalars['uuid']>;
  tier1?: Maybe<Scalars['bigint']>;
  tier2?: Maybe<Scalars['bigint']>;
  tier3?: Maybe<Scalars['bigint']>;
  tier4?: Maybe<Scalars['bigint']>;
  tier5?: Maybe<Scalars['bigint']>;
  tier6?: Maybe<Scalars['bigint']>;
  tier7?: Maybe<Scalars['bigint']>;
  tier8?: Maybe<Scalars['bigint']>;
  tier9?: Maybe<Scalars['bigint']>;
  tier10?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "smart_contract_commission" */
export type SmartContractCommissionMutationResponse = {
  __typename?: 'SmartContractCommissionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SmartContractCommission>;
};

/** on_conflict condition type for table "smart_contract_commission" */
export type SmartContractCommissionOnConflict = {
  constraint: SmartContractCommissionConstraint;
  updateColumns?: Array<SmartContractCommissionUpdateColumn>;
  where?: InputMaybe<SmartContractCommissionBoolExp>;
};

/** Ordering options when selecting data from "smart_contract_commission". */
export type SmartContractCommissionOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  product?: InputMaybe<ProductOrderBy>;
  productId?: InputMaybe<OrderBy>;
  smartContractLineageAggregate?: InputMaybe<SmartContractLineageAggregateOrderBy>;
  smartContractLineageId?: InputMaybe<OrderBy>;
  tier1?: InputMaybe<OrderBy>;
  tier2?: InputMaybe<OrderBy>;
  tier3?: InputMaybe<OrderBy>;
  tier4?: InputMaybe<OrderBy>;
  tier5?: InputMaybe<OrderBy>;
  tier6?: InputMaybe<OrderBy>;
  tier7?: InputMaybe<OrderBy>;
  tier8?: InputMaybe<OrderBy>;
  tier9?: InputMaybe<OrderBy>;
  tier10?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: smart_contract_commission */
export type SmartContractCommissionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "smart_contract_commission" */
export enum SmartContractCommissionSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SmartContractLineageId = 'smartContractLineageId',
  /** column name */
  Tier1 = 'tier1',
  /** column name */
  Tier2 = 'tier2',
  /** column name */
  Tier3 = 'tier3',
  /** column name */
  Tier4 = 'tier4',
  /** column name */
  Tier5 = 'tier5',
  /** column name */
  Tier6 = 'tier6',
  /** column name */
  Tier7 = 'tier7',
  /** column name */
  Tier8 = 'tier8',
  /** column name */
  Tier9 = 'tier9',
  /** column name */
  Tier10 = 'tier10',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "smart_contract_commission" */
export type SmartContractCommissionSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['uuid']>;
  smartContractLineageId?: InputMaybe<Scalars['uuid']>;
  tier1?: InputMaybe<Scalars['bigint']>;
  tier2?: InputMaybe<Scalars['bigint']>;
  tier3?: InputMaybe<Scalars['bigint']>;
  tier4?: InputMaybe<Scalars['bigint']>;
  tier5?: InputMaybe<Scalars['bigint']>;
  tier6?: InputMaybe<Scalars['bigint']>;
  tier7?: InputMaybe<Scalars['bigint']>;
  tier8?: InputMaybe<Scalars['bigint']>;
  tier9?: InputMaybe<Scalars['bigint']>;
  tier10?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type SmartContractCommissionStddevFields = {
  __typename?: 'SmartContractCommissionStddevFields';
  tier1?: Maybe<Scalars['Float']>;
  tier2?: Maybe<Scalars['Float']>;
  tier3?: Maybe<Scalars['Float']>;
  tier4?: Maybe<Scalars['Float']>;
  tier5?: Maybe<Scalars['Float']>;
  tier6?: Maybe<Scalars['Float']>;
  tier7?: Maybe<Scalars['Float']>;
  tier8?: Maybe<Scalars['Float']>;
  tier9?: Maybe<Scalars['Float']>;
  tier10?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type SmartContractCommissionStddevPopFields = {
  __typename?: 'SmartContractCommissionStddevPopFields';
  tier1?: Maybe<Scalars['Float']>;
  tier2?: Maybe<Scalars['Float']>;
  tier3?: Maybe<Scalars['Float']>;
  tier4?: Maybe<Scalars['Float']>;
  tier5?: Maybe<Scalars['Float']>;
  tier6?: Maybe<Scalars['Float']>;
  tier7?: Maybe<Scalars['Float']>;
  tier8?: Maybe<Scalars['Float']>;
  tier9?: Maybe<Scalars['Float']>;
  tier10?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type SmartContractCommissionStddevSampFields = {
  __typename?: 'SmartContractCommissionStddevSampFields';
  tier1?: Maybe<Scalars['Float']>;
  tier2?: Maybe<Scalars['Float']>;
  tier3?: Maybe<Scalars['Float']>;
  tier4?: Maybe<Scalars['Float']>;
  tier5?: Maybe<Scalars['Float']>;
  tier6?: Maybe<Scalars['Float']>;
  tier7?: Maybe<Scalars['Float']>;
  tier8?: Maybe<Scalars['Float']>;
  tier9?: Maybe<Scalars['Float']>;
  tier10?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "smart_contract_commission" */
export type SmartContractCommissionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SmartContractCommissionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SmartContractCommissionStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['uuid']>;
  smartContractLineageId?: InputMaybe<Scalars['uuid']>;
  tier1?: InputMaybe<Scalars['bigint']>;
  tier2?: InputMaybe<Scalars['bigint']>;
  tier3?: InputMaybe<Scalars['bigint']>;
  tier4?: InputMaybe<Scalars['bigint']>;
  tier5?: InputMaybe<Scalars['bigint']>;
  tier6?: InputMaybe<Scalars['bigint']>;
  tier7?: InputMaybe<Scalars['bigint']>;
  tier8?: InputMaybe<Scalars['bigint']>;
  tier9?: InputMaybe<Scalars['bigint']>;
  tier10?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type SmartContractCommissionSumFields = {
  __typename?: 'SmartContractCommissionSumFields';
  tier1?: Maybe<Scalars['bigint']>;
  tier2?: Maybe<Scalars['bigint']>;
  tier3?: Maybe<Scalars['bigint']>;
  tier4?: Maybe<Scalars['bigint']>;
  tier5?: Maybe<Scalars['bigint']>;
  tier6?: Maybe<Scalars['bigint']>;
  tier7?: Maybe<Scalars['bigint']>;
  tier8?: Maybe<Scalars['bigint']>;
  tier9?: Maybe<Scalars['bigint']>;
  tier10?: Maybe<Scalars['bigint']>;
};

/** update columns of table "smart_contract_commission" */
export enum SmartContractCommissionUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SmartContractLineageId = 'smartContractLineageId',
  /** column name */
  Tier1 = 'tier1',
  /** column name */
  Tier2 = 'tier2',
  /** column name */
  Tier3 = 'tier3',
  /** column name */
  Tier4 = 'tier4',
  /** column name */
  Tier5 = 'tier5',
  /** column name */
  Tier6 = 'tier6',
  /** column name */
  Tier7 = 'tier7',
  /** column name */
  Tier8 = 'tier8',
  /** column name */
  Tier9 = 'tier9',
  /** column name */
  Tier10 = 'tier10',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type SmartContractCommissionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SmartContractCommissionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SmartContractCommissionSetInput>;
  /** filter the rows which have to be updated */
  where: SmartContractCommissionBoolExp;
};

/** aggregate varPop on columns */
export type SmartContractCommissionVarPopFields = {
  __typename?: 'SmartContractCommissionVarPopFields';
  tier1?: Maybe<Scalars['Float']>;
  tier2?: Maybe<Scalars['Float']>;
  tier3?: Maybe<Scalars['Float']>;
  tier4?: Maybe<Scalars['Float']>;
  tier5?: Maybe<Scalars['Float']>;
  tier6?: Maybe<Scalars['Float']>;
  tier7?: Maybe<Scalars['Float']>;
  tier8?: Maybe<Scalars['Float']>;
  tier9?: Maybe<Scalars['Float']>;
  tier10?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type SmartContractCommissionVarSampFields = {
  __typename?: 'SmartContractCommissionVarSampFields';
  tier1?: Maybe<Scalars['Float']>;
  tier2?: Maybe<Scalars['Float']>;
  tier3?: Maybe<Scalars['Float']>;
  tier4?: Maybe<Scalars['Float']>;
  tier5?: Maybe<Scalars['Float']>;
  tier6?: Maybe<Scalars['Float']>;
  tier7?: Maybe<Scalars['Float']>;
  tier8?: Maybe<Scalars['Float']>;
  tier9?: Maybe<Scalars['Float']>;
  tier10?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SmartContractCommissionVarianceFields = {
  __typename?: 'SmartContractCommissionVarianceFields';
  tier1?: Maybe<Scalars['Float']>;
  tier2?: Maybe<Scalars['Float']>;
  tier3?: Maybe<Scalars['Float']>;
  tier4?: Maybe<Scalars['Float']>;
  tier5?: Maybe<Scalars['Float']>;
  tier6?: Maybe<Scalars['Float']>;
  tier7?: Maybe<Scalars['Float']>;
  tier8?: Maybe<Scalars['Float']>;
  tier9?: Maybe<Scalars['Float']>;
  tier10?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "smart_contract" */
export enum SmartContractConstraint {
  /** unique or primary key constraint on columns "id" */
  SmartContractPkey = 'smart_contract_pkey',
  /** unique or primary key constraint on columns "id" */
  SmartContractXidKey = 'smart_contract_xid_key',
}

/** columns and relationships of "smart_contract_document" */
export type SmartContractDocument = {
  __typename?: 'SmartContractDocument';
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  datasource?: Maybe<Datasource>;
  datasourceId?: Maybe<Scalars['uuid']>;
  documentSource?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "smart_contract_document" */
export type SmartContractDocumentAggregate = {
  __typename?: 'SmartContractDocumentAggregate';
  aggregate?: Maybe<SmartContractDocumentAggregateFields>;
  nodes: Array<SmartContractDocument>;
};

/** aggregate fields of "smart_contract_document" */
export type SmartContractDocumentAggregateFields = {
  __typename?: 'SmartContractDocumentAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SmartContractDocumentMaxFields>;
  min?: Maybe<SmartContractDocumentMinFields>;
};

/** aggregate fields of "smart_contract_document" */
export type SmartContractDocumentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SmartContractDocumentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "smart_contract_document". All fields are combined with a logical 'AND'. */
export type SmartContractDocumentBoolExp = {
  _and?: InputMaybe<Array<SmartContractDocumentBoolExp>>;
  _not?: InputMaybe<SmartContractDocumentBoolExp>;
  _or?: InputMaybe<Array<SmartContractDocumentBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  datasource?: InputMaybe<DatasourceBoolExp>;
  datasourceId?: InputMaybe<UuidComparisonExp>;
  documentSource?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "smart_contract_document" */
export enum SmartContractDocumentConstraint {
  /** unique or primary key constraint on columns "id" */
  SmartContractDocumentPkey = 'smart_contract_document_pkey',
}

/** input type for inserting data into table "smart_contract_document" */
export type SmartContractDocumentInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  datasource?: InputMaybe<DatasourceObjRelInsertInput>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  documentSource?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SmartContractDocumentMaxFields = {
  __typename?: 'SmartContractDocumentMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  datasourceId?: Maybe<Scalars['uuid']>;
  documentSource?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type SmartContractDocumentMinFields = {
  __typename?: 'SmartContractDocumentMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  datasourceId?: Maybe<Scalars['uuid']>;
  documentSource?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "smart_contract_document" */
export type SmartContractDocumentMutationResponse = {
  __typename?: 'SmartContractDocumentMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SmartContractDocument>;
};

/** input type for inserting object relation for remote table "smart_contract_document" */
export type SmartContractDocumentObjRelInsertInput = {
  data: SmartContractDocumentInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<SmartContractDocumentOnConflict>;
};

/** on_conflict condition type for table "smart_contract_document" */
export type SmartContractDocumentOnConflict = {
  constraint: SmartContractDocumentConstraint;
  updateColumns?: Array<SmartContractDocumentUpdateColumn>;
  where?: InputMaybe<SmartContractDocumentBoolExp>;
};

/** Ordering options when selecting data from "smart_contract_document". */
export type SmartContractDocumentOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  datasource?: InputMaybe<DatasourceOrderBy>;
  datasourceId?: InputMaybe<OrderBy>;
  documentSource?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: smart_contract_document */
export type SmartContractDocumentPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "smart_contract_document" */
export enum SmartContractDocumentSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatasourceId = 'datasourceId',
  /** column name */
  DocumentSource = 'documentSource',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "smart_contract_document" */
export type SmartContractDocumentSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  documentSource?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "smart_contract_document" */
export type SmartContractDocumentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SmartContractDocumentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SmartContractDocumentStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  datasourceId?: InputMaybe<Scalars['uuid']>;
  documentSource?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "smart_contract_document" */
export enum SmartContractDocumentUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DatasourceId = 'datasourceId',
  /** column name */
  DocumentSource = 'documentSource',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type SmartContractDocumentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SmartContractDocumentSetInput>;
  /** filter the rows which have to be updated */
  where: SmartContractDocumentBoolExp;
};

/** input type for incrementing numeric columns in table "smart_contract" */
export type SmartContractIncInput = {
  useCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "smart_contract" */
export type SmartContractInsertInput = {
  appraisals?: InputMaybe<AppraisalArrRelInsertInput>;
  clearinghouse?: InputMaybe<ClearinghouseObjRelInsertInput>;
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<SmartContractKindObjRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  puts?: InputMaybe<PutArrRelInsertInput>;
  smartContractDocumentId?: InputMaybe<Scalars['uuid']>;
  smartContractKindId?: InputMaybe<Scalars['uuid']>;
  terms?: InputMaybe<Scalars['String']>;
  transactionKinds?: InputMaybe<TransactionKindArrRelInsertInput>;
  transactions?: InputMaybe<TransactionArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  useCount?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "smart_contract_kind" */
export type SmartContractKind = {
  __typename?: 'SmartContractKind';
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An array relationship */
  smartContracts: Array<SmartContract>;
  /** An aggregate relationship */
  smartContractsAggregate: SmartContractAggregate;
  /** An object relationship */
  subindustry: Subindustry;
  subindustryId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "smart_contract_kind" */
export type SmartContractKindSmartContractsArgs = {
  distinctOn?: InputMaybe<Array<SmartContractSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractOrderBy>>;
  where?: InputMaybe<SmartContractBoolExp>;
};

/** columns and relationships of "smart_contract_kind" */
export type SmartContractKindSmartContractsAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmartContractSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractOrderBy>>;
  where?: InputMaybe<SmartContractBoolExp>;
};

/** aggregated selection of "smart_contract_kind" */
export type SmartContractKindAggregate = {
  __typename?: 'SmartContractKindAggregate';
  aggregate?: Maybe<SmartContractKindAggregateFields>;
  nodes: Array<SmartContractKind>;
};

export type SmartContractKindAggregateBoolExp = {
  bool_and?: InputMaybe<SmartContractKindAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<SmartContractKindAggregateBoolExpBool_Or>;
  count?: InputMaybe<SmartContractKindAggregateBoolExpCount>;
};

/** aggregate fields of "smart_contract_kind" */
export type SmartContractKindAggregateFields = {
  __typename?: 'SmartContractKindAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SmartContractKindMaxFields>;
  min?: Maybe<SmartContractKindMinFields>;
};

/** aggregate fields of "smart_contract_kind" */
export type SmartContractKindAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SmartContractKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "smart_contract_kind" */
export type SmartContractKindAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SmartContractKindMaxOrderBy>;
  min?: InputMaybe<SmartContractKindMinOrderBy>;
};

/** input type for inserting array relation for remote table "smart_contract_kind" */
export type SmartContractKindArrRelInsertInput = {
  data: Array<SmartContractKindInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<SmartContractKindOnConflict>;
};

/** Boolean expression to filter rows from the table "smart_contract_kind". All fields are combined with a logical 'AND'. */
export type SmartContractKindBoolExp = {
  _and?: InputMaybe<Array<SmartContractKindBoolExp>>;
  _not?: InputMaybe<SmartContractKindBoolExp>;
  _or?: InputMaybe<Array<SmartContractKindBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  smartContracts?: InputMaybe<SmartContractBoolExp>;
  smartContractsAggregate?: InputMaybe<SmartContractAggregateBoolExp>;
  subindustry?: InputMaybe<SubindustryBoolExp>;
  subindustryId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "smart_contract_kind" */
export enum SmartContractKindConstraint {
  /** unique or primary key constraint on columns "id" */
  SmartContractKindPkey = 'smart_contract_kind_pkey',
  /** unique or primary key constraint on columns "id" */
  SmartContractKindXidKey = 'smart_contract_kind_xid_key',
}

/** input type for inserting data into table "smart_contract_kind" */
export type SmartContractKindInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  smartContracts?: InputMaybe<SmartContractArrRelInsertInput>;
  subindustry?: InputMaybe<SubindustryObjRelInsertInput>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SmartContractKindMaxFields = {
  __typename?: 'SmartContractKindMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subindustryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "smart_contract_kind" */
export type SmartContractKindMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type SmartContractKindMinFields = {
  __typename?: 'SmartContractKindMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  subindustryId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "smart_contract_kind" */
export type SmartContractKindMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "smart_contract_kind" */
export type SmartContractKindMutationResponse = {
  __typename?: 'SmartContractKindMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SmartContractKind>;
};

/** input type for inserting object relation for remote table "smart_contract_kind" */
export type SmartContractKindObjRelInsertInput = {
  data: SmartContractKindInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<SmartContractKindOnConflict>;
};

/** on_conflict condition type for table "smart_contract_kind" */
export type SmartContractKindOnConflict = {
  constraint: SmartContractKindConstraint;
  updateColumns?: Array<SmartContractKindUpdateColumn>;
  where?: InputMaybe<SmartContractKindBoolExp>;
};

/** Ordering options when selecting data from "smart_contract_kind". */
export type SmartContractKindOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  smartContractsAggregate?: InputMaybe<SmartContractAggregateOrderBy>;
  subindustry?: InputMaybe<SubindustryOrderBy>;
  subindustryId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: smart_contract_kind */
export type SmartContractKindPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "smart_contract_kind" */
export enum SmartContractKindSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SubindustryId = 'subindustryId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "smartContractKindAggregateBoolExpBool_andArgumentsColumns" columns of table "smart_contract_kind" */
export enum SmartContractKindSelectColumnSmartContractKindAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "smartContractKindAggregateBoolExpBool_orArgumentsColumns" columns of table "smart_contract_kind" */
export enum SmartContractKindSelectColumnSmartContractKindAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "smart_contract_kind" */
export type SmartContractKindSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "smart_contract_kind" */
export type SmartContractKindStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SmartContractKindStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SmartContractKindStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  subindustryId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "smart_contract_kind" */
export enum SmartContractKindUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SubindustryId = 'subindustryId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type SmartContractKindUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SmartContractKindSetInput>;
  /** filter the rows which have to be updated */
  where: SmartContractKindBoolExp;
};

/** columns and relationships of "smart_contract_lineage" */
export type SmartContractLineage = {
  __typename?: 'SmartContractLineage';
  createdAt: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  lineageComments: Scalars['String'];
  name: Scalars['String'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  parentOrganization?: Maybe<Organization>;
  parentOrganizationId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  parentUser?: Maybe<User>;
  parentUserId?: Maybe<Scalars['uuid']>;
  smartContractId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  userId: Scalars['uuid'];
};

/** columns and relationships of "smart_contract_lineage" */
export type SmartContractLineageDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "smart_contract_lineage" */
export type SmartContractLineageAggregate = {
  __typename?: 'SmartContractLineageAggregate';
  aggregate?: Maybe<SmartContractLineageAggregateFields>;
  nodes: Array<SmartContractLineage>;
};

export type SmartContractLineageAggregateBoolExp = {
  bool_and?: InputMaybe<SmartContractLineageAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<SmartContractLineageAggregateBoolExpBool_Or>;
  count?: InputMaybe<SmartContractLineageAggregateBoolExpCount>;
};

/** aggregate fields of "smart_contract_lineage" */
export type SmartContractLineageAggregateFields = {
  __typename?: 'SmartContractLineageAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SmartContractLineageMaxFields>;
  min?: Maybe<SmartContractLineageMinFields>;
};

/** aggregate fields of "smart_contract_lineage" */
export type SmartContractLineageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SmartContractLineageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "smart_contract_lineage" */
export type SmartContractLineageAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SmartContractLineageMaxOrderBy>;
  min?: InputMaybe<SmartContractLineageMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type SmartContractLineageAppendInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "smart_contract_lineage" */
export type SmartContractLineageArrRelInsertInput = {
  data: Array<SmartContractLineageInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<SmartContractLineageOnConflict>;
};

/** Boolean expression to filter rows from the table "smart_contract_lineage". All fields are combined with a logical 'AND'. */
export type SmartContractLineageBoolExp = {
  _and?: InputMaybe<Array<SmartContractLineageBoolExp>>;
  _not?: InputMaybe<SmartContractLineageBoolExp>;
  _or?: InputMaybe<Array<SmartContractLineageBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  data?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  lineageComments?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  parentOrganization?: InputMaybe<OrganizationBoolExp>;
  parentOrganizationId?: InputMaybe<UuidComparisonExp>;
  parentUser?: InputMaybe<UserBoolExp>;
  parentUserId?: InputMaybe<UuidComparisonExp>;
  smartContractId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "smart_contract_lineage" */
export enum SmartContractLineageConstraint {
  /** unique or primary key constraint on columns "id" */
  SmartContractLineageIdKey = 'smart_contract_lineage_id_key',
  /** unique or primary key constraint on columns "id" */
  SmartContractLineagePkey = 'smart_contract_lineage_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type SmartContractLineageDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type SmartContractLineageDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type SmartContractLineageDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "smart_contract_lineage" */
export type SmartContractLineageInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lineageComments?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  parentOrganization?: InputMaybe<OrganizationObjRelInsertInput>;
  parentOrganizationId?: InputMaybe<Scalars['uuid']>;
  parentUser?: InputMaybe<UserObjRelInsertInput>;
  parentUserId?: InputMaybe<Scalars['uuid']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SmartContractLineageMaxFields = {
  __typename?: 'SmartContractLineageMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lineageComments?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  parentOrganizationId?: Maybe<Scalars['uuid']>;
  parentUserId?: Maybe<Scalars['uuid']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "smart_contract_lineage" */
export type SmartContractLineageMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lineageComments?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  parentOrganizationId?: InputMaybe<OrderBy>;
  parentUserId?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type SmartContractLineageMinFields = {
  __typename?: 'SmartContractLineageMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lineageComments?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  parentOrganizationId?: Maybe<Scalars['uuid']>;
  parentUserId?: Maybe<Scalars['uuid']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "smart_contract_lineage" */
export type SmartContractLineageMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lineageComments?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  parentOrganizationId?: InputMaybe<OrderBy>;
  parentUserId?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "smart_contract_lineage" */
export type SmartContractLineageMutationResponse = {
  __typename?: 'SmartContractLineageMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SmartContractLineage>;
};

/** on_conflict condition type for table "smart_contract_lineage" */
export type SmartContractLineageOnConflict = {
  constraint: SmartContractLineageConstraint;
  updateColumns?: Array<SmartContractLineageUpdateColumn>;
  where?: InputMaybe<SmartContractLineageBoolExp>;
};

/** Ordering options when selecting data from "smart_contract_lineage". */
export type SmartContractLineageOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  lineageComments?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  parentOrganization?: InputMaybe<OrganizationOrderBy>;
  parentOrganizationId?: InputMaybe<OrderBy>;
  parentUser?: InputMaybe<UserOrderBy>;
  parentUserId?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: smart_contract_lineage */
export type SmartContractLineagePkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type SmartContractLineagePrependInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "smart_contract_lineage" */
export enum SmartContractLineageSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  LineageComments = 'lineageComments',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  ParentOrganizationId = 'parentOrganizationId',
  /** column name */
  ParentUserId = 'parentUserId',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** select "smartContractLineageAggregateBoolExpBool_andArgumentsColumns" columns of table "smart_contract_lineage" */
export enum SmartContractLineageSelectColumnSmartContractLineageAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "smartContractLineageAggregateBoolExpBool_orArgumentsColumns" columns of table "smart_contract_lineage" */
export enum SmartContractLineageSelectColumnSmartContractLineageAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "smart_contract_lineage" */
export type SmartContractLineageSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lineageComments?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  parentOrganizationId?: InputMaybe<Scalars['uuid']>;
  parentUserId?: InputMaybe<Scalars['uuid']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "smart_contract_lineage" */
export type SmartContractLineageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SmartContractLineageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SmartContractLineageStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lineageComments?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  parentOrganizationId?: InputMaybe<Scalars['uuid']>;
  parentUserId?: InputMaybe<Scalars['uuid']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "smart_contract_lineage" */
export enum SmartContractLineageUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  LineageComments = 'lineageComments',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  ParentOrganizationId = 'parentOrganizationId',
  /** column name */
  ParentUserId = 'parentUserId',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type SmartContractLineageUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<SmartContractLineageAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<SmartContractLineageDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<SmartContractLineageDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<SmartContractLineageDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<SmartContractLineagePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SmartContractLineageSetInput>;
  /** filter the rows which have to be updated */
  where: SmartContractLineageBoolExp;
};

/** aggregate max on columns */
export type SmartContractMaxFields = {
  __typename?: 'SmartContractMaxFields';
  clearinghouseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  smartContractDocumentId?: Maybe<Scalars['uuid']>;
  smartContractKindId?: Maybe<Scalars['uuid']>;
  terms?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  useCount?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "smart_contract" */
export type SmartContractMaxOrderBy = {
  clearinghouseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  smartContractDocumentId?: InputMaybe<OrderBy>;
  smartContractKindId?: InputMaybe<OrderBy>;
  terms?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  useCount?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type SmartContractMinFields = {
  __typename?: 'SmartContractMinFields';
  clearinghouseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  smartContractDocumentId?: Maybe<Scalars['uuid']>;
  smartContractKindId?: Maybe<Scalars['uuid']>;
  terms?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  useCount?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "smart_contract" */
export type SmartContractMinOrderBy = {
  clearinghouseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  smartContractDocumentId?: InputMaybe<OrderBy>;
  smartContractKindId?: InputMaybe<OrderBy>;
  terms?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  useCount?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "smart_contract" */
export type SmartContractMutationResponse = {
  __typename?: 'SmartContractMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SmartContract>;
};

/** input type for inserting object relation for remote table "smart_contract" */
export type SmartContractObjRelInsertInput = {
  data: SmartContractInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<SmartContractOnConflict>;
};

/** on_conflict condition type for table "smart_contract" */
export type SmartContractOnConflict = {
  constraint: SmartContractConstraint;
  updateColumns?: Array<SmartContractUpdateColumn>;
  where?: InputMaybe<SmartContractBoolExp>;
};

/** Ordering options when selecting data from "smart_contract". */
export type SmartContractOrderBy = {
  appraisalsAggregate?: InputMaybe<AppraisalAggregateOrderBy>;
  clearinghouse?: InputMaybe<ClearinghouseOrderBy>;
  clearinghouseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  kind?: InputMaybe<SmartContractKindOrderBy>;
  name?: InputMaybe<OrderBy>;
  putsAggregate?: InputMaybe<PutAggregateOrderBy>;
  smartContractDocumentId?: InputMaybe<OrderBy>;
  smartContractKindId?: InputMaybe<OrderBy>;
  terms?: InputMaybe<OrderBy>;
  transactionKindsAggregate?: InputMaybe<TransactionKindAggregateOrderBy>;
  transactionsAggregate?: InputMaybe<TransactionAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  useCount?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: smart_contract */
export type SmartContractPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "smart_contract" */
export enum SmartContractSelectColumn {
  /** column name */
  ClearinghouseId = 'clearinghouseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SmartContractDocumentId = 'smartContractDocumentId',
  /** column name */
  SmartContractKindId = 'smartContractKindId',
  /** column name */
  Terms = 'terms',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UseCount = 'useCount',
}

/** select "smartContractAggregateBoolExpBool_andArgumentsColumns" columns of table "smart_contract" */
export enum SmartContractSelectColumnSmartContractAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "smartContractAggregateBoolExpBool_orArgumentsColumns" columns of table "smart_contract" */
export enum SmartContractSelectColumnSmartContractAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "smart_contract" */
export type SmartContractSetInput = {
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  smartContractDocumentId?: InputMaybe<Scalars['uuid']>;
  smartContractKindId?: InputMaybe<Scalars['uuid']>;
  terms?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  useCount?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SmartContractStddevFields = {
  __typename?: 'SmartContractStddevFields';
  useCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "smart_contract" */
export type SmartContractStddevOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type SmartContractStddevPopFields = {
  __typename?: 'SmartContractStddevPopFields';
  useCount?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "smart_contract" */
export type SmartContractStddevPopOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type SmartContractStddevSampFields = {
  __typename?: 'SmartContractStddevSampFields';
  useCount?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "smart_contract" */
export type SmartContractStddevSampOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "smart_contract" */
export type SmartContractStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SmartContractStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SmartContractStreamCursorValueInput = {
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  smartContractDocumentId?: InputMaybe<Scalars['uuid']>;
  smartContractKindId?: InputMaybe<Scalars['uuid']>;
  terms?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  useCount?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type SmartContractSumFields = {
  __typename?: 'SmartContractSumFields';
  useCount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "smart_contract" */
export type SmartContractSumOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** update columns of table "smart_contract" */
export enum SmartContractUpdateColumn {
  /** column name */
  ClearinghouseId = 'clearinghouseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SmartContractDocumentId = 'smartContractDocumentId',
  /** column name */
  SmartContractKindId = 'smartContractKindId',
  /** column name */
  Terms = 'terms',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UseCount = 'useCount',
}

export type SmartContractUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SmartContractIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SmartContractSetInput>;
  /** filter the rows which have to be updated */
  where: SmartContractBoolExp;
};

/** aggregate varPop on columns */
export type SmartContractVarPopFields = {
  __typename?: 'SmartContractVarPopFields';
  useCount?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "smart_contract" */
export type SmartContractVarPopOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type SmartContractVarSampFields = {
  __typename?: 'SmartContractVarSampFields';
  useCount?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "smart_contract" */
export type SmartContractVarSampOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type SmartContractVarianceFields = {
  __typename?: 'SmartContractVarianceFields';
  useCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "smart_contract" */
export type SmartContractVarianceOrderBy = {
  useCount?: InputMaybe<OrderBy>;
};

/** columns and relationships of "state" */
export type State = {
  __typename?: 'State';
  code: Scalars['String'];
  /** An object relationship */
  country: Country;
  countryCode: Scalars['String'];
  /** An object relationship */
  countryRegion?: Maybe<CountryRegion>;
  countryRegionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  isDeleted: Scalars['Boolean'];
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  name: Scalars['String'];
  secCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "state" */
export type StateAggregate = {
  __typename?: 'StateAggregate';
  aggregate?: Maybe<StateAggregateFields>;
  nodes: Array<State>;
};

export type StateAggregateBoolExp = {
  avg?: InputMaybe<StateAggregateBoolExpAvg>;
  bool_and?: InputMaybe<StateAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<StateAggregateBoolExpBool_Or>;
  corr?: InputMaybe<StateAggregateBoolExpCorr>;
  count?: InputMaybe<StateAggregateBoolExpCount>;
  covar_samp?: InputMaybe<StateAggregateBoolExpCovar_Samp>;
  max?: InputMaybe<StateAggregateBoolExpMax>;
  min?: InputMaybe<StateAggregateBoolExpMin>;
  stddev_samp?: InputMaybe<StateAggregateBoolExpStddev_Samp>;
  sum?: InputMaybe<StateAggregateBoolExpSum>;
  var_samp?: InputMaybe<StateAggregateBoolExpVar_Samp>;
};

/** aggregate fields of "state" */
export type StateAggregateFields = {
  __typename?: 'StateAggregateFields';
  avg?: Maybe<StateAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<StateMaxFields>;
  min?: Maybe<StateMinFields>;
  stddev?: Maybe<StateStddevFields>;
  stddevPop?: Maybe<StateStddevPopFields>;
  stddevSamp?: Maybe<StateStddevSampFields>;
  sum?: Maybe<StateSumFields>;
  varPop?: Maybe<StateVarPopFields>;
  varSamp?: Maybe<StateVarSampFields>;
  variance?: Maybe<StateVarianceFields>;
};

/** aggregate fields of "state" */
export type StateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "state" */
export type StateAggregateOrderBy = {
  avg?: InputMaybe<StateAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<StateMaxOrderBy>;
  min?: InputMaybe<StateMinOrderBy>;
  stddev?: InputMaybe<StateStddevOrderBy>;
  stddevPop?: InputMaybe<StateStddevPopOrderBy>;
  stddevSamp?: InputMaybe<StateStddevSampOrderBy>;
  sum?: InputMaybe<StateSumOrderBy>;
  varPop?: InputMaybe<StateVarPopOrderBy>;
  varSamp?: InputMaybe<StateVarSampOrderBy>;
  variance?: InputMaybe<StateVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "state" */
export type StateArrRelInsertInput = {
  data: Array<StateInsertInput>;
};

/** aggregate avg on columns */
export type StateAvgFields = {
  __typename?: 'StateAvgFields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "state" */
export type StateAvgOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "state". All fields are combined with a logical 'AND'. */
export type StateBoolExp = {
  _and?: InputMaybe<Array<StateBoolExp>>;
  _not?: InputMaybe<StateBoolExp>;
  _or?: InputMaybe<Array<StateBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<CountryBoolExp>;
  countryCode?: InputMaybe<StringComparisonExp>;
  countryRegion?: InputMaybe<CountryRegionBoolExp>;
  countryRegionId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  latitude?: InputMaybe<Float8ComparisonExp>;
  longitude?: InputMaybe<Float8ComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  secCode?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** input type for incrementing numeric columns in table "state" */
export type StateIncInput = {
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "state" */
export type StateInsertInput = {
  code?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryObjRelInsertInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  countryRegion?: InputMaybe<CountryRegionObjRelInsertInput>;
  countryRegionId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  secCode?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type StateMaxFields = {
  __typename?: 'StateMaxFields';
  code?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  countryRegionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  secCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "state" */
export type StateMaxOrderBy = {
  code?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  countryRegionId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  secCode?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type StateMinFields = {
  __typename?: 'StateMinFields';
  code?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  countryRegionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  secCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "state" */
export type StateMinOrderBy = {
  code?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  countryRegionId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  secCode?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "state" */
export type StateMutationResponse = {
  __typename?: 'StateMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<State>;
};

/** Ordering options when selecting data from "state". */
export type StateOrderBy = {
  code?: InputMaybe<OrderBy>;
  country?: InputMaybe<CountryOrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  countryRegion?: InputMaybe<CountryRegionOrderBy>;
  countryRegionId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  secCode?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "state" */
export enum StateSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CountryRegionId = 'countryRegionId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  SecCode = 'secCode',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "stateAggregateBoolExpAvgArgumentsColumns" columns of table "state" */
export enum StateSelectColumnStateAggregateBoolExpAvgArgumentsColumns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
}

/** select "stateAggregateBoolExpBool_andArgumentsColumns" columns of table "state" */
export enum StateSelectColumnStateAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "stateAggregateBoolExpBool_orArgumentsColumns" columns of table "state" */
export enum StateSelectColumnStateAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "stateAggregateBoolExpCorrArgumentsColumns" columns of table "state" */
export enum StateSelectColumnStateAggregateBoolExpCorrArgumentsColumns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
}

/** select "stateAggregateBoolExpCovar_sampArgumentsColumns" columns of table "state" */
export enum StateSelectColumnStateAggregateBoolExpCovar_SampArgumentsColumns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
}

/** select "stateAggregateBoolExpMaxArgumentsColumns" columns of table "state" */
export enum StateSelectColumnStateAggregateBoolExpMaxArgumentsColumns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
}

/** select "stateAggregateBoolExpMinArgumentsColumns" columns of table "state" */
export enum StateSelectColumnStateAggregateBoolExpMinArgumentsColumns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
}

/** select "stateAggregateBoolExpStddev_sampArgumentsColumns" columns of table "state" */
export enum StateSelectColumnStateAggregateBoolExpStddev_SampArgumentsColumns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
}

/** select "stateAggregateBoolExpSumArgumentsColumns" columns of table "state" */
export enum StateSelectColumnStateAggregateBoolExpSumArgumentsColumns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
}

/** select "stateAggregateBoolExpVar_sampArgumentsColumns" columns of table "state" */
export enum StateSelectColumnStateAggregateBoolExpVar_SampArgumentsColumns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
}

/** input type for updating data in table "state" */
export type StateSetInput = {
  code?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  countryRegionId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  secCode?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type StateStddevFields = {
  __typename?: 'StateStddevFields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "state" */
export type StateStddevOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type StateStddevPopFields = {
  __typename?: 'StateStddevPopFields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "state" */
export type StateStddevPopOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type StateStddevSampFields = {
  __typename?: 'StateStddevSampFields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "state" */
export type StateStddevSampOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "state" */
export type StateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StateStreamCursorValueInput = {
  code?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  countryRegionId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  secCode?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type StateSumFields = {
  __typename?: 'StateSumFields';
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "state" */
export type StateSumOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
};

export type StateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StateSetInput>;
  /** filter the rows which have to be updated */
  where: StateBoolExp;
};

/** aggregate varPop on columns */
export type StateVarPopFields = {
  __typename?: 'StateVarPopFields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "state" */
export type StateVarPopOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type StateVarSampFields = {
  __typename?: 'StateVarSampFields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "state" */
export type StateVarSampOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type StateVarianceFields = {
  __typename?: 'StateVarianceFields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "state" */
export type StateVarianceOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
};

/** columns and relationships of "status" */
export type Status = {
  __typename?: 'Status';
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  endDate?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  kind?: Maybe<StatusKind>;
  kindId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  merchantCurrencies: Array<MerchantCurrency>;
  /** An aggregate relationship */
  merchantCurrenciesAggregate: MerchantCurrencyAggregate;
  /** An array relationship */
  merchants: Array<Merchant>;
  /** An aggregate relationship */
  merchantsAggregate: MerchantAggregate;
  name: Scalars['String'];
  startDate?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  transactions: Array<Transaction>;
  /** An aggregate relationship */
  transactionsAggregate: TransactionAggregate;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  usersAggregate: UserAggregate;
};

/** columns and relationships of "status" */
export type StatusMerchantCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<MerchantCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantCurrencyOrderBy>>;
  where?: InputMaybe<MerchantCurrencyBoolExp>;
};

/** columns and relationships of "status" */
export type StatusMerchantCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MerchantCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantCurrencyOrderBy>>;
  where?: InputMaybe<MerchantCurrencyBoolExp>;
};

/** columns and relationships of "status" */
export type StatusMerchantsArgs = {
  distinctOn?: InputMaybe<Array<MerchantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantOrderBy>>;
  where?: InputMaybe<MerchantBoolExp>;
};

/** columns and relationships of "status" */
export type StatusMerchantsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MerchantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantOrderBy>>;
  where?: InputMaybe<MerchantBoolExp>;
};

/** columns and relationships of "status" */
export type StatusTransactionsArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

/** columns and relationships of "status" */
export type StatusTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

/** columns and relationships of "status" */
export type StatusUsersArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

/** columns and relationships of "status" */
export type StatusUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

/** aggregated selection of "status" */
export type StatusAggregate = {
  __typename?: 'StatusAggregate';
  aggregate?: Maybe<StatusAggregateFields>;
  nodes: Array<Status>;
};

export type StatusAggregateBoolExp = {
  bool_and?: InputMaybe<StatusAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<StatusAggregateBoolExpBool_Or>;
  count?: InputMaybe<StatusAggregateBoolExpCount>;
};

/** aggregate fields of "status" */
export type StatusAggregateFields = {
  __typename?: 'StatusAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<StatusMaxFields>;
  min?: Maybe<StatusMinFields>;
};

/** aggregate fields of "status" */
export type StatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "status" */
export type StatusAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<StatusMaxOrderBy>;
  min?: InputMaybe<StatusMinOrderBy>;
};

/** input type for inserting array relation for remote table "status" */
export type StatusArrRelInsertInput = {
  data: Array<StatusInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<StatusOnConflict>;
};

/** Boolean expression to filter rows from the table "status". All fields are combined with a logical 'AND'. */
export type StatusBoolExp = {
  _and?: InputMaybe<Array<StatusBoolExp>>;
  _not?: InputMaybe<StatusBoolExp>;
  _or?: InputMaybe<Array<StatusBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  endDate?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  kind?: InputMaybe<StatusKindBoolExp>;
  kindId?: InputMaybe<UuidComparisonExp>;
  merchantCurrencies?: InputMaybe<MerchantCurrencyBoolExp>;
  merchantCurrenciesAggregate?: InputMaybe<MerchantCurrencyAggregateBoolExp>;
  merchants?: InputMaybe<MerchantBoolExp>;
  merchantsAggregate?: InputMaybe<MerchantAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  startDate?: InputMaybe<TimestamptzComparisonExp>;
  transactions?: InputMaybe<TransactionBoolExp>;
  transactionsAggregate?: InputMaybe<TransactionAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  users?: InputMaybe<UserBoolExp>;
  usersAggregate?: InputMaybe<UserAggregateBoolExp>;
};

/** unique or primary key constraints on table "status" */
export enum StatusConstraint {
  /** unique or primary key constraint on columns "id" */
  StatusPkey = 'status_pkey',
  /** unique or primary key constraint on columns "id" */
  StatusXidKey = 'status_xid_key',
}

/** input type for inserting data into table "status" */
export type StatusInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<StatusKindObjRelInsertInput>;
  kindId?: InputMaybe<Scalars['uuid']>;
  merchantCurrencies?: InputMaybe<MerchantCurrencyArrRelInsertInput>;
  merchants?: InputMaybe<MerchantArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  transactions?: InputMaybe<TransactionArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  users?: InputMaybe<UserArrRelInsertInput>;
};

/** columns and relationships of "status_kind" */
export type StatusKind = {
  __typename?: 'StatusKind';
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An array relationship */
  statuses: Array<Status>;
  /** An aggregate relationship */
  statusesAggregate: StatusAggregate;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "status_kind" */
export type StatusKindStatusesArgs = {
  distinctOn?: InputMaybe<Array<StatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatusOrderBy>>;
  where?: InputMaybe<StatusBoolExp>;
};

/** columns and relationships of "status_kind" */
export type StatusKindStatusesAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatusOrderBy>>;
  where?: InputMaybe<StatusBoolExp>;
};

/** aggregated selection of "status_kind" */
export type StatusKindAggregate = {
  __typename?: 'StatusKindAggregate';
  aggregate?: Maybe<StatusKindAggregateFields>;
  nodes: Array<StatusKind>;
};

/** aggregate fields of "status_kind" */
export type StatusKindAggregateFields = {
  __typename?: 'StatusKindAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<StatusKindMaxFields>;
  min?: Maybe<StatusKindMinFields>;
};

/** aggregate fields of "status_kind" */
export type StatusKindAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StatusKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "status_kind". All fields are combined with a logical 'AND'. */
export type StatusKindBoolExp = {
  _and?: InputMaybe<Array<StatusKindBoolExp>>;
  _not?: InputMaybe<StatusKindBoolExp>;
  _or?: InputMaybe<Array<StatusKindBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  statuses?: InputMaybe<StatusBoolExp>;
  statusesAggregate?: InputMaybe<StatusAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "status_kind" */
export enum StatusKindConstraint {
  /** unique or primary key constraint on columns "id" */
  StatusKindPkey = 'status_kind_pkey',
  /** unique or primary key constraint on columns "id" */
  StatusKindXidKey = 'status_kind_xid_key',
}

/** input type for inserting data into table "status_kind" */
export type StatusKindInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<StatusArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type StatusKindMaxFields = {
  __typename?: 'StatusKindMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type StatusKindMinFields = {
  __typename?: 'StatusKindMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "status_kind" */
export type StatusKindMutationResponse = {
  __typename?: 'StatusKindMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StatusKind>;
};

/** input type for inserting object relation for remote table "status_kind" */
export type StatusKindObjRelInsertInput = {
  data: StatusKindInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<StatusKindOnConflict>;
};

/** on_conflict condition type for table "status_kind" */
export type StatusKindOnConflict = {
  constraint: StatusKindConstraint;
  updateColumns?: Array<StatusKindUpdateColumn>;
  where?: InputMaybe<StatusKindBoolExp>;
};

/** Ordering options when selecting data from "status_kind". */
export type StatusKindOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  statusesAggregate?: InputMaybe<StatusAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: status_kind */
export type StatusKindPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "status_kind" */
export enum StatusKindSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "status_kind" */
export type StatusKindSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "status_kind" */
export type StatusKindStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StatusKindStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StatusKindStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "status_kind" */
export enum StatusKindUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type StatusKindUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StatusKindSetInput>;
  /** filter the rows which have to be updated */
  where: StatusKindBoolExp;
};

/** aggregate max on columns */
export type StatusMaxFields = {
  __typename?: 'StatusMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  kindId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "status" */
export type StatusMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  kindId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type StatusMinFields = {
  __typename?: 'StatusMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  kindId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "status" */
export type StatusMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  kindId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "status" */
export type StatusMutationResponse = {
  __typename?: 'StatusMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Status>;
};

/** input type for inserting object relation for remote table "status" */
export type StatusObjRelInsertInput = {
  data: StatusInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<StatusOnConflict>;
};

/** on_conflict condition type for table "status" */
export type StatusOnConflict = {
  constraint: StatusConstraint;
  updateColumns?: Array<StatusUpdateColumn>;
  where?: InputMaybe<StatusBoolExp>;
};

/** Ordering options when selecting data from "status". */
export type StatusOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  kind?: InputMaybe<StatusKindOrderBy>;
  kindId?: InputMaybe<OrderBy>;
  merchantCurrenciesAggregate?: InputMaybe<MerchantCurrencyAggregateOrderBy>;
  merchantsAggregate?: InputMaybe<MerchantAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  transactionsAggregate?: InputMaybe<TransactionAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  usersAggregate?: InputMaybe<UserAggregateOrderBy>;
};

/** primary key columns input for table: status */
export type StatusPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "status" */
export enum StatusSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  KindId = 'kindId',
  /** column name */
  Name = 'name',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "statusAggregateBoolExpBool_andArgumentsColumns" columns of table "status" */
export enum StatusSelectColumnStatusAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "statusAggregateBoolExpBool_orArgumentsColumns" columns of table "status" */
export enum StatusSelectColumnStatusAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "status" */
export type StatusSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  kindId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "status" */
export type StatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StatusStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  kindId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "status" */
export enum StatusUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  KindId = 'kindId',
  /** column name */
  Name = 'name',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type StatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StatusSetInput>;
  /** filter the rows which have to be updated */
  where: StatusBoolExp;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "subindustry" */
export type Subindustry = {
  __typename?: 'Subindustry';
  /** An array relationship */
  certifications: Array<Certification>;
  /** An aggregate relationship */
  certificationsAggregate: CertificationAggregate;
  /** An array relationship */
  clearinghouseKinds: Array<ClearinghouseKind>;
  /** An aggregate relationship */
  clearinghouseKindsAggregate: ClearinghouseKindAggregate;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  currencies: Array<Currency>;
  /** An aggregate relationship */
  currenciesAggregate: CurrencyAggregate;
  /** An array relationship */
  currencyKinds: Array<CurrencyKind>;
  /** An aggregate relationship */
  currencyKindsAggregate: CurrencyKindAggregate;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  imageSubindustry?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An aggregate relationship */
  nfaKindsAggregate: AssetKindAggregate;
  /** An array relationship */
  nfa_kinds: Array<AssetKind>;
  /** An array relationship */
  nfas: Array<Asset>;
  /** An aggregate relationship */
  nfasAggregate: AssetAggregate;
  /** An array relationship */
  organizations: Array<Organization>;
  /** An aggregate relationship */
  organizationsAggregate: OrganizationAggregate;
  /** An array relationship */
  organizationsBySub2IndustryId: Array<Organization>;
  /** An aggregate relationship */
  organizationsBySub2IndustryIdAggregate: OrganizationAggregate;
  /** An object relationship */
  parentIndustry?: Maybe<Industry>;
  parentIndustryId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  products: Array<Product>;
  /** An aggregate relationship */
  productsAggregate: ProductAggregate;
  /** An array relationship */
  productsBySubindustry2Id: Array<Product>;
  /** An aggregate relationship */
  productsBySubindustry2IdAggregate: ProductAggregate;
  /** An object relationship */
  sicCode?: Maybe<SicCode>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  /** An aggregate relationship */
  smartContractKindsAggregate: SmartContractKindAggregate;
  /** An array relationship */
  smart_contract_kinds: Array<SmartContractKind>;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "subindustry" */
export type SubindustryCertificationsArgs = {
  distinctOn?: InputMaybe<Array<CertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CertificationOrderBy>>;
  where?: InputMaybe<CertificationBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryCertificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CertificationOrderBy>>;
  where?: InputMaybe<CertificationBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryClearinghouseKindsArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseKindOrderBy>>;
  where?: InputMaybe<ClearinghouseKindBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryClearinghouseKindsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseKindOrderBy>>;
  where?: InputMaybe<ClearinghouseKindBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<CurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOrderBy>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOrderBy>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryCurrencyKindsArgs = {
  distinctOn?: InputMaybe<Array<CurrencyKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyKindOrderBy>>;
  where?: InputMaybe<CurrencyKindBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryCurrencyKindsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencyKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyKindOrderBy>>;
  where?: InputMaybe<CurrencyKindBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryNfaKindsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssetKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetKindOrderBy>>;
  where?: InputMaybe<AssetKindBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryNfa_KindsArgs = {
  distinctOn?: InputMaybe<Array<AssetKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetKindOrderBy>>;
  where?: InputMaybe<AssetKindBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryNfasArgs = {
  distinctOn?: InputMaybe<Array<AssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetOrderBy>>;
  where?: InputMaybe<AssetBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryNfasAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetOrderBy>>;
  where?: InputMaybe<AssetBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryOrganizationsArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryOrganizationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryOrganizationsBySub2IndustryIdArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryOrganizationsBySub2IndustryIdAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryProductsArgs = {
  distinctOn?: InputMaybe<Array<ProductSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
  where?: InputMaybe<ProductBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
  where?: InputMaybe<ProductBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryProductsBySubindustry2IdArgs = {
  distinctOn?: InputMaybe<Array<ProductSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
  where?: InputMaybe<ProductBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustryProductsBySubindustry2IdAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
  where?: InputMaybe<ProductBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustrySmartContractKindsAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmartContractKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractKindOrderBy>>;
  where?: InputMaybe<SmartContractKindBoolExp>;
};

/** columns and relationships of "subindustry" */
export type SubindustrySmart_Contract_KindsArgs = {
  distinctOn?: InputMaybe<Array<SmartContractKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractKindOrderBy>>;
  where?: InputMaybe<SmartContractKindBoolExp>;
};

/** aggregated selection of "subindustry" */
export type SubindustryAggregate = {
  __typename?: 'SubindustryAggregate';
  aggregate?: Maybe<SubindustryAggregateFields>;
  nodes: Array<Subindustry>;
};

export type SubindustryAggregateBoolExp = {
  bool_and?: InputMaybe<SubindustryAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<SubindustryAggregateBoolExpBool_Or>;
  count?: InputMaybe<SubindustryAggregateBoolExpCount>;
};

/** aggregate fields of "subindustry" */
export type SubindustryAggregateFields = {
  __typename?: 'SubindustryAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SubindustryMaxFields>;
  min?: Maybe<SubindustryMinFields>;
};

/** aggregate fields of "subindustry" */
export type SubindustryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SubindustrySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "subindustry" */
export type SubindustryAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SubindustryMaxOrderBy>;
  min?: InputMaybe<SubindustryMinOrderBy>;
};

/** input type for inserting array relation for remote table "subindustry" */
export type SubindustryArrRelInsertInput = {
  data: Array<SubindustryInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<SubindustryOnConflict>;
};

/** Boolean expression to filter rows from the table "subindustry". All fields are combined with a logical 'AND'. */
export type SubindustryBoolExp = {
  _and?: InputMaybe<Array<SubindustryBoolExp>>;
  _not?: InputMaybe<SubindustryBoolExp>;
  _or?: InputMaybe<Array<SubindustryBoolExp>>;
  certifications?: InputMaybe<CertificationBoolExp>;
  certificationsAggregate?: InputMaybe<CertificationAggregateBoolExp>;
  clearinghouseKinds?: InputMaybe<ClearinghouseKindBoolExp>;
  clearinghouseKindsAggregate?: InputMaybe<ClearinghouseKindAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currencies?: InputMaybe<CurrencyBoolExp>;
  currenciesAggregate?: InputMaybe<CurrencyAggregateBoolExp>;
  currencyKinds?: InputMaybe<CurrencyKindBoolExp>;
  currencyKindsAggregate?: InputMaybe<CurrencyKindAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageSubindustry?: InputMaybe<StringComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nfa_kinds?: InputMaybe<AssetKindBoolExp>;
  nfa_kindsAggregate?: InputMaybe<AssetKindAggregateBoolExp>;
  nfas?: InputMaybe<AssetBoolExp>;
  nfasAggregate?: InputMaybe<AssetAggregateBoolExp>;
  organizations?: InputMaybe<OrganizationBoolExp>;
  organizationsAggregate?: InputMaybe<OrganizationAggregateBoolExp>;
  organizationsBySub2IndustryId?: InputMaybe<OrganizationBoolExp>;
  organizationsBySub2IndustryIdAggregate?: InputMaybe<OrganizationAggregateBoolExp>;
  parentIndustry?: InputMaybe<IndustryBoolExp>;
  parentIndustryId?: InputMaybe<UuidComparisonExp>;
  products?: InputMaybe<ProductBoolExp>;
  productsAggregate?: InputMaybe<ProductAggregateBoolExp>;
  productsBySubindustry2Id?: InputMaybe<ProductBoolExp>;
  productsBySubindustry2IdAggregate?: InputMaybe<ProductAggregateBoolExp>;
  sicCode?: InputMaybe<SicCodeBoolExp>;
  sicCodeId?: InputMaybe<UuidComparisonExp>;
  smart_contract_kinds?: InputMaybe<SmartContractKindBoolExp>;
  smart_contract_kindsAggregate?: InputMaybe<SmartContractKindAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "subindustry" */
export enum SubindustryConstraint {
  /** unique or primary key constraint on columns "id" */
  SubindustryPkey = 'subindustry_pkey',
  /** unique or primary key constraint on columns "id" */
  SubindustryXidKey = 'subindustry_xid_key',
}

/** input type for inserting data into table "subindustry" */
export type SubindustryInsertInput = {
  certifications?: InputMaybe<CertificationArrRelInsertInput>;
  clearinghouseKinds?: InputMaybe<ClearinghouseKindArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencies?: InputMaybe<CurrencyArrRelInsertInput>;
  currencyKinds?: InputMaybe<CurrencyKindArrRelInsertInput>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageSubindustry?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nfa_kinds?: InputMaybe<AssetKindArrRelInsertInput>;
  nfas?: InputMaybe<AssetArrRelInsertInput>;
  organizations?: InputMaybe<OrganizationArrRelInsertInput>;
  organizationsBySub2IndustryId?: InputMaybe<OrganizationArrRelInsertInput>;
  parentIndustry?: InputMaybe<IndustryObjRelInsertInput>;
  parentIndustryId?: InputMaybe<Scalars['uuid']>;
  products?: InputMaybe<ProductArrRelInsertInput>;
  productsBySubindustry2Id?: InputMaybe<ProductArrRelInsertInput>;
  sicCode?: InputMaybe<SicCodeObjRelInsertInput>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  smart_contract_kinds?: InputMaybe<SmartContractKindArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SubindustryMaxFields = {
  __typename?: 'SubindustryMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageSubindustry?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentIndustryId?: Maybe<Scalars['uuid']>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "subindustry" */
export type SubindustryMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageSubindustry?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  parentIndustryId?: InputMaybe<OrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type SubindustryMinFields = {
  __typename?: 'SubindustryMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageSubindustry?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentIndustryId?: Maybe<Scalars['uuid']>;
  sicCodeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "subindustry" */
export type SubindustryMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageSubindustry?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  parentIndustryId?: InputMaybe<OrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "subindustry" */
export type SubindustryMutationResponse = {
  __typename?: 'SubindustryMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subindustry>;
};

/** input type for inserting object relation for remote table "subindustry" */
export type SubindustryObjRelInsertInput = {
  data: SubindustryInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<SubindustryOnConflict>;
};

/** on_conflict condition type for table "subindustry" */
export type SubindustryOnConflict = {
  constraint: SubindustryConstraint;
  updateColumns?: Array<SubindustryUpdateColumn>;
  where?: InputMaybe<SubindustryBoolExp>;
};

/** Ordering options when selecting data from "subindustry". */
export type SubindustryOrderBy = {
  certificationsAggregate?: InputMaybe<CertificationAggregateOrderBy>;
  clearinghouseKindsAggregate?: InputMaybe<ClearinghouseKindAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currenciesAggregate?: InputMaybe<CurrencyAggregateOrderBy>;
  currencyKindsAggregate?: InputMaybe<CurrencyKindAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageSubindustry?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nfa_kindsAggregate?: InputMaybe<AssetKindAggregateOrderBy>;
  nfasAggregate?: InputMaybe<AssetAggregateOrderBy>;
  organizationsAggregate?: InputMaybe<OrganizationAggregateOrderBy>;
  organizationsBySub2IndustryIdAggregate?: InputMaybe<OrganizationAggregateOrderBy>;
  parentIndustry?: InputMaybe<IndustryOrderBy>;
  parentIndustryId?: InputMaybe<OrderBy>;
  productsAggregate?: InputMaybe<ProductAggregateOrderBy>;
  productsBySubindustry2IdAggregate?: InputMaybe<ProductAggregateOrderBy>;
  sicCode?: InputMaybe<SicCodeOrderBy>;
  sicCodeId?: InputMaybe<OrderBy>;
  smart_contract_kindsAggregate?: InputMaybe<SmartContractKindAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: subindustry */
export type SubindustryPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "subindustry" */
export enum SubindustrySelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageSubindustry = 'imageSubindustry',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  ParentIndustryId = 'parentIndustryId',
  /** column name */
  SicCodeId = 'sicCodeId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "subindustryAggregateBoolExpBool_andArgumentsColumns" columns of table "subindustry" */
export enum SubindustrySelectColumnSubindustryAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "subindustryAggregateBoolExpBool_orArgumentsColumns" columns of table "subindustry" */
export enum SubindustrySelectColumnSubindustryAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "subindustry" */
export type SubindustrySetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageSubindustry?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parentIndustryId?: InputMaybe<Scalars['uuid']>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "subindustry" */
export type SubindustryStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SubindustryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SubindustryStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageSubindustry?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parentIndustryId?: InputMaybe<Scalars['uuid']>;
  sicCodeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "subindustry" */
export enum SubindustryUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageSubindustry = 'imageSubindustry',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  ParentIndustryId = 'parentIndustryId',
  /** column name */
  SicCodeId = 'sicCodeId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type SubindustryUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SubindustrySetInput>;
  /** filter the rows which have to be updated */
  where: SubindustryBoolExp;
};

/** columns and relationships of "supply_chain_waypoint" */
export type SupplyChainWaypoint = {
  __typename?: 'SupplyChainWaypoint';
  actualProcessTime?: Maybe<Scalars['date']>;
  actualQueueTime?: Maybe<Scalars['date']>;
  avgProcessTime?: Maybe<Scalars['date']>;
  avgQueueTime?: Maybe<Scalars['date']>;
  /** An object relationship */
  consigneeOrg?: Maybe<Organization>;
  consigneeOrgId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  consignorOrg?: Maybe<Organization>;
  consignorOrgId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customsOrg?: Maybe<Organization>;
  customsOrgId?: Maybe<Scalars['uuid']>;
  description: Scalars['String'];
  /** An object relationship */
  freightForwarderOrg?: Maybe<Organization>;
  freightForwarderOrgId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  fromShippingCarrierOrg?: Maybe<Organization>;
  fromShippingCarrierOrgId?: Maybe<Scalars['uuid']>;
  geo: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  /** An object relationship */
  manufacturerOrg?: Maybe<Organization>;
  manufacturerOrgId?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  organizationFunction: Scalars['String'];
  /** An object relationship */
  originDestinationAgentOrg?: Maybe<Organization>;
  originDestinationAgentOrgId?: Maybe<Scalars['uuid']>;
  shippingId: Scalars['Int'];
  /** An object relationship */
  smartContract?: Maybe<SmartContract>;
  smartContractId?: Maybe<Scalars['uuid']>;
  status: Scalars['String'];
  /** An object relationship */
  toShippingCarrierOrg?: Maybe<Organization>;
  toShippingCarrierOrgId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  waypointC2c?: Maybe<Scalars['String']>;
  waypointCt?: Maybe<Scalars['String']>;
  waypointNumber: Scalars['Int'];
  waypointPoi?: Maybe<Scalars['String']>;
  waypointTotal: Scalars['Int'];
};

/** aggregated selection of "supply_chain_waypoint" */
export type SupplyChainWaypointAggregate = {
  __typename?: 'SupplyChainWaypointAggregate';
  aggregate?: Maybe<SupplyChainWaypointAggregateFields>;
  nodes: Array<SupplyChainWaypoint>;
};

/** aggregate fields of "supply_chain_waypoint" */
export type SupplyChainWaypointAggregateFields = {
  __typename?: 'SupplyChainWaypointAggregateFields';
  avg?: Maybe<SupplyChainWaypointAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SupplyChainWaypointMaxFields>;
  min?: Maybe<SupplyChainWaypointMinFields>;
  stddev?: Maybe<SupplyChainWaypointStddevFields>;
  stddevPop?: Maybe<SupplyChainWaypointStddevPopFields>;
  stddevSamp?: Maybe<SupplyChainWaypointStddevSampFields>;
  sum?: Maybe<SupplyChainWaypointSumFields>;
  varPop?: Maybe<SupplyChainWaypointVarPopFields>;
  varSamp?: Maybe<SupplyChainWaypointVarSampFields>;
  variance?: Maybe<SupplyChainWaypointVarianceFields>;
};

/** aggregate fields of "supply_chain_waypoint" */
export type SupplyChainWaypointAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SupplyChainWaypointSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SupplyChainWaypointAvgFields = {
  __typename?: 'SupplyChainWaypointAvgFields';
  shippingId?: Maybe<Scalars['Float']>;
  waypointNumber?: Maybe<Scalars['Float']>;
  waypointTotal?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "supply_chain_waypoint". All fields are combined with a logical 'AND'. */
export type SupplyChainWaypointBoolExp = {
  _and?: InputMaybe<Array<SupplyChainWaypointBoolExp>>;
  _not?: InputMaybe<SupplyChainWaypointBoolExp>;
  _or?: InputMaybe<Array<SupplyChainWaypointBoolExp>>;
  actualProcessTime?: InputMaybe<DateComparisonExp>;
  actualQueueTime?: InputMaybe<DateComparisonExp>;
  avgProcessTime?: InputMaybe<DateComparisonExp>;
  avgQueueTime?: InputMaybe<DateComparisonExp>;
  consigneeOrg?: InputMaybe<OrganizationBoolExp>;
  consigneeOrgId?: InputMaybe<UuidComparisonExp>;
  consignorOrg?: InputMaybe<OrganizationBoolExp>;
  consignorOrgId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  customsOrg?: InputMaybe<OrganizationBoolExp>;
  customsOrgId?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  freightForwarderOrg?: InputMaybe<OrganizationBoolExp>;
  freightForwarderOrgId?: InputMaybe<UuidComparisonExp>;
  fromShippingCarrierOrg?: InputMaybe<OrganizationBoolExp>;
  fromShippingCarrierOrgId?: InputMaybe<UuidComparisonExp>;
  geo?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  latitude?: InputMaybe<StringComparisonExp>;
  longitude?: InputMaybe<StringComparisonExp>;
  manufacturerOrg?: InputMaybe<OrganizationBoolExp>;
  manufacturerOrgId?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organizationFunction?: InputMaybe<StringComparisonExp>;
  originDestinationAgentOrg?: InputMaybe<OrganizationBoolExp>;
  originDestinationAgentOrgId?: InputMaybe<UuidComparisonExp>;
  shippingId?: InputMaybe<IntComparisonExp>;
  smartContract?: InputMaybe<SmartContractBoolExp>;
  smartContractId?: InputMaybe<UuidComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  toShippingCarrierOrg?: InputMaybe<OrganizationBoolExp>;
  toShippingCarrierOrgId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  waypointC2c?: InputMaybe<StringComparisonExp>;
  waypointCt?: InputMaybe<StringComparisonExp>;
  waypointNumber?: InputMaybe<IntComparisonExp>;
  waypointPoi?: InputMaybe<StringComparisonExp>;
  waypointTotal?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "supply_chain_waypoint" */
export enum SupplyChainWaypointConstraint {
  /** unique or primary key constraint on columns "id" */
  SupplyChainWaypointsPkey = 'supply_chain_waypoints_pkey',
}

/** input type for incrementing numeric columns in table "supply_chain_waypoint" */
export type SupplyChainWaypointIncInput = {
  shippingId?: InputMaybe<Scalars['Int']>;
  waypointNumber?: InputMaybe<Scalars['Int']>;
  waypointTotal?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "supply_chain_waypoint" */
export type SupplyChainWaypointInsertInput = {
  actualProcessTime?: InputMaybe<Scalars['date']>;
  actualQueueTime?: InputMaybe<Scalars['date']>;
  avgProcessTime?: InputMaybe<Scalars['date']>;
  avgQueueTime?: InputMaybe<Scalars['date']>;
  consigneeOrg?: InputMaybe<OrganizationObjRelInsertInput>;
  consigneeOrgId?: InputMaybe<Scalars['uuid']>;
  consignorOrg?: InputMaybe<OrganizationObjRelInsertInput>;
  consignorOrgId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customsOrg?: InputMaybe<OrganizationObjRelInsertInput>;
  customsOrgId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  freightForwarderOrg?: InputMaybe<OrganizationObjRelInsertInput>;
  freightForwarderOrgId?: InputMaybe<Scalars['uuid']>;
  fromShippingCarrierOrg?: InputMaybe<OrganizationObjRelInsertInput>;
  fromShippingCarrierOrgId?: InputMaybe<Scalars['uuid']>;
  geo?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  manufacturerOrg?: InputMaybe<OrganizationObjRelInsertInput>;
  manufacturerOrgId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organizationFunction?: InputMaybe<Scalars['String']>;
  originDestinationAgentOrg?: InputMaybe<OrganizationObjRelInsertInput>;
  originDestinationAgentOrgId?: InputMaybe<Scalars['uuid']>;
  shippingId?: InputMaybe<Scalars['Int']>;
  smartContract?: InputMaybe<SmartContractObjRelInsertInput>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  toShippingCarrierOrg?: InputMaybe<OrganizationObjRelInsertInput>;
  toShippingCarrierOrgId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  waypointC2c?: InputMaybe<Scalars['String']>;
  waypointCt?: InputMaybe<Scalars['String']>;
  waypointNumber?: InputMaybe<Scalars['Int']>;
  waypointPoi?: InputMaybe<Scalars['String']>;
  waypointTotal?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type SupplyChainWaypointMaxFields = {
  __typename?: 'SupplyChainWaypointMaxFields';
  actualProcessTime?: Maybe<Scalars['date']>;
  actualQueueTime?: Maybe<Scalars['date']>;
  avgProcessTime?: Maybe<Scalars['date']>;
  avgQueueTime?: Maybe<Scalars['date']>;
  consigneeOrgId?: Maybe<Scalars['uuid']>;
  consignorOrgId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customsOrgId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  freightForwarderOrgId?: Maybe<Scalars['uuid']>;
  fromShippingCarrierOrgId?: Maybe<Scalars['uuid']>;
  geo?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  manufacturerOrgId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationFunction?: Maybe<Scalars['String']>;
  originDestinationAgentOrgId?: Maybe<Scalars['uuid']>;
  shippingId?: Maybe<Scalars['Int']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  toShippingCarrierOrgId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  waypointC2c?: Maybe<Scalars['String']>;
  waypointCt?: Maybe<Scalars['String']>;
  waypointNumber?: Maybe<Scalars['Int']>;
  waypointPoi?: Maybe<Scalars['String']>;
  waypointTotal?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SupplyChainWaypointMinFields = {
  __typename?: 'SupplyChainWaypointMinFields';
  actualProcessTime?: Maybe<Scalars['date']>;
  actualQueueTime?: Maybe<Scalars['date']>;
  avgProcessTime?: Maybe<Scalars['date']>;
  avgQueueTime?: Maybe<Scalars['date']>;
  consigneeOrgId?: Maybe<Scalars['uuid']>;
  consignorOrgId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customsOrgId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  freightForwarderOrgId?: Maybe<Scalars['uuid']>;
  fromShippingCarrierOrgId?: Maybe<Scalars['uuid']>;
  geo?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  manufacturerOrgId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationFunction?: Maybe<Scalars['String']>;
  originDestinationAgentOrgId?: Maybe<Scalars['uuid']>;
  shippingId?: Maybe<Scalars['Int']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  toShippingCarrierOrgId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  waypointC2c?: Maybe<Scalars['String']>;
  waypointCt?: Maybe<Scalars['String']>;
  waypointNumber?: Maybe<Scalars['Int']>;
  waypointPoi?: Maybe<Scalars['String']>;
  waypointTotal?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "supply_chain_waypoint" */
export type SupplyChainWaypointMutationResponse = {
  __typename?: 'SupplyChainWaypointMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SupplyChainWaypoint>;
};

/** on_conflict condition type for table "supply_chain_waypoint" */
export type SupplyChainWaypointOnConflict = {
  constraint: SupplyChainWaypointConstraint;
  updateColumns?: Array<SupplyChainWaypointUpdateColumn>;
  where?: InputMaybe<SupplyChainWaypointBoolExp>;
};

/** Ordering options when selecting data from "supply_chain_waypoint". */
export type SupplyChainWaypointOrderBy = {
  actualProcessTime?: InputMaybe<OrderBy>;
  actualQueueTime?: InputMaybe<OrderBy>;
  avgProcessTime?: InputMaybe<OrderBy>;
  avgQueueTime?: InputMaybe<OrderBy>;
  consigneeOrg?: InputMaybe<OrganizationOrderBy>;
  consigneeOrgId?: InputMaybe<OrderBy>;
  consignorOrg?: InputMaybe<OrganizationOrderBy>;
  consignorOrgId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  customsOrg?: InputMaybe<OrganizationOrderBy>;
  customsOrgId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  freightForwarderOrg?: InputMaybe<OrganizationOrderBy>;
  freightForwarderOrgId?: InputMaybe<OrderBy>;
  fromShippingCarrierOrg?: InputMaybe<OrganizationOrderBy>;
  fromShippingCarrierOrgId?: InputMaybe<OrderBy>;
  geo?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  manufacturerOrg?: InputMaybe<OrganizationOrderBy>;
  manufacturerOrgId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationFunction?: InputMaybe<OrderBy>;
  originDestinationAgentOrg?: InputMaybe<OrganizationOrderBy>;
  originDestinationAgentOrgId?: InputMaybe<OrderBy>;
  shippingId?: InputMaybe<OrderBy>;
  smartContract?: InputMaybe<SmartContractOrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  toShippingCarrierOrg?: InputMaybe<OrganizationOrderBy>;
  toShippingCarrierOrgId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  waypointC2c?: InputMaybe<OrderBy>;
  waypointCt?: InputMaybe<OrderBy>;
  waypointNumber?: InputMaybe<OrderBy>;
  waypointPoi?: InputMaybe<OrderBy>;
  waypointTotal?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: supply_chain_waypoint */
export type SupplyChainWaypointPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "supply_chain_waypoint" */
export enum SupplyChainWaypointSelectColumn {
  /** column name */
  ActualProcessTime = 'actualProcessTime',
  /** column name */
  ActualQueueTime = 'actualQueueTime',
  /** column name */
  AvgProcessTime = 'avgProcessTime',
  /** column name */
  AvgQueueTime = 'avgQueueTime',
  /** column name */
  ConsigneeOrgId = 'consigneeOrgId',
  /** column name */
  ConsignorOrgId = 'consignorOrgId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomsOrgId = 'customsOrgId',
  /** column name */
  Description = 'description',
  /** column name */
  FreightForwarderOrgId = 'freightForwarderOrgId',
  /** column name */
  FromShippingCarrierOrgId = 'fromShippingCarrierOrgId',
  /** column name */
  Geo = 'geo',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  ManufacturerOrgId = 'manufacturerOrgId',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationFunction = 'organizationFunction',
  /** column name */
  OriginDestinationAgentOrgId = 'originDestinationAgentOrgId',
  /** column name */
  ShippingId = 'shippingId',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  Status = 'status',
  /** column name */
  ToShippingCarrierOrgId = 'toShippingCarrierOrgId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WaypointC2c = 'waypointC2c',
  /** column name */
  WaypointCt = 'waypointCt',
  /** column name */
  WaypointNumber = 'waypointNumber',
  /** column name */
  WaypointPoi = 'waypointPoi',
  /** column name */
  WaypointTotal = 'waypointTotal',
}

/** input type for updating data in table "supply_chain_waypoint" */
export type SupplyChainWaypointSetInput = {
  actualProcessTime?: InputMaybe<Scalars['date']>;
  actualQueueTime?: InputMaybe<Scalars['date']>;
  avgProcessTime?: InputMaybe<Scalars['date']>;
  avgQueueTime?: InputMaybe<Scalars['date']>;
  consigneeOrgId?: InputMaybe<Scalars['uuid']>;
  consignorOrgId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customsOrgId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  freightForwarderOrgId?: InputMaybe<Scalars['uuid']>;
  fromShippingCarrierOrgId?: InputMaybe<Scalars['uuid']>;
  geo?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  manufacturerOrgId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organizationFunction?: InputMaybe<Scalars['String']>;
  originDestinationAgentOrgId?: InputMaybe<Scalars['uuid']>;
  shippingId?: InputMaybe<Scalars['Int']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  toShippingCarrierOrgId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  waypointC2c?: InputMaybe<Scalars['String']>;
  waypointCt?: InputMaybe<Scalars['String']>;
  waypointNumber?: InputMaybe<Scalars['Int']>;
  waypointPoi?: InputMaybe<Scalars['String']>;
  waypointTotal?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SupplyChainWaypointStddevFields = {
  __typename?: 'SupplyChainWaypointStddevFields';
  shippingId?: Maybe<Scalars['Float']>;
  waypointNumber?: Maybe<Scalars['Float']>;
  waypointTotal?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type SupplyChainWaypointStddevPopFields = {
  __typename?: 'SupplyChainWaypointStddevPopFields';
  shippingId?: Maybe<Scalars['Float']>;
  waypointNumber?: Maybe<Scalars['Float']>;
  waypointTotal?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type SupplyChainWaypointStddevSampFields = {
  __typename?: 'SupplyChainWaypointStddevSampFields';
  shippingId?: Maybe<Scalars['Float']>;
  waypointNumber?: Maybe<Scalars['Float']>;
  waypointTotal?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "supply_chain_waypoint" */
export type SupplyChainWaypointStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SupplyChainWaypointStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SupplyChainWaypointStreamCursorValueInput = {
  actualProcessTime?: InputMaybe<Scalars['date']>;
  actualQueueTime?: InputMaybe<Scalars['date']>;
  avgProcessTime?: InputMaybe<Scalars['date']>;
  avgQueueTime?: InputMaybe<Scalars['date']>;
  consigneeOrgId?: InputMaybe<Scalars['uuid']>;
  consignorOrgId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customsOrgId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  freightForwarderOrgId?: InputMaybe<Scalars['uuid']>;
  fromShippingCarrierOrgId?: InputMaybe<Scalars['uuid']>;
  geo?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  manufacturerOrgId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organizationFunction?: InputMaybe<Scalars['String']>;
  originDestinationAgentOrgId?: InputMaybe<Scalars['uuid']>;
  shippingId?: InputMaybe<Scalars['Int']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  toShippingCarrierOrgId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  waypointC2c?: InputMaybe<Scalars['String']>;
  waypointCt?: InputMaybe<Scalars['String']>;
  waypointNumber?: InputMaybe<Scalars['Int']>;
  waypointPoi?: InputMaybe<Scalars['String']>;
  waypointTotal?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type SupplyChainWaypointSumFields = {
  __typename?: 'SupplyChainWaypointSumFields';
  shippingId?: Maybe<Scalars['Int']>;
  waypointNumber?: Maybe<Scalars['Int']>;
  waypointTotal?: Maybe<Scalars['Int']>;
};

/** update columns of table "supply_chain_waypoint" */
export enum SupplyChainWaypointUpdateColumn {
  /** column name */
  ActualProcessTime = 'actualProcessTime',
  /** column name */
  ActualQueueTime = 'actualQueueTime',
  /** column name */
  AvgProcessTime = 'avgProcessTime',
  /** column name */
  AvgQueueTime = 'avgQueueTime',
  /** column name */
  ConsigneeOrgId = 'consigneeOrgId',
  /** column name */
  ConsignorOrgId = 'consignorOrgId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomsOrgId = 'customsOrgId',
  /** column name */
  Description = 'description',
  /** column name */
  FreightForwarderOrgId = 'freightForwarderOrgId',
  /** column name */
  FromShippingCarrierOrgId = 'fromShippingCarrierOrgId',
  /** column name */
  Geo = 'geo',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  ManufacturerOrgId = 'manufacturerOrgId',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationFunction = 'organizationFunction',
  /** column name */
  OriginDestinationAgentOrgId = 'originDestinationAgentOrgId',
  /** column name */
  ShippingId = 'shippingId',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  Status = 'status',
  /** column name */
  ToShippingCarrierOrgId = 'toShippingCarrierOrgId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WaypointC2c = 'waypointC2c',
  /** column name */
  WaypointCt = 'waypointCt',
  /** column name */
  WaypointNumber = 'waypointNumber',
  /** column name */
  WaypointPoi = 'waypointPoi',
  /** column name */
  WaypointTotal = 'waypointTotal',
}

export type SupplyChainWaypointUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SupplyChainWaypointIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SupplyChainWaypointSetInput>;
  /** filter the rows which have to be updated */
  where: SupplyChainWaypointBoolExp;
};

/** aggregate varPop on columns */
export type SupplyChainWaypointVarPopFields = {
  __typename?: 'SupplyChainWaypointVarPopFields';
  shippingId?: Maybe<Scalars['Float']>;
  waypointNumber?: Maybe<Scalars['Float']>;
  waypointTotal?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type SupplyChainWaypointVarSampFields = {
  __typename?: 'SupplyChainWaypointVarSampFields';
  shippingId?: Maybe<Scalars['Float']>;
  waypointNumber?: Maybe<Scalars['Float']>;
  waypointTotal?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SupplyChainWaypointVarianceFields = {
  __typename?: 'SupplyChainWaypointVarianceFields';
  shippingId?: Maybe<Scalars['Float']>;
  waypointNumber?: Maybe<Scalars['Float']>;
  waypointTotal?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "trade" */
export type Trade = {
  __typename?: 'Trade';
  /** An array relationship */
  asks: Array<Ask>;
  /** An aggregate relationship */
  asksAggregate: AskAggregate;
  /** An object relationship */
  clearinghouse: Clearinghouse;
  clearinghouseId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  kind: TradeKind;
  name: Scalars['String'];
  tradeKindId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "trade" */
export type TradeAsksArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

/** columns and relationships of "trade" */
export type TradeAsksAggregateArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

/** aggregated selection of "trade" */
export type TradeAggregate = {
  __typename?: 'TradeAggregate';
  aggregate?: Maybe<TradeAggregateFields>;
  nodes: Array<Trade>;
};

export type TradeAggregateBoolExp = {
  bool_and?: InputMaybe<TradeAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<TradeAggregateBoolExpBool_Or>;
  count?: InputMaybe<TradeAggregateBoolExpCount>;
};

/** aggregate fields of "trade" */
export type TradeAggregateFields = {
  __typename?: 'TradeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<TradeMaxFields>;
  min?: Maybe<TradeMinFields>;
};

/** aggregate fields of "trade" */
export type TradeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TradeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trade" */
export type TradeAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TradeMaxOrderBy>;
  min?: InputMaybe<TradeMinOrderBy>;
};

/** input type for inserting array relation for remote table "trade" */
export type TradeArrRelInsertInput = {
  data: Array<TradeInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TradeOnConflict>;
};

/** Boolean expression to filter rows from the table "trade". All fields are combined with a logical 'AND'. */
export type TradeBoolExp = {
  _and?: InputMaybe<Array<TradeBoolExp>>;
  _not?: InputMaybe<TradeBoolExp>;
  _or?: InputMaybe<Array<TradeBoolExp>>;
  asks?: InputMaybe<AskBoolExp>;
  asksAggregate?: InputMaybe<AskAggregateBoolExp>;
  clearinghouse?: InputMaybe<ClearinghouseBoolExp>;
  clearinghouseId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  kind?: InputMaybe<TradeKindBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  tradeKindId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "trade" */
export enum TradeConstraint {
  /** unique or primary key constraint on columns "id" */
  TradePkey = 'trade_pkey',
  /** unique or primary key constraint on columns "id" */
  TradeXidKey = 'trade_xid_key',
}

/** input type for inserting data into table "trade" */
export type TradeInsertInput = {
  asks?: InputMaybe<AskArrRelInsertInput>;
  clearinghouse?: InputMaybe<ClearinghouseObjRelInsertInput>;
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<TradeKindObjRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  tradeKindId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "trade_kind" */
export type TradeKind = {
  __typename?: 'TradeKind';
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  exchanges: Array<Exchange>;
  /** An aggregate relationship */
  exchangesAggregate: ExchangeAggregate;
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An array relationship */
  trades: Array<Trade>;
  /** An aggregate relationship */
  tradesAggregate: TradeAggregate;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "trade_kind" */
export type TradeKindExchangesArgs = {
  distinctOn?: InputMaybe<Array<ExchangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeOrderBy>>;
  where?: InputMaybe<ExchangeBoolExp>;
};

/** columns and relationships of "trade_kind" */
export type TradeKindExchangesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExchangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeOrderBy>>;
  where?: InputMaybe<ExchangeBoolExp>;
};

/** columns and relationships of "trade_kind" */
export type TradeKindTradesArgs = {
  distinctOn?: InputMaybe<Array<TradeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TradeOrderBy>>;
  where?: InputMaybe<TradeBoolExp>;
};

/** columns and relationships of "trade_kind" */
export type TradeKindTradesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TradeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TradeOrderBy>>;
  where?: InputMaybe<TradeBoolExp>;
};

/** aggregated selection of "trade_kind" */
export type TradeKindAggregate = {
  __typename?: 'TradeKindAggregate';
  aggregate?: Maybe<TradeKindAggregateFields>;
  nodes: Array<TradeKind>;
};

/** aggregate fields of "trade_kind" */
export type TradeKindAggregateFields = {
  __typename?: 'TradeKindAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<TradeKindMaxFields>;
  min?: Maybe<TradeKindMinFields>;
};

/** aggregate fields of "trade_kind" */
export type TradeKindAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TradeKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "trade_kind". All fields are combined with a logical 'AND'. */
export type TradeKindBoolExp = {
  _and?: InputMaybe<Array<TradeKindBoolExp>>;
  _not?: InputMaybe<TradeKindBoolExp>;
  _or?: InputMaybe<Array<TradeKindBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  exchanges?: InputMaybe<ExchangeBoolExp>;
  exchangesAggregate?: InputMaybe<ExchangeAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  trades?: InputMaybe<TradeBoolExp>;
  tradesAggregate?: InputMaybe<TradeAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "trade_kind" */
export enum TradeKindConstraint {
  /** unique or primary key constraint on columns "id" */
  TradeKindPkey = 'trade_kind_pkey',
  /** unique or primary key constraint on columns "id" */
  TradeKindXidKey = 'trade_kind_xid_key',
}

/** input type for inserting data into table "trade_kind" */
export type TradeKindInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  exchanges?: InputMaybe<ExchangeArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  trades?: InputMaybe<TradeArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TradeKindMaxFields = {
  __typename?: 'TradeKindMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type TradeKindMinFields = {
  __typename?: 'TradeKindMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "trade_kind" */
export type TradeKindMutationResponse = {
  __typename?: 'TradeKindMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TradeKind>;
};

/** input type for inserting object relation for remote table "trade_kind" */
export type TradeKindObjRelInsertInput = {
  data: TradeKindInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TradeKindOnConflict>;
};

/** on_conflict condition type for table "trade_kind" */
export type TradeKindOnConflict = {
  constraint: TradeKindConstraint;
  updateColumns?: Array<TradeKindUpdateColumn>;
  where?: InputMaybe<TradeKindBoolExp>;
};

/** Ordering options when selecting data from "trade_kind". */
export type TradeKindOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  exchangesAggregate?: InputMaybe<ExchangeAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  tradesAggregate?: InputMaybe<TradeAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: trade_kind */
export type TradeKindPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "trade_kind" */
export enum TradeKindSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "trade_kind" */
export type TradeKindSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "trade_kind" */
export type TradeKindStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TradeKindStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TradeKindStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "trade_kind" */
export enum TradeKindUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type TradeKindUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TradeKindSetInput>;
  /** filter the rows which have to be updated */
  where: TradeKindBoolExp;
};

/** aggregate max on columns */
export type TradeMaxFields = {
  __typename?: 'TradeMaxFields';
  clearinghouseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  tradeKindId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "trade" */
export type TradeMaxOrderBy = {
  clearinghouseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  tradeKindId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TradeMinFields = {
  __typename?: 'TradeMinFields';
  clearinghouseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  tradeKindId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "trade" */
export type TradeMinOrderBy = {
  clearinghouseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  tradeKindId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "trade" */
export type TradeMutationResponse = {
  __typename?: 'TradeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Trade>;
};

/** input type for inserting object relation for remote table "trade" */
export type TradeObjRelInsertInput = {
  data: TradeInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TradeOnConflict>;
};

/** on_conflict condition type for table "trade" */
export type TradeOnConflict = {
  constraint: TradeConstraint;
  updateColumns?: Array<TradeUpdateColumn>;
  where?: InputMaybe<TradeBoolExp>;
};

/** Ordering options when selecting data from "trade". */
export type TradeOrderBy = {
  asksAggregate?: InputMaybe<AskAggregateOrderBy>;
  clearinghouse?: InputMaybe<ClearinghouseOrderBy>;
  clearinghouseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  kind?: InputMaybe<TradeKindOrderBy>;
  name?: InputMaybe<OrderBy>;
  tradeKindId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: trade */
export type TradePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "trade" */
export enum TradeSelectColumn {
  /** column name */
  ClearinghouseId = 'clearinghouseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  TradeKindId = 'tradeKindId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "tradeAggregateBoolExpBool_andArgumentsColumns" columns of table "trade" */
export enum TradeSelectColumnTradeAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "tradeAggregateBoolExpBool_orArgumentsColumns" columns of table "trade" */
export enum TradeSelectColumnTradeAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "trade" */
export type TradeSetInput = {
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  tradeKindId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "trade" */
export type TradeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TradeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TradeStreamCursorValueInput = {
  clearinghouseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  tradeKindId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "trade" */
export enum TradeUpdateColumn {
  /** column name */
  ClearinghouseId = 'clearinghouseId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  TradeKindId = 'tradeKindId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type TradeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TradeSetInput>;
  /** filter the rows which have to be updated */
  where: TradeBoolExp;
};

/** columns and relationships of "transaction" */
export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['bigint'];
  confirmation?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  currency?: Maybe<Currency>;
  currencyId: Scalars['uuid'];
  description: Scalars['String'];
  destTag?: Maybe<Scalars['String']>;
  /** An object relationship */
  fromWallet?: Maybe<Wallet>;
  fromWalletId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  kind: TransactionKind;
  networkFee: Scalars['float8'];
  /** An object relationship */
  payment: Payment;
  paymentId: Scalars['uuid'];
  /** An object relationship */
  smartContract: SmartContract;
  smartContractId: Scalars['uuid'];
  /** An object relationship */
  status: Status;
  statusId: Scalars['uuid'];
  /** An object relationship */
  toWallet?: Maybe<Wallet>;
  toWalletId?: Maybe<Scalars['uuid']>;
  transactionKindId: Scalars['uuid'];
  txnId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "transaction" */
export type TransactionAggregate = {
  __typename?: 'TransactionAggregate';
  aggregate?: Maybe<TransactionAggregateFields>;
  nodes: Array<Transaction>;
};

export type TransactionAggregateBoolExp = {
  avg?: InputMaybe<TransactionAggregateBoolExpAvg>;
  bool_and?: InputMaybe<TransactionAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<TransactionAggregateBoolExpBool_Or>;
  corr?: InputMaybe<TransactionAggregateBoolExpCorr>;
  count?: InputMaybe<TransactionAggregateBoolExpCount>;
  covar_samp?: InputMaybe<TransactionAggregateBoolExpCovar_Samp>;
  max?: InputMaybe<TransactionAggregateBoolExpMax>;
  min?: InputMaybe<TransactionAggregateBoolExpMin>;
  stddev_samp?: InputMaybe<TransactionAggregateBoolExpStddev_Samp>;
  sum?: InputMaybe<TransactionAggregateBoolExpSum>;
  var_samp?: InputMaybe<TransactionAggregateBoolExpVar_Samp>;
};

/** aggregate fields of "transaction" */
export type TransactionAggregateFields = {
  __typename?: 'TransactionAggregateFields';
  avg?: Maybe<TransactionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TransactionMaxFields>;
  min?: Maybe<TransactionMinFields>;
  stddev?: Maybe<TransactionStddevFields>;
  stddevPop?: Maybe<TransactionStddevPopFields>;
  stddevSamp?: Maybe<TransactionStddevSampFields>;
  sum?: Maybe<TransactionSumFields>;
  varPop?: Maybe<TransactionVarPopFields>;
  varSamp?: Maybe<TransactionVarSampFields>;
  variance?: Maybe<TransactionVarianceFields>;
};

/** aggregate fields of "transaction" */
export type TransactionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TransactionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "transaction" */
export type TransactionAggregateOrderBy = {
  avg?: InputMaybe<TransactionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TransactionMaxOrderBy>;
  min?: InputMaybe<TransactionMinOrderBy>;
  stddev?: InputMaybe<TransactionStddevOrderBy>;
  stddevPop?: InputMaybe<TransactionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<TransactionStddevSampOrderBy>;
  sum?: InputMaybe<TransactionSumOrderBy>;
  varPop?: InputMaybe<TransactionVarPopOrderBy>;
  varSamp?: InputMaybe<TransactionVarSampOrderBy>;
  variance?: InputMaybe<TransactionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "transaction" */
export type TransactionArrRelInsertInput = {
  data: Array<TransactionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TransactionOnConflict>;
};

/** aggregate avg on columns */
export type TransactionAvgFields = {
  __typename?: 'TransactionAvgFields';
  amount?: Maybe<Scalars['Float']>;
  networkFee?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "transaction" */
export type TransactionAvgOrderBy = {
  amount?: InputMaybe<OrderBy>;
  networkFee?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "transaction". All fields are combined with a logical 'AND'. */
export type TransactionBoolExp = {
  _and?: InputMaybe<Array<TransactionBoolExp>>;
  _not?: InputMaybe<TransactionBoolExp>;
  _or?: InputMaybe<Array<TransactionBoolExp>>;
  amount?: InputMaybe<BigintComparisonExp>;
  confirmation?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrencyBoolExp>;
  currencyId?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  destTag?: InputMaybe<StringComparisonExp>;
  fromWallet?: InputMaybe<WalletBoolExp>;
  fromWalletId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  kind?: InputMaybe<TransactionKindBoolExp>;
  networkFee?: InputMaybe<Float8ComparisonExp>;
  payment?: InputMaybe<PaymentBoolExp>;
  paymentId?: InputMaybe<UuidComparisonExp>;
  smartContract?: InputMaybe<SmartContractBoolExp>;
  smartContractId?: InputMaybe<UuidComparisonExp>;
  status?: InputMaybe<StatusBoolExp>;
  statusId?: InputMaybe<UuidComparisonExp>;
  toWallet?: InputMaybe<WalletBoolExp>;
  toWalletId?: InputMaybe<UuidComparisonExp>;
  transactionKindId?: InputMaybe<UuidComparisonExp>;
  txnId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "transaction" */
export enum TransactionConstraint {
  /** unique or primary key constraint on columns "id" */
  TransactionPkey = 'transaction_pkey',
  /** unique or primary key constraint on columns "id" */
  TransactionXidKey = 'transaction_xid_key',
}

/** input type for incrementing numeric columns in table "transaction" */
export type TransactionIncInput = {
  amount?: InputMaybe<Scalars['bigint']>;
  networkFee?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "transaction" */
export type TransactionInsertInput = {
  amount?: InputMaybe<Scalars['bigint']>;
  confirmation?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<CurrencyObjRelInsertInput>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  destTag?: InputMaybe<Scalars['String']>;
  fromWallet?: InputMaybe<WalletObjRelInsertInput>;
  fromWalletId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<TransactionKindObjRelInsertInput>;
  networkFee?: InputMaybe<Scalars['float8']>;
  payment?: InputMaybe<PaymentObjRelInsertInput>;
  paymentId?: InputMaybe<Scalars['uuid']>;
  smartContract?: InputMaybe<SmartContractObjRelInsertInput>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<StatusObjRelInsertInput>;
  statusId?: InputMaybe<Scalars['uuid']>;
  toWallet?: InputMaybe<WalletObjRelInsertInput>;
  toWalletId?: InputMaybe<Scalars['uuid']>;
  transactionKindId?: InputMaybe<Scalars['uuid']>;
  txnId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "transaction_kind" */
export type TransactionKind = {
  __typename?: 'TransactionKind';
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  smartContract: SmartContract;
  smartContractId: Scalars['uuid'];
  subIndustry1Id?: Maybe<Scalars['uuid']>;
  subIndustry2Id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  subindustry1?: Maybe<Subindustry>;
  /** An object relationship */
  subindustry2?: Maybe<Subindustry>;
  /** An array relationship */
  transactions: Array<Transaction>;
  /** An aggregate relationship */
  transactionsAggregate: TransactionAggregate;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "transaction_kind" */
export type TransactionKindTransactionsArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

/** columns and relationships of "transaction_kind" */
export type TransactionKindTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

/** aggregated selection of "transaction_kind" */
export type TransactionKindAggregate = {
  __typename?: 'TransactionKindAggregate';
  aggregate?: Maybe<TransactionKindAggregateFields>;
  nodes: Array<TransactionKind>;
};

export type TransactionKindAggregateBoolExp = {
  bool_and?: InputMaybe<TransactionKindAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<TransactionKindAggregateBoolExpBool_Or>;
  count?: InputMaybe<TransactionKindAggregateBoolExpCount>;
};

/** aggregate fields of "transaction_kind" */
export type TransactionKindAggregateFields = {
  __typename?: 'TransactionKindAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<TransactionKindMaxFields>;
  min?: Maybe<TransactionKindMinFields>;
};

/** aggregate fields of "transaction_kind" */
export type TransactionKindAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TransactionKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "transaction_kind" */
export type TransactionKindAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TransactionKindMaxOrderBy>;
  min?: InputMaybe<TransactionKindMinOrderBy>;
};

/** input type for inserting array relation for remote table "transaction_kind" */
export type TransactionKindArrRelInsertInput = {
  data: Array<TransactionKindInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TransactionKindOnConflict>;
};

/** Boolean expression to filter rows from the table "transaction_kind". All fields are combined with a logical 'AND'. */
export type TransactionKindBoolExp = {
  _and?: InputMaybe<Array<TransactionKindBoolExp>>;
  _not?: InputMaybe<TransactionKindBoolExp>;
  _or?: InputMaybe<Array<TransactionKindBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  smartContract?: InputMaybe<SmartContractBoolExp>;
  smartContractId?: InputMaybe<UuidComparisonExp>;
  subIndustry1Id?: InputMaybe<UuidComparisonExp>;
  subIndustry2Id?: InputMaybe<UuidComparisonExp>;
  subindustry1?: InputMaybe<SubindustryBoolExp>;
  subindustry2?: InputMaybe<SubindustryBoolExp>;
  transactions?: InputMaybe<TransactionBoolExp>;
  transactionsAggregate?: InputMaybe<TransactionAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "transaction_kind" */
export enum TransactionKindConstraint {
  /** unique or primary key constraint on columns "id" */
  TransactionKindPkey = 'transaction_kind_pkey',
  /** unique or primary key constraint on columns "id" */
  TransactionKindXidKey = 'transaction_kind_xid_key',
}

/** input type for inserting data into table "transaction_kind" */
export type TransactionKindInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  smartContract?: InputMaybe<SmartContractObjRelInsertInput>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  subIndustry1Id?: InputMaybe<Scalars['uuid']>;
  subIndustry2Id?: InputMaybe<Scalars['uuid']>;
  subindustry1?: InputMaybe<SubindustryObjRelInsertInput>;
  subindustry2?: InputMaybe<SubindustryObjRelInsertInput>;
  transactions?: InputMaybe<TransactionArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TransactionKindMaxFields = {
  __typename?: 'TransactionKindMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  subIndustry1Id?: Maybe<Scalars['uuid']>;
  subIndustry2Id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "transaction_kind" */
export type TransactionKindMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  subIndustry1Id?: InputMaybe<OrderBy>;
  subIndustry2Id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TransactionKindMinFields = {
  __typename?: 'TransactionKindMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  subIndustry1Id?: Maybe<Scalars['uuid']>;
  subIndustry2Id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "transaction_kind" */
export type TransactionKindMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  subIndustry1Id?: InputMaybe<OrderBy>;
  subIndustry2Id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "transaction_kind" */
export type TransactionKindMutationResponse = {
  __typename?: 'TransactionKindMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TransactionKind>;
};

/** input type for inserting object relation for remote table "transaction_kind" */
export type TransactionKindObjRelInsertInput = {
  data: TransactionKindInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TransactionKindOnConflict>;
};

/** on_conflict condition type for table "transaction_kind" */
export type TransactionKindOnConflict = {
  constraint: TransactionKindConstraint;
  updateColumns?: Array<TransactionKindUpdateColumn>;
  where?: InputMaybe<TransactionKindBoolExp>;
};

/** Ordering options when selecting data from "transaction_kind". */
export type TransactionKindOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  smartContract?: InputMaybe<SmartContractOrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  subIndustry1Id?: InputMaybe<OrderBy>;
  subIndustry2Id?: InputMaybe<OrderBy>;
  subindustry1?: InputMaybe<SubindustryOrderBy>;
  subindustry2?: InputMaybe<SubindustryOrderBy>;
  transactionsAggregate?: InputMaybe<TransactionAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: transaction_kind */
export type TransactionKindPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "transaction_kind" */
export enum TransactionKindSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  SubIndustry1Id = 'subIndustry1Id',
  /** column name */
  SubIndustry2Id = 'subIndustry2Id',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "transactionKindAggregateBoolExpBool_andArgumentsColumns" columns of table "transaction_kind" */
export enum TransactionKindSelectColumnTransactionKindAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "transactionKindAggregateBoolExpBool_orArgumentsColumns" columns of table "transaction_kind" */
export enum TransactionKindSelectColumnTransactionKindAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "transaction_kind" */
export type TransactionKindSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  subIndustry1Id?: InputMaybe<Scalars['uuid']>;
  subIndustry2Id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "transaction_kind" */
export type TransactionKindStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TransactionKindStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TransactionKindStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  subIndustry1Id?: InputMaybe<Scalars['uuid']>;
  subIndustry2Id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "transaction_kind" */
export enum TransactionKindUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  SubIndustry1Id = 'subIndustry1Id',
  /** column name */
  SubIndustry2Id = 'subIndustry2Id',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type TransactionKindUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TransactionKindSetInput>;
  /** filter the rows which have to be updated */
  where: TransactionKindBoolExp;
};

/** aggregate max on columns */
export type TransactionMaxFields = {
  __typename?: 'TransactionMaxFields';
  amount?: Maybe<Scalars['bigint']>;
  confirmation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  destTag?: Maybe<Scalars['String']>;
  fromWalletId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  networkFee?: Maybe<Scalars['float8']>;
  paymentId?: Maybe<Scalars['uuid']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  statusId?: Maybe<Scalars['uuid']>;
  toWalletId?: Maybe<Scalars['uuid']>;
  transactionKindId?: Maybe<Scalars['uuid']>;
  txnId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "transaction" */
export type TransactionMaxOrderBy = {
  amount?: InputMaybe<OrderBy>;
  confirmation?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  destTag?: InputMaybe<OrderBy>;
  fromWalletId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  networkFee?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  toWalletId?: InputMaybe<OrderBy>;
  transactionKindId?: InputMaybe<OrderBy>;
  txnId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TransactionMinFields = {
  __typename?: 'TransactionMinFields';
  amount?: Maybe<Scalars['bigint']>;
  confirmation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  destTag?: Maybe<Scalars['String']>;
  fromWalletId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  networkFee?: Maybe<Scalars['float8']>;
  paymentId?: Maybe<Scalars['uuid']>;
  smartContractId?: Maybe<Scalars['uuid']>;
  statusId?: Maybe<Scalars['uuid']>;
  toWalletId?: Maybe<Scalars['uuid']>;
  transactionKindId?: Maybe<Scalars['uuid']>;
  txnId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "transaction" */
export type TransactionMinOrderBy = {
  amount?: InputMaybe<OrderBy>;
  confirmation?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  destTag?: InputMaybe<OrderBy>;
  fromWalletId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  networkFee?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  toWalletId?: InputMaybe<OrderBy>;
  transactionKindId?: InputMaybe<OrderBy>;
  txnId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "transaction" */
export type TransactionMutationResponse = {
  __typename?: 'TransactionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transaction>;
};

/** input type for inserting object relation for remote table "transaction" */
export type TransactionObjRelInsertInput = {
  data: TransactionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TransactionOnConflict>;
};

/** on_conflict condition type for table "transaction" */
export type TransactionOnConflict = {
  constraint: TransactionConstraint;
  updateColumns?: Array<TransactionUpdateColumn>;
  where?: InputMaybe<TransactionBoolExp>;
};

/** Ordering options when selecting data from "transaction". */
export type TransactionOrderBy = {
  amount?: InputMaybe<OrderBy>;
  confirmation?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrencyOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  destTag?: InputMaybe<OrderBy>;
  fromWallet?: InputMaybe<WalletOrderBy>;
  fromWalletId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  kind?: InputMaybe<TransactionKindOrderBy>;
  networkFee?: InputMaybe<OrderBy>;
  payment?: InputMaybe<PaymentOrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  smartContract?: InputMaybe<SmartContractOrderBy>;
  smartContractId?: InputMaybe<OrderBy>;
  status?: InputMaybe<StatusOrderBy>;
  statusId?: InputMaybe<OrderBy>;
  toWallet?: InputMaybe<WalletOrderBy>;
  toWalletId?: InputMaybe<OrderBy>;
  transactionKindId?: InputMaybe<OrderBy>;
  txnId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: transaction */
export type TransactionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "transaction" */
export enum TransactionSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  Confirmation = 'confirmation',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Description = 'description',
  /** column name */
  DestTag = 'destTag',
  /** column name */
  FromWalletId = 'fromWalletId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  NetworkFee = 'networkFee',
  /** column name */
  PaymentId = 'paymentId',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  StatusId = 'statusId',
  /** column name */
  ToWalletId = 'toWalletId',
  /** column name */
  TransactionKindId = 'transactionKindId',
  /** column name */
  TxnId = 'txnId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "transactionAggregateBoolExpAvgArgumentsColumns" columns of table "transaction" */
export enum TransactionSelectColumnTransactionAggregateBoolExpAvgArgumentsColumns {
  /** column name */
  NetworkFee = 'networkFee',
}

/** select "transactionAggregateBoolExpBool_andArgumentsColumns" columns of table "transaction" */
export enum TransactionSelectColumnTransactionAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "transactionAggregateBoolExpBool_orArgumentsColumns" columns of table "transaction" */
export enum TransactionSelectColumnTransactionAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "transactionAggregateBoolExpCorrArgumentsColumns" columns of table "transaction" */
export enum TransactionSelectColumnTransactionAggregateBoolExpCorrArgumentsColumns {
  /** column name */
  NetworkFee = 'networkFee',
}

/** select "transactionAggregateBoolExpCovar_sampArgumentsColumns" columns of table "transaction" */
export enum TransactionSelectColumnTransactionAggregateBoolExpCovar_SampArgumentsColumns {
  /** column name */
  NetworkFee = 'networkFee',
}

/** select "transactionAggregateBoolExpMaxArgumentsColumns" columns of table "transaction" */
export enum TransactionSelectColumnTransactionAggregateBoolExpMaxArgumentsColumns {
  /** column name */
  NetworkFee = 'networkFee',
}

/** select "transactionAggregateBoolExpMinArgumentsColumns" columns of table "transaction" */
export enum TransactionSelectColumnTransactionAggregateBoolExpMinArgumentsColumns {
  /** column name */
  NetworkFee = 'networkFee',
}

/** select "transactionAggregateBoolExpStddev_sampArgumentsColumns" columns of table "transaction" */
export enum TransactionSelectColumnTransactionAggregateBoolExpStddev_SampArgumentsColumns {
  /** column name */
  NetworkFee = 'networkFee',
}

/** select "transactionAggregateBoolExpSumArgumentsColumns" columns of table "transaction" */
export enum TransactionSelectColumnTransactionAggregateBoolExpSumArgumentsColumns {
  /** column name */
  NetworkFee = 'networkFee',
}

/** select "transactionAggregateBoolExpVar_sampArgumentsColumns" columns of table "transaction" */
export enum TransactionSelectColumnTransactionAggregateBoolExpVar_SampArgumentsColumns {
  /** column name */
  NetworkFee = 'networkFee',
}

/** input type for updating data in table "transaction" */
export type TransactionSetInput = {
  amount?: InputMaybe<Scalars['bigint']>;
  confirmation?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  destTag?: InputMaybe<Scalars['String']>;
  fromWalletId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  networkFee?: InputMaybe<Scalars['float8']>;
  paymentId?: InputMaybe<Scalars['uuid']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  statusId?: InputMaybe<Scalars['uuid']>;
  toWalletId?: InputMaybe<Scalars['uuid']>;
  transactionKindId?: InputMaybe<Scalars['uuid']>;
  txnId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TransactionStddevFields = {
  __typename?: 'TransactionStddevFields';
  amount?: Maybe<Scalars['Float']>;
  networkFee?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "transaction" */
export type TransactionStddevOrderBy = {
  amount?: InputMaybe<OrderBy>;
  networkFee?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type TransactionStddevPopFields = {
  __typename?: 'TransactionStddevPopFields';
  amount?: Maybe<Scalars['Float']>;
  networkFee?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "transaction" */
export type TransactionStddevPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
  networkFee?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type TransactionStddevSampFields = {
  __typename?: 'TransactionStddevSampFields';
  amount?: Maybe<Scalars['Float']>;
  networkFee?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "transaction" */
export type TransactionStddevSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
  networkFee?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "transaction" */
export type TransactionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TransactionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TransactionStreamCursorValueInput = {
  amount?: InputMaybe<Scalars['bigint']>;
  confirmation?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  destTag?: InputMaybe<Scalars['String']>;
  fromWalletId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  networkFee?: InputMaybe<Scalars['float8']>;
  paymentId?: InputMaybe<Scalars['uuid']>;
  smartContractId?: InputMaybe<Scalars['uuid']>;
  statusId?: InputMaybe<Scalars['uuid']>;
  toWalletId?: InputMaybe<Scalars['uuid']>;
  transactionKindId?: InputMaybe<Scalars['uuid']>;
  txnId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type TransactionSumFields = {
  __typename?: 'TransactionSumFields';
  amount?: Maybe<Scalars['bigint']>;
  networkFee?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "transaction" */
export type TransactionSumOrderBy = {
  amount?: InputMaybe<OrderBy>;
  networkFee?: InputMaybe<OrderBy>;
};

/** update columns of table "transaction" */
export enum TransactionUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  Confirmation = 'confirmation',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Description = 'description',
  /** column name */
  DestTag = 'destTag',
  /** column name */
  FromWalletId = 'fromWalletId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  NetworkFee = 'networkFee',
  /** column name */
  PaymentId = 'paymentId',
  /** column name */
  SmartContractId = 'smartContractId',
  /** column name */
  StatusId = 'statusId',
  /** column name */
  ToWalletId = 'toWalletId',
  /** column name */
  TransactionKindId = 'transactionKindId',
  /** column name */
  TxnId = 'txnId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type TransactionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TransactionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TransactionSetInput>;
  /** filter the rows which have to be updated */
  where: TransactionBoolExp;
};

/** aggregate varPop on columns */
export type TransactionVarPopFields = {
  __typename?: 'TransactionVarPopFields';
  amount?: Maybe<Scalars['Float']>;
  networkFee?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "transaction" */
export type TransactionVarPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
  networkFee?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type TransactionVarSampFields = {
  __typename?: 'TransactionVarSampFields';
  amount?: Maybe<Scalars['Float']>;
  networkFee?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "transaction" */
export type TransactionVarSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
  networkFee?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TransactionVarianceFields = {
  __typename?: 'TransactionVarianceFields';
  amount?: Maybe<Scalars['Float']>;
  networkFee?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "transaction" */
export type TransactionVarianceOrderBy = {
  amount?: InputMaybe<OrderBy>;
  networkFee?: InputMaybe<OrderBy>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'User';
  /** An object relationship */
  country?: Maybe<Country>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  crwdsystemsApi?: Maybe<Scalars['String']>;
  email: Scalars['citext'];
  emailVerified: Scalars['Boolean'];
  id: Scalars['uuid'];
  isAdmin: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['uuid']>;
  preferences?: Maybe<Scalars['String']>;
  rememberToken: Scalars['String'];
  /** An object relationship */
  sigmaPrime?: Maybe<SigmaPrime>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  smartContractKind?: Maybe<SmartContractKind>;
  smartContractKindId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  status?: Maybe<Status>;
  statusId?: Maybe<Scalars['uuid']>;
  uniqueHash: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "user" */
export type UserAggregate = {
  __typename?: 'UserAggregate';
  aggregate?: Maybe<UserAggregateFields>;
  nodes: Array<User>;
};

export type UserAggregateBoolExp = {
  bool_and?: InputMaybe<UserAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<UserAggregateBoolExpBool_Or>;
  count?: InputMaybe<UserAggregateBoolExpCount>;
};

/** aggregate fields of "user" */
export type UserAggregateFields = {
  __typename?: 'UserAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<UserMaxFields>;
  min?: Maybe<UserMinFields>;
};

/** aggregate fields of "user" */
export type UserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type UserAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserMaxOrderBy>;
  min?: InputMaybe<UserMinOrderBy>;
};

/** input type for inserting array relation for remote table "user" */
export type UserArrRelInsertInput = {
  data: Array<UserInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<UserOnConflict>;
};

/** columns and relationships of "user_auth" */
export type UserAuth = {
  __typename?: 'UserAuth';
  createdAt: Scalars['timestamptz'];
  hash: Scalars['String'];
  salt: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** aggregated selection of "user_auth" */
export type UserAuthAggregate = {
  __typename?: 'UserAuthAggregate';
  aggregate?: Maybe<UserAuthAggregateFields>;
  nodes: Array<UserAuth>;
};

/** aggregate fields of "user_auth" */
export type UserAuthAggregateFields = {
  __typename?: 'UserAuthAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<UserAuthMaxFields>;
  min?: Maybe<UserAuthMinFields>;
};

/** aggregate fields of "user_auth" */
export type UserAuthAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserAuthSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_auth". All fields are combined with a logical 'AND'. */
export type UserAuthBoolExp = {
  _and?: InputMaybe<Array<UserAuthBoolExp>>;
  _not?: InputMaybe<UserAuthBoolExp>;
  _or?: InputMaybe<Array<UserAuthBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  hash?: InputMaybe<StringComparisonExp>;
  salt?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "user_auth" */
export enum UserAuthConstraint {
  /** unique or primary key constraint on columns "user_id" */
  UserAuthPkey = 'user_auth_pkey',
}

/** input type for inserting data into table "user_auth" */
export type UserAuthInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hash?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserAuthMaxFields = {
  __typename?: 'UserAuthMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  hash?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type UserAuthMinFields = {
  __typename?: 'UserAuthMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  hash?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_auth" */
export type UserAuthMutationResponse = {
  __typename?: 'UserAuthMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserAuth>;
};

/** on_conflict condition type for table "user_auth" */
export type UserAuthOnConflict = {
  constraint: UserAuthConstraint;
  updateColumns?: Array<UserAuthUpdateColumn>;
  where?: InputMaybe<UserAuthBoolExp>;
};

/** Ordering options when selecting data from "user_auth". */
export type UserAuthOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  hash?: InputMaybe<OrderBy>;
  salt?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_auth */
export type UserAuthPkColumnsInput = {
  userId: Scalars['uuid'];
};

/** select columns of table "user_auth" */
export enum UserAuthSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Hash = 'hash',
  /** column name */
  Salt = 'salt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "user_auth" */
export type UserAuthSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hash?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_auth" */
export type UserAuthStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserAuthStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserAuthStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hash?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_auth" */
export enum UserAuthUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Hash = 'hash',
  /** column name */
  Salt = 'salt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type UserAuthUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserAuthSetInput>;
  /** filter the rows which have to be updated */
  where: UserAuthBoolExp;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type UserBoolExp = {
  _and?: InputMaybe<Array<UserBoolExp>>;
  _not?: InputMaybe<UserBoolExp>;
  _or?: InputMaybe<Array<UserBoolExp>>;
  country?: InputMaybe<CountryBoolExp>;
  countryCode?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  crwdsystemsApi?: InputMaybe<StringComparisonExp>;
  email?: InputMaybe<CitextComparisonExp>;
  emailVerified?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isAdmin?: InputMaybe<BooleanComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  preferences?: InputMaybe<StringComparisonExp>;
  rememberToken?: InputMaybe<StringComparisonExp>;
  sigmaPrime?: InputMaybe<SigmaPrimeBoolExp>;
  sigmaPrimeId?: InputMaybe<UuidComparisonExp>;
  smartContractKind?: InputMaybe<SmartContractKindBoolExp>;
  smartContractKindId?: InputMaybe<UuidComparisonExp>;
  status?: InputMaybe<StatusBoolExp>;
  statusId?: InputMaybe<UuidComparisonExp>;
  uniqueHash?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "user" */
export enum UserConstraint {
  /** unique or primary key constraint on columns "email" */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey',
  /** unique or primary key constraint on columns "remember_token" */
  UserRememberTokenKey = 'user_remember_token_key',
  /** unique or primary key constraint on columns "sigma_prime_id" */
  UserSigmaPrimeIdKey = 'user_sigma_prime_id_key',
  /** unique or primary key constraint on columns "unique_hash" */
  UserUuidKey = 'user_uuid_key',
  /** unique or primary key constraint on columns "id" */
  UserXidKey = 'user_xid_key',
}

/** input type for inserting data into table "user" */
export type UserInsertInput = {
  country?: InputMaybe<CountryObjRelInsertInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdsystemsApi?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['citext']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  preferences?: InputMaybe<Scalars['String']>;
  rememberToken?: InputMaybe<Scalars['String']>;
  sigmaPrime?: InputMaybe<SigmaPrimeObjRelInsertInput>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  smartContractKind?: InputMaybe<SmartContractKindObjRelInsertInput>;
  smartContractKindId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<StatusObjRelInsertInput>;
  statusId?: InputMaybe<Scalars['uuid']>;
  uniqueHash?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type UserMaxFields = {
  __typename?: 'UserMaxFields';
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crwdsystemsApi?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  preferences?: Maybe<Scalars['String']>;
  rememberToken?: Maybe<Scalars['String']>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  smartContractKindId?: Maybe<Scalars['uuid']>;
  statusId?: Maybe<Scalars['uuid']>;
  uniqueHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user" */
export type UserMaxOrderBy = {
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  crwdsystemsApi?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  preferences?: InputMaybe<OrderBy>;
  rememberToken?: InputMaybe<OrderBy>;
  sigmaPrimeId?: InputMaybe<OrderBy>;
  smartContractKindId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  uniqueHash?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserMinFields = {
  __typename?: 'UserMinFields';
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crwdsystemsApi?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  preferences?: Maybe<Scalars['String']>;
  rememberToken?: Maybe<Scalars['String']>;
  sigmaPrimeId?: Maybe<Scalars['uuid']>;
  smartContractKindId?: Maybe<Scalars['uuid']>;
  statusId?: Maybe<Scalars['uuid']>;
  uniqueHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user" */
export type UserMinOrderBy = {
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  crwdsystemsApi?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  preferences?: InputMaybe<OrderBy>;
  rememberToken?: InputMaybe<OrderBy>;
  sigmaPrimeId?: InputMaybe<OrderBy>;
  smartContractKindId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  uniqueHash?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user" */
export type UserMutationResponse = {
  __typename?: 'UserMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type UserObjRelInsertInput = {
  data: UserInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<UserOnConflict>;
};

/** on_conflict condition type for table "user" */
export type UserOnConflict = {
  constraint: UserConstraint;
  updateColumns?: Array<UserUpdateColumn>;
  where?: InputMaybe<UserBoolExp>;
};

/** Ordering options when selecting data from "user". */
export type UserOrderBy = {
  country?: InputMaybe<CountryOrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  crwdsystemsApi?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  emailVerified?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAdmin?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  preferences?: InputMaybe<OrderBy>;
  rememberToken?: InputMaybe<OrderBy>;
  sigmaPrime?: InputMaybe<SigmaPrimeOrderBy>;
  sigmaPrimeId?: InputMaybe<OrderBy>;
  smartContractKind?: InputMaybe<SmartContractKindOrderBy>;
  smartContractKindId?: InputMaybe<OrderBy>;
  status?: InputMaybe<StatusOrderBy>;
  statusId?: InputMaybe<OrderBy>;
  uniqueHash?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user */
export type UserPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "user" */
export enum UserSelectColumn {
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrwdsystemsApi = 'crwdsystemsApi',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Preferences = 'preferences',
  /** column name */
  RememberToken = 'rememberToken',
  /** column name */
  SigmaPrimeId = 'sigmaPrimeId',
  /** column name */
  SmartContractKindId = 'smartContractKindId',
  /** column name */
  StatusId = 'statusId',
  /** column name */
  UniqueHash = 'uniqueHash',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "userAggregateBoolExpBool_andArgumentsColumns" columns of table "user" */
export enum UserSelectColumnUserAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "userAggregateBoolExpBool_orArgumentsColumns" columns of table "user" */
export enum UserSelectColumnUserAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "user" */
export type UserSetInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdsystemsApi?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['citext']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  preferences?: InputMaybe<Scalars['String']>;
  rememberToken?: InputMaybe<Scalars['String']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  smartContractKindId?: InputMaybe<Scalars['uuid']>;
  statusId?: InputMaybe<Scalars['uuid']>;
  uniqueHash?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

export type UserSignupInput = {
  data?: InputMaybe<Scalars['jsonb']>;
  email: Scalars['String'];
  personAddress1: Scalars['String'];
  personAddress2?: InputMaybe<Scalars['String']>;
  personCity: Scalars['String'];
  personCountryCode: Scalars['String'];
  personFamilyName?: InputMaybe<Scalars['String']>;
  personFirstName: Scalars['String'];
  personHandleName: Scalars['String'];
  personLastName: Scalars['String'];
  personMiddleName?: InputMaybe<Scalars['String']>;
  personPostal: Scalars['String'];
  personPostal4?: InputMaybe<Scalars['String']>;
  personPrimaryMobile: Scalars['String'];
  personProvinceState: Scalars['String'];
  personRegionCounty?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  referrerEmail?: InputMaybe<Scalars['String']>;
};

export type UserSignupOutput = {
  __typename?: 'UserSignupOutput';
  id: Scalars['String'];
};

/** Streaming cursor of the table "user" */
export type UserStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserStreamCursorValueInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crwdsystemsApi?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['citext']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  preferences?: InputMaybe<Scalars['String']>;
  rememberToken?: InputMaybe<Scalars['String']>;
  sigmaPrimeId?: InputMaybe<Scalars['uuid']>;
  smartContractKindId?: InputMaybe<Scalars['uuid']>;
  statusId?: InputMaybe<Scalars['uuid']>;
  uniqueHash?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "user" */
export enum UserUpdateColumn {
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrwdsystemsApi = 'crwdsystemsApi',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Preferences = 'preferences',
  /** column name */
  RememberToken = 'rememberToken',
  /** column name */
  SigmaPrimeId = 'sigmaPrimeId',
  /** column name */
  SmartContractKindId = 'smartContractKindId',
  /** column name */
  StatusId = 'statusId',
  /** column name */
  UniqueHash = 'uniqueHash',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type UserUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSetInput>;
  /** filter the rows which have to be updated */
  where: UserBoolExp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "wallet" */
export type Wallet = {
  __typename?: 'Wallet';
  /** An array relationship */
  asks: Array<Ask>;
  /** An aggregate relationship */
  asksAggregate: AskAggregate;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  defaultCurrency: Currency;
  defaultCurrencyId: Scalars['uuid'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  /** An object relationship */
  kind?: Maybe<WalletKind>;
  name: Scalars['String'];
  primaryHash: Scalars['String'];
  /** An object relationship */
  primaryUser: User;
  primaryUserId: Scalars['uuid'];
  /** An array relationship */
  puts: Array<Put>;
  /** An aggregate relationship */
  putsAggregate: PutAggregate;
  secondaryHash?: Maybe<Scalars['String']>;
  /** An object relationship */
  secondaryUser?: Maybe<User>;
  secondaryUserId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  transactions: Array<Transaction>;
  /** An aggregate relationship */
  transactionsAggregate: TransactionAggregate;
  /** An array relationship */
  transactionsByToWalletId: Array<Transaction>;
  /** An aggregate relationship */
  transactionsByToWalletIdAggregate: TransactionAggregate;
  updatedAt: Scalars['timestamptz'];
  walletAddress: Scalars['String'];
  walletKindId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "wallet" */
export type WalletAsksArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

/** columns and relationships of "wallet" */
export type WalletAsksAggregateArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

/** columns and relationships of "wallet" */
export type WalletPutsArgs = {
  distinctOn?: InputMaybe<Array<PutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PutOrderBy>>;
  where?: InputMaybe<PutBoolExp>;
};

/** columns and relationships of "wallet" */
export type WalletPutsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PutOrderBy>>;
  where?: InputMaybe<PutBoolExp>;
};

/** columns and relationships of "wallet" */
export type WalletTransactionsArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

/** columns and relationships of "wallet" */
export type WalletTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

/** columns and relationships of "wallet" */
export type WalletTransactionsByToWalletIdArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

/** columns and relationships of "wallet" */
export type WalletTransactionsByToWalletIdAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

/** aggregated selection of "wallet" */
export type WalletAggregate = {
  __typename?: 'WalletAggregate';
  aggregate?: Maybe<WalletAggregateFields>;
  nodes: Array<Wallet>;
};

export type WalletAggregateBoolExp = {
  bool_and?: InputMaybe<WalletAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<WalletAggregateBoolExpBool_Or>;
  count?: InputMaybe<WalletAggregateBoolExpCount>;
};

/** aggregate fields of "wallet" */
export type WalletAggregateFields = {
  __typename?: 'WalletAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<WalletMaxFields>;
  min?: Maybe<WalletMinFields>;
};

/** aggregate fields of "wallet" */
export type WalletAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WalletSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "wallet" */
export type WalletAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WalletMaxOrderBy>;
  min?: InputMaybe<WalletMinOrderBy>;
};

/** input type for inserting array relation for remote table "wallet" */
export type WalletArrRelInsertInput = {
  data: Array<WalletInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<WalletOnConflict>;
};

/** Boolean expression to filter rows from the table "wallet". All fields are combined with a logical 'AND'. */
export type WalletBoolExp = {
  _and?: InputMaybe<Array<WalletBoolExp>>;
  _not?: InputMaybe<WalletBoolExp>;
  _or?: InputMaybe<Array<WalletBoolExp>>;
  asks?: InputMaybe<AskBoolExp>;
  asksAggregate?: InputMaybe<AskAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  defaultCurrency?: InputMaybe<CurrencyBoolExp>;
  defaultCurrencyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  kind?: InputMaybe<WalletKindBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  primaryHash?: InputMaybe<StringComparisonExp>;
  primaryUser?: InputMaybe<UserBoolExp>;
  primaryUserId?: InputMaybe<UuidComparisonExp>;
  puts?: InputMaybe<PutBoolExp>;
  putsAggregate?: InputMaybe<PutAggregateBoolExp>;
  secondaryHash?: InputMaybe<StringComparisonExp>;
  secondaryUser?: InputMaybe<UserBoolExp>;
  secondaryUserId?: InputMaybe<UuidComparisonExp>;
  transactions?: InputMaybe<TransactionBoolExp>;
  transactionsAggregate?: InputMaybe<TransactionAggregateBoolExp>;
  transactionsByToWalletId?: InputMaybe<TransactionBoolExp>;
  transactionsByToWalletIdAggregate?: InputMaybe<TransactionAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  walletAddress?: InputMaybe<StringComparisonExp>;
  walletKindId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "wallet" */
export enum WalletConstraint {
  /** unique or primary key constraint on columns "name" */
  WalletNameKey = 'wallet_name_key',
  /** unique or primary key constraint on columns "id" */
  WalletPkey = 'wallet_pkey',
  /** unique or primary key constraint on columns "primary_hash" */
  WalletPrimaryHashKey = 'wallet_primary_hash_key',
  /** unique or primary key constraint on columns "secondary_hash" */
  WalletSecondaryHashKey = 'wallet_secondary_hash_key',
  /** unique or primary key constraint on columns "wallet_address" */
  WalletWalletAddressKey = 'wallet_wallet_address_key',
  /** unique or primary key constraint on columns "id" */
  WalletXidKey = 'wallet_xid_key',
}

/** input type for inserting data into table "wallet" */
export type WalletInsertInput = {
  asks?: InputMaybe<AskArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  defaultCurrency?: InputMaybe<CurrencyObjRelInsertInput>;
  defaultCurrencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<WalletKindObjRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  primaryHash?: InputMaybe<Scalars['String']>;
  primaryUser?: InputMaybe<UserObjRelInsertInput>;
  primaryUserId?: InputMaybe<Scalars['uuid']>;
  puts?: InputMaybe<PutArrRelInsertInput>;
  secondaryHash?: InputMaybe<Scalars['String']>;
  secondaryUser?: InputMaybe<UserObjRelInsertInput>;
  secondaryUserId?: InputMaybe<Scalars['uuid']>;
  transactions?: InputMaybe<TransactionArrRelInsertInput>;
  transactionsByToWalletId?: InputMaybe<TransactionArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  walletAddress?: InputMaybe<Scalars['String']>;
  walletKindId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "wallet_inventory" */
export type WalletInventory = {
  __typename?: 'WalletInventory';
  accountNumber?: Maybe<Scalars['String']>;
  /** An object relationship */
  asset?: Maybe<Asset>;
  assetId?: Maybe<Scalars['uuid']>;
  availableAt?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  currency?: Maybe<Currency>;
  currencyId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  documentUser?: Maybe<DocumentUser>;
  documentUserId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  favorite?: Maybe<Favorite>;
  favoriteId?: Maybe<Scalars['uuid']>;
  ibanNumber?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  inventoryAmount?: Maybe<Scalars['bigint']>;
  isDeleted: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['uuid']>;
  restriction?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  /** An object relationship */
  transaction?: Maybe<Transaction>;
  transactionId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  wallet?: Maybe<Wallet>;
  walletId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "wallet_inventory" */
export type WalletInventoryAggregate = {
  __typename?: 'WalletInventoryAggregate';
  aggregate?: Maybe<WalletInventoryAggregateFields>;
  nodes: Array<WalletInventory>;
};

/** aggregate fields of "wallet_inventory" */
export type WalletInventoryAggregateFields = {
  __typename?: 'WalletInventoryAggregateFields';
  avg?: Maybe<WalletInventoryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<WalletInventoryMaxFields>;
  min?: Maybe<WalletInventoryMinFields>;
  stddev?: Maybe<WalletInventoryStddevFields>;
  stddevPop?: Maybe<WalletInventoryStddevPopFields>;
  stddevSamp?: Maybe<WalletInventoryStddevSampFields>;
  sum?: Maybe<WalletInventorySumFields>;
  varPop?: Maybe<WalletInventoryVarPopFields>;
  varSamp?: Maybe<WalletInventoryVarSampFields>;
  variance?: Maybe<WalletInventoryVarianceFields>;
};

/** aggregate fields of "wallet_inventory" */
export type WalletInventoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WalletInventorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type WalletInventoryAvgFields = {
  __typename?: 'WalletInventoryAvgFields';
  inventoryAmount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "wallet_inventory". All fields are combined with a logical 'AND'. */
export type WalletInventoryBoolExp = {
  _and?: InputMaybe<Array<WalletInventoryBoolExp>>;
  _not?: InputMaybe<WalletInventoryBoolExp>;
  _or?: InputMaybe<Array<WalletInventoryBoolExp>>;
  accountNumber?: InputMaybe<StringComparisonExp>;
  asset?: InputMaybe<AssetBoolExp>;
  assetId?: InputMaybe<UuidComparisonExp>;
  availableAt?: InputMaybe<TimestamptzComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrencyBoolExp>;
  currencyId?: InputMaybe<UuidComparisonExp>;
  documentUser?: InputMaybe<DocumentUserBoolExp>;
  documentUserId?: InputMaybe<UuidComparisonExp>;
  favorite?: InputMaybe<FavoriteBoolExp>;
  favoriteId?: InputMaybe<UuidComparisonExp>;
  ibanNumber?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  inventoryAmount?: InputMaybe<BigintComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  order?: InputMaybe<OrderBoolExp>;
  orderId?: InputMaybe<UuidComparisonExp>;
  product?: InputMaybe<ProductBoolExp>;
  productId?: InputMaybe<UuidComparisonExp>;
  restriction?: InputMaybe<StringComparisonExp>;
  routingNumber?: InputMaybe<StringComparisonExp>;
  swiftCode?: InputMaybe<StringComparisonExp>;
  transaction?: InputMaybe<TransactionBoolExp>;
  transactionId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  wallet?: InputMaybe<WalletBoolExp>;
  walletId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "wallet_inventory" */
export enum WalletInventoryConstraint {
  /** unique or primary key constraint on columns "id" */
  WalletInventoryPkey = 'wallet_inventory_pkey',
}

/** input type for incrementing numeric columns in table "wallet_inventory" */
export type WalletInventoryIncInput = {
  inventoryAmount?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "wallet_inventory" */
export type WalletInventoryInsertInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  asset?: InputMaybe<AssetObjRelInsertInput>;
  assetId?: InputMaybe<Scalars['uuid']>;
  availableAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<CurrencyObjRelInsertInput>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  documentUser?: InputMaybe<DocumentUserObjRelInsertInput>;
  documentUserId?: InputMaybe<Scalars['uuid']>;
  favorite?: InputMaybe<FavoriteObjRelInsertInput>;
  favoriteId?: InputMaybe<Scalars['uuid']>;
  ibanNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inventoryAmount?: InputMaybe<Scalars['bigint']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderObjRelInsertInput>;
  orderId?: InputMaybe<Scalars['uuid']>;
  product?: InputMaybe<ProductObjRelInsertInput>;
  productId?: InputMaybe<Scalars['uuid']>;
  restriction?: InputMaybe<Scalars['String']>;
  routingNumber?: InputMaybe<Scalars['String']>;
  swiftCode?: InputMaybe<Scalars['String']>;
  transaction?: InputMaybe<TransactionObjRelInsertInput>;
  transactionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  wallet?: InputMaybe<WalletObjRelInsertInput>;
  walletId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type WalletInventoryMaxFields = {
  __typename?: 'WalletInventoryMaxFields';
  accountNumber?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['uuid']>;
  availableAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  documentUserId?: Maybe<Scalars['uuid']>;
  favoriteId?: Maybe<Scalars['uuid']>;
  ibanNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inventoryAmount?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  restriction?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  walletId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type WalletInventoryMinFields = {
  __typename?: 'WalletInventoryMinFields';
  accountNumber?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['uuid']>;
  availableAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyId?: Maybe<Scalars['uuid']>;
  documentUserId?: Maybe<Scalars['uuid']>;
  favoriteId?: Maybe<Scalars['uuid']>;
  ibanNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inventoryAmount?: Maybe<Scalars['bigint']>;
  notes?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  restriction?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  walletId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "wallet_inventory" */
export type WalletInventoryMutationResponse = {
  __typename?: 'WalletInventoryMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WalletInventory>;
};

/** on_conflict condition type for table "wallet_inventory" */
export type WalletInventoryOnConflict = {
  constraint: WalletInventoryConstraint;
  updateColumns?: Array<WalletInventoryUpdateColumn>;
  where?: InputMaybe<WalletInventoryBoolExp>;
};

/** Ordering options when selecting data from "wallet_inventory". */
export type WalletInventoryOrderBy = {
  accountNumber?: InputMaybe<OrderBy>;
  asset?: InputMaybe<AssetOrderBy>;
  assetId?: InputMaybe<OrderBy>;
  availableAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrencyOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  documentUser?: InputMaybe<DocumentUserOrderBy>;
  documentUserId?: InputMaybe<OrderBy>;
  favorite?: InputMaybe<FavoriteOrderBy>;
  favoriteId?: InputMaybe<OrderBy>;
  ibanNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inventoryAmount?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderOrderBy>;
  orderId?: InputMaybe<OrderBy>;
  product?: InputMaybe<ProductOrderBy>;
  productId?: InputMaybe<OrderBy>;
  restriction?: InputMaybe<OrderBy>;
  routingNumber?: InputMaybe<OrderBy>;
  swiftCode?: InputMaybe<OrderBy>;
  transaction?: InputMaybe<TransactionOrderBy>;
  transactionId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  wallet?: InputMaybe<WalletOrderBy>;
  walletId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: wallet_inventory */
export type WalletInventoryPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "wallet_inventory" */
export enum WalletInventorySelectColumn {
  /** column name */
  AccountNumber = 'accountNumber',
  /** column name */
  AssetId = 'assetId',
  /** column name */
  AvailableAt = 'availableAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  DocumentUserId = 'documentUserId',
  /** column name */
  FavoriteId = 'favoriteId',
  /** column name */
  IbanNumber = 'ibanNumber',
  /** column name */
  Id = 'id',
  /** column name */
  InventoryAmount = 'inventoryAmount',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Restriction = 'restriction',
  /** column name */
  RoutingNumber = 'routingNumber',
  /** column name */
  SwiftCode = 'swiftCode',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WalletId = 'walletId',
}

/** input type for updating data in table "wallet_inventory" */
export type WalletInventorySetInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['uuid']>;
  availableAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  documentUserId?: InputMaybe<Scalars['uuid']>;
  favoriteId?: InputMaybe<Scalars['uuid']>;
  ibanNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inventoryAmount?: InputMaybe<Scalars['bigint']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['uuid']>;
  productId?: InputMaybe<Scalars['uuid']>;
  restriction?: InputMaybe<Scalars['String']>;
  routingNumber?: InputMaybe<Scalars['String']>;
  swiftCode?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  walletId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type WalletInventoryStddevFields = {
  __typename?: 'WalletInventoryStddevFields';
  inventoryAmount?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type WalletInventoryStddevPopFields = {
  __typename?: 'WalletInventoryStddevPopFields';
  inventoryAmount?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type WalletInventoryStddevSampFields = {
  __typename?: 'WalletInventoryStddevSampFields';
  inventoryAmount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "wallet_inventory" */
export type WalletInventoryStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WalletInventoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WalletInventoryStreamCursorValueInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['uuid']>;
  availableAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['uuid']>;
  documentUserId?: InputMaybe<Scalars['uuid']>;
  favoriteId?: InputMaybe<Scalars['uuid']>;
  ibanNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inventoryAmount?: InputMaybe<Scalars['bigint']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['uuid']>;
  productId?: InputMaybe<Scalars['uuid']>;
  restriction?: InputMaybe<Scalars['String']>;
  routingNumber?: InputMaybe<Scalars['String']>;
  swiftCode?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  walletId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type WalletInventorySumFields = {
  __typename?: 'WalletInventorySumFields';
  inventoryAmount?: Maybe<Scalars['bigint']>;
};

/** update columns of table "wallet_inventory" */
export enum WalletInventoryUpdateColumn {
  /** column name */
  AccountNumber = 'accountNumber',
  /** column name */
  AssetId = 'assetId',
  /** column name */
  AvailableAt = 'availableAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  DocumentUserId = 'documentUserId',
  /** column name */
  FavoriteId = 'favoriteId',
  /** column name */
  IbanNumber = 'ibanNumber',
  /** column name */
  Id = 'id',
  /** column name */
  InventoryAmount = 'inventoryAmount',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Restriction = 'restriction',
  /** column name */
  RoutingNumber = 'routingNumber',
  /** column name */
  SwiftCode = 'swiftCode',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WalletId = 'walletId',
}

export type WalletInventoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WalletInventoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WalletInventorySetInput>;
  /** filter the rows which have to be updated */
  where: WalletInventoryBoolExp;
};

/** aggregate varPop on columns */
export type WalletInventoryVarPopFields = {
  __typename?: 'WalletInventoryVarPopFields';
  inventoryAmount?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type WalletInventoryVarSampFields = {
  __typename?: 'WalletInventoryVarSampFields';
  inventoryAmount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type WalletInventoryVarianceFields = {
  __typename?: 'WalletInventoryVarianceFields';
  inventoryAmount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "wallet_kind" */
export type WalletKind = {
  __typename?: 'WalletKind';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  merchant?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organization?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['timestamptz'];
  user?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  wallets: Array<Wallet>;
  /** An aggregate relationship */
  walletsAggregate: WalletAggregate;
};

/** columns and relationships of "wallet_kind" */
export type WalletKindWalletsArgs = {
  distinctOn?: InputMaybe<Array<WalletSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletOrderBy>>;
  where?: InputMaybe<WalletBoolExp>;
};

/** columns and relationships of "wallet_kind" */
export type WalletKindWalletsAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletOrderBy>>;
  where?: InputMaybe<WalletBoolExp>;
};

/** aggregated selection of "wallet_kind" */
export type WalletKindAggregate = {
  __typename?: 'WalletKindAggregate';
  aggregate?: Maybe<WalletKindAggregateFields>;
  nodes: Array<WalletKind>;
};

/** aggregate fields of "wallet_kind" */
export type WalletKindAggregateFields = {
  __typename?: 'WalletKindAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<WalletKindMaxFields>;
  min?: Maybe<WalletKindMinFields>;
};

/** aggregate fields of "wallet_kind" */
export type WalletKindAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WalletKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "wallet_kind". All fields are combined with a logical 'AND'. */
export type WalletKindBoolExp = {
  _and?: InputMaybe<Array<WalletKindBoolExp>>;
  _not?: InputMaybe<WalletKindBoolExp>;
  _or?: InputMaybe<Array<WalletKindBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  merchant?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<BooleanComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<BooleanComparisonExp>;
  wallets?: InputMaybe<WalletBoolExp>;
  walletsAggregate?: InputMaybe<WalletAggregateBoolExp>;
};

/** unique or primary key constraints on table "wallet_kind" */
export enum WalletKindConstraint {
  /** unique or primary key constraint on columns "id" */
  WalletKindPkey = 'wallet_kind_pkey',
  /** unique or primary key constraint on columns "id" */
  WalletKindXidKey = 'wallet_kind_xid_key',
}

/** input type for inserting data into table "wallet_kind" */
export type WalletKindInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  merchant?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Scalars['Boolean']>;
  wallets?: InputMaybe<WalletArrRelInsertInput>;
};

/** aggregate max on columns */
export type WalletKindMaxFields = {
  __typename?: 'WalletKindMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type WalletKindMinFields = {
  __typename?: 'WalletKindMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "wallet_kind" */
export type WalletKindMutationResponse = {
  __typename?: 'WalletKindMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WalletKind>;
};

/** input type for inserting object relation for remote table "wallet_kind" */
export type WalletKindObjRelInsertInput = {
  data: WalletKindInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<WalletKindOnConflict>;
};

/** on_conflict condition type for table "wallet_kind" */
export type WalletKindOnConflict = {
  constraint: WalletKindConstraint;
  updateColumns?: Array<WalletKindUpdateColumn>;
  where?: InputMaybe<WalletKindBoolExp>;
};

/** Ordering options when selecting data from "wallet_kind". */
export type WalletKindOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  merchant?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<OrderBy>;
  walletsAggregate?: InputMaybe<WalletAggregateOrderBy>;
};

/** primary key columns input for table: wallet_kind */
export type WalletKindPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "wallet_kind" */
export enum WalletKindSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Merchant = 'merchant',
  /** column name */
  Name = 'name',
  /** column name */
  Organization = 'organization',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  User = 'user',
}

/** input type for updating data in table "wallet_kind" */
export type WalletKindSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  merchant?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "wallet_kind" */
export type WalletKindStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WalletKindStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WalletKindStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  merchant?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "wallet_kind" */
export enum WalletKindUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Merchant = 'merchant',
  /** column name */
  Name = 'name',
  /** column name */
  Organization = 'organization',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  User = 'user',
}

export type WalletKindUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WalletKindSetInput>;
  /** filter the rows which have to be updated */
  where: WalletKindBoolExp;
};

/** aggregate max on columns */
export type WalletMaxFields = {
  __typename?: 'WalletMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultCurrencyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  primaryHash?: Maybe<Scalars['String']>;
  primaryUserId?: Maybe<Scalars['uuid']>;
  secondaryHash?: Maybe<Scalars['String']>;
  secondaryUserId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  walletAddress?: Maybe<Scalars['String']>;
  walletKindId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "wallet" */
export type WalletMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  defaultCurrencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  primaryHash?: InputMaybe<OrderBy>;
  primaryUserId?: InputMaybe<OrderBy>;
  secondaryHash?: InputMaybe<OrderBy>;
  secondaryUserId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  walletAddress?: InputMaybe<OrderBy>;
  walletKindId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WalletMinFields = {
  __typename?: 'WalletMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultCurrencyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  primaryHash?: Maybe<Scalars['String']>;
  primaryUserId?: Maybe<Scalars['uuid']>;
  secondaryHash?: Maybe<Scalars['String']>;
  secondaryUserId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  walletAddress?: Maybe<Scalars['String']>;
  walletKindId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "wallet" */
export type WalletMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  defaultCurrencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  primaryHash?: InputMaybe<OrderBy>;
  primaryUserId?: InputMaybe<OrderBy>;
  secondaryHash?: InputMaybe<OrderBy>;
  secondaryUserId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  walletAddress?: InputMaybe<OrderBy>;
  walletKindId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "wallet" */
export type WalletMutationResponse = {
  __typename?: 'WalletMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wallet>;
};

/** input type for inserting object relation for remote table "wallet" */
export type WalletObjRelInsertInput = {
  data: WalletInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<WalletOnConflict>;
};

/** on_conflict condition type for table "wallet" */
export type WalletOnConflict = {
  constraint: WalletConstraint;
  updateColumns?: Array<WalletUpdateColumn>;
  where?: InputMaybe<WalletBoolExp>;
};

/** Ordering options when selecting data from "wallet". */
export type WalletOrderBy = {
  asksAggregate?: InputMaybe<AskAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  defaultCurrency?: InputMaybe<CurrencyOrderBy>;
  defaultCurrencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  kind?: InputMaybe<WalletKindOrderBy>;
  name?: InputMaybe<OrderBy>;
  primaryHash?: InputMaybe<OrderBy>;
  primaryUser?: InputMaybe<UserOrderBy>;
  primaryUserId?: InputMaybe<OrderBy>;
  putsAggregate?: InputMaybe<PutAggregateOrderBy>;
  secondaryHash?: InputMaybe<OrderBy>;
  secondaryUser?: InputMaybe<UserOrderBy>;
  secondaryUserId?: InputMaybe<OrderBy>;
  transactionsAggregate?: InputMaybe<TransactionAggregateOrderBy>;
  transactionsByToWalletIdAggregate?: InputMaybe<TransactionAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  walletAddress?: InputMaybe<OrderBy>;
  walletKindId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: wallet */
export type WalletPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "wallet" */
export enum WalletSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DefaultCurrencyId = 'defaultCurrencyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  PrimaryHash = 'primaryHash',
  /** column name */
  PrimaryUserId = 'primaryUserId',
  /** column name */
  SecondaryHash = 'secondaryHash',
  /** column name */
  SecondaryUserId = 'secondaryUserId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WalletAddress = 'walletAddress',
  /** column name */
  WalletKindId = 'walletKindId',
}

/** select "walletAggregateBoolExpBool_andArgumentsColumns" columns of table "wallet" */
export enum WalletSelectColumnWalletAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** select "walletAggregateBoolExpBool_orArgumentsColumns" columns of table "wallet" */
export enum WalletSelectColumnWalletAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
}

/** input type for updating data in table "wallet" */
export type WalletSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  defaultCurrencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  primaryHash?: InputMaybe<Scalars['String']>;
  primaryUserId?: InputMaybe<Scalars['uuid']>;
  secondaryHash?: InputMaybe<Scalars['String']>;
  secondaryUserId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  walletAddress?: InputMaybe<Scalars['String']>;
  walletKindId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "wallet" */
export type WalletStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WalletStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WalletStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  defaultCurrencyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  primaryHash?: InputMaybe<Scalars['String']>;
  primaryUserId?: InputMaybe<Scalars['uuid']>;
  secondaryHash?: InputMaybe<Scalars['String']>;
  secondaryUserId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  walletAddress?: InputMaybe<Scalars['String']>;
  walletKindId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "wallet" */
export enum WalletUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DefaultCurrencyId = 'defaultCurrencyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  Name = 'name',
  /** column name */
  PrimaryHash = 'primaryHash',
  /** column name */
  PrimaryUserId = 'primaryUserId',
  /** column name */
  SecondaryHash = 'secondaryHash',
  /** column name */
  SecondaryUserId = 'secondaryUserId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WalletAddress = 'walletAddress',
  /** column name */
  WalletKindId = 'walletKindId',
}

export type WalletUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WalletSetInput>;
  /** filter the rows which have to be updated */
  where: WalletBoolExp;
};

export type AppraisalAggregateBoolExpBool_And = {
  arguments: AppraisalSelectColumnAppraisalAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppraisalBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AppraisalAggregateBoolExpBool_Or = {
  arguments: AppraisalSelectColumnAppraisalAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppraisalBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AppraisalAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AppraisalSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AppraisalBoolExp>;
  predicate: IntComparisonExp;
};

export type AskAggregateBoolExpAvg = {
  arguments: AskSelectColumnAskAggregateBoolExpAvgArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AskBoolExp>;
  predicate: Float8ComparisonExp;
};

export type AskAggregateBoolExpBool_And = {
  arguments: AskSelectColumnAskAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AskBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AskAggregateBoolExpBool_Or = {
  arguments: AskSelectColumnAskAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AskBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AskAggregateBoolExpCorr = {
  arguments: AskAggregateBoolExpCorrArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AskBoolExp>;
  predicate: Float8ComparisonExp;
};

export type AskAggregateBoolExpCorrArguments = {
  X: AskSelectColumnAskAggregateBoolExpCorrArgumentsColumns;
  Y: AskSelectColumnAskAggregateBoolExpCorrArgumentsColumns;
};

export type AskAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AskBoolExp>;
  predicate: IntComparisonExp;
};

export type AskAggregateBoolExpCovar_Samp = {
  arguments: AskAggregateBoolExpCovar_SampArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AskBoolExp>;
  predicate: Float8ComparisonExp;
};

export type AskAggregateBoolExpCovar_SampArguments = {
  X: AskSelectColumnAskAggregateBoolExpCovar_SampArgumentsColumns;
  Y: AskSelectColumnAskAggregateBoolExpCovar_SampArgumentsColumns;
};

export type AskAggregateBoolExpMax = {
  arguments: AskSelectColumnAskAggregateBoolExpMaxArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AskBoolExp>;
  predicate: Float8ComparisonExp;
};

export type AskAggregateBoolExpMin = {
  arguments: AskSelectColumnAskAggregateBoolExpMinArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AskBoolExp>;
  predicate: Float8ComparisonExp;
};

export type AskAggregateBoolExpStddev_Samp = {
  arguments: AskSelectColumnAskAggregateBoolExpStddev_SampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AskBoolExp>;
  predicate: Float8ComparisonExp;
};

export type AskAggregateBoolExpSum = {
  arguments: AskSelectColumnAskAggregateBoolExpSumArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AskBoolExp>;
  predicate: Float8ComparisonExp;
};

export type AskAggregateBoolExpVar_Samp = {
  arguments: AskSelectColumnAskAggregateBoolExpVar_SampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AskBoolExp>;
  predicate: Float8ComparisonExp;
};

export type AssetAggregateBoolExpBool_And = {
  arguments: AssetSelectColumnAssetAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AssetAggregateBoolExpBool_Or = {
  arguments: AssetSelectColumnAssetAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AssetAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AssetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetBoolExp>;
  predicate: IntComparisonExp;
};

export type AssetKindAggregateBoolExpBool_And = {
  arguments: AssetKindSelectColumnAssetKindAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetKindBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AssetKindAggregateBoolExpBool_Or = {
  arguments: AssetKindSelectColumnAssetKindAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetKindBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AssetKindAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AssetKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetKindBoolExp>;
  predicate: IntComparisonExp;
};

export type CertificationAggregateBoolExpBool_And = {
  arguments: CertificationSelectColumnCertificationAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CertificationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CertificationAggregateBoolExpBool_Or = {
  arguments: CertificationSelectColumnCertificationAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CertificationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CertificationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CertificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CertificationBoolExp>;
  predicate: IntComparisonExp;
};

export type ClearinghouseAggregateBoolExpBool_And = {
  arguments: ClearinghouseSelectColumnClearinghouseAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClearinghouseBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ClearinghouseAggregateBoolExpBool_Or = {
  arguments: ClearinghouseSelectColumnClearinghouseAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClearinghouseBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ClearinghouseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClearinghouseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClearinghouseBoolExp>;
  predicate: IntComparisonExp;
};

export type ClearinghouseCertificationAggregateBoolExpBool_And = {
  arguments: ClearinghouseCertificationSelectColumnClearinghouseCertificationAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClearinghouseCertificationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ClearinghouseCertificationAggregateBoolExpBool_Or = {
  arguments: ClearinghouseCertificationSelectColumnClearinghouseCertificationAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClearinghouseCertificationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ClearinghouseCertificationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClearinghouseCertificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClearinghouseCertificationBoolExp>;
  predicate: IntComparisonExp;
};

export type ClearinghouseKindAggregateBoolExpBool_And = {
  arguments: ClearinghouseKindSelectColumnClearinghouseKindAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClearinghouseKindBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ClearinghouseKindAggregateBoolExpBool_Or = {
  arguments: ClearinghouseKindSelectColumnClearinghouseKindAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClearinghouseKindBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ClearinghouseKindAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClearinghouseKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClearinghouseKindBoolExp>;
  predicate: IntComparisonExp;
};

export type CurrencyAggregateBoolExpBool_And = {
  arguments: CurrencySelectColumnCurrencyAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CurrencyBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CurrencyAggregateBoolExpBool_Or = {
  arguments: CurrencySelectColumnCurrencyAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CurrencyBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CurrencyAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CurrencySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CurrencyBoolExp>;
  predicate: IntComparisonExp;
};

export type CurrencyKindAggregateBoolExpBool_And = {
  arguments: CurrencyKindSelectColumnCurrencyKindAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CurrencyKindBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CurrencyKindAggregateBoolExpBool_Or = {
  arguments: CurrencyKindSelectColumnCurrencyKindAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CurrencyKindBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CurrencyKindAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CurrencyKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CurrencyKindBoolExp>;
  predicate: IntComparisonExp;
};

export type CurrencyOwnerAggregateBoolExpBool_And = {
  arguments: CurrencyOwnerSelectColumnCurrencyOwnerAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CurrencyOwnerBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CurrencyOwnerAggregateBoolExpBool_Or = {
  arguments: CurrencyOwnerSelectColumnCurrencyOwnerAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CurrencyOwnerBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CurrencyOwnerAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CurrencyOwnerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CurrencyOwnerBoolExp>;
  predicate: IntComparisonExp;
};

export type ExchangeAggregateBoolExpBool_And = {
  arguments: ExchangeSelectColumnExchangeAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ExchangeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ExchangeAggregateBoolExpBool_Or = {
  arguments: ExchangeSelectColumnExchangeAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ExchangeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ExchangeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ExchangeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ExchangeBoolExp>;
  predicate: IntComparisonExp;
};

export type FavoriteCommentAggregateBoolExpBool_And = {
  arguments: FavoriteCommentSelectColumnFavoriteCommentAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FavoriteCommentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type FavoriteCommentAggregateBoolExpBool_Or = {
  arguments: FavoriteCommentSelectColumnFavoriteCommentAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FavoriteCommentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type FavoriteCommentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FavoriteCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FavoriteCommentBoolExp>;
  predicate: IntComparisonExp;
};

export type GroupAggregateBoolExpBool_And = {
  arguments: GroupSelectColumnGroupAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GroupBoolExp>;
  predicate: BooleanComparisonExp;
};

export type GroupAggregateBoolExpBool_Or = {
  arguments: GroupSelectColumnGroupAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GroupBoolExp>;
  predicate: BooleanComparisonExp;
};

export type GroupAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<GroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GroupBoolExp>;
  predicate: IntComparisonExp;
};

export type IndustryAggregateBoolExpBool_And = {
  arguments: IndustrySelectColumnIndustryAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IndustryBoolExp>;
  predicate: BooleanComparisonExp;
};

export type IndustryAggregateBoolExpBool_Or = {
  arguments: IndustrySelectColumnIndustryAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IndustryBoolExp>;
  predicate: BooleanComparisonExp;
};

export type IndustryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IndustrySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IndustryBoolExp>;
  predicate: IntComparisonExp;
};

export type InteractionAggregateBoolExpBool_And = {
  arguments: InteractionSelectColumnInteractionAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InteractionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type InteractionAggregateBoolExpBool_Or = {
  arguments: InteractionSelectColumnInteractionAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InteractionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type InteractionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<InteractionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InteractionBoolExp>;
  predicate: IntComparisonExp;
};

export type MerchantAggregateBoolExpBool_And = {
  arguments: MerchantSelectColumnMerchantAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MerchantAggregateBoolExpBool_Or = {
  arguments: MerchantSelectColumnMerchantAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MerchantAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchantSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantBoolExp>;
  predicate: IntComparisonExp;
};

export type MerchantCurrencyAggregateBoolExpBool_And = {
  arguments: MerchantCurrencySelectColumnMerchantCurrencyAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantCurrencyBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MerchantCurrencyAggregateBoolExpBool_Or = {
  arguments: MerchantCurrencySelectColumnMerchantCurrencyAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantCurrencyBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MerchantCurrencyAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchantCurrencySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantCurrencyBoolExp>;
  predicate: IntComparisonExp;
};

export type MerchantOrganizationAggregateBoolExpBool_And = {
  arguments: MerchantOrganizationSelectColumnMerchantOrganizationAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantOrganizationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MerchantOrganizationAggregateBoolExpBool_Or = {
  arguments: MerchantOrganizationSelectColumnMerchantOrganizationAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantOrganizationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MerchantOrganizationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchantOrganizationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantOrganizationBoolExp>;
  predicate: IntComparisonExp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "address_attributes" */
  deleteAddressAttributes?: Maybe<AddressAttributesMutationResponse>;
  /** delete single row from the table: "address_attributes" */
  deleteAddressAttributesByPk?: Maybe<AddressAttributes>;
  /** delete data from the table: "appraisal" */
  deleteAppraisal?: Maybe<AppraisalMutationResponse>;
  /** delete single row from the table: "appraisal" */
  deleteAppraisalByPk?: Maybe<Appraisal>;
  /** delete data from the table: "ask" */
  deleteAsk?: Maybe<AskMutationResponse>;
  /** delete single row from the table: "ask" */
  deleteAskByPk?: Maybe<Ask>;
  /** delete data from the table: "ask_put_transaction" */
  deleteAskPutTransaction?: Maybe<AskPutTransactionMutationResponse>;
  /** delete single row from the table: "ask_put_transaction" */
  deleteAskPutTransactionByPk?: Maybe<AskPutTransaction>;
  /** delete data from the table: "asset" */
  deleteAsset?: Maybe<AssetMutationResponse>;
  /** delete data from the table: "asset_account" */
  deleteAssetAccount?: Maybe<AssetAccountMutationResponse>;
  /** delete single row from the table: "asset_account" */
  deleteAssetAccountByPk?: Maybe<AssetAccount>;
  /** delete single row from the table: "asset" */
  deleteAssetByPk?: Maybe<Asset>;
  /** delete data from the table: "asset_kind" */
  deleteAssetKind?: Maybe<AssetKindMutationResponse>;
  /** delete single row from the table: "asset_kind" */
  deleteAssetKindByPk?: Maybe<AssetKind>;
  /** delete data from the table: "cache" */
  deleteCache?: Maybe<CacheMutationResponse>;
  /** delete single row from the table: "cache" */
  deleteCacheByPk?: Maybe<Cache>;
  /** delete data from the table: "cart" */
  deleteCart?: Maybe<CartMutationResponse>;
  /** delete single row from the table: "cart" */
  deleteCartByPk?: Maybe<Cart>;
  /** delete data from the table: "certification" */
  deleteCertification?: Maybe<CertificationMutationResponse>;
  /** delete single row from the table: "certification" */
  deleteCertificationByPk?: Maybe<Certification>;
  /** delete data from the table: "clearinghouse" */
  deleteClearinghouse?: Maybe<ClearinghouseMutationResponse>;
  /** delete single row from the table: "clearinghouse" */
  deleteClearinghouseByPk?: Maybe<Clearinghouse>;
  /** delete data from the table: "clearinghouse_certification" */
  deleteClearinghouseCertification?: Maybe<ClearinghouseCertificationMutationResponse>;
  /** delete single row from the table: "clearinghouse_certification" */
  deleteClearinghouseCertificationByPk?: Maybe<ClearinghouseCertification>;
  /** delete data from the table: "clearinghouse_kind" */
  deleteClearinghouseKind?: Maybe<ClearinghouseKindMutationResponse>;
  /** delete single row from the table: "clearinghouse_kind" */
  deleteClearinghouseKindByPk?: Maybe<ClearinghouseKind>;
  /** delete data from the table: "country" */
  deleteCountry?: Maybe<CountryMutationResponse>;
  /** delete single row from the table: "country" */
  deleteCountryByPk?: Maybe<Country>;
  /** delete data from the table: "country_region" */
  deleteCountryRegion?: Maybe<CountryRegionMutationResponse>;
  /** delete single row from the table: "country_region" */
  deleteCountryRegionByPk?: Maybe<CountryRegion>;
  /** delete data from the table: "currency" */
  deleteCurrency?: Maybe<CurrencyMutationResponse>;
  /** delete single row from the table: "currency" */
  deleteCurrencyByPk?: Maybe<Currency>;
  /** delete data from the table: "currency_kind" */
  deleteCurrencyKind?: Maybe<CurrencyKindMutationResponse>;
  /** delete single row from the table: "currency_kind" */
  deleteCurrencyKindByPk?: Maybe<CurrencyKind>;
  /** delete data from the table: "currency_owner" */
  deleteCurrencyOwner?: Maybe<CurrencyOwnerMutationResponse>;
  /** delete single row from the table: "currency_owner" */
  deleteCurrencyOwnerByPk?: Maybe<CurrencyOwner>;
  /** delete data from the table: "datasource" */
  deleteDatasource?: Maybe<DatasourceMutationResponse>;
  /** delete single row from the table: "datasource" */
  deleteDatasourceByPk?: Maybe<Datasource>;
  /** delete data from the table: "document_user" */
  deleteDocumentUser?: Maybe<DocumentUserMutationResponse>;
  /** delete single row from the table: "document_user" */
  deleteDocumentUserByPk?: Maybe<DocumentUser>;
  /** delete data from the table: "exchange" */
  deleteExchange?: Maybe<ExchangeMutationResponse>;
  /** delete single row from the table: "exchange" */
  deleteExchangeByPk?: Maybe<Exchange>;
  /** delete data from the table: "exchange_kind" */
  deleteExchangeKind?: Maybe<ExchangeKindMutationResponse>;
  /** delete single row from the table: "exchange_kind" */
  deleteExchangeKindByPk?: Maybe<ExchangeKind>;
  /** delete data from the table: "favorite" */
  deleteFavorite?: Maybe<FavoriteMutationResponse>;
  /** delete single row from the table: "favorite" */
  deleteFavoriteByPk?: Maybe<Favorite>;
  /** delete data from the table: "favorite_comment" */
  deleteFavoriteComment?: Maybe<FavoriteCommentMutationResponse>;
  /** delete single row from the table: "favorite_comment" */
  deleteFavoriteCommentByPk?: Maybe<FavoriteComment>;
  /** delete data from the table: "gics_sector" */
  deleteGicsSector?: Maybe<GicsSectorMutationResponse>;
  /** delete single row from the table: "gics_sector" */
  deleteGicsSectorByPk?: Maybe<GicsSector>;
  /** delete data from the table: "group" */
  deleteGroup?: Maybe<GroupMutationResponse>;
  /** delete single row from the table: "group" */
  deleteGroupByPk?: Maybe<Group>;
  /** delete data from the table: "industry" */
  deleteIndustry?: Maybe<IndustryMutationResponse>;
  /** delete single row from the table: "industry" */
  deleteIndustryByPk?: Maybe<Industry>;
  /** delete data from the table: "interaction" */
  deleteInteraction?: Maybe<InteractionMutationResponse>;
  /** delete single row from the table: "interaction" */
  deleteInteractionByPk?: Maybe<Interaction>;
  /** delete data from the table: "kyc_kind" */
  deleteKycKind?: Maybe<KycKindMutationResponse>;
  /** delete single row from the table: "kyc_kind" */
  deleteKycKindByPk?: Maybe<KycKind>;
  /** delete data from the table: "kyc_status" */
  deleteKycStatus?: Maybe<KycStatusMutationResponse>;
  /** delete single row from the table: "kyc_status" */
  deleteKycStatusByPk?: Maybe<KycStatus>;
  /** delete data from the table: "market" */
  deleteMarket?: Maybe<MarketMutationResponse>;
  /** delete single row from the table: "market" */
  deleteMarketByPk?: Maybe<Market>;
  /** delete data from the table: "material" */
  deleteMaterial?: Maybe<MaterialMutationResponse>;
  /** delete single row from the table: "material" */
  deleteMaterialByPk?: Maybe<Material>;
  /** delete data from the table: "merchant" */
  deleteMerchant?: Maybe<MerchantMutationResponse>;
  /** delete single row from the table: "merchant" */
  deleteMerchantByPk?: Maybe<Merchant>;
  /** delete data from the table: "merchant_currency" */
  deleteMerchantCurrency?: Maybe<MerchantCurrencyMutationResponse>;
  /** delete single row from the table: "merchant_currency" */
  deleteMerchantCurrencyByPk?: Maybe<MerchantCurrency>;
  /** delete data from the table: "merchant_organization" */
  deleteMerchantOrganization?: Maybe<MerchantOrganizationMutationResponse>;
  /** delete single row from the table: "merchant_organization" */
  deleteMerchantOrganizationByPk?: Maybe<MerchantOrganization>;
  /** delete data from the table: "order" */
  deleteOrder?: Maybe<OrderMutationResponse>;
  /** delete single row from the table: "order" */
  deleteOrderByPk?: Maybe<Order>;
  /** delete data from the table: "organization" */
  deleteOrganization?: Maybe<OrganizationMutationResponse>;
  /** delete single row from the table: "organization" */
  deleteOrganizationByPk?: Maybe<Organization>;
  /** delete data from the table: "organization_kind" */
  deleteOrganizationKind?: Maybe<OrganizationKindMutationResponse>;
  /** delete single row from the table: "organization_kind" */
  deleteOrganizationKindByPk?: Maybe<OrganizationKind>;
  /** delete data from the table: "organization_person" */
  deleteOrganizationPerson?: Maybe<OrganizationPersonMutationResponse>;
  /** delete single row from the table: "organization_person" */
  deleteOrganizationPersonByPk?: Maybe<OrganizationPerson>;
  /** delete data from the table: "organization_status" */
  deleteOrganizationStatus?: Maybe<OrganizationStatusMutationResponse>;
  /** delete single row from the table: "organization_status" */
  deleteOrganizationStatusByPk?: Maybe<OrganizationStatus>;
  /** delete data from the table: "password_reset" */
  deletePasswordReset?: Maybe<PasswordResetMutationResponse>;
  /** delete single row from the table: "password_reset" */
  deletePasswordResetByPk?: Maybe<PasswordReset>;
  /** delete data from the table: "payment" */
  deletePayment?: Maybe<PaymentMutationResponse>;
  /** delete single row from the table: "payment" */
  deletePaymentByPk?: Maybe<Payment>;
  /** delete data from the table: "product" */
  deleteProduct?: Maybe<ProductMutationResponse>;
  /** delete single row from the table: "product" */
  deleteProductByPk?: Maybe<Product>;
  /** delete data from the table: "product_distribution" */
  deleteProductDistribution?: Maybe<ProductDistributionMutationResponse>;
  /** delete single row from the table: "product_distribution" */
  deleteProductDistributionByPk?: Maybe<ProductDistribution>;
  /** delete data from the table: "product_kind" */
  deleteProductKind?: Maybe<ProductKindMutationResponse>;
  /** delete single row from the table: "product_kind" */
  deleteProductKindByPk?: Maybe<ProductKind>;
  /** delete data from the table: "product_status" */
  deleteProductStatus?: Maybe<ProductStatusMutationResponse>;
  /** delete single row from the table: "product_status" */
  deleteProductStatusByPk?: Maybe<ProductStatus>;
  /** delete data from the table: "put" */
  deletePut?: Maybe<PutMutationResponse>;
  /** delete single row from the table: "put" */
  deletePutByPk?: Maybe<Put>;
  /** delete data from the table: "setting" */
  deleteSetting?: Maybe<SettingMutationResponse>;
  /** delete single row from the table: "setting" */
  deleteSettingByPk?: Maybe<Setting>;
  /** delete data from the table: "shipping" */
  deleteShipping?: Maybe<ShippingMutationResponse>;
  /** delete single row from the table: "shipping" */
  deleteShippingByPk?: Maybe<Shipping>;
  /** delete data from the table: "shop" */
  deleteShop?: Maybe<ShopMutationResponse>;
  /** delete single row from the table: "shop" */
  deleteShopByPk?: Maybe<Shop>;
  /** delete data from the table: "sic_code" */
  deleteSicCode?: Maybe<SicCodeMutationResponse>;
  /** delete single row from the table: "sic_code" */
  deleteSicCodeByPk?: Maybe<SicCode>;
  /** delete data from the table: "sigma_identity" */
  deleteSigmaIdentity?: Maybe<SigmaIdentityMutationResponse>;
  /** delete single row from the table: "sigma_identity" */
  deleteSigmaIdentityByPk?: Maybe<SigmaIdentity>;
  /** delete data from the table: "sigma_prime" */
  deleteSigmaPrime?: Maybe<SigmaPrimeMutationResponse>;
  /** delete data from the table: "sigma_prime_address" */
  deleteSigmaPrimeAddress?: Maybe<SigmaPrimeAddressMutationResponse>;
  /** delete single row from the table: "sigma_prime_address" */
  deleteSigmaPrimeAddressByPk?: Maybe<SigmaPrimeAddress>;
  /** delete single row from the table: "sigma_prime" */
  deleteSigmaPrimeByPk?: Maybe<SigmaPrime>;
  /** delete data from the table: "sigma_prime_kind" */
  deleteSigmaPrimeKind?: Maybe<SigmaPrimeKindMutationResponse>;
  /** delete single row from the table: "sigma_prime_kind" */
  deleteSigmaPrimeKindByPk?: Maybe<SigmaPrimeKind>;
  /** delete data from the table: "sigma_social" */
  deleteSigmaSocial?: Maybe<SigmaSocialMutationResponse>;
  /** delete single row from the table: "sigma_social" */
  deleteSigmaSocialByPk?: Maybe<SigmaSocial>;
  /** delete data from the table: "smart_contract" */
  deleteSmartContract?: Maybe<SmartContractMutationResponse>;
  /** delete single row from the table: "smart_contract" */
  deleteSmartContractByPk?: Maybe<SmartContract>;
  /** delete data from the table: "smart_contract_commission" */
  deleteSmartContractCommission?: Maybe<SmartContractCommissionMutationResponse>;
  /** delete single row from the table: "smart_contract_commission" */
  deleteSmartContractCommissionByPk?: Maybe<SmartContractCommission>;
  /** delete data from the table: "smart_contract_document" */
  deleteSmartContractDocument?: Maybe<SmartContractDocumentMutationResponse>;
  /** delete single row from the table: "smart_contract_document" */
  deleteSmartContractDocumentByPk?: Maybe<SmartContractDocument>;
  /** delete data from the table: "smart_contract_kind" */
  deleteSmartContractKind?: Maybe<SmartContractKindMutationResponse>;
  /** delete single row from the table: "smart_contract_kind" */
  deleteSmartContractKindByPk?: Maybe<SmartContractKind>;
  /** delete data from the table: "smart_contract_lineage" */
  deleteSmartContractLineage?: Maybe<SmartContractLineageMutationResponse>;
  /** delete single row from the table: "smart_contract_lineage" */
  deleteSmartContractLineageByPk?: Maybe<SmartContractLineage>;
  /** delete data from the table: "state" */
  deleteState?: Maybe<StateMutationResponse>;
  /** delete data from the table: "status" */
  deleteStatus?: Maybe<StatusMutationResponse>;
  /** delete single row from the table: "status" */
  deleteStatusByPk?: Maybe<Status>;
  /** delete data from the table: "status_kind" */
  deleteStatusKind?: Maybe<StatusKindMutationResponse>;
  /** delete single row from the table: "status_kind" */
  deleteStatusKindByPk?: Maybe<StatusKind>;
  /** delete data from the table: "subindustry" */
  deleteSubindustry?: Maybe<SubindustryMutationResponse>;
  /** delete single row from the table: "subindustry" */
  deleteSubindustryByPk?: Maybe<Subindustry>;
  /** delete data from the table: "supply_chain_waypoint" */
  deleteSupplyChainWaypoint?: Maybe<SupplyChainWaypointMutationResponse>;
  /** delete single row from the table: "supply_chain_waypoint" */
  deleteSupplyChainWaypointByPk?: Maybe<SupplyChainWaypoint>;
  /** delete data from the table: "trade" */
  deleteTrade?: Maybe<TradeMutationResponse>;
  /** delete single row from the table: "trade" */
  deleteTradeByPk?: Maybe<Trade>;
  /** delete data from the table: "trade_kind" */
  deleteTradeKind?: Maybe<TradeKindMutationResponse>;
  /** delete single row from the table: "trade_kind" */
  deleteTradeKindByPk?: Maybe<TradeKind>;
  /** delete data from the table: "transaction" */
  deleteTransaction?: Maybe<TransactionMutationResponse>;
  /** delete single row from the table: "transaction" */
  deleteTransactionByPk?: Maybe<Transaction>;
  /** delete data from the table: "transaction_kind" */
  deleteTransactionKind?: Maybe<TransactionKindMutationResponse>;
  /** delete single row from the table: "transaction_kind" */
  deleteTransactionKindByPk?: Maybe<TransactionKind>;
  /** delete data from the table: "user" */
  deleteUser?: Maybe<UserMutationResponse>;
  /** delete data from the table: "user_auth" */
  deleteUserAuth?: Maybe<UserAuthMutationResponse>;
  /** delete single row from the table: "user_auth" */
  deleteUserAuthByPk?: Maybe<UserAuth>;
  /** delete single row from the table: "user" */
  deleteUserByPk?: Maybe<User>;
  /** delete data from the table: "wallet" */
  deleteWallet?: Maybe<WalletMutationResponse>;
  /** delete single row from the table: "wallet" */
  deleteWalletByPk?: Maybe<Wallet>;
  /** delete data from the table: "wallet_inventory" */
  deleteWalletInventory?: Maybe<WalletInventoryMutationResponse>;
  /** delete single row from the table: "wallet_inventory" */
  deleteWalletInventoryByPk?: Maybe<WalletInventory>;
  /** delete data from the table: "wallet_kind" */
  deleteWalletKind?: Maybe<WalletKindMutationResponse>;
  /** delete single row from the table: "wallet_kind" */
  deleteWalletKindByPk?: Maybe<WalletKind>;
  /** insert data into the table: "address_attributes" */
  insertAddressAttributes?: Maybe<AddressAttributesMutationResponse>;
  /** insert a single row into the table: "address_attributes" */
  insertAddressAttributesOne?: Maybe<AddressAttributes>;
  /** insert data into the table: "appraisal" */
  insertAppraisal?: Maybe<AppraisalMutationResponse>;
  /** insert a single row into the table: "appraisal" */
  insertAppraisalOne?: Maybe<Appraisal>;
  /** insert data into the table: "ask" */
  insertAsk?: Maybe<AskMutationResponse>;
  /** insert a single row into the table: "ask" */
  insertAskOne?: Maybe<Ask>;
  /** insert data into the table: "ask_put_transaction" */
  insertAskPutTransaction?: Maybe<AskPutTransactionMutationResponse>;
  /** insert a single row into the table: "ask_put_transaction" */
  insertAskPutTransactionOne?: Maybe<AskPutTransaction>;
  /** insert data into the table: "asset" */
  insertAsset?: Maybe<AssetMutationResponse>;
  /** insert data into the table: "asset_account" */
  insertAssetAccount?: Maybe<AssetAccountMutationResponse>;
  /** insert a single row into the table: "asset_account" */
  insertAssetAccountOne?: Maybe<AssetAccount>;
  /** insert data into the table: "asset_kind" */
  insertAssetKind?: Maybe<AssetKindMutationResponse>;
  /** insert a single row into the table: "asset_kind" */
  insertAssetKindOne?: Maybe<AssetKind>;
  /** insert a single row into the table: "asset" */
  insertAssetOne?: Maybe<Asset>;
  /** insert data into the table: "cache" */
  insertCache?: Maybe<CacheMutationResponse>;
  /** insert a single row into the table: "cache" */
  insertCacheOne?: Maybe<Cache>;
  /** insert data into the table: "cart" */
  insertCart?: Maybe<CartMutationResponse>;
  /** insert a single row into the table: "cart" */
  insertCartOne?: Maybe<Cart>;
  /** insert data into the table: "certification" */
  insertCertification?: Maybe<CertificationMutationResponse>;
  /** insert a single row into the table: "certification" */
  insertCertificationOne?: Maybe<Certification>;
  /** insert data into the table: "clearinghouse" */
  insertClearinghouse?: Maybe<ClearinghouseMutationResponse>;
  /** insert data into the table: "clearinghouse_certification" */
  insertClearinghouseCertification?: Maybe<ClearinghouseCertificationMutationResponse>;
  /** insert a single row into the table: "clearinghouse_certification" */
  insertClearinghouseCertificationOne?: Maybe<ClearinghouseCertification>;
  /** insert data into the table: "clearinghouse_kind" */
  insertClearinghouseKind?: Maybe<ClearinghouseKindMutationResponse>;
  /** insert a single row into the table: "clearinghouse_kind" */
  insertClearinghouseKindOne?: Maybe<ClearinghouseKind>;
  /** insert a single row into the table: "clearinghouse" */
  insertClearinghouseOne?: Maybe<Clearinghouse>;
  /** insert data into the table: "country" */
  insertCountry?: Maybe<CountryMutationResponse>;
  /** insert a single row into the table: "country" */
  insertCountryOne?: Maybe<Country>;
  /** insert data into the table: "country_region" */
  insertCountryRegion?: Maybe<CountryRegionMutationResponse>;
  /** insert a single row into the table: "country_region" */
  insertCountryRegionOne?: Maybe<CountryRegion>;
  /** insert data into the table: "currency" */
  insertCurrency?: Maybe<CurrencyMutationResponse>;
  /** insert data into the table: "currency_kind" */
  insertCurrencyKind?: Maybe<CurrencyKindMutationResponse>;
  /** insert a single row into the table: "currency_kind" */
  insertCurrencyKindOne?: Maybe<CurrencyKind>;
  /** insert a single row into the table: "currency" */
  insertCurrencyOne?: Maybe<Currency>;
  /** insert data into the table: "currency_owner" */
  insertCurrencyOwner?: Maybe<CurrencyOwnerMutationResponse>;
  /** insert a single row into the table: "currency_owner" */
  insertCurrencyOwnerOne?: Maybe<CurrencyOwner>;
  /** insert data into the table: "datasource" */
  insertDatasource?: Maybe<DatasourceMutationResponse>;
  /** insert a single row into the table: "datasource" */
  insertDatasourceOne?: Maybe<Datasource>;
  /** insert data into the table: "document_user" */
  insertDocumentUser?: Maybe<DocumentUserMutationResponse>;
  /** insert a single row into the table: "document_user" */
  insertDocumentUserOne?: Maybe<DocumentUser>;
  /** insert data into the table: "exchange" */
  insertExchange?: Maybe<ExchangeMutationResponse>;
  /** insert data into the table: "exchange_kind" */
  insertExchangeKind?: Maybe<ExchangeKindMutationResponse>;
  /** insert a single row into the table: "exchange_kind" */
  insertExchangeKindOne?: Maybe<ExchangeKind>;
  /** insert a single row into the table: "exchange" */
  insertExchangeOne?: Maybe<Exchange>;
  /** insert data into the table: "favorite" */
  insertFavorite?: Maybe<FavoriteMutationResponse>;
  /** insert data into the table: "favorite_comment" */
  insertFavoriteComment?: Maybe<FavoriteCommentMutationResponse>;
  /** insert a single row into the table: "favorite_comment" */
  insertFavoriteCommentOne?: Maybe<FavoriteComment>;
  /** insert a single row into the table: "favorite" */
  insertFavoriteOne?: Maybe<Favorite>;
  /** insert data into the table: "gics_sector" */
  insertGicsSector?: Maybe<GicsSectorMutationResponse>;
  /** insert a single row into the table: "gics_sector" */
  insertGicsSectorOne?: Maybe<GicsSector>;
  /** insert data into the table: "group" */
  insertGroup?: Maybe<GroupMutationResponse>;
  /** insert a single row into the table: "group" */
  insertGroupOne?: Maybe<Group>;
  /** insert data into the table: "industry" */
  insertIndustry?: Maybe<IndustryMutationResponse>;
  /** insert a single row into the table: "industry" */
  insertIndustryOne?: Maybe<Industry>;
  /** insert data into the table: "interaction" */
  insertInteraction?: Maybe<InteractionMutationResponse>;
  /** insert a single row into the table: "interaction" */
  insertInteractionOne?: Maybe<Interaction>;
  /** insert data into the table: "kyc_kind" */
  insertKycKind?: Maybe<KycKindMutationResponse>;
  /** insert a single row into the table: "kyc_kind" */
  insertKycKindOne?: Maybe<KycKind>;
  /** insert data into the table: "kyc_status" */
  insertKycStatus?: Maybe<KycStatusMutationResponse>;
  /** insert a single row into the table: "kyc_status" */
  insertKycStatusOne?: Maybe<KycStatus>;
  /** insert data into the table: "market" */
  insertMarket?: Maybe<MarketMutationResponse>;
  /** insert a single row into the table: "market" */
  insertMarketOne?: Maybe<Market>;
  /** insert data into the table: "material" */
  insertMaterial?: Maybe<MaterialMutationResponse>;
  /** insert a single row into the table: "material" */
  insertMaterialOne?: Maybe<Material>;
  /** insert data into the table: "merchant" */
  insertMerchant?: Maybe<MerchantMutationResponse>;
  /** insert data into the table: "merchant_currency" */
  insertMerchantCurrency?: Maybe<MerchantCurrencyMutationResponse>;
  /** insert a single row into the table: "merchant_currency" */
  insertMerchantCurrencyOne?: Maybe<MerchantCurrency>;
  /** insert a single row into the table: "merchant" */
  insertMerchantOne?: Maybe<Merchant>;
  /** insert data into the table: "merchant_organization" */
  insertMerchantOrganization?: Maybe<MerchantOrganizationMutationResponse>;
  /** insert a single row into the table: "merchant_organization" */
  insertMerchantOrganizationOne?: Maybe<MerchantOrganization>;
  /** insert data into the table: "order" */
  insertOrder?: Maybe<OrderMutationResponse>;
  /** insert a single row into the table: "order" */
  insertOrderOne?: Maybe<Order>;
  /** insert data into the table: "organization" */
  insertOrganization?: Maybe<OrganizationMutationResponse>;
  /** insert data into the table: "organization_kind" */
  insertOrganizationKind?: Maybe<OrganizationKindMutationResponse>;
  /** insert a single row into the table: "organization_kind" */
  insertOrganizationKindOne?: Maybe<OrganizationKind>;
  /** insert a single row into the table: "organization" */
  insertOrganizationOne?: Maybe<Organization>;
  /** insert data into the table: "organization_person" */
  insertOrganizationPerson?: Maybe<OrganizationPersonMutationResponse>;
  /** insert a single row into the table: "organization_person" */
  insertOrganizationPersonOne?: Maybe<OrganizationPerson>;
  /** insert data into the table: "organization_status" */
  insertOrganizationStatus?: Maybe<OrganizationStatusMutationResponse>;
  /** insert a single row into the table: "organization_status" */
  insertOrganizationStatusOne?: Maybe<OrganizationStatus>;
  /** insert data into the table: "password_reset" */
  insertPasswordReset?: Maybe<PasswordResetMutationResponse>;
  /** insert a single row into the table: "password_reset" */
  insertPasswordResetOne?: Maybe<PasswordReset>;
  /** insert data into the table: "payment" */
  insertPayment?: Maybe<PaymentMutationResponse>;
  /** insert a single row into the table: "payment" */
  insertPaymentOne?: Maybe<Payment>;
  /** insert data into the table: "product" */
  insertProduct?: Maybe<ProductMutationResponse>;
  /** insert data into the table: "product_distribution" */
  insertProductDistribution?: Maybe<ProductDistributionMutationResponse>;
  /** insert a single row into the table: "product_distribution" */
  insertProductDistributionOne?: Maybe<ProductDistribution>;
  /** insert data into the table: "product_kind" */
  insertProductKind?: Maybe<ProductKindMutationResponse>;
  /** insert a single row into the table: "product_kind" */
  insertProductKindOne?: Maybe<ProductKind>;
  /** insert a single row into the table: "product" */
  insertProductOne?: Maybe<Product>;
  /** insert data into the table: "product_status" */
  insertProductStatus?: Maybe<ProductStatusMutationResponse>;
  /** insert a single row into the table: "product_status" */
  insertProductStatusOne?: Maybe<ProductStatus>;
  /** insert data into the table: "put" */
  insertPut?: Maybe<PutMutationResponse>;
  /** insert a single row into the table: "put" */
  insertPutOne?: Maybe<Put>;
  /** insert data into the table: "setting" */
  insertSetting?: Maybe<SettingMutationResponse>;
  /** insert a single row into the table: "setting" */
  insertSettingOne?: Maybe<Setting>;
  /** insert data into the table: "shipping" */
  insertShipping?: Maybe<ShippingMutationResponse>;
  /** insert a single row into the table: "shipping" */
  insertShippingOne?: Maybe<Shipping>;
  /** insert data into the table: "shop" */
  insertShop?: Maybe<ShopMutationResponse>;
  /** insert a single row into the table: "shop" */
  insertShopOne?: Maybe<Shop>;
  /** insert data into the table: "sic_code" */
  insertSicCode?: Maybe<SicCodeMutationResponse>;
  /** insert a single row into the table: "sic_code" */
  insertSicCodeOne?: Maybe<SicCode>;
  /** insert data into the table: "sigma_identity" */
  insertSigmaIdentity?: Maybe<SigmaIdentityMutationResponse>;
  /** insert a single row into the table: "sigma_identity" */
  insertSigmaIdentityOne?: Maybe<SigmaIdentity>;
  /** insert data into the table: "sigma_prime" */
  insertSigmaPrime?: Maybe<SigmaPrimeMutationResponse>;
  /** insert data into the table: "sigma_prime_address" */
  insertSigmaPrimeAddress?: Maybe<SigmaPrimeAddressMutationResponse>;
  /** insert a single row into the table: "sigma_prime_address" */
  insertSigmaPrimeAddressOne?: Maybe<SigmaPrimeAddress>;
  /** insert data into the table: "sigma_prime_kind" */
  insertSigmaPrimeKind?: Maybe<SigmaPrimeKindMutationResponse>;
  /** insert a single row into the table: "sigma_prime_kind" */
  insertSigmaPrimeKindOne?: Maybe<SigmaPrimeKind>;
  /** insert a single row into the table: "sigma_prime" */
  insertSigmaPrimeOne?: Maybe<SigmaPrime>;
  /** insert data into the table: "sigma_social" */
  insertSigmaSocial?: Maybe<SigmaSocialMutationResponse>;
  /** insert a single row into the table: "sigma_social" */
  insertSigmaSocialOne?: Maybe<SigmaSocial>;
  /** insert data into the table: "smart_contract" */
  insertSmartContract?: Maybe<SmartContractMutationResponse>;
  /** insert data into the table: "smart_contract_commission" */
  insertSmartContractCommission?: Maybe<SmartContractCommissionMutationResponse>;
  /** insert a single row into the table: "smart_contract_commission" */
  insertSmartContractCommissionOne?: Maybe<SmartContractCommission>;
  /** insert data into the table: "smart_contract_document" */
  insertSmartContractDocument?: Maybe<SmartContractDocumentMutationResponse>;
  /** insert a single row into the table: "smart_contract_document" */
  insertSmartContractDocumentOne?: Maybe<SmartContractDocument>;
  /** insert data into the table: "smart_contract_kind" */
  insertSmartContractKind?: Maybe<SmartContractKindMutationResponse>;
  /** insert a single row into the table: "smart_contract_kind" */
  insertSmartContractKindOne?: Maybe<SmartContractKind>;
  /** insert data into the table: "smart_contract_lineage" */
  insertSmartContractLineage?: Maybe<SmartContractLineageMutationResponse>;
  /** insert a single row into the table: "smart_contract_lineage" */
  insertSmartContractLineageOne?: Maybe<SmartContractLineage>;
  /** insert a single row into the table: "smart_contract" */
  insertSmartContractOne?: Maybe<SmartContract>;
  /** insert data into the table: "state" */
  insertState?: Maybe<StateMutationResponse>;
  /** insert a single row into the table: "state" */
  insertStateOne?: Maybe<State>;
  /** insert data into the table: "status" */
  insertStatus?: Maybe<StatusMutationResponse>;
  /** insert data into the table: "status_kind" */
  insertStatusKind?: Maybe<StatusKindMutationResponse>;
  /** insert a single row into the table: "status_kind" */
  insertStatusKindOne?: Maybe<StatusKind>;
  /** insert a single row into the table: "status" */
  insertStatusOne?: Maybe<Status>;
  /** insert data into the table: "subindustry" */
  insertSubindustry?: Maybe<SubindustryMutationResponse>;
  /** insert a single row into the table: "subindustry" */
  insertSubindustryOne?: Maybe<Subindustry>;
  /** insert data into the table: "supply_chain_waypoint" */
  insertSupplyChainWaypoint?: Maybe<SupplyChainWaypointMutationResponse>;
  /** insert a single row into the table: "supply_chain_waypoint" */
  insertSupplyChainWaypointOne?: Maybe<SupplyChainWaypoint>;
  /** insert data into the table: "trade" */
  insertTrade?: Maybe<TradeMutationResponse>;
  /** insert data into the table: "trade_kind" */
  insertTradeKind?: Maybe<TradeKindMutationResponse>;
  /** insert a single row into the table: "trade_kind" */
  insertTradeKindOne?: Maybe<TradeKind>;
  /** insert a single row into the table: "trade" */
  insertTradeOne?: Maybe<Trade>;
  /** insert data into the table: "transaction" */
  insertTransaction?: Maybe<TransactionMutationResponse>;
  /** insert data into the table: "transaction_kind" */
  insertTransactionKind?: Maybe<TransactionKindMutationResponse>;
  /** insert a single row into the table: "transaction_kind" */
  insertTransactionKindOne?: Maybe<TransactionKind>;
  /** insert a single row into the table: "transaction" */
  insertTransactionOne?: Maybe<Transaction>;
  /** insert data into the table: "user" */
  insertUser?: Maybe<UserMutationResponse>;
  /** insert data into the table: "user_auth" */
  insertUserAuth?: Maybe<UserAuthMutationResponse>;
  /** insert a single row into the table: "user_auth" */
  insertUserAuthOne?: Maybe<UserAuth>;
  /** insert a single row into the table: "user" */
  insertUserOne?: Maybe<User>;
  /** insert data into the table: "wallet" */
  insertWallet?: Maybe<WalletMutationResponse>;
  /** insert data into the table: "wallet_inventory" */
  insertWalletInventory?: Maybe<WalletInventoryMutationResponse>;
  /** insert a single row into the table: "wallet_inventory" */
  insertWalletInventoryOne?: Maybe<WalletInventory>;
  /** insert data into the table: "wallet_kind" */
  insertWalletKind?: Maybe<WalletKindMutationResponse>;
  /** insert a single row into the table: "wallet_kind" */
  insertWalletKindOne?: Maybe<WalletKind>;
  /** insert a single row into the table: "wallet" */
  insertWalletOne?: Maybe<Wallet>;
  /** update data of the table: "address_attributes" */
  updateAddressAttributes?: Maybe<AddressAttributesMutationResponse>;
  /** update single row of the table: "address_attributes" */
  updateAddressAttributesByPk?: Maybe<AddressAttributes>;
  /** update multiples rows of table: "address_attributes" */
  updateAddressAttributesMany?: Maybe<Array<Maybe<AddressAttributesMutationResponse>>>;
  /** update data of the table: "appraisal" */
  updateAppraisal?: Maybe<AppraisalMutationResponse>;
  /** update single row of the table: "appraisal" */
  updateAppraisalByPk?: Maybe<Appraisal>;
  /** update multiples rows of table: "appraisal" */
  updateAppraisalMany?: Maybe<Array<Maybe<AppraisalMutationResponse>>>;
  /** update data of the table: "ask" */
  updateAsk?: Maybe<AskMutationResponse>;
  /** update single row of the table: "ask" */
  updateAskByPk?: Maybe<Ask>;
  /** update multiples rows of table: "ask" */
  updateAskMany?: Maybe<Array<Maybe<AskMutationResponse>>>;
  /** update data of the table: "ask_put_transaction" */
  updateAskPutTransaction?: Maybe<AskPutTransactionMutationResponse>;
  /** update single row of the table: "ask_put_transaction" */
  updateAskPutTransactionByPk?: Maybe<AskPutTransaction>;
  /** update multiples rows of table: "ask_put_transaction" */
  updateAskPutTransactionMany?: Maybe<Array<Maybe<AskPutTransactionMutationResponse>>>;
  /** update data of the table: "asset" */
  updateAsset?: Maybe<AssetMutationResponse>;
  /** update data of the table: "asset_account" */
  updateAssetAccount?: Maybe<AssetAccountMutationResponse>;
  /** update single row of the table: "asset_account" */
  updateAssetAccountByPk?: Maybe<AssetAccount>;
  /** update multiples rows of table: "asset_account" */
  updateAssetAccountMany?: Maybe<Array<Maybe<AssetAccountMutationResponse>>>;
  /** update single row of the table: "asset" */
  updateAssetByPk?: Maybe<Asset>;
  /** update data of the table: "asset_kind" */
  updateAssetKind?: Maybe<AssetKindMutationResponse>;
  /** update single row of the table: "asset_kind" */
  updateAssetKindByPk?: Maybe<AssetKind>;
  /** update multiples rows of table: "asset_kind" */
  updateAssetKindMany?: Maybe<Array<Maybe<AssetKindMutationResponse>>>;
  /** update multiples rows of table: "asset" */
  updateAssetMany?: Maybe<Array<Maybe<AssetMutationResponse>>>;
  /** update data of the table: "cache" */
  updateCache?: Maybe<CacheMutationResponse>;
  /** update single row of the table: "cache" */
  updateCacheByPk?: Maybe<Cache>;
  /** update multiples rows of table: "cache" */
  updateCacheMany?: Maybe<Array<Maybe<CacheMutationResponse>>>;
  /** update data of the table: "cart" */
  updateCart?: Maybe<CartMutationResponse>;
  /** update single row of the table: "cart" */
  updateCartByPk?: Maybe<Cart>;
  /** update multiples rows of table: "cart" */
  updateCartMany?: Maybe<Array<Maybe<CartMutationResponse>>>;
  /** update data of the table: "certification" */
  updateCertification?: Maybe<CertificationMutationResponse>;
  /** update single row of the table: "certification" */
  updateCertificationByPk?: Maybe<Certification>;
  /** update multiples rows of table: "certification" */
  updateCertificationMany?: Maybe<Array<Maybe<CertificationMutationResponse>>>;
  /** update data of the table: "clearinghouse" */
  updateClearinghouse?: Maybe<ClearinghouseMutationResponse>;
  /** update single row of the table: "clearinghouse" */
  updateClearinghouseByPk?: Maybe<Clearinghouse>;
  /** update data of the table: "clearinghouse_certification" */
  updateClearinghouseCertification?: Maybe<ClearinghouseCertificationMutationResponse>;
  /** update single row of the table: "clearinghouse_certification" */
  updateClearinghouseCertificationByPk?: Maybe<ClearinghouseCertification>;
  /** update multiples rows of table: "clearinghouse_certification" */
  updateClearinghouseCertificationMany?: Maybe<Array<Maybe<ClearinghouseCertificationMutationResponse>>>;
  /** update data of the table: "clearinghouse_kind" */
  updateClearinghouseKind?: Maybe<ClearinghouseKindMutationResponse>;
  /** update single row of the table: "clearinghouse_kind" */
  updateClearinghouseKindByPk?: Maybe<ClearinghouseKind>;
  /** update multiples rows of table: "clearinghouse_kind" */
  updateClearinghouseKindMany?: Maybe<Array<Maybe<ClearinghouseKindMutationResponse>>>;
  /** update multiples rows of table: "clearinghouse" */
  updateClearinghouseMany?: Maybe<Array<Maybe<ClearinghouseMutationResponse>>>;
  /** update data of the table: "country" */
  updateCountry?: Maybe<CountryMutationResponse>;
  /** update single row of the table: "country" */
  updateCountryByPk?: Maybe<Country>;
  /** update multiples rows of table: "country" */
  updateCountryMany?: Maybe<Array<Maybe<CountryMutationResponse>>>;
  /** update data of the table: "country_region" */
  updateCountryRegion?: Maybe<CountryRegionMutationResponse>;
  /** update single row of the table: "country_region" */
  updateCountryRegionByPk?: Maybe<CountryRegion>;
  /** update multiples rows of table: "country_region" */
  updateCountryRegionMany?: Maybe<Array<Maybe<CountryRegionMutationResponse>>>;
  /** update data of the table: "currency" */
  updateCurrency?: Maybe<CurrencyMutationResponse>;
  /** update single row of the table: "currency" */
  updateCurrencyByPk?: Maybe<Currency>;
  /** update data of the table: "currency_kind" */
  updateCurrencyKind?: Maybe<CurrencyKindMutationResponse>;
  /** update single row of the table: "currency_kind" */
  updateCurrencyKindByPk?: Maybe<CurrencyKind>;
  /** update multiples rows of table: "currency_kind" */
  updateCurrencyKindMany?: Maybe<Array<Maybe<CurrencyKindMutationResponse>>>;
  /** update multiples rows of table: "currency" */
  updateCurrencyMany?: Maybe<Array<Maybe<CurrencyMutationResponse>>>;
  /** update data of the table: "currency_owner" */
  updateCurrencyOwner?: Maybe<CurrencyOwnerMutationResponse>;
  /** update single row of the table: "currency_owner" */
  updateCurrencyOwnerByPk?: Maybe<CurrencyOwner>;
  /** update multiples rows of table: "currency_owner" */
  updateCurrencyOwnerMany?: Maybe<Array<Maybe<CurrencyOwnerMutationResponse>>>;
  /** update data of the table: "datasource" */
  updateDatasource?: Maybe<DatasourceMutationResponse>;
  /** update single row of the table: "datasource" */
  updateDatasourceByPk?: Maybe<Datasource>;
  /** update multiples rows of table: "datasource" */
  updateDatasourceMany?: Maybe<Array<Maybe<DatasourceMutationResponse>>>;
  /** update data of the table: "document_user" */
  updateDocumentUser?: Maybe<DocumentUserMutationResponse>;
  /** update single row of the table: "document_user" */
  updateDocumentUserByPk?: Maybe<DocumentUser>;
  /** update multiples rows of table: "document_user" */
  updateDocumentUserMany?: Maybe<Array<Maybe<DocumentUserMutationResponse>>>;
  /** update data of the table: "exchange" */
  updateExchange?: Maybe<ExchangeMutationResponse>;
  /** update single row of the table: "exchange" */
  updateExchangeByPk?: Maybe<Exchange>;
  /** update data of the table: "exchange_kind" */
  updateExchangeKind?: Maybe<ExchangeKindMutationResponse>;
  /** update single row of the table: "exchange_kind" */
  updateExchangeKindByPk?: Maybe<ExchangeKind>;
  /** update multiples rows of table: "exchange_kind" */
  updateExchangeKindMany?: Maybe<Array<Maybe<ExchangeKindMutationResponse>>>;
  /** update multiples rows of table: "exchange" */
  updateExchangeMany?: Maybe<Array<Maybe<ExchangeMutationResponse>>>;
  /** update data of the table: "favorite" */
  updateFavorite?: Maybe<FavoriteMutationResponse>;
  /** update single row of the table: "favorite" */
  updateFavoriteByPk?: Maybe<Favorite>;
  /** update data of the table: "favorite_comment" */
  updateFavoriteComment?: Maybe<FavoriteCommentMutationResponse>;
  /** update single row of the table: "favorite_comment" */
  updateFavoriteCommentByPk?: Maybe<FavoriteComment>;
  /** update multiples rows of table: "favorite_comment" */
  updateFavoriteCommentMany?: Maybe<Array<Maybe<FavoriteCommentMutationResponse>>>;
  /** update multiples rows of table: "favorite" */
  updateFavoriteMany?: Maybe<Array<Maybe<FavoriteMutationResponse>>>;
  /** update data of the table: "gics_sector" */
  updateGicsSector?: Maybe<GicsSectorMutationResponse>;
  /** update single row of the table: "gics_sector" */
  updateGicsSectorByPk?: Maybe<GicsSector>;
  /** update multiples rows of table: "gics_sector" */
  updateGicsSectorMany?: Maybe<Array<Maybe<GicsSectorMutationResponse>>>;
  /** update data of the table: "group" */
  updateGroup?: Maybe<GroupMutationResponse>;
  /** update single row of the table: "group" */
  updateGroupByPk?: Maybe<Group>;
  /** update multiples rows of table: "group" */
  updateGroupMany?: Maybe<Array<Maybe<GroupMutationResponse>>>;
  /** update data of the table: "industry" */
  updateIndustry?: Maybe<IndustryMutationResponse>;
  /** update single row of the table: "industry" */
  updateIndustryByPk?: Maybe<Industry>;
  /** update multiples rows of table: "industry" */
  updateIndustryMany?: Maybe<Array<Maybe<IndustryMutationResponse>>>;
  /** update data of the table: "interaction" */
  updateInteraction?: Maybe<InteractionMutationResponse>;
  /** update single row of the table: "interaction" */
  updateInteractionByPk?: Maybe<Interaction>;
  /** update multiples rows of table: "interaction" */
  updateInteractionMany?: Maybe<Array<Maybe<InteractionMutationResponse>>>;
  /** update data of the table: "kyc_kind" */
  updateKycKind?: Maybe<KycKindMutationResponse>;
  /** update single row of the table: "kyc_kind" */
  updateKycKindByPk?: Maybe<KycKind>;
  /** update multiples rows of table: "kyc_kind" */
  updateKycKindMany?: Maybe<Array<Maybe<KycKindMutationResponse>>>;
  /** update data of the table: "kyc_status" */
  updateKycStatus?: Maybe<KycStatusMutationResponse>;
  /** update single row of the table: "kyc_status" */
  updateKycStatusByPk?: Maybe<KycStatus>;
  /** update multiples rows of table: "kyc_status" */
  updateKycStatusMany?: Maybe<Array<Maybe<KycStatusMutationResponse>>>;
  /** update data of the table: "market" */
  updateMarket?: Maybe<MarketMutationResponse>;
  /** update single row of the table: "market" */
  updateMarketByPk?: Maybe<Market>;
  /** update multiples rows of table: "market" */
  updateMarketMany?: Maybe<Array<Maybe<MarketMutationResponse>>>;
  /** update data of the table: "material" */
  updateMaterial?: Maybe<MaterialMutationResponse>;
  /** update single row of the table: "material" */
  updateMaterialByPk?: Maybe<Material>;
  /** update multiples rows of table: "material" */
  updateMaterialMany?: Maybe<Array<Maybe<MaterialMutationResponse>>>;
  /** update data of the table: "merchant" */
  updateMerchant?: Maybe<MerchantMutationResponse>;
  /** update single row of the table: "merchant" */
  updateMerchantByPk?: Maybe<Merchant>;
  /** update data of the table: "merchant_currency" */
  updateMerchantCurrency?: Maybe<MerchantCurrencyMutationResponse>;
  /** update single row of the table: "merchant_currency" */
  updateMerchantCurrencyByPk?: Maybe<MerchantCurrency>;
  /** update multiples rows of table: "merchant_currency" */
  updateMerchantCurrencyMany?: Maybe<Array<Maybe<MerchantCurrencyMutationResponse>>>;
  /** update multiples rows of table: "merchant" */
  updateMerchantMany?: Maybe<Array<Maybe<MerchantMutationResponse>>>;
  /** update data of the table: "merchant_organization" */
  updateMerchantOrganization?: Maybe<MerchantOrganizationMutationResponse>;
  /** update single row of the table: "merchant_organization" */
  updateMerchantOrganizationByPk?: Maybe<MerchantOrganization>;
  /** update multiples rows of table: "merchant_organization" */
  updateMerchantOrganizationMany?: Maybe<Array<Maybe<MerchantOrganizationMutationResponse>>>;
  /** update data of the table: "order" */
  updateOrder?: Maybe<OrderMutationResponse>;
  /** update single row of the table: "order" */
  updateOrderByPk?: Maybe<Order>;
  /** update multiples rows of table: "order" */
  updateOrderMany?: Maybe<Array<Maybe<OrderMutationResponse>>>;
  /** update data of the table: "organization" */
  updateOrganization?: Maybe<OrganizationMutationResponse>;
  /** update single row of the table: "organization" */
  updateOrganizationByPk?: Maybe<Organization>;
  /** update data of the table: "organization_kind" */
  updateOrganizationKind?: Maybe<OrganizationKindMutationResponse>;
  /** update single row of the table: "organization_kind" */
  updateOrganizationKindByPk?: Maybe<OrganizationKind>;
  /** update multiples rows of table: "organization_kind" */
  updateOrganizationKindMany?: Maybe<Array<Maybe<OrganizationKindMutationResponse>>>;
  /** update multiples rows of table: "organization" */
  updateOrganizationMany?: Maybe<Array<Maybe<OrganizationMutationResponse>>>;
  /** update data of the table: "organization_person" */
  updateOrganizationPerson?: Maybe<OrganizationPersonMutationResponse>;
  /** update single row of the table: "organization_person" */
  updateOrganizationPersonByPk?: Maybe<OrganizationPerson>;
  /** update multiples rows of table: "organization_person" */
  updateOrganizationPersonMany?: Maybe<Array<Maybe<OrganizationPersonMutationResponse>>>;
  /** update data of the table: "organization_status" */
  updateOrganizationStatus?: Maybe<OrganizationStatusMutationResponse>;
  /** update single row of the table: "organization_status" */
  updateOrganizationStatusByPk?: Maybe<OrganizationStatus>;
  /** update multiples rows of table: "organization_status" */
  updateOrganizationStatusMany?: Maybe<Array<Maybe<OrganizationStatusMutationResponse>>>;
  /** update data of the table: "password_reset" */
  updatePasswordReset?: Maybe<PasswordResetMutationResponse>;
  /** update single row of the table: "password_reset" */
  updatePasswordResetByPk?: Maybe<PasswordReset>;
  /** update multiples rows of table: "password_reset" */
  updatePasswordResetMany?: Maybe<Array<Maybe<PasswordResetMutationResponse>>>;
  /** update data of the table: "payment" */
  updatePayment?: Maybe<PaymentMutationResponse>;
  /** update single row of the table: "payment" */
  updatePaymentByPk?: Maybe<Payment>;
  /** update multiples rows of table: "payment" */
  updatePaymentMany?: Maybe<Array<Maybe<PaymentMutationResponse>>>;
  /** update data of the table: "product" */
  updateProduct?: Maybe<ProductMutationResponse>;
  /** update single row of the table: "product" */
  updateProductByPk?: Maybe<Product>;
  /** update data of the table: "product_distribution" */
  updateProductDistribution?: Maybe<ProductDistributionMutationResponse>;
  /** update single row of the table: "product_distribution" */
  updateProductDistributionByPk?: Maybe<ProductDistribution>;
  /** update multiples rows of table: "product_distribution" */
  updateProductDistributionMany?: Maybe<Array<Maybe<ProductDistributionMutationResponse>>>;
  /** update data of the table: "product_kind" */
  updateProductKind?: Maybe<ProductKindMutationResponse>;
  /** update single row of the table: "product_kind" */
  updateProductKindByPk?: Maybe<ProductKind>;
  /** update multiples rows of table: "product_kind" */
  updateProductKindMany?: Maybe<Array<Maybe<ProductKindMutationResponse>>>;
  /** update multiples rows of table: "product" */
  updateProductMany?: Maybe<Array<Maybe<ProductMutationResponse>>>;
  /** update data of the table: "product_status" */
  updateProductStatus?: Maybe<ProductStatusMutationResponse>;
  /** update single row of the table: "product_status" */
  updateProductStatusByPk?: Maybe<ProductStatus>;
  /** update multiples rows of table: "product_status" */
  updateProductStatusMany?: Maybe<Array<Maybe<ProductStatusMutationResponse>>>;
  /** update data of the table: "put" */
  updatePut?: Maybe<PutMutationResponse>;
  /** update single row of the table: "put" */
  updatePutByPk?: Maybe<Put>;
  /** update multiples rows of table: "put" */
  updatePutMany?: Maybe<Array<Maybe<PutMutationResponse>>>;
  /** update data of the table: "setting" */
  updateSetting?: Maybe<SettingMutationResponse>;
  /** update single row of the table: "setting" */
  updateSettingByPk?: Maybe<Setting>;
  /** update multiples rows of table: "setting" */
  updateSettingMany?: Maybe<Array<Maybe<SettingMutationResponse>>>;
  /** update data of the table: "shipping" */
  updateShipping?: Maybe<ShippingMutationResponse>;
  /** update single row of the table: "shipping" */
  updateShippingByPk?: Maybe<Shipping>;
  /** update multiples rows of table: "shipping" */
  updateShippingMany?: Maybe<Array<Maybe<ShippingMutationResponse>>>;
  /** update data of the table: "shop" */
  updateShop?: Maybe<ShopMutationResponse>;
  /** update single row of the table: "shop" */
  updateShopByPk?: Maybe<Shop>;
  /** update multiples rows of table: "shop" */
  updateShopMany?: Maybe<Array<Maybe<ShopMutationResponse>>>;
  /** update data of the table: "sic_code" */
  updateSicCode?: Maybe<SicCodeMutationResponse>;
  /** update single row of the table: "sic_code" */
  updateSicCodeByPk?: Maybe<SicCode>;
  /** update multiples rows of table: "sic_code" */
  updateSicCodeMany?: Maybe<Array<Maybe<SicCodeMutationResponse>>>;
  /** update data of the table: "sigma_identity" */
  updateSigmaIdentity?: Maybe<SigmaIdentityMutationResponse>;
  /** update single row of the table: "sigma_identity" */
  updateSigmaIdentityByPk?: Maybe<SigmaIdentity>;
  /** update multiples rows of table: "sigma_identity" */
  updateSigmaIdentityMany?: Maybe<Array<Maybe<SigmaIdentityMutationResponse>>>;
  /** update data of the table: "sigma_prime" */
  updateSigmaPrime?: Maybe<SigmaPrimeMutationResponse>;
  /** update data of the table: "sigma_prime_address" */
  updateSigmaPrimeAddress?: Maybe<SigmaPrimeAddressMutationResponse>;
  /** update single row of the table: "sigma_prime_address" */
  updateSigmaPrimeAddressByPk?: Maybe<SigmaPrimeAddress>;
  /** update multiples rows of table: "sigma_prime_address" */
  updateSigmaPrimeAddressMany?: Maybe<Array<Maybe<SigmaPrimeAddressMutationResponse>>>;
  /** update single row of the table: "sigma_prime" */
  updateSigmaPrimeByPk?: Maybe<SigmaPrime>;
  /** update data of the table: "sigma_prime_kind" */
  updateSigmaPrimeKind?: Maybe<SigmaPrimeKindMutationResponse>;
  /** update single row of the table: "sigma_prime_kind" */
  updateSigmaPrimeKindByPk?: Maybe<SigmaPrimeKind>;
  /** update multiples rows of table: "sigma_prime_kind" */
  updateSigmaPrimeKindMany?: Maybe<Array<Maybe<SigmaPrimeKindMutationResponse>>>;
  /** update multiples rows of table: "sigma_prime" */
  updateSigmaPrimeMany?: Maybe<Array<Maybe<SigmaPrimeMutationResponse>>>;
  /** update data of the table: "sigma_social" */
  updateSigmaSocial?: Maybe<SigmaSocialMutationResponse>;
  /** update single row of the table: "sigma_social" */
  updateSigmaSocialByPk?: Maybe<SigmaSocial>;
  /** update multiples rows of table: "sigma_social" */
  updateSigmaSocialMany?: Maybe<Array<Maybe<SigmaSocialMutationResponse>>>;
  /** update data of the table: "smart_contract" */
  updateSmartContract?: Maybe<SmartContractMutationResponse>;
  /** update single row of the table: "smart_contract" */
  updateSmartContractByPk?: Maybe<SmartContract>;
  /** update data of the table: "smart_contract_commission" */
  updateSmartContractCommission?: Maybe<SmartContractCommissionMutationResponse>;
  /** update single row of the table: "smart_contract_commission" */
  updateSmartContractCommissionByPk?: Maybe<SmartContractCommission>;
  /** update multiples rows of table: "smart_contract_commission" */
  updateSmartContractCommissionMany?: Maybe<Array<Maybe<SmartContractCommissionMutationResponse>>>;
  /** update data of the table: "smart_contract_document" */
  updateSmartContractDocument?: Maybe<SmartContractDocumentMutationResponse>;
  /** update single row of the table: "smart_contract_document" */
  updateSmartContractDocumentByPk?: Maybe<SmartContractDocument>;
  /** update multiples rows of table: "smart_contract_document" */
  updateSmartContractDocumentMany?: Maybe<Array<Maybe<SmartContractDocumentMutationResponse>>>;
  /** update data of the table: "smart_contract_kind" */
  updateSmartContractKind?: Maybe<SmartContractKindMutationResponse>;
  /** update single row of the table: "smart_contract_kind" */
  updateSmartContractKindByPk?: Maybe<SmartContractKind>;
  /** update multiples rows of table: "smart_contract_kind" */
  updateSmartContractKindMany?: Maybe<Array<Maybe<SmartContractKindMutationResponse>>>;
  /** update data of the table: "smart_contract_lineage" */
  updateSmartContractLineage?: Maybe<SmartContractLineageMutationResponse>;
  /** update single row of the table: "smart_contract_lineage" */
  updateSmartContractLineageByPk?: Maybe<SmartContractLineage>;
  /** update multiples rows of table: "smart_contract_lineage" */
  updateSmartContractLineageMany?: Maybe<Array<Maybe<SmartContractLineageMutationResponse>>>;
  /** update multiples rows of table: "smart_contract" */
  updateSmartContractMany?: Maybe<Array<Maybe<SmartContractMutationResponse>>>;
  /** update data of the table: "state" */
  updateState?: Maybe<StateMutationResponse>;
  /** update multiples rows of table: "state" */
  updateStateMany?: Maybe<Array<Maybe<StateMutationResponse>>>;
  /** update data of the table: "status" */
  updateStatus?: Maybe<StatusMutationResponse>;
  /** update single row of the table: "status" */
  updateStatusByPk?: Maybe<Status>;
  /** update data of the table: "status_kind" */
  updateStatusKind?: Maybe<StatusKindMutationResponse>;
  /** update single row of the table: "status_kind" */
  updateStatusKindByPk?: Maybe<StatusKind>;
  /** update multiples rows of table: "status_kind" */
  updateStatusKindMany?: Maybe<Array<Maybe<StatusKindMutationResponse>>>;
  /** update multiples rows of table: "status" */
  updateStatusMany?: Maybe<Array<Maybe<StatusMutationResponse>>>;
  /** update data of the table: "subindustry" */
  updateSubindustry?: Maybe<SubindustryMutationResponse>;
  /** update single row of the table: "subindustry" */
  updateSubindustryByPk?: Maybe<Subindustry>;
  /** update multiples rows of table: "subindustry" */
  updateSubindustryMany?: Maybe<Array<Maybe<SubindustryMutationResponse>>>;
  /** update data of the table: "supply_chain_waypoint" */
  updateSupplyChainWaypoint?: Maybe<SupplyChainWaypointMutationResponse>;
  /** update single row of the table: "supply_chain_waypoint" */
  updateSupplyChainWaypointByPk?: Maybe<SupplyChainWaypoint>;
  /** update multiples rows of table: "supply_chain_waypoint" */
  updateSupplyChainWaypointMany?: Maybe<Array<Maybe<SupplyChainWaypointMutationResponse>>>;
  /** update data of the table: "trade" */
  updateTrade?: Maybe<TradeMutationResponse>;
  /** update single row of the table: "trade" */
  updateTradeByPk?: Maybe<Trade>;
  /** update data of the table: "trade_kind" */
  updateTradeKind?: Maybe<TradeKindMutationResponse>;
  /** update single row of the table: "trade_kind" */
  updateTradeKindByPk?: Maybe<TradeKind>;
  /** update multiples rows of table: "trade_kind" */
  updateTradeKindMany?: Maybe<Array<Maybe<TradeKindMutationResponse>>>;
  /** update multiples rows of table: "trade" */
  updateTradeMany?: Maybe<Array<Maybe<TradeMutationResponse>>>;
  /** update data of the table: "transaction" */
  updateTransaction?: Maybe<TransactionMutationResponse>;
  /** update single row of the table: "transaction" */
  updateTransactionByPk?: Maybe<Transaction>;
  /** update data of the table: "transaction_kind" */
  updateTransactionKind?: Maybe<TransactionKindMutationResponse>;
  /** update single row of the table: "transaction_kind" */
  updateTransactionKindByPk?: Maybe<TransactionKind>;
  /** update multiples rows of table: "transaction_kind" */
  updateTransactionKindMany?: Maybe<Array<Maybe<TransactionKindMutationResponse>>>;
  /** update multiples rows of table: "transaction" */
  updateTransactionMany?: Maybe<Array<Maybe<TransactionMutationResponse>>>;
  /** update data of the table: "user" */
  updateUser?: Maybe<UserMutationResponse>;
  /** update data of the table: "user_auth" */
  updateUserAuth?: Maybe<UserAuthMutationResponse>;
  /** update single row of the table: "user_auth" */
  updateUserAuthByPk?: Maybe<UserAuth>;
  /** update multiples rows of table: "user_auth" */
  updateUserAuthMany?: Maybe<Array<Maybe<UserAuthMutationResponse>>>;
  /** update single row of the table: "user" */
  updateUserByPk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  updateUserMany?: Maybe<Array<Maybe<UserMutationResponse>>>;
  /** update data of the table: "wallet" */
  updateWallet?: Maybe<WalletMutationResponse>;
  /** update single row of the table: "wallet" */
  updateWalletByPk?: Maybe<Wallet>;
  /** update data of the table: "wallet_inventory" */
  updateWalletInventory?: Maybe<WalletInventoryMutationResponse>;
  /** update single row of the table: "wallet_inventory" */
  updateWalletInventoryByPk?: Maybe<WalletInventory>;
  /** update multiples rows of table: "wallet_inventory" */
  updateWalletInventoryMany?: Maybe<Array<Maybe<WalletInventoryMutationResponse>>>;
  /** update data of the table: "wallet_kind" */
  updateWalletKind?: Maybe<WalletKindMutationResponse>;
  /** update single row of the table: "wallet_kind" */
  updateWalletKindByPk?: Maybe<WalletKind>;
  /** update multiples rows of table: "wallet_kind" */
  updateWalletKindMany?: Maybe<Array<Maybe<WalletKindMutationResponse>>>;
  /** update multiples rows of table: "wallet" */
  updateWalletMany?: Maybe<Array<Maybe<WalletMutationResponse>>>;
  userSignup?: Maybe<UserSignupOutput>;
};

/** mutation root */
export type Mutation_RootDeleteAddressAttributesArgs = {
  where: AddressAttributesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteAddressAttributesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteAppraisalArgs = {
  where: AppraisalBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteAppraisalByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteAskArgs = {
  where: AskBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteAskByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteAskPutTransactionArgs = {
  where: AskPutTransactionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteAskPutTransactionByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteAssetArgs = {
  where: AssetBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteAssetAccountArgs = {
  where: AssetAccountBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteAssetAccountByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteAssetByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteAssetKindArgs = {
  where: AssetKindBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteAssetKindByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCacheArgs = {
  where: CacheBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCacheByPkArgs = {
  key: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteCartArgs = {
  where: CartBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCartByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCertificationArgs = {
  where: CertificationBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCertificationByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClearinghouseArgs = {
  where: ClearinghouseBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClearinghouseByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClearinghouseCertificationArgs = {
  where: ClearinghouseCertificationBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClearinghouseCertificationByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClearinghouseKindArgs = {
  where: ClearinghouseKindBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClearinghouseKindByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCountryArgs = {
  where: CountryBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCountryByPkArgs = {
  code: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteCountryRegionArgs = {
  where: CountryRegionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCountryRegionByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCurrencyArgs = {
  where: CurrencyBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCurrencyByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCurrencyKindArgs = {
  where: CurrencyKindBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCurrencyKindByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCurrencyOwnerArgs = {
  where: CurrencyOwnerBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCurrencyOwnerByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteDatasourceArgs = {
  where: DatasourceBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteDatasourceByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteDocumentUserArgs = {
  where: DocumentUserBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteDocumentUserByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteExchangeArgs = {
  where: ExchangeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteExchangeByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteExchangeKindArgs = {
  where: ExchangeKindBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteExchangeKindByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteFavoriteArgs = {
  where: FavoriteBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteFavoriteByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteFavoriteCommentArgs = {
  where: FavoriteCommentBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteFavoriteCommentByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteGicsSectorArgs = {
  where: GicsSectorBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteGicsSectorByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteGroupArgs = {
  where: GroupBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteGroupByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteIndustryArgs = {
  where: IndustryBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteIndustryByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteInteractionArgs = {
  where: InteractionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteInteractionByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteKycKindArgs = {
  where: KycKindBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteKycKindByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteKycStatusArgs = {
  where: KycStatusBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteKycStatusByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteMarketArgs = {
  where: MarketBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMarketByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteMaterialArgs = {
  where: MaterialBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMaterialByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteMerchantArgs = {
  where: MerchantBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMerchantByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteMerchantCurrencyArgs = {
  where: MerchantCurrencyBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMerchantCurrencyByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteMerchantOrganizationArgs = {
  where: MerchantOrganizationBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMerchantOrganizationByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteOrderArgs = {
  where: OrderBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteOrderByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteOrganizationArgs = {
  where: OrganizationBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteOrganizationByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteOrganizationKindArgs = {
  where: OrganizationKindBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteOrganizationKindByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteOrganizationPersonArgs = {
  where: OrganizationPersonBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteOrganizationPersonByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteOrganizationStatusArgs = {
  where: OrganizationStatusBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteOrganizationStatusByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeletePasswordResetArgs = {
  where: PasswordResetBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePasswordResetByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeletePaymentArgs = {
  where: PaymentBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePaymentByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteProductArgs = {
  where: ProductBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProductByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteProductDistributionArgs = {
  where: ProductDistributionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProductDistributionByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteProductKindArgs = {
  where: ProductKindBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProductKindByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteProductStatusArgs = {
  where: ProductStatusBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProductStatusByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeletePutArgs = {
  where: PutBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePutByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSettingArgs = {
  where: SettingBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSettingByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteShippingArgs = {
  where: ShippingBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteShippingByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteShopArgs = {
  where: ShopBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteShopByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSicCodeArgs = {
  where: SicCodeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSicCodeByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSigmaIdentityArgs = {
  where: SigmaIdentityBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSigmaIdentityByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSigmaPrimeArgs = {
  where: SigmaPrimeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSigmaPrimeAddressArgs = {
  where: SigmaPrimeAddressBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSigmaPrimeAddressByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSigmaPrimeByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSigmaPrimeKindArgs = {
  where: SigmaPrimeKindBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSigmaPrimeKindByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSigmaSocialArgs = {
  where: SigmaSocialBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSigmaSocialByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSmartContractArgs = {
  where: SmartContractBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSmartContractByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSmartContractCommissionArgs = {
  where: SmartContractCommissionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSmartContractCommissionByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSmartContractDocumentArgs = {
  where: SmartContractDocumentBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSmartContractDocumentByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSmartContractKindArgs = {
  where: SmartContractKindBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSmartContractKindByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSmartContractLineageArgs = {
  where: SmartContractLineageBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSmartContractLineageByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteStateArgs = {
  where: StateBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteStatusArgs = {
  where: StatusBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteStatusByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteStatusKindArgs = {
  where: StatusKindBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteStatusKindByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSubindustryArgs = {
  where: SubindustryBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSubindustryByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSupplyChainWaypointArgs = {
  where: SupplyChainWaypointBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSupplyChainWaypointByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteTradeArgs = {
  where: TradeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTradeByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteTradeKindArgs = {
  where: TradeKindBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTradeKindByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteTransactionArgs = {
  where: TransactionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTransactionByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteTransactionKindArgs = {
  where: TransactionKindBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTransactionKindByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  where: UserBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteUserAuthArgs = {
  where: UserAuthBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteUserAuthByPkArgs = {
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteUserByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteWalletArgs = {
  where: WalletBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteWalletByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteWalletInventoryArgs = {
  where: WalletInventoryBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteWalletInventoryByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteWalletKindArgs = {
  where: WalletKindBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteWalletKindByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootInsertAddressAttributesArgs = {
  objects: Array<AddressAttributesInsertInput>;
  onConflict?: InputMaybe<AddressAttributesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAddressAttributesOneArgs = {
  object: AddressAttributesInsertInput;
  onConflict?: InputMaybe<AddressAttributesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAppraisalArgs = {
  objects: Array<AppraisalInsertInput>;
  onConflict?: InputMaybe<AppraisalOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAppraisalOneArgs = {
  object: AppraisalInsertInput;
  onConflict?: InputMaybe<AppraisalOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAskArgs = {
  objects: Array<AskInsertInput>;
  onConflict?: InputMaybe<AskOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAskOneArgs = {
  object: AskInsertInput;
  onConflict?: InputMaybe<AskOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAskPutTransactionArgs = {
  objects: Array<AskPutTransactionInsertInput>;
  onConflict?: InputMaybe<AskPutTransactionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAskPutTransactionOneArgs = {
  object: AskPutTransactionInsertInput;
  onConflict?: InputMaybe<AskPutTransactionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAssetArgs = {
  objects: Array<AssetInsertInput>;
  onConflict?: InputMaybe<AssetOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAssetAccountArgs = {
  objects: Array<AssetAccountInsertInput>;
  onConflict?: InputMaybe<AssetAccountOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAssetAccountOneArgs = {
  object: AssetAccountInsertInput;
  onConflict?: InputMaybe<AssetAccountOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAssetKindArgs = {
  objects: Array<AssetKindInsertInput>;
  onConflict?: InputMaybe<AssetKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAssetKindOneArgs = {
  object: AssetKindInsertInput;
  onConflict?: InputMaybe<AssetKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAssetOneArgs = {
  object: AssetInsertInput;
  onConflict?: InputMaybe<AssetOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCacheArgs = {
  objects: Array<CacheInsertInput>;
  onConflict?: InputMaybe<CacheOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCacheOneArgs = {
  object: CacheInsertInput;
  onConflict?: InputMaybe<CacheOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCartArgs = {
  objects: Array<CartInsertInput>;
  onConflict?: InputMaybe<CartOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCartOneArgs = {
  object: CartInsertInput;
  onConflict?: InputMaybe<CartOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCertificationArgs = {
  objects: Array<CertificationInsertInput>;
  onConflict?: InputMaybe<CertificationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCertificationOneArgs = {
  object: CertificationInsertInput;
  onConflict?: InputMaybe<CertificationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClearinghouseArgs = {
  objects: Array<ClearinghouseInsertInput>;
  onConflict?: InputMaybe<ClearinghouseOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClearinghouseCertificationArgs = {
  objects: Array<ClearinghouseCertificationInsertInput>;
  onConflict?: InputMaybe<ClearinghouseCertificationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClearinghouseCertificationOneArgs = {
  object: ClearinghouseCertificationInsertInput;
  onConflict?: InputMaybe<ClearinghouseCertificationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClearinghouseKindArgs = {
  objects: Array<ClearinghouseKindInsertInput>;
  onConflict?: InputMaybe<ClearinghouseKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClearinghouseKindOneArgs = {
  object: ClearinghouseKindInsertInput;
  onConflict?: InputMaybe<ClearinghouseKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClearinghouseOneArgs = {
  object: ClearinghouseInsertInput;
  onConflict?: InputMaybe<ClearinghouseOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCountryArgs = {
  objects: Array<CountryInsertInput>;
  onConflict?: InputMaybe<CountryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCountryOneArgs = {
  object: CountryInsertInput;
  onConflict?: InputMaybe<CountryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCountryRegionArgs = {
  objects: Array<CountryRegionInsertInput>;
  onConflict?: InputMaybe<CountryRegionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCountryRegionOneArgs = {
  object: CountryRegionInsertInput;
  onConflict?: InputMaybe<CountryRegionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCurrencyArgs = {
  objects: Array<CurrencyInsertInput>;
  onConflict?: InputMaybe<CurrencyOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCurrencyKindArgs = {
  objects: Array<CurrencyKindInsertInput>;
  onConflict?: InputMaybe<CurrencyKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCurrencyKindOneArgs = {
  object: CurrencyKindInsertInput;
  onConflict?: InputMaybe<CurrencyKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCurrencyOneArgs = {
  object: CurrencyInsertInput;
  onConflict?: InputMaybe<CurrencyOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCurrencyOwnerArgs = {
  objects: Array<CurrencyOwnerInsertInput>;
  onConflict?: InputMaybe<CurrencyOwnerOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCurrencyOwnerOneArgs = {
  object: CurrencyOwnerInsertInput;
  onConflict?: InputMaybe<CurrencyOwnerOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDatasourceArgs = {
  objects: Array<DatasourceInsertInput>;
  onConflict?: InputMaybe<DatasourceOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDatasourceOneArgs = {
  object: DatasourceInsertInput;
  onConflict?: InputMaybe<DatasourceOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDocumentUserArgs = {
  objects: Array<DocumentUserInsertInput>;
  onConflict?: InputMaybe<DocumentUserOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDocumentUserOneArgs = {
  object: DocumentUserInsertInput;
  onConflict?: InputMaybe<DocumentUserOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertExchangeArgs = {
  objects: Array<ExchangeInsertInput>;
  onConflict?: InputMaybe<ExchangeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertExchangeKindArgs = {
  objects: Array<ExchangeKindInsertInput>;
  onConflict?: InputMaybe<ExchangeKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertExchangeKindOneArgs = {
  object: ExchangeKindInsertInput;
  onConflict?: InputMaybe<ExchangeKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertExchangeOneArgs = {
  object: ExchangeInsertInput;
  onConflict?: InputMaybe<ExchangeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFavoriteArgs = {
  objects: Array<FavoriteInsertInput>;
  onConflict?: InputMaybe<FavoriteOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFavoriteCommentArgs = {
  objects: Array<FavoriteCommentInsertInput>;
  onConflict?: InputMaybe<FavoriteCommentOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFavoriteCommentOneArgs = {
  object: FavoriteCommentInsertInput;
  onConflict?: InputMaybe<FavoriteCommentOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFavoriteOneArgs = {
  object: FavoriteInsertInput;
  onConflict?: InputMaybe<FavoriteOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGicsSectorArgs = {
  objects: Array<GicsSectorInsertInput>;
  onConflict?: InputMaybe<GicsSectorOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGicsSectorOneArgs = {
  object: GicsSectorInsertInput;
  onConflict?: InputMaybe<GicsSectorOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGroupArgs = {
  objects: Array<GroupInsertInput>;
  onConflict?: InputMaybe<GroupOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGroupOneArgs = {
  object: GroupInsertInput;
  onConflict?: InputMaybe<GroupOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertIndustryArgs = {
  objects: Array<IndustryInsertInput>;
  onConflict?: InputMaybe<IndustryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertIndustryOneArgs = {
  object: IndustryInsertInput;
  onConflict?: InputMaybe<IndustryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertInteractionArgs = {
  objects: Array<InteractionInsertInput>;
  onConflict?: InputMaybe<InteractionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertInteractionOneArgs = {
  object: InteractionInsertInput;
  onConflict?: InputMaybe<InteractionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertKycKindArgs = {
  objects: Array<KycKindInsertInput>;
  onConflict?: InputMaybe<KycKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertKycKindOneArgs = {
  object: KycKindInsertInput;
  onConflict?: InputMaybe<KycKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertKycStatusArgs = {
  objects: Array<KycStatusInsertInput>;
  onConflict?: InputMaybe<KycStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertKycStatusOneArgs = {
  object: KycStatusInsertInput;
  onConflict?: InputMaybe<KycStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMarketArgs = {
  objects: Array<MarketInsertInput>;
  onConflict?: InputMaybe<MarketOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMarketOneArgs = {
  object: MarketInsertInput;
  onConflict?: InputMaybe<MarketOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMaterialArgs = {
  objects: Array<MaterialInsertInput>;
  onConflict?: InputMaybe<MaterialOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMaterialOneArgs = {
  object: MaterialInsertInput;
  onConflict?: InputMaybe<MaterialOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMerchantArgs = {
  objects: Array<MerchantInsertInput>;
  onConflict?: InputMaybe<MerchantOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMerchantCurrencyArgs = {
  objects: Array<MerchantCurrencyInsertInput>;
  onConflict?: InputMaybe<MerchantCurrencyOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMerchantCurrencyOneArgs = {
  object: MerchantCurrencyInsertInput;
  onConflict?: InputMaybe<MerchantCurrencyOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMerchantOneArgs = {
  object: MerchantInsertInput;
  onConflict?: InputMaybe<MerchantOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMerchantOrganizationArgs = {
  objects: Array<MerchantOrganizationInsertInput>;
  onConflict?: InputMaybe<MerchantOrganizationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMerchantOrganizationOneArgs = {
  object: MerchantOrganizationInsertInput;
  onConflict?: InputMaybe<MerchantOrganizationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrderArgs = {
  objects: Array<OrderInsertInput>;
  onConflict?: InputMaybe<OrderOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrderOneArgs = {
  object: OrderInsertInput;
  onConflict?: InputMaybe<OrderOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganizationArgs = {
  objects: Array<OrganizationInsertInput>;
  onConflict?: InputMaybe<OrganizationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganizationKindArgs = {
  objects: Array<OrganizationKindInsertInput>;
  onConflict?: InputMaybe<OrganizationKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganizationKindOneArgs = {
  object: OrganizationKindInsertInput;
  onConflict?: InputMaybe<OrganizationKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganizationOneArgs = {
  object: OrganizationInsertInput;
  onConflict?: InputMaybe<OrganizationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganizationPersonArgs = {
  objects: Array<OrganizationPersonInsertInput>;
  onConflict?: InputMaybe<OrganizationPersonOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganizationPersonOneArgs = {
  object: OrganizationPersonInsertInput;
  onConflict?: InputMaybe<OrganizationPersonOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganizationStatusArgs = {
  objects: Array<OrganizationStatusInsertInput>;
  onConflict?: InputMaybe<OrganizationStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganizationStatusOneArgs = {
  object: OrganizationStatusInsertInput;
  onConflict?: InputMaybe<OrganizationStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPasswordResetArgs = {
  objects: Array<PasswordResetInsertInput>;
  onConflict?: InputMaybe<PasswordResetOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPasswordResetOneArgs = {
  object: PasswordResetInsertInput;
  onConflict?: InputMaybe<PasswordResetOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPaymentArgs = {
  objects: Array<PaymentInsertInput>;
  onConflict?: InputMaybe<PaymentOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPaymentOneArgs = {
  object: PaymentInsertInput;
  onConflict?: InputMaybe<PaymentOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProductArgs = {
  objects: Array<ProductInsertInput>;
  onConflict?: InputMaybe<ProductOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProductDistributionArgs = {
  objects: Array<ProductDistributionInsertInput>;
  onConflict?: InputMaybe<ProductDistributionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProductDistributionOneArgs = {
  object: ProductDistributionInsertInput;
  onConflict?: InputMaybe<ProductDistributionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProductKindArgs = {
  objects: Array<ProductKindInsertInput>;
  onConflict?: InputMaybe<ProductKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProductKindOneArgs = {
  object: ProductKindInsertInput;
  onConflict?: InputMaybe<ProductKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProductOneArgs = {
  object: ProductInsertInput;
  onConflict?: InputMaybe<ProductOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProductStatusArgs = {
  objects: Array<ProductStatusInsertInput>;
  onConflict?: InputMaybe<ProductStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProductStatusOneArgs = {
  object: ProductStatusInsertInput;
  onConflict?: InputMaybe<ProductStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPutArgs = {
  objects: Array<PutInsertInput>;
  onConflict?: InputMaybe<PutOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPutOneArgs = {
  object: PutInsertInput;
  onConflict?: InputMaybe<PutOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSettingArgs = {
  objects: Array<SettingInsertInput>;
  onConflict?: InputMaybe<SettingOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSettingOneArgs = {
  object: SettingInsertInput;
  onConflict?: InputMaybe<SettingOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertShippingArgs = {
  objects: Array<ShippingInsertInput>;
  onConflict?: InputMaybe<ShippingOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertShippingOneArgs = {
  object: ShippingInsertInput;
  onConflict?: InputMaybe<ShippingOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertShopArgs = {
  objects: Array<ShopInsertInput>;
  onConflict?: InputMaybe<ShopOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertShopOneArgs = {
  object: ShopInsertInput;
  onConflict?: InputMaybe<ShopOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSicCodeArgs = {
  objects: Array<SicCodeInsertInput>;
  onConflict?: InputMaybe<SicCodeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSicCodeOneArgs = {
  object: SicCodeInsertInput;
  onConflict?: InputMaybe<SicCodeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSigmaIdentityArgs = {
  objects: Array<SigmaIdentityInsertInput>;
  onConflict?: InputMaybe<SigmaIdentityOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSigmaIdentityOneArgs = {
  object: SigmaIdentityInsertInput;
  onConflict?: InputMaybe<SigmaIdentityOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSigmaPrimeArgs = {
  objects: Array<SigmaPrimeInsertInput>;
  onConflict?: InputMaybe<SigmaPrimeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSigmaPrimeAddressArgs = {
  objects: Array<SigmaPrimeAddressInsertInput>;
  onConflict?: InputMaybe<SigmaPrimeAddressOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSigmaPrimeAddressOneArgs = {
  object: SigmaPrimeAddressInsertInput;
  onConflict?: InputMaybe<SigmaPrimeAddressOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSigmaPrimeKindArgs = {
  objects: Array<SigmaPrimeKindInsertInput>;
  onConflict?: InputMaybe<SigmaPrimeKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSigmaPrimeKindOneArgs = {
  object: SigmaPrimeKindInsertInput;
  onConflict?: InputMaybe<SigmaPrimeKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSigmaPrimeOneArgs = {
  object: SigmaPrimeInsertInput;
  onConflict?: InputMaybe<SigmaPrimeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSigmaSocialArgs = {
  objects: Array<SigmaSocialInsertInput>;
  onConflict?: InputMaybe<SigmaSocialOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSigmaSocialOneArgs = {
  object: SigmaSocialInsertInput;
  onConflict?: InputMaybe<SigmaSocialOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSmartContractArgs = {
  objects: Array<SmartContractInsertInput>;
  onConflict?: InputMaybe<SmartContractOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSmartContractCommissionArgs = {
  objects: Array<SmartContractCommissionInsertInput>;
  onConflict?: InputMaybe<SmartContractCommissionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSmartContractCommissionOneArgs = {
  object: SmartContractCommissionInsertInput;
  onConflict?: InputMaybe<SmartContractCommissionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSmartContractDocumentArgs = {
  objects: Array<SmartContractDocumentInsertInput>;
  onConflict?: InputMaybe<SmartContractDocumentOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSmartContractDocumentOneArgs = {
  object: SmartContractDocumentInsertInput;
  onConflict?: InputMaybe<SmartContractDocumentOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSmartContractKindArgs = {
  objects: Array<SmartContractKindInsertInput>;
  onConflict?: InputMaybe<SmartContractKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSmartContractKindOneArgs = {
  object: SmartContractKindInsertInput;
  onConflict?: InputMaybe<SmartContractKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSmartContractLineageArgs = {
  objects: Array<SmartContractLineageInsertInput>;
  onConflict?: InputMaybe<SmartContractLineageOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSmartContractLineageOneArgs = {
  object: SmartContractLineageInsertInput;
  onConflict?: InputMaybe<SmartContractLineageOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSmartContractOneArgs = {
  object: SmartContractInsertInput;
  onConflict?: InputMaybe<SmartContractOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStateArgs = {
  objects: Array<StateInsertInput>;
};

/** mutation root */
export type Mutation_RootInsertStateOneArgs = {
  object: StateInsertInput;
};

/** mutation root */
export type Mutation_RootInsertStatusArgs = {
  objects: Array<StatusInsertInput>;
  onConflict?: InputMaybe<StatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatusKindArgs = {
  objects: Array<StatusKindInsertInput>;
  onConflict?: InputMaybe<StatusKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatusKindOneArgs = {
  object: StatusKindInsertInput;
  onConflict?: InputMaybe<StatusKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatusOneArgs = {
  object: StatusInsertInput;
  onConflict?: InputMaybe<StatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSubindustryArgs = {
  objects: Array<SubindustryInsertInput>;
  onConflict?: InputMaybe<SubindustryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSubindustryOneArgs = {
  object: SubindustryInsertInput;
  onConflict?: InputMaybe<SubindustryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSupplyChainWaypointArgs = {
  objects: Array<SupplyChainWaypointInsertInput>;
  onConflict?: InputMaybe<SupplyChainWaypointOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSupplyChainWaypointOneArgs = {
  object: SupplyChainWaypointInsertInput;
  onConflict?: InputMaybe<SupplyChainWaypointOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTradeArgs = {
  objects: Array<TradeInsertInput>;
  onConflict?: InputMaybe<TradeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTradeKindArgs = {
  objects: Array<TradeKindInsertInput>;
  onConflict?: InputMaybe<TradeKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTradeKindOneArgs = {
  object: TradeKindInsertInput;
  onConflict?: InputMaybe<TradeKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTradeOneArgs = {
  object: TradeInsertInput;
  onConflict?: InputMaybe<TradeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTransactionArgs = {
  objects: Array<TransactionInsertInput>;
  onConflict?: InputMaybe<TransactionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTransactionKindArgs = {
  objects: Array<TransactionKindInsertInput>;
  onConflict?: InputMaybe<TransactionKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTransactionKindOneArgs = {
  object: TransactionKindInsertInput;
  onConflict?: InputMaybe<TransactionKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTransactionOneArgs = {
  object: TransactionInsertInput;
  onConflict?: InputMaybe<TransactionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUserArgs = {
  objects: Array<UserInsertInput>;
  onConflict?: InputMaybe<UserOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUserAuthArgs = {
  objects: Array<UserAuthInsertInput>;
  onConflict?: InputMaybe<UserAuthOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUserAuthOneArgs = {
  object: UserAuthInsertInput;
  onConflict?: InputMaybe<UserAuthOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUserOneArgs = {
  object: UserInsertInput;
  onConflict?: InputMaybe<UserOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletArgs = {
  objects: Array<WalletInsertInput>;
  onConflict?: InputMaybe<WalletOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletInventoryArgs = {
  objects: Array<WalletInventoryInsertInput>;
  onConflict?: InputMaybe<WalletInventoryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletInventoryOneArgs = {
  object: WalletInventoryInsertInput;
  onConflict?: InputMaybe<WalletInventoryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletKindArgs = {
  objects: Array<WalletKindInsertInput>;
  onConflict?: InputMaybe<WalletKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletKindOneArgs = {
  object: WalletKindInsertInput;
  onConflict?: InputMaybe<WalletKindOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletOneArgs = {
  object: WalletInsertInput;
  onConflict?: InputMaybe<WalletOnConflict>;
};

/** mutation root */
export type Mutation_RootUpdateAddressAttributesArgs = {
  _set?: InputMaybe<AddressAttributesSetInput>;
  where: AddressAttributesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAddressAttributesByPkArgs = {
  _set?: InputMaybe<AddressAttributesSetInput>;
  pkColumns: AddressAttributesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAddressAttributesManyArgs = {
  updates: Array<AddressAttributesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateAppraisalArgs = {
  _set?: InputMaybe<AppraisalSetInput>;
  where: AppraisalBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAppraisalByPkArgs = {
  _set?: InputMaybe<AppraisalSetInput>;
  pkColumns: AppraisalPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAppraisalManyArgs = {
  updates: Array<AppraisalUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateAskArgs = {
  _inc?: InputMaybe<AskIncInput>;
  _set?: InputMaybe<AskSetInput>;
  where: AskBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAskByPkArgs = {
  _inc?: InputMaybe<AskIncInput>;
  _set?: InputMaybe<AskSetInput>;
  pkColumns: AskPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAskManyArgs = {
  updates: Array<AskUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateAskPutTransactionArgs = {
  _set?: InputMaybe<AskPutTransactionSetInput>;
  where: AskPutTransactionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAskPutTransactionByPkArgs = {
  _set?: InputMaybe<AskPutTransactionSetInput>;
  pkColumns: AskPutTransactionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAskPutTransactionManyArgs = {
  updates: Array<AskPutTransactionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateAssetArgs = {
  _inc?: InputMaybe<AssetIncInput>;
  _set?: InputMaybe<AssetSetInput>;
  where: AssetBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAssetAccountArgs = {
  _inc?: InputMaybe<AssetAccountIncInput>;
  _set?: InputMaybe<AssetAccountSetInput>;
  where: AssetAccountBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAssetAccountByPkArgs = {
  _inc?: InputMaybe<AssetAccountIncInput>;
  _set?: InputMaybe<AssetAccountSetInput>;
  pkColumns: AssetAccountPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAssetAccountManyArgs = {
  updates: Array<AssetAccountUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateAssetByPkArgs = {
  _inc?: InputMaybe<AssetIncInput>;
  _set?: InputMaybe<AssetSetInput>;
  pkColumns: AssetPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAssetKindArgs = {
  _set?: InputMaybe<AssetKindSetInput>;
  where: AssetKindBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAssetKindByPkArgs = {
  _set?: InputMaybe<AssetKindSetInput>;
  pkColumns: AssetKindPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAssetKindManyArgs = {
  updates: Array<AssetKindUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateAssetManyArgs = {
  updates: Array<AssetUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCacheArgs = {
  _set?: InputMaybe<CacheSetInput>;
  where: CacheBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCacheByPkArgs = {
  _set?: InputMaybe<CacheSetInput>;
  pkColumns: CachePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCacheManyArgs = {
  updates: Array<CacheUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCartArgs = {
  _set?: InputMaybe<CartSetInput>;
  where: CartBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCartByPkArgs = {
  _set?: InputMaybe<CartSetInput>;
  pkColumns: CartPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCartManyArgs = {
  updates: Array<CartUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCertificationArgs = {
  _set?: InputMaybe<CertificationSetInput>;
  where: CertificationBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCertificationByPkArgs = {
  _set?: InputMaybe<CertificationSetInput>;
  pkColumns: CertificationPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCertificationManyArgs = {
  updates: Array<CertificationUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClearinghouseArgs = {
  _set?: InputMaybe<ClearinghouseSetInput>;
  where: ClearinghouseBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClearinghouseByPkArgs = {
  _set?: InputMaybe<ClearinghouseSetInput>;
  pkColumns: ClearinghousePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClearinghouseCertificationArgs = {
  _set?: InputMaybe<ClearinghouseCertificationSetInput>;
  where: ClearinghouseCertificationBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClearinghouseCertificationByPkArgs = {
  _set?: InputMaybe<ClearinghouseCertificationSetInput>;
  pkColumns: ClearinghouseCertificationPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClearinghouseCertificationManyArgs = {
  updates: Array<ClearinghouseCertificationUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClearinghouseKindArgs = {
  _set?: InputMaybe<ClearinghouseKindSetInput>;
  where: ClearinghouseKindBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClearinghouseKindByPkArgs = {
  _set?: InputMaybe<ClearinghouseKindSetInput>;
  pkColumns: ClearinghouseKindPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClearinghouseKindManyArgs = {
  updates: Array<ClearinghouseKindUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClearinghouseManyArgs = {
  updates: Array<ClearinghouseUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCountryArgs = {
  _append?: InputMaybe<CountryAppendInput>;
  _deleteAtPath?: InputMaybe<CountryDeleteAtPathInput>;
  _deleteElem?: InputMaybe<CountryDeleteElemInput>;
  _deleteKey?: InputMaybe<CountryDeleteKeyInput>;
  _inc?: InputMaybe<CountryIncInput>;
  _prepend?: InputMaybe<CountryPrependInput>;
  _set?: InputMaybe<CountrySetInput>;
  where: CountryBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCountryByPkArgs = {
  _append?: InputMaybe<CountryAppendInput>;
  _deleteAtPath?: InputMaybe<CountryDeleteAtPathInput>;
  _deleteElem?: InputMaybe<CountryDeleteElemInput>;
  _deleteKey?: InputMaybe<CountryDeleteKeyInput>;
  _inc?: InputMaybe<CountryIncInput>;
  _prepend?: InputMaybe<CountryPrependInput>;
  _set?: InputMaybe<CountrySetInput>;
  pkColumns: CountryPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCountryManyArgs = {
  updates: Array<CountryUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCountryRegionArgs = {
  _set?: InputMaybe<CountryRegionSetInput>;
  where: CountryRegionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCountryRegionByPkArgs = {
  _set?: InputMaybe<CountryRegionSetInput>;
  pkColumns: CountryRegionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCountryRegionManyArgs = {
  updates: Array<CountryRegionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCurrencyArgs = {
  _inc?: InputMaybe<CurrencyIncInput>;
  _set?: InputMaybe<CurrencySetInput>;
  where: CurrencyBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCurrencyByPkArgs = {
  _inc?: InputMaybe<CurrencyIncInput>;
  _set?: InputMaybe<CurrencySetInput>;
  pkColumns: CurrencyPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCurrencyKindArgs = {
  _set?: InputMaybe<CurrencyKindSetInput>;
  where: CurrencyKindBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCurrencyKindByPkArgs = {
  _set?: InputMaybe<CurrencyKindSetInput>;
  pkColumns: CurrencyKindPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCurrencyKindManyArgs = {
  updates: Array<CurrencyKindUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCurrencyManyArgs = {
  updates: Array<CurrencyUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCurrencyOwnerArgs = {
  _set?: InputMaybe<CurrencyOwnerSetInput>;
  where: CurrencyOwnerBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCurrencyOwnerByPkArgs = {
  _set?: InputMaybe<CurrencyOwnerSetInput>;
  pkColumns: CurrencyOwnerPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCurrencyOwnerManyArgs = {
  updates: Array<CurrencyOwnerUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateDatasourceArgs = {
  _set?: InputMaybe<DatasourceSetInput>;
  where: DatasourceBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateDatasourceByPkArgs = {
  _set?: InputMaybe<DatasourceSetInput>;
  pkColumns: DatasourcePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateDatasourceManyArgs = {
  updates: Array<DatasourceUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateDocumentUserArgs = {
  _set?: InputMaybe<DocumentUserSetInput>;
  where: DocumentUserBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateDocumentUserByPkArgs = {
  _set?: InputMaybe<DocumentUserSetInput>;
  pkColumns: DocumentUserPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateDocumentUserManyArgs = {
  updates: Array<DocumentUserUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateExchangeArgs = {
  _inc?: InputMaybe<ExchangeIncInput>;
  _set?: InputMaybe<ExchangeSetInput>;
  where: ExchangeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateExchangeByPkArgs = {
  _inc?: InputMaybe<ExchangeIncInput>;
  _set?: InputMaybe<ExchangeSetInput>;
  pkColumns: ExchangePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateExchangeKindArgs = {
  _set?: InputMaybe<ExchangeKindSetInput>;
  where: ExchangeKindBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateExchangeKindByPkArgs = {
  _set?: InputMaybe<ExchangeKindSetInput>;
  pkColumns: ExchangeKindPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateExchangeKindManyArgs = {
  updates: Array<ExchangeKindUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateExchangeManyArgs = {
  updates: Array<ExchangeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateFavoriteArgs = {
  _set?: InputMaybe<FavoriteSetInput>;
  where: FavoriteBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateFavoriteByPkArgs = {
  _set?: InputMaybe<FavoriteSetInput>;
  pkColumns: FavoritePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateFavoriteCommentArgs = {
  _set?: InputMaybe<FavoriteCommentSetInput>;
  where: FavoriteCommentBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateFavoriteCommentByPkArgs = {
  _set?: InputMaybe<FavoriteCommentSetInput>;
  pkColumns: FavoriteCommentPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateFavoriteCommentManyArgs = {
  updates: Array<FavoriteCommentUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateFavoriteManyArgs = {
  updates: Array<FavoriteUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateGicsSectorArgs = {
  _set?: InputMaybe<GicsSectorSetInput>;
  where: GicsSectorBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateGicsSectorByPkArgs = {
  _set?: InputMaybe<GicsSectorSetInput>;
  pkColumns: GicsSectorPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateGicsSectorManyArgs = {
  updates: Array<GicsSectorUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateGroupArgs = {
  _set?: InputMaybe<GroupSetInput>;
  where: GroupBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateGroupByPkArgs = {
  _set?: InputMaybe<GroupSetInput>;
  pkColumns: GroupPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateGroupManyArgs = {
  updates: Array<GroupUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateIndustryArgs = {
  _set?: InputMaybe<IndustrySetInput>;
  where: IndustryBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateIndustryByPkArgs = {
  _set?: InputMaybe<IndustrySetInput>;
  pkColumns: IndustryPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateIndustryManyArgs = {
  updates: Array<IndustryUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateInteractionArgs = {
  _inc?: InputMaybe<InteractionIncInput>;
  _set?: InputMaybe<InteractionSetInput>;
  where: InteractionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateInteractionByPkArgs = {
  _inc?: InputMaybe<InteractionIncInput>;
  _set?: InputMaybe<InteractionSetInput>;
  pkColumns: InteractionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateInteractionManyArgs = {
  updates: Array<InteractionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateKycKindArgs = {
  _set?: InputMaybe<KycKindSetInput>;
  where: KycKindBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateKycKindByPkArgs = {
  _set?: InputMaybe<KycKindSetInput>;
  pkColumns: KycKindPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateKycKindManyArgs = {
  updates: Array<KycKindUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateKycStatusArgs = {
  _set?: InputMaybe<KycStatusSetInput>;
  where: KycStatusBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateKycStatusByPkArgs = {
  _set?: InputMaybe<KycStatusSetInput>;
  pkColumns: KycStatusPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateKycStatusManyArgs = {
  updates: Array<KycStatusUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMarketArgs = {
  _set?: InputMaybe<MarketSetInput>;
  where: MarketBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMarketByPkArgs = {
  _set?: InputMaybe<MarketSetInput>;
  pkColumns: MarketPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMarketManyArgs = {
  updates: Array<MarketUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMaterialArgs = {
  _set?: InputMaybe<MaterialSetInput>;
  where: MaterialBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMaterialByPkArgs = {
  _set?: InputMaybe<MaterialSetInput>;
  pkColumns: MaterialPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMaterialManyArgs = {
  updates: Array<MaterialUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMerchantArgs = {
  _set?: InputMaybe<MerchantSetInput>;
  where: MerchantBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMerchantByPkArgs = {
  _set?: InputMaybe<MerchantSetInput>;
  pkColumns: MerchantPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMerchantCurrencyArgs = {
  _set?: InputMaybe<MerchantCurrencySetInput>;
  where: MerchantCurrencyBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMerchantCurrencyByPkArgs = {
  _set?: InputMaybe<MerchantCurrencySetInput>;
  pkColumns: MerchantCurrencyPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMerchantCurrencyManyArgs = {
  updates: Array<MerchantCurrencyUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMerchantManyArgs = {
  updates: Array<MerchantUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMerchantOrganizationArgs = {
  _set?: InputMaybe<MerchantOrganizationSetInput>;
  where: MerchantOrganizationBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMerchantOrganizationByPkArgs = {
  _set?: InputMaybe<MerchantOrganizationSetInput>;
  pkColumns: MerchantOrganizationPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMerchantOrganizationManyArgs = {
  updates: Array<MerchantOrganizationUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateOrderArgs = {
  _inc?: InputMaybe<OrderIncInput>;
  _set?: InputMaybe<OrderSetInput>;
  where: OrderBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateOrderByPkArgs = {
  _inc?: InputMaybe<OrderIncInput>;
  _set?: InputMaybe<OrderSetInput>;
  pkColumns: OrderPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateOrderManyArgs = {
  updates: Array<OrderUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationArgs = {
  _set?: InputMaybe<OrganizationSetInput>;
  where: OrganizationBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationByPkArgs = {
  _set?: InputMaybe<OrganizationSetInput>;
  pkColumns: OrganizationPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationKindArgs = {
  _set?: InputMaybe<OrganizationKindSetInput>;
  where: OrganizationKindBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationKindByPkArgs = {
  _set?: InputMaybe<OrganizationKindSetInput>;
  pkColumns: OrganizationKindPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationKindManyArgs = {
  updates: Array<OrganizationKindUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationManyArgs = {
  updates: Array<OrganizationUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationPersonArgs = {
  _set?: InputMaybe<OrganizationPersonSetInput>;
  where: OrganizationPersonBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationPersonByPkArgs = {
  _set?: InputMaybe<OrganizationPersonSetInput>;
  pkColumns: OrganizationPersonPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationPersonManyArgs = {
  updates: Array<OrganizationPersonUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationStatusArgs = {
  _set?: InputMaybe<OrganizationStatusSetInput>;
  where: OrganizationStatusBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationStatusByPkArgs = {
  _set?: InputMaybe<OrganizationStatusSetInput>;
  pkColumns: OrganizationStatusPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationStatusManyArgs = {
  updates: Array<OrganizationStatusUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePasswordResetArgs = {
  _set?: InputMaybe<PasswordResetSetInput>;
  where: PasswordResetBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePasswordResetByPkArgs = {
  _set?: InputMaybe<PasswordResetSetInput>;
  pkColumns: PasswordResetPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePasswordResetManyArgs = {
  updates: Array<PasswordResetUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePaymentArgs = {
  _inc?: InputMaybe<PaymentIncInput>;
  _set?: InputMaybe<PaymentSetInput>;
  where: PaymentBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePaymentByPkArgs = {
  _inc?: InputMaybe<PaymentIncInput>;
  _set?: InputMaybe<PaymentSetInput>;
  pkColumns: PaymentPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePaymentManyArgs = {
  updates: Array<PaymentUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProductArgs = {
  _inc?: InputMaybe<ProductIncInput>;
  _set?: InputMaybe<ProductSetInput>;
  where: ProductBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProductByPkArgs = {
  _inc?: InputMaybe<ProductIncInput>;
  _set?: InputMaybe<ProductSetInput>;
  pkColumns: ProductPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProductDistributionArgs = {
  _set?: InputMaybe<ProductDistributionSetInput>;
  where: ProductDistributionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProductDistributionByPkArgs = {
  _set?: InputMaybe<ProductDistributionSetInput>;
  pkColumns: ProductDistributionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProductDistributionManyArgs = {
  updates: Array<ProductDistributionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProductKindArgs = {
  _set?: InputMaybe<ProductKindSetInput>;
  where: ProductKindBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProductKindByPkArgs = {
  _set?: InputMaybe<ProductKindSetInput>;
  pkColumns: ProductKindPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProductKindManyArgs = {
  updates: Array<ProductKindUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProductManyArgs = {
  updates: Array<ProductUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProductStatusArgs = {
  _set?: InputMaybe<ProductStatusSetInput>;
  where: ProductStatusBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProductStatusByPkArgs = {
  _set?: InputMaybe<ProductStatusSetInput>;
  pkColumns: ProductStatusPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProductStatusManyArgs = {
  updates: Array<ProductStatusUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePutArgs = {
  _inc?: InputMaybe<PutIncInput>;
  _set?: InputMaybe<PutSetInput>;
  where: PutBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePutByPkArgs = {
  _inc?: InputMaybe<PutIncInput>;
  _set?: InputMaybe<PutSetInput>;
  pkColumns: PutPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePutManyArgs = {
  updates: Array<PutUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSettingArgs = {
  _set?: InputMaybe<SettingSetInput>;
  where: SettingBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSettingByPkArgs = {
  _set?: InputMaybe<SettingSetInput>;
  pkColumns: SettingPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSettingManyArgs = {
  updates: Array<SettingUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateShippingArgs = {
  _set?: InputMaybe<ShippingSetInput>;
  where: ShippingBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateShippingByPkArgs = {
  _set?: InputMaybe<ShippingSetInput>;
  pkColumns: ShippingPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateShippingManyArgs = {
  updates: Array<ShippingUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateShopArgs = {
  _set?: InputMaybe<ShopSetInput>;
  where: ShopBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateShopByPkArgs = {
  _set?: InputMaybe<ShopSetInput>;
  pkColumns: ShopPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateShopManyArgs = {
  updates: Array<ShopUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSicCodeArgs = {
  _inc?: InputMaybe<SicCodeIncInput>;
  _set?: InputMaybe<SicCodeSetInput>;
  where: SicCodeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSicCodeByPkArgs = {
  _inc?: InputMaybe<SicCodeIncInput>;
  _set?: InputMaybe<SicCodeSetInput>;
  pkColumns: SicCodePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSicCodeManyArgs = {
  updates: Array<SicCodeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSigmaIdentityArgs = {
  _inc?: InputMaybe<SigmaIdentityIncInput>;
  _set?: InputMaybe<SigmaIdentitySetInput>;
  where: SigmaIdentityBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSigmaIdentityByPkArgs = {
  _inc?: InputMaybe<SigmaIdentityIncInput>;
  _set?: InputMaybe<SigmaIdentitySetInput>;
  pkColumns: SigmaIdentityPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSigmaIdentityManyArgs = {
  updates: Array<SigmaIdentityUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSigmaPrimeArgs = {
  _set?: InputMaybe<SigmaPrimeSetInput>;
  where: SigmaPrimeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSigmaPrimeAddressArgs = {
  _inc?: InputMaybe<SigmaPrimeAddressIncInput>;
  _set?: InputMaybe<SigmaPrimeAddressSetInput>;
  where: SigmaPrimeAddressBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSigmaPrimeAddressByPkArgs = {
  _inc?: InputMaybe<SigmaPrimeAddressIncInput>;
  _set?: InputMaybe<SigmaPrimeAddressSetInput>;
  pkColumns: SigmaPrimeAddressPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSigmaPrimeAddressManyArgs = {
  updates: Array<SigmaPrimeAddressUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSigmaPrimeByPkArgs = {
  _set?: InputMaybe<SigmaPrimeSetInput>;
  pkColumns: SigmaPrimePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSigmaPrimeKindArgs = {
  _set?: InputMaybe<SigmaPrimeKindSetInput>;
  where: SigmaPrimeKindBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSigmaPrimeKindByPkArgs = {
  _set?: InputMaybe<SigmaPrimeKindSetInput>;
  pkColumns: SigmaPrimeKindPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSigmaPrimeKindManyArgs = {
  updates: Array<SigmaPrimeKindUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSigmaPrimeManyArgs = {
  updates: Array<SigmaPrimeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSigmaSocialArgs = {
  _set?: InputMaybe<SigmaSocialSetInput>;
  where: SigmaSocialBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSigmaSocialByPkArgs = {
  _set?: InputMaybe<SigmaSocialSetInput>;
  pkColumns: SigmaSocialPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSigmaSocialManyArgs = {
  updates: Array<SigmaSocialUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractArgs = {
  _inc?: InputMaybe<SmartContractIncInput>;
  _set?: InputMaybe<SmartContractSetInput>;
  where: SmartContractBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractByPkArgs = {
  _inc?: InputMaybe<SmartContractIncInput>;
  _set?: InputMaybe<SmartContractSetInput>;
  pkColumns: SmartContractPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractCommissionArgs = {
  _inc?: InputMaybe<SmartContractCommissionIncInput>;
  _set?: InputMaybe<SmartContractCommissionSetInput>;
  where: SmartContractCommissionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractCommissionByPkArgs = {
  _inc?: InputMaybe<SmartContractCommissionIncInput>;
  _set?: InputMaybe<SmartContractCommissionSetInput>;
  pkColumns: SmartContractCommissionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractCommissionManyArgs = {
  updates: Array<SmartContractCommissionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractDocumentArgs = {
  _set?: InputMaybe<SmartContractDocumentSetInput>;
  where: SmartContractDocumentBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractDocumentByPkArgs = {
  _set?: InputMaybe<SmartContractDocumentSetInput>;
  pkColumns: SmartContractDocumentPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractDocumentManyArgs = {
  updates: Array<SmartContractDocumentUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractKindArgs = {
  _set?: InputMaybe<SmartContractKindSetInput>;
  where: SmartContractKindBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractKindByPkArgs = {
  _set?: InputMaybe<SmartContractKindSetInput>;
  pkColumns: SmartContractKindPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractKindManyArgs = {
  updates: Array<SmartContractKindUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractLineageArgs = {
  _append?: InputMaybe<SmartContractLineageAppendInput>;
  _deleteAtPath?: InputMaybe<SmartContractLineageDeleteAtPathInput>;
  _deleteElem?: InputMaybe<SmartContractLineageDeleteElemInput>;
  _deleteKey?: InputMaybe<SmartContractLineageDeleteKeyInput>;
  _prepend?: InputMaybe<SmartContractLineagePrependInput>;
  _set?: InputMaybe<SmartContractLineageSetInput>;
  where: SmartContractLineageBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractLineageByPkArgs = {
  _append?: InputMaybe<SmartContractLineageAppendInput>;
  _deleteAtPath?: InputMaybe<SmartContractLineageDeleteAtPathInput>;
  _deleteElem?: InputMaybe<SmartContractLineageDeleteElemInput>;
  _deleteKey?: InputMaybe<SmartContractLineageDeleteKeyInput>;
  _prepend?: InputMaybe<SmartContractLineagePrependInput>;
  _set?: InputMaybe<SmartContractLineageSetInput>;
  pkColumns: SmartContractLineagePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractLineageManyArgs = {
  updates: Array<SmartContractLineageUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSmartContractManyArgs = {
  updates: Array<SmartContractUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStateArgs = {
  _inc?: InputMaybe<StateIncInput>;
  _set?: InputMaybe<StateSetInput>;
  where: StateBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateStateManyArgs = {
  updates: Array<StateUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStatusArgs = {
  _set?: InputMaybe<StatusSetInput>;
  where: StatusBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateStatusByPkArgs = {
  _set?: InputMaybe<StatusSetInput>;
  pkColumns: StatusPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateStatusKindArgs = {
  _set?: InputMaybe<StatusKindSetInput>;
  where: StatusKindBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateStatusKindByPkArgs = {
  _set?: InputMaybe<StatusKindSetInput>;
  pkColumns: StatusKindPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateStatusKindManyArgs = {
  updates: Array<StatusKindUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStatusManyArgs = {
  updates: Array<StatusUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSubindustryArgs = {
  _set?: InputMaybe<SubindustrySetInput>;
  where: SubindustryBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSubindustryByPkArgs = {
  _set?: InputMaybe<SubindustrySetInput>;
  pkColumns: SubindustryPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSubindustryManyArgs = {
  updates: Array<SubindustryUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSupplyChainWaypointArgs = {
  _inc?: InputMaybe<SupplyChainWaypointIncInput>;
  _set?: InputMaybe<SupplyChainWaypointSetInput>;
  where: SupplyChainWaypointBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSupplyChainWaypointByPkArgs = {
  _inc?: InputMaybe<SupplyChainWaypointIncInput>;
  _set?: InputMaybe<SupplyChainWaypointSetInput>;
  pkColumns: SupplyChainWaypointPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSupplyChainWaypointManyArgs = {
  updates: Array<SupplyChainWaypointUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTradeArgs = {
  _set?: InputMaybe<TradeSetInput>;
  where: TradeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTradeByPkArgs = {
  _set?: InputMaybe<TradeSetInput>;
  pkColumns: TradePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTradeKindArgs = {
  _set?: InputMaybe<TradeKindSetInput>;
  where: TradeKindBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTradeKindByPkArgs = {
  _set?: InputMaybe<TradeKindSetInput>;
  pkColumns: TradeKindPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTradeKindManyArgs = {
  updates: Array<TradeKindUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTradeManyArgs = {
  updates: Array<TradeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTransactionArgs = {
  _inc?: InputMaybe<TransactionIncInput>;
  _set?: InputMaybe<TransactionSetInput>;
  where: TransactionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTransactionByPkArgs = {
  _inc?: InputMaybe<TransactionIncInput>;
  _set?: InputMaybe<TransactionSetInput>;
  pkColumns: TransactionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTransactionKindArgs = {
  _set?: InputMaybe<TransactionKindSetInput>;
  where: TransactionKindBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTransactionKindByPkArgs = {
  _set?: InputMaybe<TransactionKindSetInput>;
  pkColumns: TransactionKindPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTransactionKindManyArgs = {
  updates: Array<TransactionKindUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTransactionManyArgs = {
  updates: Array<TransactionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  _set?: InputMaybe<UserSetInput>;
  where: UserBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateUserAuthArgs = {
  _set?: InputMaybe<UserAuthSetInput>;
  where: UserAuthBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateUserAuthByPkArgs = {
  _set?: InputMaybe<UserAuthSetInput>;
  pkColumns: UserAuthPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateUserAuthManyArgs = {
  updates: Array<UserAuthUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateUserByPkArgs = {
  _set?: InputMaybe<UserSetInput>;
  pkColumns: UserPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateUserManyArgs = {
  updates: Array<UserUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateWalletArgs = {
  _set?: InputMaybe<WalletSetInput>;
  where: WalletBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateWalletByPkArgs = {
  _set?: InputMaybe<WalletSetInput>;
  pkColumns: WalletPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateWalletInventoryArgs = {
  _inc?: InputMaybe<WalletInventoryIncInput>;
  _set?: InputMaybe<WalletInventorySetInput>;
  where: WalletInventoryBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateWalletInventoryByPkArgs = {
  _inc?: InputMaybe<WalletInventoryIncInput>;
  _set?: InputMaybe<WalletInventorySetInput>;
  pkColumns: WalletInventoryPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateWalletInventoryManyArgs = {
  updates: Array<WalletInventoryUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateWalletKindArgs = {
  _set?: InputMaybe<WalletKindSetInput>;
  where: WalletKindBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateWalletKindByPkArgs = {
  _set?: InputMaybe<WalletKindSetInput>;
  pkColumns: WalletKindPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateWalletKindManyArgs = {
  updates: Array<WalletKindUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateWalletManyArgs = {
  updates: Array<WalletUpdates>;
};

/** mutation root */
export type Mutation_RootUserSignupArgs = {
  userInput: UserSignupInput;
};

export type OrganizationAggregateBoolExpBool_And = {
  arguments: OrganizationSelectColumnOrganizationAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OrganizationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type OrganizationAggregateBoolExpBool_Or = {
  arguments: OrganizationSelectColumnOrganizationAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OrganizationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type OrganizationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OrganizationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OrganizationBoolExp>;
  predicate: IntComparisonExp;
};

export type PaymentAggregateBoolExpBool_And = {
  arguments: PaymentSelectColumnPaymentAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PaymentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PaymentAggregateBoolExpBool_Or = {
  arguments: PaymentSelectColumnPaymentAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PaymentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PaymentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PaymentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PaymentBoolExp>;
  predicate: IntComparisonExp;
};

export type ProductAggregateBoolExpAvg = {
  arguments: ProductSelectColumnProductAggregateBoolExpAvgArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ProductAggregateBoolExpBool_And = {
  arguments: ProductSelectColumnProductAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProductAggregateBoolExpBool_Or = {
  arguments: ProductSelectColumnProductAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProductAggregateBoolExpCorr = {
  arguments: ProductAggregateBoolExpCorrArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ProductAggregateBoolExpCorrArguments = {
  X: ProductSelectColumnProductAggregateBoolExpCorrArgumentsColumns;
  Y: ProductSelectColumnProductAggregateBoolExpCorrArgumentsColumns;
};

export type ProductAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ProductSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductBoolExp>;
  predicate: IntComparisonExp;
};

export type ProductAggregateBoolExpCovar_Samp = {
  arguments: ProductAggregateBoolExpCovar_SampArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ProductAggregateBoolExpCovar_SampArguments = {
  X: ProductSelectColumnProductAggregateBoolExpCovar_SampArgumentsColumns;
  Y: ProductSelectColumnProductAggregateBoolExpCovar_SampArgumentsColumns;
};

export type ProductAggregateBoolExpMax = {
  arguments: ProductSelectColumnProductAggregateBoolExpMaxArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ProductAggregateBoolExpMin = {
  arguments: ProductSelectColumnProductAggregateBoolExpMinArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ProductAggregateBoolExpStddev_Samp = {
  arguments: ProductSelectColumnProductAggregateBoolExpStddev_SampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ProductAggregateBoolExpSum = {
  arguments: ProductSelectColumnProductAggregateBoolExpSumArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ProductAggregateBoolExpVar_Samp = {
  arguments: ProductSelectColumnProductAggregateBoolExpVar_SampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductBoolExp>;
  predicate: Float8ComparisonExp;
};

export type PutAggregateBoolExpAvg = {
  arguments: PutSelectColumnPutAggregateBoolExpAvgArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PutBoolExp>;
  predicate: Float8ComparisonExp;
};

export type PutAggregateBoolExpBool_And = {
  arguments: PutSelectColumnPutAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PutBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PutAggregateBoolExpBool_Or = {
  arguments: PutSelectColumnPutAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PutBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PutAggregateBoolExpCorr = {
  arguments: PutAggregateBoolExpCorrArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PutBoolExp>;
  predicate: Float8ComparisonExp;
};

export type PutAggregateBoolExpCorrArguments = {
  X: PutSelectColumnPutAggregateBoolExpCorrArgumentsColumns;
  Y: PutSelectColumnPutAggregateBoolExpCorrArgumentsColumns;
};

export type PutAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PutSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PutBoolExp>;
  predicate: IntComparisonExp;
};

export type PutAggregateBoolExpCovar_Samp = {
  arguments: PutAggregateBoolExpCovar_SampArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PutBoolExp>;
  predicate: Float8ComparisonExp;
};

export type PutAggregateBoolExpCovar_SampArguments = {
  X: PutSelectColumnPutAggregateBoolExpCovar_SampArgumentsColumns;
  Y: PutSelectColumnPutAggregateBoolExpCovar_SampArgumentsColumns;
};

export type PutAggregateBoolExpMax = {
  arguments: PutSelectColumnPutAggregateBoolExpMaxArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PutBoolExp>;
  predicate: Float8ComparisonExp;
};

export type PutAggregateBoolExpMin = {
  arguments: PutSelectColumnPutAggregateBoolExpMinArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PutBoolExp>;
  predicate: Float8ComparisonExp;
};

export type PutAggregateBoolExpStddev_Samp = {
  arguments: PutSelectColumnPutAggregateBoolExpStddev_SampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PutBoolExp>;
  predicate: Float8ComparisonExp;
};

export type PutAggregateBoolExpSum = {
  arguments: PutSelectColumnPutAggregateBoolExpSumArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PutBoolExp>;
  predicate: Float8ComparisonExp;
};

export type PutAggregateBoolExpVar_Samp = {
  arguments: PutSelectColumnPutAggregateBoolExpVar_SampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PutBoolExp>;
  predicate: Float8ComparisonExp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "address_attributes" */
  addressAttributes: Array<AddressAttributes>;
  /** fetch aggregated fields from the table: "address_attributes" */
  addressAttributesAggregate: AddressAttributesAggregate;
  /** fetch data from the table: "address_attributes" using primary key columns */
  addressAttributesByPk?: Maybe<AddressAttributes>;
  /** fetch data from the table: "appraisal" */
  appraisal: Array<Appraisal>;
  /** fetch aggregated fields from the table: "appraisal" */
  appraisalAggregate: AppraisalAggregate;
  /** fetch data from the table: "appraisal" using primary key columns */
  appraisalByPk?: Maybe<Appraisal>;
  /** fetch data from the table: "ask" */
  ask: Array<Ask>;
  /** fetch aggregated fields from the table: "ask" */
  askAggregate: AskAggregate;
  /** fetch data from the table: "ask" using primary key columns */
  askByPk?: Maybe<Ask>;
  /** fetch data from the table: "ask_put_transaction" */
  askPutTransaction: Array<AskPutTransaction>;
  /** fetch aggregated fields from the table: "ask_put_transaction" */
  askPutTransactionAggregate: AskPutTransactionAggregate;
  /** fetch data from the table: "ask_put_transaction" using primary key columns */
  askPutTransactionByPk?: Maybe<AskPutTransaction>;
  /** fetch data from the table: "asset" */
  asset: Array<Asset>;
  /** fetch data from the table: "asset_account" */
  assetAccount: Array<AssetAccount>;
  /** fetch aggregated fields from the table: "asset_account" */
  assetAccountAggregate: AssetAccountAggregate;
  /** fetch data from the table: "asset_account" using primary key columns */
  assetAccountByPk?: Maybe<AssetAccount>;
  /** fetch aggregated fields from the table: "asset" */
  assetAggregate: AssetAggregate;
  /** fetch data from the table: "asset" using primary key columns */
  assetByPk?: Maybe<Asset>;
  /** An array relationship */
  assetKind: Array<AssetKind>;
  /** An aggregate relationship */
  assetKindAggregate: AssetKindAggregate;
  /** fetch data from the table: "asset_kind" using primary key columns */
  assetKindByPk?: Maybe<AssetKind>;
  /** fetch data from the table: "cache" */
  cache: Array<Cache>;
  /** fetch aggregated fields from the table: "cache" */
  cacheAggregate: CacheAggregate;
  /** fetch data from the table: "cache" using primary key columns */
  cacheByPk?: Maybe<Cache>;
  /** fetch data from the table: "cart" */
  cart: Array<Cart>;
  /** fetch aggregated fields from the table: "cart" */
  cartAggregate: CartAggregate;
  /** fetch data from the table: "cart" using primary key columns */
  cartByPk?: Maybe<Cart>;
  /** fetch data from the table: "certification" */
  certification: Array<Certification>;
  /** fetch aggregated fields from the table: "certification" */
  certificationAggregate: CertificationAggregate;
  /** fetch data from the table: "certification" using primary key columns */
  certificationByPk?: Maybe<Certification>;
  /** fetch data from the table: "clearinghouse" */
  clearinghouse: Array<Clearinghouse>;
  /** fetch aggregated fields from the table: "clearinghouse" */
  clearinghouseAggregate: ClearinghouseAggregate;
  /** fetch data from the table: "clearinghouse" using primary key columns */
  clearinghouseByPk?: Maybe<Clearinghouse>;
  /** fetch data from the table: "clearinghouse_certification" */
  clearinghouseCertification: Array<ClearinghouseCertification>;
  /** fetch aggregated fields from the table: "clearinghouse_certification" */
  clearinghouseCertificationAggregate: ClearinghouseCertificationAggregate;
  /** fetch data from the table: "clearinghouse_certification" using primary key columns */
  clearinghouseCertificationByPk?: Maybe<ClearinghouseCertification>;
  /** fetch data from the table: "clearinghouse_kind" */
  clearinghouseKind: Array<ClearinghouseKind>;
  /** fetch aggregated fields from the table: "clearinghouse_kind" */
  clearinghouseKindAggregate: ClearinghouseKindAggregate;
  /** fetch data from the table: "clearinghouse_kind" using primary key columns */
  clearinghouseKindByPk?: Maybe<ClearinghouseKind>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  countryAggregate: CountryAggregate;
  /** fetch data from the table: "country" using primary key columns */
  countryByPk?: Maybe<Country>;
  /** fetch data from the table: "country_region" */
  countryRegion: Array<CountryRegion>;
  /** fetch aggregated fields from the table: "country_region" */
  countryRegionAggregate: CountryRegionAggregate;
  /** fetch data from the table: "country_region" using primary key columns */
  countryRegionByPk?: Maybe<CountryRegion>;
  /** fetch data from the table: "currency" */
  currency: Array<Currency>;
  /** fetch aggregated fields from the table: "currency" */
  currencyAggregate: CurrencyAggregate;
  /** fetch data from the table: "currency" using primary key columns */
  currencyByPk?: Maybe<Currency>;
  /** fetch data from the table: "currency_kind" */
  currencyKind: Array<CurrencyKind>;
  /** fetch aggregated fields from the table: "currency_kind" */
  currencyKindAggregate: CurrencyKindAggregate;
  /** fetch data from the table: "currency_kind" using primary key columns */
  currencyKindByPk?: Maybe<CurrencyKind>;
  /** fetch data from the table: "currency_owner" */
  currencyOwner: Array<CurrencyOwner>;
  /** fetch aggregated fields from the table: "currency_owner" */
  currencyOwnerAggregate: CurrencyOwnerAggregate;
  /** fetch data from the table: "currency_owner" using primary key columns */
  currencyOwnerByPk?: Maybe<CurrencyOwner>;
  /** fetch data from the table: "datasource" */
  datasource: Array<Datasource>;
  /** fetch aggregated fields from the table: "datasource" */
  datasourceAggregate: DatasourceAggregate;
  /** fetch data from the table: "datasource" using primary key columns */
  datasourceByPk?: Maybe<Datasource>;
  /** fetch data from the table: "document_user" */
  documentUser: Array<DocumentUser>;
  /** fetch aggregated fields from the table: "document_user" */
  documentUserAggregate: DocumentUserAggregate;
  /** fetch data from the table: "document_user" using primary key columns */
  documentUserByPk?: Maybe<DocumentUser>;
  /** fetch data from the table: "exchange" */
  exchange: Array<Exchange>;
  /** fetch aggregated fields from the table: "exchange" */
  exchangeAggregate: ExchangeAggregate;
  /** fetch data from the table: "exchange" using primary key columns */
  exchangeByPk?: Maybe<Exchange>;
  /** fetch data from the table: "exchange_kind" */
  exchangeKind: Array<ExchangeKind>;
  /** fetch aggregated fields from the table: "exchange_kind" */
  exchangeKindAggregate: ExchangeKindAggregate;
  /** fetch data from the table: "exchange_kind" using primary key columns */
  exchangeKindByPk?: Maybe<ExchangeKind>;
  /** fetch data from the table: "favorite" */
  favorite: Array<Favorite>;
  /** fetch aggregated fields from the table: "favorite" */
  favoriteAggregate: FavoriteAggregate;
  /** fetch data from the table: "favorite" using primary key columns */
  favoriteByPk?: Maybe<Favorite>;
  /** fetch data from the table: "favorite_comment" */
  favoriteComment: Array<FavoriteComment>;
  /** fetch aggregated fields from the table: "favorite_comment" */
  favoriteCommentAggregate: FavoriteCommentAggregate;
  /** fetch data from the table: "favorite_comment" using primary key columns */
  favoriteCommentByPk?: Maybe<FavoriteComment>;
  /** fetch data from the table: "gics_sector" */
  gicsSector: Array<GicsSector>;
  /** fetch aggregated fields from the table: "gics_sector" */
  gicsSectorAggregate: GicsSectorAggregate;
  /** fetch data from the table: "gics_sector" using primary key columns */
  gicsSectorByPk?: Maybe<GicsSector>;
  /** fetch data from the table: "group" */
  group: Array<Group>;
  /** fetch aggregated fields from the table: "group" */
  groupAggregate: GroupAggregate;
  /** fetch data from the table: "group" using primary key columns */
  groupByPk?: Maybe<Group>;
  /** fetch data from the table: "industry" */
  industry: Array<Industry>;
  /** fetch aggregated fields from the table: "industry" */
  industryAggregate: IndustryAggregate;
  /** fetch data from the table: "industry" using primary key columns */
  industryByPk?: Maybe<Industry>;
  /** fetch data from the table: "interaction" */
  interaction: Array<Interaction>;
  /** fetch aggregated fields from the table: "interaction" */
  interactionAggregate: InteractionAggregate;
  /** fetch data from the table: "interaction" using primary key columns */
  interactionByPk?: Maybe<Interaction>;
  isHandleAvailable?: Maybe<IsHandleAvailable>;
  /** fetch data from the table: "kyc_kind" */
  kycKind: Array<KycKind>;
  /** fetch aggregated fields from the table: "kyc_kind" */
  kycKindAggregate: KycKindAggregate;
  /** fetch data from the table: "kyc_kind" using primary key columns */
  kycKindByPk?: Maybe<KycKind>;
  /** fetch data from the table: "kyc_status" */
  kycStatus: Array<KycStatus>;
  /** fetch aggregated fields from the table: "kyc_status" */
  kycStatusAggregate: KycStatusAggregate;
  /** fetch data from the table: "kyc_status" using primary key columns */
  kycStatusByPk?: Maybe<KycStatus>;
  /** fetch data from the table: "market" */
  market: Array<Market>;
  /** fetch aggregated fields from the table: "market" */
  marketAggregate: MarketAggregate;
  /** fetch data from the table: "market" using primary key columns */
  marketByPk?: Maybe<Market>;
  /** fetch data from the table: "material" */
  material: Array<Material>;
  /** fetch aggregated fields from the table: "material" */
  materialAggregate: MaterialAggregate;
  /** fetch data from the table: "material" using primary key columns */
  materialByPk?: Maybe<Material>;
  /** fetch data from the table: "merchant" */
  merchant: Array<Merchant>;
  /** fetch aggregated fields from the table: "merchant" */
  merchantAggregate: MerchantAggregate;
  /** fetch data from the table: "merchant" using primary key columns */
  merchantByPk?: Maybe<Merchant>;
  /** fetch data from the table: "merchant_currency" */
  merchantCurrency: Array<MerchantCurrency>;
  /** fetch aggregated fields from the table: "merchant_currency" */
  merchantCurrencyAggregate: MerchantCurrencyAggregate;
  /** fetch data from the table: "merchant_currency" using primary key columns */
  merchantCurrencyByPk?: Maybe<MerchantCurrency>;
  /** fetch data from the table: "merchant_organization" */
  merchantOrganization: Array<MerchantOrganization>;
  /** fetch aggregated fields from the table: "merchant_organization" */
  merchantOrganizationAggregate: MerchantOrganizationAggregate;
  /** fetch data from the table: "merchant_organization" using primary key columns */
  merchantOrganizationByPk?: Maybe<MerchantOrganization>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "order" */
  orderAggregate: OrderAggregate;
  /** fetch data from the table: "order" using primary key columns */
  orderByPk?: Maybe<Order>;
  /** fetch data from the table: "organization" */
  organization: Array<Organization>;
  /** fetch aggregated fields from the table: "organization" */
  organizationAggregate: OrganizationAggregate;
  /** fetch data from the table: "organization" using primary key columns */
  organizationByPk?: Maybe<Organization>;
  /** fetch data from the table: "organization_kind" */
  organizationKind: Array<OrganizationKind>;
  /** fetch aggregated fields from the table: "organization_kind" */
  organizationKindAggregate: OrganizationKindAggregate;
  /** fetch data from the table: "organization_kind" using primary key columns */
  organizationKindByPk?: Maybe<OrganizationKind>;
  /** fetch data from the table: "organization_person" */
  organizationPerson: Array<OrganizationPerson>;
  /** fetch aggregated fields from the table: "organization_person" */
  organizationPersonAggregate: OrganizationPersonAggregate;
  /** fetch data from the table: "organization_person" using primary key columns */
  organizationPersonByPk?: Maybe<OrganizationPerson>;
  /** fetch data from the table: "organization_status" */
  organizationStatus: Array<OrganizationStatus>;
  /** fetch aggregated fields from the table: "organization_status" */
  organizationStatusAggregate: OrganizationStatusAggregate;
  /** fetch data from the table: "organization_status" using primary key columns */
  organizationStatusByPk?: Maybe<OrganizationStatus>;
  /** fetch data from the table: "password_reset" */
  passwordReset: Array<PasswordReset>;
  /** fetch aggregated fields from the table: "password_reset" */
  passwordResetAggregate: PasswordResetAggregate;
  /** fetch data from the table: "password_reset" using primary key columns */
  passwordResetByPk?: Maybe<PasswordReset>;
  /** fetch data from the table: "payment" */
  payment: Array<Payment>;
  /** fetch aggregated fields from the table: "payment" */
  paymentAggregate: PaymentAggregate;
  /** fetch data from the table: "payment" using primary key columns */
  paymentByPk?: Maybe<Payment>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  productAggregate: ProductAggregate;
  /** fetch data from the table: "product" using primary key columns */
  productByPk?: Maybe<Product>;
  /** fetch data from the table: "product_distribution" */
  productDistribution: Array<ProductDistribution>;
  /** fetch aggregated fields from the table: "product_distribution" */
  productDistributionAggregate: ProductDistributionAggregate;
  /** fetch data from the table: "product_distribution" using primary key columns */
  productDistributionByPk?: Maybe<ProductDistribution>;
  /** fetch data from the table: "product_kind" */
  productKind: Array<ProductKind>;
  /** fetch aggregated fields from the table: "product_kind" */
  productKindAggregate: ProductKindAggregate;
  /** fetch data from the table: "product_kind" using primary key columns */
  productKindByPk?: Maybe<ProductKind>;
  /** fetch data from the table: "product_status" */
  productStatus: Array<ProductStatus>;
  /** fetch aggregated fields from the table: "product_status" */
  productStatusAggregate: ProductStatusAggregate;
  /** fetch data from the table: "product_status" using primary key columns */
  productStatusByPk?: Maybe<ProductStatus>;
  /** fetch data from the table: "put" */
  put: Array<Put>;
  /** fetch aggregated fields from the table: "put" */
  putAggregate: PutAggregate;
  /** fetch data from the table: "put" using primary key columns */
  putByPk?: Maybe<Put>;
  /** fetch data from the table: "setting" */
  setting: Array<Setting>;
  /** fetch aggregated fields from the table: "setting" */
  settingAggregate: SettingAggregate;
  /** fetch data from the table: "setting" using primary key columns */
  settingByPk?: Maybe<Setting>;
  /** fetch data from the table: "shipping" */
  shipping: Array<Shipping>;
  /** fetch aggregated fields from the table: "shipping" */
  shippingAggregate: ShippingAggregate;
  /** fetch data from the table: "shipping" using primary key columns */
  shippingByPk?: Maybe<Shipping>;
  /** fetch data from the table: "shop" */
  shop: Array<Shop>;
  /** fetch aggregated fields from the table: "shop" */
  shopAggregate: ShopAggregate;
  /** fetch data from the table: "shop" using primary key columns */
  shopByPk?: Maybe<Shop>;
  /** fetch data from the table: "sic_code" */
  sicCode: Array<SicCode>;
  /** fetch aggregated fields from the table: "sic_code" */
  sicCodeAggregate: SicCodeAggregate;
  /** fetch data from the table: "sic_code" using primary key columns */
  sicCodeByPk?: Maybe<SicCode>;
  /** fetch data from the table: "sigma_identity" */
  sigmaIdentity: Array<SigmaIdentity>;
  /** fetch aggregated fields from the table: "sigma_identity" */
  sigmaIdentityAggregate: SigmaIdentityAggregate;
  /** fetch data from the table: "sigma_identity" using primary key columns */
  sigmaIdentityByPk?: Maybe<SigmaIdentity>;
  /** fetch data from the table: "sigma_prime" */
  sigmaPrime: Array<SigmaPrime>;
  /** fetch data from the table: "sigma_prime_address" */
  sigmaPrimeAddress: Array<SigmaPrimeAddress>;
  /** fetch aggregated fields from the table: "sigma_prime_address" */
  sigmaPrimeAddressAggregate: SigmaPrimeAddressAggregate;
  /** fetch data from the table: "sigma_prime_address" using primary key columns */
  sigmaPrimeAddressByPk?: Maybe<SigmaPrimeAddress>;
  /** fetch aggregated fields from the table: "sigma_prime" */
  sigmaPrimeAggregate: SigmaPrimeAggregate;
  /** fetch data from the table: "sigma_prime" using primary key columns */
  sigmaPrimeByPk?: Maybe<SigmaPrime>;
  /** fetch data from the table: "sigma_prime_kind" */
  sigmaPrimeKind: Array<SigmaPrimeKind>;
  /** fetch aggregated fields from the table: "sigma_prime_kind" */
  sigmaPrimeKindAggregate: SigmaPrimeKindAggregate;
  /** fetch data from the table: "sigma_prime_kind" using primary key columns */
  sigmaPrimeKindByPk?: Maybe<SigmaPrimeKind>;
  /** fetch data from the table: "sigma_social" */
  sigmaSocial: Array<SigmaSocial>;
  /** fetch aggregated fields from the table: "sigma_social" */
  sigmaSocialAggregate: SigmaSocialAggregate;
  /** fetch data from the table: "sigma_social" using primary key columns */
  sigmaSocialByPk?: Maybe<SigmaSocial>;
  /** fetch data from the table: "smart_contract" */
  smartContract: Array<SmartContract>;
  /** fetch aggregated fields from the table: "smart_contract" */
  smartContractAggregate: SmartContractAggregate;
  /** fetch data from the table: "smart_contract" using primary key columns */
  smartContractByPk?: Maybe<SmartContract>;
  /** fetch data from the table: "smart_contract_commission" */
  smartContractCommission: Array<SmartContractCommission>;
  /** fetch aggregated fields from the table: "smart_contract_commission" */
  smartContractCommissionAggregate: SmartContractCommissionAggregate;
  /** fetch data from the table: "smart_contract_commission" using primary key columns */
  smartContractCommissionByPk?: Maybe<SmartContractCommission>;
  /** fetch data from the table: "smart_contract_document" */
  smartContractDocument: Array<SmartContractDocument>;
  /** fetch aggregated fields from the table: "smart_contract_document" */
  smartContractDocumentAggregate: SmartContractDocumentAggregate;
  /** fetch data from the table: "smart_contract_document" using primary key columns */
  smartContractDocumentByPk?: Maybe<SmartContractDocument>;
  /** fetch data from the table: "smart_contract_kind" */
  smartContractKind: Array<SmartContractKind>;
  /** fetch aggregated fields from the table: "smart_contract_kind" */
  smartContractKindAggregate: SmartContractKindAggregate;
  /** fetch data from the table: "smart_contract_kind" using primary key columns */
  smartContractKindByPk?: Maybe<SmartContractKind>;
  /** fetch data from the table: "smart_contract_lineage" */
  smartContractLineage: Array<SmartContractLineage>;
  /** fetch aggregated fields from the table: "smart_contract_lineage" */
  smartContractLineageAggregate: SmartContractLineageAggregate;
  /** fetch data from the table: "smart_contract_lineage" using primary key columns */
  smartContractLineageByPk?: Maybe<SmartContractLineage>;
  /** fetch data from the table: "state" */
  state: Array<State>;
  /** fetch aggregated fields from the table: "state" */
  stateAggregate: StateAggregate;
  /** fetch data from the table: "status" */
  status: Array<Status>;
  /** fetch aggregated fields from the table: "status" */
  statusAggregate: StatusAggregate;
  /** fetch data from the table: "status" using primary key columns */
  statusByPk?: Maybe<Status>;
  /** fetch data from the table: "status_kind" */
  statusKind: Array<StatusKind>;
  /** fetch aggregated fields from the table: "status_kind" */
  statusKindAggregate: StatusKindAggregate;
  /** fetch data from the table: "status_kind" using primary key columns */
  statusKindByPk?: Maybe<StatusKind>;
  /** fetch data from the table: "subindustry" */
  subindustry: Array<Subindustry>;
  /** fetch aggregated fields from the table: "subindustry" */
  subindustryAggregate: SubindustryAggregate;
  /** fetch data from the table: "subindustry" using primary key columns */
  subindustryByPk?: Maybe<Subindustry>;
  /** fetch data from the table: "supply_chain_waypoint" */
  supplyChainWaypoint: Array<SupplyChainWaypoint>;
  /** fetch aggregated fields from the table: "supply_chain_waypoint" */
  supplyChainWaypointAggregate: SupplyChainWaypointAggregate;
  /** fetch data from the table: "supply_chain_waypoint" using primary key columns */
  supplyChainWaypointByPk?: Maybe<SupplyChainWaypoint>;
  /** fetch data from the table: "trade" */
  trade: Array<Trade>;
  /** fetch aggregated fields from the table: "trade" */
  tradeAggregate: TradeAggregate;
  /** fetch data from the table: "trade" using primary key columns */
  tradeByPk?: Maybe<Trade>;
  /** fetch data from the table: "trade_kind" */
  tradeKind: Array<TradeKind>;
  /** fetch aggregated fields from the table: "trade_kind" */
  tradeKindAggregate: TradeKindAggregate;
  /** fetch data from the table: "trade_kind" using primary key columns */
  tradeKindByPk?: Maybe<TradeKind>;
  /** fetch data from the table: "transaction" */
  transaction: Array<Transaction>;
  /** fetch aggregated fields from the table: "transaction" */
  transactionAggregate: TransactionAggregate;
  /** fetch data from the table: "transaction" using primary key columns */
  transactionByPk?: Maybe<Transaction>;
  /** fetch data from the table: "transaction_kind" */
  transactionKind: Array<TransactionKind>;
  /** fetch aggregated fields from the table: "transaction_kind" */
  transactionKindAggregate: TransactionKindAggregate;
  /** fetch data from the table: "transaction_kind" using primary key columns */
  transactionKindByPk?: Maybe<TransactionKind>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  userAggregate: UserAggregate;
  /** fetch data from the table: "user_auth" */
  userAuth: Array<UserAuth>;
  /** fetch aggregated fields from the table: "user_auth" */
  userAuthAggregate: UserAuthAggregate;
  /** fetch data from the table: "user_auth" using primary key columns */
  userAuthByPk?: Maybe<UserAuth>;
  /** fetch data from the table: "user" using primary key columns */
  userByPk?: Maybe<User>;
  /** fetch data from the table: "wallet" */
  wallet: Array<Wallet>;
  /** fetch aggregated fields from the table: "wallet" */
  walletAggregate: WalletAggregate;
  /** fetch data from the table: "wallet" using primary key columns */
  walletByPk?: Maybe<Wallet>;
  /** fetch data from the table: "wallet_inventory" */
  walletInventory: Array<WalletInventory>;
  /** fetch aggregated fields from the table: "wallet_inventory" */
  walletInventoryAggregate: WalletInventoryAggregate;
  /** fetch data from the table: "wallet_inventory" using primary key columns */
  walletInventoryByPk?: Maybe<WalletInventory>;
  /** fetch data from the table: "wallet_kind" */
  walletKind: Array<WalletKind>;
  /** fetch aggregated fields from the table: "wallet_kind" */
  walletKindAggregate: WalletKindAggregate;
  /** fetch data from the table: "wallet_kind" using primary key columns */
  walletKindByPk?: Maybe<WalletKind>;
};

export type Query_RootAddressAttributesArgs = {
  distinctOn?: InputMaybe<Array<AddressAttributesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressAttributesOrderBy>>;
  where?: InputMaybe<AddressAttributesBoolExp>;
};

export type Query_RootAddressAttributesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AddressAttributesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressAttributesOrderBy>>;
  where?: InputMaybe<AddressAttributesBoolExp>;
};

export type Query_RootAddressAttributesByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAppraisalArgs = {
  distinctOn?: InputMaybe<Array<AppraisalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppraisalOrderBy>>;
  where?: InputMaybe<AppraisalBoolExp>;
};

export type Query_RootAppraisalAggregateArgs = {
  distinctOn?: InputMaybe<Array<AppraisalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppraisalOrderBy>>;
  where?: InputMaybe<AppraisalBoolExp>;
};

export type Query_RootAppraisalByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAskArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

export type Query_RootAskAggregateArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

export type Query_RootAskByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAskPutTransactionArgs = {
  distinctOn?: InputMaybe<Array<AskPutTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskPutTransactionOrderBy>>;
  where?: InputMaybe<AskPutTransactionBoolExp>;
};

export type Query_RootAskPutTransactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AskPutTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskPutTransactionOrderBy>>;
  where?: InputMaybe<AskPutTransactionBoolExp>;
};

export type Query_RootAskPutTransactionByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAssetArgs = {
  distinctOn?: InputMaybe<Array<AssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetOrderBy>>;
  where?: InputMaybe<AssetBoolExp>;
};

export type Query_RootAssetAccountArgs = {
  distinctOn?: InputMaybe<Array<AssetAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetAccountOrderBy>>;
  where?: InputMaybe<AssetAccountBoolExp>;
};

export type Query_RootAssetAccountAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssetAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetAccountOrderBy>>;
  where?: InputMaybe<AssetAccountBoolExp>;
};

export type Query_RootAssetAccountByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAssetAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetOrderBy>>;
  where?: InputMaybe<AssetBoolExp>;
};

export type Query_RootAssetByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAssetKindArgs = {
  distinctOn?: InputMaybe<Array<AssetKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetKindOrderBy>>;
  where?: InputMaybe<AssetKindBoolExp>;
};

export type Query_RootAssetKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssetKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetKindOrderBy>>;
  where?: InputMaybe<AssetKindBoolExp>;
};

export type Query_RootAssetKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCacheArgs = {
  distinctOn?: InputMaybe<Array<CacheSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CacheOrderBy>>;
  where?: InputMaybe<CacheBoolExp>;
};

export type Query_RootCacheAggregateArgs = {
  distinctOn?: InputMaybe<Array<CacheSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CacheOrderBy>>;
  where?: InputMaybe<CacheBoolExp>;
};

export type Query_RootCacheByPkArgs = {
  key: Scalars['String'];
};

export type Query_RootCartArgs = {
  distinctOn?: InputMaybe<Array<CartSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CartOrderBy>>;
  where?: InputMaybe<CartBoolExp>;
};

export type Query_RootCartAggregateArgs = {
  distinctOn?: InputMaybe<Array<CartSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CartOrderBy>>;
  where?: InputMaybe<CartBoolExp>;
};

export type Query_RootCartByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCertificationArgs = {
  distinctOn?: InputMaybe<Array<CertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CertificationOrderBy>>;
  where?: InputMaybe<CertificationBoolExp>;
};

export type Query_RootCertificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<CertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CertificationOrderBy>>;
  where?: InputMaybe<CertificationBoolExp>;
};

export type Query_RootCertificationByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClearinghouseArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseOrderBy>>;
  where?: InputMaybe<ClearinghouseBoolExp>;
};

export type Query_RootClearinghouseAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseOrderBy>>;
  where?: InputMaybe<ClearinghouseBoolExp>;
};

export type Query_RootClearinghouseByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClearinghouseCertificationArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseCertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseCertificationOrderBy>>;
  where?: InputMaybe<ClearinghouseCertificationBoolExp>;
};

export type Query_RootClearinghouseCertificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseCertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseCertificationOrderBy>>;
  where?: InputMaybe<ClearinghouseCertificationBoolExp>;
};

export type Query_RootClearinghouseCertificationByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClearinghouseKindArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseKindOrderBy>>;
  where?: InputMaybe<ClearinghouseKindBoolExp>;
};

export type Query_RootClearinghouseKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseKindOrderBy>>;
  where?: InputMaybe<ClearinghouseKindBoolExp>;
};

export type Query_RootClearinghouseKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCountryArgs = {
  distinctOn?: InputMaybe<Array<CountrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountryOrderBy>>;
  where?: InputMaybe<CountryBoolExp>;
};

export type Query_RootCountryAggregateArgs = {
  distinctOn?: InputMaybe<Array<CountrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountryOrderBy>>;
  where?: InputMaybe<CountryBoolExp>;
};

export type Query_RootCountryByPkArgs = {
  code: Scalars['String'];
};

export type Query_RootCountryRegionArgs = {
  distinctOn?: InputMaybe<Array<CountryRegionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountryRegionOrderBy>>;
  where?: InputMaybe<CountryRegionBoolExp>;
};

export type Query_RootCountryRegionAggregateArgs = {
  distinctOn?: InputMaybe<Array<CountryRegionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountryRegionOrderBy>>;
  where?: InputMaybe<CountryRegionBoolExp>;
};

export type Query_RootCountryRegionByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCurrencyArgs = {
  distinctOn?: InputMaybe<Array<CurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOrderBy>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

export type Query_RootCurrencyAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOrderBy>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

export type Query_RootCurrencyByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCurrencyKindArgs = {
  distinctOn?: InputMaybe<Array<CurrencyKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyKindOrderBy>>;
  where?: InputMaybe<CurrencyKindBoolExp>;
};

export type Query_RootCurrencyKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencyKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyKindOrderBy>>;
  where?: InputMaybe<CurrencyKindBoolExp>;
};

export type Query_RootCurrencyKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCurrencyOwnerArgs = {
  distinctOn?: InputMaybe<Array<CurrencyOwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOwnerOrderBy>>;
  where?: InputMaybe<CurrencyOwnerBoolExp>;
};

export type Query_RootCurrencyOwnerAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencyOwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOwnerOrderBy>>;
  where?: InputMaybe<CurrencyOwnerBoolExp>;
};

export type Query_RootCurrencyOwnerByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDatasourceArgs = {
  distinctOn?: InputMaybe<Array<DatasourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DatasourceOrderBy>>;
  where?: InputMaybe<DatasourceBoolExp>;
};

export type Query_RootDatasourceAggregateArgs = {
  distinctOn?: InputMaybe<Array<DatasourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DatasourceOrderBy>>;
  where?: InputMaybe<DatasourceBoolExp>;
};

export type Query_RootDatasourceByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDocumentUserArgs = {
  distinctOn?: InputMaybe<Array<DocumentUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentUserOrderBy>>;
  where?: InputMaybe<DocumentUserBoolExp>;
};

export type Query_RootDocumentUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<DocumentUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentUserOrderBy>>;
  where?: InputMaybe<DocumentUserBoolExp>;
};

export type Query_RootDocumentUserByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootExchangeArgs = {
  distinctOn?: InputMaybe<Array<ExchangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeOrderBy>>;
  where?: InputMaybe<ExchangeBoolExp>;
};

export type Query_RootExchangeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExchangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeOrderBy>>;
  where?: InputMaybe<ExchangeBoolExp>;
};

export type Query_RootExchangeByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootExchangeKindArgs = {
  distinctOn?: InputMaybe<Array<ExchangeKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeKindOrderBy>>;
  where?: InputMaybe<ExchangeKindBoolExp>;
};

export type Query_RootExchangeKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExchangeKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeKindOrderBy>>;
  where?: InputMaybe<ExchangeKindBoolExp>;
};

export type Query_RootExchangeKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootFavoriteArgs = {
  distinctOn?: InputMaybe<Array<FavoriteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteOrderBy>>;
  where?: InputMaybe<FavoriteBoolExp>;
};

export type Query_RootFavoriteAggregateArgs = {
  distinctOn?: InputMaybe<Array<FavoriteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteOrderBy>>;
  where?: InputMaybe<FavoriteBoolExp>;
};

export type Query_RootFavoriteByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootFavoriteCommentArgs = {
  distinctOn?: InputMaybe<Array<FavoriteCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteCommentOrderBy>>;
  where?: InputMaybe<FavoriteCommentBoolExp>;
};

export type Query_RootFavoriteCommentAggregateArgs = {
  distinctOn?: InputMaybe<Array<FavoriteCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteCommentOrderBy>>;
  where?: InputMaybe<FavoriteCommentBoolExp>;
};

export type Query_RootFavoriteCommentByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGicsSectorArgs = {
  distinctOn?: InputMaybe<Array<GicsSectorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GicsSectorOrderBy>>;
  where?: InputMaybe<GicsSectorBoolExp>;
};

export type Query_RootGicsSectorAggregateArgs = {
  distinctOn?: InputMaybe<Array<GicsSectorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GicsSectorOrderBy>>;
  where?: InputMaybe<GicsSectorBoolExp>;
};

export type Query_RootGicsSectorByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGroupArgs = {
  distinctOn?: InputMaybe<Array<GroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GroupOrderBy>>;
  where?: InputMaybe<GroupBoolExp>;
};

export type Query_RootGroupAggregateArgs = {
  distinctOn?: InputMaybe<Array<GroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GroupOrderBy>>;
  where?: InputMaybe<GroupBoolExp>;
};

export type Query_RootGroupByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootIndustryArgs = {
  distinctOn?: InputMaybe<Array<IndustrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IndustryOrderBy>>;
  where?: InputMaybe<IndustryBoolExp>;
};

export type Query_RootIndustryAggregateArgs = {
  distinctOn?: InputMaybe<Array<IndustrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IndustryOrderBy>>;
  where?: InputMaybe<IndustryBoolExp>;
};

export type Query_RootIndustryByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootInteractionArgs = {
  distinctOn?: InputMaybe<Array<InteractionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionOrderBy>>;
  where?: InputMaybe<InteractionBoolExp>;
};

export type Query_RootInteractionAggregateArgs = {
  distinctOn?: InputMaybe<Array<InteractionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionOrderBy>>;
  where?: InputMaybe<InteractionBoolExp>;
};

export type Query_RootInteractionByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootIsHandleAvailableArgs = {
  handle: Scalars['String'];
};

export type Query_RootKycKindArgs = {
  distinctOn?: InputMaybe<Array<KycKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<KycKindOrderBy>>;
  where?: InputMaybe<KycKindBoolExp>;
};

export type Query_RootKycKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<KycKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<KycKindOrderBy>>;
  where?: InputMaybe<KycKindBoolExp>;
};

export type Query_RootKycKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootKycStatusArgs = {
  distinctOn?: InputMaybe<Array<KycStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<KycStatusOrderBy>>;
  where?: InputMaybe<KycStatusBoolExp>;
};

export type Query_RootKycStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<KycStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<KycStatusOrderBy>>;
  where?: InputMaybe<KycStatusBoolExp>;
};

export type Query_RootKycStatusByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMarketArgs = {
  distinctOn?: InputMaybe<Array<MarketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MarketOrderBy>>;
  where?: InputMaybe<MarketBoolExp>;
};

export type Query_RootMarketAggregateArgs = {
  distinctOn?: InputMaybe<Array<MarketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MarketOrderBy>>;
  where?: InputMaybe<MarketBoolExp>;
};

export type Query_RootMarketByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMaterialArgs = {
  distinctOn?: InputMaybe<Array<MaterialSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MaterialOrderBy>>;
  where?: InputMaybe<MaterialBoolExp>;
};

export type Query_RootMaterialAggregateArgs = {
  distinctOn?: InputMaybe<Array<MaterialSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MaterialOrderBy>>;
  where?: InputMaybe<MaterialBoolExp>;
};

export type Query_RootMaterialByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMerchantArgs = {
  distinctOn?: InputMaybe<Array<MerchantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantOrderBy>>;
  where?: InputMaybe<MerchantBoolExp>;
};

export type Query_RootMerchantAggregateArgs = {
  distinctOn?: InputMaybe<Array<MerchantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantOrderBy>>;
  where?: InputMaybe<MerchantBoolExp>;
};

export type Query_RootMerchantByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMerchantCurrencyArgs = {
  distinctOn?: InputMaybe<Array<MerchantCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantCurrencyOrderBy>>;
  where?: InputMaybe<MerchantCurrencyBoolExp>;
};

export type Query_RootMerchantCurrencyAggregateArgs = {
  distinctOn?: InputMaybe<Array<MerchantCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantCurrencyOrderBy>>;
  where?: InputMaybe<MerchantCurrencyBoolExp>;
};

export type Query_RootMerchantCurrencyByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMerchantOrganizationArgs = {
  distinctOn?: InputMaybe<Array<MerchantOrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantOrganizationOrderBy>>;
  where?: InputMaybe<MerchantOrganizationBoolExp>;
};

export type Query_RootMerchantOrganizationAggregateArgs = {
  distinctOn?: InputMaybe<Array<MerchantOrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantOrganizationOrderBy>>;
  where?: InputMaybe<MerchantOrganizationBoolExp>;
};

export type Query_RootMerchantOrganizationByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrderArgs = {
  distinctOn?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};

export type Query_RootOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};

export type Query_RootOrderByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganizationArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};

export type Query_RootOrganizationAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};

export type Query_RootOrganizationByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganizationKindArgs = {
  distinctOn?: InputMaybe<Array<OrganizationKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationKindOrderBy>>;
  where?: InputMaybe<OrganizationKindBoolExp>;
};

export type Query_RootOrganizationKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationKindOrderBy>>;
  where?: InputMaybe<OrganizationKindBoolExp>;
};

export type Query_RootOrganizationKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganizationPersonArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPersonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPersonOrderBy>>;
  where?: InputMaybe<OrganizationPersonBoolExp>;
};

export type Query_RootOrganizationPersonAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPersonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPersonOrderBy>>;
  where?: InputMaybe<OrganizationPersonBoolExp>;
};

export type Query_RootOrganizationPersonByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganizationStatusArgs = {
  distinctOn?: InputMaybe<Array<OrganizationStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationStatusOrderBy>>;
  where?: InputMaybe<OrganizationStatusBoolExp>;
};

export type Query_RootOrganizationStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationStatusOrderBy>>;
  where?: InputMaybe<OrganizationStatusBoolExp>;
};

export type Query_RootOrganizationStatusByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPasswordResetArgs = {
  distinctOn?: InputMaybe<Array<PasswordResetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PasswordResetOrderBy>>;
  where?: InputMaybe<PasswordResetBoolExp>;
};

export type Query_RootPasswordResetAggregateArgs = {
  distinctOn?: InputMaybe<Array<PasswordResetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PasswordResetOrderBy>>;
  where?: InputMaybe<PasswordResetBoolExp>;
};

export type Query_RootPasswordResetByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPaymentArgs = {
  distinctOn?: InputMaybe<Array<PaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentOrderBy>>;
  where?: InputMaybe<PaymentBoolExp>;
};

export type Query_RootPaymentAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentOrderBy>>;
  where?: InputMaybe<PaymentBoolExp>;
};

export type Query_RootPaymentByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProductArgs = {
  distinctOn?: InputMaybe<Array<ProductSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
  where?: InputMaybe<ProductBoolExp>;
};

export type Query_RootProductAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
  where?: InputMaybe<ProductBoolExp>;
};

export type Query_RootProductByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProductDistributionArgs = {
  distinctOn?: InputMaybe<Array<ProductDistributionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductDistributionOrderBy>>;
  where?: InputMaybe<ProductDistributionBoolExp>;
};

export type Query_RootProductDistributionAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductDistributionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductDistributionOrderBy>>;
  where?: InputMaybe<ProductDistributionBoolExp>;
};

export type Query_RootProductDistributionByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProductKindArgs = {
  distinctOn?: InputMaybe<Array<ProductKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductKindOrderBy>>;
  where?: InputMaybe<ProductKindBoolExp>;
};

export type Query_RootProductKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductKindOrderBy>>;
  where?: InputMaybe<ProductKindBoolExp>;
};

export type Query_RootProductKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProductStatusArgs = {
  distinctOn?: InputMaybe<Array<ProductStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductStatusOrderBy>>;
  where?: InputMaybe<ProductStatusBoolExp>;
};

export type Query_RootProductStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductStatusOrderBy>>;
  where?: InputMaybe<ProductStatusBoolExp>;
};

export type Query_RootProductStatusByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPutArgs = {
  distinctOn?: InputMaybe<Array<PutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PutOrderBy>>;
  where?: InputMaybe<PutBoolExp>;
};

export type Query_RootPutAggregateArgs = {
  distinctOn?: InputMaybe<Array<PutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PutOrderBy>>;
  where?: InputMaybe<PutBoolExp>;
};

export type Query_RootPutByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSettingArgs = {
  distinctOn?: InputMaybe<Array<SettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SettingOrderBy>>;
  where?: InputMaybe<SettingBoolExp>;
};

export type Query_RootSettingAggregateArgs = {
  distinctOn?: InputMaybe<Array<SettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SettingOrderBy>>;
  where?: InputMaybe<SettingBoolExp>;
};

export type Query_RootSettingByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootShippingArgs = {
  distinctOn?: InputMaybe<Array<ShippingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ShippingOrderBy>>;
  where?: InputMaybe<ShippingBoolExp>;
};

export type Query_RootShippingAggregateArgs = {
  distinctOn?: InputMaybe<Array<ShippingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ShippingOrderBy>>;
  where?: InputMaybe<ShippingBoolExp>;
};

export type Query_RootShippingByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootShopArgs = {
  distinctOn?: InputMaybe<Array<ShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ShopOrderBy>>;
  where?: InputMaybe<ShopBoolExp>;
};

export type Query_RootShopAggregateArgs = {
  distinctOn?: InputMaybe<Array<ShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ShopOrderBy>>;
  where?: InputMaybe<ShopBoolExp>;
};

export type Query_RootShopByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSicCodeArgs = {
  distinctOn?: InputMaybe<Array<SicCodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SicCodeOrderBy>>;
  where?: InputMaybe<SicCodeBoolExp>;
};

export type Query_RootSicCodeAggregateArgs = {
  distinctOn?: InputMaybe<Array<SicCodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SicCodeOrderBy>>;
  where?: InputMaybe<SicCodeBoolExp>;
};

export type Query_RootSicCodeByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSigmaIdentityArgs = {
  distinctOn?: InputMaybe<Array<SigmaIdentitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaIdentityOrderBy>>;
  where?: InputMaybe<SigmaIdentityBoolExp>;
};

export type Query_RootSigmaIdentityAggregateArgs = {
  distinctOn?: InputMaybe<Array<SigmaIdentitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaIdentityOrderBy>>;
  where?: InputMaybe<SigmaIdentityBoolExp>;
};

export type Query_RootSigmaIdentityByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSigmaPrimeArgs = {
  distinctOn?: InputMaybe<Array<SigmaPrimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaPrimeOrderBy>>;
  where?: InputMaybe<SigmaPrimeBoolExp>;
};

export type Query_RootSigmaPrimeAddressArgs = {
  distinctOn?: InputMaybe<Array<SigmaPrimeAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaPrimeAddressOrderBy>>;
  where?: InputMaybe<SigmaPrimeAddressBoolExp>;
};

export type Query_RootSigmaPrimeAddressAggregateArgs = {
  distinctOn?: InputMaybe<Array<SigmaPrimeAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaPrimeAddressOrderBy>>;
  where?: InputMaybe<SigmaPrimeAddressBoolExp>;
};

export type Query_RootSigmaPrimeAddressByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSigmaPrimeAggregateArgs = {
  distinctOn?: InputMaybe<Array<SigmaPrimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaPrimeOrderBy>>;
  where?: InputMaybe<SigmaPrimeBoolExp>;
};

export type Query_RootSigmaPrimeByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSigmaPrimeKindArgs = {
  distinctOn?: InputMaybe<Array<SigmaPrimeKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaPrimeKindOrderBy>>;
  where?: InputMaybe<SigmaPrimeKindBoolExp>;
};

export type Query_RootSigmaPrimeKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<SigmaPrimeKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaPrimeKindOrderBy>>;
  where?: InputMaybe<SigmaPrimeKindBoolExp>;
};

export type Query_RootSigmaPrimeKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSigmaSocialArgs = {
  distinctOn?: InputMaybe<Array<SigmaSocialSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaSocialOrderBy>>;
  where?: InputMaybe<SigmaSocialBoolExp>;
};

export type Query_RootSigmaSocialAggregateArgs = {
  distinctOn?: InputMaybe<Array<SigmaSocialSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaSocialOrderBy>>;
  where?: InputMaybe<SigmaSocialBoolExp>;
};

export type Query_RootSigmaSocialByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSmartContractArgs = {
  distinctOn?: InputMaybe<Array<SmartContractSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractOrderBy>>;
  where?: InputMaybe<SmartContractBoolExp>;
};

export type Query_RootSmartContractAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmartContractSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractOrderBy>>;
  where?: InputMaybe<SmartContractBoolExp>;
};

export type Query_RootSmartContractByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSmartContractCommissionArgs = {
  distinctOn?: InputMaybe<Array<SmartContractCommissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractCommissionOrderBy>>;
  where?: InputMaybe<SmartContractCommissionBoolExp>;
};

export type Query_RootSmartContractCommissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmartContractCommissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractCommissionOrderBy>>;
  where?: InputMaybe<SmartContractCommissionBoolExp>;
};

export type Query_RootSmartContractCommissionByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSmartContractDocumentArgs = {
  distinctOn?: InputMaybe<Array<SmartContractDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractDocumentOrderBy>>;
  where?: InputMaybe<SmartContractDocumentBoolExp>;
};

export type Query_RootSmartContractDocumentAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmartContractDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractDocumentOrderBy>>;
  where?: InputMaybe<SmartContractDocumentBoolExp>;
};

export type Query_RootSmartContractDocumentByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSmartContractKindArgs = {
  distinctOn?: InputMaybe<Array<SmartContractKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractKindOrderBy>>;
  where?: InputMaybe<SmartContractKindBoolExp>;
};

export type Query_RootSmartContractKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmartContractKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractKindOrderBy>>;
  where?: InputMaybe<SmartContractKindBoolExp>;
};

export type Query_RootSmartContractKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSmartContractLineageArgs = {
  distinctOn?: InputMaybe<Array<SmartContractLineageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractLineageOrderBy>>;
  where?: InputMaybe<SmartContractLineageBoolExp>;
};

export type Query_RootSmartContractLineageAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmartContractLineageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractLineageOrderBy>>;
  where?: InputMaybe<SmartContractLineageBoolExp>;
};

export type Query_RootSmartContractLineageByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootStateArgs = {
  distinctOn?: InputMaybe<Array<StateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StateOrderBy>>;
  where?: InputMaybe<StateBoolExp>;
};

export type Query_RootStateAggregateArgs = {
  distinctOn?: InputMaybe<Array<StateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StateOrderBy>>;
  where?: InputMaybe<StateBoolExp>;
};

export type Query_RootStatusArgs = {
  distinctOn?: InputMaybe<Array<StatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatusOrderBy>>;
  where?: InputMaybe<StatusBoolExp>;
};

export type Query_RootStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatusOrderBy>>;
  where?: InputMaybe<StatusBoolExp>;
};

export type Query_RootStatusByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootStatusKindArgs = {
  distinctOn?: InputMaybe<Array<StatusKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatusKindOrderBy>>;
  where?: InputMaybe<StatusKindBoolExp>;
};

export type Query_RootStatusKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatusKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatusKindOrderBy>>;
  where?: InputMaybe<StatusKindBoolExp>;
};

export type Query_RootStatusKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSubindustryArgs = {
  distinctOn?: InputMaybe<Array<SubindustrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubindustryOrderBy>>;
  where?: InputMaybe<SubindustryBoolExp>;
};

export type Query_RootSubindustryAggregateArgs = {
  distinctOn?: InputMaybe<Array<SubindustrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubindustryOrderBy>>;
  where?: InputMaybe<SubindustryBoolExp>;
};

export type Query_RootSubindustryByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSupplyChainWaypointArgs = {
  distinctOn?: InputMaybe<Array<SupplyChainWaypointSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SupplyChainWaypointOrderBy>>;
  where?: InputMaybe<SupplyChainWaypointBoolExp>;
};

export type Query_RootSupplyChainWaypointAggregateArgs = {
  distinctOn?: InputMaybe<Array<SupplyChainWaypointSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SupplyChainWaypointOrderBy>>;
  where?: InputMaybe<SupplyChainWaypointBoolExp>;
};

export type Query_RootSupplyChainWaypointByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTradeArgs = {
  distinctOn?: InputMaybe<Array<TradeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TradeOrderBy>>;
  where?: InputMaybe<TradeBoolExp>;
};

export type Query_RootTradeAggregateArgs = {
  distinctOn?: InputMaybe<Array<TradeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TradeOrderBy>>;
  where?: InputMaybe<TradeBoolExp>;
};

export type Query_RootTradeByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTradeKindArgs = {
  distinctOn?: InputMaybe<Array<TradeKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TradeKindOrderBy>>;
  where?: InputMaybe<TradeKindBoolExp>;
};

export type Query_RootTradeKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<TradeKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TradeKindOrderBy>>;
  where?: InputMaybe<TradeKindBoolExp>;
};

export type Query_RootTradeKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTransactionArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

export type Query_RootTransactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

export type Query_RootTransactionByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTransactionKindArgs = {
  distinctOn?: InputMaybe<Array<TransactionKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionKindOrderBy>>;
  where?: InputMaybe<TransactionKindBoolExp>;
};

export type Query_RootTransactionKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionKindOrderBy>>;
  where?: InputMaybe<TransactionKindBoolExp>;
};

export type Query_RootTransactionKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUserArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

export type Query_RootUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

export type Query_RootUserAuthArgs = {
  distinctOn?: InputMaybe<Array<UserAuthSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserAuthOrderBy>>;
  where?: InputMaybe<UserAuthBoolExp>;
};

export type Query_RootUserAuthAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserAuthSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserAuthOrderBy>>;
  where?: InputMaybe<UserAuthBoolExp>;
};

export type Query_RootUserAuthByPkArgs = {
  userId: Scalars['uuid'];
};

export type Query_RootUserByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootWalletArgs = {
  distinctOn?: InputMaybe<Array<WalletSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletOrderBy>>;
  where?: InputMaybe<WalletBoolExp>;
};

export type Query_RootWalletAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletOrderBy>>;
  where?: InputMaybe<WalletBoolExp>;
};

export type Query_RootWalletByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootWalletInventoryArgs = {
  distinctOn?: InputMaybe<Array<WalletInventorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletInventoryOrderBy>>;
  where?: InputMaybe<WalletInventoryBoolExp>;
};

export type Query_RootWalletInventoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletInventorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletInventoryOrderBy>>;
  where?: InputMaybe<WalletInventoryBoolExp>;
};

export type Query_RootWalletInventoryByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootWalletKindArgs = {
  distinctOn?: InputMaybe<Array<WalletKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletKindOrderBy>>;
  where?: InputMaybe<WalletKindBoolExp>;
};

export type Query_RootWalletKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletKindOrderBy>>;
  where?: InputMaybe<WalletKindBoolExp>;
};

export type Query_RootWalletKindByPkArgs = {
  id: Scalars['uuid'];
};

export type SmartContractAggregateBoolExpBool_And = {
  arguments: SmartContractSelectColumnSmartContractAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SmartContractBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SmartContractAggregateBoolExpBool_Or = {
  arguments: SmartContractSelectColumnSmartContractAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SmartContractBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SmartContractAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<SmartContractSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SmartContractBoolExp>;
  predicate: IntComparisonExp;
};

export type SmartContractKindAggregateBoolExpBool_And = {
  arguments: SmartContractKindSelectColumnSmartContractKindAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SmartContractKindBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SmartContractKindAggregateBoolExpBool_Or = {
  arguments: SmartContractKindSelectColumnSmartContractKindAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SmartContractKindBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SmartContractKindAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<SmartContractKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SmartContractKindBoolExp>;
  predicate: IntComparisonExp;
};

export type SmartContractLineageAggregateBoolExpBool_And = {
  arguments: SmartContractLineageSelectColumnSmartContractLineageAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SmartContractLineageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SmartContractLineageAggregateBoolExpBool_Or = {
  arguments: SmartContractLineageSelectColumnSmartContractLineageAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SmartContractLineageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SmartContractLineageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<SmartContractLineageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SmartContractLineageBoolExp>;
  predicate: IntComparisonExp;
};

export type StateAggregateBoolExpAvg = {
  arguments: StateSelectColumnStateAggregateBoolExpAvgArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StateBoolExp>;
  predicate: Float8ComparisonExp;
};

export type StateAggregateBoolExpBool_And = {
  arguments: StateSelectColumnStateAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StateBoolExp>;
  predicate: BooleanComparisonExp;
};

export type StateAggregateBoolExpBool_Or = {
  arguments: StateSelectColumnStateAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StateBoolExp>;
  predicate: BooleanComparisonExp;
};

export type StateAggregateBoolExpCorr = {
  arguments: StateAggregateBoolExpCorrArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StateBoolExp>;
  predicate: Float8ComparisonExp;
};

export type StateAggregateBoolExpCorrArguments = {
  X: StateSelectColumnStateAggregateBoolExpCorrArgumentsColumns;
  Y: StateSelectColumnStateAggregateBoolExpCorrArgumentsColumns;
};

export type StateAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<StateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StateBoolExp>;
  predicate: IntComparisonExp;
};

export type StateAggregateBoolExpCovar_Samp = {
  arguments: StateAggregateBoolExpCovar_SampArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StateBoolExp>;
  predicate: Float8ComparisonExp;
};

export type StateAggregateBoolExpCovar_SampArguments = {
  X: StateSelectColumnStateAggregateBoolExpCovar_SampArgumentsColumns;
  Y: StateSelectColumnStateAggregateBoolExpCovar_SampArgumentsColumns;
};

export type StateAggregateBoolExpMax = {
  arguments: StateSelectColumnStateAggregateBoolExpMaxArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StateBoolExp>;
  predicate: Float8ComparisonExp;
};

export type StateAggregateBoolExpMin = {
  arguments: StateSelectColumnStateAggregateBoolExpMinArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StateBoolExp>;
  predicate: Float8ComparisonExp;
};

export type StateAggregateBoolExpStddev_Samp = {
  arguments: StateSelectColumnStateAggregateBoolExpStddev_SampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StateBoolExp>;
  predicate: Float8ComparisonExp;
};

export type StateAggregateBoolExpSum = {
  arguments: StateSelectColumnStateAggregateBoolExpSumArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StateBoolExp>;
  predicate: Float8ComparisonExp;
};

export type StateAggregateBoolExpVar_Samp = {
  arguments: StateSelectColumnStateAggregateBoolExpVar_SampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StateBoolExp>;
  predicate: Float8ComparisonExp;
};

export type StatusAggregateBoolExpBool_And = {
  arguments: StatusSelectColumnStatusAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StatusBoolExp>;
  predicate: BooleanComparisonExp;
};

export type StatusAggregateBoolExpBool_Or = {
  arguments: StatusSelectColumnStatusAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StatusBoolExp>;
  predicate: BooleanComparisonExp;
};

export type StatusAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<StatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StatusBoolExp>;
  predicate: IntComparisonExp;
};

export type SubindustryAggregateBoolExpBool_And = {
  arguments: SubindustrySelectColumnSubindustryAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SubindustryBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SubindustryAggregateBoolExpBool_Or = {
  arguments: SubindustrySelectColumnSubindustryAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SubindustryBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SubindustryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<SubindustrySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SubindustryBoolExp>;
  predicate: IntComparisonExp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "address_attributes" */
  addressAttributes: Array<AddressAttributes>;
  /** fetch aggregated fields from the table: "address_attributes" */
  addressAttributesAggregate: AddressAttributesAggregate;
  /** fetch data from the table: "address_attributes" using primary key columns */
  addressAttributesByPk?: Maybe<AddressAttributes>;
  /** fetch data from the table in a streaming manner: "address_attributes" */
  addressAttributesStream: Array<AddressAttributes>;
  /** fetch data from the table: "appraisal" */
  appraisal: Array<Appraisal>;
  /** fetch aggregated fields from the table: "appraisal" */
  appraisalAggregate: AppraisalAggregate;
  /** fetch data from the table: "appraisal" using primary key columns */
  appraisalByPk?: Maybe<Appraisal>;
  /** fetch data from the table in a streaming manner: "appraisal" */
  appraisalStream: Array<Appraisal>;
  /** fetch data from the table: "ask" */
  ask: Array<Ask>;
  /** fetch aggregated fields from the table: "ask" */
  askAggregate: AskAggregate;
  /** fetch data from the table: "ask" using primary key columns */
  askByPk?: Maybe<Ask>;
  /** fetch data from the table: "ask_put_transaction" */
  askPutTransaction: Array<AskPutTransaction>;
  /** fetch aggregated fields from the table: "ask_put_transaction" */
  askPutTransactionAggregate: AskPutTransactionAggregate;
  /** fetch data from the table: "ask_put_transaction" using primary key columns */
  askPutTransactionByPk?: Maybe<AskPutTransaction>;
  /** fetch data from the table in a streaming manner: "ask_put_transaction" */
  askPutTransactionStream: Array<AskPutTransaction>;
  /** fetch data from the table in a streaming manner: "ask" */
  askStream: Array<Ask>;
  /** fetch data from the table: "asset" */
  asset: Array<Asset>;
  /** fetch data from the table: "asset_account" */
  assetAccount: Array<AssetAccount>;
  /** fetch aggregated fields from the table: "asset_account" */
  assetAccountAggregate: AssetAccountAggregate;
  /** fetch data from the table: "asset_account" using primary key columns */
  assetAccountByPk?: Maybe<AssetAccount>;
  /** fetch data from the table in a streaming manner: "asset_account" */
  assetAccountStream: Array<AssetAccount>;
  /** fetch aggregated fields from the table: "asset" */
  assetAggregate: AssetAggregate;
  /** fetch data from the table: "asset" using primary key columns */
  assetByPk?: Maybe<Asset>;
  /** An array relationship */
  assetKind: Array<AssetKind>;
  /** An aggregate relationship */
  assetKindAggregate: AssetKindAggregate;
  /** fetch data from the table: "asset_kind" using primary key columns */
  assetKindByPk?: Maybe<AssetKind>;
  /** fetch data from the table in a streaming manner: "asset_kind" */
  assetKindStream: Array<AssetKind>;
  /** fetch data from the table in a streaming manner: "asset" */
  assetStream: Array<Asset>;
  /** fetch data from the table: "cache" */
  cache: Array<Cache>;
  /** fetch aggregated fields from the table: "cache" */
  cacheAggregate: CacheAggregate;
  /** fetch data from the table: "cache" using primary key columns */
  cacheByPk?: Maybe<Cache>;
  /** fetch data from the table in a streaming manner: "cache" */
  cacheStream: Array<Cache>;
  /** fetch data from the table: "cart" */
  cart: Array<Cart>;
  /** fetch aggregated fields from the table: "cart" */
  cartAggregate: CartAggregate;
  /** fetch data from the table: "cart" using primary key columns */
  cartByPk?: Maybe<Cart>;
  /** fetch data from the table in a streaming manner: "cart" */
  cartStream: Array<Cart>;
  /** fetch data from the table: "certification" */
  certification: Array<Certification>;
  /** fetch aggregated fields from the table: "certification" */
  certificationAggregate: CertificationAggregate;
  /** fetch data from the table: "certification" using primary key columns */
  certificationByPk?: Maybe<Certification>;
  /** fetch data from the table in a streaming manner: "certification" */
  certificationStream: Array<Certification>;
  /** fetch data from the table: "clearinghouse" */
  clearinghouse: Array<Clearinghouse>;
  /** fetch aggregated fields from the table: "clearinghouse" */
  clearinghouseAggregate: ClearinghouseAggregate;
  /** fetch data from the table: "clearinghouse" using primary key columns */
  clearinghouseByPk?: Maybe<Clearinghouse>;
  /** fetch data from the table: "clearinghouse_certification" */
  clearinghouseCertification: Array<ClearinghouseCertification>;
  /** fetch aggregated fields from the table: "clearinghouse_certification" */
  clearinghouseCertificationAggregate: ClearinghouseCertificationAggregate;
  /** fetch data from the table: "clearinghouse_certification" using primary key columns */
  clearinghouseCertificationByPk?: Maybe<ClearinghouseCertification>;
  /** fetch data from the table in a streaming manner: "clearinghouse_certification" */
  clearinghouseCertificationStream: Array<ClearinghouseCertification>;
  /** fetch data from the table: "clearinghouse_kind" */
  clearinghouseKind: Array<ClearinghouseKind>;
  /** fetch aggregated fields from the table: "clearinghouse_kind" */
  clearinghouseKindAggregate: ClearinghouseKindAggregate;
  /** fetch data from the table: "clearinghouse_kind" using primary key columns */
  clearinghouseKindByPk?: Maybe<ClearinghouseKind>;
  /** fetch data from the table in a streaming manner: "clearinghouse_kind" */
  clearinghouseKindStream: Array<ClearinghouseKind>;
  /** fetch data from the table in a streaming manner: "clearinghouse" */
  clearinghouseStream: Array<Clearinghouse>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  countryAggregate: CountryAggregate;
  /** fetch data from the table: "country" using primary key columns */
  countryByPk?: Maybe<Country>;
  /** fetch data from the table: "country_region" */
  countryRegion: Array<CountryRegion>;
  /** fetch aggregated fields from the table: "country_region" */
  countryRegionAggregate: CountryRegionAggregate;
  /** fetch data from the table: "country_region" using primary key columns */
  countryRegionByPk?: Maybe<CountryRegion>;
  /** fetch data from the table in a streaming manner: "country_region" */
  countryRegionStream: Array<CountryRegion>;
  /** fetch data from the table in a streaming manner: "country" */
  countryStream: Array<Country>;
  /** fetch data from the table: "currency" */
  currency: Array<Currency>;
  /** fetch aggregated fields from the table: "currency" */
  currencyAggregate: CurrencyAggregate;
  /** fetch data from the table: "currency" using primary key columns */
  currencyByPk?: Maybe<Currency>;
  /** fetch data from the table: "currency_kind" */
  currencyKind: Array<CurrencyKind>;
  /** fetch aggregated fields from the table: "currency_kind" */
  currencyKindAggregate: CurrencyKindAggregate;
  /** fetch data from the table: "currency_kind" using primary key columns */
  currencyKindByPk?: Maybe<CurrencyKind>;
  /** fetch data from the table in a streaming manner: "currency_kind" */
  currencyKindStream: Array<CurrencyKind>;
  /** fetch data from the table: "currency_owner" */
  currencyOwner: Array<CurrencyOwner>;
  /** fetch aggregated fields from the table: "currency_owner" */
  currencyOwnerAggregate: CurrencyOwnerAggregate;
  /** fetch data from the table: "currency_owner" using primary key columns */
  currencyOwnerByPk?: Maybe<CurrencyOwner>;
  /** fetch data from the table in a streaming manner: "currency_owner" */
  currencyOwnerStream: Array<CurrencyOwner>;
  /** fetch data from the table in a streaming manner: "currency" */
  currencyStream: Array<Currency>;
  /** fetch data from the table: "datasource" */
  datasource: Array<Datasource>;
  /** fetch aggregated fields from the table: "datasource" */
  datasourceAggregate: DatasourceAggregate;
  /** fetch data from the table: "datasource" using primary key columns */
  datasourceByPk?: Maybe<Datasource>;
  /** fetch data from the table in a streaming manner: "datasource" */
  datasourceStream: Array<Datasource>;
  /** fetch data from the table: "document_user" */
  documentUser: Array<DocumentUser>;
  /** fetch aggregated fields from the table: "document_user" */
  documentUserAggregate: DocumentUserAggregate;
  /** fetch data from the table: "document_user" using primary key columns */
  documentUserByPk?: Maybe<DocumentUser>;
  /** fetch data from the table in a streaming manner: "document_user" */
  documentUserStream: Array<DocumentUser>;
  /** fetch data from the table: "exchange" */
  exchange: Array<Exchange>;
  /** fetch aggregated fields from the table: "exchange" */
  exchangeAggregate: ExchangeAggregate;
  /** fetch data from the table: "exchange" using primary key columns */
  exchangeByPk?: Maybe<Exchange>;
  /** fetch data from the table: "exchange_kind" */
  exchangeKind: Array<ExchangeKind>;
  /** fetch aggregated fields from the table: "exchange_kind" */
  exchangeKindAggregate: ExchangeKindAggregate;
  /** fetch data from the table: "exchange_kind" using primary key columns */
  exchangeKindByPk?: Maybe<ExchangeKind>;
  /** fetch data from the table in a streaming manner: "exchange_kind" */
  exchangeKindStream: Array<ExchangeKind>;
  /** fetch data from the table in a streaming manner: "exchange" */
  exchangeStream: Array<Exchange>;
  /** fetch data from the table: "favorite" */
  favorite: Array<Favorite>;
  /** fetch aggregated fields from the table: "favorite" */
  favoriteAggregate: FavoriteAggregate;
  /** fetch data from the table: "favorite" using primary key columns */
  favoriteByPk?: Maybe<Favorite>;
  /** fetch data from the table: "favorite_comment" */
  favoriteComment: Array<FavoriteComment>;
  /** fetch aggregated fields from the table: "favorite_comment" */
  favoriteCommentAggregate: FavoriteCommentAggregate;
  /** fetch data from the table: "favorite_comment" using primary key columns */
  favoriteCommentByPk?: Maybe<FavoriteComment>;
  /** fetch data from the table in a streaming manner: "favorite_comment" */
  favoriteCommentStream: Array<FavoriteComment>;
  /** fetch data from the table in a streaming manner: "favorite" */
  favoriteStream: Array<Favorite>;
  /** fetch data from the table: "gics_sector" */
  gicsSector: Array<GicsSector>;
  /** fetch aggregated fields from the table: "gics_sector" */
  gicsSectorAggregate: GicsSectorAggregate;
  /** fetch data from the table: "gics_sector" using primary key columns */
  gicsSectorByPk?: Maybe<GicsSector>;
  /** fetch data from the table in a streaming manner: "gics_sector" */
  gicsSectorStream: Array<GicsSector>;
  /** fetch data from the table: "group" */
  group: Array<Group>;
  /** fetch aggregated fields from the table: "group" */
  groupAggregate: GroupAggregate;
  /** fetch data from the table: "group" using primary key columns */
  groupByPk?: Maybe<Group>;
  /** fetch data from the table in a streaming manner: "group" */
  groupStream: Array<Group>;
  /** fetch data from the table: "industry" */
  industry: Array<Industry>;
  /** fetch aggregated fields from the table: "industry" */
  industryAggregate: IndustryAggregate;
  /** fetch data from the table: "industry" using primary key columns */
  industryByPk?: Maybe<Industry>;
  /** fetch data from the table in a streaming manner: "industry" */
  industryStream: Array<Industry>;
  /** fetch data from the table: "interaction" */
  interaction: Array<Interaction>;
  /** fetch aggregated fields from the table: "interaction" */
  interactionAggregate: InteractionAggregate;
  /** fetch data from the table: "interaction" using primary key columns */
  interactionByPk?: Maybe<Interaction>;
  /** fetch data from the table in a streaming manner: "interaction" */
  interactionStream: Array<Interaction>;
  /** fetch data from the table: "kyc_kind" */
  kycKind: Array<KycKind>;
  /** fetch aggregated fields from the table: "kyc_kind" */
  kycKindAggregate: KycKindAggregate;
  /** fetch data from the table: "kyc_kind" using primary key columns */
  kycKindByPk?: Maybe<KycKind>;
  /** fetch data from the table in a streaming manner: "kyc_kind" */
  kycKindStream: Array<KycKind>;
  /** fetch data from the table: "kyc_status" */
  kycStatus: Array<KycStatus>;
  /** fetch aggregated fields from the table: "kyc_status" */
  kycStatusAggregate: KycStatusAggregate;
  /** fetch data from the table: "kyc_status" using primary key columns */
  kycStatusByPk?: Maybe<KycStatus>;
  /** fetch data from the table in a streaming manner: "kyc_status" */
  kycStatusStream: Array<KycStatus>;
  /** fetch data from the table: "market" */
  market: Array<Market>;
  /** fetch aggregated fields from the table: "market" */
  marketAggregate: MarketAggregate;
  /** fetch data from the table: "market" using primary key columns */
  marketByPk?: Maybe<Market>;
  /** fetch data from the table in a streaming manner: "market" */
  marketStream: Array<Market>;
  /** fetch data from the table: "material" */
  material: Array<Material>;
  /** fetch aggregated fields from the table: "material" */
  materialAggregate: MaterialAggregate;
  /** fetch data from the table: "material" using primary key columns */
  materialByPk?: Maybe<Material>;
  /** fetch data from the table in a streaming manner: "material" */
  materialStream: Array<Material>;
  /** fetch data from the table: "merchant" */
  merchant: Array<Merchant>;
  /** fetch aggregated fields from the table: "merchant" */
  merchantAggregate: MerchantAggregate;
  /** fetch data from the table: "merchant" using primary key columns */
  merchantByPk?: Maybe<Merchant>;
  /** fetch data from the table: "merchant_currency" */
  merchantCurrency: Array<MerchantCurrency>;
  /** fetch aggregated fields from the table: "merchant_currency" */
  merchantCurrencyAggregate: MerchantCurrencyAggregate;
  /** fetch data from the table: "merchant_currency" using primary key columns */
  merchantCurrencyByPk?: Maybe<MerchantCurrency>;
  /** fetch data from the table in a streaming manner: "merchant_currency" */
  merchantCurrencyStream: Array<MerchantCurrency>;
  /** fetch data from the table: "merchant_organization" */
  merchantOrganization: Array<MerchantOrganization>;
  /** fetch aggregated fields from the table: "merchant_organization" */
  merchantOrganizationAggregate: MerchantOrganizationAggregate;
  /** fetch data from the table: "merchant_organization" using primary key columns */
  merchantOrganizationByPk?: Maybe<MerchantOrganization>;
  /** fetch data from the table in a streaming manner: "merchant_organization" */
  merchantOrganizationStream: Array<MerchantOrganization>;
  /** fetch data from the table in a streaming manner: "merchant" */
  merchantStream: Array<Merchant>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "order" */
  orderAggregate: OrderAggregate;
  /** fetch data from the table: "order" using primary key columns */
  orderByPk?: Maybe<Order>;
  /** fetch data from the table in a streaming manner: "order" */
  orderStream: Array<Order>;
  /** fetch data from the table: "organization" */
  organization: Array<Organization>;
  /** fetch aggregated fields from the table: "organization" */
  organizationAggregate: OrganizationAggregate;
  /** fetch data from the table: "organization" using primary key columns */
  organizationByPk?: Maybe<Organization>;
  /** fetch data from the table: "organization_kind" */
  organizationKind: Array<OrganizationKind>;
  /** fetch aggregated fields from the table: "organization_kind" */
  organizationKindAggregate: OrganizationKindAggregate;
  /** fetch data from the table: "organization_kind" using primary key columns */
  organizationKindByPk?: Maybe<OrganizationKind>;
  /** fetch data from the table in a streaming manner: "organization_kind" */
  organizationKindStream: Array<OrganizationKind>;
  /** fetch data from the table: "organization_person" */
  organizationPerson: Array<OrganizationPerson>;
  /** fetch aggregated fields from the table: "organization_person" */
  organizationPersonAggregate: OrganizationPersonAggregate;
  /** fetch data from the table: "organization_person" using primary key columns */
  organizationPersonByPk?: Maybe<OrganizationPerson>;
  /** fetch data from the table in a streaming manner: "organization_person" */
  organizationPersonStream: Array<OrganizationPerson>;
  /** fetch data from the table: "organization_status" */
  organizationStatus: Array<OrganizationStatus>;
  /** fetch aggregated fields from the table: "organization_status" */
  organizationStatusAggregate: OrganizationStatusAggregate;
  /** fetch data from the table: "organization_status" using primary key columns */
  organizationStatusByPk?: Maybe<OrganizationStatus>;
  /** fetch data from the table in a streaming manner: "organization_status" */
  organizationStatusStream: Array<OrganizationStatus>;
  /** fetch data from the table in a streaming manner: "organization" */
  organizationStream: Array<Organization>;
  /** fetch data from the table: "password_reset" */
  passwordReset: Array<PasswordReset>;
  /** fetch aggregated fields from the table: "password_reset" */
  passwordResetAggregate: PasswordResetAggregate;
  /** fetch data from the table: "password_reset" using primary key columns */
  passwordResetByPk?: Maybe<PasswordReset>;
  /** fetch data from the table in a streaming manner: "password_reset" */
  passwordResetStream: Array<PasswordReset>;
  /** fetch data from the table: "payment" */
  payment: Array<Payment>;
  /** fetch aggregated fields from the table: "payment" */
  paymentAggregate: PaymentAggregate;
  /** fetch data from the table: "payment" using primary key columns */
  paymentByPk?: Maybe<Payment>;
  /** fetch data from the table in a streaming manner: "payment" */
  paymentStream: Array<Payment>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  productAggregate: ProductAggregate;
  /** fetch data from the table: "product" using primary key columns */
  productByPk?: Maybe<Product>;
  /** fetch data from the table: "product_distribution" */
  productDistribution: Array<ProductDistribution>;
  /** fetch aggregated fields from the table: "product_distribution" */
  productDistributionAggregate: ProductDistributionAggregate;
  /** fetch data from the table: "product_distribution" using primary key columns */
  productDistributionByPk?: Maybe<ProductDistribution>;
  /** fetch data from the table in a streaming manner: "product_distribution" */
  productDistributionStream: Array<ProductDistribution>;
  /** fetch data from the table: "product_kind" */
  productKind: Array<ProductKind>;
  /** fetch aggregated fields from the table: "product_kind" */
  productKindAggregate: ProductKindAggregate;
  /** fetch data from the table: "product_kind" using primary key columns */
  productKindByPk?: Maybe<ProductKind>;
  /** fetch data from the table in a streaming manner: "product_kind" */
  productKindStream: Array<ProductKind>;
  /** fetch data from the table: "product_status" */
  productStatus: Array<ProductStatus>;
  /** fetch aggregated fields from the table: "product_status" */
  productStatusAggregate: ProductStatusAggregate;
  /** fetch data from the table: "product_status" using primary key columns */
  productStatusByPk?: Maybe<ProductStatus>;
  /** fetch data from the table in a streaming manner: "product_status" */
  productStatusStream: Array<ProductStatus>;
  /** fetch data from the table in a streaming manner: "product" */
  productStream: Array<Product>;
  /** fetch data from the table: "put" */
  put: Array<Put>;
  /** fetch aggregated fields from the table: "put" */
  putAggregate: PutAggregate;
  /** fetch data from the table: "put" using primary key columns */
  putByPk?: Maybe<Put>;
  /** fetch data from the table in a streaming manner: "put" */
  putStream: Array<Put>;
  /** fetch data from the table: "setting" */
  setting: Array<Setting>;
  /** fetch aggregated fields from the table: "setting" */
  settingAggregate: SettingAggregate;
  /** fetch data from the table: "setting" using primary key columns */
  settingByPk?: Maybe<Setting>;
  /** fetch data from the table in a streaming manner: "setting" */
  settingStream: Array<Setting>;
  /** fetch data from the table: "shipping" */
  shipping: Array<Shipping>;
  /** fetch aggregated fields from the table: "shipping" */
  shippingAggregate: ShippingAggregate;
  /** fetch data from the table: "shipping" using primary key columns */
  shippingByPk?: Maybe<Shipping>;
  /** fetch data from the table in a streaming manner: "shipping" */
  shippingStream: Array<Shipping>;
  /** fetch data from the table: "shop" */
  shop: Array<Shop>;
  /** fetch aggregated fields from the table: "shop" */
  shopAggregate: ShopAggregate;
  /** fetch data from the table: "shop" using primary key columns */
  shopByPk?: Maybe<Shop>;
  /** fetch data from the table in a streaming manner: "shop" */
  shopStream: Array<Shop>;
  /** fetch data from the table: "sic_code" */
  sicCode: Array<SicCode>;
  /** fetch aggregated fields from the table: "sic_code" */
  sicCodeAggregate: SicCodeAggregate;
  /** fetch data from the table: "sic_code" using primary key columns */
  sicCodeByPk?: Maybe<SicCode>;
  /** fetch data from the table in a streaming manner: "sic_code" */
  sicCodeStream: Array<SicCode>;
  /** fetch data from the table: "sigma_identity" */
  sigmaIdentity: Array<SigmaIdentity>;
  /** fetch aggregated fields from the table: "sigma_identity" */
  sigmaIdentityAggregate: SigmaIdentityAggregate;
  /** fetch data from the table: "sigma_identity" using primary key columns */
  sigmaIdentityByPk?: Maybe<SigmaIdentity>;
  /** fetch data from the table in a streaming manner: "sigma_identity" */
  sigmaIdentityStream: Array<SigmaIdentity>;
  /** fetch data from the table: "sigma_prime" */
  sigmaPrime: Array<SigmaPrime>;
  /** fetch data from the table: "sigma_prime_address" */
  sigmaPrimeAddress: Array<SigmaPrimeAddress>;
  /** fetch aggregated fields from the table: "sigma_prime_address" */
  sigmaPrimeAddressAggregate: SigmaPrimeAddressAggregate;
  /** fetch data from the table: "sigma_prime_address" using primary key columns */
  sigmaPrimeAddressByPk?: Maybe<SigmaPrimeAddress>;
  /** fetch data from the table in a streaming manner: "sigma_prime_address" */
  sigmaPrimeAddressStream: Array<SigmaPrimeAddress>;
  /** fetch aggregated fields from the table: "sigma_prime" */
  sigmaPrimeAggregate: SigmaPrimeAggregate;
  /** fetch data from the table: "sigma_prime" using primary key columns */
  sigmaPrimeByPk?: Maybe<SigmaPrime>;
  /** fetch data from the table: "sigma_prime_kind" */
  sigmaPrimeKind: Array<SigmaPrimeKind>;
  /** fetch aggregated fields from the table: "sigma_prime_kind" */
  sigmaPrimeKindAggregate: SigmaPrimeKindAggregate;
  /** fetch data from the table: "sigma_prime_kind" using primary key columns */
  sigmaPrimeKindByPk?: Maybe<SigmaPrimeKind>;
  /** fetch data from the table in a streaming manner: "sigma_prime_kind" */
  sigmaPrimeKindStream: Array<SigmaPrimeKind>;
  /** fetch data from the table in a streaming manner: "sigma_prime" */
  sigmaPrimeStream: Array<SigmaPrime>;
  /** fetch data from the table: "sigma_social" */
  sigmaSocial: Array<SigmaSocial>;
  /** fetch aggregated fields from the table: "sigma_social" */
  sigmaSocialAggregate: SigmaSocialAggregate;
  /** fetch data from the table: "sigma_social" using primary key columns */
  sigmaSocialByPk?: Maybe<SigmaSocial>;
  /** fetch data from the table in a streaming manner: "sigma_social" */
  sigmaSocialStream: Array<SigmaSocial>;
  /** fetch data from the table: "smart_contract" */
  smartContract: Array<SmartContract>;
  /** fetch aggregated fields from the table: "smart_contract" */
  smartContractAggregate: SmartContractAggregate;
  /** fetch data from the table: "smart_contract" using primary key columns */
  smartContractByPk?: Maybe<SmartContract>;
  /** fetch data from the table: "smart_contract_commission" */
  smartContractCommission: Array<SmartContractCommission>;
  /** fetch aggregated fields from the table: "smart_contract_commission" */
  smartContractCommissionAggregate: SmartContractCommissionAggregate;
  /** fetch data from the table: "smart_contract_commission" using primary key columns */
  smartContractCommissionByPk?: Maybe<SmartContractCommission>;
  /** fetch data from the table in a streaming manner: "smart_contract_commission" */
  smartContractCommissionStream: Array<SmartContractCommission>;
  /** fetch data from the table: "smart_contract_document" */
  smartContractDocument: Array<SmartContractDocument>;
  /** fetch aggregated fields from the table: "smart_contract_document" */
  smartContractDocumentAggregate: SmartContractDocumentAggregate;
  /** fetch data from the table: "smart_contract_document" using primary key columns */
  smartContractDocumentByPk?: Maybe<SmartContractDocument>;
  /** fetch data from the table in a streaming manner: "smart_contract_document" */
  smartContractDocumentStream: Array<SmartContractDocument>;
  /** fetch data from the table: "smart_contract_kind" */
  smartContractKind: Array<SmartContractKind>;
  /** fetch aggregated fields from the table: "smart_contract_kind" */
  smartContractKindAggregate: SmartContractKindAggregate;
  /** fetch data from the table: "smart_contract_kind" using primary key columns */
  smartContractKindByPk?: Maybe<SmartContractKind>;
  /** fetch data from the table in a streaming manner: "smart_contract_kind" */
  smartContractKindStream: Array<SmartContractKind>;
  /** fetch data from the table: "smart_contract_lineage" */
  smartContractLineage: Array<SmartContractLineage>;
  /** fetch aggregated fields from the table: "smart_contract_lineage" */
  smartContractLineageAggregate: SmartContractLineageAggregate;
  /** fetch data from the table: "smart_contract_lineage" using primary key columns */
  smartContractLineageByPk?: Maybe<SmartContractLineage>;
  /** fetch data from the table in a streaming manner: "smart_contract_lineage" */
  smartContractLineageStream: Array<SmartContractLineage>;
  /** fetch data from the table in a streaming manner: "smart_contract" */
  smartContractStream: Array<SmartContract>;
  /** fetch data from the table: "state" */
  state: Array<State>;
  /** fetch aggregated fields from the table: "state" */
  stateAggregate: StateAggregate;
  /** fetch data from the table in a streaming manner: "state" */
  stateStream: Array<State>;
  /** fetch data from the table: "status" */
  status: Array<Status>;
  /** fetch aggregated fields from the table: "status" */
  statusAggregate: StatusAggregate;
  /** fetch data from the table: "status" using primary key columns */
  statusByPk?: Maybe<Status>;
  /** fetch data from the table: "status_kind" */
  statusKind: Array<StatusKind>;
  /** fetch aggregated fields from the table: "status_kind" */
  statusKindAggregate: StatusKindAggregate;
  /** fetch data from the table: "status_kind" using primary key columns */
  statusKindByPk?: Maybe<StatusKind>;
  /** fetch data from the table in a streaming manner: "status_kind" */
  statusKindStream: Array<StatusKind>;
  /** fetch data from the table in a streaming manner: "status" */
  statusStream: Array<Status>;
  /** fetch data from the table: "subindustry" */
  subindustry: Array<Subindustry>;
  /** fetch aggregated fields from the table: "subindustry" */
  subindustryAggregate: SubindustryAggregate;
  /** fetch data from the table: "subindustry" using primary key columns */
  subindustryByPk?: Maybe<Subindustry>;
  /** fetch data from the table in a streaming manner: "subindustry" */
  subindustryStream: Array<Subindustry>;
  /** fetch data from the table: "supply_chain_waypoint" */
  supplyChainWaypoint: Array<SupplyChainWaypoint>;
  /** fetch aggregated fields from the table: "supply_chain_waypoint" */
  supplyChainWaypointAggregate: SupplyChainWaypointAggregate;
  /** fetch data from the table: "supply_chain_waypoint" using primary key columns */
  supplyChainWaypointByPk?: Maybe<SupplyChainWaypoint>;
  /** fetch data from the table in a streaming manner: "supply_chain_waypoint" */
  supplyChainWaypointStream: Array<SupplyChainWaypoint>;
  /** fetch data from the table: "trade" */
  trade: Array<Trade>;
  /** fetch aggregated fields from the table: "trade" */
  tradeAggregate: TradeAggregate;
  /** fetch data from the table: "trade" using primary key columns */
  tradeByPk?: Maybe<Trade>;
  /** fetch data from the table: "trade_kind" */
  tradeKind: Array<TradeKind>;
  /** fetch aggregated fields from the table: "trade_kind" */
  tradeKindAggregate: TradeKindAggregate;
  /** fetch data from the table: "trade_kind" using primary key columns */
  tradeKindByPk?: Maybe<TradeKind>;
  /** fetch data from the table in a streaming manner: "trade_kind" */
  tradeKindStream: Array<TradeKind>;
  /** fetch data from the table in a streaming manner: "trade" */
  tradeStream: Array<Trade>;
  /** fetch data from the table: "transaction" */
  transaction: Array<Transaction>;
  /** fetch aggregated fields from the table: "transaction" */
  transactionAggregate: TransactionAggregate;
  /** fetch data from the table: "transaction" using primary key columns */
  transactionByPk?: Maybe<Transaction>;
  /** fetch data from the table: "transaction_kind" */
  transactionKind: Array<TransactionKind>;
  /** fetch aggregated fields from the table: "transaction_kind" */
  transactionKindAggregate: TransactionKindAggregate;
  /** fetch data from the table: "transaction_kind" using primary key columns */
  transactionKindByPk?: Maybe<TransactionKind>;
  /** fetch data from the table in a streaming manner: "transaction_kind" */
  transactionKindStream: Array<TransactionKind>;
  /** fetch data from the table in a streaming manner: "transaction" */
  transactionStream: Array<Transaction>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  userAggregate: UserAggregate;
  /** fetch data from the table: "user_auth" */
  userAuth: Array<UserAuth>;
  /** fetch aggregated fields from the table: "user_auth" */
  userAuthAggregate: UserAuthAggregate;
  /** fetch data from the table: "user_auth" using primary key columns */
  userAuthByPk?: Maybe<UserAuth>;
  /** fetch data from the table in a streaming manner: "user_auth" */
  userAuthStream: Array<UserAuth>;
  /** fetch data from the table: "user" using primary key columns */
  userByPk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  userStream: Array<User>;
  /** fetch data from the table: "wallet" */
  wallet: Array<Wallet>;
  /** fetch aggregated fields from the table: "wallet" */
  walletAggregate: WalletAggregate;
  /** fetch data from the table: "wallet" using primary key columns */
  walletByPk?: Maybe<Wallet>;
  /** fetch data from the table: "wallet_inventory" */
  walletInventory: Array<WalletInventory>;
  /** fetch aggregated fields from the table: "wallet_inventory" */
  walletInventoryAggregate: WalletInventoryAggregate;
  /** fetch data from the table: "wallet_inventory" using primary key columns */
  walletInventoryByPk?: Maybe<WalletInventory>;
  /** fetch data from the table in a streaming manner: "wallet_inventory" */
  walletInventoryStream: Array<WalletInventory>;
  /** fetch data from the table: "wallet_kind" */
  walletKind: Array<WalletKind>;
  /** fetch aggregated fields from the table: "wallet_kind" */
  walletKindAggregate: WalletKindAggregate;
  /** fetch data from the table: "wallet_kind" using primary key columns */
  walletKindByPk?: Maybe<WalletKind>;
  /** fetch data from the table in a streaming manner: "wallet_kind" */
  walletKindStream: Array<WalletKind>;
  /** fetch data from the table in a streaming manner: "wallet" */
  walletStream: Array<Wallet>;
};

export type Subscription_RootAddressAttributesArgs = {
  distinctOn?: InputMaybe<Array<AddressAttributesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressAttributesOrderBy>>;
  where?: InputMaybe<AddressAttributesBoolExp>;
};

export type Subscription_RootAddressAttributesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AddressAttributesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressAttributesOrderBy>>;
  where?: InputMaybe<AddressAttributesBoolExp>;
};

export type Subscription_RootAddressAttributesByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAddressAttributesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AddressAttributesStreamCursorInput>>;
  where?: InputMaybe<AddressAttributesBoolExp>;
};

export type Subscription_RootAppraisalArgs = {
  distinctOn?: InputMaybe<Array<AppraisalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppraisalOrderBy>>;
  where?: InputMaybe<AppraisalBoolExp>;
};

export type Subscription_RootAppraisalAggregateArgs = {
  distinctOn?: InputMaybe<Array<AppraisalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppraisalOrderBy>>;
  where?: InputMaybe<AppraisalBoolExp>;
};

export type Subscription_RootAppraisalByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAppraisalStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AppraisalStreamCursorInput>>;
  where?: InputMaybe<AppraisalBoolExp>;
};

export type Subscription_RootAskArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

export type Subscription_RootAskAggregateArgs = {
  distinctOn?: InputMaybe<Array<AskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskOrderBy>>;
  where?: InputMaybe<AskBoolExp>;
};

export type Subscription_RootAskByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAskPutTransactionArgs = {
  distinctOn?: InputMaybe<Array<AskPutTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskPutTransactionOrderBy>>;
  where?: InputMaybe<AskPutTransactionBoolExp>;
};

export type Subscription_RootAskPutTransactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AskPutTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AskPutTransactionOrderBy>>;
  where?: InputMaybe<AskPutTransactionBoolExp>;
};

export type Subscription_RootAskPutTransactionByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAskPutTransactionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AskPutTransactionStreamCursorInput>>;
  where?: InputMaybe<AskPutTransactionBoolExp>;
};

export type Subscription_RootAskStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AskStreamCursorInput>>;
  where?: InputMaybe<AskBoolExp>;
};

export type Subscription_RootAssetArgs = {
  distinctOn?: InputMaybe<Array<AssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetOrderBy>>;
  where?: InputMaybe<AssetBoolExp>;
};

export type Subscription_RootAssetAccountArgs = {
  distinctOn?: InputMaybe<Array<AssetAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetAccountOrderBy>>;
  where?: InputMaybe<AssetAccountBoolExp>;
};

export type Subscription_RootAssetAccountAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssetAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetAccountOrderBy>>;
  where?: InputMaybe<AssetAccountBoolExp>;
};

export type Subscription_RootAssetAccountByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAssetAccountStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AssetAccountStreamCursorInput>>;
  where?: InputMaybe<AssetAccountBoolExp>;
};

export type Subscription_RootAssetAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetOrderBy>>;
  where?: InputMaybe<AssetBoolExp>;
};

export type Subscription_RootAssetByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAssetKindArgs = {
  distinctOn?: InputMaybe<Array<AssetKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetKindOrderBy>>;
  where?: InputMaybe<AssetKindBoolExp>;
};

export type Subscription_RootAssetKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssetKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetKindOrderBy>>;
  where?: InputMaybe<AssetKindBoolExp>;
};

export type Subscription_RootAssetKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAssetKindStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AssetKindStreamCursorInput>>;
  where?: InputMaybe<AssetKindBoolExp>;
};

export type Subscription_RootAssetStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AssetStreamCursorInput>>;
  where?: InputMaybe<AssetBoolExp>;
};

export type Subscription_RootCacheArgs = {
  distinctOn?: InputMaybe<Array<CacheSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CacheOrderBy>>;
  where?: InputMaybe<CacheBoolExp>;
};

export type Subscription_RootCacheAggregateArgs = {
  distinctOn?: InputMaybe<Array<CacheSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CacheOrderBy>>;
  where?: InputMaybe<CacheBoolExp>;
};

export type Subscription_RootCacheByPkArgs = {
  key: Scalars['String'];
};

export type Subscription_RootCacheStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CacheStreamCursorInput>>;
  where?: InputMaybe<CacheBoolExp>;
};

export type Subscription_RootCartArgs = {
  distinctOn?: InputMaybe<Array<CartSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CartOrderBy>>;
  where?: InputMaybe<CartBoolExp>;
};

export type Subscription_RootCartAggregateArgs = {
  distinctOn?: InputMaybe<Array<CartSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CartOrderBy>>;
  where?: InputMaybe<CartBoolExp>;
};

export type Subscription_RootCartByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCartStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CartStreamCursorInput>>;
  where?: InputMaybe<CartBoolExp>;
};

export type Subscription_RootCertificationArgs = {
  distinctOn?: InputMaybe<Array<CertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CertificationOrderBy>>;
  where?: InputMaybe<CertificationBoolExp>;
};

export type Subscription_RootCertificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<CertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CertificationOrderBy>>;
  where?: InputMaybe<CertificationBoolExp>;
};

export type Subscription_RootCertificationByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCertificationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CertificationStreamCursorInput>>;
  where?: InputMaybe<CertificationBoolExp>;
};

export type Subscription_RootClearinghouseArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseOrderBy>>;
  where?: InputMaybe<ClearinghouseBoolExp>;
};

export type Subscription_RootClearinghouseAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseOrderBy>>;
  where?: InputMaybe<ClearinghouseBoolExp>;
};

export type Subscription_RootClearinghouseByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClearinghouseCertificationArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseCertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseCertificationOrderBy>>;
  where?: InputMaybe<ClearinghouseCertificationBoolExp>;
};

export type Subscription_RootClearinghouseCertificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseCertificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseCertificationOrderBy>>;
  where?: InputMaybe<ClearinghouseCertificationBoolExp>;
};

export type Subscription_RootClearinghouseCertificationByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClearinghouseCertificationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClearinghouseCertificationStreamCursorInput>>;
  where?: InputMaybe<ClearinghouseCertificationBoolExp>;
};

export type Subscription_RootClearinghouseKindArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseKindOrderBy>>;
  where?: InputMaybe<ClearinghouseKindBoolExp>;
};

export type Subscription_RootClearinghouseKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClearinghouseKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClearinghouseKindOrderBy>>;
  where?: InputMaybe<ClearinghouseKindBoolExp>;
};

export type Subscription_RootClearinghouseKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClearinghouseKindStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClearinghouseKindStreamCursorInput>>;
  where?: InputMaybe<ClearinghouseKindBoolExp>;
};

export type Subscription_RootClearinghouseStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClearinghouseStreamCursorInput>>;
  where?: InputMaybe<ClearinghouseBoolExp>;
};

export type Subscription_RootCountryArgs = {
  distinctOn?: InputMaybe<Array<CountrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountryOrderBy>>;
  where?: InputMaybe<CountryBoolExp>;
};

export type Subscription_RootCountryAggregateArgs = {
  distinctOn?: InputMaybe<Array<CountrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountryOrderBy>>;
  where?: InputMaybe<CountryBoolExp>;
};

export type Subscription_RootCountryByPkArgs = {
  code: Scalars['String'];
};

export type Subscription_RootCountryRegionArgs = {
  distinctOn?: InputMaybe<Array<CountryRegionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountryRegionOrderBy>>;
  where?: InputMaybe<CountryRegionBoolExp>;
};

export type Subscription_RootCountryRegionAggregateArgs = {
  distinctOn?: InputMaybe<Array<CountryRegionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountryRegionOrderBy>>;
  where?: InputMaybe<CountryRegionBoolExp>;
};

export type Subscription_RootCountryRegionByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCountryRegionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CountryRegionStreamCursorInput>>;
  where?: InputMaybe<CountryRegionBoolExp>;
};

export type Subscription_RootCountryStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CountryStreamCursorInput>>;
  where?: InputMaybe<CountryBoolExp>;
};

export type Subscription_RootCurrencyArgs = {
  distinctOn?: InputMaybe<Array<CurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOrderBy>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

export type Subscription_RootCurrencyAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOrderBy>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

export type Subscription_RootCurrencyByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCurrencyKindArgs = {
  distinctOn?: InputMaybe<Array<CurrencyKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyKindOrderBy>>;
  where?: InputMaybe<CurrencyKindBoolExp>;
};

export type Subscription_RootCurrencyKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencyKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyKindOrderBy>>;
  where?: InputMaybe<CurrencyKindBoolExp>;
};

export type Subscription_RootCurrencyKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCurrencyKindStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CurrencyKindStreamCursorInput>>;
  where?: InputMaybe<CurrencyKindBoolExp>;
};

export type Subscription_RootCurrencyOwnerArgs = {
  distinctOn?: InputMaybe<Array<CurrencyOwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOwnerOrderBy>>;
  where?: InputMaybe<CurrencyOwnerBoolExp>;
};

export type Subscription_RootCurrencyOwnerAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrencyOwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyOwnerOrderBy>>;
  where?: InputMaybe<CurrencyOwnerBoolExp>;
};

export type Subscription_RootCurrencyOwnerByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCurrencyOwnerStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CurrencyOwnerStreamCursorInput>>;
  where?: InputMaybe<CurrencyOwnerBoolExp>;
};

export type Subscription_RootCurrencyStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CurrencyStreamCursorInput>>;
  where?: InputMaybe<CurrencyBoolExp>;
};

export type Subscription_RootDatasourceArgs = {
  distinctOn?: InputMaybe<Array<DatasourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DatasourceOrderBy>>;
  where?: InputMaybe<DatasourceBoolExp>;
};

export type Subscription_RootDatasourceAggregateArgs = {
  distinctOn?: InputMaybe<Array<DatasourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DatasourceOrderBy>>;
  where?: InputMaybe<DatasourceBoolExp>;
};

export type Subscription_RootDatasourceByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDatasourceStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DatasourceStreamCursorInput>>;
  where?: InputMaybe<DatasourceBoolExp>;
};

export type Subscription_RootDocumentUserArgs = {
  distinctOn?: InputMaybe<Array<DocumentUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentUserOrderBy>>;
  where?: InputMaybe<DocumentUserBoolExp>;
};

export type Subscription_RootDocumentUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<DocumentUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentUserOrderBy>>;
  where?: InputMaybe<DocumentUserBoolExp>;
};

export type Subscription_RootDocumentUserByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDocumentUserStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DocumentUserStreamCursorInput>>;
  where?: InputMaybe<DocumentUserBoolExp>;
};

export type Subscription_RootExchangeArgs = {
  distinctOn?: InputMaybe<Array<ExchangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeOrderBy>>;
  where?: InputMaybe<ExchangeBoolExp>;
};

export type Subscription_RootExchangeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExchangeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeOrderBy>>;
  where?: InputMaybe<ExchangeBoolExp>;
};

export type Subscription_RootExchangeByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootExchangeKindArgs = {
  distinctOn?: InputMaybe<Array<ExchangeKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeKindOrderBy>>;
  where?: InputMaybe<ExchangeKindBoolExp>;
};

export type Subscription_RootExchangeKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExchangeKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeKindOrderBy>>;
  where?: InputMaybe<ExchangeKindBoolExp>;
};

export type Subscription_RootExchangeKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootExchangeKindStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ExchangeKindStreamCursorInput>>;
  where?: InputMaybe<ExchangeKindBoolExp>;
};

export type Subscription_RootExchangeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ExchangeStreamCursorInput>>;
  where?: InputMaybe<ExchangeBoolExp>;
};

export type Subscription_RootFavoriteArgs = {
  distinctOn?: InputMaybe<Array<FavoriteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteOrderBy>>;
  where?: InputMaybe<FavoriteBoolExp>;
};

export type Subscription_RootFavoriteAggregateArgs = {
  distinctOn?: InputMaybe<Array<FavoriteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteOrderBy>>;
  where?: InputMaybe<FavoriteBoolExp>;
};

export type Subscription_RootFavoriteByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootFavoriteCommentArgs = {
  distinctOn?: InputMaybe<Array<FavoriteCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteCommentOrderBy>>;
  where?: InputMaybe<FavoriteCommentBoolExp>;
};

export type Subscription_RootFavoriteCommentAggregateArgs = {
  distinctOn?: InputMaybe<Array<FavoriteCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteCommentOrderBy>>;
  where?: InputMaybe<FavoriteCommentBoolExp>;
};

export type Subscription_RootFavoriteCommentByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootFavoriteCommentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<FavoriteCommentStreamCursorInput>>;
  where?: InputMaybe<FavoriteCommentBoolExp>;
};

export type Subscription_RootFavoriteStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<FavoriteStreamCursorInput>>;
  where?: InputMaybe<FavoriteBoolExp>;
};

export type Subscription_RootGicsSectorArgs = {
  distinctOn?: InputMaybe<Array<GicsSectorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GicsSectorOrderBy>>;
  where?: InputMaybe<GicsSectorBoolExp>;
};

export type Subscription_RootGicsSectorAggregateArgs = {
  distinctOn?: InputMaybe<Array<GicsSectorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GicsSectorOrderBy>>;
  where?: InputMaybe<GicsSectorBoolExp>;
};

export type Subscription_RootGicsSectorByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGicsSectorStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<GicsSectorStreamCursorInput>>;
  where?: InputMaybe<GicsSectorBoolExp>;
};

export type Subscription_RootGroupArgs = {
  distinctOn?: InputMaybe<Array<GroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GroupOrderBy>>;
  where?: InputMaybe<GroupBoolExp>;
};

export type Subscription_RootGroupAggregateArgs = {
  distinctOn?: InputMaybe<Array<GroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GroupOrderBy>>;
  where?: InputMaybe<GroupBoolExp>;
};

export type Subscription_RootGroupByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGroupStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<GroupStreamCursorInput>>;
  where?: InputMaybe<GroupBoolExp>;
};

export type Subscription_RootIndustryArgs = {
  distinctOn?: InputMaybe<Array<IndustrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IndustryOrderBy>>;
  where?: InputMaybe<IndustryBoolExp>;
};

export type Subscription_RootIndustryAggregateArgs = {
  distinctOn?: InputMaybe<Array<IndustrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IndustryOrderBy>>;
  where?: InputMaybe<IndustryBoolExp>;
};

export type Subscription_RootIndustryByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootIndustryStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<IndustryStreamCursorInput>>;
  where?: InputMaybe<IndustryBoolExp>;
};

export type Subscription_RootInteractionArgs = {
  distinctOn?: InputMaybe<Array<InteractionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionOrderBy>>;
  where?: InputMaybe<InteractionBoolExp>;
};

export type Subscription_RootInteractionAggregateArgs = {
  distinctOn?: InputMaybe<Array<InteractionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionOrderBy>>;
  where?: InputMaybe<InteractionBoolExp>;
};

export type Subscription_RootInteractionByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootInteractionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<InteractionStreamCursorInput>>;
  where?: InputMaybe<InteractionBoolExp>;
};

export type Subscription_RootKycKindArgs = {
  distinctOn?: InputMaybe<Array<KycKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<KycKindOrderBy>>;
  where?: InputMaybe<KycKindBoolExp>;
};

export type Subscription_RootKycKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<KycKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<KycKindOrderBy>>;
  where?: InputMaybe<KycKindBoolExp>;
};

export type Subscription_RootKycKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootKycKindStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<KycKindStreamCursorInput>>;
  where?: InputMaybe<KycKindBoolExp>;
};

export type Subscription_RootKycStatusArgs = {
  distinctOn?: InputMaybe<Array<KycStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<KycStatusOrderBy>>;
  where?: InputMaybe<KycStatusBoolExp>;
};

export type Subscription_RootKycStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<KycStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<KycStatusOrderBy>>;
  where?: InputMaybe<KycStatusBoolExp>;
};

export type Subscription_RootKycStatusByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootKycStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<KycStatusStreamCursorInput>>;
  where?: InputMaybe<KycStatusBoolExp>;
};

export type Subscription_RootMarketArgs = {
  distinctOn?: InputMaybe<Array<MarketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MarketOrderBy>>;
  where?: InputMaybe<MarketBoolExp>;
};

export type Subscription_RootMarketAggregateArgs = {
  distinctOn?: InputMaybe<Array<MarketSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MarketOrderBy>>;
  where?: InputMaybe<MarketBoolExp>;
};

export type Subscription_RootMarketByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMarketStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<MarketStreamCursorInput>>;
  where?: InputMaybe<MarketBoolExp>;
};

export type Subscription_RootMaterialArgs = {
  distinctOn?: InputMaybe<Array<MaterialSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MaterialOrderBy>>;
  where?: InputMaybe<MaterialBoolExp>;
};

export type Subscription_RootMaterialAggregateArgs = {
  distinctOn?: InputMaybe<Array<MaterialSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MaterialOrderBy>>;
  where?: InputMaybe<MaterialBoolExp>;
};

export type Subscription_RootMaterialByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMaterialStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<MaterialStreamCursorInput>>;
  where?: InputMaybe<MaterialBoolExp>;
};

export type Subscription_RootMerchantArgs = {
  distinctOn?: InputMaybe<Array<MerchantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantOrderBy>>;
  where?: InputMaybe<MerchantBoolExp>;
};

export type Subscription_RootMerchantAggregateArgs = {
  distinctOn?: InputMaybe<Array<MerchantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantOrderBy>>;
  where?: InputMaybe<MerchantBoolExp>;
};

export type Subscription_RootMerchantByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMerchantCurrencyArgs = {
  distinctOn?: InputMaybe<Array<MerchantCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantCurrencyOrderBy>>;
  where?: InputMaybe<MerchantCurrencyBoolExp>;
};

export type Subscription_RootMerchantCurrencyAggregateArgs = {
  distinctOn?: InputMaybe<Array<MerchantCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantCurrencyOrderBy>>;
  where?: InputMaybe<MerchantCurrencyBoolExp>;
};

export type Subscription_RootMerchantCurrencyByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMerchantCurrencyStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantCurrencyStreamCursorInput>>;
  where?: InputMaybe<MerchantCurrencyBoolExp>;
};

export type Subscription_RootMerchantOrganizationArgs = {
  distinctOn?: InputMaybe<Array<MerchantOrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantOrganizationOrderBy>>;
  where?: InputMaybe<MerchantOrganizationBoolExp>;
};

export type Subscription_RootMerchantOrganizationAggregateArgs = {
  distinctOn?: InputMaybe<Array<MerchantOrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MerchantOrganizationOrderBy>>;
  where?: InputMaybe<MerchantOrganizationBoolExp>;
};

export type Subscription_RootMerchantOrganizationByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMerchantOrganizationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantOrganizationStreamCursorInput>>;
  where?: InputMaybe<MerchantOrganizationBoolExp>;
};

export type Subscription_RootMerchantStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantStreamCursorInput>>;
  where?: InputMaybe<MerchantBoolExp>;
};

export type Subscription_RootOrderArgs = {
  distinctOn?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};

export type Subscription_RootOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};

export type Subscription_RootOrderByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrderStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrderStreamCursorInput>>;
  where?: InputMaybe<OrderBoolExp>;
};

export type Subscription_RootOrganizationArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};

export type Subscription_RootOrganizationAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};

export type Subscription_RootOrganizationByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganizationKindArgs = {
  distinctOn?: InputMaybe<Array<OrganizationKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationKindOrderBy>>;
  where?: InputMaybe<OrganizationKindBoolExp>;
};

export type Subscription_RootOrganizationKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationKindOrderBy>>;
  where?: InputMaybe<OrganizationKindBoolExp>;
};

export type Subscription_RootOrganizationKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganizationKindStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrganizationKindStreamCursorInput>>;
  where?: InputMaybe<OrganizationKindBoolExp>;
};

export type Subscription_RootOrganizationPersonArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPersonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPersonOrderBy>>;
  where?: InputMaybe<OrganizationPersonBoolExp>;
};

export type Subscription_RootOrganizationPersonAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPersonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPersonOrderBy>>;
  where?: InputMaybe<OrganizationPersonBoolExp>;
};

export type Subscription_RootOrganizationPersonByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganizationPersonStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrganizationPersonStreamCursorInput>>;
  where?: InputMaybe<OrganizationPersonBoolExp>;
};

export type Subscription_RootOrganizationStatusArgs = {
  distinctOn?: InputMaybe<Array<OrganizationStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationStatusOrderBy>>;
  where?: InputMaybe<OrganizationStatusBoolExp>;
};

export type Subscription_RootOrganizationStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationStatusOrderBy>>;
  where?: InputMaybe<OrganizationStatusBoolExp>;
};

export type Subscription_RootOrganizationStatusByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganizationStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrganizationStatusStreamCursorInput>>;
  where?: InputMaybe<OrganizationStatusBoolExp>;
};

export type Subscription_RootOrganizationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrganizationStreamCursorInput>>;
  where?: InputMaybe<OrganizationBoolExp>;
};

export type Subscription_RootPasswordResetArgs = {
  distinctOn?: InputMaybe<Array<PasswordResetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PasswordResetOrderBy>>;
  where?: InputMaybe<PasswordResetBoolExp>;
};

export type Subscription_RootPasswordResetAggregateArgs = {
  distinctOn?: InputMaybe<Array<PasswordResetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PasswordResetOrderBy>>;
  where?: InputMaybe<PasswordResetBoolExp>;
};

export type Subscription_RootPasswordResetByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPasswordResetStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PasswordResetStreamCursorInput>>;
  where?: InputMaybe<PasswordResetBoolExp>;
};

export type Subscription_RootPaymentArgs = {
  distinctOn?: InputMaybe<Array<PaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentOrderBy>>;
  where?: InputMaybe<PaymentBoolExp>;
};

export type Subscription_RootPaymentAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentOrderBy>>;
  where?: InputMaybe<PaymentBoolExp>;
};

export type Subscription_RootPaymentByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPaymentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PaymentStreamCursorInput>>;
  where?: InputMaybe<PaymentBoolExp>;
};

export type Subscription_RootProductArgs = {
  distinctOn?: InputMaybe<Array<ProductSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
  where?: InputMaybe<ProductBoolExp>;
};

export type Subscription_RootProductAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
  where?: InputMaybe<ProductBoolExp>;
};

export type Subscription_RootProductByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProductDistributionArgs = {
  distinctOn?: InputMaybe<Array<ProductDistributionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductDistributionOrderBy>>;
  where?: InputMaybe<ProductDistributionBoolExp>;
};

export type Subscription_RootProductDistributionAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductDistributionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductDistributionOrderBy>>;
  where?: InputMaybe<ProductDistributionBoolExp>;
};

export type Subscription_RootProductDistributionByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProductDistributionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProductDistributionStreamCursorInput>>;
  where?: InputMaybe<ProductDistributionBoolExp>;
};

export type Subscription_RootProductKindArgs = {
  distinctOn?: InputMaybe<Array<ProductKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductKindOrderBy>>;
  where?: InputMaybe<ProductKindBoolExp>;
};

export type Subscription_RootProductKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductKindOrderBy>>;
  where?: InputMaybe<ProductKindBoolExp>;
};

export type Subscription_RootProductKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProductKindStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProductKindStreamCursorInput>>;
  where?: InputMaybe<ProductKindBoolExp>;
};

export type Subscription_RootProductStatusArgs = {
  distinctOn?: InputMaybe<Array<ProductStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductStatusOrderBy>>;
  where?: InputMaybe<ProductStatusBoolExp>;
};

export type Subscription_RootProductStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductStatusOrderBy>>;
  where?: InputMaybe<ProductStatusBoolExp>;
};

export type Subscription_RootProductStatusByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProductStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProductStatusStreamCursorInput>>;
  where?: InputMaybe<ProductStatusBoolExp>;
};

export type Subscription_RootProductStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProductStreamCursorInput>>;
  where?: InputMaybe<ProductBoolExp>;
};

export type Subscription_RootPutArgs = {
  distinctOn?: InputMaybe<Array<PutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PutOrderBy>>;
  where?: InputMaybe<PutBoolExp>;
};

export type Subscription_RootPutAggregateArgs = {
  distinctOn?: InputMaybe<Array<PutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PutOrderBy>>;
  where?: InputMaybe<PutBoolExp>;
};

export type Subscription_RootPutByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPutStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PutStreamCursorInput>>;
  where?: InputMaybe<PutBoolExp>;
};

export type Subscription_RootSettingArgs = {
  distinctOn?: InputMaybe<Array<SettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SettingOrderBy>>;
  where?: InputMaybe<SettingBoolExp>;
};

export type Subscription_RootSettingAggregateArgs = {
  distinctOn?: InputMaybe<Array<SettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SettingOrderBy>>;
  where?: InputMaybe<SettingBoolExp>;
};

export type Subscription_RootSettingByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSettingStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SettingStreamCursorInput>>;
  where?: InputMaybe<SettingBoolExp>;
};

export type Subscription_RootShippingArgs = {
  distinctOn?: InputMaybe<Array<ShippingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ShippingOrderBy>>;
  where?: InputMaybe<ShippingBoolExp>;
};

export type Subscription_RootShippingAggregateArgs = {
  distinctOn?: InputMaybe<Array<ShippingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ShippingOrderBy>>;
  where?: InputMaybe<ShippingBoolExp>;
};

export type Subscription_RootShippingByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootShippingStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ShippingStreamCursorInput>>;
  where?: InputMaybe<ShippingBoolExp>;
};

export type Subscription_RootShopArgs = {
  distinctOn?: InputMaybe<Array<ShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ShopOrderBy>>;
  where?: InputMaybe<ShopBoolExp>;
};

export type Subscription_RootShopAggregateArgs = {
  distinctOn?: InputMaybe<Array<ShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ShopOrderBy>>;
  where?: InputMaybe<ShopBoolExp>;
};

export type Subscription_RootShopByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootShopStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ShopStreamCursorInput>>;
  where?: InputMaybe<ShopBoolExp>;
};

export type Subscription_RootSicCodeArgs = {
  distinctOn?: InputMaybe<Array<SicCodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SicCodeOrderBy>>;
  where?: InputMaybe<SicCodeBoolExp>;
};

export type Subscription_RootSicCodeAggregateArgs = {
  distinctOn?: InputMaybe<Array<SicCodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SicCodeOrderBy>>;
  where?: InputMaybe<SicCodeBoolExp>;
};

export type Subscription_RootSicCodeByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSicCodeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SicCodeStreamCursorInput>>;
  where?: InputMaybe<SicCodeBoolExp>;
};

export type Subscription_RootSigmaIdentityArgs = {
  distinctOn?: InputMaybe<Array<SigmaIdentitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaIdentityOrderBy>>;
  where?: InputMaybe<SigmaIdentityBoolExp>;
};

export type Subscription_RootSigmaIdentityAggregateArgs = {
  distinctOn?: InputMaybe<Array<SigmaIdentitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaIdentityOrderBy>>;
  where?: InputMaybe<SigmaIdentityBoolExp>;
};

export type Subscription_RootSigmaIdentityByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSigmaIdentityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SigmaIdentityStreamCursorInput>>;
  where?: InputMaybe<SigmaIdentityBoolExp>;
};

export type Subscription_RootSigmaPrimeArgs = {
  distinctOn?: InputMaybe<Array<SigmaPrimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaPrimeOrderBy>>;
  where?: InputMaybe<SigmaPrimeBoolExp>;
};

export type Subscription_RootSigmaPrimeAddressArgs = {
  distinctOn?: InputMaybe<Array<SigmaPrimeAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaPrimeAddressOrderBy>>;
  where?: InputMaybe<SigmaPrimeAddressBoolExp>;
};

export type Subscription_RootSigmaPrimeAddressAggregateArgs = {
  distinctOn?: InputMaybe<Array<SigmaPrimeAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaPrimeAddressOrderBy>>;
  where?: InputMaybe<SigmaPrimeAddressBoolExp>;
};

export type Subscription_RootSigmaPrimeAddressByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSigmaPrimeAddressStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SigmaPrimeAddressStreamCursorInput>>;
  where?: InputMaybe<SigmaPrimeAddressBoolExp>;
};

export type Subscription_RootSigmaPrimeAggregateArgs = {
  distinctOn?: InputMaybe<Array<SigmaPrimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaPrimeOrderBy>>;
  where?: InputMaybe<SigmaPrimeBoolExp>;
};

export type Subscription_RootSigmaPrimeByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSigmaPrimeKindArgs = {
  distinctOn?: InputMaybe<Array<SigmaPrimeKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaPrimeKindOrderBy>>;
  where?: InputMaybe<SigmaPrimeKindBoolExp>;
};

export type Subscription_RootSigmaPrimeKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<SigmaPrimeKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaPrimeKindOrderBy>>;
  where?: InputMaybe<SigmaPrimeKindBoolExp>;
};

export type Subscription_RootSigmaPrimeKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSigmaPrimeKindStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SigmaPrimeKindStreamCursorInput>>;
  where?: InputMaybe<SigmaPrimeKindBoolExp>;
};

export type Subscription_RootSigmaPrimeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SigmaPrimeStreamCursorInput>>;
  where?: InputMaybe<SigmaPrimeBoolExp>;
};

export type Subscription_RootSigmaSocialArgs = {
  distinctOn?: InputMaybe<Array<SigmaSocialSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaSocialOrderBy>>;
  where?: InputMaybe<SigmaSocialBoolExp>;
};

export type Subscription_RootSigmaSocialAggregateArgs = {
  distinctOn?: InputMaybe<Array<SigmaSocialSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SigmaSocialOrderBy>>;
  where?: InputMaybe<SigmaSocialBoolExp>;
};

export type Subscription_RootSigmaSocialByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSigmaSocialStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SigmaSocialStreamCursorInput>>;
  where?: InputMaybe<SigmaSocialBoolExp>;
};

export type Subscription_RootSmartContractArgs = {
  distinctOn?: InputMaybe<Array<SmartContractSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractOrderBy>>;
  where?: InputMaybe<SmartContractBoolExp>;
};

export type Subscription_RootSmartContractAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmartContractSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractOrderBy>>;
  where?: InputMaybe<SmartContractBoolExp>;
};

export type Subscription_RootSmartContractByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSmartContractCommissionArgs = {
  distinctOn?: InputMaybe<Array<SmartContractCommissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractCommissionOrderBy>>;
  where?: InputMaybe<SmartContractCommissionBoolExp>;
};

export type Subscription_RootSmartContractCommissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmartContractCommissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractCommissionOrderBy>>;
  where?: InputMaybe<SmartContractCommissionBoolExp>;
};

export type Subscription_RootSmartContractCommissionByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSmartContractCommissionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SmartContractCommissionStreamCursorInput>>;
  where?: InputMaybe<SmartContractCommissionBoolExp>;
};

export type Subscription_RootSmartContractDocumentArgs = {
  distinctOn?: InputMaybe<Array<SmartContractDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractDocumentOrderBy>>;
  where?: InputMaybe<SmartContractDocumentBoolExp>;
};

export type Subscription_RootSmartContractDocumentAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmartContractDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractDocumentOrderBy>>;
  where?: InputMaybe<SmartContractDocumentBoolExp>;
};

export type Subscription_RootSmartContractDocumentByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSmartContractDocumentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SmartContractDocumentStreamCursorInput>>;
  where?: InputMaybe<SmartContractDocumentBoolExp>;
};

export type Subscription_RootSmartContractKindArgs = {
  distinctOn?: InputMaybe<Array<SmartContractKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractKindOrderBy>>;
  where?: InputMaybe<SmartContractKindBoolExp>;
};

export type Subscription_RootSmartContractKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmartContractKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractKindOrderBy>>;
  where?: InputMaybe<SmartContractKindBoolExp>;
};

export type Subscription_RootSmartContractKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSmartContractKindStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SmartContractKindStreamCursorInput>>;
  where?: InputMaybe<SmartContractKindBoolExp>;
};

export type Subscription_RootSmartContractLineageArgs = {
  distinctOn?: InputMaybe<Array<SmartContractLineageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractLineageOrderBy>>;
  where?: InputMaybe<SmartContractLineageBoolExp>;
};

export type Subscription_RootSmartContractLineageAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmartContractLineageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmartContractLineageOrderBy>>;
  where?: InputMaybe<SmartContractLineageBoolExp>;
};

export type Subscription_RootSmartContractLineageByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSmartContractLineageStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SmartContractLineageStreamCursorInput>>;
  where?: InputMaybe<SmartContractLineageBoolExp>;
};

export type Subscription_RootSmartContractStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SmartContractStreamCursorInput>>;
  where?: InputMaybe<SmartContractBoolExp>;
};

export type Subscription_RootStateArgs = {
  distinctOn?: InputMaybe<Array<StateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StateOrderBy>>;
  where?: InputMaybe<StateBoolExp>;
};

export type Subscription_RootStateAggregateArgs = {
  distinctOn?: InputMaybe<Array<StateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StateOrderBy>>;
  where?: InputMaybe<StateBoolExp>;
};

export type Subscription_RootStateStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<StateStreamCursorInput>>;
  where?: InputMaybe<StateBoolExp>;
};

export type Subscription_RootStatusArgs = {
  distinctOn?: InputMaybe<Array<StatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatusOrderBy>>;
  where?: InputMaybe<StatusBoolExp>;
};

export type Subscription_RootStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatusOrderBy>>;
  where?: InputMaybe<StatusBoolExp>;
};

export type Subscription_RootStatusByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootStatusKindArgs = {
  distinctOn?: InputMaybe<Array<StatusKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatusKindOrderBy>>;
  where?: InputMaybe<StatusKindBoolExp>;
};

export type Subscription_RootStatusKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatusKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatusKindOrderBy>>;
  where?: InputMaybe<StatusKindBoolExp>;
};

export type Subscription_RootStatusKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootStatusKindStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<StatusKindStreamCursorInput>>;
  where?: InputMaybe<StatusKindBoolExp>;
};

export type Subscription_RootStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<StatusStreamCursorInput>>;
  where?: InputMaybe<StatusBoolExp>;
};

export type Subscription_RootSubindustryArgs = {
  distinctOn?: InputMaybe<Array<SubindustrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubindustryOrderBy>>;
  where?: InputMaybe<SubindustryBoolExp>;
};

export type Subscription_RootSubindustryAggregateArgs = {
  distinctOn?: InputMaybe<Array<SubindustrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubindustryOrderBy>>;
  where?: InputMaybe<SubindustryBoolExp>;
};

export type Subscription_RootSubindustryByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSubindustryStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SubindustryStreamCursorInput>>;
  where?: InputMaybe<SubindustryBoolExp>;
};

export type Subscription_RootSupplyChainWaypointArgs = {
  distinctOn?: InputMaybe<Array<SupplyChainWaypointSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SupplyChainWaypointOrderBy>>;
  where?: InputMaybe<SupplyChainWaypointBoolExp>;
};

export type Subscription_RootSupplyChainWaypointAggregateArgs = {
  distinctOn?: InputMaybe<Array<SupplyChainWaypointSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SupplyChainWaypointOrderBy>>;
  where?: InputMaybe<SupplyChainWaypointBoolExp>;
};

export type Subscription_RootSupplyChainWaypointByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSupplyChainWaypointStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SupplyChainWaypointStreamCursorInput>>;
  where?: InputMaybe<SupplyChainWaypointBoolExp>;
};

export type Subscription_RootTradeArgs = {
  distinctOn?: InputMaybe<Array<TradeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TradeOrderBy>>;
  where?: InputMaybe<TradeBoolExp>;
};

export type Subscription_RootTradeAggregateArgs = {
  distinctOn?: InputMaybe<Array<TradeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TradeOrderBy>>;
  where?: InputMaybe<TradeBoolExp>;
};

export type Subscription_RootTradeByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTradeKindArgs = {
  distinctOn?: InputMaybe<Array<TradeKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TradeKindOrderBy>>;
  where?: InputMaybe<TradeKindBoolExp>;
};

export type Subscription_RootTradeKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<TradeKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TradeKindOrderBy>>;
  where?: InputMaybe<TradeKindBoolExp>;
};

export type Subscription_RootTradeKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTradeKindStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TradeKindStreamCursorInput>>;
  where?: InputMaybe<TradeKindBoolExp>;
};

export type Subscription_RootTradeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TradeStreamCursorInput>>;
  where?: InputMaybe<TradeBoolExp>;
};

export type Subscription_RootTransactionArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

export type Subscription_RootTransactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionOrderBy>>;
  where?: InputMaybe<TransactionBoolExp>;
};

export type Subscription_RootTransactionByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTransactionKindArgs = {
  distinctOn?: InputMaybe<Array<TransactionKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionKindOrderBy>>;
  where?: InputMaybe<TransactionKindBoolExp>;
};

export type Subscription_RootTransactionKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionKindOrderBy>>;
  where?: InputMaybe<TransactionKindBoolExp>;
};

export type Subscription_RootTransactionKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTransactionKindStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TransactionKindStreamCursorInput>>;
  where?: InputMaybe<TransactionKindBoolExp>;
};

export type Subscription_RootTransactionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TransactionStreamCursorInput>>;
  where?: InputMaybe<TransactionBoolExp>;
};

export type Subscription_RootUserArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

export type Subscription_RootUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

export type Subscription_RootUserAuthArgs = {
  distinctOn?: InputMaybe<Array<UserAuthSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserAuthOrderBy>>;
  where?: InputMaybe<UserAuthBoolExp>;
};

export type Subscription_RootUserAuthAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserAuthSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserAuthOrderBy>>;
  where?: InputMaybe<UserAuthBoolExp>;
};

export type Subscription_RootUserAuthByPkArgs = {
  userId: Scalars['uuid'];
};

export type Subscription_RootUserAuthStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<UserAuthStreamCursorInput>>;
  where?: InputMaybe<UserAuthBoolExp>;
};

export type Subscription_RootUserByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUserStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<UserStreamCursorInput>>;
  where?: InputMaybe<UserBoolExp>;
};

export type Subscription_RootWalletArgs = {
  distinctOn?: InputMaybe<Array<WalletSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletOrderBy>>;
  where?: InputMaybe<WalletBoolExp>;
};

export type Subscription_RootWalletAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletOrderBy>>;
  where?: InputMaybe<WalletBoolExp>;
};

export type Subscription_RootWalletByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootWalletInventoryArgs = {
  distinctOn?: InputMaybe<Array<WalletInventorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletInventoryOrderBy>>;
  where?: InputMaybe<WalletInventoryBoolExp>;
};

export type Subscription_RootWalletInventoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletInventorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletInventoryOrderBy>>;
  where?: InputMaybe<WalletInventoryBoolExp>;
};

export type Subscription_RootWalletInventoryByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootWalletInventoryStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<WalletInventoryStreamCursorInput>>;
  where?: InputMaybe<WalletInventoryBoolExp>;
};

export type Subscription_RootWalletKindArgs = {
  distinctOn?: InputMaybe<Array<WalletKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletKindOrderBy>>;
  where?: InputMaybe<WalletKindBoolExp>;
};

export type Subscription_RootWalletKindAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletKindSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WalletKindOrderBy>>;
  where?: InputMaybe<WalletKindBoolExp>;
};

export type Subscription_RootWalletKindByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootWalletKindStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<WalletKindStreamCursorInput>>;
  where?: InputMaybe<WalletKindBoolExp>;
};

export type Subscription_RootWalletStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<WalletStreamCursorInput>>;
  where?: InputMaybe<WalletBoolExp>;
};

export type TradeAggregateBoolExpBool_And = {
  arguments: TradeSelectColumnTradeAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TradeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type TradeAggregateBoolExpBool_Or = {
  arguments: TradeSelectColumnTradeAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TradeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type TradeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TradeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TradeBoolExp>;
  predicate: IntComparisonExp;
};

export type TransactionAggregateBoolExpAvg = {
  arguments: TransactionSelectColumnTransactionAggregateBoolExpAvgArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransactionBoolExp>;
  predicate: Float8ComparisonExp;
};

export type TransactionAggregateBoolExpBool_And = {
  arguments: TransactionSelectColumnTransactionAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransactionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type TransactionAggregateBoolExpBool_Or = {
  arguments: TransactionSelectColumnTransactionAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransactionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type TransactionAggregateBoolExpCorr = {
  arguments: TransactionAggregateBoolExpCorrArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransactionBoolExp>;
  predicate: Float8ComparisonExp;
};

export type TransactionAggregateBoolExpCorrArguments = {
  X: TransactionSelectColumnTransactionAggregateBoolExpCorrArgumentsColumns;
  Y: TransactionSelectColumnTransactionAggregateBoolExpCorrArgumentsColumns;
};

export type TransactionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TransactionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransactionBoolExp>;
  predicate: IntComparisonExp;
};

export type TransactionAggregateBoolExpCovar_Samp = {
  arguments: TransactionAggregateBoolExpCovar_SampArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransactionBoolExp>;
  predicate: Float8ComparisonExp;
};

export type TransactionAggregateBoolExpCovar_SampArguments = {
  X: TransactionSelectColumnTransactionAggregateBoolExpCovar_SampArgumentsColumns;
  Y: TransactionSelectColumnTransactionAggregateBoolExpCovar_SampArgumentsColumns;
};

export type TransactionAggregateBoolExpMax = {
  arguments: TransactionSelectColumnTransactionAggregateBoolExpMaxArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransactionBoolExp>;
  predicate: Float8ComparisonExp;
};

export type TransactionAggregateBoolExpMin = {
  arguments: TransactionSelectColumnTransactionAggregateBoolExpMinArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransactionBoolExp>;
  predicate: Float8ComparisonExp;
};

export type TransactionAggregateBoolExpStddev_Samp = {
  arguments: TransactionSelectColumnTransactionAggregateBoolExpStddev_SampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransactionBoolExp>;
  predicate: Float8ComparisonExp;
};

export type TransactionAggregateBoolExpSum = {
  arguments: TransactionSelectColumnTransactionAggregateBoolExpSumArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransactionBoolExp>;
  predicate: Float8ComparisonExp;
};

export type TransactionAggregateBoolExpVar_Samp = {
  arguments: TransactionSelectColumnTransactionAggregateBoolExpVar_SampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransactionBoolExp>;
  predicate: Float8ComparisonExp;
};

export type TransactionKindAggregateBoolExpBool_And = {
  arguments: TransactionKindSelectColumnTransactionKindAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransactionKindBoolExp>;
  predicate: BooleanComparisonExp;
};

export type TransactionKindAggregateBoolExpBool_Or = {
  arguments: TransactionKindSelectColumnTransactionKindAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransactionKindBoolExp>;
  predicate: BooleanComparisonExp;
};

export type TransactionKindAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TransactionKindSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransactionKindBoolExp>;
  predicate: IntComparisonExp;
};

export type UserAggregateBoolExpBool_And = {
  arguments: UserSelectColumnUserAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserAggregateBoolExpBool_Or = {
  arguments: UserSelectColumnUserAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserBoolExp>;
  predicate: IntComparisonExp;
};

export type WalletAggregateBoolExpBool_And = {
  arguments: WalletSelectColumnWalletAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<WalletBoolExp>;
  predicate: BooleanComparisonExp;
};

export type WalletAggregateBoolExpBool_Or = {
  arguments: WalletSelectColumnWalletAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<WalletBoolExp>;
  predicate: BooleanComparisonExp;
};

export type WalletAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WalletSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<WalletBoolExp>;
  predicate: IntComparisonExp;
};

export type StatusKindFieldsFragment = {
  __typename?: 'StatusKind';
  id: any;
  name: string;
  description: string;
  updatedAt: any;
  createdAt: any;
  isDeleted: boolean;
};

export type StatusFieldsFragment = {
  __typename?: 'Status';
  id: any;
  description: string;
  kindId?: any | null;
  name: string;
  updatedAt: any;
  startDate?: any | null;
  endDate?: any | null;
  createdAt: any;
  isDeleted: boolean;
  kind?: {
    __typename?: 'StatusKind';
    id: any;
    name: string;
    description: string;
    updatedAt: any;
    createdAt: any;
    isDeleted: boolean;
  } | null;
};

export type GicsSectorFieldsFragment = {
  __typename?: 'GicsSector';
  id: any;
  name: string;
  description?: string | null;
  imageSector?: string | null;
  updatedAt: any;
  isDeleted: boolean;
  createdAt: any;
  sicCodeId?: any | null;
  sicCode?: {
    __typename?: 'SicCode';
    id: any;
    code: number;
    name: string;
    sicCodeOffice?: string | null;
    gicsCodeName?: string | null;
    gicsCode?: number | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
  } | null;
};

export type GroupFieldsFragment = {
  __typename?: 'Group';
  id: any;
  name: string;
  description?: string | null;
  updatedAt: any;
  isDeleted: boolean;
  createdAt: any;
  imageGroup?: string | null;
  gicsSectorId: any;
  sicCodeId?: any | null;
  gicsSector: {
    __typename?: 'GicsSector';
    id: any;
    name: string;
    description?: string | null;
    imageSector?: string | null;
    updatedAt: any;
    isDeleted: boolean;
    createdAt: any;
    sicCodeId?: any | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  };
  sicCode?: {
    __typename?: 'SicCode';
    id: any;
    code: number;
    name: string;
    sicCodeOffice?: string | null;
    gicsCodeName?: string | null;
    gicsCode?: number | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
  } | null;
};

export type IndustryFieldsFragment = {
  __typename?: 'Industry';
  id: any;
  name: string;
  description?: string | null;
  imageIndustry?: string | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  groupId?: any | null;
  sicCodeId?: any | null;
  group?: { __typename?: 'Group'; name: string } | null;
  sicCode?: {
    __typename?: 'SicCode';
    id: any;
    code: number;
    name: string;
    sicCodeOffice?: string | null;
    gicsCodeName?: string | null;
    gicsCode?: number | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
  } | null;
};

export type SicCodeFieldsFragment = {
  __typename?: 'SicCode';
  id: any;
  code: number;
  name: string;
  sicCodeOffice?: string | null;
  gicsCodeName?: string | null;
  gicsCode?: number | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
};

export type SubindustryFieldsFragment = {
  __typename?: 'Subindustry';
  id: any;
  name: string;
  description?: string | null;
  imageSubindustry?: string | null;
  parentIndustryId?: any | null;
  sicCodeId?: any | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  parentIndustry?: {
    __typename?: 'Industry';
    id: any;
    name: string;
    description?: string | null;
    imageIndustry?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    groupId?: any | null;
    sicCodeId?: any | null;
    group?: { __typename?: 'Group'; name: string } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  sicCode?: {
    __typename?: 'SicCode';
    id: any;
    code: number;
    name: string;
    sicCodeOffice?: string | null;
    gicsCodeName?: string | null;
    gicsCode?: number | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
  } | null;
};

export type SigmaPrimeKindFieldsFragment = {
  __typename?: 'SigmaPrimeKind';
  id: any;
  name: string;
  description: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
};

export type SigmaPrimeFieldsFragment = {
  __typename?: 'SigmaPrime';
  id: any;
  sigmaPrimeKindId?: any | null;
  crwdId?: string | null;
  personParent1SigmaId?: any | null;
  personParent2SigmaId?: any | null;
  personFirstName: string;
  personMiddleName?: string | null;
  personLastName?: string | null;
  personFamilyName?: string | null;
  personNameSuffix?: string | null;
  personHandleName?: any | null;
  personTitleName?: string | null;
  personPrimaryMobile?: string | null;
  personPrimaryPhone?: string | null;
  personPrimaryIpAddress?: string | null;
  personPrimaryEmail: any;
  personBusinessEmail?: any | null;
  personPrimaryFacePhoto?: string | null;
  personPrimaryKycStatus: string;
  personPrimaryAmlStatus?: string | null;
  personPrivacyPolicy: string;
  personStatus: string;
  personExchangeRequest: string;
  personUniqueHash: string;
  personPrimaryLanguageId?: string | null;
  aboutMe?: string | null;
  profilePhoto?: string | null;
  userId?: any | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  kind?: {
    __typename?: 'SigmaPrimeKind';
    id: any;
    name: string;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
  } | null;
  personParent1?: {
    __typename?: 'SigmaPrime';
    id: any;
    crwdId?: string | null;
    personFirstName: string;
    personLastName?: string | null;
  } | null;
  personParent2?: {
    __typename?: 'SigmaPrime';
    id: any;
    crwdId?: string | null;
    personFirstName: string;
    personLastName?: string | null;
  } | null;
  user?: { __typename?: 'User'; id: any; email: any } | null;
};

export type OrganizationKindFieldsFragment = {
  __typename?: 'OrganizationKind';
  id: any;
  name: string;
  description?: string | null;
  createdAt: any;
  updatedAt: any;
};

export type OrganizationFieldsFragment = {
  __typename?: 'Organization';
  id: any;
  name: string;
  crwdsystemsApiReference?: string | null;
  organizationKindId?: any | null;
  organizationPrimaryAddress1?: string | null;
  organizationPrimaryAddress2?: string | null;
  organizationPrimaryCity?: string | null;
  organizationPrimaryState?: string | null;
  organizationPrimaryPostalCode?: string | null;
  organizationPrimaryRegion?: string | null;
  organizationPrimaryCountryCode?: string | null;
  organizationPrimaryPhone?: string | null;
  geocode?: string | null;
  crwdTicker?: string | null;
  organizationPersonStatus?: string | null;
  personUniqueHash?: string | null;
  personFirstName?: string | null;
  personMiddleName?: string | null;
  personLastName?: string | null;
  companyTitleName?: string | null;
  personPrimaryMobile?: string | null;
  personBusinessEmail?: string | null;
  companyPrimaryWebsite?: string | null;
  companyEmployeeCount?: string | null;
  companyRevenue?: string | null;
  sicCodeId?: any | null;
  industryId?: any | null;
  sub1IndustryId?: any | null;
  sub2IndustryId?: any | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  kind?: {
    __typename?: 'OrganizationKind';
    id: any;
    name: string;
    description?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
  sicCode?: {
    __typename?: 'SicCode';
    id: any;
    code: number;
    name: string;
    sicCodeOffice?: string | null;
    gicsCodeName?: string | null;
    gicsCode?: number | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
  } | null;
  industry?: {
    __typename?: 'Industry';
    id: any;
    name: string;
    description?: string | null;
    imageIndustry?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    groupId?: any | null;
    sicCodeId?: any | null;
    group?: { __typename?: 'Group'; name: string } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  subindustry1?: {
    __typename?: 'Subindustry';
    id: any;
    name: string;
    description?: string | null;
    imageSubindustry?: string | null;
    parentIndustryId?: any | null;
    sicCodeId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    parentIndustry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  subindustry2?: {
    __typename?: 'Subindustry';
    id: any;
    name: string;
    description?: string | null;
    imageSubindustry?: string | null;
    parentIndustryId?: any | null;
    sicCodeId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    parentIndustry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
};

export type CountryFieldsFragment = {
  __typename?: 'Country';
  code: string;
  name: string;
  region: string;
  phoneCode: number;
  secCode?: string | null;
  stateLabel: string;
  longitude: any;
  latitude: any;
  isoNumber: number;
  iso3: string;
  flagEmojiUnicode: string;
  flagEmoji: string;
  currencyCode: string;
  createdAt?: any | null;
  updatedAt?: any | null;
  isDeleted: boolean;
};

export type StateFieldsFragment = {
  __typename?: 'State';
  code: string;
  name: string;
  secCode?: string | null;
  longitude?: any | null;
  latitude?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  isDeleted: boolean;
  countryCode: string;
  country: {
    __typename?: 'Country';
    code: string;
    name: string;
    region: string;
    phoneCode: number;
    secCode?: string | null;
    stateLabel: string;
    longitude: any;
    latitude: any;
    isoNumber: number;
    iso3: string;
    flagEmojiUnicode: string;
    flagEmoji: string;
    currencyCode: string;
    createdAt?: any | null;
    updatedAt?: any | null;
    isDeleted: boolean;
  };
};

export type CurrencyKindFieldsFragment = {
  __typename?: 'CurrencyKind';
  id: any;
  name: string;
  description: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  gicsSectorId?: any | null;
  groupId?: any | null;
  industryId?: any | null;
  subindustryId?: any | null;
  gicsSector?: {
    __typename?: 'GicsSector';
    id: any;
    name: string;
    description?: string | null;
    imageSector?: string | null;
    updatedAt: any;
    isDeleted: boolean;
    createdAt: any;
    sicCodeId?: any | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  group?: {
    __typename?: 'Group';
    id: any;
    name: string;
    description?: string | null;
    updatedAt: any;
    isDeleted: boolean;
    createdAt: any;
    imageGroup?: string | null;
    gicsSectorId: any;
    sicCodeId?: any | null;
    gicsSector: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  industry?: {
    __typename?: 'Industry';
    id: any;
    name: string;
    description?: string | null;
    imageIndustry?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    groupId?: any | null;
    sicCodeId?: any | null;
    group?: { __typename?: 'Group'; name: string } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  subindustry?: {
    __typename?: 'Subindustry';
    id: any;
    name: string;
    description?: string | null;
    imageSubindustry?: string | null;
    parentIndustryId?: any | null;
    sicCodeId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    parentIndustry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
};

export type CurrencyFieldsFragment = {
  __typename?: 'Currency';
  id: any;
  abbreviation?: string | null;
  authorized?: any | null;
  cc?: string | null;
  description?: string | null;
  reserved?: any | null;
  name: string;
  issued?: any | null;
  image?: string | null;
  symbol?: string | null;
  numericCode?: string | null;
  minorUnit?: string | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  countryCode?: string | null;
  currencyKindId?: any | null;
  groupsId?: any | null;
  sectorId?: any | null;
  subindustryId?: any | null;
  country?: {
    __typename?: 'Country';
    code: string;
    name: string;
    region: string;
    phoneCode: number;
    secCode?: string | null;
    stateLabel: string;
    longitude: any;
    latitude: any;
    isoNumber: number;
    iso3: string;
    flagEmojiUnicode: string;
    flagEmoji: string;
    currencyCode: string;
    createdAt?: any | null;
    updatedAt?: any | null;
    isDeleted: boolean;
  } | null;
  kind?: {
    __typename?: 'CurrencyKind';
    id: any;
    name: string;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    gicsSectorId?: any | null;
    groupId?: any | null;
    industryId?: any | null;
    subindustryId?: any | null;
    gicsSector?: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      imageGroup?: string | null;
      gicsSectorId: any;
      sicCodeId?: any | null;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  group?: {
    __typename?: 'Group';
    id: any;
    name: string;
    description?: string | null;
    updatedAt: any;
    isDeleted: boolean;
    createdAt: any;
    imageGroup?: string | null;
    gicsSectorId: any;
    sicCodeId?: any | null;
    gicsSector: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  gicsSector?: {
    __typename?: 'GicsSector';
    id: any;
    name: string;
    description?: string | null;
    imageSector?: string | null;
    updatedAt: any;
    isDeleted: boolean;
    createdAt: any;
    sicCodeId?: any | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  subindustry?: {
    __typename?: 'Subindustry';
    id: any;
    name: string;
    description?: string | null;
    imageSubindustry?: string | null;
    parentIndustryId?: any | null;
    sicCodeId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    parentIndustry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
};

export type CurrencyOwnerFieldsFragment = {
  __typename?: 'CurrencyOwner';
  id: any;
  dateAuthorized: any;
  dateIssued: any;
  ownerTransfer: boolean;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  currencyId: any;
  grantingOrganizationId: any;
  organizationId?: any | null;
  originalOrganizationId?: any | null;
  currency: {
    __typename?: 'Currency';
    id: any;
    abbreviation?: string | null;
    authorized?: any | null;
    cc?: string | null;
    description?: string | null;
    reserved?: any | null;
    name: string;
    issued?: any | null;
    image?: string | null;
    symbol?: string | null;
    numericCode?: string | null;
    minorUnit?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    countryCode?: string | null;
    currencyKindId?: any | null;
    groupsId?: any | null;
    sectorId?: any | null;
    subindustryId?: any | null;
    country?: {
      __typename?: 'Country';
      code: string;
      name: string;
      region: string;
      phoneCode: number;
      secCode?: string | null;
      stateLabel: string;
      longitude: any;
      latitude: any;
      isoNumber: number;
      iso3: string;
      flagEmojiUnicode: string;
      flagEmoji: string;
      currencyCode: string;
      createdAt?: any | null;
      updatedAt?: any | null;
      isDeleted: boolean;
    } | null;
    kind?: {
      __typename?: 'CurrencyKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      gicsSectorId?: any | null;
      groupId?: any | null;
      industryId?: any | null;
      subindustryId?: any | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      imageGroup?: string | null;
      gicsSectorId: any;
      sicCodeId?: any | null;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    gicsSector?: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  };
  organizationByGrantingOrganizationId: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  };
  organization?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  organizationByOriginalOrganizationId?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
};

export type UserFieldsFragment = {
  __typename?: 'User';
  email: any;
  emailVerified: boolean;
  preferences?: string | null;
  rememberToken: string;
  isAdmin: boolean;
  crwdsystemsApi?: string | null;
  createdAt: any;
  updatedAt: any;
  id: any;
  organizationId?: any | null;
  statusId?: any | null;
  isDeleted: boolean;
  sigmaPrimeId?: any | null;
  uniqueHash: string;
  countryCode?: string | null;
  organization?: { __typename?: 'Organization'; id: any; name: string } | null;
  status?: {
    __typename?: 'Status';
    id: any;
    description: string;
    kindId?: any | null;
    name: string;
    updatedAt: any;
    startDate?: any | null;
    endDate?: any | null;
    createdAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'StatusKind';
      id: any;
      name: string;
      description: string;
      updatedAt: any;
      createdAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  sigmaPrime?: {
    __typename?: 'SigmaPrime';
    id: any;
    sigmaPrimeKindId?: any | null;
    crwdId?: string | null;
    personParent1SigmaId?: any | null;
    personParent2SigmaId?: any | null;
    personFirstName: string;
    personMiddleName?: string | null;
    personLastName?: string | null;
    personFamilyName?: string | null;
    personNameSuffix?: string | null;
    personHandleName?: any | null;
    personTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personPrimaryPhone?: string | null;
    personPrimaryIpAddress?: string | null;
    personPrimaryEmail: any;
    personBusinessEmail?: any | null;
    personPrimaryFacePhoto?: string | null;
    personPrimaryKycStatus: string;
    personPrimaryAmlStatus?: string | null;
    personPrivacyPolicy: string;
    personStatus: string;
    personExchangeRequest: string;
    personUniqueHash: string;
    personPrimaryLanguageId?: string | null;
    aboutMe?: string | null;
    profilePhoto?: string | null;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'SigmaPrimeKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    personParent1?: {
      __typename?: 'SigmaPrime';
      id: any;
      crwdId?: string | null;
      personFirstName: string;
      personLastName?: string | null;
    } | null;
    personParent2?: {
      __typename?: 'SigmaPrime';
      id: any;
      crwdId?: string | null;
      personFirstName: string;
      personLastName?: string | null;
    } | null;
    user?: { __typename?: 'User'; id: any; email: any } | null;
  } | null;
};

export type UserAuthFieldsFragment = {
  __typename?: 'UserAuth';
  userId: any;
  hash: string;
  salt: string;
  createdAt: any;
  updatedAt: any;
};

export type FavoriteFieldsFragment = {
  __typename?: 'Favorite';
  id: any;
  name: string;
  blog?: string | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  walletId?: any | null;
  wallet?: {
    __typename?: 'Wallet';
    id: any;
    name: string;
    walletAddress: string;
    primaryHash: string;
    secondaryHash?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    defaultCurrencyId: any;
    primaryUserId: any;
    secondaryUserId?: any | null;
    walletKindId?: any | null;
    defaultCurrency: {
      __typename?: 'Currency';
      id: any;
      abbreviation?: string | null;
      authorized?: any | null;
      cc?: string | null;
      description?: string | null;
      reserved?: any | null;
      name: string;
      issued?: any | null;
      image?: string | null;
      symbol?: string | null;
      numericCode?: string | null;
      minorUnit?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      countryCode?: string | null;
      currencyKindId?: any | null;
      groupsId?: any | null;
      sectorId?: any | null;
      subindustryId?: any | null;
      country?: {
        __typename?: 'Country';
        code: string;
        name: string;
        region: string;
        phoneCode: number;
        secCode?: string | null;
        stateLabel: string;
        longitude: any;
        latitude: any;
        isoNumber: number;
        iso3: string;
        flagEmojiUnicode: string;
        flagEmoji: string;
        currencyCode: string;
        createdAt?: any | null;
        updatedAt?: any | null;
        isDeleted: boolean;
      } | null;
      kind?: {
        __typename?: 'CurrencyKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId?: any | null;
        groupId?: any | null;
        industryId?: any | null;
        subindustryId?: any | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    };
    primaryUser: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
    secondaryUser?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
    kind?: {
      __typename?: 'WalletKind';
      id: any;
      name: string;
      merchant?: boolean | null;
      organization?: boolean | null;
      user?: boolean | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
};

export type WalletKindFieldsFragment = {
  __typename?: 'WalletKind';
  id: any;
  name: string;
  merchant?: boolean | null;
  organization?: boolean | null;
  user?: boolean | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
};

export type WalletFieldsFragment = {
  __typename?: 'Wallet';
  id: any;
  name: string;
  walletAddress: string;
  primaryHash: string;
  secondaryHash?: string | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  defaultCurrencyId: any;
  primaryUserId: any;
  secondaryUserId?: any | null;
  walletKindId?: any | null;
  defaultCurrency: {
    __typename?: 'Currency';
    id: any;
    abbreviation?: string | null;
    authorized?: any | null;
    cc?: string | null;
    description?: string | null;
    reserved?: any | null;
    name: string;
    issued?: any | null;
    image?: string | null;
    symbol?: string | null;
    numericCode?: string | null;
    minorUnit?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    countryCode?: string | null;
    currencyKindId?: any | null;
    groupsId?: any | null;
    sectorId?: any | null;
    subindustryId?: any | null;
    country?: {
      __typename?: 'Country';
      code: string;
      name: string;
      region: string;
      phoneCode: number;
      secCode?: string | null;
      stateLabel: string;
      longitude: any;
      latitude: any;
      isoNumber: number;
      iso3: string;
      flagEmojiUnicode: string;
      flagEmoji: string;
      currencyCode: string;
      createdAt?: any | null;
      updatedAt?: any | null;
      isDeleted: boolean;
    } | null;
    kind?: {
      __typename?: 'CurrencyKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      gicsSectorId?: any | null;
      groupId?: any | null;
      industryId?: any | null;
      subindustryId?: any | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      imageGroup?: string | null;
      gicsSectorId: any;
      sicCodeId?: any | null;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    gicsSector?: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  };
  primaryUser: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  };
  secondaryUser?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
  kind?: {
    __typename?: 'WalletKind';
    id: any;
    name: string;
    merchant?: boolean | null;
    organization?: boolean | null;
    user?: boolean | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
  } | null;
};

export type CertificationFieldsFragment = {
  __typename?: 'Certification';
  id: any;
  name: string;
  description: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  gicsSectorId: any;
  groupId: any;
  organizationId: any;
  industryId?: any | null;
  subindustryId: any;
  gicsSector: {
    __typename?: 'GicsSector';
    id: any;
    name: string;
    description?: string | null;
    imageSector?: string | null;
    updatedAt: any;
    isDeleted: boolean;
    createdAt: any;
    sicCodeId?: any | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  };
  group: {
    __typename?: 'Group';
    id: any;
    name: string;
    description?: string | null;
    updatedAt: any;
    isDeleted: boolean;
    createdAt: any;
    imageGroup?: string | null;
    gicsSectorId: any;
    sicCodeId?: any | null;
    gicsSector: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  };
  organization: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  };
  industry?: {
    __typename?: 'Industry';
    id: any;
    name: string;
    description?: string | null;
    imageIndustry?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    groupId?: any | null;
    sicCodeId?: any | null;
    group?: { __typename?: 'Group'; name: string } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  subindustry: {
    __typename?: 'Subindustry';
    id: any;
    name: string;
    description?: string | null;
    imageSubindustry?: string | null;
    parentIndustryId?: any | null;
    sicCodeId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    parentIndustry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  };
};

export type ClearinghouseKindFieldsFragment = {
  __typename?: 'ClearinghouseKind';
  id: any;
  name: string;
  description: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  gicsSectorId: any;
  groupId: any;
  industryId: any;
  subindustryId: any;
  gicsSector: {
    __typename?: 'GicsSector';
    id: any;
    name: string;
    description?: string | null;
    imageSector?: string | null;
    updatedAt: any;
    isDeleted: boolean;
    createdAt: any;
    sicCodeId?: any | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  };
  group: {
    __typename?: 'Group';
    id: any;
    name: string;
    description?: string | null;
    updatedAt: any;
    isDeleted: boolean;
    createdAt: any;
    imageGroup?: string | null;
    gicsSectorId: any;
    sicCodeId?: any | null;
    gicsSector: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  };
  industry: {
    __typename?: 'Industry';
    id: any;
    name: string;
    description?: string | null;
    imageIndustry?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    groupId?: any | null;
    sicCodeId?: any | null;
    group?: { __typename?: 'Group'; name: string } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  };
  subindustry: {
    __typename?: 'Subindustry';
    id: any;
    name: string;
    description?: string | null;
    imageSubindustry?: string | null;
    parentIndustryId?: any | null;
    sicCodeId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    parentIndustry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  };
};

export type ClearinghouseFieldsFragment = {
  __typename?: 'Clearinghouse';
  id: any;
  name: string;
  description: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  clearinghouseKindId: any;
  userId: any;
  kind: {
    __typename?: 'ClearinghouseKind';
    id: any;
    name: string;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    gicsSectorId: any;
    groupId: any;
    industryId: any;
    subindustryId: any;
    gicsSector: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
    group: {
      __typename?: 'Group';
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      imageGroup?: string | null;
      gicsSectorId: any;
      sicCodeId?: any | null;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
    industry: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
    subindustry: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
  };
  user: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  };
};

export type ClearinghouseCertificationFieldsFragment = {
  __typename?: 'ClearinghouseCertification';
  id: any;
  isActive: boolean;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  certificationId: any;
  clearinghouseId: any;
  certification: {
    __typename?: 'Certification';
    id: any;
    name: string;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    gicsSectorId: any;
    groupId: any;
    organizationId: any;
    industryId?: any | null;
    subindustryId: any;
    gicsSector: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
    group: {
      __typename?: 'Group';
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      imageGroup?: string | null;
      gicsSectorId: any;
      sicCodeId?: any | null;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
    organization: {
      __typename?: 'Organization';
      id: any;
      name: string;
      crwdsystemsApiReference?: string | null;
      organizationKindId?: any | null;
      organizationPrimaryAddress1?: string | null;
      organizationPrimaryAddress2?: string | null;
      organizationPrimaryCity?: string | null;
      organizationPrimaryState?: string | null;
      organizationPrimaryPostalCode?: string | null;
      organizationPrimaryRegion?: string | null;
      organizationPrimaryCountryCode?: string | null;
      organizationPrimaryPhone?: string | null;
      geocode?: string | null;
      crwdTicker?: string | null;
      organizationPersonStatus?: string | null;
      personUniqueHash?: string | null;
      personFirstName?: string | null;
      personMiddleName?: string | null;
      personLastName?: string | null;
      companyTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personBusinessEmail?: string | null;
      companyPrimaryWebsite?: string | null;
      companyEmployeeCount?: string | null;
      companyRevenue?: string | null;
      sicCodeId?: any | null;
      industryId?: any | null;
      sub1IndustryId?: any | null;
      sub2IndustryId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'OrganizationKind';
        id: any;
        name: string;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    };
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
  };
  clearinghouse: {
    __typename?: 'Clearinghouse';
    id: any;
    name: string;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    clearinghouseKindId: any;
    userId: any;
    kind: {
      __typename?: 'ClearinghouseKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      gicsSectorId: any;
      groupId: any;
      industryId: any;
      subindustryId: any;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      group: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      industry: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      subindustry: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
    };
    user: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
  };
};

export type MerchantFieldsFragment = {
  __typename?: 'Merchant';
  id: any;
  name: string;
  description?: string | null;
  crwdsystemsApi: string;
  notifyUrl?: string | null;
  uniqueHash: string;
  url?: string | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  userId: any;
  statusId?: any | null;
  user: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  };
  status?: {
    __typename?: 'Status';
    id: any;
    description: string;
    kindId?: any | null;
    name: string;
    updatedAt: any;
    startDate?: any | null;
    endDate?: any | null;
    createdAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'StatusKind';
      id: any;
      name: string;
      description: string;
      updatedAt: any;
      createdAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
};

export type MerchantCurrencyFieldsFragment = {
  __typename?: 'MerchantCurrency';
  id: any;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  currencyId: any;
  merchantId: any;
  statusId?: any | null;
  currency: {
    __typename?: 'Currency';
    id: any;
    abbreviation?: string | null;
    authorized?: any | null;
    cc?: string | null;
    description?: string | null;
    reserved?: any | null;
    name: string;
    issued?: any | null;
    image?: string | null;
    symbol?: string | null;
    numericCode?: string | null;
    minorUnit?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    countryCode?: string | null;
    currencyKindId?: any | null;
    groupsId?: any | null;
    sectorId?: any | null;
    subindustryId?: any | null;
    country?: {
      __typename?: 'Country';
      code: string;
      name: string;
      region: string;
      phoneCode: number;
      secCode?: string | null;
      stateLabel: string;
      longitude: any;
      latitude: any;
      isoNumber: number;
      iso3: string;
      flagEmojiUnicode: string;
      flagEmoji: string;
      currencyCode: string;
      createdAt?: any | null;
      updatedAt?: any | null;
      isDeleted: boolean;
    } | null;
    kind?: {
      __typename?: 'CurrencyKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      gicsSectorId?: any | null;
      groupId?: any | null;
      industryId?: any | null;
      subindustryId?: any | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      imageGroup?: string | null;
      gicsSectorId: any;
      sicCodeId?: any | null;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    gicsSector?: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  };
  merchant: {
    __typename?: 'Merchant';
    id: any;
    name: string;
    description?: string | null;
    crwdsystemsApi: string;
    notifyUrl?: string | null;
    uniqueHash: string;
    url?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    userId: any;
    statusId?: any | null;
    user: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  };
  status?: {
    __typename?: 'Status';
    id: any;
    description: string;
    kindId?: any | null;
    name: string;
    updatedAt: any;
    startDate?: any | null;
    endDate?: any | null;
    createdAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'StatusKind';
      id: any;
      name: string;
      description: string;
      updatedAt: any;
      createdAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
};

export type MerchantOrganizationFieldsFragment = {
  __typename?: 'MerchantOrganization';
  id: any;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  merchantId?: any | null;
  organizationId?: any | null;
  merchant?: {
    __typename?: 'Merchant';
    id: any;
    name: string;
    description?: string | null;
    crwdsystemsApi: string;
    notifyUrl?: string | null;
    uniqueHash: string;
    url?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    userId: any;
    statusId?: any | null;
    user: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  organization?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
};

export type SmartContractKindFieldsFragment = {
  __typename?: 'SmartContractKind';
  id: any;
  name: string;
  description: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  subindustryId: any;
  subindustry: {
    __typename?: 'Subindustry';
    id: any;
    name: string;
    description?: string | null;
    imageSubindustry?: string | null;
    parentIndustryId?: any | null;
    sicCodeId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    parentIndustry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  };
};

export type SmartContractFieldsFragment = {
  __typename?: 'SmartContract';
  id: any;
  name: string;
  description: string;
  useCount: number;
  terms: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  clearinghouseId: any;
  smartContractKindId: any;
  clearinghouse: {
    __typename?: 'Clearinghouse';
    id: any;
    name: string;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    clearinghouseKindId: any;
    userId: any;
    kind: {
      __typename?: 'ClearinghouseKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      gicsSectorId: any;
      groupId: any;
      industryId: any;
      subindustryId: any;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      group: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      industry: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      subindustry: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
    };
    user: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
  };
  kind: {
    __typename?: 'SmartContractKind';
    id: any;
    name: string;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    subindustryId: any;
    subindustry: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
  };
};

export type PaymentFieldsFragment = {
  __typename?: 'Payment';
  id: any;
  orderId?: any | null;
  amountCrwdunit?: any | null;
  amountCurrency?: any | null;
  amountUsd?: number | null;
  paymentDetails: string;
  receiveWalletTxn: string;
  sendWalletTxn: string;
  successUrl: string;
  cancelUrl: string;
  comment?: string | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  currencyId?: any | null;
  userId: any;
  statusId?: any | null;
  askId?: any | null;
  putId?: any | null;
  assetAccountId?: any | null;
  currency?: {
    __typename?: 'Currency';
    id: any;
    abbreviation?: string | null;
    authorized?: any | null;
    cc?: string | null;
    description?: string | null;
    reserved?: any | null;
    name: string;
    issued?: any | null;
    image?: string | null;
    symbol?: string | null;
    numericCode?: string | null;
    minorUnit?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    countryCode?: string | null;
    currencyKindId?: any | null;
    groupsId?: any | null;
    sectorId?: any | null;
    subindustryId?: any | null;
    country?: {
      __typename?: 'Country';
      code: string;
      name: string;
      region: string;
      phoneCode: number;
      secCode?: string | null;
      stateLabel: string;
      longitude: any;
      latitude: any;
      isoNumber: number;
      iso3: string;
      flagEmojiUnicode: string;
      flagEmoji: string;
      currencyCode: string;
      createdAt?: any | null;
      updatedAt?: any | null;
      isDeleted: boolean;
    } | null;
    kind?: {
      __typename?: 'CurrencyKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      gicsSectorId?: any | null;
      groupId?: any | null;
      industryId?: any | null;
      subindustryId?: any | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      imageGroup?: string | null;
      gicsSectorId: any;
      sicCodeId?: any | null;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    gicsSector?: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  user: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  };
  status?: { __typename?: 'Status'; id: any; name: string } | null;
  ask?: { __typename?: 'Ask'; id: any; name: string } | null;
  put?: { __typename?: 'Put'; id: any; name: string } | null;
  assetAccount?: {
    __typename?: 'AssetAccount';
    id: any;
    accountNumber?: number | null;
    availableAt: any;
    cvv: string;
    expirationDate: any;
    ibanNumber: string;
    issueDate: any;
    organizationId?: any | null;
    routingNumber: string;
    securityCode: string;
    swiftCode: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    userId?: any | null;
    balanceAmount?: any | null;
    accountKind?: string | null;
    accountName?: string | null;
    achNumber?: string | null;
    ccNumber?: string | null;
    organization?: {
      __typename?: 'Organization';
      id: any;
      name: string;
      crwdsystemsApiReference?: string | null;
      organizationKindId?: any | null;
      organizationPrimaryAddress1?: string | null;
      organizationPrimaryAddress2?: string | null;
      organizationPrimaryCity?: string | null;
      organizationPrimaryState?: string | null;
      organizationPrimaryPostalCode?: string | null;
      organizationPrimaryRegion?: string | null;
      organizationPrimaryCountryCode?: string | null;
      organizationPrimaryPhone?: string | null;
      geocode?: string | null;
      crwdTicker?: string | null;
      organizationPersonStatus?: string | null;
      personUniqueHash?: string | null;
      personFirstName?: string | null;
      personMiddleName?: string | null;
      personLastName?: string | null;
      companyTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personBusinessEmail?: string | null;
      companyPrimaryWebsite?: string | null;
      companyEmployeeCount?: string | null;
      companyRevenue?: string | null;
      sicCodeId?: any | null;
      industryId?: any | null;
      sub1IndustryId?: any | null;
      sub2IndustryId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'OrganizationKind';
        id: any;
        name: string;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    user?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
  } | null;
};

export type TransactionKindFieldsFragment = {
  __typename?: 'TransactionKind';
  id: any;
  name: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  smartContractId: any;
  description?: string | null;
  subIndustry1Id?: any | null;
  subIndustry2Id?: any | null;
  smartContract: {
    __typename?: 'SmartContract';
    id: any;
    name: string;
    description: string;
    useCount: number;
    terms: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    clearinghouseId: any;
    smartContractKindId: any;
    clearinghouse: {
      __typename?: 'Clearinghouse';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseKindId: any;
      userId: any;
      kind: {
        __typename?: 'ClearinghouseKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId: any;
        groupId: any;
        industryId: any;
        subindustryId: any;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        group: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        industry: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
      user: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
    };
    kind: {
      __typename?: 'SmartContractKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      subindustryId: any;
      subindustry: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
    };
  };
  subindustry1?: {
    __typename?: 'Subindustry';
    id: any;
    name: string;
    description?: string | null;
    imageSubindustry?: string | null;
    parentIndustryId?: any | null;
    sicCodeId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    parentIndustry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  subindustry2?: {
    __typename?: 'Subindustry';
    id: any;
    name: string;
    description?: string | null;
    imageSubindustry?: string | null;
    parentIndustryId?: any | null;
    sicCodeId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    parentIndustry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
};

export type TransactionFieldsFragment = {
  __typename?: 'Transaction';
  id: any;
  txnId: string;
  networkFee: any;
  destTag?: string | null;
  confirmation?: string | null;
  amount: any;
  description: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  fromWalletId?: any | null;
  paymentId: any;
  smartContractId: any;
  statusId: any;
  toWalletId?: any | null;
  transactionKindId: any;
  currencyId: any;
  fromWallet?: {
    __typename?: 'Wallet';
    id: any;
    name: string;
    walletAddress: string;
    primaryHash: string;
    secondaryHash?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    defaultCurrencyId: any;
    primaryUserId: any;
    secondaryUserId?: any | null;
    walletKindId?: any | null;
    defaultCurrency: {
      __typename?: 'Currency';
      id: any;
      abbreviation?: string | null;
      authorized?: any | null;
      cc?: string | null;
      description?: string | null;
      reserved?: any | null;
      name: string;
      issued?: any | null;
      image?: string | null;
      symbol?: string | null;
      numericCode?: string | null;
      minorUnit?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      countryCode?: string | null;
      currencyKindId?: any | null;
      groupsId?: any | null;
      sectorId?: any | null;
      subindustryId?: any | null;
      country?: {
        __typename?: 'Country';
        code: string;
        name: string;
        region: string;
        phoneCode: number;
        secCode?: string | null;
        stateLabel: string;
        longitude: any;
        latitude: any;
        isoNumber: number;
        iso3: string;
        flagEmojiUnicode: string;
        flagEmoji: string;
        currencyCode: string;
        createdAt?: any | null;
        updatedAt?: any | null;
        isDeleted: boolean;
      } | null;
      kind?: {
        __typename?: 'CurrencyKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId?: any | null;
        groupId?: any | null;
        industryId?: any | null;
        subindustryId?: any | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    };
    primaryUser: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
    secondaryUser?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
    kind?: {
      __typename?: 'WalletKind';
      id: any;
      name: string;
      merchant?: boolean | null;
      organization?: boolean | null;
      user?: boolean | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  payment: {
    __typename?: 'Payment';
    id: any;
    orderId?: any | null;
    amountCrwdunit?: any | null;
    amountCurrency?: any | null;
    amountUsd?: number | null;
    paymentDetails: string;
    receiveWalletTxn: string;
    sendWalletTxn: string;
    successUrl: string;
    cancelUrl: string;
    comment?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    currencyId?: any | null;
    userId: any;
    statusId?: any | null;
    askId?: any | null;
    putId?: any | null;
    assetAccountId?: any | null;
    currency?: {
      __typename?: 'Currency';
      id: any;
      abbreviation?: string | null;
      authorized?: any | null;
      cc?: string | null;
      description?: string | null;
      reserved?: any | null;
      name: string;
      issued?: any | null;
      image?: string | null;
      symbol?: string | null;
      numericCode?: string | null;
      minorUnit?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      countryCode?: string | null;
      currencyKindId?: any | null;
      groupsId?: any | null;
      sectorId?: any | null;
      subindustryId?: any | null;
      country?: {
        __typename?: 'Country';
        code: string;
        name: string;
        region: string;
        phoneCode: number;
        secCode?: string | null;
        stateLabel: string;
        longitude: any;
        latitude: any;
        isoNumber: number;
        iso3: string;
        flagEmojiUnicode: string;
        flagEmoji: string;
        currencyCode: string;
        createdAt?: any | null;
        updatedAt?: any | null;
        isDeleted: boolean;
      } | null;
      kind?: {
        __typename?: 'CurrencyKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId?: any | null;
        groupId?: any | null;
        industryId?: any | null;
        subindustryId?: any | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    user: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
    status?: { __typename?: 'Status'; id: any; name: string } | null;
    ask?: { __typename?: 'Ask'; id: any; name: string } | null;
    put?: { __typename?: 'Put'; id: any; name: string } | null;
    assetAccount?: {
      __typename?: 'AssetAccount';
      id: any;
      accountNumber?: number | null;
      availableAt: any;
      cvv: string;
      expirationDate: any;
      ibanNumber: string;
      issueDate: any;
      organizationId?: any | null;
      routingNumber: string;
      securityCode: string;
      swiftCode: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      userId?: any | null;
      balanceAmount?: any | null;
      accountKind?: string | null;
      accountName?: string | null;
      achNumber?: string | null;
      ccNumber?: string | null;
      organization?: {
        __typename?: 'Organization';
        id: any;
        name: string;
        crwdsystemsApiReference?: string | null;
        organizationKindId?: any | null;
        organizationPrimaryAddress1?: string | null;
        organizationPrimaryAddress2?: string | null;
        organizationPrimaryCity?: string | null;
        organizationPrimaryState?: string | null;
        organizationPrimaryPostalCode?: string | null;
        organizationPrimaryRegion?: string | null;
        organizationPrimaryCountryCode?: string | null;
        organizationPrimaryPhone?: string | null;
        geocode?: string | null;
        crwdTicker?: string | null;
        organizationPersonStatus?: string | null;
        personUniqueHash?: string | null;
        personFirstName?: string | null;
        personMiddleName?: string | null;
        personLastName?: string | null;
        companyTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personBusinessEmail?: string | null;
        companyPrimaryWebsite?: string | null;
        companyEmployeeCount?: string | null;
        companyRevenue?: string | null;
        sicCodeId?: any | null;
        industryId?: any | null;
        sub1IndustryId?: any | null;
        sub2IndustryId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'OrganizationKind';
          id: any;
          name: string;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry1?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry2?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      user?: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      } | null;
    } | null;
  };
  smartContract: {
    __typename?: 'SmartContract';
    id: any;
    name: string;
    description: string;
    useCount: number;
    terms: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    clearinghouseId: any;
    smartContractKindId: any;
    clearinghouse: {
      __typename?: 'Clearinghouse';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseKindId: any;
      userId: any;
      kind: {
        __typename?: 'ClearinghouseKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId: any;
        groupId: any;
        industryId: any;
        subindustryId: any;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        group: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        industry: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
      user: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
    };
    kind: {
      __typename?: 'SmartContractKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      subindustryId: any;
      subindustry: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
    };
  };
  status: {
    __typename?: 'Status';
    id: any;
    description: string;
    kindId?: any | null;
    name: string;
    updatedAt: any;
    startDate?: any | null;
    endDate?: any | null;
    createdAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'StatusKind';
      id: any;
      name: string;
      description: string;
      updatedAt: any;
      createdAt: any;
      isDeleted: boolean;
    } | null;
  };
  toWallet?: {
    __typename?: 'Wallet';
    id: any;
    name: string;
    walletAddress: string;
    primaryHash: string;
    secondaryHash?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    defaultCurrencyId: any;
    primaryUserId: any;
    secondaryUserId?: any | null;
    walletKindId?: any | null;
    defaultCurrency: {
      __typename?: 'Currency';
      id: any;
      abbreviation?: string | null;
      authorized?: any | null;
      cc?: string | null;
      description?: string | null;
      reserved?: any | null;
      name: string;
      issued?: any | null;
      image?: string | null;
      symbol?: string | null;
      numericCode?: string | null;
      minorUnit?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      countryCode?: string | null;
      currencyKindId?: any | null;
      groupsId?: any | null;
      sectorId?: any | null;
      subindustryId?: any | null;
      country?: {
        __typename?: 'Country';
        code: string;
        name: string;
        region: string;
        phoneCode: number;
        secCode?: string | null;
        stateLabel: string;
        longitude: any;
        latitude: any;
        isoNumber: number;
        iso3: string;
        flagEmojiUnicode: string;
        flagEmoji: string;
        currencyCode: string;
        createdAt?: any | null;
        updatedAt?: any | null;
        isDeleted: boolean;
      } | null;
      kind?: {
        __typename?: 'CurrencyKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId?: any | null;
        groupId?: any | null;
        industryId?: any | null;
        subindustryId?: any | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    };
    primaryUser: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
    secondaryUser?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
    kind?: {
      __typename?: 'WalletKind';
      id: any;
      name: string;
      merchant?: boolean | null;
      organization?: boolean | null;
      user?: boolean | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  kind: {
    __typename?: 'TransactionKind';
    id: any;
    name: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    smartContractId: any;
    description?: string | null;
    subIndustry1Id?: any | null;
    subIndustry2Id?: any | null;
    smartContract: {
      __typename?: 'SmartContract';
      id: any;
      name: string;
      description: string;
      useCount: number;
      terms: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseId: any;
      smartContractKindId: any;
      clearinghouse: {
        __typename?: 'Clearinghouse';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        clearinghouseKindId: any;
        userId: any;
        kind: {
          __typename?: 'ClearinghouseKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId: any;
          groupId: any;
          industryId: any;
          subindustryId: any;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          group: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          industry: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          subindustry: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
        };
        user: {
          __typename?: 'User';
          email: any;
          emailVerified: boolean;
          preferences?: string | null;
          rememberToken: string;
          isAdmin: boolean;
          crwdsystemsApi?: string | null;
          createdAt: any;
          updatedAt: any;
          id: any;
          organizationId?: any | null;
          statusId?: any | null;
          isDeleted: boolean;
          sigmaPrimeId?: any | null;
          uniqueHash: string;
          countryCode?: string | null;
          organization?: { __typename?: 'Organization'; id: any; name: string } | null;
          status?: {
            __typename?: 'Status';
            id: any;
            description: string;
            kindId?: any | null;
            name: string;
            updatedAt: any;
            startDate?: any | null;
            endDate?: any | null;
            createdAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'StatusKind';
              id: any;
              name: string;
              description: string;
              updatedAt: any;
              createdAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sigmaPrime?: {
            __typename?: 'SigmaPrime';
            id: any;
            sigmaPrimeKindId?: any | null;
            crwdId?: string | null;
            personParent1SigmaId?: any | null;
            personParent2SigmaId?: any | null;
            personFirstName: string;
            personMiddleName?: string | null;
            personLastName?: string | null;
            personFamilyName?: string | null;
            personNameSuffix?: string | null;
            personHandleName?: any | null;
            personTitleName?: string | null;
            personPrimaryMobile?: string | null;
            personPrimaryPhone?: string | null;
            personPrimaryIpAddress?: string | null;
            personPrimaryEmail: any;
            personBusinessEmail?: any | null;
            personPrimaryFacePhoto?: string | null;
            personPrimaryKycStatus: string;
            personPrimaryAmlStatus?: string | null;
            personPrivacyPolicy: string;
            personStatus: string;
            personExchangeRequest: string;
            personUniqueHash: string;
            personPrimaryLanguageId?: string | null;
            aboutMe?: string | null;
            profilePhoto?: string | null;
            userId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'SigmaPrimeKind';
              id: any;
              name: string;
              description: string;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
            personParent1?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            personParent2?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            user?: { __typename?: 'User'; id: any; email: any } | null;
          } | null;
        };
      };
      kind: {
        __typename?: 'SmartContractKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        subindustryId: any;
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
    };
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  };
  currency?: { __typename?: 'Currency'; id: any; name: string } | null;
};

export type InteractionFieldsFragment = {
  __typename?: 'Interaction';
  id: any;
  liked: boolean;
  useCount: number;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  currencyId: any;
  merchantId: any;
  userId: any;
  merchant: {
    __typename?: 'Merchant';
    id: any;
    name: string;
    description?: string | null;
    crwdsystemsApi: string;
    notifyUrl?: string | null;
    uniqueHash: string;
    url?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    userId: any;
    statusId?: any | null;
    user: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  };
  currency: {
    __typename?: 'Currency';
    id: any;
    abbreviation?: string | null;
    authorized?: any | null;
    cc?: string | null;
    description?: string | null;
    reserved?: any | null;
    name: string;
    issued?: any | null;
    image?: string | null;
    symbol?: string | null;
    numericCode?: string | null;
    minorUnit?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    countryCode?: string | null;
    currencyKindId?: any | null;
    groupsId?: any | null;
    sectorId?: any | null;
    subindustryId?: any | null;
    country?: {
      __typename?: 'Country';
      code: string;
      name: string;
      region: string;
      phoneCode: number;
      secCode?: string | null;
      stateLabel: string;
      longitude: any;
      latitude: any;
      isoNumber: number;
      iso3: string;
      flagEmojiUnicode: string;
      flagEmoji: string;
      currencyCode: string;
      createdAt?: any | null;
      updatedAt?: any | null;
      isDeleted: boolean;
    } | null;
    kind?: {
      __typename?: 'CurrencyKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      gicsSectorId?: any | null;
      groupId?: any | null;
      industryId?: any | null;
      subindustryId?: any | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      imageGroup?: string | null;
      gicsSectorId: any;
      sicCodeId?: any | null;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    gicsSector?: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  };
  user: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  };
};

export type SettingFieldsFragment = {
  __typename?: 'Setting';
  id: any;
  key: string;
  value: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
};

export type TradeKindFieldsFragment = {
  __typename?: 'TradeKind';
  id: any;
  name: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
};

export type TradeFieldsFragment = {
  __typename?: 'Trade';
  id: any;
  name: string;
  description: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  clearinghouseId: any;
  tradeKindId: any;
  clearinghouse: {
    __typename?: 'Clearinghouse';
    id: any;
    name: string;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    clearinghouseKindId: any;
    userId: any;
    kind: {
      __typename?: 'ClearinghouseKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      gicsSectorId: any;
      groupId: any;
      industryId: any;
      subindustryId: any;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      group: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      industry: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      subindustry: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
    };
    user: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
  };
  kind: { __typename?: 'TradeKind'; id: any; name: string; createdAt: any; updatedAt: any; isDeleted: boolean };
};

export type AppraisalFieldsFragment = {
  __typename?: 'Appraisal';
  id: any;
  name: string;
  description: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  organizationId: any;
  smartContractId: any;
  userId: any;
  organization: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  };
  smartContract: {
    __typename?: 'SmartContract';
    id: any;
    name: string;
    description: string;
    useCount: number;
    terms: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    clearinghouseId: any;
    smartContractKindId: any;
    clearinghouse: {
      __typename?: 'Clearinghouse';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseKindId: any;
      userId: any;
      kind: {
        __typename?: 'ClearinghouseKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId: any;
        groupId: any;
        industryId: any;
        subindustryId: any;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        group: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        industry: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
      user: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
    };
    kind: {
      __typename?: 'SmartContractKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      subindustryId: any;
      subindustry: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
    };
  };
  user: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  };
};

export type PasswordResetFieldsFragment = {
  __typename?: 'PasswordReset';
  id: any;
  email: string;
  token: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
};

export type AssetKindFieldsFragment = {
  __typename?: 'AssetKind';
  id: any;
  name: string;
  description: string;
  images?: string | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  subindustryId: any;
  subindustry: {
    __typename?: 'Subindustry';
    id: any;
    name: string;
    description?: string | null;
    imageSubindustry?: string | null;
    parentIndustryId?: any | null;
    sicCodeId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    parentIndustry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  };
};

export type AssetFieldsFragment = {
  __typename?: 'Asset';
  appraisalId: any;
  assetAccountId?: any | null;
  assetKindId: any;
  category?: string | null;
  classification?: any | null;
  countryCode: string;
  createdAt: any;
  datasourceId?: any | null;
  description: string;
  dimensions: string;
  embedCode?: string | null;
  format?: string | null;
  id: any;
  images?: string | null;
  isDeleted: boolean;
  materialId?: any | null;
  materials: string;
  name: string;
  organizationId?: any | null;
  parentCategory?: string | null;
  sourceUrl?: string | null;
  sourceUserId?: any | null;
  subindustryId: any;
  uom: string;
  updatedAt: any;
  userId: any;
  productId?: any | null;
  authorized?: string | null;
  issued?: string | null;
  appraisal: {
    __typename?: 'Appraisal';
    id: any;
    name: string;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    organizationId: any;
    smartContractId: any;
    userId: any;
    organization: {
      __typename?: 'Organization';
      id: any;
      name: string;
      crwdsystemsApiReference?: string | null;
      organizationKindId?: any | null;
      organizationPrimaryAddress1?: string | null;
      organizationPrimaryAddress2?: string | null;
      organizationPrimaryCity?: string | null;
      organizationPrimaryState?: string | null;
      organizationPrimaryPostalCode?: string | null;
      organizationPrimaryRegion?: string | null;
      organizationPrimaryCountryCode?: string | null;
      organizationPrimaryPhone?: string | null;
      geocode?: string | null;
      crwdTicker?: string | null;
      organizationPersonStatus?: string | null;
      personUniqueHash?: string | null;
      personFirstName?: string | null;
      personMiddleName?: string | null;
      personLastName?: string | null;
      companyTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personBusinessEmail?: string | null;
      companyPrimaryWebsite?: string | null;
      companyEmployeeCount?: string | null;
      companyRevenue?: string | null;
      sicCodeId?: any | null;
      industryId?: any | null;
      sub1IndustryId?: any | null;
      sub2IndustryId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'OrganizationKind';
        id: any;
        name: string;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    };
    smartContract: {
      __typename?: 'SmartContract';
      id: any;
      name: string;
      description: string;
      useCount: number;
      terms: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseId: any;
      smartContractKindId: any;
      clearinghouse: {
        __typename?: 'Clearinghouse';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        clearinghouseKindId: any;
        userId: any;
        kind: {
          __typename?: 'ClearinghouseKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId: any;
          groupId: any;
          industryId: any;
          subindustryId: any;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          group: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          industry: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          subindustry: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
        };
        user: {
          __typename?: 'User';
          email: any;
          emailVerified: boolean;
          preferences?: string | null;
          rememberToken: string;
          isAdmin: boolean;
          crwdsystemsApi?: string | null;
          createdAt: any;
          updatedAt: any;
          id: any;
          organizationId?: any | null;
          statusId?: any | null;
          isDeleted: boolean;
          sigmaPrimeId?: any | null;
          uniqueHash: string;
          countryCode?: string | null;
          organization?: { __typename?: 'Organization'; id: any; name: string } | null;
          status?: {
            __typename?: 'Status';
            id: any;
            description: string;
            kindId?: any | null;
            name: string;
            updatedAt: any;
            startDate?: any | null;
            endDate?: any | null;
            createdAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'StatusKind';
              id: any;
              name: string;
              description: string;
              updatedAt: any;
              createdAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sigmaPrime?: {
            __typename?: 'SigmaPrime';
            id: any;
            sigmaPrimeKindId?: any | null;
            crwdId?: string | null;
            personParent1SigmaId?: any | null;
            personParent2SigmaId?: any | null;
            personFirstName: string;
            personMiddleName?: string | null;
            personLastName?: string | null;
            personFamilyName?: string | null;
            personNameSuffix?: string | null;
            personHandleName?: any | null;
            personTitleName?: string | null;
            personPrimaryMobile?: string | null;
            personPrimaryPhone?: string | null;
            personPrimaryIpAddress?: string | null;
            personPrimaryEmail: any;
            personBusinessEmail?: any | null;
            personPrimaryFacePhoto?: string | null;
            personPrimaryKycStatus: string;
            personPrimaryAmlStatus?: string | null;
            personPrivacyPolicy: string;
            personStatus: string;
            personExchangeRequest: string;
            personUniqueHash: string;
            personPrimaryLanguageId?: string | null;
            aboutMe?: string | null;
            profilePhoto?: string | null;
            userId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'SigmaPrimeKind';
              id: any;
              name: string;
              description: string;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
            personParent1?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            personParent2?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            user?: { __typename?: 'User'; id: any; email: any } | null;
          } | null;
        };
      };
      kind: {
        __typename?: 'SmartContractKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        subindustryId: any;
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
    };
    user: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
  };
  kind: {
    __typename?: 'AssetKind';
    id: any;
    name: string;
    description: string;
    images?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    subindustryId: any;
    subindustry: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
  };
  country: {
    __typename?: 'Country';
    code: string;
    name: string;
    region: string;
    phoneCode: number;
    secCode?: string | null;
    stateLabel: string;
    longitude: any;
    latitude: any;
    isoNumber: number;
    iso3: string;
    flagEmojiUnicode: string;
    flagEmoji: string;
    currencyCode: string;
    createdAt?: any | null;
    updatedAt?: any | null;
    isDeleted: boolean;
  };
  datasource?: {
    __typename?: 'Datasource';
    id: any;
    name: string;
    location?: string | null;
    organizationId?: any | null;
    governance?: string | null;
    pedigree?: string | null;
    authorizedUserId?: any | null;
    createdAt: any;
    updatedAt: any;
    organization?: {
      __typename?: 'Organization';
      id: any;
      name: string;
      crwdsystemsApiReference?: string | null;
      organizationKindId?: any | null;
      organizationPrimaryAddress1?: string | null;
      organizationPrimaryAddress2?: string | null;
      organizationPrimaryCity?: string | null;
      organizationPrimaryState?: string | null;
      organizationPrimaryPostalCode?: string | null;
      organizationPrimaryRegion?: string | null;
      organizationPrimaryCountryCode?: string | null;
      organizationPrimaryPhone?: string | null;
      geocode?: string | null;
      crwdTicker?: string | null;
      organizationPersonStatus?: string | null;
      personUniqueHash?: string | null;
      personFirstName?: string | null;
      personMiddleName?: string | null;
      personLastName?: string | null;
      companyTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personBusinessEmail?: string | null;
      companyPrimaryWebsite?: string | null;
      companyEmployeeCount?: string | null;
      companyRevenue?: string | null;
      sicCodeId?: any | null;
      industryId?: any | null;
      sub1IndustryId?: any | null;
      sub2IndustryId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'OrganizationKind';
        id: any;
        name: string;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    authorizedUser?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
  } | null;
  material?: {
    __typename?: 'Material';
    id: any;
    name: string;
    description: string;
    detailsUrl?: string | null;
    image?: string | null;
    isCommodity?: boolean | null;
    materialCategory: string;
    materialSubcategory?: string | null;
    materialType: string;
    symbol?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
  } | null;
  organization?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  sourceUser?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
  subindustry: {
    __typename?: 'Subindustry';
    id: any;
    name: string;
    description?: string | null;
    imageSubindustry?: string | null;
    parentIndustryId?: any | null;
    sicCodeId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    parentIndustry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  };
  user: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  };
  product?: { __typename?: 'Product'; id: any; name: string } | null;
};

export type PutDropDownFragment = { __typename?: 'Put'; id: any; name: string };

export type PutFieldsFragment = {
  __typename?: 'Put';
  id: any;
  name: string;
  amount: any;
  winningPut?: boolean | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  askId: any;
  currencyId: any;
  smartContractId: any;
  walletId: any;
  ask: { __typename?: 'Ask'; id: any; name: string };
  currency: { __typename?: 'Currency'; id: any; name: string };
  smartContract: { __typename?: 'SmartContract'; id: any; name: string };
  wallet: { __typename?: 'Wallet'; id: any; name: string };
};

export type ExchangeKindFieldsFragment = {
  __typename?: 'ExchangeKind';
  id: any;
  name: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
};

export type ExchangeFieldsFragment = {
  __typename?: 'Exchange';
  id: any;
  name: string;
  amount: any;
  description: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  exchangeKindId?: any | null;
  swapCurrencyId: any;
  tradeKindId: any;
  userId: any;
  kind?: {
    __typename?: 'ExchangeKind';
    id: any;
    name: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
  } | null;
  swapCurrency: {
    __typename?: 'Currency';
    id: any;
    abbreviation?: string | null;
    authorized?: any | null;
    cc?: string | null;
    description?: string | null;
    reserved?: any | null;
    name: string;
    issued?: any | null;
    image?: string | null;
    symbol?: string | null;
    numericCode?: string | null;
    minorUnit?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    countryCode?: string | null;
    currencyKindId?: any | null;
    groupsId?: any | null;
    sectorId?: any | null;
    subindustryId?: any | null;
    country?: {
      __typename?: 'Country';
      code: string;
      name: string;
      region: string;
      phoneCode: number;
      secCode?: string | null;
      stateLabel: string;
      longitude: any;
      latitude: any;
      isoNumber: number;
      iso3: string;
      flagEmojiUnicode: string;
      flagEmoji: string;
      currencyCode: string;
      createdAt?: any | null;
      updatedAt?: any | null;
      isDeleted: boolean;
    } | null;
    kind?: {
      __typename?: 'CurrencyKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      gicsSectorId?: any | null;
      groupId?: any | null;
      industryId?: any | null;
      subindustryId?: any | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      imageGroup?: string | null;
      gicsSectorId: any;
      sicCodeId?: any | null;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    gicsSector?: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  };
  tradeKind: { __typename?: 'TradeKind'; id: any; name: string; createdAt: any; updatedAt: any; isDeleted: boolean };
  user: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  };
};

export type AskDropDownFieldsFragment = { __typename?: 'Ask'; id: any; name: string };

export type AskFieldsFragment = {
  __typename?: 'Ask';
  id: any;
  name: string;
  startingAsk: any;
  description: string;
  askNumber: any;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  currencyId: any;
  exchangeId?: any | null;
  assetId: any;
  productId?: any | null;
  tradeId: any;
  walletId: any;
  image?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  totalAvailable?: any | null;
  firmPrice?: boolean | null;
  currency: {
    __typename?: 'Currency';
    id: any;
    abbreviation?: string | null;
    authorized?: any | null;
    cc?: string | null;
    description?: string | null;
    reserved?: any | null;
    name: string;
    issued?: any | null;
    image?: string | null;
    symbol?: string | null;
    numericCode?: string | null;
    minorUnit?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    countryCode?: string | null;
    currencyKindId?: any | null;
    groupsId?: any | null;
    sectorId?: any | null;
    subindustryId?: any | null;
    country?: {
      __typename?: 'Country';
      code: string;
      name: string;
      region: string;
      phoneCode: number;
      secCode?: string | null;
      stateLabel: string;
      longitude: any;
      latitude: any;
      isoNumber: number;
      iso3: string;
      flagEmojiUnicode: string;
      flagEmoji: string;
      currencyCode: string;
      createdAt?: any | null;
      updatedAt?: any | null;
      isDeleted: boolean;
    } | null;
    kind?: {
      __typename?: 'CurrencyKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      gicsSectorId?: any | null;
      groupId?: any | null;
      industryId?: any | null;
      subindustryId?: any | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      imageGroup?: string | null;
      gicsSectorId: any;
      sicCodeId?: any | null;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    gicsSector?: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  };
  exchange?: {
    __typename?: 'Exchange';
    id: any;
    name: string;
    amount: any;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    exchangeKindId?: any | null;
    swapCurrencyId: any;
    tradeKindId: any;
    userId: any;
    kind?: {
      __typename?: 'ExchangeKind';
      id: any;
      name: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    swapCurrency: {
      __typename?: 'Currency';
      id: any;
      abbreviation?: string | null;
      authorized?: any | null;
      cc?: string | null;
      description?: string | null;
      reserved?: any | null;
      name: string;
      issued?: any | null;
      image?: string | null;
      symbol?: string | null;
      numericCode?: string | null;
      minorUnit?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      countryCode?: string | null;
      currencyKindId?: any | null;
      groupsId?: any | null;
      sectorId?: any | null;
      subindustryId?: any | null;
      country?: {
        __typename?: 'Country';
        code: string;
        name: string;
        region: string;
        phoneCode: number;
        secCode?: string | null;
        stateLabel: string;
        longitude: any;
        latitude: any;
        isoNumber: number;
        iso3: string;
        flagEmojiUnicode: string;
        flagEmoji: string;
        currencyCode: string;
        createdAt?: any | null;
        updatedAt?: any | null;
        isDeleted: boolean;
      } | null;
      kind?: {
        __typename?: 'CurrencyKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId?: any | null;
        groupId?: any | null;
        industryId?: any | null;
        subindustryId?: any | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    };
    tradeKind: { __typename?: 'TradeKind'; id: any; name: string; createdAt: any; updatedAt: any; isDeleted: boolean };
    user: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
  } | null;
  asset: {
    __typename?: 'Asset';
    appraisalId: any;
    assetAccountId?: any | null;
    assetKindId: any;
    category?: string | null;
    classification?: any | null;
    countryCode: string;
    createdAt: any;
    datasourceId?: any | null;
    description: string;
    dimensions: string;
    embedCode?: string | null;
    format?: string | null;
    id: any;
    images?: string | null;
    isDeleted: boolean;
    materialId?: any | null;
    materials: string;
    name: string;
    organizationId?: any | null;
    parentCategory?: string | null;
    sourceUrl?: string | null;
    sourceUserId?: any | null;
    subindustryId: any;
    uom: string;
    updatedAt: any;
    userId: any;
    productId?: any | null;
    authorized?: string | null;
    issued?: string | null;
    appraisal: {
      __typename?: 'Appraisal';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      organizationId: any;
      smartContractId: any;
      userId: any;
      organization: {
        __typename?: 'Organization';
        id: any;
        name: string;
        crwdsystemsApiReference?: string | null;
        organizationKindId?: any | null;
        organizationPrimaryAddress1?: string | null;
        organizationPrimaryAddress2?: string | null;
        organizationPrimaryCity?: string | null;
        organizationPrimaryState?: string | null;
        organizationPrimaryPostalCode?: string | null;
        organizationPrimaryRegion?: string | null;
        organizationPrimaryCountryCode?: string | null;
        organizationPrimaryPhone?: string | null;
        geocode?: string | null;
        crwdTicker?: string | null;
        organizationPersonStatus?: string | null;
        personUniqueHash?: string | null;
        personFirstName?: string | null;
        personMiddleName?: string | null;
        personLastName?: string | null;
        companyTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personBusinessEmail?: string | null;
        companyPrimaryWebsite?: string | null;
        companyEmployeeCount?: string | null;
        companyRevenue?: string | null;
        sicCodeId?: any | null;
        industryId?: any | null;
        sub1IndustryId?: any | null;
        sub2IndustryId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'OrganizationKind';
          id: any;
          name: string;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry1?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry2?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      };
      smartContract: {
        __typename?: 'SmartContract';
        id: any;
        name: string;
        description: string;
        useCount: number;
        terms: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        clearinghouseId: any;
        smartContractKindId: any;
        clearinghouse: {
          __typename?: 'Clearinghouse';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          clearinghouseKindId: any;
          userId: any;
          kind: {
            __typename?: 'ClearinghouseKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            gicsSectorId: any;
            groupId: any;
            industryId: any;
            subindustryId: any;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            group: {
              __typename?: 'Group';
              id: any;
              name: string;
              description?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              imageGroup?: string | null;
              gicsSectorId: any;
              sicCodeId?: any | null;
              gicsSector: {
                __typename?: 'GicsSector';
                id: any;
                name: string;
                description?: string | null;
                imageSector?: string | null;
                updatedAt: any;
                isDeleted: boolean;
                createdAt: any;
                sicCodeId?: any | null;
                sicCode?: {
                  __typename?: 'SicCode';
                  id: any;
                  code: number;
                  name: string;
                  sicCodeOffice?: string | null;
                  gicsCodeName?: string | null;
                  gicsCode?: number | null;
                  createdAt: any;
                  updatedAt: any;
                  isDeleted: boolean;
                } | null;
              };
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            industry: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            subindustry: {
              __typename?: 'Subindustry';
              id: any;
              name: string;
              description?: string | null;
              imageSubindustry?: string | null;
              parentIndustryId?: any | null;
              sicCodeId?: any | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              parentIndustry?: {
                __typename?: 'Industry';
                id: any;
                name: string;
                description?: string | null;
                imageIndustry?: string | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
                groupId?: any | null;
                sicCodeId?: any | null;
                group?: { __typename?: 'Group'; name: string } | null;
                sicCode?: {
                  __typename?: 'SicCode';
                  id: any;
                  code: number;
                  name: string;
                  sicCodeOffice?: string | null;
                  gicsCodeName?: string | null;
                  gicsCode?: number | null;
                  createdAt: any;
                  updatedAt: any;
                  isDeleted: boolean;
                } | null;
              } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
          };
          user: {
            __typename?: 'User';
            email: any;
            emailVerified: boolean;
            preferences?: string | null;
            rememberToken: string;
            isAdmin: boolean;
            crwdsystemsApi?: string | null;
            createdAt: any;
            updatedAt: any;
            id: any;
            organizationId?: any | null;
            statusId?: any | null;
            isDeleted: boolean;
            sigmaPrimeId?: any | null;
            uniqueHash: string;
            countryCode?: string | null;
            organization?: { __typename?: 'Organization'; id: any; name: string } | null;
            status?: {
              __typename?: 'Status';
              id: any;
              description: string;
              kindId?: any | null;
              name: string;
              updatedAt: any;
              startDate?: any | null;
              endDate?: any | null;
              createdAt: any;
              isDeleted: boolean;
              kind?: {
                __typename?: 'StatusKind';
                id: any;
                name: string;
                description: string;
                updatedAt: any;
                createdAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sigmaPrime?: {
              __typename?: 'SigmaPrime';
              id: any;
              sigmaPrimeKindId?: any | null;
              crwdId?: string | null;
              personParent1SigmaId?: any | null;
              personParent2SigmaId?: any | null;
              personFirstName: string;
              personMiddleName?: string | null;
              personLastName?: string | null;
              personFamilyName?: string | null;
              personNameSuffix?: string | null;
              personHandleName?: any | null;
              personTitleName?: string | null;
              personPrimaryMobile?: string | null;
              personPrimaryPhone?: string | null;
              personPrimaryIpAddress?: string | null;
              personPrimaryEmail: any;
              personBusinessEmail?: any | null;
              personPrimaryFacePhoto?: string | null;
              personPrimaryKycStatus: string;
              personPrimaryAmlStatus?: string | null;
              personPrivacyPolicy: string;
              personStatus: string;
              personExchangeRequest: string;
              personUniqueHash: string;
              personPrimaryLanguageId?: string | null;
              aboutMe?: string | null;
              profilePhoto?: string | null;
              userId?: any | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              kind?: {
                __typename?: 'SigmaPrimeKind';
                id: any;
                name: string;
                description: string;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
              personParent1?: {
                __typename?: 'SigmaPrime';
                id: any;
                crwdId?: string | null;
                personFirstName: string;
                personLastName?: string | null;
              } | null;
              personParent2?: {
                __typename?: 'SigmaPrime';
                id: any;
                crwdId?: string | null;
                personFirstName: string;
                personLastName?: string | null;
              } | null;
              user?: { __typename?: 'User'; id: any; email: any } | null;
            } | null;
          };
        };
        kind: {
          __typename?: 'SmartContractKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          subindustryId: any;
          subindustry: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
        };
      };
      user: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
    };
    kind: {
      __typename?: 'AssetKind';
      id: any;
      name: string;
      description: string;
      images?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      subindustryId: any;
      subindustry: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
    };
    country: {
      __typename?: 'Country';
      code: string;
      name: string;
      region: string;
      phoneCode: number;
      secCode?: string | null;
      stateLabel: string;
      longitude: any;
      latitude: any;
      isoNumber: number;
      iso3: string;
      flagEmojiUnicode: string;
      flagEmoji: string;
      currencyCode: string;
      createdAt?: any | null;
      updatedAt?: any | null;
      isDeleted: boolean;
    };
    datasource?: {
      __typename?: 'Datasource';
      id: any;
      name: string;
      location?: string | null;
      organizationId?: any | null;
      governance?: string | null;
      pedigree?: string | null;
      authorizedUserId?: any | null;
      createdAt: any;
      updatedAt: any;
      organization?: {
        __typename?: 'Organization';
        id: any;
        name: string;
        crwdsystemsApiReference?: string | null;
        organizationKindId?: any | null;
        organizationPrimaryAddress1?: string | null;
        organizationPrimaryAddress2?: string | null;
        organizationPrimaryCity?: string | null;
        organizationPrimaryState?: string | null;
        organizationPrimaryPostalCode?: string | null;
        organizationPrimaryRegion?: string | null;
        organizationPrimaryCountryCode?: string | null;
        organizationPrimaryPhone?: string | null;
        geocode?: string | null;
        crwdTicker?: string | null;
        organizationPersonStatus?: string | null;
        personUniqueHash?: string | null;
        personFirstName?: string | null;
        personMiddleName?: string | null;
        personLastName?: string | null;
        companyTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personBusinessEmail?: string | null;
        companyPrimaryWebsite?: string | null;
        companyEmployeeCount?: string | null;
        companyRevenue?: string | null;
        sicCodeId?: any | null;
        industryId?: any | null;
        sub1IndustryId?: any | null;
        sub2IndustryId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'OrganizationKind';
          id: any;
          name: string;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry1?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry2?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      authorizedUser?: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      } | null;
    } | null;
    material?: {
      __typename?: 'Material';
      id: any;
      name: string;
      description: string;
      detailsUrl?: string | null;
      image?: string | null;
      isCommodity?: boolean | null;
      materialCategory: string;
      materialSubcategory?: string | null;
      materialType: string;
      symbol?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    organization?: {
      __typename?: 'Organization';
      id: any;
      name: string;
      crwdsystemsApiReference?: string | null;
      organizationKindId?: any | null;
      organizationPrimaryAddress1?: string | null;
      organizationPrimaryAddress2?: string | null;
      organizationPrimaryCity?: string | null;
      organizationPrimaryState?: string | null;
      organizationPrimaryPostalCode?: string | null;
      organizationPrimaryRegion?: string | null;
      organizationPrimaryCountryCode?: string | null;
      organizationPrimaryPhone?: string | null;
      geocode?: string | null;
      crwdTicker?: string | null;
      organizationPersonStatus?: string | null;
      personUniqueHash?: string | null;
      personFirstName?: string | null;
      personMiddleName?: string | null;
      personLastName?: string | null;
      companyTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personBusinessEmail?: string | null;
      companyPrimaryWebsite?: string | null;
      companyEmployeeCount?: string | null;
      companyRevenue?: string | null;
      sicCodeId?: any | null;
      industryId?: any | null;
      sub1IndustryId?: any | null;
      sub2IndustryId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'OrganizationKind';
        id: any;
        name: string;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    sourceUser?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
    subindustry: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
    user: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
    product?: { __typename?: 'Product'; id: any; name: string } | null;
  };
  product?: {
    __typename?: 'Product';
    id: any;
    actionUrlActivate?: string | null;
    actionUrlDeactivate?: string | null;
    assetId?: any | null;
    binId?: number | null;
    caliberString?: string | null;
    categories?: string | null;
    categoryNumber?: string | null;
    ceNumber?: string | null;
    chainNumberOfDevices?: string | null;
    contentList?: string | null;
    distanceUom?: string | null;
    duration?: string | null;
    effectHeight?: string | null;
    effectWidth?: string | null;
    exNumberList?: string | null;
    facebookReferenceId?: string | null;
    glAccountList?: string | null;
    googleReferenceId?: string | null;
    images?: string | null;
    internalName?: string | null;
    itemCost?: any | null;
    longDescription?: string | null;
    manufacturerBrand?: string | null;
    manufacturerProductId?: any | null;
    manufacturerOrgId?: any | null;
    marketplaces?: string | null;
    markets?: string | null;
    materialId?: any | null;
    metaDescription?: string | null;
    metaKeywords?: string | null;
    metaTitle?: string | null;
    name: string;
    numberPackedItems?: string | null;
    price: any;
    productKindId?: any | null;
    productStatusId?: any | null;
    qtyUom?: string | null;
    reorderSmartContractId?: any | null;
    shops?: string | null;
    sicCodeId?: any | null;
    sku: string;
    smartContractId?: any | null;
    subindustry1Id?: any | null;
    subindustry2Id?: any | null;
    supplierOrgListId?: any | null;
    unHazMatId?: string | null;
    size?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    asset?: { __typename?: 'Asset'; id: any; name: string } | null;
    manufacturerProduct?: { __typename?: 'Product'; id: any; name: string } | null;
    manufacturerOrg?: { __typename?: 'Organization'; id: any; name: string } | null;
    material?: { __typename?: 'Material'; id: any; name: string } | null;
    kind?: { __typename?: 'ProductKind'; id: any; name: string } | null;
    status?: { __typename?: 'ProductStatus'; id: any; name: string } | null;
    reorderSmartContract?: { __typename?: 'SmartContract'; id: any; name: string } | null;
    sicCode?: { __typename?: 'SicCode'; id: any; code: number; name: string } | null;
    smartContract?: { __typename?: 'SmartContract'; id: any; name: string } | null;
    subindustry1?: { __typename?: 'Subindustry'; id: any; name: string } | null;
    subindustry2?: { __typename?: 'Subindustry'; id: any; name: string } | null;
  } | null;
  trade: {
    __typename?: 'Trade';
    id: any;
    name: string;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    clearinghouseId: any;
    tradeKindId: any;
    clearinghouse: {
      __typename?: 'Clearinghouse';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseKindId: any;
      userId: any;
      kind: {
        __typename?: 'ClearinghouseKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId: any;
        groupId: any;
        industryId: any;
        subindustryId: any;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        group: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        industry: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
      user: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
    };
    kind: { __typename?: 'TradeKind'; id: any; name: string; createdAt: any; updatedAt: any; isDeleted: boolean };
  };
  wallet: {
    __typename?: 'Wallet';
    id: any;
    name: string;
    walletAddress: string;
    primaryHash: string;
    secondaryHash?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    defaultCurrencyId: any;
    primaryUserId: any;
    secondaryUserId?: any | null;
    walletKindId?: any | null;
    defaultCurrency: {
      __typename?: 'Currency';
      id: any;
      abbreviation?: string | null;
      authorized?: any | null;
      cc?: string | null;
      description?: string | null;
      reserved?: any | null;
      name: string;
      issued?: any | null;
      image?: string | null;
      symbol?: string | null;
      numericCode?: string | null;
      minorUnit?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      countryCode?: string | null;
      currencyKindId?: any | null;
      groupsId?: any | null;
      sectorId?: any | null;
      subindustryId?: any | null;
      country?: {
        __typename?: 'Country';
        code: string;
        name: string;
        region: string;
        phoneCode: number;
        secCode?: string | null;
        stateLabel: string;
        longitude: any;
        latitude: any;
        isoNumber: number;
        iso3: string;
        flagEmojiUnicode: string;
        flagEmoji: string;
        currencyCode: string;
        createdAt?: any | null;
        updatedAt?: any | null;
        isDeleted: boolean;
      } | null;
      kind?: {
        __typename?: 'CurrencyKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId?: any | null;
        groupId?: any | null;
        industryId?: any | null;
        subindustryId?: any | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    };
    primaryUser: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
    secondaryUser?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
    kind?: {
      __typename?: 'WalletKind';
      id: any;
      name: string;
      merchant?: boolean | null;
      organization?: boolean | null;
      user?: boolean | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  };
};

export type AskPutTransactionFieldsFragment = {
  __typename?: 'AskPutTransaction';
  id: any;
  askId: any;
  putId: any;
  transactionId: any;
  description: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  ask?: { __typename?: 'Ask'; id: any; name: string } | null;
  put?: { __typename?: 'Put'; id: any; name: string } | null;
  transaction?: {
    __typename?: 'Transaction';
    id: any;
    txnId: string;
    networkFee: any;
    destTag?: string | null;
    confirmation?: string | null;
    amount: any;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    fromWalletId?: any | null;
    paymentId: any;
    smartContractId: any;
    statusId: any;
    toWalletId?: any | null;
    transactionKindId: any;
    currencyId: any;
    fromWallet?: {
      __typename?: 'Wallet';
      id: any;
      name: string;
      walletAddress: string;
      primaryHash: string;
      secondaryHash?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      defaultCurrencyId: any;
      primaryUserId: any;
      secondaryUserId?: any | null;
      walletKindId?: any | null;
      defaultCurrency: {
        __typename?: 'Currency';
        id: any;
        abbreviation?: string | null;
        authorized?: any | null;
        cc?: string | null;
        description?: string | null;
        reserved?: any | null;
        name: string;
        issued?: any | null;
        image?: string | null;
        symbol?: string | null;
        numericCode?: string | null;
        minorUnit?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        countryCode?: string | null;
        currencyKindId?: any | null;
        groupsId?: any | null;
        sectorId?: any | null;
        subindustryId?: any | null;
        country?: {
          __typename?: 'Country';
          code: string;
          name: string;
          region: string;
          phoneCode: number;
          secCode?: string | null;
          stateLabel: string;
          longitude: any;
          latitude: any;
          isoNumber: number;
          iso3: string;
          flagEmojiUnicode: string;
          flagEmoji: string;
          currencyCode: string;
          createdAt?: any | null;
          updatedAt?: any | null;
          isDeleted: boolean;
        } | null;
        kind?: {
          __typename?: 'CurrencyKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId?: any | null;
          groupId?: any | null;
          industryId?: any | null;
          subindustryId?: any | null;
          gicsSector?: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          group?: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          industry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          subindustry?: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      };
      primaryUser: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
      secondaryUser?: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      } | null;
      kind?: {
        __typename?: 'WalletKind';
        id: any;
        name: string;
        merchant?: boolean | null;
        organization?: boolean | null;
        user?: boolean | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    payment: {
      __typename?: 'Payment';
      id: any;
      orderId?: any | null;
      amountCrwdunit?: any | null;
      amountCurrency?: any | null;
      amountUsd?: number | null;
      paymentDetails: string;
      receiveWalletTxn: string;
      sendWalletTxn: string;
      successUrl: string;
      cancelUrl: string;
      comment?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      currencyId?: any | null;
      userId: any;
      statusId?: any | null;
      askId?: any | null;
      putId?: any | null;
      assetAccountId?: any | null;
      currency?: {
        __typename?: 'Currency';
        id: any;
        abbreviation?: string | null;
        authorized?: any | null;
        cc?: string | null;
        description?: string | null;
        reserved?: any | null;
        name: string;
        issued?: any | null;
        image?: string | null;
        symbol?: string | null;
        numericCode?: string | null;
        minorUnit?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        countryCode?: string | null;
        currencyKindId?: any | null;
        groupsId?: any | null;
        sectorId?: any | null;
        subindustryId?: any | null;
        country?: {
          __typename?: 'Country';
          code: string;
          name: string;
          region: string;
          phoneCode: number;
          secCode?: string | null;
          stateLabel: string;
          longitude: any;
          latitude: any;
          isoNumber: number;
          iso3: string;
          flagEmojiUnicode: string;
          flagEmoji: string;
          currencyCode: string;
          createdAt?: any | null;
          updatedAt?: any | null;
          isDeleted: boolean;
        } | null;
        kind?: {
          __typename?: 'CurrencyKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId?: any | null;
          groupId?: any | null;
          industryId?: any | null;
          subindustryId?: any | null;
          gicsSector?: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          group?: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          industry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          subindustry?: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      user: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
      status?: { __typename?: 'Status'; id: any; name: string } | null;
      ask?: { __typename?: 'Ask'; id: any; name: string } | null;
      put?: { __typename?: 'Put'; id: any; name: string } | null;
      assetAccount?: {
        __typename?: 'AssetAccount';
        id: any;
        accountNumber?: number | null;
        availableAt: any;
        cvv: string;
        expirationDate: any;
        ibanNumber: string;
        issueDate: any;
        organizationId?: any | null;
        routingNumber: string;
        securityCode: string;
        swiftCode: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        userId?: any | null;
        balanceAmount?: any | null;
        accountKind?: string | null;
        accountName?: string | null;
        achNumber?: string | null;
        ccNumber?: string | null;
        organization?: {
          __typename?: 'Organization';
          id: any;
          name: string;
          crwdsystemsApiReference?: string | null;
          organizationKindId?: any | null;
          organizationPrimaryAddress1?: string | null;
          organizationPrimaryAddress2?: string | null;
          organizationPrimaryCity?: string | null;
          organizationPrimaryState?: string | null;
          organizationPrimaryPostalCode?: string | null;
          organizationPrimaryRegion?: string | null;
          organizationPrimaryCountryCode?: string | null;
          organizationPrimaryPhone?: string | null;
          geocode?: string | null;
          crwdTicker?: string | null;
          organizationPersonStatus?: string | null;
          personUniqueHash?: string | null;
          personFirstName?: string | null;
          personMiddleName?: string | null;
          personLastName?: string | null;
          companyTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personBusinessEmail?: string | null;
          companyPrimaryWebsite?: string | null;
          companyEmployeeCount?: string | null;
          companyRevenue?: string | null;
          sicCodeId?: any | null;
          industryId?: any | null;
          sub1IndustryId?: any | null;
          sub2IndustryId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'OrganizationKind';
            id: any;
            name: string;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          industry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          subindustry1?: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          subindustry2?: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
        } | null;
        user?: {
          __typename?: 'User';
          email: any;
          emailVerified: boolean;
          preferences?: string | null;
          rememberToken: string;
          isAdmin: boolean;
          crwdsystemsApi?: string | null;
          createdAt: any;
          updatedAt: any;
          id: any;
          organizationId?: any | null;
          statusId?: any | null;
          isDeleted: boolean;
          sigmaPrimeId?: any | null;
          uniqueHash: string;
          countryCode?: string | null;
          organization?: { __typename?: 'Organization'; id: any; name: string } | null;
          status?: {
            __typename?: 'Status';
            id: any;
            description: string;
            kindId?: any | null;
            name: string;
            updatedAt: any;
            startDate?: any | null;
            endDate?: any | null;
            createdAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'StatusKind';
              id: any;
              name: string;
              description: string;
              updatedAt: any;
              createdAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sigmaPrime?: {
            __typename?: 'SigmaPrime';
            id: any;
            sigmaPrimeKindId?: any | null;
            crwdId?: string | null;
            personParent1SigmaId?: any | null;
            personParent2SigmaId?: any | null;
            personFirstName: string;
            personMiddleName?: string | null;
            personLastName?: string | null;
            personFamilyName?: string | null;
            personNameSuffix?: string | null;
            personHandleName?: any | null;
            personTitleName?: string | null;
            personPrimaryMobile?: string | null;
            personPrimaryPhone?: string | null;
            personPrimaryIpAddress?: string | null;
            personPrimaryEmail: any;
            personBusinessEmail?: any | null;
            personPrimaryFacePhoto?: string | null;
            personPrimaryKycStatus: string;
            personPrimaryAmlStatus?: string | null;
            personPrivacyPolicy: string;
            personStatus: string;
            personExchangeRequest: string;
            personUniqueHash: string;
            personPrimaryLanguageId?: string | null;
            aboutMe?: string | null;
            profilePhoto?: string | null;
            userId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'SigmaPrimeKind';
              id: any;
              name: string;
              description: string;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
            personParent1?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            personParent2?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            user?: { __typename?: 'User'; id: any; email: any } | null;
          } | null;
        } | null;
      } | null;
    };
    smartContract: {
      __typename?: 'SmartContract';
      id: any;
      name: string;
      description: string;
      useCount: number;
      terms: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseId: any;
      smartContractKindId: any;
      clearinghouse: {
        __typename?: 'Clearinghouse';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        clearinghouseKindId: any;
        userId: any;
        kind: {
          __typename?: 'ClearinghouseKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId: any;
          groupId: any;
          industryId: any;
          subindustryId: any;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          group: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          industry: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          subindustry: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
        };
        user: {
          __typename?: 'User';
          email: any;
          emailVerified: boolean;
          preferences?: string | null;
          rememberToken: string;
          isAdmin: boolean;
          crwdsystemsApi?: string | null;
          createdAt: any;
          updatedAt: any;
          id: any;
          organizationId?: any | null;
          statusId?: any | null;
          isDeleted: boolean;
          sigmaPrimeId?: any | null;
          uniqueHash: string;
          countryCode?: string | null;
          organization?: { __typename?: 'Organization'; id: any; name: string } | null;
          status?: {
            __typename?: 'Status';
            id: any;
            description: string;
            kindId?: any | null;
            name: string;
            updatedAt: any;
            startDate?: any | null;
            endDate?: any | null;
            createdAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'StatusKind';
              id: any;
              name: string;
              description: string;
              updatedAt: any;
              createdAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sigmaPrime?: {
            __typename?: 'SigmaPrime';
            id: any;
            sigmaPrimeKindId?: any | null;
            crwdId?: string | null;
            personParent1SigmaId?: any | null;
            personParent2SigmaId?: any | null;
            personFirstName: string;
            personMiddleName?: string | null;
            personLastName?: string | null;
            personFamilyName?: string | null;
            personNameSuffix?: string | null;
            personHandleName?: any | null;
            personTitleName?: string | null;
            personPrimaryMobile?: string | null;
            personPrimaryPhone?: string | null;
            personPrimaryIpAddress?: string | null;
            personPrimaryEmail: any;
            personBusinessEmail?: any | null;
            personPrimaryFacePhoto?: string | null;
            personPrimaryKycStatus: string;
            personPrimaryAmlStatus?: string | null;
            personPrivacyPolicy: string;
            personStatus: string;
            personExchangeRequest: string;
            personUniqueHash: string;
            personPrimaryLanguageId?: string | null;
            aboutMe?: string | null;
            profilePhoto?: string | null;
            userId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'SigmaPrimeKind';
              id: any;
              name: string;
              description: string;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
            personParent1?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            personParent2?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            user?: { __typename?: 'User'; id: any; email: any } | null;
          } | null;
        };
      };
      kind: {
        __typename?: 'SmartContractKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        subindustryId: any;
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
    };
    status: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    };
    toWallet?: {
      __typename?: 'Wallet';
      id: any;
      name: string;
      walletAddress: string;
      primaryHash: string;
      secondaryHash?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      defaultCurrencyId: any;
      primaryUserId: any;
      secondaryUserId?: any | null;
      walletKindId?: any | null;
      defaultCurrency: {
        __typename?: 'Currency';
        id: any;
        abbreviation?: string | null;
        authorized?: any | null;
        cc?: string | null;
        description?: string | null;
        reserved?: any | null;
        name: string;
        issued?: any | null;
        image?: string | null;
        symbol?: string | null;
        numericCode?: string | null;
        minorUnit?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        countryCode?: string | null;
        currencyKindId?: any | null;
        groupsId?: any | null;
        sectorId?: any | null;
        subindustryId?: any | null;
        country?: {
          __typename?: 'Country';
          code: string;
          name: string;
          region: string;
          phoneCode: number;
          secCode?: string | null;
          stateLabel: string;
          longitude: any;
          latitude: any;
          isoNumber: number;
          iso3: string;
          flagEmojiUnicode: string;
          flagEmoji: string;
          currencyCode: string;
          createdAt?: any | null;
          updatedAt?: any | null;
          isDeleted: boolean;
        } | null;
        kind?: {
          __typename?: 'CurrencyKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId?: any | null;
          groupId?: any | null;
          industryId?: any | null;
          subindustryId?: any | null;
          gicsSector?: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          group?: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          industry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          subindustry?: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      };
      primaryUser: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
      secondaryUser?: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      } | null;
      kind?: {
        __typename?: 'WalletKind';
        id: any;
        name: string;
        merchant?: boolean | null;
        organization?: boolean | null;
        user?: boolean | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    kind: {
      __typename?: 'TransactionKind';
      id: any;
      name: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      smartContractId: any;
      description?: string | null;
      subIndustry1Id?: any | null;
      subIndustry2Id?: any | null;
      smartContract: {
        __typename?: 'SmartContract';
        id: any;
        name: string;
        description: string;
        useCount: number;
        terms: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        clearinghouseId: any;
        smartContractKindId: any;
        clearinghouse: {
          __typename?: 'Clearinghouse';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          clearinghouseKindId: any;
          userId: any;
          kind: {
            __typename?: 'ClearinghouseKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            gicsSectorId: any;
            groupId: any;
            industryId: any;
            subindustryId: any;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            group: {
              __typename?: 'Group';
              id: any;
              name: string;
              description?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              imageGroup?: string | null;
              gicsSectorId: any;
              sicCodeId?: any | null;
              gicsSector: {
                __typename?: 'GicsSector';
                id: any;
                name: string;
                description?: string | null;
                imageSector?: string | null;
                updatedAt: any;
                isDeleted: boolean;
                createdAt: any;
                sicCodeId?: any | null;
                sicCode?: {
                  __typename?: 'SicCode';
                  id: any;
                  code: number;
                  name: string;
                  sicCodeOffice?: string | null;
                  gicsCodeName?: string | null;
                  gicsCode?: number | null;
                  createdAt: any;
                  updatedAt: any;
                  isDeleted: boolean;
                } | null;
              };
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            industry: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            subindustry: {
              __typename?: 'Subindustry';
              id: any;
              name: string;
              description?: string | null;
              imageSubindustry?: string | null;
              parentIndustryId?: any | null;
              sicCodeId?: any | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              parentIndustry?: {
                __typename?: 'Industry';
                id: any;
                name: string;
                description?: string | null;
                imageIndustry?: string | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
                groupId?: any | null;
                sicCodeId?: any | null;
                group?: { __typename?: 'Group'; name: string } | null;
                sicCode?: {
                  __typename?: 'SicCode';
                  id: any;
                  code: number;
                  name: string;
                  sicCodeOffice?: string | null;
                  gicsCodeName?: string | null;
                  gicsCode?: number | null;
                  createdAt: any;
                  updatedAt: any;
                  isDeleted: boolean;
                } | null;
              } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
          };
          user: {
            __typename?: 'User';
            email: any;
            emailVerified: boolean;
            preferences?: string | null;
            rememberToken: string;
            isAdmin: boolean;
            crwdsystemsApi?: string | null;
            createdAt: any;
            updatedAt: any;
            id: any;
            organizationId?: any | null;
            statusId?: any | null;
            isDeleted: boolean;
            sigmaPrimeId?: any | null;
            uniqueHash: string;
            countryCode?: string | null;
            organization?: { __typename?: 'Organization'; id: any; name: string } | null;
            status?: {
              __typename?: 'Status';
              id: any;
              description: string;
              kindId?: any | null;
              name: string;
              updatedAt: any;
              startDate?: any | null;
              endDate?: any | null;
              createdAt: any;
              isDeleted: boolean;
              kind?: {
                __typename?: 'StatusKind';
                id: any;
                name: string;
                description: string;
                updatedAt: any;
                createdAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sigmaPrime?: {
              __typename?: 'SigmaPrime';
              id: any;
              sigmaPrimeKindId?: any | null;
              crwdId?: string | null;
              personParent1SigmaId?: any | null;
              personParent2SigmaId?: any | null;
              personFirstName: string;
              personMiddleName?: string | null;
              personLastName?: string | null;
              personFamilyName?: string | null;
              personNameSuffix?: string | null;
              personHandleName?: any | null;
              personTitleName?: string | null;
              personPrimaryMobile?: string | null;
              personPrimaryPhone?: string | null;
              personPrimaryIpAddress?: string | null;
              personPrimaryEmail: any;
              personBusinessEmail?: any | null;
              personPrimaryFacePhoto?: string | null;
              personPrimaryKycStatus: string;
              personPrimaryAmlStatus?: string | null;
              personPrivacyPolicy: string;
              personStatus: string;
              personExchangeRequest: string;
              personUniqueHash: string;
              personPrimaryLanguageId?: string | null;
              aboutMe?: string | null;
              profilePhoto?: string | null;
              userId?: any | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              kind?: {
                __typename?: 'SigmaPrimeKind';
                id: any;
                name: string;
                description: string;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
              personParent1?: {
                __typename?: 'SigmaPrime';
                id: any;
                crwdId?: string | null;
                personFirstName: string;
                personLastName?: string | null;
              } | null;
              personParent2?: {
                __typename?: 'SigmaPrime';
                id: any;
                crwdId?: string | null;
                personFirstName: string;
                personLastName?: string | null;
              } | null;
              user?: { __typename?: 'User'; id: any; email: any } | null;
            } | null;
          };
        };
        kind: {
          __typename?: 'SmartContractKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          subindustryId: any;
          subindustry: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
        };
      };
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    };
    currency?: { __typename?: 'Currency'; id: any; name: string } | null;
  } | null;
};

export type SmartContractLineageFieldsFragment = {
  __typename?: 'SmartContractLineage';
  id: any;
  createdAt: any;
  name: string;
  lineageComments: string;
  parentUserId?: any | null;
  smartContractId: any;
  updatedAt: any;
  userId: any;
  isDeleted?: boolean | null;
  organizationId?: any | null;
  parentOrganizationId?: any | null;
  parentUser?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
  user?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
  organization?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  parentOrganization?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
};

export type ProductStatusFieldsFragment = {
  __typename?: 'ProductStatus';
  id: any;
  description: string;
  createdAt: any;
  name: string;
  updatedAt: any;
  isDeleted?: boolean | null;
};

export type DocumentUserFieldsFragment = {
  __typename?: 'DocumentUser';
  createdAt: any;
  documentLocation?: string | null;
  id: any;
  isDeleted?: boolean | null;
  smartContractDocumentId: any;
  userId: any;
  walletId: any;
  smartContractDocument?: {
    __typename?: 'SmartContractDocument';
    id: any;
    name: string;
    documentSource?: string | null;
    datasourceId?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    isDeleted?: boolean | null;
    datasource?: {
      __typename?: 'Datasource';
      id: any;
      name: string;
      location?: string | null;
      organizationId?: any | null;
      governance?: string | null;
      pedigree?: string | null;
      authorizedUserId?: any | null;
      createdAt: any;
      updatedAt: any;
      organization?: {
        __typename?: 'Organization';
        id: any;
        name: string;
        crwdsystemsApiReference?: string | null;
        organizationKindId?: any | null;
        organizationPrimaryAddress1?: string | null;
        organizationPrimaryAddress2?: string | null;
        organizationPrimaryCity?: string | null;
        organizationPrimaryState?: string | null;
        organizationPrimaryPostalCode?: string | null;
        organizationPrimaryRegion?: string | null;
        organizationPrimaryCountryCode?: string | null;
        organizationPrimaryPhone?: string | null;
        geocode?: string | null;
        crwdTicker?: string | null;
        organizationPersonStatus?: string | null;
        personUniqueHash?: string | null;
        personFirstName?: string | null;
        personMiddleName?: string | null;
        personLastName?: string | null;
        companyTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personBusinessEmail?: string | null;
        companyPrimaryWebsite?: string | null;
        companyEmployeeCount?: string | null;
        companyRevenue?: string | null;
        sicCodeId?: any | null;
        industryId?: any | null;
        sub1IndustryId?: any | null;
        sub2IndustryId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'OrganizationKind';
          id: any;
          name: string;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry1?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry2?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      authorizedUser?: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  user?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
  wallet?: {
    __typename?: 'Wallet';
    id: any;
    name: string;
    walletAddress: string;
    primaryHash: string;
    secondaryHash?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    defaultCurrencyId: any;
    primaryUserId: any;
    secondaryUserId?: any | null;
    walletKindId?: any | null;
    defaultCurrency: {
      __typename?: 'Currency';
      id: any;
      abbreviation?: string | null;
      authorized?: any | null;
      cc?: string | null;
      description?: string | null;
      reserved?: any | null;
      name: string;
      issued?: any | null;
      image?: string | null;
      symbol?: string | null;
      numericCode?: string | null;
      minorUnit?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      countryCode?: string | null;
      currencyKindId?: any | null;
      groupsId?: any | null;
      sectorId?: any | null;
      subindustryId?: any | null;
      country?: {
        __typename?: 'Country';
        code: string;
        name: string;
        region: string;
        phoneCode: number;
        secCode?: string | null;
        stateLabel: string;
        longitude: any;
        latitude: any;
        isoNumber: number;
        iso3: string;
        flagEmojiUnicode: string;
        flagEmoji: string;
        currencyCode: string;
        createdAt?: any | null;
        updatedAt?: any | null;
        isDeleted: boolean;
      } | null;
      kind?: {
        __typename?: 'CurrencyKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId?: any | null;
        groupId?: any | null;
        industryId?: any | null;
        subindustryId?: any | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    };
    primaryUser: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
    secondaryUser?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
    kind?: {
      __typename?: 'WalletKind';
      id: any;
      name: string;
      merchant?: boolean | null;
      organization?: boolean | null;
      user?: boolean | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
};

export type SigmaPrimeAddressFieldsFragment = {
  __typename?: 'SigmaPrimeAddress';
  id: any;
  createdAt: any;
  updatedAt: any;
  personUniqueHash: string;
  sigmaPrimeId?: any | null;
  personFirstName?: string | null;
  personMiddleName?: string | null;
  personLastName?: string | null;
  personAddress1?: string | null;
  personAddress2?: string | null;
  personSector?: string | null;
  personCity?: string | null;
  personRegionCounty?: string | null;
  personProvinceState?: string | null;
  personPostal?: string | null;
  personPostal4?: string | null;
  personAddressLatitude?: string | null;
  personAddressLongitude?: string | null;
  geocode?: string | null;
  firstInHouse?: string | null;
  childPresent?: string | null;
  mfdu?: string | null;
  ageRange?: string | null;
  exactAge?: string | null;
  estimatedAge?: string | null;
  estimatedIncome?: string | null;
  addressType?: string | null;
  dwellingType?: string | null;
  homeownerType?: string | null;
  property?: string | null;
  medianHomeValue?: string | null;
  marital?: string | null;
  ethnicCd?: string | null;
  title?: string | null;
  medianYrsEducation?: string | null;
  sex?: string | null;
  dataPedigree?: string | null;
  estimatedWealth?: string | null;
  personPhoneNumber?: string | null;
  timeZone?: string | null;
  personDob?: string | null;
  personCountryCode?: string | null;
  isDeleted: boolean;
  addressRecordType?: string | null;
  elotSequence?: number | null;
  rdi?: string | null;
  congressionalDistrict?: string | null;
  carrierRoute?: string | null;
  dpvCode?: string | null;
  personEmail?: string | null;
  addressIp?: string | null;
  addressEmail?: string | null;
  addressSsn?: string | null;
  addressSubindustry?: string | null;
  sigmaPrime?: {
    __typename?: 'SigmaPrime';
    id: any;
    sigmaPrimeKindId?: any | null;
    crwdId?: string | null;
    personParent1SigmaId?: any | null;
    personParent2SigmaId?: any | null;
    personFirstName: string;
    personMiddleName?: string | null;
    personLastName?: string | null;
    personFamilyName?: string | null;
    personNameSuffix?: string | null;
    personHandleName?: any | null;
    personTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personPrimaryPhone?: string | null;
    personPrimaryIpAddress?: string | null;
    personPrimaryEmail: any;
    personBusinessEmail?: any | null;
    personPrimaryFacePhoto?: string | null;
    personPrimaryKycStatus: string;
    personPrimaryAmlStatus?: string | null;
    personPrivacyPolicy: string;
    personStatus: string;
    personExchangeRequest: string;
    personUniqueHash: string;
    personPrimaryLanguageId?: string | null;
    aboutMe?: string | null;
    profilePhoto?: string | null;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'SigmaPrimeKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    personParent1?: {
      __typename?: 'SigmaPrime';
      id: any;
      crwdId?: string | null;
      personFirstName: string;
      personLastName?: string | null;
    } | null;
    personParent2?: {
      __typename?: 'SigmaPrime';
      id: any;
      crwdId?: string | null;
      personFirstName: string;
      personLastName?: string | null;
    } | null;
    user?: { __typename?: 'User'; id: any; email: any } | null;
  } | null;
};

export type DatasourceFieldsFragment = {
  __typename?: 'Datasource';
  id: any;
  name: string;
  location?: string | null;
  organizationId?: any | null;
  governance?: string | null;
  pedigree?: string | null;
  authorizedUserId?: any | null;
  createdAt: any;
  updatedAt: any;
  organization?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  authorizedUser?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
};

export type AddressAttributesFieldsFragment = {
  __typename?: 'AddressAttributes';
  id: any;
  sigmaPrimeAddressId?: any | null;
  personUniqueHash?: string | null;
  datasourceId?: any | null;
  personPhoneNumber?: string | null;
  addressEmail?: string | null;
  addressSubindustry?: string | null;
  uniqueSubindustry?: string | null;
  description?: string | null;
  comments?: string | null;
  createdAt: any;
  updatedAt: any;
  isDeleted?: boolean | null;
  sigmaPrimeAddress?: {
    __typename?: 'SigmaPrimeAddress';
    id: any;
    createdAt: any;
    updatedAt: any;
    personUniqueHash: string;
    sigmaPrimeId?: any | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    personAddress1?: string | null;
    personAddress2?: string | null;
    personSector?: string | null;
    personCity?: string | null;
    personRegionCounty?: string | null;
    personProvinceState?: string | null;
    personPostal?: string | null;
    personPostal4?: string | null;
    personAddressLatitude?: string | null;
    personAddressLongitude?: string | null;
    geocode?: string | null;
    firstInHouse?: string | null;
    childPresent?: string | null;
    mfdu?: string | null;
    ageRange?: string | null;
    exactAge?: string | null;
    estimatedAge?: string | null;
    estimatedIncome?: string | null;
    addressType?: string | null;
    dwellingType?: string | null;
    homeownerType?: string | null;
    property?: string | null;
    medianHomeValue?: string | null;
    marital?: string | null;
    ethnicCd?: string | null;
    title?: string | null;
    medianYrsEducation?: string | null;
    sex?: string | null;
    dataPedigree?: string | null;
    estimatedWealth?: string | null;
    personPhoneNumber?: string | null;
    timeZone?: string | null;
    personDob?: string | null;
    personCountryCode?: string | null;
    isDeleted: boolean;
    addressRecordType?: string | null;
    elotSequence?: number | null;
    rdi?: string | null;
    congressionalDistrict?: string | null;
    carrierRoute?: string | null;
    dpvCode?: string | null;
    personEmail?: string | null;
    addressIp?: string | null;
    addressEmail?: string | null;
    addressSsn?: string | null;
    addressSubindustry?: string | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
  datasource?: {
    __typename?: 'Datasource';
    id: any;
    name: string;
    location?: string | null;
    organizationId?: any | null;
    governance?: string | null;
    pedigree?: string | null;
    authorizedUserId?: any | null;
    createdAt: any;
    updatedAt: any;
    organization?: {
      __typename?: 'Organization';
      id: any;
      name: string;
      crwdsystemsApiReference?: string | null;
      organizationKindId?: any | null;
      organizationPrimaryAddress1?: string | null;
      organizationPrimaryAddress2?: string | null;
      organizationPrimaryCity?: string | null;
      organizationPrimaryState?: string | null;
      organizationPrimaryPostalCode?: string | null;
      organizationPrimaryRegion?: string | null;
      organizationPrimaryCountryCode?: string | null;
      organizationPrimaryPhone?: string | null;
      geocode?: string | null;
      crwdTicker?: string | null;
      organizationPersonStatus?: string | null;
      personUniqueHash?: string | null;
      personFirstName?: string | null;
      personMiddleName?: string | null;
      personLastName?: string | null;
      companyTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personBusinessEmail?: string | null;
      companyPrimaryWebsite?: string | null;
      companyEmployeeCount?: string | null;
      companyRevenue?: string | null;
      sicCodeId?: any | null;
      industryId?: any | null;
      sub1IndustryId?: any | null;
      sub2IndustryId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'OrganizationKind';
        id: any;
        name: string;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    authorizedUser?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
  } | null;
};

export type SmartContractCommissionFieldsFragment = {
  __typename?: 'SmartContractCommission';
  id: any;
  productId: any;
  smartContractLineageId: any;
  tier1: any;
  tier2: any;
  tier3: any;
  tier4: any;
  tier5: any;
  tier6: any;
  tier7: any;
  tier8: any;
  tier9: any;
  tier10: any;
  createdAt: any;
  updatedAt: any;
  isDeleted?: boolean | null;
  product?: {
    __typename?: 'Product';
    id: any;
    actionUrlActivate?: string | null;
    actionUrlDeactivate?: string | null;
    assetId?: any | null;
    binId?: number | null;
    caliberString?: string | null;
    categories?: string | null;
    categoryNumber?: string | null;
    ceNumber?: string | null;
    chainNumberOfDevices?: string | null;
    contentList?: string | null;
    distanceUom?: string | null;
    duration?: string | null;
    effectHeight?: string | null;
    effectWidth?: string | null;
    exNumberList?: string | null;
    facebookReferenceId?: string | null;
    glAccountList?: string | null;
    googleReferenceId?: string | null;
    images?: string | null;
    internalName?: string | null;
    itemCost?: any | null;
    longDescription?: string | null;
    manufacturerBrand?: string | null;
    manufacturerProductId?: any | null;
    manufacturerOrgId?: any | null;
    marketplaces?: string | null;
    markets?: string | null;
    materialId?: any | null;
    metaDescription?: string | null;
    metaKeywords?: string | null;
    metaTitle?: string | null;
    name: string;
    numberPackedItems?: string | null;
    price: any;
    productKindId?: any | null;
    productStatusId?: any | null;
    qtyUom?: string | null;
    reorderSmartContractId?: any | null;
    shops?: string | null;
    sicCodeId?: any | null;
    sku: string;
    smartContractId?: any | null;
    subindustry1Id?: any | null;
    subindustry2Id?: any | null;
    supplierOrgListId?: any | null;
    unHazMatId?: string | null;
    size?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    asset?: { __typename?: 'Asset'; id: any; name: string } | null;
    manufacturerProduct?: { __typename?: 'Product'; id: any; name: string } | null;
    manufacturerOrg?: { __typename?: 'Organization'; id: any; name: string } | null;
    material?: { __typename?: 'Material'; id: any; name: string } | null;
    kind?: { __typename?: 'ProductKind'; id: any; name: string } | null;
    status?: { __typename?: 'ProductStatus'; id: any; name: string } | null;
    reorderSmartContract?: { __typename?: 'SmartContract'; id: any; name: string } | null;
    sicCode?: { __typename?: 'SicCode'; id: any; code: number; name: string } | null;
    smartContract?: { __typename?: 'SmartContract'; id: any; name: string } | null;
    subindustry1?: { __typename?: 'Subindustry'; id: any; name: string } | null;
    subindustry2?: { __typename?: 'Subindustry'; id: any; name: string } | null;
  } | null;
  smartContractLineage: Array<{
    __typename?: 'SmartContractLineage';
    id: any;
    createdAt: any;
    name: string;
    lineageComments: string;
    parentUserId?: any | null;
    smartContractId: any;
    updatedAt: any;
    userId: any;
    isDeleted?: boolean | null;
    organizationId?: any | null;
    parentOrganizationId?: any | null;
    parentUser?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
    user?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
    organization?: {
      __typename?: 'Organization';
      id: any;
      name: string;
      crwdsystemsApiReference?: string | null;
      organizationKindId?: any | null;
      organizationPrimaryAddress1?: string | null;
      organizationPrimaryAddress2?: string | null;
      organizationPrimaryCity?: string | null;
      organizationPrimaryState?: string | null;
      organizationPrimaryPostalCode?: string | null;
      organizationPrimaryRegion?: string | null;
      organizationPrimaryCountryCode?: string | null;
      organizationPrimaryPhone?: string | null;
      geocode?: string | null;
      crwdTicker?: string | null;
      organizationPersonStatus?: string | null;
      personUniqueHash?: string | null;
      personFirstName?: string | null;
      personMiddleName?: string | null;
      personLastName?: string | null;
      companyTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personBusinessEmail?: string | null;
      companyPrimaryWebsite?: string | null;
      companyEmployeeCount?: string | null;
      companyRevenue?: string | null;
      sicCodeId?: any | null;
      industryId?: any | null;
      sub1IndustryId?: any | null;
      sub2IndustryId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'OrganizationKind';
        id: any;
        name: string;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    parentOrganization?: {
      __typename?: 'Organization';
      id: any;
      name: string;
      crwdsystemsApiReference?: string | null;
      organizationKindId?: any | null;
      organizationPrimaryAddress1?: string | null;
      organizationPrimaryAddress2?: string | null;
      organizationPrimaryCity?: string | null;
      organizationPrimaryState?: string | null;
      organizationPrimaryPostalCode?: string | null;
      organizationPrimaryRegion?: string | null;
      organizationPrimaryCountryCode?: string | null;
      organizationPrimaryPhone?: string | null;
      geocode?: string | null;
      crwdTicker?: string | null;
      organizationPersonStatus?: string | null;
      personUniqueHash?: string | null;
      personFirstName?: string | null;
      personMiddleName?: string | null;
      personLastName?: string | null;
      companyTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personBusinessEmail?: string | null;
      companyPrimaryWebsite?: string | null;
      companyEmployeeCount?: string | null;
      companyRevenue?: string | null;
      sicCodeId?: any | null;
      industryId?: any | null;
      sub1IndustryId?: any | null;
      sub2IndustryId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'OrganizationKind';
        id: any;
        name: string;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
  }>;
};

export type SmartContractDocumentFieldsFragment = {
  __typename?: 'SmartContractDocument';
  id: any;
  name: string;
  documentSource?: string | null;
  datasourceId?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  isDeleted?: boolean | null;
  datasource?: {
    __typename?: 'Datasource';
    id: any;
    name: string;
    location?: string | null;
    organizationId?: any | null;
    governance?: string | null;
    pedigree?: string | null;
    authorizedUserId?: any | null;
    createdAt: any;
    updatedAt: any;
    organization?: {
      __typename?: 'Organization';
      id: any;
      name: string;
      crwdsystemsApiReference?: string | null;
      organizationKindId?: any | null;
      organizationPrimaryAddress1?: string | null;
      organizationPrimaryAddress2?: string | null;
      organizationPrimaryCity?: string | null;
      organizationPrimaryState?: string | null;
      organizationPrimaryPostalCode?: string | null;
      organizationPrimaryRegion?: string | null;
      organizationPrimaryCountryCode?: string | null;
      organizationPrimaryPhone?: string | null;
      geocode?: string | null;
      crwdTicker?: string | null;
      organizationPersonStatus?: string | null;
      personUniqueHash?: string | null;
      personFirstName?: string | null;
      personMiddleName?: string | null;
      personLastName?: string | null;
      companyTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personBusinessEmail?: string | null;
      companyPrimaryWebsite?: string | null;
      companyEmployeeCount?: string | null;
      companyRevenue?: string | null;
      sicCodeId?: any | null;
      industryId?: any | null;
      sub1IndustryId?: any | null;
      sub2IndustryId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'OrganizationKind';
        id: any;
        name: string;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    authorizedUser?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
  } | null;
};

export type WalletInventoryFieldsFragment = {
  __typename?: 'WalletInventory';
  id: any;
  accountNumber?: string | null;
  ibanNumber?: string | null;
  inventoryAmount?: any | null;
  notes?: string | null;
  restriction?: string | null;
  routingNumber?: string | null;
  swiftCode?: string | null;
  availableAt?: any | null;
  assetId?: any | null;
  currencyId?: any | null;
  documentUserId?: any | null;
  favoriteId?: any | null;
  productId?: any | null;
  transactionId?: any | null;
  walletId?: any | null;
  isDeleted: boolean;
  createdAt: any;
  updatedAt: any;
  asset?: {
    __typename?: 'Asset';
    appraisalId: any;
    assetAccountId?: any | null;
    assetKindId: any;
    category?: string | null;
    classification?: any | null;
    countryCode: string;
    createdAt: any;
    datasourceId?: any | null;
    description: string;
    dimensions: string;
    embedCode?: string | null;
    format?: string | null;
    id: any;
    images?: string | null;
    isDeleted: boolean;
    materialId?: any | null;
    materials: string;
    name: string;
    organizationId?: any | null;
    parentCategory?: string | null;
    sourceUrl?: string | null;
    sourceUserId?: any | null;
    subindustryId: any;
    uom: string;
    updatedAt: any;
    userId: any;
    productId?: any | null;
    authorized?: string | null;
    issued?: string | null;
    appraisal: {
      __typename?: 'Appraisal';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      organizationId: any;
      smartContractId: any;
      userId: any;
      organization: {
        __typename?: 'Organization';
        id: any;
        name: string;
        crwdsystemsApiReference?: string | null;
        organizationKindId?: any | null;
        organizationPrimaryAddress1?: string | null;
        organizationPrimaryAddress2?: string | null;
        organizationPrimaryCity?: string | null;
        organizationPrimaryState?: string | null;
        organizationPrimaryPostalCode?: string | null;
        organizationPrimaryRegion?: string | null;
        organizationPrimaryCountryCode?: string | null;
        organizationPrimaryPhone?: string | null;
        geocode?: string | null;
        crwdTicker?: string | null;
        organizationPersonStatus?: string | null;
        personUniqueHash?: string | null;
        personFirstName?: string | null;
        personMiddleName?: string | null;
        personLastName?: string | null;
        companyTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personBusinessEmail?: string | null;
        companyPrimaryWebsite?: string | null;
        companyEmployeeCount?: string | null;
        companyRevenue?: string | null;
        sicCodeId?: any | null;
        industryId?: any | null;
        sub1IndustryId?: any | null;
        sub2IndustryId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'OrganizationKind';
          id: any;
          name: string;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry1?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry2?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      };
      smartContract: {
        __typename?: 'SmartContract';
        id: any;
        name: string;
        description: string;
        useCount: number;
        terms: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        clearinghouseId: any;
        smartContractKindId: any;
        clearinghouse: {
          __typename?: 'Clearinghouse';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          clearinghouseKindId: any;
          userId: any;
          kind: {
            __typename?: 'ClearinghouseKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            gicsSectorId: any;
            groupId: any;
            industryId: any;
            subindustryId: any;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            group: {
              __typename?: 'Group';
              id: any;
              name: string;
              description?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              imageGroup?: string | null;
              gicsSectorId: any;
              sicCodeId?: any | null;
              gicsSector: {
                __typename?: 'GicsSector';
                id: any;
                name: string;
                description?: string | null;
                imageSector?: string | null;
                updatedAt: any;
                isDeleted: boolean;
                createdAt: any;
                sicCodeId?: any | null;
                sicCode?: {
                  __typename?: 'SicCode';
                  id: any;
                  code: number;
                  name: string;
                  sicCodeOffice?: string | null;
                  gicsCodeName?: string | null;
                  gicsCode?: number | null;
                  createdAt: any;
                  updatedAt: any;
                  isDeleted: boolean;
                } | null;
              };
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            industry: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            subindustry: {
              __typename?: 'Subindustry';
              id: any;
              name: string;
              description?: string | null;
              imageSubindustry?: string | null;
              parentIndustryId?: any | null;
              sicCodeId?: any | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              parentIndustry?: {
                __typename?: 'Industry';
                id: any;
                name: string;
                description?: string | null;
                imageIndustry?: string | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
                groupId?: any | null;
                sicCodeId?: any | null;
                group?: { __typename?: 'Group'; name: string } | null;
                sicCode?: {
                  __typename?: 'SicCode';
                  id: any;
                  code: number;
                  name: string;
                  sicCodeOffice?: string | null;
                  gicsCodeName?: string | null;
                  gicsCode?: number | null;
                  createdAt: any;
                  updatedAt: any;
                  isDeleted: boolean;
                } | null;
              } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
          };
          user: {
            __typename?: 'User';
            email: any;
            emailVerified: boolean;
            preferences?: string | null;
            rememberToken: string;
            isAdmin: boolean;
            crwdsystemsApi?: string | null;
            createdAt: any;
            updatedAt: any;
            id: any;
            organizationId?: any | null;
            statusId?: any | null;
            isDeleted: boolean;
            sigmaPrimeId?: any | null;
            uniqueHash: string;
            countryCode?: string | null;
            organization?: { __typename?: 'Organization'; id: any; name: string } | null;
            status?: {
              __typename?: 'Status';
              id: any;
              description: string;
              kindId?: any | null;
              name: string;
              updatedAt: any;
              startDate?: any | null;
              endDate?: any | null;
              createdAt: any;
              isDeleted: boolean;
              kind?: {
                __typename?: 'StatusKind';
                id: any;
                name: string;
                description: string;
                updatedAt: any;
                createdAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sigmaPrime?: {
              __typename?: 'SigmaPrime';
              id: any;
              sigmaPrimeKindId?: any | null;
              crwdId?: string | null;
              personParent1SigmaId?: any | null;
              personParent2SigmaId?: any | null;
              personFirstName: string;
              personMiddleName?: string | null;
              personLastName?: string | null;
              personFamilyName?: string | null;
              personNameSuffix?: string | null;
              personHandleName?: any | null;
              personTitleName?: string | null;
              personPrimaryMobile?: string | null;
              personPrimaryPhone?: string | null;
              personPrimaryIpAddress?: string | null;
              personPrimaryEmail: any;
              personBusinessEmail?: any | null;
              personPrimaryFacePhoto?: string | null;
              personPrimaryKycStatus: string;
              personPrimaryAmlStatus?: string | null;
              personPrivacyPolicy: string;
              personStatus: string;
              personExchangeRequest: string;
              personUniqueHash: string;
              personPrimaryLanguageId?: string | null;
              aboutMe?: string | null;
              profilePhoto?: string | null;
              userId?: any | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              kind?: {
                __typename?: 'SigmaPrimeKind';
                id: any;
                name: string;
                description: string;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
              personParent1?: {
                __typename?: 'SigmaPrime';
                id: any;
                crwdId?: string | null;
                personFirstName: string;
                personLastName?: string | null;
              } | null;
              personParent2?: {
                __typename?: 'SigmaPrime';
                id: any;
                crwdId?: string | null;
                personFirstName: string;
                personLastName?: string | null;
              } | null;
              user?: { __typename?: 'User'; id: any; email: any } | null;
            } | null;
          };
        };
        kind: {
          __typename?: 'SmartContractKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          subindustryId: any;
          subindustry: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
        };
      };
      user: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
    };
    kind: {
      __typename?: 'AssetKind';
      id: any;
      name: string;
      description: string;
      images?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      subindustryId: any;
      subindustry: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
    };
    country: {
      __typename?: 'Country';
      code: string;
      name: string;
      region: string;
      phoneCode: number;
      secCode?: string | null;
      stateLabel: string;
      longitude: any;
      latitude: any;
      isoNumber: number;
      iso3: string;
      flagEmojiUnicode: string;
      flagEmoji: string;
      currencyCode: string;
      createdAt?: any | null;
      updatedAt?: any | null;
      isDeleted: boolean;
    };
    datasource?: {
      __typename?: 'Datasource';
      id: any;
      name: string;
      location?: string | null;
      organizationId?: any | null;
      governance?: string | null;
      pedigree?: string | null;
      authorizedUserId?: any | null;
      createdAt: any;
      updatedAt: any;
      organization?: {
        __typename?: 'Organization';
        id: any;
        name: string;
        crwdsystemsApiReference?: string | null;
        organizationKindId?: any | null;
        organizationPrimaryAddress1?: string | null;
        organizationPrimaryAddress2?: string | null;
        organizationPrimaryCity?: string | null;
        organizationPrimaryState?: string | null;
        organizationPrimaryPostalCode?: string | null;
        organizationPrimaryRegion?: string | null;
        organizationPrimaryCountryCode?: string | null;
        organizationPrimaryPhone?: string | null;
        geocode?: string | null;
        crwdTicker?: string | null;
        organizationPersonStatus?: string | null;
        personUniqueHash?: string | null;
        personFirstName?: string | null;
        personMiddleName?: string | null;
        personLastName?: string | null;
        companyTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personBusinessEmail?: string | null;
        companyPrimaryWebsite?: string | null;
        companyEmployeeCount?: string | null;
        companyRevenue?: string | null;
        sicCodeId?: any | null;
        industryId?: any | null;
        sub1IndustryId?: any | null;
        sub2IndustryId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'OrganizationKind';
          id: any;
          name: string;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry1?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry2?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      authorizedUser?: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      } | null;
    } | null;
    material?: {
      __typename?: 'Material';
      id: any;
      name: string;
      description: string;
      detailsUrl?: string | null;
      image?: string | null;
      isCommodity?: boolean | null;
      materialCategory: string;
      materialSubcategory?: string | null;
      materialType: string;
      symbol?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    organization?: {
      __typename?: 'Organization';
      id: any;
      name: string;
      crwdsystemsApiReference?: string | null;
      organizationKindId?: any | null;
      organizationPrimaryAddress1?: string | null;
      organizationPrimaryAddress2?: string | null;
      organizationPrimaryCity?: string | null;
      organizationPrimaryState?: string | null;
      organizationPrimaryPostalCode?: string | null;
      organizationPrimaryRegion?: string | null;
      organizationPrimaryCountryCode?: string | null;
      organizationPrimaryPhone?: string | null;
      geocode?: string | null;
      crwdTicker?: string | null;
      organizationPersonStatus?: string | null;
      personUniqueHash?: string | null;
      personFirstName?: string | null;
      personMiddleName?: string | null;
      personLastName?: string | null;
      companyTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personBusinessEmail?: string | null;
      companyPrimaryWebsite?: string | null;
      companyEmployeeCount?: string | null;
      companyRevenue?: string | null;
      sicCodeId?: any | null;
      industryId?: any | null;
      sub1IndustryId?: any | null;
      sub2IndustryId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'OrganizationKind';
        id: any;
        name: string;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    sourceUser?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
    subindustry: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    };
    user: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
    product?: { __typename?: 'Product'; id: any; name: string } | null;
  } | null;
  currency?: {
    __typename?: 'Currency';
    id: any;
    abbreviation?: string | null;
    authorized?: any | null;
    cc?: string | null;
    description?: string | null;
    reserved?: any | null;
    name: string;
    issued?: any | null;
    image?: string | null;
    symbol?: string | null;
    numericCode?: string | null;
    minorUnit?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    countryCode?: string | null;
    currencyKindId?: any | null;
    groupsId?: any | null;
    sectorId?: any | null;
    subindustryId?: any | null;
    country?: {
      __typename?: 'Country';
      code: string;
      name: string;
      region: string;
      phoneCode: number;
      secCode?: string | null;
      stateLabel: string;
      longitude: any;
      latitude: any;
      isoNumber: number;
      iso3: string;
      flagEmojiUnicode: string;
      flagEmoji: string;
      currencyCode: string;
      createdAt?: any | null;
      updatedAt?: any | null;
      isDeleted: boolean;
    } | null;
    kind?: {
      __typename?: 'CurrencyKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      gicsSectorId?: any | null;
      groupId?: any | null;
      industryId?: any | null;
      subindustryId?: any | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      imageGroup?: string | null;
      gicsSectorId: any;
      sicCodeId?: any | null;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    gicsSector?: {
      __typename?: 'GicsSector';
      id: any;
      name: string;
      description?: string | null;
      imageSector?: string | null;
      updatedAt: any;
      isDeleted: boolean;
      createdAt: any;
      sicCodeId?: any | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  documentUser?: {
    __typename?: 'DocumentUser';
    createdAt: any;
    documentLocation?: string | null;
    id: any;
    isDeleted?: boolean | null;
    smartContractDocumentId: any;
    userId: any;
    walletId: any;
    smartContractDocument?: {
      __typename?: 'SmartContractDocument';
      id: any;
      name: string;
      documentSource?: string | null;
      datasourceId?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      isDeleted?: boolean | null;
      datasource?: {
        __typename?: 'Datasource';
        id: any;
        name: string;
        location?: string | null;
        organizationId?: any | null;
        governance?: string | null;
        pedigree?: string | null;
        authorizedUserId?: any | null;
        createdAt: any;
        updatedAt: any;
        organization?: {
          __typename?: 'Organization';
          id: any;
          name: string;
          crwdsystemsApiReference?: string | null;
          organizationKindId?: any | null;
          organizationPrimaryAddress1?: string | null;
          organizationPrimaryAddress2?: string | null;
          organizationPrimaryCity?: string | null;
          organizationPrimaryState?: string | null;
          organizationPrimaryPostalCode?: string | null;
          organizationPrimaryRegion?: string | null;
          organizationPrimaryCountryCode?: string | null;
          organizationPrimaryPhone?: string | null;
          geocode?: string | null;
          crwdTicker?: string | null;
          organizationPersonStatus?: string | null;
          personUniqueHash?: string | null;
          personFirstName?: string | null;
          personMiddleName?: string | null;
          personLastName?: string | null;
          companyTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personBusinessEmail?: string | null;
          companyPrimaryWebsite?: string | null;
          companyEmployeeCount?: string | null;
          companyRevenue?: string | null;
          sicCodeId?: any | null;
          industryId?: any | null;
          sub1IndustryId?: any | null;
          sub2IndustryId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'OrganizationKind';
            id: any;
            name: string;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          industry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          subindustry1?: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          subindustry2?: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
        } | null;
        authorizedUser?: {
          __typename?: 'User';
          email: any;
          emailVerified: boolean;
          preferences?: string | null;
          rememberToken: string;
          isAdmin: boolean;
          crwdsystemsApi?: string | null;
          createdAt: any;
          updatedAt: any;
          id: any;
          organizationId?: any | null;
          statusId?: any | null;
          isDeleted: boolean;
          sigmaPrimeId?: any | null;
          uniqueHash: string;
          countryCode?: string | null;
          organization?: { __typename?: 'Organization'; id: any; name: string } | null;
          status?: {
            __typename?: 'Status';
            id: any;
            description: string;
            kindId?: any | null;
            name: string;
            updatedAt: any;
            startDate?: any | null;
            endDate?: any | null;
            createdAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'StatusKind';
              id: any;
              name: string;
              description: string;
              updatedAt: any;
              createdAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sigmaPrime?: {
            __typename?: 'SigmaPrime';
            id: any;
            sigmaPrimeKindId?: any | null;
            crwdId?: string | null;
            personParent1SigmaId?: any | null;
            personParent2SigmaId?: any | null;
            personFirstName: string;
            personMiddleName?: string | null;
            personLastName?: string | null;
            personFamilyName?: string | null;
            personNameSuffix?: string | null;
            personHandleName?: any | null;
            personTitleName?: string | null;
            personPrimaryMobile?: string | null;
            personPrimaryPhone?: string | null;
            personPrimaryIpAddress?: string | null;
            personPrimaryEmail: any;
            personBusinessEmail?: any | null;
            personPrimaryFacePhoto?: string | null;
            personPrimaryKycStatus: string;
            personPrimaryAmlStatus?: string | null;
            personPrivacyPolicy: string;
            personStatus: string;
            personExchangeRequest: string;
            personUniqueHash: string;
            personPrimaryLanguageId?: string | null;
            aboutMe?: string | null;
            profilePhoto?: string | null;
            userId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'SigmaPrimeKind';
              id: any;
              name: string;
              description: string;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
            personParent1?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            personParent2?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            user?: { __typename?: 'User'; id: any; email: any } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    user?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
    wallet?: {
      __typename?: 'Wallet';
      id: any;
      name: string;
      walletAddress: string;
      primaryHash: string;
      secondaryHash?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      defaultCurrencyId: any;
      primaryUserId: any;
      secondaryUserId?: any | null;
      walletKindId?: any | null;
      defaultCurrency: {
        __typename?: 'Currency';
        id: any;
        abbreviation?: string | null;
        authorized?: any | null;
        cc?: string | null;
        description?: string | null;
        reserved?: any | null;
        name: string;
        issued?: any | null;
        image?: string | null;
        symbol?: string | null;
        numericCode?: string | null;
        minorUnit?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        countryCode?: string | null;
        currencyKindId?: any | null;
        groupsId?: any | null;
        sectorId?: any | null;
        subindustryId?: any | null;
        country?: {
          __typename?: 'Country';
          code: string;
          name: string;
          region: string;
          phoneCode: number;
          secCode?: string | null;
          stateLabel: string;
          longitude: any;
          latitude: any;
          isoNumber: number;
          iso3: string;
          flagEmojiUnicode: string;
          flagEmoji: string;
          currencyCode: string;
          createdAt?: any | null;
          updatedAt?: any | null;
          isDeleted: boolean;
        } | null;
        kind?: {
          __typename?: 'CurrencyKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId?: any | null;
          groupId?: any | null;
          industryId?: any | null;
          subindustryId?: any | null;
          gicsSector?: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          group?: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          industry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          subindustry?: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      };
      primaryUser: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
      secondaryUser?: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      } | null;
      kind?: {
        __typename?: 'WalletKind';
        id: any;
        name: string;
        merchant?: boolean | null;
        organization?: boolean | null;
        user?: boolean | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  favorite?: {
    __typename?: 'Favorite';
    id: any;
    name: string;
    blog?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    walletId?: any | null;
    wallet?: {
      __typename?: 'Wallet';
      id: any;
      name: string;
      walletAddress: string;
      primaryHash: string;
      secondaryHash?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      defaultCurrencyId: any;
      primaryUserId: any;
      secondaryUserId?: any | null;
      walletKindId?: any | null;
      defaultCurrency: {
        __typename?: 'Currency';
        id: any;
        abbreviation?: string | null;
        authorized?: any | null;
        cc?: string | null;
        description?: string | null;
        reserved?: any | null;
        name: string;
        issued?: any | null;
        image?: string | null;
        symbol?: string | null;
        numericCode?: string | null;
        minorUnit?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        countryCode?: string | null;
        currencyKindId?: any | null;
        groupsId?: any | null;
        sectorId?: any | null;
        subindustryId?: any | null;
        country?: {
          __typename?: 'Country';
          code: string;
          name: string;
          region: string;
          phoneCode: number;
          secCode?: string | null;
          stateLabel: string;
          longitude: any;
          latitude: any;
          isoNumber: number;
          iso3: string;
          flagEmojiUnicode: string;
          flagEmoji: string;
          currencyCode: string;
          createdAt?: any | null;
          updatedAt?: any | null;
          isDeleted: boolean;
        } | null;
        kind?: {
          __typename?: 'CurrencyKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId?: any | null;
          groupId?: any | null;
          industryId?: any | null;
          subindustryId?: any | null;
          gicsSector?: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          group?: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          industry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          subindustry?: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      };
      primaryUser: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
      secondaryUser?: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      } | null;
      kind?: {
        __typename?: 'WalletKind';
        id: any;
        name: string;
        merchant?: boolean | null;
        organization?: boolean | null;
        user?: boolean | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  product?: {
    __typename?: 'Product';
    id: any;
    actionUrlActivate?: string | null;
    actionUrlDeactivate?: string | null;
    assetId?: any | null;
    binId?: number | null;
    caliberString?: string | null;
    categories?: string | null;
    categoryNumber?: string | null;
    ceNumber?: string | null;
    chainNumberOfDevices?: string | null;
    contentList?: string | null;
    distanceUom?: string | null;
    duration?: string | null;
    effectHeight?: string | null;
    effectWidth?: string | null;
    exNumberList?: string | null;
    facebookReferenceId?: string | null;
    glAccountList?: string | null;
    googleReferenceId?: string | null;
    images?: string | null;
    internalName?: string | null;
    itemCost?: any | null;
    longDescription?: string | null;
    manufacturerBrand?: string | null;
    manufacturerProductId?: any | null;
    manufacturerOrgId?: any | null;
    marketplaces?: string | null;
    markets?: string | null;
    materialId?: any | null;
    metaDescription?: string | null;
    metaKeywords?: string | null;
    metaTitle?: string | null;
    name: string;
    numberPackedItems?: string | null;
    price: any;
    productKindId?: any | null;
    productStatusId?: any | null;
    qtyUom?: string | null;
    reorderSmartContractId?: any | null;
    shops?: string | null;
    sicCodeId?: any | null;
    sku: string;
    smartContractId?: any | null;
    subindustry1Id?: any | null;
    subindustry2Id?: any | null;
    supplierOrgListId?: any | null;
    unHazMatId?: string | null;
    size?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    asset?: { __typename?: 'Asset'; id: any; name: string } | null;
    manufacturerProduct?: { __typename?: 'Product'; id: any; name: string } | null;
    manufacturerOrg?: { __typename?: 'Organization'; id: any; name: string } | null;
    material?: { __typename?: 'Material'; id: any; name: string } | null;
    kind?: { __typename?: 'ProductKind'; id: any; name: string } | null;
    status?: { __typename?: 'ProductStatus'; id: any; name: string } | null;
    reorderSmartContract?: { __typename?: 'SmartContract'; id: any; name: string } | null;
    sicCode?: { __typename?: 'SicCode'; id: any; code: number; name: string } | null;
    smartContract?: { __typename?: 'SmartContract'; id: any; name: string } | null;
    subindustry1?: { __typename?: 'Subindustry'; id: any; name: string } | null;
    subindustry2?: { __typename?: 'Subindustry'; id: any; name: string } | null;
  } | null;
  transaction?: {
    __typename?: 'Transaction';
    id: any;
    txnId: string;
    networkFee: any;
    destTag?: string | null;
    confirmation?: string | null;
    amount: any;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    fromWalletId?: any | null;
    paymentId: any;
    smartContractId: any;
    statusId: any;
    toWalletId?: any | null;
    transactionKindId: any;
    currencyId: any;
    fromWallet?: {
      __typename?: 'Wallet';
      id: any;
      name: string;
      walletAddress: string;
      primaryHash: string;
      secondaryHash?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      defaultCurrencyId: any;
      primaryUserId: any;
      secondaryUserId?: any | null;
      walletKindId?: any | null;
      defaultCurrency: {
        __typename?: 'Currency';
        id: any;
        abbreviation?: string | null;
        authorized?: any | null;
        cc?: string | null;
        description?: string | null;
        reserved?: any | null;
        name: string;
        issued?: any | null;
        image?: string | null;
        symbol?: string | null;
        numericCode?: string | null;
        minorUnit?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        countryCode?: string | null;
        currencyKindId?: any | null;
        groupsId?: any | null;
        sectorId?: any | null;
        subindustryId?: any | null;
        country?: {
          __typename?: 'Country';
          code: string;
          name: string;
          region: string;
          phoneCode: number;
          secCode?: string | null;
          stateLabel: string;
          longitude: any;
          latitude: any;
          isoNumber: number;
          iso3: string;
          flagEmojiUnicode: string;
          flagEmoji: string;
          currencyCode: string;
          createdAt?: any | null;
          updatedAt?: any | null;
          isDeleted: boolean;
        } | null;
        kind?: {
          __typename?: 'CurrencyKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId?: any | null;
          groupId?: any | null;
          industryId?: any | null;
          subindustryId?: any | null;
          gicsSector?: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          group?: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          industry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          subindustry?: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      };
      primaryUser: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
      secondaryUser?: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      } | null;
      kind?: {
        __typename?: 'WalletKind';
        id: any;
        name: string;
        merchant?: boolean | null;
        organization?: boolean | null;
        user?: boolean | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    payment: {
      __typename?: 'Payment';
      id: any;
      orderId?: any | null;
      amountCrwdunit?: any | null;
      amountCurrency?: any | null;
      amountUsd?: number | null;
      paymentDetails: string;
      receiveWalletTxn: string;
      sendWalletTxn: string;
      successUrl: string;
      cancelUrl: string;
      comment?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      currencyId?: any | null;
      userId: any;
      statusId?: any | null;
      askId?: any | null;
      putId?: any | null;
      assetAccountId?: any | null;
      currency?: {
        __typename?: 'Currency';
        id: any;
        abbreviation?: string | null;
        authorized?: any | null;
        cc?: string | null;
        description?: string | null;
        reserved?: any | null;
        name: string;
        issued?: any | null;
        image?: string | null;
        symbol?: string | null;
        numericCode?: string | null;
        minorUnit?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        countryCode?: string | null;
        currencyKindId?: any | null;
        groupsId?: any | null;
        sectorId?: any | null;
        subindustryId?: any | null;
        country?: {
          __typename?: 'Country';
          code: string;
          name: string;
          region: string;
          phoneCode: number;
          secCode?: string | null;
          stateLabel: string;
          longitude: any;
          latitude: any;
          isoNumber: number;
          iso3: string;
          flagEmojiUnicode: string;
          flagEmoji: string;
          currencyCode: string;
          createdAt?: any | null;
          updatedAt?: any | null;
          isDeleted: boolean;
        } | null;
        kind?: {
          __typename?: 'CurrencyKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId?: any | null;
          groupId?: any | null;
          industryId?: any | null;
          subindustryId?: any | null;
          gicsSector?: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          group?: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          industry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          subindustry?: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      user: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
      status?: { __typename?: 'Status'; id: any; name: string } | null;
      ask?: { __typename?: 'Ask'; id: any; name: string } | null;
      put?: { __typename?: 'Put'; id: any; name: string } | null;
      assetAccount?: {
        __typename?: 'AssetAccount';
        id: any;
        accountNumber?: number | null;
        availableAt: any;
        cvv: string;
        expirationDate: any;
        ibanNumber: string;
        issueDate: any;
        organizationId?: any | null;
        routingNumber: string;
        securityCode: string;
        swiftCode: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        userId?: any | null;
        balanceAmount?: any | null;
        accountKind?: string | null;
        accountName?: string | null;
        achNumber?: string | null;
        ccNumber?: string | null;
        organization?: {
          __typename?: 'Organization';
          id: any;
          name: string;
          crwdsystemsApiReference?: string | null;
          organizationKindId?: any | null;
          organizationPrimaryAddress1?: string | null;
          organizationPrimaryAddress2?: string | null;
          organizationPrimaryCity?: string | null;
          organizationPrimaryState?: string | null;
          organizationPrimaryPostalCode?: string | null;
          organizationPrimaryRegion?: string | null;
          organizationPrimaryCountryCode?: string | null;
          organizationPrimaryPhone?: string | null;
          geocode?: string | null;
          crwdTicker?: string | null;
          organizationPersonStatus?: string | null;
          personUniqueHash?: string | null;
          personFirstName?: string | null;
          personMiddleName?: string | null;
          personLastName?: string | null;
          companyTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personBusinessEmail?: string | null;
          companyPrimaryWebsite?: string | null;
          companyEmployeeCount?: string | null;
          companyRevenue?: string | null;
          sicCodeId?: any | null;
          industryId?: any | null;
          sub1IndustryId?: any | null;
          sub2IndustryId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'OrganizationKind';
            id: any;
            name: string;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          industry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          subindustry1?: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          subindustry2?: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
        } | null;
        user?: {
          __typename?: 'User';
          email: any;
          emailVerified: boolean;
          preferences?: string | null;
          rememberToken: string;
          isAdmin: boolean;
          crwdsystemsApi?: string | null;
          createdAt: any;
          updatedAt: any;
          id: any;
          organizationId?: any | null;
          statusId?: any | null;
          isDeleted: boolean;
          sigmaPrimeId?: any | null;
          uniqueHash: string;
          countryCode?: string | null;
          organization?: { __typename?: 'Organization'; id: any; name: string } | null;
          status?: {
            __typename?: 'Status';
            id: any;
            description: string;
            kindId?: any | null;
            name: string;
            updatedAt: any;
            startDate?: any | null;
            endDate?: any | null;
            createdAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'StatusKind';
              id: any;
              name: string;
              description: string;
              updatedAt: any;
              createdAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sigmaPrime?: {
            __typename?: 'SigmaPrime';
            id: any;
            sigmaPrimeKindId?: any | null;
            crwdId?: string | null;
            personParent1SigmaId?: any | null;
            personParent2SigmaId?: any | null;
            personFirstName: string;
            personMiddleName?: string | null;
            personLastName?: string | null;
            personFamilyName?: string | null;
            personNameSuffix?: string | null;
            personHandleName?: any | null;
            personTitleName?: string | null;
            personPrimaryMobile?: string | null;
            personPrimaryPhone?: string | null;
            personPrimaryIpAddress?: string | null;
            personPrimaryEmail: any;
            personBusinessEmail?: any | null;
            personPrimaryFacePhoto?: string | null;
            personPrimaryKycStatus: string;
            personPrimaryAmlStatus?: string | null;
            personPrivacyPolicy: string;
            personStatus: string;
            personExchangeRequest: string;
            personUniqueHash: string;
            personPrimaryLanguageId?: string | null;
            aboutMe?: string | null;
            profilePhoto?: string | null;
            userId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'SigmaPrimeKind';
              id: any;
              name: string;
              description: string;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
            personParent1?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            personParent2?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            user?: { __typename?: 'User'; id: any; email: any } | null;
          } | null;
        } | null;
      } | null;
    };
    smartContract: {
      __typename?: 'SmartContract';
      id: any;
      name: string;
      description: string;
      useCount: number;
      terms: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseId: any;
      smartContractKindId: any;
      clearinghouse: {
        __typename?: 'Clearinghouse';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        clearinghouseKindId: any;
        userId: any;
        kind: {
          __typename?: 'ClearinghouseKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId: any;
          groupId: any;
          industryId: any;
          subindustryId: any;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          group: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          industry: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          subindustry: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
        };
        user: {
          __typename?: 'User';
          email: any;
          emailVerified: boolean;
          preferences?: string | null;
          rememberToken: string;
          isAdmin: boolean;
          crwdsystemsApi?: string | null;
          createdAt: any;
          updatedAt: any;
          id: any;
          organizationId?: any | null;
          statusId?: any | null;
          isDeleted: boolean;
          sigmaPrimeId?: any | null;
          uniqueHash: string;
          countryCode?: string | null;
          organization?: { __typename?: 'Organization'; id: any; name: string } | null;
          status?: {
            __typename?: 'Status';
            id: any;
            description: string;
            kindId?: any | null;
            name: string;
            updatedAt: any;
            startDate?: any | null;
            endDate?: any | null;
            createdAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'StatusKind';
              id: any;
              name: string;
              description: string;
              updatedAt: any;
              createdAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sigmaPrime?: {
            __typename?: 'SigmaPrime';
            id: any;
            sigmaPrimeKindId?: any | null;
            crwdId?: string | null;
            personParent1SigmaId?: any | null;
            personParent2SigmaId?: any | null;
            personFirstName: string;
            personMiddleName?: string | null;
            personLastName?: string | null;
            personFamilyName?: string | null;
            personNameSuffix?: string | null;
            personHandleName?: any | null;
            personTitleName?: string | null;
            personPrimaryMobile?: string | null;
            personPrimaryPhone?: string | null;
            personPrimaryIpAddress?: string | null;
            personPrimaryEmail: any;
            personBusinessEmail?: any | null;
            personPrimaryFacePhoto?: string | null;
            personPrimaryKycStatus: string;
            personPrimaryAmlStatus?: string | null;
            personPrivacyPolicy: string;
            personStatus: string;
            personExchangeRequest: string;
            personUniqueHash: string;
            personPrimaryLanguageId?: string | null;
            aboutMe?: string | null;
            profilePhoto?: string | null;
            userId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'SigmaPrimeKind';
              id: any;
              name: string;
              description: string;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
            personParent1?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            personParent2?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            user?: { __typename?: 'User'; id: any; email: any } | null;
          } | null;
        };
      };
      kind: {
        __typename?: 'SmartContractKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        subindustryId: any;
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
    };
    status: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    };
    toWallet?: {
      __typename?: 'Wallet';
      id: any;
      name: string;
      walletAddress: string;
      primaryHash: string;
      secondaryHash?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      defaultCurrencyId: any;
      primaryUserId: any;
      secondaryUserId?: any | null;
      walletKindId?: any | null;
      defaultCurrency: {
        __typename?: 'Currency';
        id: any;
        abbreviation?: string | null;
        authorized?: any | null;
        cc?: string | null;
        description?: string | null;
        reserved?: any | null;
        name: string;
        issued?: any | null;
        image?: string | null;
        symbol?: string | null;
        numericCode?: string | null;
        minorUnit?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        countryCode?: string | null;
        currencyKindId?: any | null;
        groupsId?: any | null;
        sectorId?: any | null;
        subindustryId?: any | null;
        country?: {
          __typename?: 'Country';
          code: string;
          name: string;
          region: string;
          phoneCode: number;
          secCode?: string | null;
          stateLabel: string;
          longitude: any;
          latitude: any;
          isoNumber: number;
          iso3: string;
          flagEmojiUnicode: string;
          flagEmoji: string;
          currencyCode: string;
          createdAt?: any | null;
          updatedAt?: any | null;
          isDeleted: boolean;
        } | null;
        kind?: {
          __typename?: 'CurrencyKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId?: any | null;
          groupId?: any | null;
          industryId?: any | null;
          subindustryId?: any | null;
          gicsSector?: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          group?: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          industry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          subindustry?: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      };
      primaryUser: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
      secondaryUser?: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      } | null;
      kind?: {
        __typename?: 'WalletKind';
        id: any;
        name: string;
        merchant?: boolean | null;
        organization?: boolean | null;
        user?: boolean | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    kind: {
      __typename?: 'TransactionKind';
      id: any;
      name: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      smartContractId: any;
      description?: string | null;
      subIndustry1Id?: any | null;
      subIndustry2Id?: any | null;
      smartContract: {
        __typename?: 'SmartContract';
        id: any;
        name: string;
        description: string;
        useCount: number;
        terms: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        clearinghouseId: any;
        smartContractKindId: any;
        clearinghouse: {
          __typename?: 'Clearinghouse';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          clearinghouseKindId: any;
          userId: any;
          kind: {
            __typename?: 'ClearinghouseKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            gicsSectorId: any;
            groupId: any;
            industryId: any;
            subindustryId: any;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            group: {
              __typename?: 'Group';
              id: any;
              name: string;
              description?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              imageGroup?: string | null;
              gicsSectorId: any;
              sicCodeId?: any | null;
              gicsSector: {
                __typename?: 'GicsSector';
                id: any;
                name: string;
                description?: string | null;
                imageSector?: string | null;
                updatedAt: any;
                isDeleted: boolean;
                createdAt: any;
                sicCodeId?: any | null;
                sicCode?: {
                  __typename?: 'SicCode';
                  id: any;
                  code: number;
                  name: string;
                  sicCodeOffice?: string | null;
                  gicsCodeName?: string | null;
                  gicsCode?: number | null;
                  createdAt: any;
                  updatedAt: any;
                  isDeleted: boolean;
                } | null;
              };
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            industry: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            subindustry: {
              __typename?: 'Subindustry';
              id: any;
              name: string;
              description?: string | null;
              imageSubindustry?: string | null;
              parentIndustryId?: any | null;
              sicCodeId?: any | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              parentIndustry?: {
                __typename?: 'Industry';
                id: any;
                name: string;
                description?: string | null;
                imageIndustry?: string | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
                groupId?: any | null;
                sicCodeId?: any | null;
                group?: { __typename?: 'Group'; name: string } | null;
                sicCode?: {
                  __typename?: 'SicCode';
                  id: any;
                  code: number;
                  name: string;
                  sicCodeOffice?: string | null;
                  gicsCodeName?: string | null;
                  gicsCode?: number | null;
                  createdAt: any;
                  updatedAt: any;
                  isDeleted: boolean;
                } | null;
              } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
          };
          user: {
            __typename?: 'User';
            email: any;
            emailVerified: boolean;
            preferences?: string | null;
            rememberToken: string;
            isAdmin: boolean;
            crwdsystemsApi?: string | null;
            createdAt: any;
            updatedAt: any;
            id: any;
            organizationId?: any | null;
            statusId?: any | null;
            isDeleted: boolean;
            sigmaPrimeId?: any | null;
            uniqueHash: string;
            countryCode?: string | null;
            organization?: { __typename?: 'Organization'; id: any; name: string } | null;
            status?: {
              __typename?: 'Status';
              id: any;
              description: string;
              kindId?: any | null;
              name: string;
              updatedAt: any;
              startDate?: any | null;
              endDate?: any | null;
              createdAt: any;
              isDeleted: boolean;
              kind?: {
                __typename?: 'StatusKind';
                id: any;
                name: string;
                description: string;
                updatedAt: any;
                createdAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sigmaPrime?: {
              __typename?: 'SigmaPrime';
              id: any;
              sigmaPrimeKindId?: any | null;
              crwdId?: string | null;
              personParent1SigmaId?: any | null;
              personParent2SigmaId?: any | null;
              personFirstName: string;
              personMiddleName?: string | null;
              personLastName?: string | null;
              personFamilyName?: string | null;
              personNameSuffix?: string | null;
              personHandleName?: any | null;
              personTitleName?: string | null;
              personPrimaryMobile?: string | null;
              personPrimaryPhone?: string | null;
              personPrimaryIpAddress?: string | null;
              personPrimaryEmail: any;
              personBusinessEmail?: any | null;
              personPrimaryFacePhoto?: string | null;
              personPrimaryKycStatus: string;
              personPrimaryAmlStatus?: string | null;
              personPrivacyPolicy: string;
              personStatus: string;
              personExchangeRequest: string;
              personUniqueHash: string;
              personPrimaryLanguageId?: string | null;
              aboutMe?: string | null;
              profilePhoto?: string | null;
              userId?: any | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              kind?: {
                __typename?: 'SigmaPrimeKind';
                id: any;
                name: string;
                description: string;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
              personParent1?: {
                __typename?: 'SigmaPrime';
                id: any;
                crwdId?: string | null;
                personFirstName: string;
                personLastName?: string | null;
              } | null;
              personParent2?: {
                __typename?: 'SigmaPrime';
                id: any;
                crwdId?: string | null;
                personFirstName: string;
                personLastName?: string | null;
              } | null;
              user?: { __typename?: 'User'; id: any; email: any } | null;
            } | null;
          };
        };
        kind: {
          __typename?: 'SmartContractKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          subindustryId: any;
          subindustry: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
        };
      };
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    };
    currency?: { __typename?: 'Currency'; id: any; name: string } | null;
  } | null;
  wallet?: {
    __typename?: 'Wallet';
    id: any;
    name: string;
    walletAddress: string;
    primaryHash: string;
    secondaryHash?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    defaultCurrencyId: any;
    primaryUserId: any;
    secondaryUserId?: any | null;
    walletKindId?: any | null;
    defaultCurrency: {
      __typename?: 'Currency';
      id: any;
      abbreviation?: string | null;
      authorized?: any | null;
      cc?: string | null;
      description?: string | null;
      reserved?: any | null;
      name: string;
      issued?: any | null;
      image?: string | null;
      symbol?: string | null;
      numericCode?: string | null;
      minorUnit?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      countryCode?: string | null;
      currencyKindId?: any | null;
      groupsId?: any | null;
      sectorId?: any | null;
      subindustryId?: any | null;
      country?: {
        __typename?: 'Country';
        code: string;
        name: string;
        region: string;
        phoneCode: number;
        secCode?: string | null;
        stateLabel: string;
        longitude: any;
        latitude: any;
        isoNumber: number;
        iso3: string;
        flagEmojiUnicode: string;
        flagEmoji: string;
        currencyCode: string;
        createdAt?: any | null;
        updatedAt?: any | null;
        isDeleted: boolean;
      } | null;
      kind?: {
        __typename?: 'CurrencyKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId?: any | null;
        groupId?: any | null;
        industryId?: any | null;
        subindustryId?: any | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    };
    primaryUser: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
    secondaryUser?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
    kind?: {
      __typename?: 'WalletKind';
      id: any;
      name: string;
      merchant?: boolean | null;
      organization?: boolean | null;
      user?: boolean | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
};

export type AssetAccountFieldsFragment = {
  __typename?: 'AssetAccount';
  id: any;
  accountNumber?: number | null;
  availableAt: any;
  cvv: string;
  expirationDate: any;
  ibanNumber: string;
  issueDate: any;
  organizationId?: any | null;
  routingNumber: string;
  securityCode: string;
  swiftCode: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  userId?: any | null;
  balanceAmount?: any | null;
  accountKind?: string | null;
  accountName?: string | null;
  achNumber?: string | null;
  ccNumber?: string | null;
  organization?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  user?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
};

export type ProductDistributionFieldsFragment = {
  __typename?: 'ProductDistribution';
  id: any;
  name: string;
  organizationId?: any | null;
  productCategory: string;
  productTags: string;
  productStatusId: any;
  shortDescription: string;
  smartContractId?: any | null;
  userId?: any | null;
  visibility: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  organization?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  productStatus?: {
    __typename?: 'ProductStatus';
    id: any;
    description: string;
    createdAt: any;
    name: string;
    updatedAt: any;
    isDeleted?: boolean | null;
  } | null;
  smartContract?: {
    __typename?: 'SmartContract';
    id: any;
    name: string;
    description: string;
    useCount: number;
    terms: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    clearinghouseId: any;
    smartContractKindId: any;
    clearinghouse: {
      __typename?: 'Clearinghouse';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseKindId: any;
      userId: any;
      kind: {
        __typename?: 'ClearinghouseKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId: any;
        groupId: any;
        industryId: any;
        subindustryId: any;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        group: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        industry: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
      user: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
    };
    kind: {
      __typename?: 'SmartContractKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      subindustryId: any;
      subindustry: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
    };
  } | null;
  user?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
};

export type OrderFieldsFragment = {
  __typename?: 'Order';
  id: any;
  askId: any;
  productDistributionId?: any | null;
  putId: any;
  userId?: any | null;
  orderToken?: any | null;
  shipCountyRegion?: string | null;
  shipEmail: string;
  shipFee: number;
  shipGeo?: string | null;
  shipMethod: string;
  shipPhone: string;
  shipPostal4: string;
  shipPostalCode?: string | null;
  shipProvinceState?: string | null;
  shipSector?: string | null;
  shipStreet1?: string | null;
  shipStreet2?: string | null;
  sigmaPrimeAddressId?: any | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  ask?: { __typename?: 'Ask'; id: any; name: string } | null;
  productDistribution?: { __typename?: 'ProductDistribution'; id: any; name: string } | null;
  put?: { __typename?: 'Put'; id: any; name: string } | null;
  user?: { __typename?: 'User'; id: any; uniqueHash: string; rememberToken: string } | null;
  sigmaPrimeAddress?: { __typename?: 'SigmaPrimeAddress'; id: any; title?: string | null } | null;
};

export type ProductFieldsFragment = {
  __typename?: 'Product';
  id: any;
  actionUrlActivate?: string | null;
  actionUrlDeactivate?: string | null;
  assetId?: any | null;
  binId?: number | null;
  caliberString?: string | null;
  categories?: string | null;
  categoryNumber?: string | null;
  ceNumber?: string | null;
  chainNumberOfDevices?: string | null;
  contentList?: string | null;
  distanceUom?: string | null;
  duration?: string | null;
  effectHeight?: string | null;
  effectWidth?: string | null;
  exNumberList?: string | null;
  facebookReferenceId?: string | null;
  glAccountList?: string | null;
  googleReferenceId?: string | null;
  images?: string | null;
  internalName?: string | null;
  itemCost?: any | null;
  longDescription?: string | null;
  manufacturerBrand?: string | null;
  manufacturerProductId?: any | null;
  manufacturerOrgId?: any | null;
  marketplaces?: string | null;
  markets?: string | null;
  materialId?: any | null;
  metaDescription?: string | null;
  metaKeywords?: string | null;
  metaTitle?: string | null;
  name: string;
  numberPackedItems?: string | null;
  price: any;
  productKindId?: any | null;
  productStatusId?: any | null;
  qtyUom?: string | null;
  reorderSmartContractId?: any | null;
  shops?: string | null;
  sicCodeId?: any | null;
  sku: string;
  smartContractId?: any | null;
  subindustry1Id?: any | null;
  subindustry2Id?: any | null;
  supplierOrgListId?: any | null;
  unHazMatId?: string | null;
  size?: string | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  asset?: { __typename?: 'Asset'; id: any; name: string } | null;
  manufacturerProduct?: { __typename?: 'Product'; id: any; name: string } | null;
  manufacturerOrg?: { __typename?: 'Organization'; id: any; name: string } | null;
  material?: { __typename?: 'Material'; id: any; name: string } | null;
  kind?: { __typename?: 'ProductKind'; id: any; name: string } | null;
  status?: { __typename?: 'ProductStatus'; id: any; name: string } | null;
  reorderSmartContract?: { __typename?: 'SmartContract'; id: any; name: string } | null;
  sicCode?: { __typename?: 'SicCode'; id: any; code: number; name: string } | null;
  smartContract?: { __typename?: 'SmartContract'; id: any; name: string } | null;
  subindustry1?: { __typename?: 'Subindustry'; id: any; name: string } | null;
  subindustry2?: { __typename?: 'Subindustry'; id: any; name: string } | null;
};

export type ProductKindFieldsFragment = {
  __typename?: 'ProductKind';
  id: any;
  name: string;
  clearinghouseId?: any | null;
  description: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
  clearinghouse?: {
    __typename?: 'Clearinghouse';
    id: any;
    name: string;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    clearinghouseKindId: any;
    userId: any;
    kind: {
      __typename?: 'ClearinghouseKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      gicsSectorId: any;
      groupId: any;
      industryId: any;
      subindustryId: any;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      group: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      industry: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      subindustry: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
    };
    user: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
  } | null;
};

export type MaterialFieldsFragment = {
  __typename?: 'Material';
  id: any;
  name: string;
  description: string;
  detailsUrl?: string | null;
  image?: string | null;
  isCommodity?: boolean | null;
  materialCategory: string;
  materialSubcategory?: string | null;
  materialType: string;
  symbol?: string | null;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
};

export type CartFieldsFragment = {
  __typename?: 'Cart';
  id: any;
  emailAddress: string;
  ipaddress: string;
  marketId: any;
  productId: any;
  rememberToken: string;
  shopId: any;
  sigmaPrimeAddress: any;
  sigmaPrimeId: any;
  userId?: any | null;
  createdAt: any;
  updatedAt: any;
  isDeleted?: boolean | null;
  market?: {
    __typename?: 'Market';
    createdAt: any;
    description: string;
    id: any;
    images?: string | null;
    isDeleted: boolean;
    logo?: string | null;
    name: string;
    organizationId: any;
    smartContractId?: any | null;
    subindustry1Id?: any | null;
    subindustry2Id?: any | null;
    updatedAt: any;
    url?: string | null;
    userId?: any | null;
    organization?: {
      __typename?: 'Organization';
      id: any;
      name: string;
      crwdsystemsApiReference?: string | null;
      organizationKindId?: any | null;
      organizationPrimaryAddress1?: string | null;
      organizationPrimaryAddress2?: string | null;
      organizationPrimaryCity?: string | null;
      organizationPrimaryState?: string | null;
      organizationPrimaryPostalCode?: string | null;
      organizationPrimaryRegion?: string | null;
      organizationPrimaryCountryCode?: string | null;
      organizationPrimaryPhone?: string | null;
      geocode?: string | null;
      crwdTicker?: string | null;
      organizationPersonStatus?: string | null;
      personUniqueHash?: string | null;
      personFirstName?: string | null;
      personMiddleName?: string | null;
      personLastName?: string | null;
      companyTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personBusinessEmail?: string | null;
      companyPrimaryWebsite?: string | null;
      companyEmployeeCount?: string | null;
      companyRevenue?: string | null;
      sicCodeId?: any | null;
      industryId?: any | null;
      sub1IndustryId?: any | null;
      sub2IndustryId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'OrganizationKind';
        id: any;
        name: string;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    smartContract?: {
      __typename?: 'SmartContract';
      id: any;
      name: string;
      description: string;
      useCount: number;
      terms: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseId: any;
      smartContractKindId: any;
      clearinghouse: {
        __typename?: 'Clearinghouse';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        clearinghouseKindId: any;
        userId: any;
        kind: {
          __typename?: 'ClearinghouseKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId: any;
          groupId: any;
          industryId: any;
          subindustryId: any;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          group: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          industry: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          subindustry: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
        };
        user: {
          __typename?: 'User';
          email: any;
          emailVerified: boolean;
          preferences?: string | null;
          rememberToken: string;
          isAdmin: boolean;
          crwdsystemsApi?: string | null;
          createdAt: any;
          updatedAt: any;
          id: any;
          organizationId?: any | null;
          statusId?: any | null;
          isDeleted: boolean;
          sigmaPrimeId?: any | null;
          uniqueHash: string;
          countryCode?: string | null;
          organization?: { __typename?: 'Organization'; id: any; name: string } | null;
          status?: {
            __typename?: 'Status';
            id: any;
            description: string;
            kindId?: any | null;
            name: string;
            updatedAt: any;
            startDate?: any | null;
            endDate?: any | null;
            createdAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'StatusKind';
              id: any;
              name: string;
              description: string;
              updatedAt: any;
              createdAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sigmaPrime?: {
            __typename?: 'SigmaPrime';
            id: any;
            sigmaPrimeKindId?: any | null;
            crwdId?: string | null;
            personParent1SigmaId?: any | null;
            personParent2SigmaId?: any | null;
            personFirstName: string;
            personMiddleName?: string | null;
            personLastName?: string | null;
            personFamilyName?: string | null;
            personNameSuffix?: string | null;
            personHandleName?: any | null;
            personTitleName?: string | null;
            personPrimaryMobile?: string | null;
            personPrimaryPhone?: string | null;
            personPrimaryIpAddress?: string | null;
            personPrimaryEmail: any;
            personBusinessEmail?: any | null;
            personPrimaryFacePhoto?: string | null;
            personPrimaryKycStatus: string;
            personPrimaryAmlStatus?: string | null;
            personPrivacyPolicy: string;
            personStatus: string;
            personExchangeRequest: string;
            personUniqueHash: string;
            personPrimaryLanguageId?: string | null;
            aboutMe?: string | null;
            profilePhoto?: string | null;
            userId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'SigmaPrimeKind';
              id: any;
              name: string;
              description: string;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
            personParent1?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            personParent2?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            user?: { __typename?: 'User'; id: any; email: any } | null;
          } | null;
        };
      };
      kind: {
        __typename?: 'SmartContractKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        subindustryId: any;
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    user?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
  } | null;
  product?: {
    __typename?: 'Product';
    id: any;
    actionUrlActivate?: string | null;
    actionUrlDeactivate?: string | null;
    assetId?: any | null;
    binId?: number | null;
    caliberString?: string | null;
    categories?: string | null;
    categoryNumber?: string | null;
    ceNumber?: string | null;
    chainNumberOfDevices?: string | null;
    contentList?: string | null;
    distanceUom?: string | null;
    duration?: string | null;
    effectHeight?: string | null;
    effectWidth?: string | null;
    exNumberList?: string | null;
    facebookReferenceId?: string | null;
    glAccountList?: string | null;
    googleReferenceId?: string | null;
    images?: string | null;
    internalName?: string | null;
    itemCost?: any | null;
    longDescription?: string | null;
    manufacturerBrand?: string | null;
    manufacturerProductId?: any | null;
    manufacturerOrgId?: any | null;
    marketplaces?: string | null;
    markets?: string | null;
    materialId?: any | null;
    metaDescription?: string | null;
    metaKeywords?: string | null;
    metaTitle?: string | null;
    name: string;
    numberPackedItems?: string | null;
    price: any;
    productKindId?: any | null;
    productStatusId?: any | null;
    qtyUom?: string | null;
    reorderSmartContractId?: any | null;
    shops?: string | null;
    sicCodeId?: any | null;
    sku: string;
    smartContractId?: any | null;
    subindustry1Id?: any | null;
    subindustry2Id?: any | null;
    supplierOrgListId?: any | null;
    unHazMatId?: string | null;
    size?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    asset?: { __typename?: 'Asset'; id: any; name: string } | null;
    manufacturerProduct?: { __typename?: 'Product'; id: any; name: string } | null;
    manufacturerOrg?: { __typename?: 'Organization'; id: any; name: string } | null;
    material?: { __typename?: 'Material'; id: any; name: string } | null;
    kind?: { __typename?: 'ProductKind'; id: any; name: string } | null;
    status?: { __typename?: 'ProductStatus'; id: any; name: string } | null;
    reorderSmartContract?: { __typename?: 'SmartContract'; id: any; name: string } | null;
    sicCode?: { __typename?: 'SicCode'; id: any; code: number; name: string } | null;
    smartContract?: { __typename?: 'SmartContract'; id: any; name: string } | null;
    subindustry1?: { __typename?: 'Subindustry'; id: any; name: string } | null;
    subindustry2?: { __typename?: 'Subindustry'; id: any; name: string } | null;
  } | null;
  shop?: {
    __typename?: 'Shop';
    createdAt: any;
    id: any;
    isDeleted: boolean;
    marketId: any;
    shopDescription: string;
    shopKind: string;
    shopUrl: string;
    smartContractId: any;
    uniqueMarketUrl: string;
    updatedAt: any;
    userId: any;
    market?: {
      __typename?: 'Market';
      createdAt: any;
      description: string;
      id: any;
      images?: string | null;
      isDeleted: boolean;
      logo?: string | null;
      name: string;
      organizationId: any;
      smartContractId?: any | null;
      subindustry1Id?: any | null;
      subindustry2Id?: any | null;
      updatedAt: any;
      url?: string | null;
      userId?: any | null;
      organization?: {
        __typename?: 'Organization';
        id: any;
        name: string;
        crwdsystemsApiReference?: string | null;
        organizationKindId?: any | null;
        organizationPrimaryAddress1?: string | null;
        organizationPrimaryAddress2?: string | null;
        organizationPrimaryCity?: string | null;
        organizationPrimaryState?: string | null;
        organizationPrimaryPostalCode?: string | null;
        organizationPrimaryRegion?: string | null;
        organizationPrimaryCountryCode?: string | null;
        organizationPrimaryPhone?: string | null;
        geocode?: string | null;
        crwdTicker?: string | null;
        organizationPersonStatus?: string | null;
        personUniqueHash?: string | null;
        personFirstName?: string | null;
        personMiddleName?: string | null;
        personLastName?: string | null;
        companyTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personBusinessEmail?: string | null;
        companyPrimaryWebsite?: string | null;
        companyEmployeeCount?: string | null;
        companyRevenue?: string | null;
        sicCodeId?: any | null;
        industryId?: any | null;
        sub1IndustryId?: any | null;
        sub2IndustryId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'OrganizationKind';
          id: any;
          name: string;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry1?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry2?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      smartContract?: {
        __typename?: 'SmartContract';
        id: any;
        name: string;
        description: string;
        useCount: number;
        terms: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        clearinghouseId: any;
        smartContractKindId: any;
        clearinghouse: {
          __typename?: 'Clearinghouse';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          clearinghouseKindId: any;
          userId: any;
          kind: {
            __typename?: 'ClearinghouseKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            gicsSectorId: any;
            groupId: any;
            industryId: any;
            subindustryId: any;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            group: {
              __typename?: 'Group';
              id: any;
              name: string;
              description?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              imageGroup?: string | null;
              gicsSectorId: any;
              sicCodeId?: any | null;
              gicsSector: {
                __typename?: 'GicsSector';
                id: any;
                name: string;
                description?: string | null;
                imageSector?: string | null;
                updatedAt: any;
                isDeleted: boolean;
                createdAt: any;
                sicCodeId?: any | null;
                sicCode?: {
                  __typename?: 'SicCode';
                  id: any;
                  code: number;
                  name: string;
                  sicCodeOffice?: string | null;
                  gicsCodeName?: string | null;
                  gicsCode?: number | null;
                  createdAt: any;
                  updatedAt: any;
                  isDeleted: boolean;
                } | null;
              };
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            industry: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            subindustry: {
              __typename?: 'Subindustry';
              id: any;
              name: string;
              description?: string | null;
              imageSubindustry?: string | null;
              parentIndustryId?: any | null;
              sicCodeId?: any | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              parentIndustry?: {
                __typename?: 'Industry';
                id: any;
                name: string;
                description?: string | null;
                imageIndustry?: string | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
                groupId?: any | null;
                sicCodeId?: any | null;
                group?: { __typename?: 'Group'; name: string } | null;
                sicCode?: {
                  __typename?: 'SicCode';
                  id: any;
                  code: number;
                  name: string;
                  sicCodeOffice?: string | null;
                  gicsCodeName?: string | null;
                  gicsCode?: number | null;
                  createdAt: any;
                  updatedAt: any;
                  isDeleted: boolean;
                } | null;
              } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
          };
          user: {
            __typename?: 'User';
            email: any;
            emailVerified: boolean;
            preferences?: string | null;
            rememberToken: string;
            isAdmin: boolean;
            crwdsystemsApi?: string | null;
            createdAt: any;
            updatedAt: any;
            id: any;
            organizationId?: any | null;
            statusId?: any | null;
            isDeleted: boolean;
            sigmaPrimeId?: any | null;
            uniqueHash: string;
            countryCode?: string | null;
            organization?: { __typename?: 'Organization'; id: any; name: string } | null;
            status?: {
              __typename?: 'Status';
              id: any;
              description: string;
              kindId?: any | null;
              name: string;
              updatedAt: any;
              startDate?: any | null;
              endDate?: any | null;
              createdAt: any;
              isDeleted: boolean;
              kind?: {
                __typename?: 'StatusKind';
                id: any;
                name: string;
                description: string;
                updatedAt: any;
                createdAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sigmaPrime?: {
              __typename?: 'SigmaPrime';
              id: any;
              sigmaPrimeKindId?: any | null;
              crwdId?: string | null;
              personParent1SigmaId?: any | null;
              personParent2SigmaId?: any | null;
              personFirstName: string;
              personMiddleName?: string | null;
              personLastName?: string | null;
              personFamilyName?: string | null;
              personNameSuffix?: string | null;
              personHandleName?: any | null;
              personTitleName?: string | null;
              personPrimaryMobile?: string | null;
              personPrimaryPhone?: string | null;
              personPrimaryIpAddress?: string | null;
              personPrimaryEmail: any;
              personBusinessEmail?: any | null;
              personPrimaryFacePhoto?: string | null;
              personPrimaryKycStatus: string;
              personPrimaryAmlStatus?: string | null;
              personPrivacyPolicy: string;
              personStatus: string;
              personExchangeRequest: string;
              personUniqueHash: string;
              personPrimaryLanguageId?: string | null;
              aboutMe?: string | null;
              profilePhoto?: string | null;
              userId?: any | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              kind?: {
                __typename?: 'SigmaPrimeKind';
                id: any;
                name: string;
                description: string;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
              personParent1?: {
                __typename?: 'SigmaPrime';
                id: any;
                crwdId?: string | null;
                personFirstName: string;
                personLastName?: string | null;
              } | null;
              personParent2?: {
                __typename?: 'SigmaPrime';
                id: any;
                crwdId?: string | null;
                personFirstName: string;
                personLastName?: string | null;
              } | null;
              user?: { __typename?: 'User'; id: any; email: any } | null;
            } | null;
          };
        };
        kind: {
          __typename?: 'SmartContractKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          subindustryId: any;
          subindustry: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
        };
      } | null;
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      user?: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      } | null;
    } | null;
    smartContract?: {
      __typename?: 'SmartContract';
      id: any;
      name: string;
      description: string;
      useCount: number;
      terms: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseId: any;
      smartContractKindId: any;
      clearinghouse: {
        __typename?: 'Clearinghouse';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        clearinghouseKindId: any;
        userId: any;
        kind: {
          __typename?: 'ClearinghouseKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId: any;
          groupId: any;
          industryId: any;
          subindustryId: any;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          group: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          industry: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          subindustry: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
        };
        user: {
          __typename?: 'User';
          email: any;
          emailVerified: boolean;
          preferences?: string | null;
          rememberToken: string;
          isAdmin: boolean;
          crwdsystemsApi?: string | null;
          createdAt: any;
          updatedAt: any;
          id: any;
          organizationId?: any | null;
          statusId?: any | null;
          isDeleted: boolean;
          sigmaPrimeId?: any | null;
          uniqueHash: string;
          countryCode?: string | null;
          organization?: { __typename?: 'Organization'; id: any; name: string } | null;
          status?: {
            __typename?: 'Status';
            id: any;
            description: string;
            kindId?: any | null;
            name: string;
            updatedAt: any;
            startDate?: any | null;
            endDate?: any | null;
            createdAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'StatusKind';
              id: any;
              name: string;
              description: string;
              updatedAt: any;
              createdAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sigmaPrime?: {
            __typename?: 'SigmaPrime';
            id: any;
            sigmaPrimeKindId?: any | null;
            crwdId?: string | null;
            personParent1SigmaId?: any | null;
            personParent2SigmaId?: any | null;
            personFirstName: string;
            personMiddleName?: string | null;
            personLastName?: string | null;
            personFamilyName?: string | null;
            personNameSuffix?: string | null;
            personHandleName?: any | null;
            personTitleName?: string | null;
            personPrimaryMobile?: string | null;
            personPrimaryPhone?: string | null;
            personPrimaryIpAddress?: string | null;
            personPrimaryEmail: any;
            personBusinessEmail?: any | null;
            personPrimaryFacePhoto?: string | null;
            personPrimaryKycStatus: string;
            personPrimaryAmlStatus?: string | null;
            personPrivacyPolicy: string;
            personStatus: string;
            personExchangeRequest: string;
            personUniqueHash: string;
            personPrimaryLanguageId?: string | null;
            aboutMe?: string | null;
            profilePhoto?: string | null;
            userId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'SigmaPrimeKind';
              id: any;
              name: string;
              description: string;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
            personParent1?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            personParent2?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            user?: { __typename?: 'User'; id: any; email: any } | null;
          } | null;
        };
      };
      kind: {
        __typename?: 'SmartContractKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        subindustryId: any;
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
    } | null;
    user?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
  } | null;
  sigmaPrime?: {
    __typename?: 'SigmaPrime';
    id: any;
    sigmaPrimeKindId?: any | null;
    crwdId?: string | null;
    personParent1SigmaId?: any | null;
    personParent2SigmaId?: any | null;
    personFirstName: string;
    personMiddleName?: string | null;
    personLastName?: string | null;
    personFamilyName?: string | null;
    personNameSuffix?: string | null;
    personHandleName?: any | null;
    personTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personPrimaryPhone?: string | null;
    personPrimaryIpAddress?: string | null;
    personPrimaryEmail: any;
    personBusinessEmail?: any | null;
    personPrimaryFacePhoto?: string | null;
    personPrimaryKycStatus: string;
    personPrimaryAmlStatus?: string | null;
    personPrivacyPolicy: string;
    personStatus: string;
    personExchangeRequest: string;
    personUniqueHash: string;
    personPrimaryLanguageId?: string | null;
    aboutMe?: string | null;
    profilePhoto?: string | null;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'SigmaPrimeKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    personParent1?: {
      __typename?: 'SigmaPrime';
      id: any;
      crwdId?: string | null;
      personFirstName: string;
      personLastName?: string | null;
    } | null;
    personParent2?: {
      __typename?: 'SigmaPrime';
      id: any;
      crwdId?: string | null;
      personFirstName: string;
      personLastName?: string | null;
    } | null;
    user?: { __typename?: 'User'; id: any; email: any } | null;
  } | null;
  user?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
};

export type MarketFieldsFragment = {
  __typename?: 'Market';
  createdAt: any;
  description: string;
  id: any;
  images?: string | null;
  isDeleted: boolean;
  logo?: string | null;
  name: string;
  organizationId: any;
  smartContractId?: any | null;
  subindustry1Id?: any | null;
  subindustry2Id?: any | null;
  updatedAt: any;
  url?: string | null;
  userId?: any | null;
  organization?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  smartContract?: {
    __typename?: 'SmartContract';
    id: any;
    name: string;
    description: string;
    useCount: number;
    terms: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    clearinghouseId: any;
    smartContractKindId: any;
    clearinghouse: {
      __typename?: 'Clearinghouse';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseKindId: any;
      userId: any;
      kind: {
        __typename?: 'ClearinghouseKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId: any;
        groupId: any;
        industryId: any;
        subindustryId: any;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        group: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        industry: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
      user: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
    };
    kind: {
      __typename?: 'SmartContractKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      subindustryId: any;
      subindustry: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
    };
  } | null;
  subindustry1?: {
    __typename?: 'Subindustry';
    id: any;
    name: string;
    description?: string | null;
    imageSubindustry?: string | null;
    parentIndustryId?: any | null;
    sicCodeId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    parentIndustry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  subindustry2?: {
    __typename?: 'Subindustry';
    id: any;
    name: string;
    description?: string | null;
    imageSubindustry?: string | null;
    parentIndustryId?: any | null;
    sicCodeId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    parentIndustry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  } | null;
  user?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
};

export type OrganizationStatusFieldsFragment = {
  __typename?: 'OrganizationStatus';
  clearinghouseId?: any | null;
  createdAt: any;
  description: string;
  id: any;
  isDeleted: boolean;
  name: string;
  organizationId: any;
  updatedAt: any;
  clearinghouse?: {
    __typename?: 'Clearinghouse';
    id: any;
    name: string;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    clearinghouseKindId: any;
    userId: any;
    kind: {
      __typename?: 'ClearinghouseKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      gicsSectorId: any;
      groupId: any;
      industryId: any;
      subindustryId: any;
      gicsSector: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      group: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      industry: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
      subindustry: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
    };
    user: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
  } | null;
  organization?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
};

export type ShippingFieldsFragment = {
  __typename?: 'Shipping';
  createdAt: any;
  deliveryDate: any;
  deliverytype: string;
  expectedDeliveryDate: any;
  fromOrganizationId: any;
  fromUserId: any;
  id: any;
  materialId: any;
  orderId: any;
  productId: any;
  shipDate: any;
  shippingCarrierOrgId?: any | null;
  shippingDetails: string;
  toOrganizationId: any;
  toUserId: any;
  trackingNumber: string;
  updatedAt: any;
  fromOrganization?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  fromUser?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
  material?: {
    __typename?: 'Material';
    id: any;
    name: string;
    description: string;
    detailsUrl?: string | null;
    image?: string | null;
    isCommodity?: boolean | null;
    materialCategory: string;
    materialSubcategory?: string | null;
    materialType: string;
    symbol?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
  } | null;
  order?: {
    __typename?: 'Order';
    id: any;
    askId: any;
    productDistributionId?: any | null;
    putId: any;
    userId?: any | null;
    orderToken?: any | null;
    shipCountyRegion?: string | null;
    shipEmail: string;
    shipFee: number;
    shipGeo?: string | null;
    shipMethod: string;
    shipPhone: string;
    shipPostal4: string;
    shipPostalCode?: string | null;
    shipProvinceState?: string | null;
    shipSector?: string | null;
    shipStreet1?: string | null;
    shipStreet2?: string | null;
    sigmaPrimeAddressId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    ask?: { __typename?: 'Ask'; id: any; name: string } | null;
    productDistribution?: { __typename?: 'ProductDistribution'; id: any; name: string } | null;
    put?: { __typename?: 'Put'; id: any; name: string } | null;
    user?: { __typename?: 'User'; id: any; uniqueHash: string; rememberToken: string } | null;
    sigmaPrimeAddress?: { __typename?: 'SigmaPrimeAddress'; id: any; title?: string | null } | null;
  } | null;
  product?: {
    __typename?: 'Product';
    id: any;
    actionUrlActivate?: string | null;
    actionUrlDeactivate?: string | null;
    assetId?: any | null;
    binId?: number | null;
    caliberString?: string | null;
    categories?: string | null;
    categoryNumber?: string | null;
    ceNumber?: string | null;
    chainNumberOfDevices?: string | null;
    contentList?: string | null;
    distanceUom?: string | null;
    duration?: string | null;
    effectHeight?: string | null;
    effectWidth?: string | null;
    exNumberList?: string | null;
    facebookReferenceId?: string | null;
    glAccountList?: string | null;
    googleReferenceId?: string | null;
    images?: string | null;
    internalName?: string | null;
    itemCost?: any | null;
    longDescription?: string | null;
    manufacturerBrand?: string | null;
    manufacturerProductId?: any | null;
    manufacturerOrgId?: any | null;
    marketplaces?: string | null;
    markets?: string | null;
    materialId?: any | null;
    metaDescription?: string | null;
    metaKeywords?: string | null;
    metaTitle?: string | null;
    name: string;
    numberPackedItems?: string | null;
    price: any;
    productKindId?: any | null;
    productStatusId?: any | null;
    qtyUom?: string | null;
    reorderSmartContractId?: any | null;
    shops?: string | null;
    sicCodeId?: any | null;
    sku: string;
    smartContractId?: any | null;
    subindustry1Id?: any | null;
    subindustry2Id?: any | null;
    supplierOrgListId?: any | null;
    unHazMatId?: string | null;
    size?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    asset?: { __typename?: 'Asset'; id: any; name: string } | null;
    manufacturerProduct?: { __typename?: 'Product'; id: any; name: string } | null;
    manufacturerOrg?: { __typename?: 'Organization'; id: any; name: string } | null;
    material?: { __typename?: 'Material'; id: any; name: string } | null;
    kind?: { __typename?: 'ProductKind'; id: any; name: string } | null;
    status?: { __typename?: 'ProductStatus'; id: any; name: string } | null;
    reorderSmartContract?: { __typename?: 'SmartContract'; id: any; name: string } | null;
    sicCode?: { __typename?: 'SicCode'; id: any; code: number; name: string } | null;
    smartContract?: { __typename?: 'SmartContract'; id: any; name: string } | null;
    subindustry1?: { __typename?: 'Subindustry'; id: any; name: string } | null;
    subindustry2?: { __typename?: 'Subindustry'; id: any; name: string } | null;
  } | null;
  shippingCarrierOrg?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  toOrganization?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  toUser?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
};

export type ShopFieldsFragment = {
  __typename?: 'Shop';
  createdAt: any;
  id: any;
  isDeleted: boolean;
  marketId: any;
  shopDescription: string;
  shopKind: string;
  shopUrl: string;
  smartContractId: any;
  uniqueMarketUrl: string;
  updatedAt: any;
  userId: any;
  market?: {
    __typename?: 'Market';
    createdAt: any;
    description: string;
    id: any;
    images?: string | null;
    isDeleted: boolean;
    logo?: string | null;
    name: string;
    organizationId: any;
    smartContractId?: any | null;
    subindustry1Id?: any | null;
    subindustry2Id?: any | null;
    updatedAt: any;
    url?: string | null;
    userId?: any | null;
    organization?: {
      __typename?: 'Organization';
      id: any;
      name: string;
      crwdsystemsApiReference?: string | null;
      organizationKindId?: any | null;
      organizationPrimaryAddress1?: string | null;
      organizationPrimaryAddress2?: string | null;
      organizationPrimaryCity?: string | null;
      organizationPrimaryState?: string | null;
      organizationPrimaryPostalCode?: string | null;
      organizationPrimaryRegion?: string | null;
      organizationPrimaryCountryCode?: string | null;
      organizationPrimaryPhone?: string | null;
      geocode?: string | null;
      crwdTicker?: string | null;
      organizationPersonStatus?: string | null;
      personUniqueHash?: string | null;
      personFirstName?: string | null;
      personMiddleName?: string | null;
      personLastName?: string | null;
      companyTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personBusinessEmail?: string | null;
      companyPrimaryWebsite?: string | null;
      companyEmployeeCount?: string | null;
      companyRevenue?: string | null;
      sicCodeId?: any | null;
      industryId?: any | null;
      sub1IndustryId?: any | null;
      sub2IndustryId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'OrganizationKind';
        id: any;
        name: string;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      industry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry1?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry2?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    } | null;
    smartContract?: {
      __typename?: 'SmartContract';
      id: any;
      name: string;
      description: string;
      useCount: number;
      terms: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseId: any;
      smartContractKindId: any;
      clearinghouse: {
        __typename?: 'Clearinghouse';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        clearinghouseKindId: any;
        userId: any;
        kind: {
          __typename?: 'ClearinghouseKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          gicsSectorId: any;
          groupId: any;
          industryId: any;
          subindustryId: any;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          group: {
            __typename?: 'Group';
            id: any;
            name: string;
            description?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            imageGroup?: string | null;
            gicsSectorId: any;
            sicCodeId?: any | null;
            gicsSector: {
              __typename?: 'GicsSector';
              id: any;
              name: string;
              description?: string | null;
              imageSector?: string | null;
              updatedAt: any;
              isDeleted: boolean;
              createdAt: any;
              sicCodeId?: any | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            };
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          industry: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          subindustry: {
            __typename?: 'Subindustry';
            id: any;
            name: string;
            description?: string | null;
            imageSubindustry?: string | null;
            parentIndustryId?: any | null;
            sicCodeId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            parentIndustry?: {
              __typename?: 'Industry';
              id: any;
              name: string;
              description?: string | null;
              imageIndustry?: string | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
              groupId?: any | null;
              sicCodeId?: any | null;
              group?: { __typename?: 'Group'; name: string } | null;
              sicCode?: {
                __typename?: 'SicCode';
                id: any;
                code: number;
                name: string;
                sicCodeOffice?: string | null;
                gicsCodeName?: string | null;
                gicsCode?: number | null;
                createdAt: any;
                updatedAt: any;
                isDeleted: boolean;
              } | null;
            } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
        };
        user: {
          __typename?: 'User';
          email: any;
          emailVerified: boolean;
          preferences?: string | null;
          rememberToken: string;
          isAdmin: boolean;
          crwdsystemsApi?: string | null;
          createdAt: any;
          updatedAt: any;
          id: any;
          organizationId?: any | null;
          statusId?: any | null;
          isDeleted: boolean;
          sigmaPrimeId?: any | null;
          uniqueHash: string;
          countryCode?: string | null;
          organization?: { __typename?: 'Organization'; id: any; name: string } | null;
          status?: {
            __typename?: 'Status';
            id: any;
            description: string;
            kindId?: any | null;
            name: string;
            updatedAt: any;
            startDate?: any | null;
            endDate?: any | null;
            createdAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'StatusKind';
              id: any;
              name: string;
              description: string;
              updatedAt: any;
              createdAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sigmaPrime?: {
            __typename?: 'SigmaPrime';
            id: any;
            sigmaPrimeKindId?: any | null;
            crwdId?: string | null;
            personParent1SigmaId?: any | null;
            personParent2SigmaId?: any | null;
            personFirstName: string;
            personMiddleName?: string | null;
            personLastName?: string | null;
            personFamilyName?: string | null;
            personNameSuffix?: string | null;
            personHandleName?: any | null;
            personTitleName?: string | null;
            personPrimaryMobile?: string | null;
            personPrimaryPhone?: string | null;
            personPrimaryIpAddress?: string | null;
            personPrimaryEmail: any;
            personBusinessEmail?: any | null;
            personPrimaryFacePhoto?: string | null;
            personPrimaryKycStatus: string;
            personPrimaryAmlStatus?: string | null;
            personPrivacyPolicy: string;
            personStatus: string;
            personExchangeRequest: string;
            personUniqueHash: string;
            personPrimaryLanguageId?: string | null;
            aboutMe?: string | null;
            profilePhoto?: string | null;
            userId?: any | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            kind?: {
              __typename?: 'SigmaPrimeKind';
              id: any;
              name: string;
              description: string;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
            personParent1?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            personParent2?: {
              __typename?: 'SigmaPrime';
              id: any;
              crwdId?: string | null;
              personFirstName: string;
              personLastName?: string | null;
            } | null;
            user?: { __typename?: 'User'; id: any; email: any } | null;
          } | null;
        };
      };
      kind: {
        __typename?: 'SmartContractKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        subindustryId: any;
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    user?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
  } | null;
  smartContract?: {
    __typename?: 'SmartContract';
    id: any;
    name: string;
    description: string;
    useCount: number;
    terms: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    clearinghouseId: any;
    smartContractKindId: any;
    clearinghouse: {
      __typename?: 'Clearinghouse';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseKindId: any;
      userId: any;
      kind: {
        __typename?: 'ClearinghouseKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId: any;
        groupId: any;
        industryId: any;
        subindustryId: any;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        group: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        industry: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
      user: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
    };
    kind: {
      __typename?: 'SmartContractKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      subindustryId: any;
      subindustry: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
    };
  } | null;
  user?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
};

export type SupplyChainWaypointFieldsFragment = {
  __typename?: 'SupplyChainWaypoint';
  actualProcessTime?: any | null;
  actualQueueTime?: any | null;
  avgProcessTime?: any | null;
  avgQueueTime?: any | null;
  consigneeOrgId?: any | null;
  consignorOrgId?: any | null;
  createdAt: any;
  customsOrgId?: any | null;
  description: string;
  freightForwarderOrgId?: any | null;
  fromShippingCarrierOrgId?: any | null;
  geo: string;
  id: any;
  isDeleted: boolean;
  latitude: string;
  longitude: string;
  manufacturerOrgId?: any | null;
  name: string;
  organizationFunction: string;
  originDestinationAgentOrgId?: any | null;
  shippingId: number;
  smartContractId?: any | null;
  status: string;
  toShippingCarrierOrgId?: any | null;
  updatedAt: any;
  waypointC2c?: string | null;
  waypointCt?: string | null;
  waypointNumber: number;
  waypointPoi?: string | null;
  waypointTotal: number;
  consigneeOrg?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  consignorOrg?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  customsOrg?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  freightForwarderOrg?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  fromShippingCarrierOrg?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  manufacturerOrg?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  originDestinationAgentOrg?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
  smartContract?: {
    __typename?: 'SmartContract';
    id: any;
    name: string;
    description: string;
    useCount: number;
    terms: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    clearinghouseId: any;
    smartContractKindId: any;
    clearinghouse: {
      __typename?: 'Clearinghouse';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      clearinghouseKindId: any;
      userId: any;
      kind: {
        __typename?: 'ClearinghouseKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId: any;
        groupId: any;
        industryId: any;
        subindustryId: any;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        group: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        industry: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        subindustry: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
      };
      user: {
        __typename?: 'User';
        email: any;
        emailVerified: boolean;
        preferences?: string | null;
        rememberToken: string;
        isAdmin: boolean;
        crwdsystemsApi?: string | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        organizationId?: any | null;
        statusId?: any | null;
        isDeleted: boolean;
        sigmaPrimeId?: any | null;
        uniqueHash: string;
        countryCode?: string | null;
        organization?: { __typename?: 'Organization'; id: any; name: string } | null;
        status?: {
          __typename?: 'Status';
          id: any;
          description: string;
          kindId?: any | null;
          name: string;
          updatedAt: any;
          startDate?: any | null;
          endDate?: any | null;
          createdAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'StatusKind';
            id: any;
            name: string;
            description: string;
            updatedAt: any;
            createdAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sigmaPrime?: {
          __typename?: 'SigmaPrime';
          id: any;
          sigmaPrimeKindId?: any | null;
          crwdId?: string | null;
          personParent1SigmaId?: any | null;
          personParent2SigmaId?: any | null;
          personFirstName: string;
          personMiddleName?: string | null;
          personLastName?: string | null;
          personFamilyName?: string | null;
          personNameSuffix?: string | null;
          personHandleName?: any | null;
          personTitleName?: string | null;
          personPrimaryMobile?: string | null;
          personPrimaryPhone?: string | null;
          personPrimaryIpAddress?: string | null;
          personPrimaryEmail: any;
          personBusinessEmail?: any | null;
          personPrimaryFacePhoto?: string | null;
          personPrimaryKycStatus: string;
          personPrimaryAmlStatus?: string | null;
          personPrivacyPolicy: string;
          personStatus: string;
          personExchangeRequest: string;
          personUniqueHash: string;
          personPrimaryLanguageId?: string | null;
          aboutMe?: string | null;
          profilePhoto?: string | null;
          userId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          kind?: {
            __typename?: 'SigmaPrimeKind';
            id: any;
            name: string;
            description: string;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
          personParent1?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          personParent2?: {
            __typename?: 'SigmaPrime';
            id: any;
            crwdId?: string | null;
            personFirstName: string;
            personLastName?: string | null;
          } | null;
          user?: { __typename?: 'User'; id: any; email: any } | null;
        } | null;
      };
    };
    kind: {
      __typename?: 'SmartContractKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      subindustryId: any;
      subindustry: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      };
    };
  } | null;
  toShippingCarrierOrg?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
};

export type CacheFieldsFragment = {
  __typename?: 'Cache';
  key: string;
  value: string;
  createdAt: any;
  updatedAt: any;
  expiresAt: any;
};

export type KycKindFieldsFragment = {
  __typename?: 'KycKind';
  id: any;
  name: string;
  description: string;
  createdAt: any;
  updatedAt: any;
  isDeleted: boolean;
};

export type KycStatusFieldsFragment = {
  __typename?: 'KycStatus';
  id: any;
  name: string;
  description: string;
  isDeleted: boolean;
  createdAt: any;
  countryCode?: string | null;
  updatedAt: any;
  kycKindId?: any | null;
  sigmaPrimeId?: any | null;
  organizationId?: any | null;
  kycKind?: {
    __typename?: 'KycKind';
    id: any;
    name: string;
    description: string;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
  } | null;
  sigmaPrime?: {
    __typename?: 'SigmaPrime';
    id: any;
    sigmaPrimeKindId?: any | null;
    crwdId?: string | null;
    personParent1SigmaId?: any | null;
    personParent2SigmaId?: any | null;
    personFirstName: string;
    personMiddleName?: string | null;
    personLastName?: string | null;
    personFamilyName?: string | null;
    personNameSuffix?: string | null;
    personHandleName?: any | null;
    personTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personPrimaryPhone?: string | null;
    personPrimaryIpAddress?: string | null;
    personPrimaryEmail: any;
    personBusinessEmail?: any | null;
    personPrimaryFacePhoto?: string | null;
    personPrimaryKycStatus: string;
    personPrimaryAmlStatus?: string | null;
    personPrivacyPolicy: string;
    personStatus: string;
    personExchangeRequest: string;
    personUniqueHash: string;
    personPrimaryLanguageId?: string | null;
    aboutMe?: string | null;
    profilePhoto?: string | null;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'SigmaPrimeKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    personParent1?: {
      __typename?: 'SigmaPrime';
      id: any;
      crwdId?: string | null;
      personFirstName: string;
      personLastName?: string | null;
    } | null;
    personParent2?: {
      __typename?: 'SigmaPrime';
      id: any;
      crwdId?: string | null;
      personFirstName: string;
      personLastName?: string | null;
    } | null;
    user?: { __typename?: 'User'; id: any; email: any } | null;
  } | null;
  organization?: {
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'OrganizationKind';
      id: any;
      name: string;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null;
    sicCode?: {
      __typename?: 'SicCode';
      id: any;
      code: number;
      name: string;
      sicCodeOffice?: string | null;
      gicsCodeName?: string | null;
      gicsCode?: number | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: any;
      name: string;
      description?: string | null;
      imageIndustry?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      groupId?: any | null;
      sicCodeId?: any | null;
      group?: { __typename?: 'Group'; name: string } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry1?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    subindustry2?: {
      __typename?: 'Subindustry';
      id: any;
      name: string;
      description?: string | null;
      imageSubindustry?: string | null;
      parentIndustryId?: any | null;
      sicCodeId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      parentIndustry?: {
        __typename?: 'Industry';
        id: any;
        name: string;
        description?: string | null;
        imageIndustry?: string | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        groupId?: any | null;
        sicCodeId?: any | null;
        group?: { __typename?: 'Group'; name: string } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sicCode?: {
        __typename?: 'SicCode';
        id: any;
        code: number;
        name: string;
        sicCodeOffice?: string | null;
        gicsCodeName?: string | null;
        gicsCode?: number | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
  } | null;
};

export type UpsertAppraisalMutationVariables = Exact<{
  objects: Array<AppraisalInsertInput> | AppraisalInsertInput;
}>;

export type UpsertAppraisalMutation = {
  __typename?: 'mutation_root';
  upsertAppraisal?: { __typename?: 'AppraisalMutationResponse'; affectedRows: number } | null;
};

export type UpsertAskMutationVariables = Exact<{
  objects: Array<AskInsertInput> | AskInsertInput;
}>;

export type UpsertAskMutation = {
  __typename?: 'mutation_root';
  upsertAsk?: { __typename?: 'AskMutationResponse'; affectedRows: number } | null;
};

export type UpsertAskPutTransactionMutationVariables = Exact<{
  objects: Array<AskPutTransactionInsertInput> | AskPutTransactionInsertInput;
}>;

export type UpsertAskPutTransactionMutation = {
  __typename?: 'mutation_root';
  upsertAskPutTransaction?: { __typename?: 'AskPutTransactionMutationResponse'; affectedRows: number } | null;
};

export type UpsertCertificationMutationVariables = Exact<{
  objects: Array<CertificationInsertInput> | CertificationInsertInput;
}>;

export type UpsertCertificationMutation = {
  __typename?: 'mutation_root';
  upsertCertification?: { __typename?: 'CertificationMutationResponse'; affectedRows: number } | null;
};

export type UpsertClearinghouseMutationVariables = Exact<{
  objects: Array<ClearinghouseInsertInput> | ClearinghouseInsertInput;
}>;

export type UpsertClearinghouseMutation = {
  __typename?: 'mutation_root';
  upsertClearinghouse?: { __typename?: 'ClearinghouseMutationResponse'; affectedRows: number } | null;
};

export type UpsertClearinghouseCertificationMutationVariables = Exact<{
  objects: Array<ClearinghouseCertificationInsertInput> | ClearinghouseCertificationInsertInput;
}>;

export type UpsertClearinghouseCertificationMutation = {
  __typename?: 'mutation_root';
  upsertClearinghouseCertification?: {
    __typename?: 'ClearinghouseCertificationMutationResponse';
    affectedRows: number;
  } | null;
};

export type UpsertClearinghouseKindMutationVariables = Exact<{
  objects: Array<ClearinghouseKindInsertInput> | ClearinghouseKindInsertInput;
}>;

export type UpsertClearinghouseKindMutation = {
  __typename?: 'mutation_root';
  upsertClearinghouseKind?: { __typename?: 'ClearinghouseKindMutationResponse'; affectedRows: number } | null;
};

export type UpsertCountriesMutationVariables = Exact<{
  objects: Array<CountryInsertInput> | CountryInsertInput;
}>;

export type UpsertCountriesMutation = {
  __typename?: 'mutation_root';
  upsertCountries?: { __typename?: 'CountryMutationResponse'; affectedRows: number } | null;
};

export type UpsertCurrencyMutationVariables = Exact<{
  objects: Array<CurrencyInsertInput> | CurrencyInsertInput;
}>;

export type UpsertCurrencyMutation = {
  __typename?: 'mutation_root';
  upsertCurrency?: { __typename?: 'CurrencyMutationResponse'; affectedRows: number } | null;
};

export type UpsertCurrencyKindMutationVariables = Exact<{
  objects: Array<CurrencyKindInsertInput> | CurrencyKindInsertInput;
}>;

export type UpsertCurrencyKindMutation = {
  __typename?: 'mutation_root';
  upsertCurrencyKind?: { __typename?: 'CurrencyKindMutationResponse'; affectedRows: number } | null;
};

export type UpsertCurrencyOwnerMutationVariables = Exact<{
  objects: Array<CurrencyOwnerInsertInput> | CurrencyOwnerInsertInput;
}>;

export type UpsertCurrencyOwnerMutation = {
  __typename?: 'mutation_root';
  upsertCurrencyOwner?: { __typename?: 'CurrencyOwnerMutationResponse'; affectedRows: number } | null;
};

export type UpsertGroupsMutationVariables = Exact<{
  objects: Array<GroupInsertInput> | GroupInsertInput;
}>;

export type UpsertGroupsMutation = {
  __typename?: 'mutation_root';
  upsertGroups?: { __typename?: 'GroupMutationResponse'; affectedRows: number } | null;
};

export type UpsertOrganizationsMutationVariables = Exact<{
  objects: Array<OrganizationInsertInput> | OrganizationInsertInput;
}>;

export type UpsertOrganizationsMutation = {
  __typename?: 'mutation_root';
  upsertOrganizations?: { __typename?: 'OrganizationMutationResponse'; affectedRows: number } | null;
};

export type UpsertOrganizationKindMutationVariables = Exact<{
  objects: Array<OrganizationKindInsertInput> | OrganizationKindInsertInput;
}>;

export type UpsertOrganizationKindMutation = {
  __typename?: 'mutation_root';
  upsertOrganizationKind?: { __typename?: 'OrganizationKindMutationResponse'; affectedRows: number } | null;
};

export type UpsertSubindustriesMutationVariables = Exact<{
  objects: Array<SubindustryInsertInput> | SubindustryInsertInput;
}>;

export type UpsertSubindustriesMutation = {
  __typename?: 'mutation_root';
  upsertSubindustries?: { __typename?: 'SubindustryMutationResponse'; affectedRows: number } | null;
};

export type UpsertIndustriesMutationVariables = Exact<{
  objects: Array<IndustryInsertInput> | IndustryInsertInput;
}>;

export type UpsertIndustriesMutation = {
  __typename?: 'mutation_root';
  upsertIndustries?: { __typename?: 'IndustryMutationResponse'; affectedRows: number } | null;
};

export type UpsertExchangeKindMutationVariables = Exact<{
  objects: Array<ExchangeKindInsertInput> | ExchangeKindInsertInput;
}>;

export type UpsertExchangeKindMutation = {
  __typename?: 'mutation_root';
  upsertExchangeKind?: { __typename?: 'ExchangeKindMutationResponse'; affectedRows: number } | null;
};

export type UpsertExchangeMutationVariables = Exact<{
  objects: Array<ExchangeInsertInput> | ExchangeInsertInput;
}>;

export type UpsertExchangeMutation = {
  __typename?: 'mutation_root';
  upsertExchange?: { __typename?: 'ExchangeMutationResponse'; affectedRows: number } | null;
};

export type UpsertSicCodeMutationVariables = Exact<{
  objects: Array<SicCodeInsertInput> | SicCodeInsertInput;
}>;

export type UpsertSicCodeMutation = {
  __typename?: 'mutation_root';
  upsertSicCode?: { __typename?: 'SicCodeMutationResponse'; affectedRows: number } | null;
};

export type UpsertGicsSectorMutationVariables = Exact<{
  objects: Array<GicsSectorInsertInput> | GicsSectorInsertInput;
}>;

export type UpsertGicsSectorMutation = {
  __typename?: 'mutation_root';
  upsertGicsSector?: { __typename?: 'GicsSectorMutationResponse'; affectedRows: number } | null;
};

export type UpsertStatusesMutationVariables = Exact<{
  objects: Array<StatusInsertInput> | StatusInsertInput;
}>;

export type UpsertStatusesMutation = {
  __typename?: 'mutation_root';
  upsertStatuses?: { __typename?: 'StatusMutationResponse'; affectedRows: number } | null;
};

export type UpsertStatusKindsMutationVariables = Exact<{
  objects: Array<StatusKindInsertInput> | StatusKindInsertInput;
}>;

export type UpsertStatusKindsMutation = {
  __typename?: 'mutation_root';
  upsertStatusKinds?: { __typename?: 'StatusKindMutationResponse'; affectedRows: number } | null;
};

export type UpsertInteractionMutationVariables = Exact<{
  objects: Array<InteractionInsertInput> | InteractionInsertInput;
}>;

export type UpsertInteractionMutation = {
  __typename?: 'mutation_root';
  upsertInteraction?: { __typename?: 'InteractionMutationResponse'; affectedRows: number } | null;
};

export type UpsertMerchantMutationVariables = Exact<{
  objects: Array<MerchantInsertInput> | MerchantInsertInput;
}>;

export type UpsertMerchantMutation = {
  __typename?: 'mutation_root';
  upsertMerchant?: { __typename?: 'MerchantMutationResponse'; affectedRows: number } | null;
};

export type UpsertMerchantCurrencyMutationVariables = Exact<{
  objects: Array<MerchantCurrencyInsertInput> | MerchantCurrencyInsertInput;
}>;

export type UpsertMerchantCurrencyMutation = {
  __typename?: 'mutation_root';
  upsertMerchantCurrency?: { __typename?: 'MerchantCurrencyMutationResponse'; affectedRows: number } | null;
};

export type UpsertMerchantOrganizationMutationVariables = Exact<{
  objects: Array<MerchantOrganizationInsertInput> | MerchantOrganizationInsertInput;
}>;

export type UpsertMerchantOrganizationMutation = {
  __typename?: 'mutation_root';
  upsertMerchantOrganization?: { __typename?: 'MerchantOrganizationMutationResponse'; affectedRows: number } | null;
};

export type UpsertAssetMutationVariables = Exact<{
  objects: Array<AssetInsertInput> | AssetInsertInput;
}>;

export type UpsertAssetMutation = {
  __typename?: 'mutation_root';
  upsertAsset?: { __typename?: 'AssetMutationResponse'; affectedRows: number } | null;
};

export type UpsertAssetKindMutationVariables = Exact<{
  objects: Array<AssetKindInsertInput> | AssetKindInsertInput;
}>;

export type UpsertAssetKindMutation = {
  __typename?: 'mutation_root';
  upsertAssetKind?: { __typename?: 'AssetKindMutationResponse'; affectedRows: number } | null;
};

export type UpsertPutMutationVariables = Exact<{
  objects: Array<PutInsertInput> | PutInsertInput;
}>;

export type UpsertPutMutation = {
  __typename?: 'mutation_root';
  upsertPut?: { __typename?: 'PutMutationResponse'; affectedRows: number } | null;
};

export type UpsertSettingsMutationVariables = Exact<{
  objects: Array<SettingInsertInput> | SettingInsertInput;
}>;

export type UpsertSettingsMutation = {
  __typename?: 'mutation_root';
  upsertSettings?: { __typename?: 'SettingMutationResponse'; affectedRows: number } | null;
};

export type UpsertSmartContractLineageMutationVariables = Exact<{
  objects: Array<SmartContractLineageInsertInput> | SmartContractLineageInsertInput;
}>;

export type UpsertSmartContractLineageMutation = {
  __typename?: 'mutation_root';
  upsertSmartContractLineage?: { __typename?: 'SmartContractLineageMutationResponse'; affectedRows: number } | null;
};

export type UpsertDocumentUserMutationVariables = Exact<{
  objects: Array<DocumentUserInsertInput> | DocumentUserInsertInput;
}>;

export type UpsertDocumentUserMutation = {
  __typename?: 'mutation_root';
  upsertDocumentUser?: { __typename?: 'DocumentUserMutationResponse'; affectedRows: number } | null;
};

export type UpsertProductStatusMutationVariables = Exact<{
  objects: Array<ProductStatusInsertInput> | ProductStatusInsertInput;
}>;

export type UpsertProductStatusMutation = {
  __typename?: 'mutation_root';
  upsertProductStatusLineage?: { __typename?: 'ProductStatusMutationResponse'; affectedRows: number } | null;
};

export type UpsertSmartContractMutationVariables = Exact<{
  objects: Array<SmartContractInsertInput> | SmartContractInsertInput;
}>;

export type UpsertSmartContractMutation = {
  __typename?: 'mutation_root';
  upsertSmartContract?: { __typename?: 'SmartContractMutationResponse'; affectedRows: number } | null;
};

export type UpsertSmartContractKindMutationVariables = Exact<{
  objects: Array<SmartContractKindInsertInput> | SmartContractKindInsertInput;
}>;

export type UpsertSmartContractKindMutation = {
  __typename?: 'mutation_root';
  upsertSmartContractKind?: { __typename?: 'SmartContractKindMutationResponse'; affectedRows: number } | null;
};

export type UpsertTradeMutationVariables = Exact<{
  objects: Array<TradeInsertInput> | TradeInsertInput;
}>;

export type UpsertTradeMutation = {
  __typename?: 'mutation_root';
  upsertTrade?: { __typename?: 'TradeMutationResponse'; affectedRows: number } | null;
};

export type UpsertTransactionMutationVariables = Exact<{
  objects: Array<TransactionInsertInput> | TransactionInsertInput;
}>;

export type UpsertTransactionMutation = {
  __typename?: 'mutation_root';
  upsertTransaction?: { __typename?: 'TransactionMutationResponse'; affectedRows: number } | null;
};

export type UpsertTransactionKindMutationVariables = Exact<{
  objects: Array<TransactionKindInsertInput> | TransactionKindInsertInput;
}>;

export type UpsertTransactionKindMutation = {
  __typename?: 'mutation_root';
  upsertTransaction?: { __typename?: 'TransactionKindMutationResponse'; affectedRows: number } | null;
};

export type UpsertTradeKindMutationVariables = Exact<{
  objects: Array<TradeKindInsertInput> | TradeKindInsertInput;
}>;

export type UpsertTradeKindMutation = {
  __typename?: 'mutation_root';
  upsertTradeKind?: { __typename?: 'TradeKindMutationResponse'; affectedRows: number } | null;
};

export type UpsertWalletMutationVariables = Exact<{
  objects: Array<WalletInsertInput> | WalletInsertInput;
}>;

export type UpsertWalletMutation = {
  __typename?: 'mutation_root';
  upsertWallet?: { __typename?: 'WalletMutationResponse'; affectedRows: number } | null;
};

export type UpsertWalletKindMutationVariables = Exact<{
  objects: Array<WalletKindInsertInput> | WalletKindInsertInput;
}>;

export type UpsertWalletKindMutation = {
  __typename?: 'mutation_root';
  upsertWalletKind?: { __typename?: 'WalletKindMutationResponse'; affectedRows: number } | null;
};

export type UpsertSigmaPrimeKindMutationVariables = Exact<{
  objects: Array<SigmaPrimeKindInsertInput> | SigmaPrimeKindInsertInput;
}>;

export type UpsertSigmaPrimeKindMutation = {
  __typename?: 'mutation_root';
  upsertSigmaPrimeKind?: { __typename?: 'SigmaPrimeKindMutationResponse'; affectedRows: number } | null;
};

export type UpsertSigmaPrimeMutationVariables = Exact<{
  objects: Array<SigmaPrimeInsertInput> | SigmaPrimeInsertInput;
}>;

export type UpsertSigmaPrimeMutation = {
  __typename?: 'mutation_root';
  upsertSigmaPrime?: {
    __typename?: 'SigmaPrimeMutationResponse';
    affectedRows: number;
    returning: Array<{
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    }>;
  } | null;
};

export type UpdateSigmaPrimeMutationVariables = Exact<{
  id: Scalars['uuid'];
  set: SigmaPrimeSetInput;
}>;

export type UpdateSigmaPrimeMutation = {
  __typename?: 'mutation_root';
  updateSigmaPrimeByPk?: {
    __typename?: 'SigmaPrime';
    id: any;
    sigmaPrimeKindId?: any | null;
    crwdId?: string | null;
    personParent1SigmaId?: any | null;
    personParent2SigmaId?: any | null;
    personFirstName: string;
    personMiddleName?: string | null;
    personLastName?: string | null;
    personFamilyName?: string | null;
    personNameSuffix?: string | null;
    personHandleName?: any | null;
    personTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personPrimaryPhone?: string | null;
    personPrimaryIpAddress?: string | null;
    personPrimaryEmail: any;
    personBusinessEmail?: any | null;
    personPrimaryFacePhoto?: string | null;
    personPrimaryKycStatus: string;
    personPrimaryAmlStatus?: string | null;
    personPrivacyPolicy: string;
    personStatus: string;
    personExchangeRequest: string;
    personUniqueHash: string;
    personPrimaryLanguageId?: string | null;
    aboutMe?: string | null;
    profilePhoto?: string | null;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'SigmaPrimeKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    personParent1?: {
      __typename?: 'SigmaPrime';
      id: any;
      crwdId?: string | null;
      personFirstName: string;
      personLastName?: string | null;
    } | null;
    personParent2?: {
      __typename?: 'SigmaPrime';
      id: any;
      crwdId?: string | null;
      personFirstName: string;
      personLastName?: string | null;
    } | null;
    user?: { __typename?: 'User'; id: any; email: any } | null;
  } | null;
};

export type UpsertUserMutationVariables = Exact<{
  objects: Array<UserInsertInput> | UserInsertInput;
}>;

export type UpsertUserMutation = {
  __typename?: 'mutation_root';
  upsertUser?: {
    __typename?: 'UserMutationResponse';
    affectedRows: number;
    returning: Array<{
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    }>;
  } | null;
};

export type InsertUserMutationVariables = Exact<{
  object: UserInsertInput;
}>;

export type InsertUserMutation = {
  __typename?: 'mutation_root';
  insertUser?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
};

export type InsertUserAuthMutationVariables = Exact<{
  object: UserAuthInsertInput;
}>;

export type InsertUserAuthMutation = {
  __typename?: 'mutation_root';
  insertUserAuth?: {
    __typename?: 'UserAuth';
    userId: any;
    hash: string;
    salt: string;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['uuid'];
  set: UserSetInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'mutation_root';
  updateUserByPk?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
};

export type UpsertUserAuthMutationVariables = Exact<{
  object: UserAuthInsertInput;
}>;

export type UpsertUserAuthMutation = {
  __typename?: 'mutation_root';
  upsertUserAuth?: {
    __typename?: 'UserAuth';
    userId: any;
    hash: string;
    salt: string;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type VerifyEmailMutationVariables = Exact<{
  email: Scalars['citext'];
}>;

export type VerifyEmailMutation = {
  __typename?: 'mutation_root';
  verifyEmail?: { __typename?: 'UserMutationResponse'; affectedRows: number } | null;
};

export type UpsertSigmaPrimeAddressMutationVariables = Exact<{
  objects: Array<SigmaPrimeAddressInsertInput> | SigmaPrimeAddressInsertInput;
}>;

export type UpsertSigmaPrimeAddressMutation = {
  __typename?: 'mutation_root';
  upsertSigmaPrimeAddress?: {
    __typename?: 'SigmaPrimeAddressMutationResponse';
    affectedRows: number;
    returning: Array<{
      __typename?: 'SigmaPrimeAddress';
      id: any;
      createdAt: any;
      updatedAt: any;
      personUniqueHash: string;
      sigmaPrimeId?: any | null;
      personFirstName?: string | null;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personAddress1?: string | null;
      personAddress2?: string | null;
      personSector?: string | null;
      personCity?: string | null;
      personRegionCounty?: string | null;
      personProvinceState?: string | null;
      personPostal?: string | null;
      personPostal4?: string | null;
      personAddressLatitude?: string | null;
      personAddressLongitude?: string | null;
      geocode?: string | null;
      firstInHouse?: string | null;
      childPresent?: string | null;
      mfdu?: string | null;
      ageRange?: string | null;
      exactAge?: string | null;
      estimatedAge?: string | null;
      estimatedIncome?: string | null;
      addressType?: string | null;
      dwellingType?: string | null;
      homeownerType?: string | null;
      property?: string | null;
      medianHomeValue?: string | null;
      marital?: string | null;
      ethnicCd?: string | null;
      title?: string | null;
      medianYrsEducation?: string | null;
      sex?: string | null;
      dataPedigree?: string | null;
      estimatedWealth?: string | null;
      personPhoneNumber?: string | null;
      timeZone?: string | null;
      personDob?: string | null;
      personCountryCode?: string | null;
      isDeleted: boolean;
      addressRecordType?: string | null;
      elotSequence?: number | null;
      rdi?: string | null;
      congressionalDistrict?: string | null;
      carrierRoute?: string | null;
      dpvCode?: string | null;
      personEmail?: string | null;
      addressIp?: string | null;
      addressEmail?: string | null;
      addressSsn?: string | null;
      addressSubindustry?: string | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    }>;
  } | null;
};

export type UpsertAddressAttributesMutationVariables = Exact<{
  objects: Array<AddressAttributesInsertInput> | AddressAttributesInsertInput;
}>;

export type UpsertAddressAttributesMutation = {
  __typename?: 'mutation_root';
  upsertAddressAttributes?: { __typename?: 'AddressAttributesMutationResponse'; affectedRows: number } | null;
};

export type UpsertDatasourceMutationVariables = Exact<{
  objects: Array<DatasourceInsertInput> | DatasourceInsertInput;
}>;

export type UpsertDatasourceMutation = {
  __typename?: 'mutation_root';
  upsertDatasource?: { __typename?: 'DatasourceMutationResponse'; affectedRows: number } | null;
};

export type UpsertSmartContractCommissionMutationVariables = Exact<{
  objects: Array<SmartContractCommissionInsertInput> | SmartContractCommissionInsertInput;
}>;

export type UpsertSmartContractCommissionMutation = {
  __typename?: 'mutation_root';
  upsertSmartContractCommission?: {
    __typename?: 'SmartContractCommissionMutationResponse';
    affectedRows: number;
  } | null;
};

export type UpsertSmartContractDocumentMutationVariables = Exact<{
  objects: Array<SmartContractDocumentInsertInput> | SmartContractDocumentInsertInput;
}>;

export type UpsertSmartContractDocumentMutation = {
  __typename?: 'mutation_root';
  upsertSmartContractDocument?: { __typename?: 'SmartContractDocumentMutationResponse'; affectedRows: number } | null;
};

export type UpsertWalletInventoryMutationVariables = Exact<{
  objects: Array<WalletInventoryInsertInput> | WalletInventoryInsertInput;
}>;

export type UpsertWalletInventoryMutation = {
  __typename?: 'mutation_root';
  upsertWalletInventory?: { __typename?: 'WalletInventoryMutationResponse'; affectedRows: number } | null;
};

export type UpsertAssetAccountMutationVariables = Exact<{
  objects: Array<AssetAccountInsertInput> | AssetAccountInsertInput;
}>;

export type UpsertAssetAccountMutation = {
  __typename?: 'mutation_root';
  upsertAssetAccount?: { __typename?: 'AssetAccountMutationResponse'; affectedRows: number } | null;
};

export type UpsertProductDistributionMutationVariables = Exact<{
  objects: Array<ProductDistributionInsertInput> | ProductDistributionInsertInput;
}>;

export type UpsertProductDistributionMutation = {
  __typename?: 'mutation_root';
  upsertProductDistribution?: { __typename?: 'ProductDistributionMutationResponse'; affectedRows: number } | null;
};

export type UpsertPaymentMutationVariables = Exact<{
  objects: Array<PaymentInsertInput> | PaymentInsertInput;
}>;

export type UpsertPaymentMutation = {
  __typename?: 'mutation_root';
  upsertPayment?: { __typename?: 'PaymentMutationResponse'; affectedRows: number } | null;
};

export type UpsertOrderMutationVariables = Exact<{
  objects: Array<OrderInsertInput> | OrderInsertInput;
}>;

export type UpsertOrderMutation = {
  __typename?: 'mutation_root';
  upsertOrder?: { __typename?: 'OrderMutationResponse'; affectedRows: number } | null;
};

export type UpsertProductMutationVariables = Exact<{
  objects: Array<ProductInsertInput> | ProductInsertInput;
}>;

export type UpsertProductMutation = {
  __typename?: 'mutation_root';
  upsertProduct?: {
    __typename?: 'ProductMutationResponse';
    affectedRows: number;
    returning: Array<{
      __typename?: 'Product';
      id: any;
      actionUrlActivate?: string | null;
      actionUrlDeactivate?: string | null;
      assetId?: any | null;
      binId?: number | null;
      caliberString?: string | null;
      categories?: string | null;
      categoryNumber?: string | null;
      ceNumber?: string | null;
      chainNumberOfDevices?: string | null;
      contentList?: string | null;
      distanceUom?: string | null;
      duration?: string | null;
      effectHeight?: string | null;
      effectWidth?: string | null;
      exNumberList?: string | null;
      facebookReferenceId?: string | null;
      glAccountList?: string | null;
      googleReferenceId?: string | null;
      images?: string | null;
      internalName?: string | null;
      itemCost?: any | null;
      longDescription?: string | null;
      manufacturerBrand?: string | null;
      manufacturerProductId?: any | null;
      manufacturerOrgId?: any | null;
      marketplaces?: string | null;
      markets?: string | null;
      materialId?: any | null;
      metaDescription?: string | null;
      metaKeywords?: string | null;
      metaTitle?: string | null;
      name: string;
      numberPackedItems?: string | null;
      price: any;
      productKindId?: any | null;
      productStatusId?: any | null;
      qtyUom?: string | null;
      reorderSmartContractId?: any | null;
      shops?: string | null;
      sicCodeId?: any | null;
      sku: string;
      smartContractId?: any | null;
      subindustry1Id?: any | null;
      subindustry2Id?: any | null;
      supplierOrgListId?: any | null;
      unHazMatId?: string | null;
      size?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      asset?: { __typename?: 'Asset'; id: any; name: string } | null;
      manufacturerProduct?: { __typename?: 'Product'; id: any; name: string } | null;
      manufacturerOrg?: { __typename?: 'Organization'; id: any; name: string } | null;
      material?: { __typename?: 'Material'; id: any; name: string } | null;
      kind?: { __typename?: 'ProductKind'; id: any; name: string } | null;
      status?: { __typename?: 'ProductStatus'; id: any; name: string } | null;
      reorderSmartContract?: { __typename?: 'SmartContract'; id: any; name: string } | null;
      sicCode?: { __typename?: 'SicCode'; id: any; code: number; name: string } | null;
      smartContract?: { __typename?: 'SmartContract'; id: any; name: string } | null;
      subindustry1?: { __typename?: 'Subindustry'; id: any; name: string } | null;
      subindustry2?: { __typename?: 'Subindustry'; id: any; name: string } | null;
    }>;
  } | null;
};

export type UpsertProductKindMutationVariables = Exact<{
  objects: Array<ProductKindInsertInput> | ProductKindInsertInput;
}>;

export type UpsertProductKindMutation = {
  __typename?: 'mutation_root';
  upsertProductKind?: { __typename?: 'ProductKindMutationResponse'; affectedRows: number } | null;
};

export type UpsertMaterialMutationVariables = Exact<{
  objects: Array<MaterialInsertInput> | MaterialInsertInput;
}>;

export type UpsertMaterialMutation = {
  __typename?: 'mutation_root';
  upsertMaterial?: { __typename?: 'MaterialMutationResponse'; affectedRows: number } | null;
};

export type UpsertCartMutationVariables = Exact<{
  objects: Array<CartInsertInput> | CartInsertInput;
}>;

export type UpsertCartMutation = {
  __typename?: 'mutation_root';
  upsertCart?: { __typename?: 'CartMutationResponse'; affectedRows: number } | null;
};

export type UpsertMarketMutationVariables = Exact<{
  objects: Array<MarketInsertInput> | MarketInsertInput;
}>;

export type UpsertMarketMutation = {
  __typename?: 'mutation_root';
  upsertMarket?: { __typename?: 'MarketMutationResponse'; affectedRows: number } | null;
};

export type UpsertOrganizationStatusMutationVariables = Exact<{
  objects: Array<OrganizationStatusInsertInput> | OrganizationStatusInsertInput;
}>;

export type UpsertOrganizationStatusMutation = {
  __typename?: 'mutation_root';
  upsertOrganizationStatus?: { __typename?: 'OrganizationStatusMutationResponse'; affectedRows: number } | null;
};

export type UpsertShippingMutationVariables = Exact<{
  objects: Array<ShippingInsertInput> | ShippingInsertInput;
}>;

export type UpsertShippingMutation = {
  __typename?: 'mutation_root';
  upsertShipping?: { __typename?: 'ShippingMutationResponse'; affectedRows: number } | null;
};

export type UpsertShopMutationVariables = Exact<{
  objects: Array<ShopInsertInput> | ShopInsertInput;
}>;

export type UpsertShopMutation = {
  __typename?: 'mutation_root';
  upsertShop?: { __typename?: 'ShopMutationResponse'; affectedRows: number } | null;
};

export type UpsertSupplyChainWaypointsMutationVariables = Exact<{
  objects: Array<SupplyChainWaypointInsertInput> | SupplyChainWaypointInsertInput;
}>;

export type UpsertSupplyChainWaypointsMutation = {
  __typename?: 'mutation_root';
  upsertSupplyChainWaypoints?: { __typename?: 'SupplyChainWaypointMutationResponse'; affectedRows: number } | null;
};

export type GetAskByAssetQueryVariables = Exact<{
  assetId: Scalars['uuid'];
}>;

export type GetAskByAssetQuery = {
  __typename?: 'query_root';
  getAskByAsset: Array<{
    __typename?: 'Ask';
    id: any;
    name: string;
    startingAsk: any;
    description: string;
    askNumber: any;
    startDate?: any | null;
    endDate?: any | null;
    totalAvailable?: any | null;
    image?: string | null;
    wallet: { __typename?: 'Wallet'; id: any; name: string };
    trade: { __typename?: 'Trade'; id: any; name: string };
    currency: { __typename?: 'Currency'; id: any; name: string };
  }>;
};

export type GetAskByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetAskByIdQuery = {
  __typename?: 'query_root';
  getAskById: Array<{
    __typename?: 'Ask';
    id: any;
    name: string;
    startingAsk: any;
    description: string;
    askNumber: any;
    startDate?: any | null;
    endDate?: any | null;
    totalAvailable?: any | null;
    image?: string | null;
    firmPrice?: boolean | null;
    currency: { __typename?: 'Currency'; id: any; name: string };
    wallet: {
      __typename?: 'Wallet';
      id: any;
      name: string;
      walletAddress: string;
      primaryHash: string;
      secondaryHash?: string | null;
    };
    product?: {
      __typename?: 'Product';
      id: any;
      name: string;
      sku: string;
      size?: string | null;
      caliberString?: string | null;
      categories?: string | null;
      categoryNumber?: string | null;
      ceNumber?: string | null;
      chainNumberOfDevices?: string | null;
      contentList?: string | null;
      distanceUom?: string | null;
      duration?: string | null;
      effectHeight?: string | null;
      effectWidth?: string | null;
      exNumberList?: string | null;
      facebookReferenceId?: string | null;
      glAccountList?: string | null;
      googleReferenceId?: string | null;
      images?: string | null;
      internalName?: string | null;
      itemCost?: any | null;
      longDescription?: string | null;
      metaDescription?: string | null;
      metaKeywords?: string | null;
      metaTitle?: string | null;
      numberPackedItems?: string | null;
      price: any;
      productKindId?: any | null;
      manufacturerBrand?: string | null;
    } | null;
  }>;
};

export type GetPutByAskQueryVariables = Exact<{
  askId: Scalars['uuid'];
}>;

export type GetPutByAskQuery = {
  __typename?: 'query_root';
  getPutByAsk: Array<{
    __typename?: 'Put';
    id: any;
    name: string;
    amount: any;
    currency: { __typename?: 'Currency'; id: any; name: string };
    wallet: { __typename?: 'Wallet'; id: any; name: string };
  }>;
};

export type GetMaxAmountPutByAskIdQueryVariables = Exact<{
  askId: Scalars['uuid'];
}>;

export type GetMaxAmountPutByAskIdQuery = {
  __typename?: 'query_root';
  getMaxAmountPutByAskId: Array<{
    __typename?: 'Put';
    id: any;
    name: string;
    amount: any;
    currency: { __typename?: 'Currency'; id: any; name: string };
  }>;
};

export type GetFeaturedAskQueryVariables = Exact<{
  assetId: Scalars['uuid'];
}>;

export type GetFeaturedAskQuery = {
  __typename?: 'query_root';
  getFeaturedAsk: Array<{
    __typename?: 'Ask';
    id: any;
    name: string;
    startingAsk: any;
    description: string;
    askNumber: any;
    startDate?: any | null;
    endDate?: any | null;
    totalAvailable?: any | null;
    firmPrice?: boolean | null;
    currency: { __typename?: 'Currency'; id: any; name: string };
    wallet: {
      __typename?: 'Wallet';
      id: any;
      name: string;
      walletAddress: string;
      primaryHash: string;
      secondaryHash?: string | null;
    };
  }>;
};

export type GetPutsByAssetIdQueryVariables = Exact<{
  assetId: Scalars['uuid'];
}>;

export type GetPutsByAssetIdQuery = {
  __typename?: 'query_root';
  getPutsByAssetId: Array<{ __typename?: 'Put'; id: any; name: string; createdAt: any }>;
};

export type UpsertCacheMutationVariables = Exact<{
  object: CacheInsertInput;
}>;

export type UpsertCacheMutation = {
  __typename?: 'mutation_root';
  upsertCache?: {
    __typename?: 'Cache';
    key: string;
    value: string;
    createdAt: any;
    updatedAt: any;
    expiresAt: any;
  } | null;
};

export type DeleteCacheMutationVariables = Exact<{
  key: Scalars['String'];
}>;

export type DeleteCacheMutation = {
  __typename?: 'mutation_root';
  deleteCache?: { __typename?: 'Cache'; key: string; value: string } | null;
};

export type UpsertKycStatusMutationVariables = Exact<{
  objects: Array<KycStatusInsertInput> | KycStatusInsertInput;
}>;

export type UpsertKycStatusMutation = {
  __typename?: 'mutation_root';
  upsertKycStatus?: { __typename?: 'KycStatusMutationResponse'; affectedRows: number } | null;
};

export type UpsertKycKindMutationVariables = Exact<{
  objects: Array<KycKindInsertInput> | KycKindInsertInput;
}>;

export type UpsertKycKindMutation = {
  __typename?: 'mutation_root';
  upsertKycKind?: { __typename?: 'KycKindMutationResponse'; affectedRows: number } | null;
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = {
  __typename?: 'query_root';
  countries: Array<{
    __typename?: 'Country';
    code: string;
    name: string;
    region: string;
    phoneCode: number;
    secCode?: string | null;
    stateLabel: string;
    longitude: any;
    latitude: any;
    isoNumber: number;
    iso3: string;
    flagEmojiUnicode: string;
    flagEmoji: string;
    currencyCode: string;
    createdAt?: any | null;
    updatedAt?: any | null;
    isDeleted: boolean;
  }>;
};

export type AddressStatesByCountryCodeQueryVariables = Exact<{
  countryCode: Scalars['String'];
}>;

export type AddressStatesByCountryCodeQuery = {
  __typename?: 'query_root';
  addressStatesByCountryCode: Array<{
    __typename?: 'State';
    code: string;
    name: string;
    secCode?: string | null;
    longitude?: any | null;
    latitude?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    isDeleted: boolean;
    countryCode: string;
    country: {
      __typename?: 'Country';
      code: string;
      name: string;
      region: string;
      phoneCode: number;
      secCode?: string | null;
      stateLabel: string;
      longitude: any;
      latitude: any;
      isoNumber: number;
      iso3: string;
      flagEmojiUnicode: string;
      flagEmoji: string;
      currencyCode: string;
      createdAt?: any | null;
      updatedAt?: any | null;
      isDeleted: boolean;
    };
  }>;
};

export type GetOrganizationByUserIdQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type GetOrganizationByUserIdQuery = {
  __typename?: 'query_root';
  organization: Array<{
    __typename?: 'Organization';
    id: any;
    name: string;
    crwdsystemsApiReference?: string | null;
    organizationKindId?: any | null;
    organizationPrimaryAddress1?: string | null;
    organizationPrimaryAddress2?: string | null;
    organizationPrimaryCity?: string | null;
    organizationPrimaryState?: string | null;
    organizationPrimaryPostalCode?: string | null;
    organizationPrimaryRegion?: string | null;
    organizationPrimaryCountryCode?: string | null;
    organizationPrimaryPhone?: string | null;
    geocode?: string | null;
    crwdTicker?: string | null;
    organizationPersonStatus?: string | null;
    personUniqueHash?: string | null;
    personFirstName?: string | null;
    personMiddleName?: string | null;
    personLastName?: string | null;
    companyTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personBusinessEmail?: string | null;
    companyPrimaryWebsite?: string | null;
    companyEmployeeCount?: string | null;
    companyRevenue?: string | null;
    sicCodeId?: any | null;
    industryId?: any | null;
    sub1IndustryId?: any | null;
    sub2IndustryId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
  }>;
};

export type GetSmartContractLineagesQueryVariables = Exact<{ [key: string]: never }>;

export type GetSmartContractLineagesQuery = {
  __typename?: 'query_root';
  smartContractLineage: Array<{
    __typename?: 'SmartContractLineage';
    user?: {
      __typename?: 'User';
      id: any;
      uniqueHash: string;
      rememberToken: string;
      sigmaPrime?: { __typename?: 'SigmaPrime'; personFirstName: string; personLastName?: string | null } | null;
    } | null;
    parentUser?: {
      __typename?: 'User';
      id: any;
      uniqueHash: string;
      rememberToken: string;
      sigmaPrime?: { __typename?: 'SigmaPrime'; personFirstName: string; personLastName?: string | null } | null;
    } | null;
  }>;
};

export type GetSigmaPrimesByHandleQueryVariables = Exact<{
  handle: Scalars['citext'];
}>;

export type GetSigmaPrimesByHandleQuery = {
  __typename?: 'query_root';
  sigmaPrime: Array<{ __typename?: 'SigmaPrime'; id: any }>;
};

export type GetUserSigmaPrimesByEmailQueryVariables = Exact<{
  email: Scalars['citext'];
}>;

export type GetUserSigmaPrimesByEmailQuery = {
  __typename?: 'query_root';
  sigmaPrimes: Array<{
    __typename?: 'SigmaPrime';
    id: any;
    sigmaPrimeKindId?: any | null;
    crwdId?: string | null;
    personParent1SigmaId?: any | null;
    personParent2SigmaId?: any | null;
    personFirstName: string;
    personMiddleName?: string | null;
    personLastName?: string | null;
    personFamilyName?: string | null;
    personNameSuffix?: string | null;
    personHandleName?: any | null;
    personTitleName?: string | null;
    personPrimaryMobile?: string | null;
    personPrimaryPhone?: string | null;
    personPrimaryIpAddress?: string | null;
    personPrimaryEmail: any;
    personBusinessEmail?: any | null;
    personPrimaryFacePhoto?: string | null;
    personPrimaryKycStatus: string;
    personPrimaryAmlStatus?: string | null;
    personPrivacyPolicy: string;
    personStatus: string;
    personExchangeRequest: string;
    personUniqueHash: string;
    personPrimaryLanguageId?: string | null;
    aboutMe?: string | null;
    profilePhoto?: string | null;
    userId?: any | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    kind?: {
      __typename?: 'SigmaPrimeKind';
      id: any;
      name: string;
      description: string;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
    personParent1?: {
      __typename?: 'SigmaPrime';
      id: any;
      crwdId?: string | null;
      personFirstName: string;
      personLastName?: string | null;
    } | null;
    personParent2?: {
      __typename?: 'SigmaPrime';
      id: any;
      crwdId?: string | null;
      personFirstName: string;
      personLastName?: string | null;
    } | null;
    user?: { __typename?: 'User'; id: any; email: any } | null;
  }>;
  users: Array<{
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  }>;
};

export type UserByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type UserByIdQuery = {
  __typename?: 'query_root';
  userById?: {
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  } | null;
};

export type UsersQueryVariables = Exact<{ [key: string]: never }>;

export type UsersQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  }>;
};

export type UsersByEmailQueryVariables = Exact<{
  email: Scalars['citext'];
}>;

export type UsersByEmailQuery = {
  __typename?: 'query_root';
  usersByEmail: Array<{
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  }>;
};

export type UsersAndAuthsByEmailQueryVariables = Exact<{
  email: Scalars['citext'];
}>;

export type UsersAndAuthsByEmailQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  }>;
  userAuths: Array<{
    __typename?: 'UserAuth';
    userId: any;
    hash: string;
    salt: string;
    createdAt: any;
    updatedAt: any;
  }>;
};

export type UsersByVerifiedEmailQueryVariables = Exact<{
  email: Scalars['citext'];
}>;

export type UsersByVerifiedEmailQuery = {
  __typename?: 'query_root';
  usersByVerifiedEmail: Array<{
    __typename?: 'User';
    email: any;
    emailVerified: boolean;
    preferences?: string | null;
    rememberToken: string;
    isAdmin: boolean;
    crwdsystemsApi?: string | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    organizationId?: any | null;
    statusId?: any | null;
    isDeleted: boolean;
    sigmaPrimeId?: any | null;
    uniqueHash: string;
    countryCode?: string | null;
    organization?: { __typename?: 'Organization'; id: any; name: string } | null;
    status?: {
      __typename?: 'Status';
      id: any;
      description: string;
      kindId?: any | null;
      name: string;
      updatedAt: any;
      startDate?: any | null;
      endDate?: any | null;
      createdAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'StatusKind';
        id: any;
        name: string;
        description: string;
        updatedAt: any;
        createdAt: any;
        isDeleted: boolean;
      } | null;
    } | null;
    sigmaPrime?: {
      __typename?: 'SigmaPrime';
      id: any;
      sigmaPrimeKindId?: any | null;
      crwdId?: string | null;
      personParent1SigmaId?: any | null;
      personParent2SigmaId?: any | null;
      personFirstName: string;
      personMiddleName?: string | null;
      personLastName?: string | null;
      personFamilyName?: string | null;
      personNameSuffix?: string | null;
      personHandleName?: any | null;
      personTitleName?: string | null;
      personPrimaryMobile?: string | null;
      personPrimaryPhone?: string | null;
      personPrimaryIpAddress?: string | null;
      personPrimaryEmail: any;
      personBusinessEmail?: any | null;
      personPrimaryFacePhoto?: string | null;
      personPrimaryKycStatus: string;
      personPrimaryAmlStatus?: string | null;
      personPrivacyPolicy: string;
      personStatus: string;
      personExchangeRequest: string;
      personUniqueHash: string;
      personPrimaryLanguageId?: string | null;
      aboutMe?: string | null;
      profilePhoto?: string | null;
      userId?: any | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      kind?: {
        __typename?: 'SigmaPrimeKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
      } | null;
      personParent1?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      personParent2?: {
        __typename?: 'SigmaPrime';
        id: any;
        crwdId?: string | null;
        personFirstName: string;
        personLastName?: string | null;
      } | null;
      user?: { __typename?: 'User'; id: any; email: any } | null;
    } | null;
  }>;
};

export type CacheQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type CacheQuery = {
  __typename?: 'query_root';
  cache?: { __typename?: 'Cache'; key: string; value: string; createdAt: any; updatedAt: any; expiresAt: any } | null;
};

export type WalletsForUserQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type WalletsForUserQuery = {
  __typename?: 'query_root';
  walletsForUser: Array<{
    __typename?: 'Wallet';
    id: any;
    name: string;
    walletAddress: string;
    primaryHash: string;
    secondaryHash?: string | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
    defaultCurrencyId: any;
    primaryUserId: any;
    secondaryUserId?: any | null;
    walletKindId?: any | null;
    defaultCurrency: {
      __typename?: 'Currency';
      id: any;
      abbreviation?: string | null;
      authorized?: any | null;
      cc?: string | null;
      description?: string | null;
      reserved?: any | null;
      name: string;
      issued?: any | null;
      image?: string | null;
      symbol?: string | null;
      numericCode?: string | null;
      minorUnit?: string | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
      countryCode?: string | null;
      currencyKindId?: any | null;
      groupsId?: any | null;
      sectorId?: any | null;
      subindustryId?: any | null;
      country?: {
        __typename?: 'Country';
        code: string;
        name: string;
        region: string;
        phoneCode: number;
        secCode?: string | null;
        stateLabel: string;
        longitude: any;
        latitude: any;
        isoNumber: number;
        iso3: string;
        flagEmojiUnicode: string;
        flagEmoji: string;
        currencyCode: string;
        createdAt?: any | null;
        updatedAt?: any | null;
        isDeleted: boolean;
      } | null;
      kind?: {
        __typename?: 'CurrencyKind';
        id: any;
        name: string;
        description: string;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        gicsSectorId?: any | null;
        groupId?: any | null;
        industryId?: any | null;
        subindustryId?: any | null;
        gicsSector?: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        group?: {
          __typename?: 'Group';
          id: any;
          name: string;
          description?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          imageGroup?: string | null;
          gicsSectorId: any;
          sicCodeId?: any | null;
          gicsSector: {
            __typename?: 'GicsSector';
            id: any;
            name: string;
            description?: string | null;
            imageSector?: string | null;
            updatedAt: any;
            isDeleted: boolean;
            createdAt: any;
            sicCodeId?: any | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          };
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        subindustry?: {
          __typename?: 'Subindustry';
          id: any;
          name: string;
          description?: string | null;
          imageSubindustry?: string | null;
          parentIndustryId?: any | null;
          sicCodeId?: any | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          parentIndustry?: {
            __typename?: 'Industry';
            id: any;
            name: string;
            description?: string | null;
            imageIndustry?: string | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
            groupId?: any | null;
            sicCodeId?: any | null;
            group?: { __typename?: 'Group'; name: string } | null;
            sicCode?: {
              __typename?: 'SicCode';
              id: any;
              code: number;
              name: string;
              sicCodeOffice?: string | null;
              gicsCodeName?: string | null;
              gicsCode?: number | null;
              createdAt: any;
              updatedAt: any;
              isDeleted: boolean;
            } | null;
          } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id: any;
        name: string;
        description?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        imageGroup?: string | null;
        gicsSectorId: any;
        sicCodeId?: any | null;
        gicsSector: {
          __typename?: 'GicsSector';
          id: any;
          name: string;
          description?: string | null;
          imageSector?: string | null;
          updatedAt: any;
          isDeleted: boolean;
          createdAt: any;
          sicCodeId?: any | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        };
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      gicsSector?: {
        __typename?: 'GicsSector';
        id: any;
        name: string;
        description?: string | null;
        imageSector?: string | null;
        updatedAt: any;
        isDeleted: boolean;
        createdAt: any;
        sicCodeId?: any | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      subindustry?: {
        __typename?: 'Subindustry';
        id: any;
        name: string;
        description?: string | null;
        imageSubindustry?: string | null;
        parentIndustryId?: any | null;
        sicCodeId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        parentIndustry?: {
          __typename?: 'Industry';
          id: any;
          name: string;
          description?: string | null;
          imageIndustry?: string | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
          groupId?: any | null;
          sicCodeId?: any | null;
          group?: { __typename?: 'Group'; name: string } | null;
          sicCode?: {
            __typename?: 'SicCode';
            id: any;
            code: number;
            name: string;
            sicCodeOffice?: string | null;
            gicsCodeName?: string | null;
            gicsCode?: number | null;
            createdAt: any;
            updatedAt: any;
            isDeleted: boolean;
          } | null;
        } | null;
        sicCode?: {
          __typename?: 'SicCode';
          id: any;
          code: number;
          name: string;
          sicCodeOffice?: string | null;
          gicsCodeName?: string | null;
          gicsCode?: number | null;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
    };
    primaryUser: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    };
    secondaryUser?: {
      __typename?: 'User';
      email: any;
      emailVerified: boolean;
      preferences?: string | null;
      rememberToken: string;
      isAdmin: boolean;
      crwdsystemsApi?: string | null;
      createdAt: any;
      updatedAt: any;
      id: any;
      organizationId?: any | null;
      statusId?: any | null;
      isDeleted: boolean;
      sigmaPrimeId?: any | null;
      uniqueHash: string;
      countryCode?: string | null;
      organization?: { __typename?: 'Organization'; id: any; name: string } | null;
      status?: {
        __typename?: 'Status';
        id: any;
        description: string;
        kindId?: any | null;
        name: string;
        updatedAt: any;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'StatusKind';
          id: any;
          name: string;
          description: string;
          updatedAt: any;
          createdAt: any;
          isDeleted: boolean;
        } | null;
      } | null;
      sigmaPrime?: {
        __typename?: 'SigmaPrime';
        id: any;
        sigmaPrimeKindId?: any | null;
        crwdId?: string | null;
        personParent1SigmaId?: any | null;
        personParent2SigmaId?: any | null;
        personFirstName: string;
        personMiddleName?: string | null;
        personLastName?: string | null;
        personFamilyName?: string | null;
        personNameSuffix?: string | null;
        personHandleName?: any | null;
        personTitleName?: string | null;
        personPrimaryMobile?: string | null;
        personPrimaryPhone?: string | null;
        personPrimaryIpAddress?: string | null;
        personPrimaryEmail: any;
        personBusinessEmail?: any | null;
        personPrimaryFacePhoto?: string | null;
        personPrimaryKycStatus: string;
        personPrimaryAmlStatus?: string | null;
        personPrivacyPolicy: string;
        personStatus: string;
        personExchangeRequest: string;
        personUniqueHash: string;
        personPrimaryLanguageId?: string | null;
        aboutMe?: string | null;
        profilePhoto?: string | null;
        userId?: any | null;
        createdAt: any;
        updatedAt: any;
        isDeleted: boolean;
        kind?: {
          __typename?: 'SigmaPrimeKind';
          id: any;
          name: string;
          description: string;
          createdAt: any;
          updatedAt: any;
          isDeleted: boolean;
        } | null;
        personParent1?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        personParent2?: {
          __typename?: 'SigmaPrime';
          id: any;
          crwdId?: string | null;
          personFirstName: string;
          personLastName?: string | null;
        } | null;
        user?: { __typename?: 'User'; id: any; email: any } | null;
      } | null;
    } | null;
    kind?: {
      __typename?: 'WalletKind';
      id: any;
      name: string;
      merchant?: boolean | null;
      organization?: boolean | null;
      user?: boolean | null;
      createdAt: any;
      updatedAt: any;
      isDeleted: boolean;
    } | null;
  }>;
  walletKinds: Array<{
    __typename?: 'WalletKind';
    id: any;
    name: string;
    merchant?: boolean | null;
    organization?: boolean | null;
    user?: boolean | null;
    createdAt: any;
    updatedAt: any;
    isDeleted: boolean;
  }>;
};

export const CountryFieldsFragmentDoc = gql`
  fragment countryFields on Country {
    code
    name
    region
    phoneCode
    secCode
    stateLabel
    longitude
    latitude
    isoNumber
    iso3
    flagEmojiUnicode
    flagEmoji
    currencyCode
    createdAt
    updatedAt
    isDeleted
  }
`;
export const StateFieldsFragmentDoc = gql`
  fragment stateFields on State {
    code
    name
    secCode
    longitude
    latitude
    createdAt
    updatedAt
    isDeleted
    countryCode
    country {
      ...countryFields
    }
  }
  ${CountryFieldsFragmentDoc}
`;
export const SicCodeFieldsFragmentDoc = gql`
  fragment sicCodeFields on SicCode {
    id
    code
    name
    sicCodeOffice
    gicsCodeName
    gicsCode
    createdAt
    updatedAt
    isDeleted
  }
`;
export const GicsSectorFieldsFragmentDoc = gql`
  fragment gicsSectorFields on GicsSector {
    id
    name
    description
    imageSector
    updatedAt
    isDeleted
    createdAt
    sicCodeId
    sicCode {
      ...sicCodeFields
    }
  }
  ${SicCodeFieldsFragmentDoc}
`;
export const GroupFieldsFragmentDoc = gql`
  fragment groupFields on Group {
    id
    name
    description
    updatedAt
    isDeleted
    createdAt
    imageGroup
    gicsSectorId
    gicsSector {
      ...gicsSectorFields
    }
    sicCodeId
    sicCode {
      ...sicCodeFields
    }
  }
  ${GicsSectorFieldsFragmentDoc}
  ${SicCodeFieldsFragmentDoc}
`;
export const IndustryFieldsFragmentDoc = gql`
  fragment industryFields on Industry {
    id
    name
    description
    imageIndustry
    createdAt
    updatedAt
    isDeleted
    groupId
    group {
      name
    }
    sicCodeId
    sicCode {
      ...sicCodeFields
    }
  }
  ${SicCodeFieldsFragmentDoc}
`;
export const SubindustryFieldsFragmentDoc = gql`
  fragment subindustryFields on Subindustry {
    id
    name
    description
    imageSubindustry
    parentIndustryId
    parentIndustry {
      ...industryFields
    }
    sicCodeId
    sicCode {
      ...sicCodeFields
    }
    createdAt
    updatedAt
    isDeleted
  }
  ${IndustryFieldsFragmentDoc}
  ${SicCodeFieldsFragmentDoc}
`;
export const CurrencyKindFieldsFragmentDoc = gql`
  fragment currencyKindFields on CurrencyKind {
    id
    name
    description
    createdAt
    updatedAt
    isDeleted
    gicsSectorId
    gicsSector {
      ...gicsSectorFields
    }
    groupId
    group {
      ...groupFields
    }
    industryId
    industry {
      ...industryFields
    }
    subindustryId
    subindustry {
      ...subindustryFields
    }
  }
  ${GicsSectorFieldsFragmentDoc}
  ${GroupFieldsFragmentDoc}
  ${IndustryFieldsFragmentDoc}
  ${SubindustryFieldsFragmentDoc}
`;
export const CurrencyFieldsFragmentDoc = gql`
  fragment currencyFields on Currency {
    id
    abbreviation
    authorized
    cc
    description
    reserved
    name
    issued
    image
    symbol
    numericCode
    minorUnit
    createdAt
    updatedAt
    isDeleted
    countryCode
    country {
      ...countryFields
    }
    currencyKindId
    kind {
      ...currencyKindFields
    }
    groupsId
    group {
      ...groupFields
    }
    sectorId
    gicsSector {
      ...gicsSectorFields
    }
    subindustryId
    subindustry {
      ...subindustryFields
    }
  }
  ${CountryFieldsFragmentDoc}
  ${CurrencyKindFieldsFragmentDoc}
  ${GroupFieldsFragmentDoc}
  ${GicsSectorFieldsFragmentDoc}
  ${SubindustryFieldsFragmentDoc}
`;
export const OrganizationKindFieldsFragmentDoc = gql`
  fragment organizationKindFields on OrganizationKind {
    id
    name
    description
    createdAt
    updatedAt
  }
`;
export const OrganizationFieldsFragmentDoc = gql`
  fragment organizationFields on Organization {
    id
    name
    crwdsystemsApiReference
    organizationKindId
    kind {
      ...organizationKindFields
    }
    organizationPrimaryAddress1
    organizationPrimaryAddress2
    organizationPrimaryCity
    organizationPrimaryState
    organizationPrimaryPostalCode
    organizationPrimaryRegion
    organizationPrimaryCountryCode
    organizationPrimaryPhone
    geocode
    crwdTicker
    organizationPersonStatus
    personUniqueHash
    personFirstName
    personMiddleName
    personLastName
    companyTitleName
    personPrimaryMobile
    personBusinessEmail
    companyPrimaryWebsite
    companyEmployeeCount
    companyRevenue
    sicCodeId
    sicCode {
      ...sicCodeFields
    }
    industryId
    industry {
      ...industryFields
    }
    sub1IndustryId
    subindustry1 {
      ...subindustryFields
    }
    sub2IndustryId
    subindustry2 {
      ...subindustryFields
    }
    createdAt
    updatedAt
    isDeleted
  }
  ${OrganizationKindFieldsFragmentDoc}
  ${SicCodeFieldsFragmentDoc}
  ${IndustryFieldsFragmentDoc}
  ${SubindustryFieldsFragmentDoc}
`;
export const CurrencyOwnerFieldsFragmentDoc = gql`
  fragment currencyOwnerFields on CurrencyOwner {
    id
    dateAuthorized
    dateIssued
    ownerTransfer
    createdAt
    updatedAt
    isDeleted
    currencyId
    currency {
      ...currencyFields
    }
    grantingOrganizationId
    organizationByGrantingOrganizationId {
      ...organizationFields
    }
    organizationId
    organization {
      ...organizationFields
    }
    originalOrganizationId
    organizationByOriginalOrganizationId {
      ...organizationFields
    }
  }
  ${CurrencyFieldsFragmentDoc}
  ${OrganizationFieldsFragmentDoc}
`;
export const UserAuthFieldsFragmentDoc = gql`
  fragment userAuthFields on UserAuth {
    userId
    hash
    salt
    createdAt
    updatedAt
  }
`;
export const CertificationFieldsFragmentDoc = gql`
  fragment certificationFields on Certification {
    id
    name
    description
    createdAt
    updatedAt
    isDeleted
    gicsSectorId
    gicsSector {
      ...gicsSectorFields
    }
    groupId
    group {
      ...groupFields
    }
    organizationId
    organization {
      ...organizationFields
    }
    industryId
    industry {
      ...industryFields
    }
    subindustryId
    subindustry {
      ...subindustryFields
    }
  }
  ${GicsSectorFieldsFragmentDoc}
  ${GroupFieldsFragmentDoc}
  ${OrganizationFieldsFragmentDoc}
  ${IndustryFieldsFragmentDoc}
  ${SubindustryFieldsFragmentDoc}
`;
export const ClearinghouseKindFieldsFragmentDoc = gql`
  fragment clearinghouseKindFields on ClearinghouseKind {
    id
    name
    description
    createdAt
    updatedAt
    isDeleted
    gicsSectorId
    gicsSector {
      ...gicsSectorFields
    }
    groupId
    group {
      ...groupFields
    }
    industryId
    industry {
      ...industryFields
    }
    subindustryId
    subindustry {
      ...subindustryFields
    }
  }
  ${GicsSectorFieldsFragmentDoc}
  ${GroupFieldsFragmentDoc}
  ${IndustryFieldsFragmentDoc}
  ${SubindustryFieldsFragmentDoc}
`;
export const StatusKindFieldsFragmentDoc = gql`
  fragment statusKindFields on StatusKind {
    id
    name
    description
    updatedAt
    createdAt
    isDeleted
  }
`;
export const StatusFieldsFragmentDoc = gql`
  fragment statusFields on Status {
    id
    description
    kindId
    name
    updatedAt
    startDate
    endDate
    createdAt
    isDeleted
    kind {
      ...statusKindFields
    }
  }
  ${StatusKindFieldsFragmentDoc}
`;
export const SigmaPrimeKindFieldsFragmentDoc = gql`
  fragment sigmaPrimeKindFields on SigmaPrimeKind {
    id
    name
    description
    createdAt
    updatedAt
    isDeleted
  }
`;
export const SigmaPrimeFieldsFragmentDoc = gql`
  fragment sigmaPrimeFields on SigmaPrime {
    id
    sigmaPrimeKindId
    kind {
      ...sigmaPrimeKindFields
    }
    crwdId
    personParent1SigmaId
    personParent1 {
      id
      crwdId
      personFirstName
      personLastName
    }
    personParent2SigmaId
    personParent2 {
      id
      crwdId
      personFirstName
      personLastName
    }
    personFirstName
    personMiddleName
    personLastName
    personFamilyName
    personNameSuffix
    personHandleName
    personTitleName
    personPrimaryMobile
    personPrimaryPhone
    personPrimaryIpAddress
    personPrimaryEmail
    personBusinessEmail
    personPrimaryFacePhoto
    personPrimaryKycStatus
    personPrimaryAmlStatus
    personPrivacyPolicy
    personStatus
    personExchangeRequest
    personUniqueHash
    personPrimaryLanguageId
    aboutMe
    profilePhoto
    userId
    user {
      id
      email
    }
    createdAt
    updatedAt
    isDeleted
  }
  ${SigmaPrimeKindFieldsFragmentDoc}
`;
export const UserFieldsFragmentDoc = gql`
  fragment userFields on User {
    email
    emailVerified
    preferences
    rememberToken
    isAdmin
    crwdsystemsApi
    createdAt
    updatedAt
    id
    email
    organizationId
    organization {
      id
      name
    }
    statusId
    status {
      ...statusFields
    }
    isDeleted
    sigmaPrimeId
    sigmaPrime {
      ...sigmaPrimeFields
    }
    uniqueHash
    countryCode
  }
  ${StatusFieldsFragmentDoc}
  ${SigmaPrimeFieldsFragmentDoc}
`;
export const ClearinghouseFieldsFragmentDoc = gql`
  fragment clearinghouseFields on Clearinghouse {
    id
    name
    description
    createdAt
    updatedAt
    isDeleted
    clearinghouseKindId
    kind {
      ...clearinghouseKindFields
    }
    userId
    user {
      ...userFields
    }
  }
  ${ClearinghouseKindFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const ClearinghouseCertificationFieldsFragmentDoc = gql`
  fragment clearinghouseCertificationFields on ClearinghouseCertification {
    id
    isActive
    createdAt
    updatedAt
    isDeleted
    certificationId
    certification {
      ...certificationFields
    }
    clearinghouseId
    clearinghouse {
      ...clearinghouseFields
    }
  }
  ${CertificationFieldsFragmentDoc}
  ${ClearinghouseFieldsFragmentDoc}
`;
export const MerchantFieldsFragmentDoc = gql`
  fragment merchantFields on Merchant {
    id
    name
    description
    crwdsystemsApi
    notifyUrl
    uniqueHash
    url
    createdAt
    updatedAt
    isDeleted
    userId
    user {
      ...userFields
    }
    statusId
    status {
      ...statusFields
    }
  }
  ${UserFieldsFragmentDoc}
  ${StatusFieldsFragmentDoc}
`;
export const MerchantCurrencyFieldsFragmentDoc = gql`
  fragment merchantCurrencyFields on MerchantCurrency {
    id
    createdAt
    updatedAt
    isDeleted
    currencyId
    currency {
      ...currencyFields
    }
    merchantId
    merchant {
      ...merchantFields
    }
    statusId
    status {
      ...statusFields
    }
  }
  ${CurrencyFieldsFragmentDoc}
  ${MerchantFieldsFragmentDoc}
  ${StatusFieldsFragmentDoc}
`;
export const MerchantOrganizationFieldsFragmentDoc = gql`
  fragment merchantOrganizationFields on MerchantOrganization {
    id
    createdAt
    updatedAt
    isDeleted
    merchantId
    merchant {
      ...merchantFields
    }
    organizationId
    organization {
      ...organizationFields
    }
  }
  ${MerchantFieldsFragmentDoc}
  ${OrganizationFieldsFragmentDoc}
`;
export const InteractionFieldsFragmentDoc = gql`
  fragment interactionFields on Interaction {
    id
    liked
    useCount
    createdAt
    updatedAt
    isDeleted
    currencyId
    merchantId
    merchant {
      ...merchantFields
    }
    currency {
      ...currencyFields
    }
    userId
    user {
      ...userFields
    }
  }
  ${MerchantFieldsFragmentDoc}
  ${CurrencyFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const SettingFieldsFragmentDoc = gql`
  fragment settingFields on Setting {
    id
    key
    value
    createdAt
    updatedAt
    isDeleted
  }
`;
export const PasswordResetFieldsFragmentDoc = gql`
  fragment passwordResetFields on PasswordReset {
    id
    email
    token
    createdAt
    updatedAt
    isDeleted
  }
`;
export const PutDropDownFragmentDoc = gql`
  fragment putDropDown on Put {
    id
    name
  }
`;
export const PutFieldsFragmentDoc = gql`
  fragment putFields on Put {
    id
    name
    amount
    winningPut
    createdAt
    updatedAt
    isDeleted
    askId
    ask {
      id
      name
    }
    currencyId
    currency {
      id
      name
    }
    smartContractId
    smartContract {
      id
      name
    }
    walletId
    wallet {
      id
      name
    }
  }
`;
export const AskDropDownFieldsFragmentDoc = gql`
  fragment askDropDownFields on Ask {
    id
    name
  }
`;
export const ExchangeKindFieldsFragmentDoc = gql`
  fragment exchangeKindFields on ExchangeKind {
    id
    name
    createdAt
    updatedAt
    isDeleted
  }
`;
export const TradeKindFieldsFragmentDoc = gql`
  fragment tradeKindFields on TradeKind {
    id
    name
    createdAt
    updatedAt
    isDeleted
  }
`;
export const ExchangeFieldsFragmentDoc = gql`
  fragment exchangeFields on Exchange {
    id
    name
    amount
    description
    createdAt
    updatedAt
    isDeleted
    exchangeKindId
    kind {
      ...exchangeKindFields
    }
    swapCurrencyId
    swapCurrency {
      ...currencyFields
    }
    tradeKindId
    tradeKind {
      ...tradeKindFields
    }
    userId
    user {
      ...userFields
    }
  }
  ${ExchangeKindFieldsFragmentDoc}
  ${CurrencyFieldsFragmentDoc}
  ${TradeKindFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const SmartContractKindFieldsFragmentDoc = gql`
  fragment smartContractKindFields on SmartContractKind {
    id
    name
    description
    createdAt
    updatedAt
    isDeleted
    subindustryId
    subindustry {
      ...subindustryFields
    }
  }
  ${SubindustryFieldsFragmentDoc}
`;
export const SmartContractFieldsFragmentDoc = gql`
  fragment smartContractFields on SmartContract {
    id
    name
    description
    useCount
    terms
    createdAt
    updatedAt
    isDeleted
    clearinghouseId
    clearinghouse {
      ...clearinghouseFields
    }
    smartContractKindId
    kind {
      ...smartContractKindFields
    }
  }
  ${ClearinghouseFieldsFragmentDoc}
  ${SmartContractKindFieldsFragmentDoc}
`;
export const AppraisalFieldsFragmentDoc = gql`
  fragment appraisalFields on Appraisal {
    id
    name
    description
    createdAt
    updatedAt
    isDeleted
    organizationId
    organization {
      ...organizationFields
    }
    smartContractId
    smartContract {
      ...smartContractFields
    }
    userId
    user {
      ...userFields
    }
  }
  ${OrganizationFieldsFragmentDoc}
  ${SmartContractFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const AssetKindFieldsFragmentDoc = gql`
  fragment assetKindFields on AssetKind {
    id
    name
    description
    images
    createdAt
    updatedAt
    isDeleted
    subindustryId
    subindustry {
      ...subindustryFields
    }
  }
  ${SubindustryFieldsFragmentDoc}
`;
export const DatasourceFieldsFragmentDoc = gql`
  fragment datasourceFields on Datasource {
    id
    name
    location
    organizationId
    organization {
      ...organizationFields
    }
    governance
    pedigree
    authorizedUserId
    authorizedUser {
      ...userFields
    }
    createdAt
    updatedAt
  }
  ${OrganizationFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const MaterialFieldsFragmentDoc = gql`
  fragment materialFields on Material {
    id
    name
    description
    detailsUrl
    image
    isCommodity
    materialCategory
    materialSubcategory
    materialType
    symbol
    createdAt
    updatedAt
    isDeleted
  }
`;
export const AssetFieldsFragmentDoc = gql`
  fragment assetFields on Asset {
    appraisal {
      ...appraisalFields
    }
    appraisalId
    assetAccountId
    assetKindId
    kind {
      ...assetKindFields
    }
    category
    classification
    country {
      ...countryFields
    }
    countryCode
    createdAt
    datasource {
      ...datasourceFields
    }
    datasourceId
    description
    dimensions
    embedCode
    format
    id
    images
    isDeleted
    material {
      ...materialFields
    }
    materialId
    materials
    name
    organization {
      ...organizationFields
    }
    organizationId
    parentCategory
    sourceUrl
    sourceUserId
    sourceUser {
      ...userFields
    }
    subindustry {
      ...subindustryFields
    }
    subindustryId
    uom
    updatedAt
    user {
      ...userFields
    }
    userId
    productId
    product {
      id
      name
    }
    authorized
    issued
  }
  ${AppraisalFieldsFragmentDoc}
  ${AssetKindFieldsFragmentDoc}
  ${CountryFieldsFragmentDoc}
  ${DatasourceFieldsFragmentDoc}
  ${MaterialFieldsFragmentDoc}
  ${OrganizationFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${SubindustryFieldsFragmentDoc}
`;
export const ProductFieldsFragmentDoc = gql`
  fragment productFields on Product {
    id
    actionUrlActivate
    actionUrlDeactivate
    assetId
    asset {
      id
      name
    }
    binId
    caliberString
    categories
    categoryNumber
    ceNumber
    chainNumberOfDevices
    contentList
    distanceUom
    duration
    effectHeight
    effectWidth
    exNumberList
    facebookReferenceId
    glAccountList
    googleReferenceId
    images
    internalName
    itemCost
    longDescription
    manufacturerBrand
    manufacturerProductId
    manufacturerProduct {
      id
      name
    }
    manufacturerOrgId
    manufacturerOrg {
      id
      name
    }
    marketplaces
    markets
    materialId
    material {
      id
      name
    }
    metaDescription
    metaKeywords
    metaTitle
    name
    numberPackedItems
    price
    productKindId
    kind {
      id
      name
    }
    productStatusId
    status {
      id
      name
    }
    qtyUom
    reorderSmartContractId
    reorderSmartContract {
      id
      name
    }
    shops
    sicCodeId
    sicCode {
      id
      code
      name
    }
    sku
    smartContractId
    smartContract {
      id
      name
    }
    subindustry1 {
      id
      name
    }
    subindustry1Id
    subindustry2Id
    subindustry2 {
      id
      name
    }
    supplierOrgListId
    unHazMatId
    size
    createdAt
    updatedAt
    isDeleted
  }
`;
export const TradeFieldsFragmentDoc = gql`
  fragment tradeFields on Trade {
    id
    name
    description
    createdAt
    updatedAt
    isDeleted
    clearinghouseId
    clearinghouse {
      ...clearinghouseFields
    }
    tradeKindId
    kind {
      ...tradeKindFields
    }
  }
  ${ClearinghouseFieldsFragmentDoc}
  ${TradeKindFieldsFragmentDoc}
`;
export const WalletKindFieldsFragmentDoc = gql`
  fragment walletKindFields on WalletKind {
    id
    name
    merchant
    organization
    user
    createdAt
    updatedAt
    isDeleted
  }
`;
export const WalletFieldsFragmentDoc = gql`
  fragment walletFields on Wallet {
    id
    name
    walletAddress
    primaryHash
    secondaryHash
    createdAt
    updatedAt
    isDeleted
    defaultCurrencyId
    defaultCurrency {
      ...currencyFields
    }
    primaryUserId
    primaryUser {
      ...userFields
    }
    secondaryUserId
    secondaryUser {
      ...userFields
    }
    walletKindId
    kind {
      ...walletKindFields
    }
  }
  ${CurrencyFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${WalletKindFieldsFragmentDoc}
`;
export const AskFieldsFragmentDoc = gql`
  fragment askFields on Ask {
    id
    name
    startingAsk
    description
    askNumber
    createdAt
    updatedAt
    isDeleted
    currencyId
    currency {
      ...currencyFields
    }
    exchangeId
    exchange {
      ...exchangeFields
    }
    assetId
    asset {
      ...assetFields
    }
    productId
    product {
      ...productFields
    }
    tradeId
    trade {
      ...tradeFields
    }
    walletId
    wallet {
      ...walletFields
    }
    image
    startDate
    endDate
    totalAvailable
    firmPrice
  }
  ${CurrencyFieldsFragmentDoc}
  ${ExchangeFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${ProductFieldsFragmentDoc}
  ${TradeFieldsFragmentDoc}
  ${WalletFieldsFragmentDoc}
`;
export const AssetAccountFieldsFragmentDoc = gql`
  fragment assetAccountFields on AssetAccount {
    id
    accountNumber
    availableAt
    cvv
    expirationDate
    ibanNumber
    issueDate
    organizationId
    organization {
      ...organizationFields
    }
    routingNumber
    securityCode
    swiftCode
    createdAt
    updatedAt
    isDeleted
    userId
    user {
      ...userFields
    }
    balanceAmount
    accountKind
    accountName
    achNumber
    ccNumber
  }
  ${OrganizationFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const PaymentFieldsFragmentDoc = gql`
  fragment paymentFields on Payment {
    id
    orderId
    amountCrwdunit
    amountCurrency
    amountUsd
    paymentDetails
    receiveWalletTxn
    sendWalletTxn
    successUrl
    cancelUrl
    comment
    createdAt
    updatedAt
    isDeleted
    currencyId
    currency {
      ...currencyFields
    }
    userId
    user {
      ...userFields
    }
    statusId
    status {
      id
      name
    }
    askId
    ask {
      id
      name
    }
    putId
    put {
      id
      name
    }
    assetAccountId
    assetAccount {
      ...assetAccountFields
    }
  }
  ${CurrencyFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${AssetAccountFieldsFragmentDoc}
`;
export const TransactionKindFieldsFragmentDoc = gql`
  fragment transactionKindFields on TransactionKind {
    id
    name
    createdAt
    updatedAt
    isDeleted
    smartContractId
    description
    smartContract {
      ...smartContractFields
    }
    subIndustry1Id
    subindustry1 {
      ...subindustryFields
    }
    subIndustry2Id
    subindustry2 {
      ...subindustryFields
    }
  }
  ${SmartContractFieldsFragmentDoc}
  ${SubindustryFieldsFragmentDoc}
`;
export const TransactionFieldsFragmentDoc = gql`
  fragment transactionFields on Transaction {
    id
    txnId
    networkFee
    destTag
    confirmation
    amount
    description
    createdAt
    updatedAt
    isDeleted
    fromWalletId
    fromWallet {
      ...walletFields
    }
    paymentId
    payment {
      ...paymentFields
    }
    smartContractId
    smartContract {
      ...smartContractFields
    }
    statusId
    status {
      ...statusFields
    }
    toWalletId
    toWallet {
      ...walletFields
    }
    transactionKindId
    kind {
      ...transactionKindFields
    }
    currencyId
    currency {
      id
      name
    }
  }
  ${WalletFieldsFragmentDoc}
  ${PaymentFieldsFragmentDoc}
  ${SmartContractFieldsFragmentDoc}
  ${StatusFieldsFragmentDoc}
  ${TransactionKindFieldsFragmentDoc}
`;
export const AskPutTransactionFieldsFragmentDoc = gql`
  fragment askPutTransactionFields on AskPutTransaction {
    id
    askId
    ask {
      id
      name
    }
    putId
    put {
      id
      name
    }
    transactionId
    transaction {
      ...transactionFields
    }
    description
    createdAt
    updatedAt
    isDeleted
  }
  ${TransactionFieldsFragmentDoc}
`;
export const SigmaPrimeAddressFieldsFragmentDoc = gql`
  fragment sigmaPrimeAddressFields on SigmaPrimeAddress {
    id
    createdAt
    updatedAt
    personUniqueHash
    sigmaPrimeId
    sigmaPrime {
      ...sigmaPrimeFields
    }
    personFirstName
    personMiddleName
    personLastName
    personAddress1
    personAddress2
    personSector
    personCity
    personRegionCounty
    personProvinceState
    personPostal
    personPostal4
    personAddressLatitude
    personAddressLongitude
    geocode
    firstInHouse
    childPresent
    mfdu
    ageRange
    exactAge
    estimatedAge
    estimatedIncome
    addressType
    dwellingType
    homeownerType
    property
    medianHomeValue
    marital
    ethnicCd
    title
    medianYrsEducation
    sex
    dataPedigree
    estimatedWealth
    personPhoneNumber
    timeZone
    personDob
    personCountryCode
    isDeleted
    addressRecordType
    elotSequence
    rdi
    congressionalDistrict
    carrierRoute
    dpvCode
    personEmail
    addressIp
    addressEmail
    addressSsn
    addressSubindustry
  }
  ${SigmaPrimeFieldsFragmentDoc}
`;
export const AddressAttributesFieldsFragmentDoc = gql`
  fragment addressAttributesFields on AddressAttributes {
    id
    sigmaPrimeAddressId
    sigmaPrimeAddress {
      ...sigmaPrimeAddressFields
    }
    personUniqueHash
    datasourceId
    datasource {
      ...datasourceFields
    }
    personPhoneNumber
    addressEmail
    addressSubindustry
    uniqueSubindustry
    description
    comments
    createdAt
    updatedAt
    isDeleted
  }
  ${SigmaPrimeAddressFieldsFragmentDoc}
  ${DatasourceFieldsFragmentDoc}
`;
export const SmartContractLineageFieldsFragmentDoc = gql`
  fragment smartContractLineageFields on SmartContractLineage {
    id
    createdAt
    name
    lineageComments
    parentUserId
    smartContractId
    updatedAt
    userId
    isDeleted
    parentUser {
      ...userFields
    }
    user {
      ...userFields
    }
    organizationId
    parentOrganizationId
    organization {
      ...organizationFields
    }
    parentOrganization {
      ...organizationFields
    }
  }
  ${UserFieldsFragmentDoc}
  ${OrganizationFieldsFragmentDoc}
`;
export const SmartContractCommissionFieldsFragmentDoc = gql`
  fragment smartContractCommissionFields on SmartContractCommission {
    id
    productId
    product {
      ...productFields
    }
    smartContractLineageId
    smartContractLineage {
      ...smartContractLineageFields
    }
    tier1
    tier2
    tier3
    tier4
    tier5
    tier6
    tier7
    tier8
    tier9
    tier10
    createdAt
    updatedAt
    isDeleted
  }
  ${ProductFieldsFragmentDoc}
  ${SmartContractLineageFieldsFragmentDoc}
`;
export const SmartContractDocumentFieldsFragmentDoc = gql`
  fragment smartContractDocumentFields on SmartContractDocument {
    id
    name
    documentSource
    datasourceId
    datasource {
      ...datasourceFields
    }
    createdAt
    updatedAt
    isDeleted
  }
  ${DatasourceFieldsFragmentDoc}
`;
export const DocumentUserFieldsFragmentDoc = gql`
  fragment documentUserFields on DocumentUser {
    createdAt
    documentLocation
    id
    isDeleted
    smartContractDocument {
      ...smartContractDocumentFields
    }
    smartContractDocumentId
    user {
      ...userFields
    }
    userId
    wallet {
      ...walletFields
    }
    walletId
  }
  ${SmartContractDocumentFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${WalletFieldsFragmentDoc}
`;
export const FavoriteFieldsFragmentDoc = gql`
  fragment favoriteFields on Favorite {
    id
    name
    blog
    createdAt
    updatedAt
    isDeleted
    walletId
    wallet {
      ...walletFields
    }
  }
  ${WalletFieldsFragmentDoc}
`;
export const WalletInventoryFieldsFragmentDoc = gql`
  fragment walletInventoryFields on WalletInventory {
    id
    accountNumber
    ibanNumber
    inventoryAmount
    notes
    restriction
    routingNumber
    swiftCode
    availableAt
    assetId
    asset {
      ...assetFields
    }
    currencyId
    currency {
      ...currencyFields
    }
    documentUserId
    documentUser {
      ...documentUserFields
    }
    favoriteId
    favorite {
      ...favoriteFields
    }
    productId
    product {
      ...productFields
    }
    transactionId
    transaction {
      ...transactionFields
    }
    walletId
    wallet {
      ...walletFields
    }
    isDeleted
    createdAt
    updatedAt
  }
  ${AssetFieldsFragmentDoc}
  ${CurrencyFieldsFragmentDoc}
  ${DocumentUserFieldsFragmentDoc}
  ${FavoriteFieldsFragmentDoc}
  ${ProductFieldsFragmentDoc}
  ${TransactionFieldsFragmentDoc}
  ${WalletFieldsFragmentDoc}
`;
export const ProductStatusFieldsFragmentDoc = gql`
  fragment productStatusFields on ProductStatus {
    id
    description
    createdAt
    name
    updatedAt
    isDeleted
  }
`;
export const ProductDistributionFieldsFragmentDoc = gql`
  fragment productDistributionFields on ProductDistribution {
    id
    name
    organizationId
    organization {
      ...organizationFields
    }
    productCategory
    productTags
    productStatusId
    productStatus {
      ...productStatusFields
    }
    shortDescription
    smartContractId
    smartContract {
      ...smartContractFields
    }
    userId
    user {
      ...userFields
    }
    visibility
    createdAt
    updatedAt
    isDeleted
  }
  ${OrganizationFieldsFragmentDoc}
  ${ProductStatusFieldsFragmentDoc}
  ${SmartContractFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const ProductKindFieldsFragmentDoc = gql`
  fragment productKindFields on ProductKind {
    id
    name
    clearinghouseId
    clearinghouse {
      ...clearinghouseFields
    }
    description
    createdAt
    updatedAt
    isDeleted
  }
  ${ClearinghouseFieldsFragmentDoc}
`;
export const MarketFieldsFragmentDoc = gql`
  fragment marketFields on Market {
    createdAt
    description
    id
    images
    isDeleted
    logo
    name
    organization {
      ...organizationFields
    }
    organizationId
    smartContractId
    smartContract {
      ...smartContractFields
    }
    subindustry1Id
    subindustry2Id
    subindustry1 {
      ...subindustryFields
    }
    subindustry2 {
      ...subindustryFields
    }
    updatedAt
    url
    user {
      ...userFields
    }
    userId
  }
  ${OrganizationFieldsFragmentDoc}
  ${SmartContractFieldsFragmentDoc}
  ${SubindustryFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const ShopFieldsFragmentDoc = gql`
  fragment shopFields on Shop {
    createdAt
    id
    isDeleted
    market {
      ...marketFields
    }
    marketId
    shopDescription
    shopKind
    shopUrl
    smartContractId
    smartContract {
      ...smartContractFields
    }
    uniqueMarketUrl
    updatedAt
    user {
      ...userFields
    }
    userId
  }
  ${MarketFieldsFragmentDoc}
  ${SmartContractFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const CartFieldsFragmentDoc = gql`
  fragment cartFields on Cart {
    id
    emailAddress
    ipaddress
    market {
      ...marketFields
    }
    marketId
    product {
      ...productFields
    }
    productId
    rememberToken
    shop {
      ...shopFields
    }
    shopId
    sigmaPrimeAddress
    sigmaPrimeId
    sigmaPrime {
      ...sigmaPrimeFields
    }
    user {
      ...userFields
    }
    userId
    createdAt
    updatedAt
    isDeleted
  }
  ${MarketFieldsFragmentDoc}
  ${ProductFieldsFragmentDoc}
  ${ShopFieldsFragmentDoc}
  ${SigmaPrimeFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const OrganizationStatusFieldsFragmentDoc = gql`
  fragment organizationStatusFields on OrganizationStatus {
    clearinghouse {
      ...clearinghouseFields
    }
    clearinghouseId
    createdAt
    description
    id
    isDeleted
    name
    organization {
      ...organizationFields
    }
    organizationId
    updatedAt
  }
  ${ClearinghouseFieldsFragmentDoc}
  ${OrganizationFieldsFragmentDoc}
`;
export const OrderFieldsFragmentDoc = gql`
  fragment orderFields on Order {
    id
    askId
    ask {
      id
      name
    }
    productDistributionId
    productDistribution {
      id
      name
    }
    putId
    put {
      id
      name
    }
    userId
    user {
      id
      uniqueHash
      rememberToken
    }
    orderToken
    shipCountyRegion
    shipEmail
    shipFee
    shipGeo
    shipMethod
    shipPhone
    shipPostal4
    shipPostalCode
    shipProvinceState
    shipSector
    shipStreet1
    shipStreet2
    sigmaPrimeAddressId
    sigmaPrimeAddress {
      id
      title
    }
    createdAt
    updatedAt
    isDeleted
  }
`;
export const ShippingFieldsFragmentDoc = gql`
  fragment shippingFields on Shipping {
    createdAt
    deliveryDate
    deliverytype
    expectedDeliveryDate
    fromOrganizationId
    fromUserId
    fromOrganization {
      ...organizationFields
    }
    fromUser {
      ...userFields
    }
    id
    material {
      ...materialFields
    }
    materialId
    order {
      ...orderFields
    }
    orderId
    product {
      ...productFields
    }
    productId
    shipDate
    shippingCarrierOrgId
    shippingDetails
    shippingCarrierOrg {
      ...organizationFields
    }
    toOrganizationId
    toUserId
    toOrganization {
      ...organizationFields
    }
    toUser {
      ...userFields
    }
    trackingNumber
    updatedAt
  }
  ${OrganizationFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${MaterialFieldsFragmentDoc}
  ${OrderFieldsFragmentDoc}
  ${ProductFieldsFragmentDoc}
`;
export const SupplyChainWaypointFieldsFragmentDoc = gql`
  fragment supplyChainWaypointFields on SupplyChainWaypoint {
    actualProcessTime
    actualQueueTime
    avgProcessTime
    avgQueueTime
    consigneeOrgId
    consigneeOrg {
      ...organizationFields
    }
    consignorOrgId
    consignorOrg {
      ...organizationFields
    }
    createdAt
    customsOrgId
    customsOrg {
      ...organizationFields
    }
    description
    freightForwarderOrgId
    freightForwarderOrg {
      ...organizationFields
    }
    fromShippingCarrierOrgId
    fromShippingCarrierOrg {
      ...organizationFields
    }
    geo
    id
    isDeleted
    latitude
    longitude
    manufacturerOrgId
    manufacturerOrg {
      ...organizationFields
    }
    name
    organizationFunction
    originDestinationAgentOrgId
    originDestinationAgentOrg {
      ...organizationFields
    }
    shippingId
    smartContractId
    smartContract {
      ...smartContractFields
    }
    status
    toShippingCarrierOrgId
    toShippingCarrierOrg {
      ...organizationFields
    }
    updatedAt
    waypointC2c
    waypointCt
    waypointNumber
    waypointPoi
    waypointTotal
  }
  ${OrganizationFieldsFragmentDoc}
  ${SmartContractFieldsFragmentDoc}
`;
export const CacheFieldsFragmentDoc = gql`
  fragment cacheFields on Cache {
    key
    value
    createdAt
    updatedAt
    expiresAt
  }
`;
export const KycKindFieldsFragmentDoc = gql`
  fragment kycKindFields on KycKind {
    id
    name
    description
    createdAt
    updatedAt
    isDeleted
  }
`;
export const KycStatusFieldsFragmentDoc = gql`
  fragment kycStatusFields on KycStatus {
    id
    name
    description
    isDeleted
    createdAt
    countryCode
    updatedAt
    kycKind {
      ...kycKindFields
    }
    kycKindId
    sigmaPrime {
      ...sigmaPrimeFields
    }
    sigmaPrimeId
    organization {
      ...organizationFields
    }
    organizationId
  }
  ${KycKindFieldsFragmentDoc}
  ${SigmaPrimeFieldsFragmentDoc}
  ${OrganizationFieldsFragmentDoc}
`;
export const UpsertAppraisalDocument = gql`
  mutation upsertAppraisal($objects: [AppraisalInsertInput!]!) {
    upsertAppraisal: insertAppraisal(
      objects: $objects
      onConflict: {
        constraint: appraisal_pkey
        updateColumns: [name, description, organizationId, smartContractId, userId, isDeleted]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertAskDocument = gql`
  mutation upsertAsk($objects: [AskInsertInput!]!) {
    upsertAsk: insertAsk(
      objects: $objects
      onConflict: {
        constraint: ask_pkey
        updateColumns: [
          name
          startingAsk
          description
          askNumber
          isDeleted
          currencyId
          exchangeId
          assetId
          productId
          tradeId
          walletId
          totalAvailable
          startDate
          endDate
          image
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertAskPutTransactionDocument = gql`
  mutation upsertAskPutTransaction($objects: [AskPutTransactionInsertInput!]!) {
    upsertAskPutTransaction: insertAskPutTransaction(
      objects: $objects
      onConflict: {
        constraint: ask_put_transaction_pkey
        updateColumns: [id, askId, putId, transactionId, description, createdAt, updatedAt, isDeleted]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertCertificationDocument = gql`
  mutation upsertCertification($objects: [CertificationInsertInput!]!) {
    upsertCertification: insertCertification(
      objects: $objects
      onConflict: {
        constraint: certification_pkey
        updateColumns: [name, description, isDeleted, gicsSectorId, groupId, organizationId, industryId, subindustryId]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertClearinghouseDocument = gql`
  mutation upsertClearinghouse($objects: [ClearinghouseInsertInput!]!) {
    upsertClearinghouse: insertClearinghouse(
      objects: $objects
      onConflict: {
        constraint: clearinghouse_pkey
        updateColumns: [name, description, isDeleted, clearinghouseKindId, userId]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertClearinghouseCertificationDocument = gql`
  mutation upsertClearinghouseCertification($objects: [ClearinghouseCertificationInsertInput!]!) {
    upsertClearinghouseCertification: insertClearinghouseCertification(
      objects: $objects
      onConflict: {
        constraint: clearinghouse_certification_pkey
        updateColumns: [isActive, isDeleted, certificationId, clearinghouseId]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertClearinghouseKindDocument = gql`
  mutation upsertClearinghouseKind($objects: [ClearinghouseKindInsertInput!]!) {
    upsertClearinghouseKind: insertClearinghouseKind(
      objects: $objects
      onConflict: {
        constraint: clearinghouse_kind_pkey
        updateColumns: [name, description, isDeleted, gicsSectorId, groupId, industryId, subindustryId]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertCountriesDocument = gql`
  mutation upsertCountries($objects: [CountryInsertInput!]!) {
    upsertCountries: insertCountry(
      objects: $objects
      onConflict: {
        constraint: address_country_pkey
        updateColumns: [
          name
          region
          phoneCode
          secCode
          stateLabel
          longitude
          latitude
          isoNumber
          flagEmojiUnicode
          flagEmoji
          currencyCode
          isDeleted
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertCurrencyDocument = gql`
  mutation upsertCurrency($objects: [CurrencyInsertInput!]!) {
    upsertCurrency: insertCurrency(
      objects: $objects
      onConflict: {
        constraint: currency_pkey
        updateColumns: [
          abbreviation
          authorized
          cc
          description
          reserved
          name
          issued
          image
          symbol
          numericCode
          minorUnit
          isDeleted
          countryCode
          currencyKindId
          groupsId
          sectorId
          subindustryId
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertCurrencyKindDocument = gql`
  mutation upsertCurrencyKind($objects: [CurrencyKindInsertInput!]!) {
    upsertCurrencyKind: insertCurrencyKind(
      objects: $objects
      onConflict: {
        constraint: currency_kind_pkey
        updateColumns: [name, description, isDeleted, gicsSectorId, groupId, industryId, subindustryId]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertCurrencyOwnerDocument = gql`
  mutation upsertCurrencyOwner($objects: [CurrencyOwnerInsertInput!]!) {
    upsertCurrencyOwner: insertCurrencyOwner(
      objects: $objects
      onConflict: {
        constraint: currency_owner_pkey
        updateColumns: [
          dateAuthorized
          dateIssued
          ownerTransfer
          isDeleted
          currencyId
          grantingOrganizationId
          organizationId
          originalOrganizationId
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertGroupsDocument = gql`
  mutation upsertGroups($objects: [GroupInsertInput!]!) {
    upsertGroups: insertGroup(
      objects: $objects
      onConflict: {
        constraint: group_pkey
        updateColumns: [name, description, imageGroup, gicsSectorId, sicCodeId, isDeleted]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertOrganizationsDocument = gql`
  mutation upsertOrganizations($objects: [OrganizationInsertInput!]!) {
    upsertOrganizations: insertOrganization(
      objects: $objects
      onConflict: {
        constraint: organization_pkey
        updateColumns: [
          name
          crwdsystemsApiReference
          sicCodeId
          sub1IndustryId
          sub2IndustryId
          organizationKindId
          organizationPrimaryAddress1
          organizationPrimaryAddress2
          organizationPrimaryCity
          organizationPrimaryState
          organizationPrimaryPostalCode
          organizationPrimaryRegion
          organizationPrimaryCountryCode
          organizationPrimaryPhone
          geocode
          crwdTicker
          organizationPersonStatus
          personUniqueHash
          personFirstName
          personMiddleName
          personLastName
          companyTitleName
          personPrimaryMobile
          personBusinessEmail
          companyPrimaryWebsite
          companyEmployeeCount
          companyRevenue
          sicCodeId
          industryId
          sub1IndustryId
          sub2IndustryId
          isDeleted
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertOrganizationKindDocument = gql`
  mutation upsertOrganizationKind($objects: [OrganizationKindInsertInput!]!) {
    upsertOrganizationKind: insertOrganizationKind(
      objects: $objects
      onConflict: { constraint: organization_kind_pkey, updateColumns: [name, description, isDeleted] }
    ) {
      affectedRows
    }
  }
`;
export const UpsertSubindustriesDocument = gql`
  mutation upsertSubindustries($objects: [SubindustryInsertInput!]!) {
    upsertSubindustries: insertSubindustry(
      objects: $objects
      onConflict: {
        constraint: subindustry_pkey
        updateColumns: [name, description, imageSubindustry, parentIndustryId, sicCodeId, isDeleted]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertIndustriesDocument = gql`
  mutation upsertIndustries($objects: [IndustryInsertInput!]!) {
    upsertIndustries: insertIndustry(
      objects: $objects
      onConflict: {
        constraint: industry_pkey
        updateColumns: [name, description, imageIndustry, groupId, sicCodeId, isDeleted]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertExchangeKindDocument = gql`
  mutation upsertExchangeKind($objects: [ExchangeKindInsertInput!]!) {
    upsertExchangeKind: insertExchangeKind(
      objects: $objects
      onConflict: { constraint: exchange_kind_pkey, updateColumns: [name, isDeleted] }
    ) {
      affectedRows
    }
  }
`;
export const UpsertExchangeDocument = gql`
  mutation upsertExchange($objects: [ExchangeInsertInput!]!) {
    upsertExchange: insertExchange(
      objects: $objects
      onConflict: {
        constraint: exchange_pkey
        updateColumns: [amount, description, exchangeKindId, name, swapCurrencyId, tradeKindId, userId, isDeleted]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertSicCodeDocument = gql`
  mutation upsertSicCode($objects: [SicCodeInsertInput!]!) {
    upsertSicCode: insertSicCode(
      objects: $objects
      onConflict: {
        constraint: sic_code_pkey
        updateColumns: [code, name, sicCodeOffice, gicsCodeName, gicsCode, isDeleted]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertGicsSectorDocument = gql`
  mutation upsertGicsSector($objects: [GicsSectorInsertInput!]!) {
    upsertGicsSector: insertGicsSector(
      objects: $objects
      onConflict: {
        constraint: gics_sector_pkey
        updateColumns: [description, imageSector, name, sicCodeId, isDeleted]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertStatusesDocument = gql`
  mutation upsertStatuses($objects: [StatusInsertInput!]!) {
    upsertStatuses: insertStatus(
      objects: $objects
      onConflict: { constraint: status_pkey, updateColumns: [name, description, kindId, startDate, endDate, isDeleted] }
    ) {
      affectedRows
    }
  }
`;
export const UpsertStatusKindsDocument = gql`
  mutation upsertStatusKinds($objects: [StatusKindInsertInput!]!) {
    upsertStatusKinds: insertStatusKind(
      objects: $objects
      onConflict: { constraint: status_kind_pkey, updateColumns: [name, description, isDeleted] }
    ) {
      affectedRows
    }
  }
`;
export const UpsertInteractionDocument = gql`
  mutation upsertInteraction($objects: [InteractionInsertInput!]!) {
    upsertInteraction: insertInteraction(
      objects: $objects
      onConflict: {
        constraint: interaction_pkey
        updateColumns: [liked, useCount, isDeleted, currencyId, merchantId, userId]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertMerchantDocument = gql`
  mutation upsertMerchant($objects: [MerchantInsertInput!]!) {
    upsertMerchant: insertMerchant(
      objects: $objects
      onConflict: {
        constraint: merchant_pkey
        updateColumns: [name, description, crwdsystemsApi, notifyUrl, uniqueHash, url, isDeleted, userId, statusId]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertMerchantCurrencyDocument = gql`
  mutation upsertMerchantCurrency($objects: [MerchantCurrencyInsertInput!]!) {
    upsertMerchantCurrency: insertMerchantCurrency(
      objects: $objects
      onConflict: { constraint: merchant_currency_pkey, updateColumns: [isDeleted, currencyId, merchantId, statusId] }
    ) {
      affectedRows
    }
  }
`;
export const UpsertMerchantOrganizationDocument = gql`
  mutation upsertMerchantOrganization($objects: [MerchantOrganizationInsertInput!]!) {
    upsertMerchantOrganization: insertMerchantOrganization(
      objects: $objects
      onConflict: { constraint: merchant_organization_pkey, updateColumns: [isDeleted, merchantId, organizationId] }
    ) {
      affectedRows
    }
  }
`;
export const UpsertAssetDocument = gql`
  mutation upsertAsset($objects: [AssetInsertInput!]!) {
    upsertAsset: insertAsset(
      objects: $objects
      onConflict: {
        constraint: nfa_pkey
        updateColumns: [
          appraisalId
          assetAccountId
          assetKindId
          category
          classification
          countryCode
          datasourceId
          description
          dimensions
          embedCode
          format
          images
          isDeleted
          materialId
          materials
          name
          organizationId
          parentCategory
          sourceUrl
          sourceUserId
          subindustryId
          uom
          userId
          productId
          issued
          authorized
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertAssetKindDocument = gql`
  mutation upsertAssetKind($objects: [AssetKindInsertInput!]!) {
    upsertAssetKind: insertAssetKind(
      objects: $objects
      onConflict: { constraint: nfa_kind_pkey, updateColumns: [name, description, isDeleted, subindustryId, images] }
    ) {
      affectedRows
    }
  }
`;
export const UpsertPutDocument = gql`
  mutation upsertPut($objects: [PutInsertInput!]!) {
    upsertPut: insertPut(
      objects: $objects
      onConflict: {
        constraint: put_pkey
        updateColumns: [name, amount, winningPut, isDeleted, askId, currencyId, smartContractId, walletId]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertSettingsDocument = gql`
  mutation upsertSettings($objects: [SettingInsertInput!]!) {
    upsertSettings: insertSetting(
      objects: $objects
      onConflict: { constraint: settings_pkey, updateColumns: [key, value, isDeleted] }
    ) {
      affectedRows
    }
  }
`;
export const UpsertSmartContractLineageDocument = gql`
  mutation upsertSmartContractLineage($objects: [SmartContractLineageInsertInput!]!) {
    upsertSmartContractLineage: insertSmartContractLineage(
      objects: $objects
      onConflict: {
        constraint: smart_contract_lineage_pkey
        updateColumns: [
          lineageComments
          parentUserId
          name
          smartContractId
          isDeleted
          userId
          organizationId
          parentOrganizationId
          isDeleted
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertDocumentUserDocument = gql`
  mutation upsertDocumentUser($objects: [DocumentUserInsertInput!]!) {
    upsertDocumentUser: insertDocumentUser(
      objects: $objects
      onConflict: {
        constraint: document_user_pkey
        updateColumns: [createdAt, documentLocation, isDeleted, smartContractDocumentId, userId, walletId]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertProductStatusDocument = gql`
  mutation upsertProductStatus($objects: [ProductStatusInsertInput!]!) {
    upsertProductStatusLineage: insertProductStatus(
      objects: $objects
      onConflict: { constraint: product_status_pkey, updateColumns: [id, description, name, updatedAt, isDeleted] }
    ) {
      affectedRows
    }
  }
`;
export const UpsertSmartContractDocument = gql`
  mutation upsertSmartContract($objects: [SmartContractInsertInput!]!) {
    upsertSmartContract: insertSmartContract(
      objects: $objects
      onConflict: {
        constraint: smart_contract_pkey
        updateColumns: [name, description, useCount, terms, isDeleted, clearinghouseId, smartContractKindId]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertSmartContractKindDocument = gql`
  mutation upsertSmartContractKind($objects: [SmartContractKindInsertInput!]!) {
    upsertSmartContractKind: insertSmartContractKind(
      objects: $objects
      onConflict: { constraint: smart_contract_kind_pkey, updateColumns: [name, description, isDeleted, subindustryId] }
    ) {
      affectedRows
    }
  }
`;
export const UpsertTradeDocument = gql`
  mutation upsertTrade($objects: [TradeInsertInput!]!) {
    upsertTrade: insertTrade(
      objects: $objects
      onConflict: {
        constraint: trade_pkey
        updateColumns: [name, description, isDeleted, clearinghouseId, tradeKindId]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertTransactionDocument = gql`
  mutation upsertTransaction($objects: [TransactionInsertInput!]!) {
    upsertTransaction: insertTransaction(
      objects: $objects
      onConflict: {
        constraint: transaction_pkey
        updateColumns: [
          txnId
          networkFee
          destTag
          confirmation
          amount
          description
          createdAt
          updatedAt
          isDeleted
          fromWalletId
          paymentId
          smartContractId
          statusId
          toWalletId
          transactionKindId
          currencyId
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertTransactionKindDocument = gql`
  mutation upsertTransactionKind($objects: [TransactionKindInsertInput!]!) {
    upsertTransaction: insertTransactionKind(
      objects: $objects
      onConflict: {
        constraint: transaction_kind_pkey
        updateColumns: [
          name
          createdAt
          updatedAt
          description
          isDeleted
          smartContractId
          subIndustry1Id
          subIndustry2Id
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertTradeKindDocument = gql`
  mutation upsertTradeKind($objects: [TradeKindInsertInput!]!) {
    upsertTradeKind: insertTradeKind(
      objects: $objects
      onConflict: { constraint: trade_kind_pkey, updateColumns: [name, isDeleted] }
    ) {
      affectedRows
    }
  }
`;
export const UpsertWalletDocument = gql`
  mutation upsertWallet($objects: [WalletInsertInput!]!) {
    upsertWallet: insertWallet(
      objects: $objects
      onConflict: {
        constraint: wallet_pkey
        updateColumns: [
          name
          walletAddress
          primaryHash
          secondaryHash
          isDeleted
          defaultCurrencyId
          primaryUserId
          secondaryUserId
          walletKindId
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertWalletKindDocument = gql`
  mutation upsertWalletKind($objects: [WalletKindInsertInput!]!) {
    upsertWalletKind: insertWalletKind(
      objects: $objects
      onConflict: { constraint: wallet_kind_pkey, updateColumns: [name, merchant, organization, user, isDeleted] }
    ) {
      affectedRows
    }
  }
`;
export const UpsertSigmaPrimeKindDocument = gql`
  mutation upsertSigmaPrimeKind($objects: [SigmaPrimeKindInsertInput!]!) {
    upsertSigmaPrimeKind: insertSigmaPrimeKind(
      objects: $objects
      onConflict: { constraint: sigma_prime_kind_pkey, updateColumns: [name, description, isDeleted] }
    ) {
      affectedRows
    }
  }
`;
export const UpsertSigmaPrimeDocument = gql`
  mutation upsertSigmaPrime($objects: [SigmaPrimeInsertInput!]!) {
    upsertSigmaPrime: insertSigmaPrime(
      objects: $objects
      onConflict: {
        constraint: sigma_prime_person_primary_email_key
        updateColumns: [
          sigmaPrimeKindId
          crwdId
          personParent1SigmaId
          personParent2SigmaId
          personFirstName
          personMiddleName
          personLastName
          personFamilyName
          personNameSuffix
          personHandleName
          personTitleName
          personPrimaryMobile
          personPrimaryPhone
          personPrimaryIpAddress
          personPrimaryEmail
          personBusinessEmail
          personPrimaryFacePhoto
          personPrimaryKycStatus
          personPrimaryAmlStatus
          personPrivacyPolicy
          personStatus
          personExchangeRequest
          personUniqueHash
          personPrimaryLanguageId
          aboutMe
          profilePhoto
          userId
          isDeleted
        ]
      }
    ) {
      affectedRows
      returning {
        ...sigmaPrimeFields
      }
    }
  }
  ${SigmaPrimeFieldsFragmentDoc}
`;
export const UpdateSigmaPrimeDocument = gql`
  mutation updateSigmaPrime($id: uuid!, $set: SigmaPrimeSetInput!) {
    updateSigmaPrimeByPk(pkColumns: { id: $id }, _set: $set) {
      ...sigmaPrimeFields
    }
  }
  ${SigmaPrimeFieldsFragmentDoc}
`;
export const UpsertUserDocument = gql`
  mutation upsertUser($objects: [UserInsertInput!]!) {
    upsertUser: insertUser(
      objects: $objects
      onConflict: {
        constraint: user_email_key
        updateColumns: [
          email
          emailVerified
          preferences
          rememberToken
          isAdmin
          crwdsystemsApi
          organizationId
          statusId
          sigmaPrimeId
          uniqueHash
          countryCode
          isDeleted
        ]
      }
    ) {
      affectedRows
      returning {
        ...userFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const InsertUserDocument = gql`
  mutation insertUser($object: UserInsertInput!) {
    insertUser: insertUserOne(object: $object) {
      ...userFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const InsertUserAuthDocument = gql`
  mutation insertUserAuth($object: UserAuthInsertInput!) {
    insertUserAuth: insertUserAuthOne(object: $object) {
      ...userAuthFields
    }
  }
  ${UserAuthFieldsFragmentDoc}
`;
export const UpdateUserDocument = gql`
  mutation updateUser($id: uuid!, $set: UserSetInput!) {
    updateUserByPk(pkColumns: { id: $id }, _set: $set) {
      ...userFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const UpsertUserAuthDocument = gql`
  mutation upsertUserAuth($object: UserAuthInsertInput!) {
    upsertUserAuth: insertUserAuthOne(
      object: $object
      onConflict: { constraint: user_auth_pkey, updateColumns: [hash, salt] }
    ) {
      ...userAuthFields
    }
  }
  ${UserAuthFieldsFragmentDoc}
`;
export const VerifyEmailDocument = gql`
  mutation verifyEmail($email: citext!) {
    verifyEmail: updateUser(where: { email: { _eq: $email } }, _set: { emailVerified: true }) {
      affectedRows
    }
  }
`;
export const UpsertSigmaPrimeAddressDocument = gql`
  mutation upsertSigmaPrimeAddress($objects: [SigmaPrimeAddressInsertInput!]!) {
    upsertSigmaPrimeAddress: insertSigmaPrimeAddress(
      objects: $objects
      onConflict: {
        constraint: sigma_prime_address_pkey
        updateColumns: [
          personUniqueHash
          sigmaPrimeId
          personFirstName
          personMiddleName
          personLastName
          personAddress1
          personAddress2
          personSector
          personCity
          personRegionCounty
          personProvinceState
          personPostal
          personPostal4
          personAddressLatitude
          personAddressLongitude
          geocode
          firstInHouse
          childPresent
          mfdu
          ageRange
          exactAge
          estimatedAge
          estimatedIncome
          addressType
          dwellingType
          homeownerType
          property
          medianHomeValue
          marital
          ethnicCd
          title
          medianYrsEducation
          sex
          dataPedigree
          estimatedWealth
          personPhoneNumber
          timeZone
          personDob
          personCountryCode
          isDeleted
          addressRecordType
          elotSequence
          rdi
          congressionalDistrict
          carrierRoute
          dpvCode
          personEmail
        ]
      }
    ) {
      affectedRows
      returning {
        ...sigmaPrimeAddressFields
      }
    }
  }
  ${SigmaPrimeAddressFieldsFragmentDoc}
`;
export const UpsertAddressAttributesDocument = gql`
  mutation upsertAddressAttributes($objects: [AddressAttributesInsertInput!]!) {
    upsertAddressAttributes: insertAddressAttributes(
      objects: $objects
      onConflict: {
        constraint: address_attributes_pkey
        updateColumns: [
          sigmaPrimeAddressId
          personUniqueHash
          datasourceId
          personPhoneNumber
          addressEmail
          addressSubindustry
          uniqueSubindustry
          description
          comments
          isDeleted
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertDatasourceDocument = gql`
  mutation upsertDatasource($objects: [DatasourceInsertInput!]!) {
    upsertDatasource: insertDatasource(
      objects: $objects
      onConflict: {
        constraint: datasournce_pkey
        updateColumns: [name, location, organizationId, governance, pedigree, authorizedUserId]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertSmartContractCommissionDocument = gql`
  mutation upsertSmartContractCommission($objects: [SmartContractCommissionInsertInput!]!) {
    upsertSmartContractCommission: insertSmartContractCommission(
      objects: $objects
      onConflict: {
        constraint: smart_contract_commission_pkey
        updateColumns: [
          productId
          smartContractLineageId
          tier1
          tier2
          tier3
          tier4
          tier5
          tier6
          tier7
          tier8
          tier9
          tier10
          isDeleted
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertSmartContractDocumentDocument = gql`
  mutation upsertSmartContractDocument($objects: [SmartContractDocumentInsertInput!]!) {
    upsertSmartContractDocument: insertSmartContractDocument(
      objects: $objects
      onConflict: {
        constraint: smart_contract_document_pkey
        updateColumns: [name, documentSource, datasourceId, isDeleted]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertWalletInventoryDocument = gql`
  mutation upsertWalletInventory($objects: [WalletInventoryInsertInput!]!) {
    upsertWalletInventory: insertWalletInventory(
      objects: $objects
      onConflict: {
        constraint: wallet_inventory_pkey
        updateColumns: [
          accountNumber
          ibanNumber
          inventoryAmount
          notes
          restriction
          routingNumber
          swiftCode
          availableAt
          assetId
          currencyId
          documentUserId
          favoriteId
          productId
          transactionId
          walletId
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertAssetAccountDocument = gql`
  mutation upsertAssetAccount($objects: [AssetAccountInsertInput!]!) {
    upsertAssetAccount: insertAssetAccount(
      objects: $objects
      onConflict: {
        constraint: asset_account_pkey
        updateColumns: [
          accountNumber
          availableAt
          cvv
          expirationDate
          ibanNumber
          issueDate
          organizationId
          routingNumber
          securityCode
          swiftCode
          isDeleted
          userId
          balanceAmount
          accountKind
          accountName
          achNumber
          ccNumber
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertProductDistributionDocument = gql`
  mutation upsertProductDistribution($objects: [ProductDistributionInsertInput!]!) {
    upsertProductDistribution: insertProductDistribution(
      objects: $objects
      onConflict: {
        constraint: product_distribution_pkey
        updateColumns: [
          name
          organizationId
          productCategory
          productTags
          productStatusId
          shortDescription
          smartContractId
          userId
          visibility
          isDeleted
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertPaymentDocument = gql`
  mutation upsertPayment($objects: [PaymentInsertInput!]!) {
    upsertPayment: insertPayment(
      objects: $objects
      onConflict: {
        constraint: payment_pkey
        updateColumns: [
          orderId
          amountCrwdunit
          amountCurrency
          amountUsd
          paymentDetails
          receiveWalletTxn
          sendWalletTxn
          successUrl
          cancelUrl
          comment
          createdAt
          updatedAt
          isDeleted
          currencyId
          userId
          statusId
          askId
          putId
          assetAccountId
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertOrderDocument = gql`
  mutation upsertOrder($objects: [OrderInsertInput!]!) {
    upsertOrder: insertOrder(
      objects: $objects
      onConflict: {
        constraint: order_pkey
        updateColumns: [
          askId
          productDistributionId
          putId
          userId
          orderToken
          shipCountyRegion
          shipEmail
          shipFee
          shipGeo
          shipMethod
          shipPhone
          shipPostal4
          shipPostalCode
          shipProvinceState
          shipSector
          shipStreet1
          shipStreet2
          sigmaPrimeAddressId
          isDeleted
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertProductDocument = gql`
  mutation upsertProduct($objects: [ProductInsertInput!]!) {
    upsertProduct: insertProduct(
      objects: $objects
      onConflict: {
        constraint: product_pkey
        updateColumns: [
          actionUrlActivate
          actionUrlDeactivate
          assetId
          binId
          caliberString
          categories
          categoryNumber
          ceNumber
          chainNumberOfDevices
          contentList
          distanceUom
          duration
          effectHeight
          effectWidth
          exNumberList
          facebookReferenceId
          glAccountList
          googleReferenceId
          images
          internalName
          itemCost
          longDescription
          manufacturerBrand
          manufacturerProductId
          manufacturerOrgId
          marketplaces
          markets
          materialId
          metaDescription
          metaKeywords
          metaTitle
          name
          numberPackedItems
          price
          productKindId
          productStatusId
          qtyUom
          reorderSmartContractId
          shops
          sicCodeId
          sku
          smartContractId
          subindustry1Id
          subindustry2Id
          supplierOrgListId
          unHazMatId
          isDeleted
        ]
      }
    ) {
      affectedRows
      returning {
        ...productFields
      }
    }
  }
  ${ProductFieldsFragmentDoc}
`;
export const UpsertProductKindDocument = gql`
  mutation upsertProductKind($objects: [ProductKindInsertInput!]!) {
    upsertProductKind: insertProductKind(
      objects: $objects
      onConflict: { constraint: product_kind_pkey, updateColumns: [name, clearinghouseId, description, isDeleted] }
    ) {
      affectedRows
    }
  }
`;
export const UpsertMaterialDocument = gql`
  mutation upsertMaterial($objects: [MaterialInsertInput!]!) {
    upsertMaterial: insertMaterial(
      objects: $objects
      onConflict: {
        constraint: material_pkey
        updateColumns: [
          name
          description
          detailsUrl
          image
          isCommodity
          materialCategory
          materialSubcategory
          materialType
          symbol
          isDeleted
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertCartDocument = gql`
  mutation upsertCart($objects: [CartInsertInput!]!) {
    upsertCart: insertCart(
      objects: $objects
      onConflict: {
        constraint: cart_pkey
        updateColumns: [
          emailAddress
          ipaddress
          marketId
          productId
          rememberToken
          shopId
          sigmaPrimeAddress
          sigmaPrimeId
          userId
          isDeleted
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertMarketDocument = gql`
  mutation upsertMarket($objects: [MarketInsertInput!]!) {
    upsertMarket: insertMarket(
      objects: $objects
      onConflict: {
        constraint: market_pkey
        updateColumns: [
          description
          images
          isDeleted
          logo
          name
          organizationId
          smartContractId
          subindustry1Id
          subindustry2Id
          url
          userId
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertOrganizationStatusDocument = gql`
  mutation upsertOrganizationStatus($objects: [OrganizationStatusInsertInput!]!) {
    upsertOrganizationStatus: insertOrganizationStatus(
      objects: $objects
      onConflict: {
        constraint: organization_status_pkey
        updateColumns: [clearinghouseId, description, isDeleted, name, organizationId]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertShippingDocument = gql`
  mutation upsertShipping($objects: [ShippingInsertInput!]!) {
    upsertShipping: insertShipping(
      objects: $objects
      onConflict: {
        constraint: shipping_pkey
        updateColumns: [
          deliveryDate
          deliverytype
          expectedDeliveryDate
          fromOrganizationId
          fromUserId
          materialId
          orderId
          productId
          shipDate
          shippingCarrierOrgId
          shippingDetails
          toOrganizationId
          toUserId
          trackingNumber
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertShopDocument = gql`
  mutation upsertShop($objects: [ShopInsertInput!]!) {
    upsertShop: insertShop(
      objects: $objects
      onConflict: {
        constraint: shop_pkey
        updateColumns: [
          isDeleted
          marketId
          shopDescription
          shopKind
          shopUrl
          smartContractId
          uniqueMarketUrl
          userId
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertSupplyChainWaypointsDocument = gql`
  mutation upsertSupplyChainWaypoints($objects: [SupplyChainWaypointInsertInput!]!) {
    upsertSupplyChainWaypoints: insertSupplyChainWaypoint(
      objects: $objects
      onConflict: {
        constraint: supply_chain_waypoints_pkey
        updateColumns: [
          actualProcessTime
          actualQueueTime
          avgProcessTime
          avgQueueTime
          consigneeOrgId
          consignorOrgId
          customsOrgId
          description
          freightForwarderOrgId
          fromShippingCarrierOrgId
          geo
          isDeleted
          latitude
          longitude
          manufacturerOrgId
          name
          organizationFunction
          originDestinationAgentOrgId
          shippingId
          smartContractId
          status
          toShippingCarrierOrgId
          waypointC2c
          waypointCt
          waypointNumber
          waypointPoi
          waypointTotal
        ]
      }
    ) {
      affectedRows
    }
  }
`;
export const GetAskByAssetDocument = gql`
  query getAskByAsset($assetId: uuid!) {
    getAskByAsset: ask(where: { assetId: { _eq: $assetId } }) {
      id
      name
      startingAsk
      description
      askNumber
      startDate
      endDate
      totalAvailable
      description
      wallet {
        id
        name
      }
      trade {
        id
        name
      }
      image
      currency {
        id
        name
      }
    }
  }
`;
export const GetAskByIdDocument = gql`
  query getAskById($id: uuid!) {
    getAskById: ask(where: { id: { _eq: $id } }) {
      id
      name
      startingAsk
      description
      askNumber
      startDate
      endDate
      totalAvailable
      description
      image
      firmPrice
      currency {
        id
        name
      }
      wallet {
        id
        name
        walletAddress
        primaryHash
        secondaryHash
      }
      product {
        id
        name
        sku
        size
        caliberString
        categories
        categoryNumber
        ceNumber
        chainNumberOfDevices
        contentList
        distanceUom
        duration
        effectHeight
        effectWidth
        exNumberList
        facebookReferenceId
        glAccountList
        googleReferenceId
        images
        internalName
        itemCost
        longDescription
        metaDescription
        metaKeywords
        metaTitle
        numberPackedItems
        price
        productKindId
        manufacturerBrand
      }
    }
  }
`;
export const GetPutByAskDocument = gql`
  query getPutByAsk($askId: uuid!) {
    getPutByAsk: put(where: { askId: { _eq: $askId } }) {
      id
      name
      amount
      currency {
        id
        name
      }
      wallet {
        id
        name
      }
    }
  }
`;
export const GetMaxAmountPutByAskIdDocument = gql`
  query getMaxAmountPutByAskId($askId: uuid!) {
    getMaxAmountPutByAskId: put(where: { askId: { _eq: $askId } }, orderBy: { amount: DESC_NULLS_LAST }, limit: 1) {
      id
      name
      amount
      currency {
        id
        name
      }
    }
  }
`;
export const GetFeaturedAskDocument = gql`
  query getFeaturedAsk($assetId: uuid!) {
    getFeaturedAsk: ask(where: { assetId: { _eq: $assetId } }, orderBy: { endDate: DESC_NULLS_LAST }, limit: 1) {
      id
      name
      startingAsk
      description
      askNumber
      startDate
      endDate
      totalAvailable
      description
      firmPrice
      currency {
        id
        name
      }
      wallet {
        id
        name
        walletAddress
        primaryHash
        secondaryHash
      }
    }
  }
`;
export const GetPutsByAssetIdDocument = gql`
  query getPutsByAssetId($assetId: uuid!) {
    getPutsByAssetId: put(where: { ask: { assetId: { _eq: $assetId } } }) {
      id
      name
      createdAt
    }
  }
`;
export const UpsertCacheDocument = gql`
  mutation upsertCache($object: CacheInsertInput!) {
    upsertCache: insertCacheOne(
      object: $object
      onConflict: { constraint: cache_pkey, updateColumns: [value, updatedAt, expiresAt] }
    ) {
      ...cacheFields
    }
  }
  ${CacheFieldsFragmentDoc}
`;
export const DeleteCacheDocument = gql`
  mutation deleteCache($key: String!) {
    deleteCache: deleteCacheByPk(key: $key) {
      key
      value
    }
  }
`;
export const UpsertKycStatusDocument = gql`
  mutation upsertKycStatus($objects: [KycStatusInsertInput!]!) {
    upsertKycStatus: insertKycStatus(
      objects: $objects
      onConflict: {
        constraint: kyc_status_pkey
        updateColumns: [name, description, countryCode, kycKindId, sigmaPrimeId, organizationId]
      }
    ) {
      affectedRows
    }
  }
`;
export const UpsertKycKindDocument = gql`
  mutation upsertKycKind($objects: [KycKindInsertInput!]!) {
    upsertKycKind: insertKycKind(
      objects: $objects
      onConflict: { constraint: kyc_kind_pkey, updateColumns: [name, description] }
    ) {
      affectedRows
    }
  }
`;
export const CountriesDocument = gql`
  query countries {
    countries: country {
      ...countryFields
    }
  }
  ${CountryFieldsFragmentDoc}
`;
export const AddressStatesByCountryCodeDocument = gql`
  query addressStatesByCountryCode($countryCode: String!) {
    addressStatesByCountryCode: state(where: { countryCode: { _eq: $countryCode } }) {
      ...stateFields
    }
  }
  ${StateFieldsFragmentDoc}
`;
export const GetOrganizationByUserIdDocument = gql`
  query getOrganizationByUserId($userId: String!) {
    organization: organization(where: { crwdsystemsApiReference: { _eq: $userId }, isDeleted: { _eq: false } }) {
      id
      name
      crwdsystemsApiReference
      organizationKindId
      organizationPrimaryAddress1
      organizationPrimaryAddress2
      organizationPrimaryCity
      organizationPrimaryState
      organizationPrimaryPostalCode
      organizationPrimaryRegion
      organizationPrimaryCountryCode
      organizationPrimaryPhone
      geocode
      crwdTicker
      organizationPersonStatus
      personUniqueHash
      personFirstName
      personMiddleName
      personLastName
      companyTitleName
      personPrimaryMobile
      personBusinessEmail
      companyPrimaryWebsite
      companyEmployeeCount
      companyRevenue
      sicCodeId
      industryId
      sub1IndustryId
      sub2IndustryId
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const GetSmartContractLineagesDocument = gql`
  query getSmartContractLineages {
    smartContractLineage: smartContractLineage(
      where: { isDeleted: { _eq: false }, user: { uniqueHash: { _isNull: false } } }
      distinctOn: userId
    ) {
      user {
        id
        uniqueHash
        rememberToken
        sigmaPrime {
          personFirstName
          personLastName
        }
      }
      parentUser {
        id
        uniqueHash
        rememberToken
        sigmaPrime {
          personFirstName
          personLastName
        }
      }
    }
  }
`;
export const GetSigmaPrimesByHandleDocument = gql`
  query getSigmaPrimesByHandle($handle: citext!) {
    sigmaPrime(where: { personHandleName: { _eq: $handle } }) {
      id
    }
  }
`;
export const GetUserSigmaPrimesByEmailDocument = gql`
  query getUserSigmaPrimesByEmail($email: citext!) {
    sigmaPrimes: sigmaPrime(where: { personPrimaryEmail: { _eq: $email } }) {
      ...sigmaPrimeFields
    }
    users: user(where: { email: { _eq: $email } }) {
      ...userFields
    }
  }
  ${SigmaPrimeFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const UserByIdDocument = gql`
  query userById($id: uuid!) {
    userById: userByPk(id: $id) {
      ...userFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const UsersDocument = gql`
  query users {
    users: user {
      ...userFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const UsersByEmailDocument = gql`
  query usersByEmail($email: citext!) {
    usersByEmail: user(where: { email: { _eq: $email } }) {
      ...userFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const UsersAndAuthsByEmailDocument = gql`
  query usersAndAuthsByEmail($email: citext!) {
    users: user(where: { email: { _eq: $email } }) {
      ...userFields
    }
    userAuths: userAuth(where: { user: { email: { _eq: $email } } }) {
      ...userAuthFields
    }
  }
  ${UserFieldsFragmentDoc}
  ${UserAuthFieldsFragmentDoc}
`;
export const UsersByVerifiedEmailDocument = gql`
  query usersByVerifiedEmail($email: citext!) {
    usersByVerifiedEmail: user(where: { email: { _eq: $email }, emailVerified: { _eq: true } }) {
      ...userFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const CacheDocument = gql`
  query cache($key: String!) {
    cache: cacheByPk(key: $key) {
      ...cacheFields
    }
  }
  ${CacheFieldsFragmentDoc}
`;
export const WalletsForUserDocument = gql`
  query walletsForUser($userId: uuid!) {
    walletsForUser: wallet(where: { primaryUserId: { _eq: $userId } }) {
      ...walletFields
    }
    walletKinds: walletKind {
      ...walletKindFields
    }
  }
  ${WalletFieldsFragmentDoc}
  ${WalletKindFieldsFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    upsertAppraisal(
      variables: UpsertAppraisalMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertAppraisalMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertAppraisalMutation>(UpsertAppraisalDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertAppraisal',
        'mutation',
      );
    },
    upsertAsk(
      variables: UpsertAskMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertAskMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertAskMutation>(UpsertAskDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertAsk',
        'mutation',
      );
    },
    upsertAskPutTransaction(
      variables: UpsertAskPutTransactionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertAskPutTransactionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertAskPutTransactionMutation>(UpsertAskPutTransactionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertAskPutTransaction',
        'mutation',
      );
    },
    upsertCertification(
      variables: UpsertCertificationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertCertificationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertCertificationMutation>(UpsertCertificationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertCertification',
        'mutation',
      );
    },
    upsertClearinghouse(
      variables: UpsertClearinghouseMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertClearinghouseMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertClearinghouseMutation>(UpsertClearinghouseDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertClearinghouse',
        'mutation',
      );
    },
    upsertClearinghouseCertification(
      variables: UpsertClearinghouseCertificationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertClearinghouseCertificationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertClearinghouseCertificationMutation>(
            UpsertClearinghouseCertificationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'upsertClearinghouseCertification',
        'mutation',
      );
    },
    upsertClearinghouseKind(
      variables: UpsertClearinghouseKindMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertClearinghouseKindMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertClearinghouseKindMutation>(UpsertClearinghouseKindDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertClearinghouseKind',
        'mutation',
      );
    },
    upsertCountries(
      variables: UpsertCountriesMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertCountriesMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertCountriesMutation>(UpsertCountriesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertCountries',
        'mutation',
      );
    },
    upsertCurrency(
      variables: UpsertCurrencyMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertCurrencyMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertCurrencyMutation>(UpsertCurrencyDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertCurrency',
        'mutation',
      );
    },
    upsertCurrencyKind(
      variables: UpsertCurrencyKindMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertCurrencyKindMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertCurrencyKindMutation>(UpsertCurrencyKindDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertCurrencyKind',
        'mutation',
      );
    },
    upsertCurrencyOwner(
      variables: UpsertCurrencyOwnerMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertCurrencyOwnerMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertCurrencyOwnerMutation>(UpsertCurrencyOwnerDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertCurrencyOwner',
        'mutation',
      );
    },
    upsertGroups(
      variables: UpsertGroupsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertGroupsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertGroupsMutation>(UpsertGroupsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertGroups',
        'mutation',
      );
    },
    upsertOrganizations(
      variables: UpsertOrganizationsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertOrganizationsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertOrganizationsMutation>(UpsertOrganizationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertOrganizations',
        'mutation',
      );
    },
    upsertOrganizationKind(
      variables: UpsertOrganizationKindMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertOrganizationKindMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertOrganizationKindMutation>(UpsertOrganizationKindDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertOrganizationKind',
        'mutation',
      );
    },
    upsertSubindustries(
      variables: UpsertSubindustriesMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertSubindustriesMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertSubindustriesMutation>(UpsertSubindustriesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertSubindustries',
        'mutation',
      );
    },
    upsertIndustries(
      variables: UpsertIndustriesMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertIndustriesMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertIndustriesMutation>(UpsertIndustriesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertIndustries',
        'mutation',
      );
    },
    upsertExchangeKind(
      variables: UpsertExchangeKindMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertExchangeKindMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertExchangeKindMutation>(UpsertExchangeKindDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertExchangeKind',
        'mutation',
      );
    },
    upsertExchange(
      variables: UpsertExchangeMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertExchangeMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertExchangeMutation>(UpsertExchangeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertExchange',
        'mutation',
      );
    },
    upsertSicCode(
      variables: UpsertSicCodeMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertSicCodeMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertSicCodeMutation>(UpsertSicCodeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertSicCode',
        'mutation',
      );
    },
    upsertGicsSector(
      variables: UpsertGicsSectorMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertGicsSectorMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertGicsSectorMutation>(UpsertGicsSectorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertGicsSector',
        'mutation',
      );
    },
    upsertStatuses(
      variables: UpsertStatusesMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertStatusesMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertStatusesMutation>(UpsertStatusesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertStatuses',
        'mutation',
      );
    },
    upsertStatusKinds(
      variables: UpsertStatusKindsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertStatusKindsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertStatusKindsMutation>(UpsertStatusKindsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertStatusKinds',
        'mutation',
      );
    },
    upsertInteraction(
      variables: UpsertInteractionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertInteractionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertInteractionMutation>(UpsertInteractionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertInteraction',
        'mutation',
      );
    },
    upsertMerchant(
      variables: UpsertMerchantMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertMerchantMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertMerchantMutation>(UpsertMerchantDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertMerchant',
        'mutation',
      );
    },
    upsertMerchantCurrency(
      variables: UpsertMerchantCurrencyMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertMerchantCurrencyMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertMerchantCurrencyMutation>(UpsertMerchantCurrencyDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertMerchantCurrency',
        'mutation',
      );
    },
    upsertMerchantOrganization(
      variables: UpsertMerchantOrganizationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertMerchantOrganizationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertMerchantOrganizationMutation>(UpsertMerchantOrganizationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertMerchantOrganization',
        'mutation',
      );
    },
    upsertAsset(
      variables: UpsertAssetMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertAssetMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertAssetMutation>(UpsertAssetDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertAsset',
        'mutation',
      );
    },
    upsertAssetKind(
      variables: UpsertAssetKindMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertAssetKindMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertAssetKindMutation>(UpsertAssetKindDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertAssetKind',
        'mutation',
      );
    },
    upsertPut(
      variables: UpsertPutMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertPutMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertPutMutation>(UpsertPutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertPut',
        'mutation',
      );
    },
    upsertSettings(
      variables: UpsertSettingsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertSettingsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertSettingsMutation>(UpsertSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertSettings',
        'mutation',
      );
    },
    upsertSmartContractLineage(
      variables: UpsertSmartContractLineageMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertSmartContractLineageMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertSmartContractLineageMutation>(UpsertSmartContractLineageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertSmartContractLineage',
        'mutation',
      );
    },
    upsertDocumentUser(
      variables: UpsertDocumentUserMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertDocumentUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertDocumentUserMutation>(UpsertDocumentUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertDocumentUser',
        'mutation',
      );
    },
    upsertProductStatus(
      variables: UpsertProductStatusMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertProductStatusMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertProductStatusMutation>(UpsertProductStatusDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertProductStatus',
        'mutation',
      );
    },
    upsertSmartContract(
      variables: UpsertSmartContractMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertSmartContractMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertSmartContractMutation>(UpsertSmartContractDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertSmartContract',
        'mutation',
      );
    },
    upsertSmartContractKind(
      variables: UpsertSmartContractKindMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertSmartContractKindMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertSmartContractKindMutation>(UpsertSmartContractKindDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertSmartContractKind',
        'mutation',
      );
    },
    upsertTrade(
      variables: UpsertTradeMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertTradeMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertTradeMutation>(UpsertTradeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertTrade',
        'mutation',
      );
    },
    upsertTransaction(
      variables: UpsertTransactionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertTransactionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertTransactionMutation>(UpsertTransactionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertTransaction',
        'mutation',
      );
    },
    upsertTransactionKind(
      variables: UpsertTransactionKindMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertTransactionKindMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertTransactionKindMutation>(UpsertTransactionKindDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertTransactionKind',
        'mutation',
      );
    },
    upsertTradeKind(
      variables: UpsertTradeKindMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertTradeKindMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertTradeKindMutation>(UpsertTradeKindDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertTradeKind',
        'mutation',
      );
    },
    upsertWallet(
      variables: UpsertWalletMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertWalletMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertWalletMutation>(UpsertWalletDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertWallet',
        'mutation',
      );
    },
    upsertWalletKind(
      variables: UpsertWalletKindMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertWalletKindMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertWalletKindMutation>(UpsertWalletKindDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertWalletKind',
        'mutation',
      );
    },
    upsertSigmaPrimeKind(
      variables: UpsertSigmaPrimeKindMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertSigmaPrimeKindMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertSigmaPrimeKindMutation>(UpsertSigmaPrimeKindDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertSigmaPrimeKind',
        'mutation',
      );
    },
    upsertSigmaPrime(
      variables: UpsertSigmaPrimeMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertSigmaPrimeMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertSigmaPrimeMutation>(UpsertSigmaPrimeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertSigmaPrime',
        'mutation',
      );
    },
    updateSigmaPrime(
      variables: UpdateSigmaPrimeMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateSigmaPrimeMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateSigmaPrimeMutation>(UpdateSigmaPrimeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateSigmaPrime',
        'mutation',
      );
    },
    upsertUser(
      variables: UpsertUserMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertUserMutation>(UpsertUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertUser',
        'mutation',
      );
    },
    insertUser(
      variables: InsertUserMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<InsertUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InsertUserMutation>(InsertUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'insertUser',
        'mutation',
      );
    },
    insertUserAuth(
      variables: InsertUserAuthMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<InsertUserAuthMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<InsertUserAuthMutation>(InsertUserAuthDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'insertUserAuth',
        'mutation',
      );
    },
    updateUser(
      variables: UpdateUserMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpdateUserMutation>(UpdateUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateUser',
        'mutation',
      );
    },
    upsertUserAuth(
      variables: UpsertUserAuthMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertUserAuthMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertUserAuthMutation>(UpsertUserAuthDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertUserAuth',
        'mutation',
      );
    },
    verifyEmail(
      variables: VerifyEmailMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<VerifyEmailMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<VerifyEmailMutation>(VerifyEmailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'verifyEmail',
        'mutation',
      );
    },
    upsertSigmaPrimeAddress(
      variables: UpsertSigmaPrimeAddressMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertSigmaPrimeAddressMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertSigmaPrimeAddressMutation>(UpsertSigmaPrimeAddressDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertSigmaPrimeAddress',
        'mutation',
      );
    },
    upsertAddressAttributes(
      variables: UpsertAddressAttributesMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertAddressAttributesMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertAddressAttributesMutation>(UpsertAddressAttributesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertAddressAttributes',
        'mutation',
      );
    },
    upsertDatasource(
      variables: UpsertDatasourceMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertDatasourceMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertDatasourceMutation>(UpsertDatasourceDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertDatasource',
        'mutation',
      );
    },
    upsertSmartContractCommission(
      variables: UpsertSmartContractCommissionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertSmartContractCommissionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertSmartContractCommissionMutation>(UpsertSmartContractCommissionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertSmartContractCommission',
        'mutation',
      );
    },
    upsertSmartContractDocument(
      variables: UpsertSmartContractDocumentMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertSmartContractDocumentMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertSmartContractDocumentMutation>(UpsertSmartContractDocumentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertSmartContractDocument',
        'mutation',
      );
    },
    upsertWalletInventory(
      variables: UpsertWalletInventoryMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertWalletInventoryMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertWalletInventoryMutation>(UpsertWalletInventoryDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertWalletInventory',
        'mutation',
      );
    },
    upsertAssetAccount(
      variables: UpsertAssetAccountMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertAssetAccountMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertAssetAccountMutation>(UpsertAssetAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertAssetAccount',
        'mutation',
      );
    },
    upsertProductDistribution(
      variables: UpsertProductDistributionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertProductDistributionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertProductDistributionMutation>(UpsertProductDistributionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertProductDistribution',
        'mutation',
      );
    },
    upsertPayment(
      variables: UpsertPaymentMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertPaymentMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertPaymentMutation>(UpsertPaymentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertPayment',
        'mutation',
      );
    },
    upsertOrder(
      variables: UpsertOrderMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertOrderMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertOrderMutation>(UpsertOrderDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertOrder',
        'mutation',
      );
    },
    upsertProduct(
      variables: UpsertProductMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertProductMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertProductMutation>(UpsertProductDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertProduct',
        'mutation',
      );
    },
    upsertProductKind(
      variables: UpsertProductKindMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertProductKindMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertProductKindMutation>(UpsertProductKindDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertProductKind',
        'mutation',
      );
    },
    upsertMaterial(
      variables: UpsertMaterialMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertMaterialMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertMaterialMutation>(UpsertMaterialDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertMaterial',
        'mutation',
      );
    },
    upsertCart(
      variables: UpsertCartMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertCartMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertCartMutation>(UpsertCartDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertCart',
        'mutation',
      );
    },
    upsertMarket(
      variables: UpsertMarketMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertMarketMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertMarketMutation>(UpsertMarketDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertMarket',
        'mutation',
      );
    },
    upsertOrganizationStatus(
      variables: UpsertOrganizationStatusMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertOrganizationStatusMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertOrganizationStatusMutation>(UpsertOrganizationStatusDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertOrganizationStatus',
        'mutation',
      );
    },
    upsertShipping(
      variables: UpsertShippingMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertShippingMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertShippingMutation>(UpsertShippingDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertShipping',
        'mutation',
      );
    },
    upsertShop(
      variables: UpsertShopMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertShopMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertShopMutation>(UpsertShopDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertShop',
        'mutation',
      );
    },
    upsertSupplyChainWaypoints(
      variables: UpsertSupplyChainWaypointsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertSupplyChainWaypointsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertSupplyChainWaypointsMutation>(UpsertSupplyChainWaypointsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertSupplyChainWaypoints',
        'mutation',
      );
    },
    getAskByAsset(
      variables: GetAskByAssetQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetAskByAssetQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetAskByAssetQuery>(GetAskByAssetDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getAskByAsset',
        'query',
      );
    },
    getAskById(
      variables: GetAskByIdQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetAskByIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetAskByIdQuery>(GetAskByIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getAskById',
        'query',
      );
    },
    getPutByAsk(
      variables: GetPutByAskQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetPutByAskQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetPutByAskQuery>(GetPutByAskDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getPutByAsk',
        'query',
      );
    },
    getMaxAmountPutByAskId(
      variables: GetMaxAmountPutByAskIdQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetMaxAmountPutByAskIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetMaxAmountPutByAskIdQuery>(GetMaxAmountPutByAskIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getMaxAmountPutByAskId',
        'query',
      );
    },
    getFeaturedAsk(
      variables: GetFeaturedAskQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetFeaturedAskQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetFeaturedAskQuery>(GetFeaturedAskDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getFeaturedAsk',
        'query',
      );
    },
    getPutsByAssetId(
      variables: GetPutsByAssetIdQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetPutsByAssetIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetPutsByAssetIdQuery>(GetPutsByAssetIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getPutsByAssetId',
        'query',
      );
    },
    upsertCache(
      variables: UpsertCacheMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertCacheMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertCacheMutation>(UpsertCacheDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertCache',
        'mutation',
      );
    },
    deleteCache(
      variables: DeleteCacheMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<DeleteCacheMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<DeleteCacheMutation>(DeleteCacheDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deleteCache',
        'mutation',
      );
    },
    upsertKycStatus(
      variables: UpsertKycStatusMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertKycStatusMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertKycStatusMutation>(UpsertKycStatusDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertKycStatus',
        'mutation',
      );
    },
    upsertKycKind(
      variables: UpsertKycKindMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpsertKycKindMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UpsertKycKindMutation>(UpsertKycKindDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'upsertKycKind',
        'mutation',
      );
    },
    countries(
      variables?: CountriesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CountriesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CountriesQuery>(CountriesDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'countries',
        'query',
      );
    },
    addressStatesByCountryCode(
      variables: AddressStatesByCountryCodeQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<AddressStatesByCountryCodeQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<AddressStatesByCountryCodeQuery>(AddressStatesByCountryCodeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'addressStatesByCountryCode',
        'query',
      );
    },
    getOrganizationByUserId(
      variables: GetOrganizationByUserIdQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetOrganizationByUserIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetOrganizationByUserIdQuery>(GetOrganizationByUserIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getOrganizationByUserId',
        'query',
      );
    },
    getSmartContractLineages(
      variables?: GetSmartContractLineagesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetSmartContractLineagesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSmartContractLineagesQuery>(GetSmartContractLineagesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getSmartContractLineages',
        'query',
      );
    },
    getSigmaPrimesByHandle(
      variables: GetSigmaPrimesByHandleQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetSigmaPrimesByHandleQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetSigmaPrimesByHandleQuery>(GetSigmaPrimesByHandleDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getSigmaPrimesByHandle',
        'query',
      );
    },
    getUserSigmaPrimesByEmail(
      variables: GetUserSigmaPrimesByEmailQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetUserSigmaPrimesByEmailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetUserSigmaPrimesByEmailQuery>(GetUserSigmaPrimesByEmailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getUserSigmaPrimesByEmail',
        'query',
      );
    },
    userById(variables: UserByIdQueryVariables, requestHeaders?: Dom.RequestInit['headers']): Promise<UserByIdQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UserByIdQuery>(UserByIdDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'userById',
        'query',
      );
    },
    users(variables?: UsersQueryVariables, requestHeaders?: Dom.RequestInit['headers']): Promise<UsersQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UsersQuery>(UsersDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'users',
        'query',
      );
    },
    usersByEmail(
      variables: UsersByEmailQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UsersByEmailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UsersByEmailQuery>(UsersByEmailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'usersByEmail',
        'query',
      );
    },
    usersAndAuthsByEmail(
      variables: UsersAndAuthsByEmailQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UsersAndAuthsByEmailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UsersAndAuthsByEmailQuery>(UsersAndAuthsByEmailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'usersAndAuthsByEmail',
        'query',
      );
    },
    usersByVerifiedEmail(
      variables: UsersByVerifiedEmailQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UsersByVerifiedEmailQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<UsersByVerifiedEmailQuery>(UsersByVerifiedEmailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'usersByVerifiedEmail',
        'query',
      );
    },
    cache(variables: CacheQueryVariables, requestHeaders?: Dom.RequestInit['headers']): Promise<CacheQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<CacheQuery>(CacheDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'cache',
        'query',
      );
    },
    walletsForUser(
      variables: WalletsForUserQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<WalletsForUserQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<WalletsForUserQuery>(WalletsForUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'walletsForUser',
        'query',
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
