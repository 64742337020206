import React, { useMemo } from 'react';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { Link, useHistory } from 'react-router-dom';

import { NFTRanking } from '../../../common/data';
import Loader from 'Components/Common/Loader';
import { useGetAskByAssetQuery } from 'graphql/generated/resourceApi';
import { useClient } from 'shared/UserContext';

const ListAsks = () => {
  document.title = 'Asks | crwdcapital - universal wallet';
  const client = useClient();
  const history = useHistory();
  const askByAssetQueryState = useGetAskByAssetQuery(client, { assetId: '9d0d9499-ba3b-4f5a-8a78-cf3f944d206a' });
  const asks = (askByAssetQueryState.data?.getAskByAsset || []).filter(type => !['formId'].includes(type.name));
  // Ranking Column
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        filterable: false,
      },
      {
        Header: 'description',
        accessor: 'description',
        filterable: false,
      },
      {
        Header: 'Starting Ask',
        accessor: 'startingAsk',
        filterable: false,
      },
      {
        Header: 'Ask Number',
        accessor: 'askNumber',
        filterable: false,
      },
      {
        Header: 'currency',
        accessor: 'currency',
        filterable: false,
        Cell: cell => {
          return <h6 className="text-success mb-1 7d">{cell.value.name}</h6>;
        },
      },
      {
        Header: 'image',
        accessor: 'image',
        filterable: false,
      },
      {
        Header: 'wallet',
        accessor: 'wallet',
        filterable: false,
        Cell: cell => {
          return <h6 className="text-success mb-1 7d">{cell.value?.name}</h6>;
        },
      },
      {
        Header: 'trade',
        accessor: 'trade',
        filterable: false,
        Cell: cell => {
          return <h6 className="text-success mb-1 7d">{cell.value?.name}</h6>;
        },
      },
    ],
    [],
  );

  const viewAsk = row => {
    history.push(`/ask-details/${row.original.id}`);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Ranking" pageTitle="NFT Marketplace" />
          <div className="row">
            <div className="col-lg-12">
              <div className="card" id="contactList">
                <div className="card-header border-0">
                  <div className="d-flex align-items center">
                    <h5 className="card-title mb-0 flex-grow-1">Asks</h5>
                  </div>
                </div>
                <div className="card-body">
                  {asks?.length > 0 ? (
                    <TableContainer
                      columns={columns}
                      data={asks || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                      isNFTRankingFilter={true}
                      rowClick={ask => viewAsk(ask)}
                    />
                  ) : (
                    <div className="noresult">
                      <div className="text-center">
                        <lord-icon
                          src="https://cdn.lordicon.com/msoeawqm.json"
                          trigger="loop"
                          colors="primary:#405189,secondary:#0ab39c"
                          style={{ width: '75px', height: '75px' }}
                        ></lord-icon>
                        <h5 className="mt-2">Sorry! No Result Found</h5>
                        <p className="text-muted mb-0">
                          We've searched more than 150+ transactions We did not find any transactions for you search.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ListAsks;
