const TechnologyRiskAssessment = ({ details }) => {
  return (
    <>
      <h3>Technology Risk Assessment Report</h3>
      <div className="subsections">
        <div className="subsection">
          <h4>Technology Infrastructure</h4>
          <ul>
            <li>{details.technologyRiskAssessmentReport.technologyInfrastructure.subsection1}</li>
            <li>{details.technologyRiskAssessmentReport.technologyInfrastructure.subsection2}</li>
            <li>{details.technologyRiskAssessmentReport.technologyInfrastructure.subsection3}</li>
          </ul>
        </div>
        <div className="subsection">
          <h4>Cybersecurity Risks</h4>
          <ul>
            <li>{details.technologyRiskAssessmentReport.cybersecurityRisks.subsection1}</li>
            <li>{details.technologyRiskAssessmentReport.cybersecurityRisks.subsection2}</li>
            <li>{details.technologyRiskAssessmentReport.cybersecurityRisks.subsection3}</li>
          </ul>
        </div>
        <div className="subsection">
          <h4>Data Privacy Concerns</h4>
          <ul>
            <li>{details.technologyRiskAssessmentReport.dataPrivacyConcerns.subsection1}</li>
            <li>{details.technologyRiskAssessmentReport.dataPrivacyConcerns.subsection2}</li>
            <li>{details.technologyRiskAssessmentReport.dataPrivacyConcerns.subsection3}</li>
          </ul>
        </div>
        <div className="subsection">
          <h4>Technology Obsolescence</h4>
          <ul>
            <li>{details.technologyRiskAssessmentReport.technologyObsolescence.subsection1}</li>
            <li>{details.technologyRiskAssessmentReport.technologyObsolescence.subsection2}</li>
            <li>{details.technologyRiskAssessmentReport.technologyObsolescence.subsection3}</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default TechnologyRiskAssessment;
