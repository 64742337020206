const FinancialSummary = ({ details }) => {
  return (
    <>
      <h4>Contact Information</h4>
      <div className="row">
        <div className="col">
          <p>
            <strong>Email:</strong> {details.contactInformation.email}
          </p>
          <p>
            <strong>Phone:</strong> {details.contactInformation.phone}
          </p>
          <p>
            <strong>Address:</strong> {details.contactInformation.address}
          </p>
        </div>
      </div>
      <hr className="my-4" />

      <h4>Executive Summary</h4>
      <p>{details.executiveSummary}</p>
      <hr className="my-4" />

      <h4>Key Metrics</h4>
      <div className="row">
        <div className="col">
          <p>
            <strong>Customer Base:</strong> {details.keyMetrics.customerBase}
          </p>
          <p>
            <strong>Employees:</strong> {details.keyMetrics.employees}
          </p>
        </div>
      </div>
      <hr className="my-4" />

      <h4>Financial Highlights</h4>
      <div className="row">
        <div className="col">
          <p>
            <strong>Annual Revenue:</strong>
          </p>
          <ul>
            {details.financialHighlights.annualRevenue.map((revenue, index) => (
              <li key={index}>
                {revenue.year}: {revenue.amount}
              </li>
            ))}
          </ul>
          <p>
            <strong>Net Income:</strong>
          </p>
          <ul>
            {details.financialHighlights.netIncome.map((income, index) => (
              <li key={index}>
                {income.year}: {income.amount}
              </li>
            ))}
          </ul>
        </div>
        <div className="col">
          <p>
            <strong>Total Assets:</strong> {details.financialHighlights.totalAssets}
          </p>
          <p>
            <strong>Total Liabilities:</strong> {details.financialHighlights.totalLiabilities}
          </p>
          <p>
            <strong>Return on Revenue:</strong> {details.financialHighlights.returnOnRevenue}
          </p>
          <p>
            <strong>Return on Investment:</strong> {details.financialHighlights.returnOnInvestment}
          </p>
        </div>
      </div>
      <hr className="my-4" />

      <h4>Target Market</h4>
      <p>{details.targetMarket}</p>
      <hr className="my-4" />

      <h4>Total Addressable Market</h4>
      <p>{details.totalAddressableMarket}</p>
      <hr className="my-4" />

      <h4>Competitive Advantage</h4>
      <ul>
        {details.competitiveAdvantage.map((advantage, index) => (
          <li key={index}>{advantage}</li>
        ))}
      </ul>
      <hr className="my-4" />

      <h4>Growth Strategy</h4>
      <p>{details.growthStrategy}</p>
    </>
  );
};

export default FinancialSummary;
