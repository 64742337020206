import React from 'react';
import ReactApexChart from 'react-apexcharts';

import getChartColorsArray from 'Components/Common/ChartsDynamicColor';

function generateData(count, yrange) {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = (i + 1).toString();
    var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push({
      x: x,
      y: y,
    });
    i++;
  }
  return series;
}

const BasicHeatmap = ({ dataColors }) => {
  var chartHeatMapBasicColors = getChartColorsArray(dataColors);
  generateData();
  const series = [
    {
      name: 'Metric1',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric2',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric3',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric4',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric5',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric6',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric7',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric8',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric9',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
  ];
  var options = {
    chart: {
      height: 450,
      type: 'heatmap',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [chartHeatMapBasicColors[0]],
    title: {
      text: 'HeatMap Chart (Single color)',
      style: {
        fontWeight: 500,
      },
    },
  };
  return <ReactApexChart series={series} options={options} type="heatmap" height={450} />;
};

const MultipleHeatmap = ({ dataColors }) => {
  var chartHeatMapMultipleColors = getChartColorsArray(dataColors);
  generateData();
  const data = [
    {
      name: 'W1',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'W2',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'W3',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'W4',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'W5',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'W6',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'W7',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'W8',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'W9',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'W10',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'W11',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'W12',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'W13',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'W14',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'W15',
      data: generateData(8, {
        min: 0,
        max: 90,
      }),
    },
  ];

  const series = data;
  var options = {
    chart: {
      height: 450,
      type: 'heatmap',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      chartHeatMapMultipleColors[0],
      chartHeatMapMultipleColors[1],
      chartHeatMapMultipleColors[2],
      chartHeatMapMultipleColors[3],
      chartHeatMapMultipleColors[4],
      chartHeatMapMultipleColors[5],
      chartHeatMapMultipleColors[6],
      chartHeatMapMultipleColors[7],
    ],
    xaxis: {
      type: 'category',
      categories: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '01:00', '01:30'],
    },
    title: {
      text: 'HeatMap Chart (Different color shades for each series)',
      style: {
        fontWeight: 500,
      },
    },
    grid: {
      padding: {
        right: 20,
      },
    },
  };
  return <ReactApexChart series={series} options={options} type="heatmap" height={450} />;
};

const ColorRange = ({ dataColors }) => {
  var chartHeatMapColors = getChartColorsArray(dataColors);
  const series = [
    {
      name: 'Jan',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'Feb',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'Mar',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'Apr',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'May',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'Jun',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'Jul',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'Aug',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'Sep',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
  ];
  var options = {
    chart: {
      height: 350,
      type: 'heatmap',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: true,
        colorScale: {
          ranges: [
            {
              from: -30,
              to: 5,
              name: 'Low',
              color: chartHeatMapColors[0],
            },
            {
              from: 6,
              to: 20,
              name: 'Medium',
              color: chartHeatMapColors[1],
            },
            {
              from: 21,
              to: 45,
              name: 'High',
              color: chartHeatMapColors[2],
            },
            {
              from: 46,
              to: 55,
              name: 'Extreme',
              color: chartHeatMapColors[3],
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
    },
    title: {
      text: 'HeatMap Chart with Color Range',
      style: {
        fontWeight: 500,
      },
    },
  };
  return <ReactApexChart series={series} options={options} type="heatmap" height={350} />;
};

const RangeWithoutShades = ({ dataColors }) => {
  var chartHeatMapShadesColors = getChartColorsArray(dataColors);
  const series = [
    {
      name: 'Metric1',
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric2',
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric3',
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric4',
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric5',
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric6',
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric7',
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric8',
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Metric8',
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
  ];
  var options = {
    chart: {
      height: 350,
      type: 'heatmap',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      heatmap: {
        radius: 30,
        enableShades: false,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 50,
              color: chartHeatMapShadesColors[0],
            },
            {
              from: 51,
              to: 100,
              color: chartHeatMapShadesColors[1],
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#fff'],
      },
    },
    xaxis: {
      type: 'category',
    },
    title: {
      text: 'Rounded (Range without Shades)',
      style: {
        fontWeight: 500,
      },
    },
  };
  return <ReactApexChart series={series} options={options} type="heatmap" height={350} />;
};
export { BasicHeatmap, MultipleHeatmap, ColorRange, RangeWithoutShades };
