import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, CardImgOverlay, Col, Container, Row, Spinner } from 'reactstrap';
import { GicsBar } from './Charts';
import BreadCrumb from 'Components/Common/BreadCrumb';
import SectorPerformanceChart from './SectorPerformanceChart';

const ChartsGICS = () => {
  document.title = 'GICS Sector Charts | crwdcapital - universal wallet';

  const [loading, setLoading] = useState(false);
  const [sectors, setSectors] = useState<{ key: string; name: string; data: number[] }[]>([
    { key: 'Energy', name: 'GICS | Energy Sector', data: [] },
    { key: 'Materials', name: 'GICS | Materials', data: [] },
    { key: 'Industrials', name: 'GICS | Industrials', data: [] },
    { key: 'Consumer Discretionary', name: 'GICS | Consumer Discretionary', data: [] },
    { key: 'Consumer Staples', name: 'GICS | Consumer Staples', data: [] },
    { key: 'Health Care', name: 'GICS | Health Care', data: [] },
    { key: 'Financials', name: 'GICS | Financials', data: [] },
    { key: 'Information Technology', name: 'GICS | Information Technology', data: [] },
    { key: 'Communication Services', name: 'GICS | Communication Services', data: [] },
    { key: 'Utilities', name: 'GICS | Utilities', data: [] },
    { key: 'Real Estate', name: 'GICS | Real Estate', data: [] },
  ]);

  const [allSectorData, setAllSectorData] = useState(null);
  const params = new URLSearchParams({
    function: 'SECTOR',
    apikey: process.env.REACT_APP_CHART_API_KEY!,
  });

  const getGICsApiData = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_CHART_ENDPOINT}?${params}`);
      const data = await response.json();
      delete data['Meta Data'];

      const _sectors: { key: string; name: string; data: number[] }[] = [];
      sectors.map(_sector => {
        for (let [, value] of Object.entries<Object>(data)) {
          _sector.data.push(value[_sector.key]);
        }
        _sectors.push(_sector);
      });
      // console.log(_sectors);
      setSectors(_sectors);
      setLoading(false);
    } catch (error) {
      console.error('Error querying Alpha Vantage API:', error);
    }
  };

  const getAllGicsData = async () => {
    const url = `${process.env.REACT_APP_CHART_ENDPOINT}?${params}`;

    fetch(url)
      .then(response => response.json())
      .then(data => setAllSectorData(data))
      .catch(err => console.error(err));
  };

  useEffect(() => {
    getGICsApiData();
    getAllGicsData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="GICS Sector Charts" pageTitle="" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">US Sector Performance (realtime & historical)</h4>
                </CardHeader>
                <CardBody>
                  {allSectorData ? <SectorPerformanceChart data={allSectorData} horizontal={true} /> : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {sectors?.map((sector, i) => (
              <Col lg={6} key={i}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">{sector.name}</h4>
                  </CardHeader>
                  <CardBody className="relative">
                    {!loading && <GicsBar data={sector.data} />}
                    {loading && (
                      <>
                        <GicsBar data={sector.data} />
                        <CardImgOverlay className="flex justify-content-center align-items-center align-middle bg-spinner">
                          <Spinner />
                        </CardImgOverlay>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ChartsGICS;
