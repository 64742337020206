// Actions
export const API_RESPONSE_SUCCESS = 'API_RESPONSE_SUCCESS';
export const API_RESPONSE_ERROR = 'API_RESPONSE_ERROR';
export const GET_TICKETS_LIST = 'GET_TICKETS_LIST';

/// Add TICKET
export const ADD_NEW_TICKET = 'ADD_NEW_TICKET';
export const ADD_TICKET_SUCCESS = 'ADD_TICKET_SUCCESS';
export const ADD_TICKET_FAIL = 'ADD_TICKET_FAIL';

// Edit TICKET
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS';
export const UPDATE_TICKET_FAIL = 'UPDATE_TICKET_FAIL';

// Delete TICKET
export const DELETE_TICKET = 'DELETE_TICKET';
export const DELETE_TICKET_SUCCESS = 'DELETE_TICKET_SUCCESS';
export const DELETE_TICKET_FAIL = 'DELETE_TICKET_FAIL';
